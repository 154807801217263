import React from "react";

import "./style.scss";

const Checkbox = ({
  checked,
  onCheckboxChange,
  label,
  defaultChecked,
  defaultValue,
  isDisabled
}: any) => {
  return (
    <div className="checkbox-container">
      <input
        defaultChecked={defaultChecked}
        defaultValue={defaultValue}
        disabled={isDisabled}
        type="checkbox"
        className="custom-checkbox"
        onClick={onCheckboxChange}
        checked={checked}
      />
      <span className="checkbox-label">{label}</span>
    </div>
  );
};

export default Checkbox;
