import React, { useState } from "react";
import {
  ModalTitle,
  Button,
  ModalBody,
  ModalFooter,
  Image,
  Modal,
  ButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import closeImg from "../../../../assets/New-images/build-knowledge-close-img.svg";
import { useTranslation } from "react-i18next";

const LengthOfSummaryPopup = (props: any) => {
  const { t } = useTranslation("translation");
  const [length, setLength] = useState(null);
  const [active, setActive] = useState(false);

  const summariseDoc = () => {
    props.singleSummariseDoc(length);
  };

  return (
    <React.Fragment>
      <Modal show={props.show} centered onHide={props.onHide}>
        <ModalHeader className="clr-black pt-4 px-4 justify-content-between">
          <ModalTitle id="contained-modal-title-vcenter">
            <h2 className="mb-0 font-1">{t("Length of summary")}</h2>
          </ModalTitle>
          <button className="btn" onClick={props.onHide}>
            <img src={closeImg} alt="" />
          </button>
        </ModalHeader>
        <ModalBody className="px-4">
          <div className="font-600">
            <label>
              {t("Please choose the desired length for the summary document.")}
            </label>
            <ButtonGroup className="w-100 tab-btns-group">
              <Button
                variant="default"
                className={
                  !length
                    ? "btn-primary active"
                    : "btn-outline-primary"
                }
                onClick={() => {
                  setLength(null);
                }}
              >
                {t("Brief")}
              </Button>
              <Button
                variant="default"
                className={
                  length === 0.7
                    ? "btn-primary active"
                    : "btn-outline-primary"
                }
                onClick={() => {
                  setLength(0.7);
                }}
              >
                {t("Balanced")}
              </Button>
              <Button
                variant="default"
                className={
                  length === 0.85
                    ? "btn-primary active"
                    : "btn-outline-primary"
                }
                onClick={() => {
                  setLength(0.85);
                }}
              >
                {t("Detailed")}
              </Button>
            </ButtonGroup>
          </div>
        </ModalBody>
        <ModalFooter className="justify-content-center border-top-0">
          <button className="btn-primary btn font-600 py-1" onClick={summariseDoc}>
            {t("Summarise")}
          </button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default LengthOfSummaryPopup;
