import React, { useState, useEffect } from 'react'
import {
  Button,
  Image,
  Modal,
  ModalFooter,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap'
import ModalBody from 'react-bootstrap/ModalBody'
import ModalHeader from 'react-bootstrap/ModalHeader'
import ModalTitle from 'react-bootstrap/ModalTitle'
import Select from 'react-select'
import downarrowblk from '../../../assets/images/chevron-down-blk.svg'
import infoimg from '../../../assets/images/help-info.svg'

import { useTranslation } from "react-i18next";

interface Props {
  selectedGroups: any
  allUserGroups: any[]
  show: boolean
  size: any
  index: number
  onHide: () => void
  onUpdate: (e: any[], index: number) => void
}

const UserGroupModal = (props: Props) => {
  const { t } = useTranslation('translation');
  const [selectedGroups, setSelectedGroups] = useState([])
  useEffect(() => {
    setSelectedGroups(props.selectedGroups ? [...props.selectedGroups] : [])
  }, [props.selectedGroups])

  const PopoverComponent = (data: any) => {
    return (
      <Popover id='popover-basic-' {...data.popoverComponentProps}>
        <Popover.Content>
          {t("You can choose to assign this response to user groups. Only the users in the selected user groups will get this response for the question.")}
        </Popover.Content>
      </Popover>
    )
  }

  const chooseButtonGrouphandler = (e: any) => {
    setSelectedGroups([...e])
  }

  //   toggleModal = () => {
  //     this.setState(prevState => ({
  //       modalIsOpen: !prevState.modalIsOpen
  //     }));
  //   };

  return (
    <Modal
      show={props.show}
      size={props.size}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      dialogClassName='greetings-with-group-options'
      onHide={props.onHide}
    >
      <ModalHeader className='justify-content-center border-bottom'>
        <ModalTitle id='contained-modal-title-vcenter'>
          <h2 className='mb-0 py-4'>{t("Answer Settings")}</h2>
        </ModalTitle>
      </ModalHeader>
      <ModalBody className='py-4'>
        <div className='col-md-9'>
          <h3 className='mb-12 font-weight-bold'>
            {t("User Group")}
            <OverlayTrigger
              trigger={['hover', 'focus']}
              placement={'auto'}
              overlay={(d) => <PopoverComponent popoverComponentProps={d} />}
            >
              <Image src={infoimg} className='ml-2' alt='Info' />
            </OverlayTrigger>
          </h3>
          <Select
            isSearchable
            isMulti
            className='select-usergroup'
            classNamePrefix='select-greeting-user-group'
            components={{
              DropdownIndicator: () => (
                <img src={downarrowblk} alt={t('dropdown arrow')} />
              ),
              IndicatorSeparator: () => null,
              ClearIndicator: () => null,
            }}
            placeholder={t('Select user group')}
            defaultValue={[...selectedGroups]}
            value={[...selectedGroups]}
            onChange={chooseButtonGrouphandler}
            options={[...props.allUserGroups]}
            // getOptionLabel={(option: any) => option['name']}
            // getOptionValue={(option: any) => option['_id']}
          />
        </div>
      </ModalBody>
      <ModalFooter className='border-0 justify-content-center px-3 my-3'>
        <button
          className='btn btn-primary mr-3'
          onClick={(e) => props.onUpdate(selectedGroups, props.index)}
        >
          {t("Done")}
        </button>
        <button className='btn-outline-primary btn' onClick={props.onHide}>
          {t("Cancel")}
        </button>
      </ModalFooter>
    </Modal>
  )
}

export default UserGroupModal
