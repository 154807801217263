import cx from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import DashboardFiltersWithDateOnly from "../../../../components/UI/Dashboard/DashboardFiltersWithDateOnly/DashboardFiltersWithDateOnly";
import { getStickerSets } from "../../../../store/common/actions";
import { getAllUserGroupOptions } from "../../../../store/notifications/actions";
import {
  getAllButtonOptions,
  getAllIntents,
  getAllUserGroups,
} from "../../../../store/questions/actions";
import KnowledgeCreateFromScratch from "../../../Knowledge/KnowledgeCreateFromScratch/KnowledgeCreateFromScratch";
import "../../index.scss";
import DashboardNavigation from "../DashboardNavigation";
import Ratings from "../../pages/partials/Ratings/Ratings";
import MatchedDatatableComponent from "./partials/MatchedDatatable";
import UnMatchedDatatableComponent from "./partials/UnMatchedDatatable";
import UserFeedback from "../reports/UserFeedback";

import { useTranslation } from "react-i18next";
import { DashboardFilters } from "../../../../components/UI/Dashboard";

interface Props {}

export const MatchedUnmatchedQuestions = (props: any) => {
  const { t } = useTranslation("translation");
  const history = useHistory();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("matched");
  const [filtersData, setFiltersData] = useState({
    filter_from_date: null,
    filter_to_date: null,
    // channel: null,
    dateParameters: null,
  });

  useEffect(() => {
    if (props.location.state && props.location.state.activeTab) {
      setActiveTab(props.location.state.activeTab);
    }

    dispatch(getAllUserGroups());
    dispatch(getStickerSets());
    dispatch(getAllUserGroupOptions());
    dispatch(getAllButtonOptions());
    dispatch(getAllIntents());
  }, [props.location.state]);

  const tabsChangeHandler = (e: string) => {
    setActiveTab(e);
  };

  /**
   * Edit mode activate or de activate
   */
  const [editMode, setEditMode] = useState(false);
  const [selectedIntent, setSelectedIntent] = useState(null);
  const editKnowledgehandler = (intent: any) => {
    const editModeCheck = !editMode;
    setSelectedIntent(intent);
    setEditMode(editModeCheck);
  };

  const resetEditKnowledgehandler = (action: any) => {
    const editModeCheck = !editMode;
    setSelectedIntent(null);
    setEditMode(editModeCheck);
    // From edit mode if any intent gets deleted then we have to reload data
    if (action === "reload") {
      //   dispatch(
      //     getAllKnowledge(
      //       3,
      //       currentPage,
      //       perPage,
      //       query,
      //       topicIds,
      //       {},
      //       selectedNodes.length > 0
      //     )
      //   )
    }
  };

  const hasFilterUpdated = async (e: any) => {
    await setFiltersData({
      ...filtersData,
      ...e,
    });
  };

  return (
    <>
      <section
        className={cx({
          "page-mid-wraper h-without-foter": true,
          "dashborad-wraper": !editMode,
        })}
      >
        <div className="page-mid-wraper">

        <DashboardFilters showFilter={false} hasFilterUpdated={()=>{}}  />
          {/*--------- top-navigation -----------*/}
          <DashboardNavigation/>
          {/*-------- welcome text ------------*/}
          <div className="welcome-wrap welcome-subtext d-flex flex-md-nowrap flex-wrap mb-4">
            <h2>{t("Bot Experience")}</h2>
          </div>
          <div
              className={cx("right-animate", {
                show: editMode,
              })}
          >
            {editMode && (
                <KnowledgeCreateFromScratch
                    resetEditKnowledgehandler={resetEditKnowledgehandler}
                    selectedIntent={selectedIntent}
                    editMode={editMode}
                />
            )}
          </div>
          <div className="row">
            <Ratings filtersData={filtersData} isFullWidth/>
          </div>

          {/*<DashboardFiltersWithDateOnly hasFilterUpdated={hasFilterUpdated}/>*/}

          <UserFeedback filtersData={filtersData}/>
          {/* <MatchedDatatableComponent
            editKnowledgehandler={editKnowledgehandler}
            filtersData={filtersData}
          /> */}

          {/*<UnMatchedDatatableComponent*/}
          {/*  editKnowledgehandler={editKnowledgehandler}*/}
          {/*  filtersData={filtersData}*/}
          {/*/>*/}
          {/*  */}
        </div>
      </section>
    </>
  );
};

export default MatchedUnmatchedQuestions;
