import { Button, Modal, ModalFooter } from "react-bootstrap";
import ModalBody from "react-bootstrap/ModalBody";
import { useTranslation } from "react-i18next";
import { FormData } from "../IntegrationType";
import IntegrationModalHeader from "../Modal/IntegrationModalHeader";
import TextInput from "../../../components/UI/OldFormBuilder/TextInput";

type ApiSetup = {
  integrationType: string;
  closeModal: () => void;
  renderCopy: (text: string) => JSX.Element;
  data: ApiSetupData;
  handleIntegration: () => Promise<void>;
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
  handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    name: string,
  ) => void;
};

type ApiSetupData = {
  redirect_uri: string;
  target_link_uri: string;
  openid_connect_initiation_url: string;
};

const BlackboardApiModal = (props: ApiSetup) => {
  const { t } = useTranslation("translation");
  const windowObj: any = window;
  const blackboard_client_id =
    windowObj.ENVIRONMENT === "staging"
      ? "5021b3dc-0d1d-470f-9a2e-daa6ab9e36d1"
      : "b611ae31-8880-49f2-9bc5-b8f266164fec";

  return (
    <Modal
      className="modal-width"
      aria-labelledby="contained-modal-title-vcenter"
      show={true}
      onHide={() => {}}
      centered
    >
      <IntegrationModalHeader
        modalTitle="Enable Content Integration with Blackboard"
        closeModal={props?.closeModal}
      />

      <ModalBody className="integration-modal-body">
        <div className="tw-flex  tw-gap-4 canvas-manual-integration">
          <div className="tw-px-4 tw-py-4 w-90 left-content-wrap">
            <h2 className="mb-2">{t("Configure Rest API integration")}</h2>
            <ol>
              <li>{t("Log in to Blackboard as an admin")}.</li>
              <li>
                {t("Go to")} <strong>Administration Panel</strong>.
              </li>
              <li>
                {t("On the Administrator Panel, under")}{" "}
                <strong>Integrations</strong>, {t("select")}{" "}
                <strong>Rest API Integrations</strong>.
              </li>
              <li>
                {t("Click on button")} <strong>Create Integration</strong>.
              </li>
              <li>
                {t("In")} <strong>Application ID</strong>,{" "}
                {t("enter the value: ")}{" "}
                {props.renderCopy(blackboard_client_id)}.
              </li>
              <li>
                {t("In")} <strong>Available</strong>, {t("select")} <em>Yes</em>
                .
              </li>
              <li>
                {t("In")} <strong>End User Access</strong>, {t("select")}{" "}
                <em>Yes</em>.
              </li>
              <li>
                {t("Click on")} <strong>Submit</strong>,{" "}
                {t("to add the integration")}.
              </li>
            </ol>
          </div>

          <div className="whit-box tw-px-4 right-content-wrap w-10">
            <div>
              <h2 className="mb-2">
                {t("Required fields for Noodle Factory")}
              </h2>
              <p className="mb-0">
                {t(
                  "As you complete each step, please fill up following values to set up the integration on Noodle Factory",
                )}
              </p>
              <form>
                <div className="flex-one mt-2">
                  <h3 className="mb-2">{t("Blackboard LMS URL")}</h3>
                  <TextInput
                    inputType="text"
                    placeholder={t("Blackboard LMS URL")}
                    autoFocus={false}
                    id="client-id-1"
                    value={props.formData?.LMS_URL}
                    inputChange={(e, name) => props.handleInputChange(e, name)}
                    max={undefined}
                    min={undefined}
                    tabIndex={undefined}
                    label=""
                    description=""
                    inputClass=""
                    name="LMS_URL"
                    disabled={false}
                    onKeyPress={() => {}}
                    useTextArea={false}
                    errorMessage=""
                    ref={undefined}
                    onPaste={undefined}
                    onFocus={undefined}
                    onBlur={undefined}
                    onKeyUp={undefined}
                    spellCheck={undefined}
                    required={undefined}
                    showError={undefined}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </ModalBody>
      {/* {popUpState === "" && ( */}
      <ModalFooter>
        <Button variant="outline-primary" onClick={props.closeModal}>
          {t("Cancel")}
        </Button>
        <Button
          variant="primary"
          disabled={
            !props.formData.LMS_URL ||
            !props.formData.Client_ID ||
            !props.formData.Client_Secret
          }
          onClick={() => {
            props.handleIntegration();
            props.closeModal();
          }}
        >
          {t("Complete")}
        </Button>
      </ModalFooter>
      {/* )} */}
    </Modal>
  );
};
export default BlackboardApiModal;
