import React, { ReactElement, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getDocumentPreviewUrl } from "../../../store/knowledge/actions";
import DocEmpty from "../../../assets/New-images/doc-empty.svg";
import greenCheck from "../../../assets/New-images/green-check.svg";
import axios from "axios";
import { useTranslation } from "react-i18next";

const reactPdf = require("react-pdf/dist/esm/entry.webpack");
const { Document, Page } = reactPdf;

interface Props {
  filePath?: string;
  fileName?: string;
  fileType?: string;
  documentId?: string;
  qnaScreen?: boolean;
  setUrlMapForParent?: any;
  getNoOfPages?: any;
  multiDoc?: boolean;
  pageWidth?: number
}

export default function PDFViewer({
  filePath,
  fileName,
  fileType,
  documentId,
  qnaScreen,
  setUrlMapForParent,
  multiDoc,
  getNoOfPages,
  pageWidth
}: Props): ReactElement {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const [numPages, setNumPages] = useState(2);
  const [pageNumber, setPageNumber] = useState(1);
  const [urlMap, setUrlMap] = useState({
    name: "",
    url: "",
    type: "",
  });
  const [loading, setLoading] = useState(false);
  const [isDocumentLoading, setIsDocumentLoading] = useState(false);
  const [documentLoadError, setDocumentLoadError] = useState(false);
  let source = axios.CancelToken.source();

  useEffect(() => {
    getPreviewUrl(documentId);
    return () => {
      source.cancel('Cancelled from PDFviewer!')
    }
  }, [documentId]);

  const getPreviewUrl = async (documentId: string) => {
    let temp: any = {};
    let tempObjForParent: any = {};
    setDocumentLoadError(false);
    setLoading(true);
    try {
      let previewUrlResponse: any = await dispatch(
        getDocumentPreviewUrl(documentId, source)
      );

      if (
        previewUrlResponse.data &&
        previewUrlResponse.data.document.preview_url
      ) {
        const fileObject = {
          name: previewUrlResponse.data.document.name,
          url: previewUrlResponse.data.document.preview_url,
          type: previewUrlResponse.data.document.preview_url
            .split("?")[0]
            .split(".")[
            previewUrlResponse.data.document.preview_url.split(".").length - 1
          ],
        };
        tempObjForParent[documentId] = { ...fileObject };
        temp = { ...fileObject };
        source.cancel("File preview cancelled from source.");
      } else {
        tempObjForParent[documentId] = {
          name: previewUrlResponse.data.document.name,
          url: null,
          type: previewUrlResponse.data.document.name.split(".").pop(),
        };
        temp = {
          name: previewUrlResponse.data.document.name,
          url: null,
          type: previewUrlResponse.data.document.name.split(".").pop(),
        };
        setTimeout(() => {
          getPreviewUrl(documentId);
        }, 3000);
      }
      setUrlMapForParent && setUrlMapForParent(tempObjForParent);
      if(temp?.url){
        setLoading(false);
      }
      setUrlMap(temp);
    } catch (error) {
      setLoading(false);
      setDocumentLoadError(true);
    }
  };

  const onDocumentLoadSuccess = (data: any) => {
    getNoOfPages && getNoOfPages(data._pdfInfo.numPages || 0);
    setNumPages(data._pdfInfo.numPages);
  };

  const onDocumentLoadError = () => {
    setDocumentLoadError(true);
  };

  const changePage = (offset: any) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  return (
    <>
      <div className="pdf-viewer-wrapper">
        {urlMap &&
          urlMap.type === "pdf" &&
          !(urlMap.name.includes(".html") || urlMap.name.includes(".txt")) && (
            <React.Fragment>
              <Document
                file={urlMap && urlMap.url ? urlMap.url : ""}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={onDocumentLoadError}
                renderMode="svg"
              >
                <Page
                  pageNumber={pageNumber}
                  loading={t("Loading page...")}
                  renderAnnotationLayer={false}
                   width={pageWidth? pageWidth: 260}
              
                />
              </Document>
              <div className="pdf-viewer-page-controls">
                <button
                  type="button"
                  disabled={pageNumber <= 1}
                  onClick={previousPage}
                >
                  <svg width="6.707" height="12.414" viewBox="0 0 6.707 12.414">
                    <path
                      data-name="Icon feather-chevron-down"
                      d="M0,0,5.5,5.5,11,0"
                      transform="translate(6 0.707) rotate(90)"
                      fill="none"
                      stroke="rgba(9,35,230,0.5)"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1"
                    />
                  </svg>
                </button>
                <p className="page-number-indicator">
                  {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
                </p>
                <button
                  type="button"
                  disabled={pageNumber >= numPages}
                  onClick={nextPage}
                >
                  <svg width="6.707" height="12.414" viewBox="0 0 6.707 12.414">
                    <path
                      data-name="Icon feather-chevron-down"
                      d="M9,19l5.5-5.5L20,19"
                      transform="translate(19.707 -8.293) rotate(90)"
                      fill="none"
                      stroke="rgba(9,35,230,0.5)"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1"
                    />
                  </svg>
                </button>
              </div>
            </React.Fragment>
          )}

        {urlMap && !urlMap.url && multiDoc && !(urlMap.name.includes(".html") || urlMap.name.includes(".txt")) && (
          <div className="pdf-viewer-wrapper mt-2">
            <div className="empty-doc-msg d-flex align-items-center justify-content-center">
              <div className="text-center">
                <span className="doc-loader"></span>  
                <h3>{t("Preview Loading. Please wait")}</h3>
                {/* {t("Loading times varies depending on file size")} */}
              </div>
            </div>
          </div>
        )}

        {(urlMap && ["docx", "pptx"].includes(urlMap.type)) ||
          ((urlMap.name.includes(".html") || urlMap.name.includes(".txt")) &&
            multiDoc &&
            !loading && (
              <div className="pdf-viewer-wrapper mt-2">
                <div className="empty-doc-msg d-flex align-items-center justify-content-center">
                  <div className="text-center">
                    {/* {loading && <span className="doc-loader"></span>} */}
                    <h3>
                      {documentLoadError ||
                      !urlMap.url ||
                      urlMap.name.includes(".html") ||
                      urlMap.name.includes(".txt")
                        ? t("Preview is currently unavailable at the moment")
                        : t("Preview Loading. Please wait")}
                    </h3>
                    {/* {t("Loading times varies depending on file size")} */}
                  </div>
                </div>
              </div>
            ))}

        {urlMap &&
          !["pdf", "docx", "pptx"].includes(urlMap.type) &&
          !urlMap.name.includes(".html") &&
          !urlMap.name.includes(".txt") &&
          multiDoc &&
          !loading && (
            <div className="pdf-viewer-wrapper mt-2">
              <div className="empty-doc-msg d-flex align-items-center justify-content-center">
                <div className="text-center">
                  {/* {loading && <span className="doc-loader"></span>} */}
                  <h3>
                    {documentLoadError ||
                    !urlMap.url ||
                    urlMap.name.includes(".html") ||
                    urlMap.name.includes(".txt")
                      ? t("Preview is currently unavailable at the moment")
                      : t("Preview Loading. Please wait")}
                  </h3>
                  {/* {t("Loading times varies depending on file size")} */}
                </div>
              </div>
            </div>
          )}

        {urlMap &&
          !["pdf", "docx", "pptx"].includes(urlMap.type) &&
          !multiDoc &&
          !loading && (
            <div className="pdf-viewer-wrapper mt-2">
              <div className="empty-doc-msg d-flex align-items-center justify-content-center">
                <div className="text-center">
                  <h3>
                    {documentLoadError || !urlMap.url
                      ? t("Preview is currently unavailable at the moment")
                      : t(
                          "Preview is currently unavailable for this document type"
                        )}
                  </h3>
                  <figure className="my-3">
                    <img src={DocEmpty} alt="" />
                  </figure>
                  <h3>
                    {t(
                      "We have taken note of this issue and is working on fixing this."
                    )}
                    <br />
                    {t("We seek for your understanding.")}
                  </h3>
                </div>
              </div>
            </div>
          )}
      </div>

      {
        ((loading && !documentLoadError) || (!loading && !documentLoadError && urlMap && qnaScreen)) && (

        <div className="doc-processing d-flex flex-wrap align-items-center">
          <div className="col-status">
            {loading && !documentLoadError && (
              <span className="doc-loader"></span>
            )}
            {!loading && !documentLoadError && urlMap && qnaScreen && (
              <span className="doc-success">
                <img src={greenCheck} alt="" />
              </span>
            )}
          </div>
          <div className="col-msg pl-3">
            {loading
              ? t("Walter is still reading through your document...")
              : !loading && urlMap
              ? qnaScreen
                ? t("Your document is ready for processing!")
                : ""
              : ""}
          </div>
        </div>
        )
      }
    </>
  );
}
