import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import close from "../../../assets/images/close-blk.svg";
import setting from "../../../assets/images/cog.svg";
import {
  deleteAllNotifications,
  deleteNotification,
  getAllNotifications,
  markAllAsRead,
  readNotification,
} from "../../../store/common/actions";
import { NotificationInterface } from "../../../store/common/reducer";
import { getNotificationsSummary } from "../../../store/common/selector";
import NotificationListItem from "../NotificationListItem/NotificationListItem";
import CommonService from "../../../services/CommonService";
import { HelperService } from "../../../services/helper.service";

import { useTranslation } from "react-i18next";

const helperService = new HelperService();
const commonService = new CommonService();

interface Props {
  toggleNotificationsView: (e: any) => void;
}

export const Notifications = (props: Props) => {
  const { t } = useTranslation("translation");
  const notifications = useSelector(getNotificationsSummary);
  const history = useHistory();
  const dispatch = useDispatch();
  const [defaultNotifications, setdefaultNotifications] = useState([]);
  const [snackbarNoti, setSnackbarNoti] = useState([]);
  const windowObj: any = window;
  const isMgsFromReactApp = process.env.REACT_APP_CISCO === "mgs";

  const getSnackbarNoti = async () => {
    const res = await commonService.getSnackbarNotification();
    if (res.status === 200) {
      setSnackbarNoti(res?.data?.data.notifications);
    }
  };
  useEffect(() => {
    dispatch(getAllNotifications());
    getSnackbarNoti();
    const interval = setInterval(async () => {
      getSnackbarNoti();
      // dispatch(getAllNotifications())
    }, 20000);
    // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    return () => windowObj.clearInterval(interval);
  }, []);

  useEffect(() => {
    if (snackbarNoti.length === 1) {
      const noti = snackbarNoti.find((n) => n.shows_portal_snackbar === true);
      if (noti) {
        helperService.showClickableToaster(
          noti?.title + t(". Click here to view"),
          () => {
            windowObj.open(noti?.link);
          }
        );
        commonService.setNotificationSnackbar(noti?._id, false);
      }
    }
    if (snackbarNoti.length > 1) {
      helperService.showCombineToast(
        t("You have {{count}} new notifications. Click to view", {
          count: snackbarNoti?.length,
        }),
        (e: any) => {
          props.toggleNotificationsView(e);
        }
      );
      snackbarNoti.map(noti => commonService.setNotificationSnackbar(noti?._id, false))
    }
  }, [snackbarNoti.reduce((acc, curr) => acc + '' + curr._id, '')]);

  useEffect(() => {
    setdefaultNotifications(
      notifications.notifications ? [...notifications.notifications] : []
    );
  }, [notifications]);

  const readNotificationHandler = (e: any) => {
    e.preventDefault();
    dispatch(readNotification(e.target.dataset.id, e.target.dataset.read));
  };

  const deleteNotificationHandler = (e: any, id: string) => {
    e.preventDefault();
    dispatch(deleteNotification(id));
  };

  const markAllAsReadNotificationsHandler = (e: any) => {
    e.preventDefault();
    dispatch(markAllAsRead());
  };

  const deleteAllNotificationsHandler = async (e: any) => {
    e.preventDefault();
    await dispatch(deleteAllNotifications());
    dispatch(getAllNotifications());
  };

  const seeAllNotificationsHandler = (e: any, key: string) => {
    e.preventDefault();
    props.toggleNotificationsView(e);
    history.push("/notification", {
      activetab: key,
    });
  };

  return (
    <React.Fragment>
      <div className="notify-header">
        <h2 className={isMgsFromReactApp ? "justify-content-between" : ""}>
          {t("Notifications")}
          {
            !isMgsFromReactApp && (
              <button
                className="btn ml-auto"
                onClick={(e) => seeAllNotificationsHandler(e, "preference")}
              >
                <Image src={setting} />
              </button>
            )
          }
          <button className="btn" onClick={props.toggleNotificationsView}>
            <Image src={close} />
          </button>
        </h2>
        <div>
          {defaultNotifications && defaultNotifications.length > 0 && (
            <button className="btn" onClick={markAllAsReadNotificationsHandler}>
              {t("Mark All As Read")}
            </button>
          )}
          <button
            className="btn"
            onClick={(e) => seeAllNotificationsHandler(e, "notifications")}
          >
            {t("See All")}
          </button>
          {defaultNotifications && defaultNotifications.length > 0 && (
            <button
              className="btn clear-all"
              onClick={deleteAllNotificationsHandler}
            >
              {t("Clear All")}
            </button>
          )}
        </div>
      </div>
      <div className="notify-scrol">
        {defaultNotifications &&
          defaultNotifications.length > 0 &&
          defaultNotifications.map(
            (notification: NotificationInterface, index: number) => {
              return (
                <NotificationListItem
                  key={"NotificationSideBarItem-" + index}
                  isEdit={false}
                  notification={notification}
                  readNotificationHandler={readNotificationHandler}
                  deleteNotificationHandler={deleteNotificationHandler}
                />
              );
            }
          )}
      </div>
      <button
        className="btn send-notification"
        onClick={(e) => seeAllNotificationsHandler(e, "create")}
      >
        {t("Send Notifications")}
      </button>
    </React.Fragment>
  );
};
