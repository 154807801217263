import { subDays } from 'date-fns'
import moment from 'moment'
import React, { ReactElement, useEffect, useState } from 'react'
import { ProgressBar } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import angry from '../../../../../assets/images/angry.svg'
import extremelyhappy from '../../../../../assets/images/extremely-happy.svg'
import happy from '../../../../../assets/images/happy.svg'
import neutral from '../../../../../assets/images/neutral.svg'
import sad from '../../../../../assets/images/sad.svg'
import DateRangePickerComponent from '../../../../../components/UI/DateRangePicker/DateRangePicker'
import { getRatingsSummary } from '../../../../../store/dashboard/actions'
import { getRatingsData } from '../../../../../store/dashboard/selector'
import ExportReportShared from '../../../../../components/UI/ExportReportShared/ExportReportShared'
import cx from 'classnames';

import { useTranslation } from "react-i18next";

interface Props {}

export default function Ratings(props: any): ReactElement {
  const { t } = useTranslation('translation');
  const [loading, setLoading] = useState(false)
  const [filtersData, setFiltersData] = useState({
    filter_from_date: null,
    filter_to_date: null,
  })
  const [customDatePickerValue, setCustomDatePickerValue] = useState(false)
  const [ratingsData, setRatingsData] = useState(null)
  const [datePickerClicked, setDatePickerClicked] = useState(false)
  const [dateParameters, setDateParameters] = useState({
    value: '30',
    label: t('Last 30 days'),
  })
  const dispatch = useDispatch()

  useEffect(() => {
    if (
      loading ||
      !filtersData.filter_from_date ||
      !filtersData.filter_to_date
    ) {
      return
    }

    const fun = async () => {
      try {
        await setLoading(true)
        const reportsData: any = await dispatch(getRatingsSummary(filtersData))
        await setRatingsData(reportsData)
        await setLoading(false)
      } catch (error: any) {
        await setRatingsData({ ...ratingsData, ...error })
        await setLoading(false)
      }
    }
    fun()
    setDatePickerClicked(false)
  }, [filtersData])

  useEffect(() => {
    if (
      loading ||
      !props.filtersData.filter_from_date ||
      !props.filtersData.filter_to_date
    ) {
      return
    }

    const fn = async () => {
      const filter = {
        ...filtersData,
        ...props.filtersData,
      }
      if (props.filtersData.dateParameters) {
        await setDateParameters(props.filtersData.dateParameters)
      }
      await setFiltersData(filter)
    }
    fn()
    return () => {}
  }, [props.filtersData])

  useEffect(() => {
    const fn = async () => {
      const filter = {
        ...filtersData,
        filter_from_date:
          dateParameters.value && dateParameters.value !== 'custom'
            ? moment(subDays(new Date(), +dateParameters.value)).format(
                'YYYY-MM-DD'
              )
            : null,
        filter_to_date:
          dateParameters.value && dateParameters.value !== 'custom'
            ? moment(new Date()).format('YYYY-MM-DD')
            : null,
      }
      setCustomDatePickerValue(dateParameters.value === 'custom')
      if (dateParameters.value !== 'custom') {
        await setFiltersData(filter)
      }
    }

    fn()
    return () => {
      // cleanup
    }
  }, [dateParameters, dispatch])

  const dateChangehandler = async (e: any) => {
    const filter = {
      ...filtersData,
      ...e,
    }
    await setFiltersData(filter)
  }

  const closeDateRangeHandler = () => {
    setCustomDatePickerValue(false)
  }

  const getImage = (id: number) => {
    let img = angry
    switch (id) {
      case 1:
        img = angry
        break
      case 2:
        img = sad
        break
      case 3:
        img = neutral
        break
      case 4:
        img = happy
        break
      case 5:
        img = extremelyhappy
        break
      default:
        img = neutral
        break
    }
    return img
  }
  const getRatingComponent = (id: number) => {
    const ratingData = ratingsData.ratingSummary.rows.find(
      (row: any) => row.rating === id
    )
    return (
      <>
        <img src={getImage(id)} className='rate-smily' alt={t('rating')} />
        <ProgressBar now={ratingData ? ratingData.percentage.toFixed(2) : 0} />
        <span>{ratingData ? ratingData.percentage.toFixed(2) : 0}%</span>
      </>
    )
  }

  return (
    <div className={cx({'col-lg-6': !props.isFullWidth, 'col-lg-12': props.isFullWidth})}>
      <div className='graph-box px-0 py-3'>
        <div className='filters d-flex flex-wrap justify-content-between align-items-center border-0'>
          <h3 className='mb-0'>{t("Virtual Assistant Rating")}</h3>
          <div className='d-flex flex-wrap sugested-next-ques'>
            {/* <Select
              className='select-sort select-target'
              classNamePrefix='select-target-prefix'
              components={{
                IndicatorSeparator: () => null,
              }}
              value={dateParameters ? { ...dateParameters } : null}
              onChange={(e: any) => {
                setDatePickerClicked(e.value === 'custom')
                setDateParameters(e)
              }}
              options={[
                { value: '60', label: t('Last 60 days') },
                { value: '30', label: t('Last 30 days') },
                { value: '7', label: t('Last 7 days') },
                { value: 'custom', label: t('Custom') },
              ]}
            /> */}

            <ExportReportShared
              name={'rating-summary'}
              filtersData={filtersData}
              removedKeyFromObject="connection_ids"
            />
            {/* <button className='btn p-0 ml-3'>
              <img src={dotImg} alt='more options' />
            </button> */}
          </div>
        </div>
        {loading && (
          <div className='d-flex justify-content-center'>
            <div className='spinner-border spinner-border-lg text-primary'></div>
          </div>
        )}
        {customDatePickerValue && datePickerClicked && (
          <DateRangePickerComponent
            dateValue={{
              startDate:
                filtersData && filtersData.filter_from_date
                  ? new Date(filtersData.filter_from_date)
                  : new Date(),
              endDate:
                filtersData && filtersData.filter_to_date
                  ? new Date(filtersData.filter_to_date)
                  : new Date(),
            }}
            onDateChange={dateChangehandler}
            onCloseDateRangeHandler={closeDateRangeHandler}
          />
        )}

        {/* ------rating bar---------- */}
        {ratingsData && !loading && (
          <div className='px-4'>
            <div className='row align-items-center'>
              <div className='col-4 text-center'>
                <div className='overall-rate'>
                  {ratingsData.ratingSummary.summary.rating_average
                    ? ratingsData?.ratingSummary?.summary?.rating_average.toFixed(
                        2
                      )
                    : 0}
                </div>

                {ratingsData?.ratingSummary?.summary?.rating_average && (
                  <figure>
                    <img
                      src={getImage(
                        Math.round(
                          ratingsData?.ratingSummary?.summary.rating_average
                        )
                      )}
                      alt={t('rating')}
                    />
                  </figure>
                )}
              </div>
              <div className='col-8'>
                {Array.from(Array(5)).map((k: any, index: number) => {
                  return (
                    <div
                      className='my-3 d-flex align-items-center'
                      key={index + '_rating'}
                    >
                      {getRatingComponent(index + 1)}
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
