import React from "react";
import dotImg from "../../../assets/images/dot-vertical.svg";
import { Dropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { exportReportUsingDateAndType } from "../../../store/dashboard/actions";

import { useTranslation } from "react-i18next";

export default function ExportReportShared({
  name,
  exportName,
  filtersData,
  removedKeyFromObject,
  downloadChart,
  canvasExport,
  exportFont,
  exportButtonText
}: {
  name: string;
  exportName?: string;
  filtersData?: any;
  removedKeyFromObject?: string;
  downloadChart?: (e: any) => void;
  canvasExport?: () => void;
  exportFont?: boolean;
  exportButtonText?: string;
}) {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const exportReport = async (name: string, type: string) => {
    //
    try {
      const cloned = {
        ...filtersData,
      };
      if (removedKeyFromObject) {
        removedKeyFromObject.split(",").forEach((key: any) => {
          delete cloned[key.trim()];
        });
      }
      const blob: any = await dispatch(
        exportReportUsingDateAndType(name, type, cloned)
      );

      if (!blob || !blob.size) {
        return;
      }

      let extension =
        blob &&
        blob.type ==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ? "xlsx"
          : "csv";
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${exportName ? exportName : name}.` + extension
      );
      // 3. Append to html page
      document.body.appendChild(link);
      // 4. Force download
      link.click();
      // 5. Clean up and remove the link
      link.parentNode.removeChild(link);
    } catch (error) {}
  };

  return (
    // <></>
    <Dropdown className="export-report p-0">
      <Dropdown.Toggle id="dropdown-basic">
        {exportButtonText ? (
          <div className="clr-primary font-600">{exportButtonText}</div>
        ) : exportFont ? (
          <div className="clr-primary font-600">{t("Export")}</div>
        ) : (
          <img src={dotImg} alt="" />
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {downloadChart && (
          <Dropdown.Item onClick={downloadChart}>
            {t("Download Chart")}
          </Dropdown.Item>
        )}
        <Dropdown.Item onClick={() => exportReport(name, "csv")}>
          {t("Export as csv")}
        </Dropdown.Item>
        <Dropdown.Item onClick={() => exportReport(name, "xlsx")}>
          {t("Export as xlsx")}
        </Dropdown.Item>
        {canvasExport && (
          <Dropdown.Item onClick={canvasExport}>
            {t("Export results to")}
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}
