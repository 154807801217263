import React, { ReactElement } from 'react'

import { useTranslation } from "react-i18next";

interface Props {
  changeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void
  isInputDisabled: any
}

export default function ShowHideChatInput(props: Props): ReactElement {
  const { t } = useTranslation('translation');
  return (
    <React.Fragment>
      <h2 className='mb-24 font-600'>{t("Show/Hide Chat Input")}</h2>
      <div className='custom-control custom-radio'>
        <input
          type='radio'
          name='is_input_disabled'
          value={'false'}
          checked={props.isInputDisabled === false}
          onChange={props.changeHandler}
          className='custom-control-input'
          id='custom-radio1'
        />
        <label className='custom-control-label' htmlFor='custom-radio1'>
          {t("Show input (user will be allowed to type after the bot responses in this interaction)")}
        </label>
      </div>
      <div className='custom-control custom-radio'>
        <input
          type='radio'
          name='is_input_disabled'
          value={'true'}
          checked={props.isInputDisabled === true}
          onChange={props.changeHandler}
          className='custom-control-input'
          id='custom-radio2'
        />
        <label className='custom-control-label' htmlFor='custom-radio2'>
          {t("Hide input (user will NOT be allowed to type after the bot responses in this interaction)")}
        </label>
      </div>
      <div className='custom-control custom-radio'>
        <input
          type='radio'
          name='is_input_disabled'
          value={'null'}
          checked={props.isInputDisabled === null}
          onChange={props.changeHandler}
          className='custom-control-input'
          id='custom-radio3'
        />
        <label className='custom-control-label' htmlFor='custom-radio3'>
          {t("None (use chat input setting from bot)")}
        </label>
      </div>
    </React.Fragment>
  )
}
