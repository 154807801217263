import React, { useState, useEffect } from 'react'
import { Button, Image, Modal, ModalFooter } from 'react-bootstrap'
import ModalBody from 'react-bootstrap/ModalBody'
import ModalHeader from 'react-bootstrap/ModalHeader'
import ModalTitle from 'react-bootstrap/ModalTitle'
import CreatableSelect from 'react-select/creatable'
import closeimg from '../../../assets/images/close-blk.svg'
import Tags from '../Tags/Tags'

import { useTranslation } from "react-i18next";

export default function AddTags(props: any) {
  const { t } = useTranslation('translation');
  const [tags, setTags] = useState([])
  const [selectedTags, setSelectedTags] = useState([])
  useEffect(() => {
    if (!props.show) {
      return
    }
    setTags([...props.selectedTags])
    setSelectedTags([...props.selectedTags])
  }, [props.selectedTags, props.show ])

  const handleChange = (newValue: any, actionMeta: any) => {
    // console.group('Value Changed')
    // console.log(newValue)
    // console.log(`action: ${actionMeta.action}`)
    // console.groupEnd()
    setTags([...newValue])
    setSelectedTags([...newValue])
  }

  const removeTagsHandler = (e: any, value: any) => {
    const oldTags = [...tags]
    const hasTagPresent = oldTags.findIndex(
      (tag) => tag.value.toLowerCase() === value.toLowerCase()
    )
    if (hasTagPresent >= 0) {
      oldTags.splice(hasTagPresent, 1)
      setTags([...oldTags])
      setSelectedTags([...oldTags])
    }
  }

  return (
    <Modal
      className="add-tag-modal"
      show={props.show}
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <ModalHeader>
        <ModalTitle id='contained-modal-title-vcenter'>
        </ModalTitle>
      </ModalHeader>
      <h2 className='mb-3 pb-4 text-center border-bottom'>{t("Add Keywords")}</h2>
      <ModalBody>
        <div className='knowledge-tags d-flex flex-wrap align-items-center'>
          {tags.length > 0 &&
            tags.map((value, index) => {
              return (
                <Tags
                  key={value + '_' + index}
                  value={value.value}
                  label={value.label}
                  removeTagHandler={removeTagsHandler}
                />
              )
            })}
        </div>
        <div className='changepurpose-select-tag'>
          <CreatableSelect
            isMulti
            // defaultMenuIsOpen
            controlShouldRenderValue={false}
            isClearable={false}
            className='add-tag-select'
            classNamePrefix='add-tags'
            onChange={handleChange}
            // onInputChange={handleInputChange}
            defaultValue={selectedTags}
            value={selectedTags}
            options={[ ...tags ]}
            placeholder={t('Type tag name')}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
          />
        </div>
      </ModalBody>
      <ModalFooter className='border-0 justify-content-center px-3 mb-3 mx-4 flex-nowrap'>
        <button
          className='btn-primary btn mx-2'
          onClick={(e) => props.confirmhandler(e, tags)}
          disabled={tags.length === 0}
        >
          {t('Done')}
        </button>
        <button className='btn-outline-primary btn mx-2' onClick={props.onHide}>
          {' '}
          {t('Cancel')}
        </button>
      </ModalFooter>
    </Modal>
  )
}
