import React, { memo } from 'react'
import { ResponsiveBar } from '@nivo/bar'

interface Props {}

export default memo(function BarCompact(props: any) {
  const barColor = '#0095ff'
  const data = [
    { x: '2021-01-01', t: 90, v: 100, v1: 0.2, v2: 1.2 },
    { x: '2021-01-02', t: 80, v: 90, v1: 0.2, v2: 1.2 },
    { x: '2021-01-03', t: 70, v: 80, v1: 0.2, v2: 1.2 },
    { x: '2021-01-04', t: 60, v: 70, v1: 0.2, v2: 1.2 },
    { x: '2021-01-05', t: 10, v: 30, v1: 0.2, v2: 1.2 },
    { x: '2021-01-06', t: 14, v: 40, v1: 0.2, v2: 1.2 },
    { x: '2021-01-07', t: 20, v: 50, v1: 0.2, v2: 1.2 },
    { x: '2021-01-08', t: 30, v: 60, v1: 0.2, v2: 1.2 },
    { x: '2021-01-09', t: 45, v: 80, v1: 0.2, v2: 1.2 },
    { x: '2021-01-10', t: 10, v: 26, v1: 0.2, v2: 1.2 },
    { x: '2021-01-11', t: 15, v: 26, v1: 0.2, v2: 1.2 },
    { x: '2021-01-12', t: 20, v: 26, v1: 0.2, v2: 1.2 },
    { x: '2021-01-13', t: 25, v: 26, v1: 0.2, v2: 1.2 },
    { x: '2021-01-14', t: 60, v: 26, v1: 0.2, v2: 1.2 },
    { x: '2021-01-15', t: 30, v: 26, v1: 0.2, v2: 1.2 },
    { x: '2021-01-16', t: 15, v: 26, v1: 0.2, v2: 1.2 },
    { x: '2021-02-01', t: 25, v: 25, v1: 0.2, v2: 1.2 },
    { x: '2021-03-01', t: 40, v: 10, v1: 0.2, v2: 1.2 },
    { x: '2021-04-01', t: 45, v: 5, v1: 0.8, v2: 1.3 },
    { x: '2021-05-01', t: 55, v: 15, v1: 0.8, v2: 1.3 },
    { x: '2021-06-01', t: 65, v: 25, v1: 0.8, v2: 1.3 },
  ]

  const CustomBarComponent = ({
    x,
    y,
    width,
    height
  }: any) => (
    <g transform={`translate(${x}, ${y})`}>
      <rect
        width={width}
        height={height}
        rx='1'
        ry='1'
        fill={barColor}
        strokeWidth='0'
        stroke='#0095ff'
      ></rect>
    </g>
  )

  return (
    <ResponsiveBar
      enableGridX={false}
      enableGridY={false}
      axisLeft={null}
      axisBottom={null}
      barComponent={CustomBarComponent}
      data={props.data ? props.data : data}
      keys={props.data ? [props.keys] : ['v']}
      padding={0.6}
      margin={{
        top: 0,
        right: 0,
        bottom: 10,
        left: 0,
      }}
      indexBy={props.data ? props.indexBy : 'x'}
      enableLabel={true}
      colors={[barColor]}
      // borderRadius={10}
      layers={['grid', 'axes', 'bars', 'markers', 'legends']}
    />
  )
})
