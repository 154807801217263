import { t } from "i18next";
import React, { useState, useRef, useEffect } from "react";

import Skeleton from "react-loading-skeleton";
import { copyStringToClipboard } from "../../../utils/appUtils";
import HeaderTabs from "../OldPortalComponents/HeaderTabs";
import IconLabelButton from "../OldPortalComponents/IconLabelButton";
import InfoBlock from "../OldPortalComponents/InfoBlock";
import QuizModal from "../QuizModal";
import idealdoc from "../../../assets/images/ideal-doc.png";

import "./style.scss";

const QuizDocumentPreview = ({
  documentContent,
  documentType,
  internalLoading,
  isLoading,
  getQuizDocumentsFromContent,
  extractTables,
  extractedTablesData,
  extractionStatus,
  hideExtractBtn,
  hideTable,
  createQuizGenerateQna,
  hideTableInfoBlock,
}: any) => {
  const [openIdealDocumentModal, setOpenIdealDocumentModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [extractedContentMessage, setExtractedContentMessage] = useState("");
  const [showCopiedStatus, setShowCopiedStatus] = useState(false);
  const editableContentRef = useRef(null);

  const tabList = [
    {
      label: "Text",
    },
    {
      label: "Table",
    },
  ];

  const onlyText = [
    {
      label: "Text",
    },
  ];

  useEffect(() => {
    if (documentType === "processed") {
      setExtractedContentMessage("Questions were extracted successfully. ");
    } else if (documentType === "original" && documentContent) {
      setExtractedContentMessage("We could not extract any questions. ");
    } else if (documentType === "original" && !documentContent) {
      setExtractedContentMessage(
        "We could not process the content. Please try with another document. "
      );
    }
  }, []);

  useEffect(() => {
    if (createQuizGenerateQna === true) {
      getQuizDocumentsFromContent();
    }
  }, [createQuizGenerateQna]);

  const copyTableToClipboard = (tableId: any, data: any) => {
    setShowCopiedStatus(true);
    setTimeout(() => {
      setShowCopiedStatus(false);
    }, 2000);
    let table: any = document.getElementById(tableId);
    if (table) {
      copyStringToClipboard(table.outerHTML);
    } else {
      copyStringToClipboard("could not copy table");
    }
  };

  const convertToHTMLTable = (csv: any, tableId: any) => {
    // Remove trailing newlines
    let cleanedCSV: any = csv.replace(/\n*$/, "");
    // Split the string to an array of strings by newline
    let rowStringArr: any = cleanedCSV.split("\n");

    let table = (
      <table id={tableId} className="csv-converted-table">
        <tbody>
          {rowStringArr.map((rowString: any) => {
            let rowArr = rowString.split(" ,");
            rowArr.splice(-1);
            return (
              <tr>
                {rowArr.map((cellData: any) => (
                  <td>{cellData}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
    return table;
  };

  return (
    <div className="extract-quiz-info">
      <HeaderTabs
        selectedIndex={selectedIndex}
        onChange={(index: any) => setSelectedIndex(index)}
        tabList={hideTable ? onlyText : tabList}
      />
      {selectedIndex === 0 && (
        <div className="text-preview-content">
          {!hideExtractBtn && (
            <InfoBlock>
              <span className="highlighted-content">
                {extractedContentMessage}
              </span>
              {documentContent && (
                <React.Fragment>
                  {t("Edit the extracted content below according to our")}{" "}
                  <a
                    href="javascrip:void(0);"
                    onClick={() => {
                      setOpenIdealDocumentModal(true);
                    }}
                  >
                    {t("ideal document example")}
                  </a>{" "}
                  {t(
                    "for best results. You can click on “Extract Questions Again” after editing."
                  )}
                </React.Fragment>
              )}
              {!documentContent && (
                <a
                  href="javascrip:void(0);"
                  onClick={() => {
                    setOpenIdealDocumentModal(true);
                  }}
                >
                  {t("Check out how you can format your document.")}
                </a>
              )}
            </InfoBlock>
          )}

          <div
            ref={editableContentRef}
            contentEditable="true"
            className="quiz-info-extract"
            dangerouslySetInnerHTML={{
              __html: `<pre class='pre-quiz-info-extract'>${documentContent}</pre>`,
            }}
          ></div>
          {!hideExtractBtn && !(internalLoading || isLoading) && (
            <IconLabelButton
              isDisabled={internalLoading || isLoading}
              onClick={(e: any) => {
                getQuizDocumentsFromContent(e);
              }}
              buttonLabel={t("Extract Questions Again")}
            />
          )}
          {(internalLoading || isLoading) && (
            <div className="d-flex align-items-center">
              <span className="doc-loader spinner-width-20 mr-3"></span>
              {t("Extracting Questions")}
            </div>
          )}

          <QuizModal
            openModal={openIdealDocumentModal}
            onRequestClose={() => {
              setOpenIdealDocumentModal(false);
            }}
            noModal1100
          >
            <img
              className="img-responsive"
              src={idealdoc}
              alt="Ideal document description"
            />
          </QuizModal>
        </div>
      )}
      {selectedIndex === 1 && (
        <div className="table-preview-content">
          {extractedTablesData.length === 0 &&
            extractionStatus !== "IN_PROGRESS" && (
              <div className="initial-screen">
                {t(
                  "Click the button below to get the tables from the uploaded question bank."
                )}
                <IconLabelButton
                  buttonLabel="Extract Tables"
                  onClick={extractTables}
                />
                <p className="highlighted-text">
                  {t("Note: Table extraction works best for PDF documents.")}
                </p>
              </div>
            )}
          {extractedTablesData.length === 0 &&
            extractionStatus === "IN_PROGRESS" && (
              <Skeleton
                className="data-table-skeleton"
                count={4}
                height={100}
              />
            )}
          {extractedTablesData.length > 1 && (
            <div className="table-list">
              {!hideTableInfoBlock && (
                <InfoBlock>
                  {t(
                    "You can select and copy any table and paste it into the quiz question editor on the right."
                  )}
                </InfoBlock>
              )}
              {extractedTablesData.map((tableCSV: any, index: any) => (
                <div
                  className="table-miniature"
                  id={`table-miniature-${(index + 1).toString()}`}
                >
                  {/* <div
                    className="table-miniature-overlay"
                    onClick={() =>
                      copyTableToClipboard(
                        `csv-table-${(index + 1).toString()}`,
                        convertToHTMLTable(tableCSV)
                      )
                    }
                  >
                    {!showCopiedStatus && (
                      <React.Fragment>
                        <i className="icon-copy" />
                        <p>Copy</p>
                      </React.Fragment>
                    )}
                    {showCopiedStatus && <p>Copied!</p>}
                  </div> */}
                  {convertToHTMLTable(
                    tableCSV,
                    `csv-table-${(index + 1).toString()}`
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default QuizDocumentPreview;
