import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import DashboardService from "../../services/DashboardService";
import { Image } from "react-bootstrap";
import ThumpUp from "../../assets/images/thum-up-blue.svg";
import EditIcon from "../../assets/images/edit.svg";

import Select from "react-select";

const service = new DashboardService();
const SORTS = [
  { value: "recent", label: "Most Recent" },
  { value: "old", label: "Oldest" },
  { value: "res", label: "Most Responses" },
];
// const helperService = new HelperService();

export const LearnerQuestionDetails = (row: any) => {
  const { t } = useTranslation("translation");
  const [sortBy, setSortBy] = useState(SORTS[0]);

  const [details, setDetails] = useState<any>(null);
  // const {_id} = row
  const fetchDiscussionDetails = async (id: any, filter: string) => {
    const res = await service.getDiscussionDetails(id, filter);
    if (res.status === 200) {
      setDetails(res.data.data);
    }
  };

  useEffect(() => {
    row?.data?._id && fetchDiscussionDetails(row?.data?._id, "");
  }, [row?.data?._id]);

  useEffect(() => {
    let filter = "";
    switch (sortBy?.value) {
      case "recent":
        filter = filter + `sort_field=created_at&sort_order=dsc`;
        break;
      case "old":
        filter = filter + `sort_field=created_at&sort_order=asc`;
        break;
      case "res":
        filter = filter + `sort_field=thumbsup_count&sort_order=dsc`;
        break;
      default:
    }
    fetchDiscussionDetails(row?.data?._id, filter);
  }, [sortBy?.value]);

  if (details?.posts.length === 0) {
    return <div className="p-4 post-item text-center">{t("No posts yet")}</div>;
  }

  const unApprovalPosts = details?.posts.filter((p: any) => p?.approval_status !== 'accepted');

  const renderApprovedPosts = () => {
    const approvalPosts = details?.posts.filter((p: any) => p?.approval_status === 'accepted');
    if(approvalPosts?.length === 0 || !approvalPosts) return null

    return (
      <div>
        <div className="clr-grey"> {t('Answers Added to Knowledge Base')}</div>
        <div>
        {approvalPosts &&
          approvalPosts.map((p: any) => (
            <div
              className="d-flex py-2 align-items-center post-item"
              key={p?._id}
            >
              <Image src={p?.author_profile_image} className="author-ava" />
              <div className="flex-1 px-3">
                <b>{p.text}</b>
              </div>
              <div
                className="px-4 font-16 clr-blue"
                role="button"
                onClick={() => row?.setPost && row?.setPost([p])}
              >
                <Image className="px-1" src={EditIcon} />
                {t("Edit")}
              </div>
            </div>
          ))}
      </div>
      </div>
    )
  }
  return (
    <div className="p-2 pt-3">
      {renderApprovedPosts()}
      <div className="pt-3 d-flex justify-content-between align-items-center">
        <span className="clr-grey"> {t("Learners’ Answers")} </span>
        <div className="d-flex justify-content-between align-items-center">
          <span className="mx-3"> {t("Sort by")} </span>
          <Select
            className="select-sort select-target select-blue shadow-none radius-8 mr-2"
            classNamePrefix="select-target-prefix"
            isSearchable={false}
            components={{
              IndicatorSeparator: () => null,
            }}
            options={SORTS}
            onChange={(newValue: any) => {
              setSortBy(newValue);
            }}
            value={sortBy}
          />
        </div>
      </div>
      <div>
        {unApprovalPosts &&
          unApprovalPosts.map((p: any) => (
            <div
              className="d-flex py-2 align-items-center post-item"
              key={p?._id}
            >
              <div className="px-3">
                <Image src={ThumpUp} width={16} className="my-2" />
                <div>{p?.thumbsup_count}</div>
              </div>
              <Image src={p?.author_profile_image} className="author-ava" />
              <div className="flex-1 px-3">
                <b>{p.text}</b>
                <div className="pt-2">
                  {p?.author_nickname ||
                    p?.author_first_name + " " + p?.author_last_name}
                </div>
              </div>
              <div
                className="px-4 font-16 clr-blue"
                role="button"
                onClick={() => row?.setPost && row?.setPost([p])}
              >
                <span className="px-1">+</span>
                {t("Add")}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default LearnerQuestionDetails;
