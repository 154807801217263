import { Auth0Client } from '@auth0/auth0-spa-js';

const windowObj: any = window;
const Auth0 = new Auth0Client({
  domain: windowObj.SSO_DOMAIN,
  client_id: windowObj.SSO_CLIENTID,
  audience: windowObj.SSO_AUDIENCE,
  useRefreshTokens: true
});

export default Auth0;