import React from 'react'

import './index.scss'

interface Props {}

const StickerPicker = ({
  stickerSets,
  onSetChange,
  onSelect,
  stickers,
}: any) => {
  return (
    <div className='sticker-picker'>
      <div className='sticker-set-tray'>
        {stickerSets.map((set: any, index: number) => (
          <button
            className='sticker-set-thumbnail'
            onClick={() => onSetChange(set.name)}
            key = {set.name + '__' + index}
          >
            <img
              className='sticker-set-thumbnail-img'
              src={set.thumbnail}
              alt={set.display_name}
            />
          </button>
        ))}
      </div>
      <div className='sticker-picker-scroll'>
        {stickers.map((sticker: any, index: number) => (
          <button
            className='sticker-item'
            onClick={() => onSelect(sticker.url)}
            key = {'stickerurl__' + index}
          >
            <img className='sticker-img' src={sticker.url} alt={sticker.url} />
          </button>
        ))}
      </div>
    </div>
  )
}

export default StickerPicker
