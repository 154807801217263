import React, { useEffect, useRef, useState } from "react";
import { Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import closeimg from "../../../../assets/images/close-red.svg";
import AutoTextArea from "../../../../components/UI/AutoResizeTextArea/AutoResizeTextArea";
import { localUserQuestionUpdate } from "../../../../store/questions/actions";
import { getIntent } from "../../../../store/questions/selector";

import { useTranslation } from "react-i18next";

interface Props {}

export const UserSaysTab = (props: any) => {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const ref = useRef(null);
  const intent = useSelector(getIntent);
  const [userSaysQuestion, setUserSaysQuestions] = useState("");
  const [userSaysInitialValue, setUserSaysInitialValue] = useState("");
  const [userSaysQuestionsList, setUserSaysQuestionsList] = useState([]);
  useEffect(() => {
    if (intent && intent.questions && intent.questions.length > 0) {
      setUserSaysQuestionsList(intent.questions);
    }
  }, [intent]);

  useEffect(() => {
    if (props.hasAdded) {
      setUserSaysQuestions("");
    }
  }, [props.hasAdded]);

  useEffect(() => {
    if (props.activeTab !== "activeTab") {
      setUserSaysQuestions("");
    }
  }, [props.activeTab]);

  const dataChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const index = +e.target.dataset.index;
    const oldList = [...userSaysQuestionsList];
    oldList[index].text = value;
    setUserSaysQuestionsList(oldList);
    dispatch(localUserQuestionUpdate("questions", oldList));
  };

  const userSaysChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setUserSaysQuestions(e.target.value);
    props.isActivityDetected && props.isActivityDetected(e.target.value);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      props.isActivityDetected && props.isActivityDetected(null);
      userSaysListHandler(e);
    }
  };

  const userSaysListHandler = (e: any) => {
    e.preventDefault();
    if (!userSaysQuestion || userSaysQuestion.trim().length === 0) {
      return;
    }
    const oldList = [...userSaysQuestionsList];
    oldList.unshift({ text: userSaysQuestion });
    setUserSaysQuestionsList(oldList);
    setUserSaysQuestions("");
    dispatch(localUserQuestionUpdate("questions", oldList));
  };

  const removeUserSaysHandler = (index: number) => {
    const oldList = [...userSaysQuestionsList];
    oldList.splice(index, 1);
    setUserSaysQuestionsList(oldList);
    dispatch(localUserQuestionUpdate("questions", oldList));
  };

  return (
    <>
      <div className="added-questions">
        <ul className="list-unstyled">
          <li>
            <input
              type="text"
              className="form-control border-0 p-0"
              placeholder={t("Type the question here…")}
              onChange={userSaysChangeHandler}
              onKeyDown={handleKeyDown}
              value={userSaysQuestion}
            />
          </li>
        </ul>
        {/* <div className='user-ask-ques third-step'>
          {/* <AutoTextArea */}
        {/* <input
            type='text'
            className='form-control border-0 p-0'
            placeholder='Type the question here…'
            onChange={userSaysChangeHandler}
            onKeyDown={handleKeyDown}
            value={userSaysQuestion}
          /> */}
        {/* <button className='btn btn-link p-0' onClick={userSaysListHandler}>
            Add
          </button> 
        </div> */}
        <ul className="list-unstyled">
          {userSaysQuestionsList.map(
            (question: { text: string }, index: number) => {
              return (
                <li
                  className="second-step greeting-row"
                  key={"user_says_question_" + index}
                  ref={(e: any) => {
                    if (e) {
                      e.style.setProperty("--animation-order", index + 1);
                    }
                  }}
                >
                  <input
                    type="text"
                    key={props._id + "_IP_" + index}
                    className="form-control border-0 p-0"
                    data-index={index}
                    value={question?.text || ""}
                    onChange={dataChangeHandler}
                  />
                  <button
                    className="btn py-0"
                    onClick={() => removeUserSaysHandler(index)}
                  >
                    <Image src={closeimg} />
                  </button>
                </li>
              );
            }
          )}
        </ul>
      </div>
    </>
  );
};

export default UserSaysTab;
