import React, { ReactElement, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { MATCHED_UNMATCHED_QUESTIONS, RESPONSES } from "../../../../config";
import { matchedAndUnmatchedSummary } from "../../../../store/dashboard/actions";
import InfoIconDetails from "../InfoIconDetails/InfoIconDetails";

import { useTranslation } from "react-i18next";
import DashboardService from "../../../../services/DashboardService";
import { getClassIdsFromConnectionIds } from "../../../../utils/appUtils";

interface Props {
  filtersData?: {
    filter_from_date?: string;
    filter_to_date?: string;
    channel?: string;
  };
  classes?: []
}
const service = new DashboardService();

function UnMatchedQuestions({ filtersData, classes }: Props): ReactElement {
  const { t } = useTranslation("translation");
  const [loading, setLoading] = useState(false);
  const [matchedData, setunmatchedData] = useState(null);
  const history = useHistory();
  useEffect(() => {
    if (
      loading ||
      !filtersData.filter_from_date ||
      !filtersData.filter_to_date
    ) {
      return;
    }

    const fetchSessionData = async (filtersData: any) => {
      setLoading(true);
      const copiedFilters = {...filtersData};
      delete copiedFilters.connection_ids;
      try {
        const data: any = await service.getLearnerQuestions({
          ...copiedFilters,
          answer_status: "unanswered",
          page: 1,
          page_size: 0,
          class_id: filtersData.connection_ids ? await getClassIdsFromConnectionIds(classes, filtersData.connection_ids) : null,
        });

        await setunmatchedData(data);
        setLoading(false);
      } catch (e: any) {
        setLoading(false);
      }
    };
    fetchSessionData(filtersData);
  }, [filtersData]);

  return (
    <div
      className="col-sm-6 col-md-4 col-lg-2"
      onClick={() => {
        history.push(RESPONSES, {
          activeTab: "unanswered",
          filtersData: filtersData,
        });
      }}
    >
      <div className="graph-box">
        <h3 className="d-flex">
          {t("Unanswered Questions")}
          <InfoIconDetails
            message={t(
              "Total number of questions that the chatbot was unable to provide an answer."
            )}
          />
        </h3>
        <div className="row">
          <div className="col-4">
            {/* <h2>{weeklyUsersCount?.summary?.current_week_count}</h2> */}
            {!loading && (
              <h2>{matchedData?.data?.data?.pagination?.total_items || 0}</h2>
            )}
            {loading && (
              <div className="spinner-border spinner-border-sm text-primary"></div>
            )}
            {/* {weeklyUsersCount && (
                <div
                  className={cx({
                    'growth-percent': true,
                    'growth-up':
                      Math.sign(weeklyUsersCount?.summary?.growth) > -1,
                    'growth-down':
                      Math.sign(weeklyUsersCount?.summary?.growth) < 0,
                  })}
                >
                  {weeklyUsersCount
                    ? weeklyUsersCount?.summary?.growth * 100
                    : 0}
                  %
                </div>
              )} */}
          </div>
          <div className="col-8">
            <div style={{ width: "100%", height: "50px" }}>
              {/* {matchedData &&
                matchedData.dailyReturningUsers &&
                matchedData &&
                matchedData.dailyReturningUsers.length && (
                  <LineChartCompact
                    data={
                      matchedData.dailyReturningUsers
                        ? matchedData.dailyReturningUsers
                        : []
                    }
                  />
                )} */}

              {/* {weeklyUsersCount &&
                  weeklyUsersCount.rows &&
                  weeklyUsersCount.rows.length > 0 && (
                    <LineChartCompact data={weeklyUsersCount.rows} />
                  )} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UnMatchedQuestions;
