import React, { useEffect, useState } from "react";
import { Col, Image, OverlayTrigger, Popover, Row } from "react-bootstrap";
import Select, { components } from "react-select";
import { Container, Draggable } from "react-smooth-dnd";
import downarrowblk from "../../../assets/images/chevron-down-blk.svg";
import deleteicon from "../../../assets/New-images/delete-icon-blk.svg";
import dragIcon from "../../../assets/New-images/Icon-drag.svg";
import infoQues from "../../../assets/New-images/ques-blue-circle.svg";

import { useSelector } from "react-redux";
import { HelperService } from "../../../services/helper.service";
import { ButtonsOptions } from "../../../services/interfaces/button-options.interface";
import { AllUserGroups } from "../../../store/questions/selector";
import PermissionWrapper from "../PermissionWrapper/PermissionWrapper";

import { useTranslation } from "react-i18next";
import KnowledgeSearchModal from "./KnowledgeSearchModal";

interface Props {
  hideGroup?: boolean;
  buttonOptions: ButtonsOptions[];
  buttons: ButtonsOptions[];
  relevantIntents?: any[];
  localUserQuestionUpdate: (type: string, data: ButtonsOptions[]) => void;
  onSelectKnowledge?: (id: string) => void;
  isActivityDetected?: (
    selectedButtonTarget: any,
    data: ButtonsOptions[]
  ) => void;
}

const helperService = new HelperService();

export const QuizzesButtonsGroup = (props: Props) => {
  const { t } = useTranslation("translation");
  const allGroups = useSelector(AllUserGroups);
  const [buttonsList, setButtonOptionsList] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [knowledgeItems, setKnowledgeItems] = useState([
    { label: t("Knowledge"), value: "intent" },
    { label: t("Quiz"), value: "quiz" },
    { label: t("Form"), value: "form" },
  ]);
  const [selectedButtonTarget, setSelectedButtonTarget] = useState({
    group_ids: null,
    label: null,
    name: null,
    reference: null,
    reference_id: null,
    type: "intent",
    knowledgeType: null,
    buttonOptions: [],
  });
  const [showSearchModal, setShowSearchModal] = useState(false);
  const knowledgeItemsAsObj: any = {
    intent: { label: t("Knowledge"), value: "intent" },
    form: { label: t("Form"), value: "form" },
    quiz: { label: t("Quiz"), value: "quiz" },
  };
  
  useEffect(() => {
    let options: any[] = [];
    if (props.buttons) {
      options = props.buttons.map((btn: any) => {
        return {
          ...btn,
          knowledgeType: { ...knowledgeItemsAsObj[btn.type] },
        };
      });
    }
    setButtonOptionsList([...options]);
    setSelectedButtonTarget({
      ...selectedButtonTarget,
      knowledgeType: { ...knowledgeItemsAsObj[selectedButtonTarget.type] },
      buttonOptions: getButtonOptionsOnTheBasisOfType(
        selectedButtonTarget.type
      ),
    });

  }, []);
  
  useEffect(() => {
    // console.log('[ buttons ]', props.buttonOptions)
    // console.log('[ props.relevantIntents ]', props.relevantIntents);
    if ( props.relevantIntents.length && props.buttonOptions.length ) {
      setSelectedButtonTarget({
        ...selectedButtonTarget,
        buttonOptions: getButtonOptionsBasedOnReleventIntents()
      })
    }
  }, [ props.relevantIntents ])
  
  const getButtonOptionsBasedOnReleventIntents = () => {
    let options: any = [];
    if ( props.relevantIntents.length && props.buttonOptions.length ) {
      props.relevantIntents.forEach(d => {
        const relevant:any = props.buttonOptions.find(option => option.reference_id === d._id)
        options.push(relevant)
      })
    }
    return [ ...options.map((opt: any) => {
      return  {
        ...opt,
        label: opt.name
      }
    }) ];
  }

  const getButtonOptionsOnTheBasisOfType = (type: string) => {
    return [
      ...props.buttonOptions
        .filter((opt: any) => opt.type === type)
        .map((opt: any) => {
          return  {
            ...opt,
            label: opt.name
          }
        })
    ];
  };

  const chooseKnowledgeHandler = (e: any) => {
    setSelectedButtonTarget({
      ...selectedButtonTarget,
      group_ids: null,
      label: null,
      name: null,
      reference: null,
      reference_id: null,
      knowledgeType: { ...e },
      buttonOptions: e.value === 'intent' && props.relevantIntents && props.relevantIntents.length ? getButtonOptionsBasedOnReleventIntents() : getButtonOptionsOnTheBasisOfType(e.value),
    });
  };

  ///////// drag and drop function to arrange values  ///////////
  const applyDrag = (arr: any, dragResult: any) => {
    const { removedIndex, addedIndex, payload } = dragResult;
    if (removedIndex === null && addedIndex === null) return arr;

    const result = [...arr];
    let itemToAdd = payload;

    if (removedIndex !== null) {
      itemToAdd = result.splice(removedIndex, 1)[0];
    }

    if (addedIndex !== null) {
      result.splice(addedIndex, 0, itemToAdd);
    }
    // setButtonOptionsList([...result]);
    props.localUserQuestionUpdate("buttons", result);
    return result;
  };

  ///////// Change handlers for adding questions in loop ///////////
  const chooseButtonTargetHandler = (e: ButtonsOptions) => {
    const target = {
      ...selectedButtonTarget,
      reference_id: e.reference_id,
      name: e.name,
      type: e.type,
      label: selectedButtonTarget.label,
      knowledgeType: { ...knowledgeItemsAsObj[e.type] },
      buttonOptions: e.type === 'intent' && props.relevantIntents && props.relevantIntents.length ? getButtonOptionsBasedOnReleventIntents() : getButtonOptionsOnTheBasisOfType(e.type),
    };
    setSelectedButtonTarget(target);
    if (props.isActivityDetected) {
      props.isActivityDetected(target, buttonsList);
    }
  };

  const chooseButtonLabelHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const target = {
      ...selectedButtonTarget,
      label: e.target.value,
    };
    setSelectedButtonTarget(target);
    if (props.isActivityDetected) {
      props.isActivityDetected(target, buttonsList);
    }
  };

  const addButtonOptionsToListHandler = (e: any) => {
    e.preventDefault();
    if (selectedButtonTarget.reference_id && selectedButtonTarget.label) {
      const oldButtonsList = [...buttonsList];
      oldButtonsList.push({
        ...selectedButtonTarget,
        name: selectedButtonTarget.name,
        type: selectedButtonTarget.type,
      });
      setButtonOptionsList([...oldButtonsList]);
      props.localUserQuestionUpdate("buttons", oldButtonsList);
      setSelectedButtonTarget({
        ...selectedButtonTarget,
        label: null,
        name: null,
        reference: null,
        reference_id: null,
      });
      setSelectedGroups([]);
      if (props.isActivityDetected) {
        props.isActivityDetected(null, []);
      }
    }
  };

  ///////// Change handlers from loop ///////////
  const changeLabelHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const oldButtonsList = [...buttonsList];
    oldButtonsList[+e.target.dataset.index].label = e.target.value;
    setButtonOptionsList([...oldButtonsList]);
    props.localUserQuestionUpdate("buttons", oldButtonsList);
  };

  const chooseSelectedButtonHandler = (e: any, index: number) => {
    const oldButtonsList = [...buttonsList];
    oldButtonsList[+index].reference_id = e.reference_id;
    oldButtonsList[+index].type = e.type;
    setButtonOptionsList([...oldButtonsList]);
    props.localUserQuestionUpdate("buttons", oldButtonsList);
  };

  const chooseSelectedKnowledgeHandler = (e: any, index: number) => {
    const oldButtonsList = [...buttonsList];
    oldButtonsList[+index].reference_id = null;
    oldButtonsList[+index].type = e.value;
    oldButtonsList[+index].knowledgeType = { ...knowledgeItemsAsObj[e.value] };
    // oldButtonsList[+index].buttonOptions = getButtonOptionsOnTheBasisOfType(e.value);
    setButtonOptionsList([...oldButtonsList]);
    props.localUserQuestionUpdate("buttons", oldButtonsList);
  };

  const deleteButtonOptionsHandler = (index: number) => {
    const oldButtonsList = [...buttonsList];
    oldButtonsList.splice(index, 1);
    setButtonOptionsList([...oldButtonsList]);
    props.localUserQuestionUpdate("buttons", oldButtonsList);
    if (props.isActivityDetected) {
      props.isActivityDetected(null, oldButtonsList);
    }
  };

  const CustomMenu = ({ innerRef, innerProps, isDisabled, children }: any) => {
    return !isDisabled ? (
      <div ref={innerRef} {...innerProps} className="customReactSelectMenu">
        {children}
        <button
          className="btn btn-primary font-600 py-0"
          onClick={ShowSearchModalToggleHandler}
        >
          {t("Add New")}
        </button>
      </div>
    ) : null;
  };

  const SelectMenuButton = (props: any) => {
    return (
      <components.MenuList {...props}>
        {props.children}
        {selectedButtonTarget &&
          selectedButtonTarget.knowledgeType &&
          selectedButtonTarget.knowledgeType.value === "intent" && (
            <button
              className="btn clr-primary font-weight-normal w-100"
              onClick={ShowSearchModalToggleHandler}
            >
              {t("Select from Knowledge base")}
            </button>
          )}
      </components.MenuList>
    );
  };

  const ShowSearchModalToggleHandler = () => {
    setShowSearchModal(!showSearchModal);
  };

  const knowledgeConfirmHandler = (id: string) => {
    const intent = selectedButtonTarget.buttonOptions.find(
      (intent: any) => intent.reference_id === id
    );

    if (!intent) {
      const intentFromButtonOption = props.buttonOptions.find(
        (intent: any) => intent.reference_id === id
      );
      const oldButtonOptions: any[] = [ ...selectedButtonTarget.buttonOptions]
      oldButtonOptions.push(intentFromButtonOption);
      setSelectedButtonTarget({
        ...selectedButtonTarget,
        buttonOptions: [ ...oldButtonOptions ],
      });
      chooseButtonTargetHandler( intentFromButtonOption );
      ShowSearchModalToggleHandler(); 
    } else {
      if (intent) {
        chooseButtonTargetHandler(intent);
        ShowSearchModalToggleHandler();
      }
    }

  };

  const PopoverComponent = (data: any) => {
    return (
      <Popover id="popover-basic-" {...data.popoverComponentProps}>
        <Popover.Content>
          {t("Select the specific knowledge in the knowledge base to redirect the user to")}
        </Popover.Content>
      </Popover>
    );
  };

  return (
    <>
      <KnowledgeSearchModal
        show={showSearchModal}
        onHide={ShowSearchModalToggleHandler}
        confirmHandler={knowledgeConfirmHandler}
      />
      {/* --------------------------------------- Buttons list wrapper -------------------------------------------------- */}
      <div className="form-group mb-0">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <label className="font-16 font-600 clr-black mb-0 d-flex align-items-center">
            {t("Redirect user to")}
            
            <OverlayTrigger
              trigger={["hover", "focus"]}
              placement="auto"
              overlay={(d) => (
                <PopoverComponent popoverComponentProps={d} />
              )}
            >
              <button className="btn py-0 ml-1">
                <img src={infoQues} alt="" />
              </button>
            </OverlayTrigger>
          </label>
          <button
            className="btn py-0 clr-primary font-600"
            onClick={addButtonOptionsToListHandler}
          >
            {t("Add")}
          </button>
        </div>
        <Row className="no-gutters mb-4 align-items-center">
          <Col
            md={3}
            className="pr-md-2 mb-2 mb-md-0 d-flex align-items-center"
          >
            {/* <button className="btn py-0">
              <img src={dragIcon} alt="" />
            </button> */}
            <Select
              isSearchable={false}
              //menuIsOpen
              className="select-target w-100 quiz-select"
              classNamePrefix="select-target-prefix"
              components={{
                DropdownIndicator: () => (
                  <img src={downarrowblk} alt={t("dropdown arrow")} />
                ),
                IndicatorSeparator: () => null,
                ClearIndicator: () => null,
              }}
              options={[...knowledgeItems]}
              placeholder={
                selectedButtonTarget && selectedButtonTarget.knowledgeType && selectedButtonTarget.knowledgeType.value === 'intent' ? 
                t("Knowledge") : 
                selectedButtonTarget && selectedButtonTarget.knowledgeType && selectedButtonTarget.knowledgeType.value === 'form' ? 
                  t("Form") :
                selectedButtonTarget && selectedButtonTarget.knowledgeType && selectedButtonTarget.knowledgeType.value === 'quiz' ?
                  t("Quiz") : t("Knowledge")
              }
              onChange={chooseKnowledgeHandler}
              value={
                selectedButtonTarget.knowledgeType
                  ? selectedButtonTarget.knowledgeType
                  : null
              }
            />
          </Col>
          <Col sm={12} md={5} className="pr-md-2 mb-2 mb-md-0">
            <Select
              isSearchable={true}
              // defaultMenuIsOpen={true}
              className="select-target w-100 quiz-select"
              classNamePrefix="select-target-prefix"
              components={{
                DropdownIndicator: () => (
                  <img src={downarrowblk} alt={t("dropdown arrow")} />
                ),
                IndicatorSeparator: () => null,
                ClearIndicator: () => null,
                // Menu: CustomMenu,
                MenuList: SelectMenuButton,
              }}
              options={
                selectedButtonTarget &&
                selectedButtonTarget.buttonOptions.length
                  ? [...selectedButtonTarget.buttonOptions]
                  : []
              }
              placeholder={
                selectedButtonTarget && selectedButtonTarget.knowledgeType && selectedButtonTarget.knowledgeType.value === 'intent' ? 
                t("Knowledge") : 
                selectedButtonTarget && selectedButtonTarget.knowledgeType && selectedButtonTarget.knowledgeType.value === 'form' ? 
                  t("Form") :
                selectedButtonTarget && selectedButtonTarget.knowledgeType && selectedButtonTarget.knowledgeType.value === 'quiz' ?
                  t("Quiz") : t("Knowledge")
              }
              onChange={chooseButtonTargetHandler}
              value={
                selectedButtonTarget && selectedButtonTarget.reference_id
                  ? {
                      ...selectedButtonTarget,
                      label: helperService.findValueFromObject(
                        props.buttonOptions,
                        selectedButtonTarget.reference_id,
                        "reference_id",
                        "name"
                      ),
                    }
                  : null
              }
              getOptionValue={(option: ButtonsOptions) =>
                option["reference_id"]
              }
            />
          </Col>
          <Col sm={12} md={4}>
            <input
              type="text"
              className="form-control h-auto radius-8"
              placeholder={t("Name of button")}
              value={selectedButtonTarget.label || ""}
              onChange={chooseButtonLabelHandler}
            />
          </Col>
        </Row>
      </div>

      <Container
        dragHandleSelector=".column-drag-handle"
        onDrop={(e) => applyDrag(buttonsList, e)}
      >
        {buttonsList.map((buttonsOption: ButtonsOptions, index: number) => {
          // console.log('buttonsOption', buttonsOption, 'props.buttonOptions', getButtonOptionsOnTheBasisOfType(
          //   buttonsOption.type
          // ))
          // console.log(
          //   'label: helperService.findValueFromObject',
          //   {
          //     ...buttonsOption,
          //     label: helperService.findValueFromObject(
          //       props.buttonOptions,
          //       buttonsOption.reference_id,
          //       "reference_id",
          //       "name"
          //     ),
          //   }
          // )
          return (
            <Draggable
              key={"draggable_" + buttonsOption.reference_id + "_i_" + index}
            >
              <Row className="no-gutters mb-4 align-items-center">
                <Col
                  md={3}
                  className="pr-md-2 mb-2 mb-md-0 d-flex align-items-center"
                >
                  <button className="btn py-0 column-drag-handle">
                    <img src={dragIcon} alt="" />
                  </button>
                  <Select
                    key={
                      "select_ref_knowleedge_" +
                      buttonsOption.reference_id +
                      "_i_" +
                      index
                    }
                    isSearchable={false}
                    className="select-target w-100 quiz-select"
                    classNamePrefix="select-target-prefix"
                    components={{
                      DropdownIndicator: () => (
                        <img src={downarrowblk} alt={t("dropdown arrow")} />
                      ),
                      IndicatorSeparator: () => null,
                      ClearIndicator: () => null,
                    }}
                    options={[...knowledgeItems]}
                    placeholder={"Knowledge"}
                    onChange={(e: any) => {
                      chooseSelectedKnowledgeHandler(e, index);
                    }}
                    value={
                      buttonsOption.knowledgeType
                        ? buttonsOption.knowledgeType
                        : null
                    }
                  />
                </Col>
                <Col sm={12} md={5} className="pr-md-2 mb-2 mb-md-0">
                  <Select
                    key={
                      "select_ref_" + buttonsOption.reference_id + "_i_" + index
                    }
                    isSearchable={true}
                    className="select-target w-100 quiz-select"
                    classNamePrefix="select-target-prefix"
                    components={{
                      DropdownIndicator: () => (
                        <img src={downarrowblk} alt={t("dropdown arrow")} />
                      ),
                      IndicatorSeparator: () => null,
                      ClearIndicator: () => null,
                    }}
                    options={getButtonOptionsOnTheBasisOfType(
                      buttonsOption.type
                    )}
                    placeholder={t("Knowledge")}
                    onChange={(e) => chooseSelectedButtonHandler(e, index)}
                    value={{
                      ...buttonsOption,
                      label: helperService.findValueFromObject(
                        props.buttonOptions,
                        buttonsOption.reference_id,
                        "reference_id",
                        "name"
                      ),
                    }}
                    getOptionValue={(option: ButtonsOptions) =>
                      option["reference_id"]
                    }
                  />
                </Col>

                <Col sm={12} md={4}>
                  <div className="d-flex">
                    <input
                      type="text"
                      className="form-control h-auto radius-8"
                      key={
                        "button_ref_" +
                        buttonsOption.reference_id +
                        "_i_" +
                        index
                      }
                      placeholder={t("Name of button")}
                      data-index={index}
                      value={buttonsOption.label || ""}
                      onChange={changeLabelHandler}
                    />
                    <PermissionWrapper>
                      <button
                        className="btn border-0 mr-2"
                        type="button"
                        onClick={() => deleteButtonOptionsHandler(index)}
                      >
                        <Image src={deleteicon} />
                      </button>
                    </PermissionWrapper>
                  </div>
                </Col>
              </Row>
            </Draggable>
          );
        })}
      </Container>
    </>
  );
};

export default QuizzesButtonsGroup;
