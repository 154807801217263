import React from 'react'
import { Modal, ModalFooter, Form } from 'react-bootstrap'
import ModalBody from 'react-bootstrap/ModalBody'
import ModalHeader from 'react-bootstrap/ModalHeader'
import ModalTitle from 'react-bootstrap/ModalTitle'

import { useTranslation } from "react-i18next";

export default function TurnOnOffDocumentPreviewModal(props: any) {
  const { t } = useTranslation('translation');
  const [deleteIntents, setdeleteIntents] = React.useState(false)
  const [documentAllowedHandler, setDocumentAllowedHandler] =
    React.useState(false)
  const changeDocumentAllowedHandler = (e: any) => {
    setDocumentAllowedHandler(e.target.value === 'true' ? true : false)
  }

  React.useEffect(() => {
    if (!props.show) {
      return
    }
    setdeleteIntents(false)
    return () => {}
  }, [props.show])

  return (
    <Modal
      show={props.show}
      size={props.size}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      dialogClassName='delete-knowledge-confirmation'
    >
      <ModalHeader className='pt-3'>
        <ModalTitle id='contained-modal-title-vcenter'>
          {/* <Button onClick={props.onHide}>
            <Image src={closeimg} />
          </Button> */}
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <div className='px-4'>
          {props.message}

          {props.isDocumentTurnOnMode && (
            <div className='mt-3'>
              <h2>{t("Document Preview Access Limit")}</h2>
              <div className='mb-24'>
                {t("Configure the access to the document. A user can see full/a portion of the document based on this setting.")}
              </div>
              <div className='custom-control custom-radio'>
                <input
                  type='radio'
                  name='is_full_document_allowed'
                  value={'true'}
                  checked={documentAllowedHandler}
                  onChange={changeDocumentAllowedHandler}
                  className='custom-control-input'
                  id='custom-radio1'
                />
                <label className='custom-control-label' htmlFor='custom-radio1'>
                  {t("Show full document to the user")}
                </label>
              </div>
              <div className='custom-control custom-radio'>
                <input
                  type='radio'
                  name='is_full_document_allowed'
                  value={'false'}
                  checked={!documentAllowedHandler}
                  onChange={changeDocumentAllowedHandler}
                  className='custom-control-input'
                  id='custom-radio2'
                />
                <label className='custom-control-label' htmlFor='custom-radio2'>
                  {t("Show only a part of the document from where the response was generated")}
                </label>
              </div>
            </div>
          )}
        </div>
      </ModalBody>
      <ModalFooter className='border-0 justify-content-center px-3 mb-3 mx-4 flex-nowrap'>
        <button
          className='btn-outline-primary btn w-50 ml-0 mr-3'
          onClick={props.onHide}
        >
          {t("No")}
        </button>
        <button
          className='btn-danger btn w-50 ml-3 mr-0'
          onClick={(e) =>
            props.confirmHandler(e, {
              selectedRow: props.selectedRow,
              isDocumentTurnOnMode: props.isDocumentTurnOnMode,
              is_full_document_allowed: documentAllowedHandler,
            })
          }
        >
          {t("Yes")}
        </button>
      </ModalFooter>
    </Modal>
  )
}
