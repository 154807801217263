import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/Header";
import ChooseAgentListItem from "../../components/UI/ChooseAgentListItem/ChooseAgentListItem";
import {
  deleteAgentById,
  getAllAgents,
  getCurrentOrganisation,
  updateSelectedAgent,
} from "../../store/agents/actions";
import { getOrganisationFeatures, getOrganizationCurrentUserDetails } from "../../store/common/actions";

import { useTranslation } from "react-i18next";
import { useGlobalContext } from "../../context/GlobalContext";
import Select from "react-select";
import * as _ from "lodash";
import { Container, Image } from "react-bootstrap";
import { onAgentSearchHandler } from "../../utils/appUtils";
import { getIsSuperAdmin } from "../../store/common/selector";
import AddAgentPopup from "../SwitchAgent/popup/AddAgentPopup";
import KnowledgeService from "../../services/KnowledgeService";
import addAgentImg from "../../assets/New-images/add-agent-img.svg";
import DeleteAgentConfirmationPopup from "../SwitchAgent/popup/DeleteAgentConfirmationPopup";
import UpdateAgent from "../SwitchAgent/UpdateAgent/UpdateAgent";

interface Props {}

export const ChooseAgent = ({ allAgents, agentSelected }: any) => {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const IsSuperAdmin = useSelector(getIsSuperAdmin);
  const { setSelectedAgentEmail, setSelectedAgentName } = useGlobalContext();
  const [fetchedAgents, setFetchedAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState(
    localStorage.getItem("selectedAgent")
  );

  const [organizationName, setOrganizationName] = useState("");
  const [query, setQuery] = useState("");
  const [agentOrder, setAgentOrder] = useState({
    value: null,
    label: t("By Agent Name"),
  });
  const [isMultiAgentFeatureEnabled, setIsMultiAgentFeatureEnabled] =
  useState(false);
  const [showModal, setShowModal] = useState(null);
  const [orgLang, setOrgLang] = useState("en");
  const [selectedAgentForEdit, setSelectedAgentForEdit] = useState(null);
  const [selectedAgentForDelete, setSelectedAgentForDelete] = useState(null);
  const [editAgent, setEditAgent] = useState(null);

  const service = new KnowledgeService();

  useEffect(() => {
    // setFetchedAgents(allAgents ? [...allAgents] : []);
    fetchAllAgents();
    getCurrentOrg();
    getAgentsData();
  }, [allAgents]);
  
  const getAgentsData = async () => {
    try {
      const features: any = await dispatch(getOrganisationFeatures());
      setIsMultiAgentFeatureEnabled(
        features.some((feature: any) => feature.name === "multiagent")
      );
    } catch (e: any) {
      setIsMultiAgentFeatureEnabled(false);
    }
  };

  const getCurrentOrg = async () => {
    try {
      const currentOrganisation: any = await dispatch(getCurrentOrganisation());
      setOrganizationName(currentOrganisation?.organisation?.name);
      setOrgLang(currentOrganisation?.organisation?.default_language_code);
    } catch (e: any) {
      setOrganizationName(null);
    }
  };

  const chooseCurrentAgentHandler = async (e: any) => {
    e.preventDefault();
    try {
      const response: any = await dispatch(
        updateSelectedAgent({
          states: {
            selectedAgent: e.target.dataset.agentid,
            selectedAgentName: e.target.dataset.name,
          },
        })
      );

      setSelectedAgentName(response.selectedAgentName);
      setSelectedAgentEmail(response.selectedAgentName);
      await setSelectedAgent(response.selectedAgent);
      await localStorage.setItem("selectedAgent", response.selectedAgent);
      await localStorage.setItem(
        "selectedAgentName",
        response.selectedAgentName
      );
      await dispatch(getOrganizationCurrentUserDetails(false));
      agentSelected();
    } catch (error: any) {}
  };

  const fetchAllAgents = async () => {
    try {
      const fetchedAgents: any = await dispatch(getAllAgents());
      setFetchedAgents(
        fetchedAgents.filter((agent: any) => agent.status !== "inactive")
      );
    } catch (e: any) {
      setFetchedAgents([]);
    }
  };

  const onAddEditAgentHandler = async (agent: any, selectedPersona: any) => {
    const { chat_persona } = agent?.settings;
    const { status } = await service.updateWebAgentSettings(
      {
        settings: {
          chat_persona:
            selectedPersona && selectedPersona.value
              ? selectedPersona.value
              : chat_persona,
        },
      },
      agent?._id
    );
    if (status === 200) {
      setShowModal(false);
      fetchAllAgents();
    }
  };

  const editAgentHandler = (e: any) => {
    e.preventDefault();
    setSelectedAgentForEdit({
      show: true,
      agentid: e.target.dataset.agentid,
      name: e.target.dataset.name,
      description: e.target.dataset.description,
    });

    const editAgent = fetchedAgents.find(
      (i: any) => i._id === e.target.dataset.agentid
    );
    setEditAgent(editAgent);
  };

  const closeEditModalHandler = (e: any) => {
    e?.preventDefault();
    setSelectedAgentForEdit({
      show: false,
      agentid: null,
      name: null,
      description: null,
    });
    setEditAgent(null);
  };

  const closeEditModalAfterUpdateHandler = (
    agentId: string,
    selectedPersona: any
  ) => {
    onAddEditAgentHandler(editAgent, selectedPersona);
    setSelectedAgentForEdit({
      show: false,
      agentid: null,
      name: null,
      description: null,
    });
    setEditAgent(null);
    // fetchAllAgents();
  };

  const deleteAgentHandler = async (e: any) => {
    e.preventDefault();
    await setSelectedAgentForDelete({
      id: e.target.dataset.agentid,
      show: true,
    });
  };

  const closeConfirmationModalHandler = async (e: any) => {
    e.preventDefault();
    await setSelectedAgentForDelete({
      id: null,
      show: false,
    });
  };

  const deleteAgentAfterConfirmationHandler = async (
    e: any,
    selectedRow: any
  ) => {
    e.preventDefault();
    try {
      await dispatch(deleteAgentById(selectedRow));
      await setSelectedAgentForDelete({
        id: null,
        show: false,
      });
      await fetchAllAgents();
    } catch (e: any) {}
  };

  return (
    <>
      <DeleteAgentConfirmationPopup
        show={selectedAgentForDelete ? selectedAgentForDelete.show : false}
        onHide={closeConfirmationModalHandler}
        confirmHandler={deleteAgentAfterConfirmationHandler}
        selectedRow={selectedAgentForDelete ? selectedAgentForDelete.id : null}
      />

      <UpdateAgent
        show={selectedAgentForEdit ? selectedAgentForEdit.show : false}
        onHide={closeEditModalHandler}
        hideAfterUpdate={closeEditModalAfterUpdateHandler}
        agentid={selectedAgentForEdit ? selectedAgentForEdit.agentid : null}
        name={selectedAgentForEdit ? selectedAgentForEdit.name : null}
        description={
          selectedAgentForEdit ? selectedAgentForEdit.description : null
        }
        editAgent={editAgent}
      />

      <AddAgentPopup
        show={showModal}
        onHide={() => setShowModal(false)}
        orgLang={orgLang}
        footerButtonAction={onAddEditAgentHandler}
      />

      <Container>
        <Header
          hideElementsForOrganization={true}
          noAgentFound={true}
          isOrgSwitch={true}
          hideNotificationBell={true}
        />
        <section className="organisation-wraper h-100 w-100 background-none">
          <div className="container all-knowledge-search ">
            <h1 className="mb-4 ">
              {organizationName} / {t("Switch Agent")}
            </h1>
            <div className="font-18-px font-600 mb-3">
              {t("Select the agent which you would like to manage.")}
            </div>
            <div className="web-links justify-content-between d-flex align-items-center mw-100">
              <input
                type="text"
                className="form-control h-auto"
                placeholder="Search Agent Name"
                onChange={(e: any) => setQuery(e.target.value)}
              />
              <div className="doc-select-dropdown ml-3">
                <Select
                  className="select-sort select-target select-blue shadow-none radius-8"
                  classNamePrefix="select-target-prefix"
                  isSearchable={false}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  placeholder={t("Status")}
                  options={[
                    {
                      value: null,
                      label: t("By Agent Name"),
                    },
                    {
                      value: "date",
                      label: t("Date Created"),
                    },
                  ]}
                  onChange={(e: any) => {
                    // setClickedCustomDatePickerValue(false);
                    setAgentOrder(e);
                  }}
                  value={agentOrder}
                />
              </div>
            </div>
            <div className="row mt-3">
              {IsSuperAdmin && isMultiAgentFeatureEnabled && (
                <div className="col-md-4 col-sm-6 col-xl-3 mb-3">
                  <div
                    className="knowledge-box d-flex justify-content-center align-items-center not-hover build-knowbox"
                    onClick={() => setShowModal(true)}
                    style={{
                      minHeight: "280px",
                    }}
                  >
                    <div className="text-center">
                      <Image src={addAgentImg} alt="addAgent" />
                      <h2 className="mb-0 pt-3 clr-primary">
                        {t("Add New Agent")}
                      </h2>
                    </div>
                  </div>
                </div>
              )}
              <>
                {fetchedAgents &&
                  fetchedAgents.length > 0 &&
                  onAgentSearchHandler(fetchedAgents, query, agentOrder).map(
                    (
                      { _id, name, description, created_at, updated_at, language_code }: any,
                      index: number
                    ) => {
                      return (
                        <ChooseAgentListItem
                          key={_id + "_" + index}
                          selectedAgent={selectedAgent}
                          _id={_id}
                          name={name}
                          description={description}
                          created_at={created_at}
                          updated_at={updated_at}
                          editAgentHandler={editAgentHandler}
                          deleteAgentHandler={deleteAgentHandler}
                          chooseCurrentAgentHandler={chooseCurrentAgentHandler}
                          isMultiAgentFeatureEnabled={
                            isMultiAgentFeatureEnabled
                          }
                          hideOptions={false}
                          language_code={language_code}
                        />
                      );
                    }
                  )}
              </>
            </div>
          </div>
        </section>
      </Container>
    </>
  );
};

export default ChooseAgent;
