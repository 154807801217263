import Moment from 'react-moment'
import downarrowimg from '../../../assets/images/chevron-down.svg'
import { Popover, OverlayTrigger } from 'react-bootstrap'
import Tags from '../../../components/UI/Tags/Tags'
import { convertDataToMarkdownFormat } from '../../../utils/appUtils'

import { useTranslation } from "react-i18next";

export const customStyles = {
  header: {
    style: {
      minHeight: '56px',
    },
  },
  headRow: {
    style: {
      borderRadius: '10px',
      backgroundColor: '#0923E6',
      borderBottomWidth: '0',
    },
  },
  expanderRow: {
    style: {
      // backgroundColor: '#0923E6',
    },
  },
  expanderCell: {
    style: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
      button: {
        backgroundColor: 'transparent !important',
        img: {
          width: '10px',
        },
      },
    },
  },
  expanderButton: {
    style: {
      backgroundColor: 'transparent',
      alignItems: 'center',
    },
  },
  headCells: {
    style: {
      '&:first-child': {
        borderTopLeftRadius: '5px',
        borderBottomLeftRadius: '0px',
      },
      '&.chart--table-sort-icon': {
        marginLeft: '10px',
      },
      'input[type="checkbox"]': {
        width: '16px',
        height: '16px',
      },
      backgroundColor: '#0923E6',
      width: '300px',
      padding: '20px 20px',
      fontSize: '14px',
      color: '#fff',
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: '#FFFFFF',
      },
      div: {
        color: '#fff',
      },
    },
  },
  cells: {
    style: {
      color: '#07032B',
      fontSize: '14px',
      paddingTop: '10px',
      paddingBottom: '10px',
      'input[type="checkbox"]': {
        width: '16px',
        height: '16px',
      },
      '&:nth-child(4)': {
        div: {
          textOverflow: 'ellipsis',
          display: ' -webkit-box',
          WebkitLineClamp: '2',
          WebkitBoxOrient: 'vertical',
        },
      },
    },
  },
}

// {
//   /* const popover = (
//           <Popover id="popover-basic">
//             <Popover.Title as="h3">Popover right</Popover.Title>
//             <Popover.Content>
//               And here's some <strong>amazing</strong> content. It's very engaging.
//               right?
//             </Popover.Content>
//           </Popover>
//         ); */
// }

export const CustomResponseComponent = (data: any) => (
  <div>
    <div>
      <div
        data-tag='allowRowEvents'
        style={{
          color: '#07032B',
          overflow: 'hidden',
          textOverflow: 'ellipses',
        }}
      >
        {data.row.responses && data.row.responses.length > 0
          ? data.row.responses[0].text
          : ''}
      </div>
    </div>
  </div>
)

export const DateComponent = (data: any) => (
  <div>
    <Moment utc local format='DD/MM/YYYY hh:mm A'>{data.row.updated_at}</Moment>
  </div>
)

export const conditionalRowStyles = [
  {
    when: (row: any) => row.is_suggested === true,
    style: {
      borderLeft: '3px solid rgba(255, 0, 158, 0.7)',
    },
  },
  // You can also pass a callback to style for additional customization
  // {
  //   when: (row) => row.calories < 300,
  //   style: (row) => ({
  //     backgroundColor: row.isSpecia ? 'pink' : 'inerit',
  //   }),
  // },
]

export const ExpandableComponent = ({ data, allIntents }: any) => {
  const { t } = useTranslation('translation');
  const parent = allIntents.find((intent: any) => intent._id === data.parent_id)
  return (
    <div className='expand-wraper'>
      <div className='posible-ques'>
        <h3>{t("Author email")}</h3>
        <p>{data?.author_email}</p>
        <h3>{t("Possible questions")}</h3>
        <ol>
          {data.questions.length > 0
            ? data.questions.map((q: { text: string }, i: number) => {
                return (
                  <li key={'q_' + data._id + '_' + i}>
                    <p>{q.text}</p>
                  </li>
                )
              })
            : '-'}
        </ol>
        <h3>{t("Possible responses")}</h3>
        <ol>
          {data.responses.length > 0
            ? data.responses.map(
                (
                  response: {
                    text: string
                    group_ids: any
                  },
                  i: number
                ) => {
                  return (
                    <li key={'response_' + data._id + '_' + i}>
                      <p>{convertDataToMarkdownFormat(response.text)}</p>
                    </li>
                  )
                }
              )
            : '-'}
        </ol>
      </div>
      <div className='parent-ques'>
        <h3>{t("Parent question")}</h3>
        <p>{parent ? parent.name : '-'}</p>
      </div>
    </div>
  )
}

export const ChartTableSortIcon = (data: any) => {
  const { t } = useTranslation('translation');
  return (<img src={downarrowimg} alt={t('collapsed')} className='chart-table-sort-icon' />)
}

export const PopoverTagsComponent = (data: any) => {
  return (
    <Popover
      id='popover-basic-'
      {...data.popoverComponentProps}
      className='popover-tags-component'
    >
      <div
        data-tag='allowRowEvents'
        style={{
          color: '#07032B',
          overflow: 'hidden',
          textOverflow: 'ellipses',
        }}
        className='knowledge-tags d-flex flex-wrap align-items-center'
      >
        {data.tags &&
          data.tags.length > 0 &&
          data.tags.map((tag: string, index: number) => {
            return (
              <Tags
                key={tag + '_' + index}
                value={tag}
                label={tag}
                removeTagHandler={() => {}}
                hideDeleteOption
              />
            )
          })}
      </div>
    </Popover>
  )
}

export const TagsComponent = ({ row, showAllTagsAtOnce, hideDeleteOption = true, removeTagHandler }: any) => {
  return (
    <>
      <div
        // data-tag='allowRowEvents'
        // style={{
        //   color: '#07032B',
        //   overflow: 'hidden',
        //   textOverflow: 'ellipses',
        // }}
        className='knowledge-tags d-flex flex-wrap align-items-center'
      >
        {
          showAllTagsAtOnce && row.tags.map((tag: string, index: number) => {
            return (
              <Tags
                key={tag + '_' + index}
                value={tag}
                label={tag}
                removeTagHandler={removeTagHandler}
                hideDeleteOption={hideDeleteOption}
              />
            )
          })
        }

        {!showAllTagsAtOnce && row.tags &&
          row.tags.length > 0 &&
          row.tags.slice(0, 3).map((tag: string, index: number) => {
            return (
              <Tags
                key={tag + '_' + index}
                value={tag}
                label={tag}
                removeTagHandler={removeTagHandler}
                hideDeleteOption={hideDeleteOption}
              />
            )
          })}
        {!showAllTagsAtOnce && row.tags && row.tags.length > 3 && (
          <OverlayTrigger
            trigger={['click']}
            placement='auto'
            overlay={(d) => (
              <PopoverTagsComponent
                popoverComponentProps={d}
                tags={row.tags.slice(3, row.tags.length)}
              />
            )}
          >
            <button className='btn more-tag p-0'>+{row.tags.length - 3}</button>
          </OverlayTrigger>
        )}
      </div>
    </>
  )
}
