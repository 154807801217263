import React, { useEffect, useState } from 'react'
import Loading from '../../components/UI/Loading/Loading'

import { useTranslation } from "react-i18next";

interface Props {}

export const ReviewSubmissions = (props: any) => {
  const { t } = useTranslation('translation');
  const windoeObj: any = window
  const [iframeLoading, setiframeLoading] = useState(false)
  useEffect(() => {
    setiframeLoading(true)
  }, [])

  const iframeLoadedHandler = () => {
    setiframeLoading(false)
  }

  return (
    <>
      <section className='page-mid-wraper h-without-foter pb-0 p-0'>
        <div className='container h-100 p-0'>
          <div className='row h-100'>
            <div className='col-md-12 h-100'>
              {iframeLoading && <Loading />}
              <iframe
                id='myframe'
                src={windoeObj.OLD_PORTAL_URI + `review-submission/${props.match.params.submissionId}?viewMode=true`}
                title={t('Forms')}
                className='test-bot-iframe'
                onLoad={iframeLoadedHandler}
              ></iframe>
            </div>
          </div>
        </div>
      </section>
      {/* <footer></footer> */}
    </>
  )
}

export default ReviewSubmissions
