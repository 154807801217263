import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import createIndividualSummaryImg from "../../../assets/New-images/create-individual-summary-img.svg";
import skipImg from "../../../assets/New-images/skip-img.svg";
import summariseIntoSingleImg from "../../../assets/New-images/summarise-into-single-img.svg";
import ProgressSteps from "../../../components/UI/ProgressSteps/ProgressSteps";
import {
  BUILD_SUMMARISE_MULTI_DOCUMENTS_SECTION,
  GENERATE_KNOWLEDGE,
} from "../../../config";
import LearningOutcomes from "./LearningOutcomes";
import KnowledgeService from "../../../services/KnowledgeService";

const knowledgeService = new KnowledgeService()

const BuildSummariseDocumentsSection = (props: any) => {
  const history: any = useHistory();
  const { t } = useTranslation("translation");
  const query = new URLSearchParams(history.location.search);
  const documentId = query.get("references");
  const params: any = useParams();
  const [groupId, setGroupId] = useState(query.get("group") || "");
  const ids = documentId.split(",") || "";
  const [learningOption, setLearningOption] = useState('');

  useEffect(() => {
    setGroupId(
      history &&
        history.location &&
        history.location.state &&
        history.location.state.group
        ? history.location.state.group
        : query.get("group")
    );
  }, []);
  


  if(!learningOption) {
    return   <LearningOutcomes setLearningOption={(value: any)=> {
      setLearningOption(value)
      if(documentId.split(',').length === 1){
        history.push(
          `${BUILD_SUMMARISE_MULTI_DOCUMENTS_SECTION.replace(
            ":step",
            "3"
          ).replace(
            ":subStep",
            "1"
          )}?documents=${documentId}&group=${groupId}`,
          {
            group: groupId,
            learning: value,
            docIds : documentId?.split(',')
          }
        )
      }
    }}/>
  }

  return (
    <React.Fragment>
      <section className="page-mid-wraper">
        <div>
          {!props.hideHeaders && (
            <>
              <div className="d-flex flex-wrap justify-content-center clr-primary">
                <h1 className="mb-2">
                  {t("Would you like to merge your documents?")}
                </h1>
              </div>
              <div className="mb-32 font-16 text-center">
                {t("As your document(s) will be summarised to generate the learning outcomes and tutoring questions")}
              </div>
            </>
          )}
          <>
            <div className="build-summary-docs px-lg-5 mx-lg-4">
              {ids.length > 1 && (<div
                className="knowledge-box know-box-new radius-8 p-3 not-hover d-flex align-items-center mb-3"
                onClick={() =>
                  history.push(
                    `${BUILD_SUMMARISE_MULTI_DOCUMENTS_SECTION.replace(
                      ":step",
                      "3"
                    ).replace(
                      ":subStep",
                      "1"
                    )}?documents=${documentId}&group=${groupId}`,
                    {
                      group: groupId,
                      learning: learningOption,
                      docIds : documentId?.split(',')
                    }
                  )
                }
              >
                <figure className="h-auto mb-0">
                  <img src={summariseIntoSingleImg} alt="" />
                </figure>
                <div className="text-left pl-3">
                  <h3 className="font-weight-bold font-1 clr-primary mb-1">
                    {t(
                      "Merge document(s) into single file"
                    )}
                  </h3>
                  <p className="clr-grey-txt7 font-sm font-600">
                    {t(
                      "Consolidate and summarise uploaded documents into a single file while also preserving the uploaded files."
                    )}
                  </p>
                </div>
              </div>)}
              <div
                className="knowledge-box know-box-new radius-8 p-3 not-hover d-flex align-items-center mb-3"
                onClick={() =>
                  history.push(
                    `${BUILD_SUMMARISE_MULTI_DOCUMENTS_SECTION.replace(
                      ":step",
                      "3"
                    ).replace(
                      ":subStep",
                      "2"
                    )}?documents=${documentId}&group=${groupId}`,
                    {
                      group: groupId,
                      learning: learningOption,
                      docIds : documentId?.split(',')
                    }
                  )
                }
              >
                <figure className="h-auto mb-0">
                  <img src={createIndividualSummaryImg} alt="" />
                </figure>
                <div className="text-left pl-3">
                  <h3 className="font-weight-bold font-1 clr-primary mb-1">
                    {t("Summarise Document(s) individually")}
                  </h3>
                  <p className="clr-grey-txt7 font-sm font-600">
                    {t(
                      "Each uploaded file will be summarised individually, and both original and summarised docs are saved."
                    )}
                    <span className="d-block">
                      {t(
                        "Recommended for unstructured documents (e.g., transcripts) and PowerPoint slides. "
                      )}
                    </span>
                  </p>
                </div>
              </div>
              {/* <div
                className="knowledge-box know-box-new radius-8 p-3 not-hover d-flex align-items-center mb-3"
                onClick={() =>
                  history.push(
                    GENERATE_KNOWLEDGE.replace(":step", "5").replace(
                      ":questionId",
                      documentId
                    ) + `?group=${groupId}`,
                    {
                      group: groupId,
                    }
                  )
                }
              >
                <figure className="h-auto mb-0">
                  <img src={skipImg} alt="" />
                </figure>
                <div className="text-left pl-3">
                  <h3 className="font-weight-bold font-1 clr-primary mb-1">
                    {t("Skip this Step")}
                  </h3>
                </div>
              </div> */}
            </div>
          </>
        </div>
        <div className="pt-2">
          <ProgressSteps currentStep={params.step} />
        </div>
      </section>
    </React.Fragment>
  );
};

export default BuildSummariseDocumentsSection;
