import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddGreeting } from "../../../../app.interface";
import GreetingsWithGroups from "../../../../components/UI/GreetingsWithGroups/GreetingsWithGroups";
import PermissionWrapper from "../../../../components/UI/PermissionWrapper/PermissionWrapper";
import RichTextArea from "../../../../components/UI/RichTextArea/RichTextArea";
import { localUserQuestionUpdate } from "../../../../store/questions/actions";
import { getIntent } from "../../../../store/questions/selector";

import { useTranslation } from "react-i18next";

interface Props {}

export const VirtualAssistantTab = (props: any) => {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const intent = useSelector(getIntent);
  const [addVirtualAssistantAnswer, setVirtualAssistantAnswer] = useState({
    text: "",
    group_ids: [],
  });
  const [virtualAssistantList, setVirtualAssistantList] = useState([]);

  useEffect(() => {
    setVirtualAssistantList((intent && intent.responses) || []);
  }, [intent]);

  useEffect(() => {
    if (props.hasAdded) {
      setVirtualAssistantAnswer({ text: "", group_ids: [] });
    }
  }, [props.hasAdded]);

  ///////// Editor handlers ( Parent )  ///////////

  const onVirtualAssistantEditorChange = (id: any, name: string) => {
    addVirtualAssistantAnswer.text = name;
    setVirtualAssistantAnswer(addVirtualAssistantAnswer);
    props.isActivityDetected &&
      props.isActivityDetected(addVirtualAssistantAnswer);
  };

  ///////// Editor handlers ( LOOP )  ///////////
  const addVirtualAssistantHandler = () => {
    const oldList = [...virtualAssistantList];
    if (addVirtualAssistantAnswer && addVirtualAssistantAnswer.text) {
      oldList.unshift({ text: addVirtualAssistantAnswer.text, group_ids: [] });
      setVirtualAssistantList([...oldList]);
      dispatch(localUserQuestionUpdate("responses", oldList));
      setVirtualAssistantAnswer({ text: "", group_ids: [] });
      props.isActivityDetected && props.isActivityDetected(null);
    }
  };

  // const removeVirtualAssistantHandler = (index: number) => {
  //   const oldList = [...virtualAssistantList]
  //   oldList.splice(index, 1)
  //   setVirtualAssistantList(oldList)
  //   dispatch(localUserQuestionUpdate('responses', oldList))
  // }

  const onUpdateGreetingsHandler = (greetings: AddGreeting[]) => {
    setVirtualAssistantList([...greetings]);
    dispatch(localUserQuestionUpdate("responses", [...greetings]));
  };

  const onCopyExistingGreetingHandler = async (event: any, index: number) => {
    const oldList = [...virtualAssistantList];
    oldList.unshift({ text: event.text, group_ids: event.group_ids });
    setVirtualAssistantList([...oldList]);
    dispatch(localUserQuestionUpdate("responses", oldList));
    setVirtualAssistantAnswer({ text: "", group_ids: [] });
  };

  const renderGreetings = (
    <>
      <div className="">
        <GreetingsWithGroups
          greetings={[...virtualAssistantList]}
          allUserGroups={[...props.allUserGroups]}
          onCopyExistingGreeting={onCopyExistingGreetingHandler}
          onUpdateGreetings={onUpdateGreetingsHandler}
        />
      </div>
    </>
  );
  return (
    <>
      <div className="">
        {/* <h3 className="font-weight-bold mb-3">
          What will the virtual assistant answer?
        </h3> */}
        {/* --------------------------------------- Virtual assistant wrapper -------------------------------------------------- */}
        <div
          className={
            props.nodeElement || props.createFromScratch
              ? "user-ask-ques flex-wrap third-step py-0 position-relative col-lg-12 col-xl-12"
              : "user-ask-ques flex-wrap third-step py-0 position-relative col-lg-9 col-xl-8"
          }
        >
          <RichTextArea
            showRemoveIcon={false}
            index={-1}
            item={addVirtualAssistantAnswer}
            onEditorStateChangeHandler={onVirtualAssistantEditorChange}
            onRemoveGeetingHandler={() => {}}
          />
          <PermissionWrapper>
            <button
              className="btn btn-link p-0"
              onClick={addVirtualAssistantHandler}
            >
              {t("Add")}
            </button>
          </PermissionWrapper>
        </div>
        {renderGreetings}
        {/* <ul className='list-unstyled'>
        </ul> */}
      </div>
    </>
  );
};

export default VirtualAssistantTab;
