import { AxiosResponse } from "axios";
import { RequestObject } from "../types/axios";
import AppInstance from "../axios-global";
export class ApiUtil {
  static getTokenSilently: any;
  private constructor() {}

  /**
   * To make generic  api call ie. post get etc.
   * @param requestObject
   */
  static async SendAsync<T>(
    requestObject: RequestObject
  ): Promise<AxiosResponse<T>> {
    try {
      const res: any = await AppInstance(requestObject);
      return res;
    } catch (error: any) {
      // Handling in axios interceptor
      console.error(`Error-${error?.response.status}`);
      throw error;
    }
  }
}

export default ApiUtil;
