import React from "react";
import PropTypes from "prop-types";
import ReactSelect from "react-select";
import CreatableSelect from "react-select/creatable";

import CustomOption from "./customOption";

import "./style.css";

function Dropdown({
  placeHolder,
  isSearchable,
  data,
  dropdownContainerClassName,
  dropdownClassNamePrefix,
  onChange,
  label,
  isMandatory,
  value,
  defaultValue,
  isDisabled,
  isClearable,
  isMulti,
  description,
  customStyles,
  isNested,
  onInputChange,
  isCreatable,
  onCreateOption,
  menuIsOpen,
  menuPosition,
}) {
  const localStyles = {
    menu: (provided) => ({
      ...provided,
      color: "#07032b",
      fontSize: "0.8rem",
      maxHeight: "320px",
      overflow: "auto !important",
      whiteSpace: "normal !important",
      zIndex: 1000,
    }),
    menuList: (provided) => ({
      ...provided,
      whiteSpace: "normal !important",
      overflow: "auto !important",
    }),
    control: (provided) => ({
      ...provided,
      fontSize: "0.8rem",
      color: "#07032b",
    }),
    option: (styles, state) => {
      const color = "#1e4c88";
      return {
        ...styles,
        backgroundColor: state.isSelected
          ? color
          : state.isFocused
          ? "#ddf0f9"
          : null,
        color: state.isSelected ? "#fff" : "#07032b",
        cursor: state.isDisabled ? "not-allowed" : "pointer",
        fontWeight: state.isSelected ? "600" : "500",
        // paddingLeft: state.data.isIndented ? "40px" : "20px"
        paddingLeft:
          state.data.depth === 0
            ? "20px"
            : state.data.depth === 1
            ? "40px"
            : state.data.depth === 2
            ? "60px"
            : state.data.depth === 3
            ? "80px"
            : state.data.isIndented
            ? "40px"
            : "20px",
      };
    },
    valueContainer: (provided) => ({
      ...provided,
      overflow: "hidden !important",
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#ffffff",
      color: "#5000bf",
      border: "1px solid #5000bf",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "#5000bf",
    }),
    singleValue: (provided) => ({
      ...provided,
      maxHeight: 44,
      overflow: "hidden !important",
    }),
  };

  if (isCreatable) {
    return (
      <div className={`dropdown_wrapper ${dropdownContainerClassName}`}>
        {label && (
          <label>
            {label}
            {isMandatory && <span style={{ color: "red" }}>*</span>}
          </label>
        )}
        {description && (
          <div className="dropdown-description">{description}</div>
        )}
        <CreatableSelect
          isMulti
          classNamePrefix={dropdownClassNamePrefix}
          isClearable={isClearable}
          styles={customStyles ? customStyles : localStyles}
          onChange={onChange}
          value={value}
          options={data}
          menuPosition={menuPosition ? menuPosition : "fixed"}
          isDisabled={isDisabled}
          placeholder={placeHolder}
          isSearchable={isSearchable}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: "#ddf0f9",
              primary: "#1e4c88",
            },
          })}
          onCreateOption={onCreateOption}
        />
      </div>
    );
  }

  return (
    <div className={`dropdown_wrapper ${dropdownContainerClassName}`}>
      {label && (
        <label>
          {label}
          {isMandatory && <span style={{ color: "red" }}>*</span>}
        </label>
      )}
      {description && <div className="dropdown-description">{description}</div>}
      <ReactSelect
        // minMenuHeight={300}
        menuIsOpen={menuIsOpen}
        classNamePrefix={dropdownClassNamePrefix}
        onInputChange={onInputChange}
        components={isNested && { Option: CustomOption }}
        isMulti={isMulti}
        isClearable={isClearable}
        menuPosition={menuPosition ? menuPosition : "fixed"}
        styles={customStyles ? customStyles : localStyles}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        options={data}
        isDisabled={isDisabled}
        placeholder={placeHolder}
        isSearchable={isSearchable}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: "#ddf0f9",
            primary: "#1e4c88",
          },
        })}
      />
    </div>
  );
}

Dropdown.propTypes = {
  label: PropTypes.string,
  placeHolder: PropTypes.string,
  isSearchable: PropTypes.bool,
  data: PropTypes.array,
  dropdownContainerClassName: PropTypes.string,
  dropdownListClassName: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  errorMessage: PropTypes.string,
  isFormError: PropTypes.bool,
  isMandatory: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

Dropdown.defaultProps = {
  placeHolder: "",
  isSearchable: false,
  dropdownContainerClassName: "",
  dropdownListClassName: "",
  data: [],
  onChange: (value) => {
    console.dir(value);
  },
  isMandatory: false,
  isFormError: false,
  errorMessage: "",
  isDisabled: false,
};

export default Dropdown;
