import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
    getQuizSubmissions
} from '../../../../store/dashboard/actions'
import BarCompact from '../../BarCompact'
import { useHistory } from 'react-router';
import { REPORTS_QUIZZES } from '../../../../config/index';
import InfoIconDetails from '../InfoIconDetails/InfoIconDetails';

import { useTranslation } from "react-i18next";

interface Props {
  filtersData?: {
    filter_from_date?: string
    filter_to_date?: string
    channel?: string
  }
}

function QuizSubmissions({ filtersData }: Props): ReactElement {
  const { t } = useTranslation('translation');
  const [loading, setLoading] = useState(false)
  const [quizSubmissionData, setQuizSubmissionData] = useState(null)
  const dispatch = useDispatch()
  const history = useHistory()
  useEffect(() => {
    // console.log('[ QuizSubmission filtersData ]', filtersData)
    if (
      loading ||
      !filtersData.filter_from_date ||
      !filtersData.filter_to_date
    ) {
      return
    }

    const fetchQuizSubmissionData = async (filtersData: any) => {
      setLoading(true)
      try {
        const quizSubmissionData: any = await dispatch(getQuizSubmissions(filtersData))
        await setQuizSubmissionData(quizSubmissionData)
        setLoading(false)
      } catch (e: any) {
        setLoading(false)
        console.log(
          '[[[ src_components_UI_Dashboard_ActiveUsers_ActiveUsers.tsx ]]]',
          e
        )
      }
    }
    fetchQuizSubmissionData(filtersData)
  }, [filtersData])

  return (
    <div className='col-sm-6 col-md-4 col-lg-2' onClick={() => {
      history.push(REPORTS_QUIZZES)
    }}>
      <div className='graph-box'>
        <h3>{t("Quiz submissions")}<InfoIconDetails
            message={t('Total number of quiz submissions by all the learners in the chatbot.')}
          /></h3>
        <div className='row'>
          <div className='col-4'>
            {!loading && (
              <h2>{quizSubmissionData && quizSubmissionData.data ? quizSubmissionData.count : '-' }</h2>
            )}
            {loading && (
              <div className='spinner-border spinner-border-sm text-primary'></div>
            )}
            {/* <div className='growth-percent growth-up'>10%</div> */}
          </div>
          <div className='col-8'>
            <div style={{ width: '100%', height: '50px' }}>
            {quizSubmissionData &&
                quizSubmissionData.data &&
                quizSubmissionData.data.length > 0 && (
                  <BarCompact
                    data={
                      quizSubmissionData
                        ? quizSubmissionData.data
                        : []
                    }
                    indexBy='date'
                    keys='submission_count'
                  />
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default QuizSubmissions
