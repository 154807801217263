import React, { useEffect, useState } from "react";
import { Container, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Tour from "reactour";
import backimg from "../../../assets/images/arrow-left.svg";
import {
  getKeywordsFromDocument,
  updateAllDocuments,
  resetDocuments,
} from "../../../store/knowledge/actions";
import {
  getUserDocuments,
  iskeyWordsLoading,
} from "../../../store/knowledge/selector";
import { steps } from "./GenerateQuestionsTour";
import QuestionsList from "./partials/QuestionsList";
import { HelperService } from "../../../services/helper.service";
import { updateOrganizationFlow } from "../../../store/common/actions";
import { organizationFlowObject } from "../../../app.constant";
import { Prompt } from "react-router";
import PermissionWrapper from "../../../components/UI/PermissionWrapper/PermissionWrapper";

import { useTranslation } from "react-i18next";

const helper = new HelperService();

export const GenerateQuestions = (props: any) => {
  const { t } = useTranslation('translation');
  const history = useHistory();
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [backUrl, setBackUrl] = useState("");
  const [isSaved, setIsSaved] = useState(false);
  const [keywordGenerationProcessing, SetKeywordGenerationProcessing] =
    useState(false);
  const [generatedQuestions, setGeneratedQuestions] = useState([]);
  const [updatedQuestions, setUpdatedQuestions] = useState([]);
  const dispatch = useDispatch();
  const userDocuments = useSelector(getUserDocuments);
  const keyWordsLoading = useSelector(iskeyWordsLoading);
  useEffect(() => {
    const query = new URLSearchParams(history.location.search);
    const question = query.get("question");
    const params = question.split(",");
    const backParam = query.get("backUrl");
    setBackUrl(backParam);
    if (params.length === 0 || question.trim().length === 0) {
      backUrl && backUrl === "links"
        ? history.push("/knowledge/upload-url-documents")
        : history.push("/knowledge/upload-documents");
      return;
    }

    dispatch(
      updateOrganizationFlow({
        ...organizationFlowObject,
        prevRoute:
          backUrl && backUrl === "links"
            ? "/knowledge/upload-url-documents"
            : "/knowledge/upload-documents",
        currentRoute: `${history.location.pathname}${history.location.search}`,
        nextRoute: "/knowledge/generate-answers",
        setup_completed: false,
      })
    );

    const fn = async () => {
      if (keywordGenerationProcessing) {
        return;
      }
      await dispatch({
        type: "INIT_GENERATE_ANSWERS_SCREEN",
        payload: {
          keyWordsLoading: true,
        },
      });
      await dispatch(getKeywordsFromDocument(userDocuments, params));
    };
    SetKeywordGenerationProcessing(true);
    fn();
  }, [history.location.search]);

  useEffect(() => {
    setGeneratedQuestions([...userDocuments]);
    setUpdatedQuestions([...userDocuments]);
    const keywordsLoading = userDocuments.filter(
      (document: any) =>
        document.keywords &&
        document.keywords.length === 0 &&
        !document.keywordFetchError
    );
    SetKeywordGenerationProcessing(keywordsLoading.length > 0 ? true : false);
    console.log("[ userDocuments ]", userDocuments);
  }, [userDocuments]);

  const generateAnswers = async (e: any) => {
    e.preventDefault();
    const filteredDocuments = userDocuments.filter(
      (d: any) => d.keywords.length > 0
    );
    if (filteredDocuments.length === 0) {
      helper.showToaster(
        t("None of documents has questions, Please add questions before proceeding.")
      );
      return;
    }

    await dispatch(updateAllDocuments(filteredDocuments));
    await setIsSaved(true);
    const ID = filteredDocuments.map((d: any) => d._id).join(",");
    dispatch(
      updateOrganizationFlow({
        ...organizationFlowObject,
        prevRoute:
          backUrl && backUrl === "links"
            ? "/knowledge/upload-url-documents"
            : "/knowledge/upload-documents",
        currentRoute: `${history.location.pathname}${history.location.search}`,
        nextRoute: `/knowledge/generate-answers?question=${ID}${
          backUrl && backUrl === "links" ? "&backUrl=links" : ""
        }`,
        setup_completed: false,
      })
    );
    history.push(
      `/knowledge/generate-answers?question=${ID}${
        backUrl && backUrl === "links" ? "&backUrl=links" : ""
      }`
    );
  };

  const updatedQuestionsHandler = (data: any) => {
    const oldQuestions = [...generatedQuestions];
    const document = oldQuestions.find((d) => d._id === data.documentId);
    document.keywords =
      data.keywords && data.keywords.length > 0
        ? [...data.keywordsQnas.map((key: any) => key.question)]
        : [];
    document.keywordsQnas =
      data.keywordsQnas && data.keywordsQnas.length > 0
        ? [...data.keywordsQnas]
        : [];
    document.keywordsLoading = data.keywordsLoading
      ? data.keywordsLoading
      : false;
    document.keywordFetchError = data.keywordFetchError
      ? data.keywordFetchError
      : false;
    document.last_offset = data.last_offset ? data.last_offset : 0;
    document.retryExecution = data.retryExecution ? data.retryExecution : false;
    setUpdatedQuestions([...oldQuestions]);
    const keywordsLoading = oldQuestions.filter(
      (document: any) =>
        document.keywords.length === 0 && !document.keywordFetchError
    );
    SetKeywordGenerationProcessing(keywordsLoading.length > 0 ? true : false);
  };

  return (
    <>
      <Prompt
        when={!isSaved && props.location.search.length !== 10}
        message={t("Your questions aren't saved yet. Are you sure you want to leave ?")}
      />
      <section className="page-mid-wraper">
        <Container>
          <div className="row">
            <div className="col-md-12">
              <button
                className="btn p-0 btn-back"
                onClick={() =>
                  backUrl && backUrl === "links"
                    ? history.push("/knowledge/upload-url-documents")
                    : history.push("/knowledge/upload-documents")
                }
              >
                <Image src={backimg} /> {t("Return to previous page")}
              </button>
            </div>
          </div>

          {generatedQuestions.map((document, index) => {
            return (
              <QuestionsList
                key={document._id}
                index={index}
                {...document}
                documentLength={userDocuments.length}
                updatedQuestions={updatedQuestionsHandler}
              />
            );
          })}
        </Container>
      </section>
      <footer>
        <Container>
          <div className="d-flex align-items-center justify-content-between">
            <PermissionWrapper>
              <button
                className="btn btn-primary third-step"
                onClick={generateAnswers}
                disabled={keywordGenerationProcessing}
              >
                {t("Generate the answers")}
              </button>
            </PermissionWrapper>
          </div>
        </Container>
      </footer>
      <Tour
        steps={steps}
        isOpen={isTourOpen}
        maskClassName={"maskbg"}
        onRequestClose={() => setIsTourOpen(false)}
        showNumber={false}
        rounded={5}
        accentColor={"#F9B234"}
      />
    </>
  );
};

export default React.memo(GenerateQuestions);
