import * as type from "./actionType";

export interface NotificationInterface {
  author_email?: string | null;
  author_id?: string | null;
  content?: string;
  created_at?: string;
  excerpt?: string;
  icon?: string;
  image?: string;
  link?: string;
  mail?: string;
  title?: string;
  type?: string;
  buttons?: any;
  updated_at?: string;
  user_id?: string;
  _id?: string;
  is_popup?: boolean;
  is_read?: boolean;
}

export interface NotificationSettingInterface {
  all_channels: {
    name?: string,
    label?: string 
  }[]
  all_events: {
    name?: string,
    label?: string 
  }[]
  channels: string[]
  subscribed_events: string[]
}

interface NotificationsState {
  loading: boolean;
  notifications: NotificationInterface[];
  setting: NotificationSettingInterface;
  users: any;
  buttonOptions?: any[];
  classes?: any[];
  groups?: any[];
}

const initialState: NotificationsState = {
  loading: false,
  notifications: [],
  setting: null,
  users: [],
  buttonOptions: [],
  groups: [],
  classes: [],
}

const NotificationsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case type.GETALLNOTIFICATIONS:
      return {
        ...state,
        ...action.payload
      }
    case type.GETALLNOTIFICATIONSETTING:
      return {
        ...state,
        ...action.payload
      }
    case type.GETALLUSERS:
      return {
        ...state,
        ...action.payload
      }
    case type.GETALLUSERGROUPS:
      return {
        ...state,
        ...action.payload
      }
    default:
      return {
        ...state
      };
  }
};

export default NotificationsReducer;
