import React, { useEffect, useState } from 'react'
import { Image } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import close from '../../../assets/images/close-blk.svg'
import setting from '../../../assets/images/cog.svg'
import { getTestBotLink } from '../../../store/common/actions'

import { useTranslation } from "react-i18next";

interface Props {
  toggleBotView: (e: any) => void
}

export const TestBot = (props: Props) => {
  const { t } = useTranslation('translation');
  const [loading, setloading] = useState(false)
  const [link, setlink] = useState(null)
  const dispatch = useDispatch()

  useEffect(() => {
    const TestBot = async () => {
      const botLink = await dispatch(getTestBotLink())
      setlink(botLink)
      // setloading(false)
    }
    TestBot()
    return () => {
      setlink(null)
    }
  }, [])

  const refreshLinkHandler = async (e: any) => {
    setloading(true)
    const botLink: any = await dispatch(getTestBotLink())
    setlink(botLink)
    setloading(false)
  }

  return (
    <React.Fragment>
      <div className='notify-header'>
        <h2>
          {t("Test Bot")}
          <button className='btn ml-auto' onClick={refreshLinkHandler}>
            <svg
              version='1.1'
              width='15'
              height='15'
              viewBox='0 0 20 20'
            >
              <path
                fill='#000000'
                d='M2.49 4.49c1.605-1.605 3.74-2.49 6.010-2.49s4.405 0.884 6.010 2.49 2.49 3.74 2.49 6.010v1.293l2.146-2.146c0.195-0.195 0.512-0.195 0.707 0s0.195 0.512 0 0.707l-3 3c-0.098 0.098-0.226 0.146-0.354 0.146s-0.256-0.049-0.354-0.146l-3-3c-0.195-0.195-0.195-0.512 0-0.707s0.512-0.195 0.707 0l2.146 2.146v-1.293c0-4.136-3.364-7.5-7.5-7.5s-7.5 3.364-7.5 7.5c0 4.136 3.364 7.5 7.5 7.5 0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5c-2.27 0-4.405-0.884-6.010-2.49s-2.49-3.74-2.49-6.010c0-2.27 0.884-4.405 2.49-6.010z'
              ></path>
            </svg>
          </button>
          <button className='btn' onClick={props.toggleBotView}>
            <Image src={close} />
          </button>
        </h2>
      </div>
      <div className='notify-scrol'>
        <iframe
          className='test-bot-iframe'
          src={link}
          title={t('Chat bot')}
        ></iframe>
      </div>
    </React.Fragment>
  )
}
