import React from "react";
import { NavDropdown } from "react-bootstrap";
import { NavLink, useHistory } from "react-router-dom";
import * as CONFIG from "../../../config";
import {
  ONBOARD,
  LEARNING,
  MATCHED_UNMATCHED_QUESTIONS,
  OLDREPORTS,
  REPORTS_QUIZZES,
  USAGETRENDS,
  OVERVIEW,
} from "../../../config";
import cx from "classnames";
import { useTranslation } from "react-i18next";

export default function DashboardNavigation() {
  const { t } = useTranslation("translation");
  const history = useHistory();
  const navBarItems = [
    { route: CONFIG.OLDREPORTS_OVERVIEW, name: t("Overview") },
    // { route: CONFIG.OLDREPORTS_FEEDBACK, name: t('Feedback') },
    // { route: CONFIG.OLDREPORTS_TOPICS, name: t('Topics') },
    { route: CONFIG.OLDREPORTS_ACTIVITY, name: t("Activity") },
    // { route: CONFIG.OLDREPORTS_CONTENT, name: t('Content') },
  ];

  const navItemHandler = (e: any) => {
    e.preventDefault();
    history.push(e.target.dataset.route);
  };

  return (
      <nav className="nav">
        <NavLink activeClassName="active" to={OVERVIEW} className="nav-link">
          {t("Overview")}
        </NavLink>

        {/* <NavLink activeClassName="active" to={LEARNING} className="nav-link">
          {t("Learning")}
        </NavLink> */}

        <NavLink
            activeClassName="active"
            to={CONFIG.RESPONSES}
            className="nav-link"
        >
          {t("Responses")}
        </NavLink>

        <NavLink
            activeClassName="active"
            to={CONFIG.DISCUSSION}
            className="nav-link"
        >
          {t("Discussion")}
        </NavLink>

        <NavLink activeClassName="active" to={USAGETRENDS} className="nav-link">
          {t("Usage Trends")}
        </NavLink>

        <NavLink
            activeClassName="active"
            to={MATCHED_UNMATCHED_QUESTIONS}
            className="nav-link"
        >
          {t("Bot Experience")}
        </NavLink>

        <NavLink
            activeClassName="active"
            to={REPORTS_QUIZZES}
            className="nav-link"
        >
          {t("Quizzes")}
        </NavLink>

        {/* <NavDropdown
        title={t('More Reports')}
        id='basic-nav-dropdown'
        active={
          history.location.pathname === CONFIG.OLDREPORTS_OVERVIEW ||
          // history.location.pathname === CONFIG.OLDREPORTS_FEEDBACK ||
          // history.location.pathname === CONFIG.OLDREPORTS_TOPICS ||
          history.location.pathname === CONFIG.OLDREPORTS_ACTIVITY
          // history.location.pathname === CONFIG.OLDREPORTS_CONTENT
        }
      >
        {navBarItems.map((item: any, index: number) => {
          return (
            <NavDropdown.Item
              onClick={navItemHandler}
              data-route={item.route}
              className={cx({
                active: history.location.pathname === item.route,
              })}
            >
              {item.name}
            </NavDropdown.Item>
          );
        })}
      </NavDropdown> */}

        {/* <NavLink activeClassName = "active" to={OLDREPORTS} className='nav-link'>
            Old Reports
          </NavLink> */}
        {/* <a>Learning</a>
          <a>Knowledge</a>
          <a>Bot Experience</a>
          <a>Forms</a>
          <a>Quizzes</a> */}
      </nav>
  );
}
