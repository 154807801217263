import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import createIndividualSummaryImg from "../../assets/New-images/create-individual-summary-img.svg";
import summariseIntoSingleImg from "../../assets/New-images/summarise-into-single-img.svg";
import chatImg1 from "../../assets/New-images/chatbotImg1.svg";
import chatImg2 from "../../assets/New-images/chatbotImg2.svg";
import { BUILD_KNOWLEDGE_V2 } from "../../config";

const BuildKnowledegBaseOptions = (props: any) => {
  const history: any = useHistory();
  const { t } = useTranslation("translation");
  //   const query = new URLSearchParams(history.location.search);
  //   const documentId = query.get("references");
  //   const params: any = useParams();
  //   const [groupId, setGroupId] = useState(query.get("group") || "");
  //   const ids = documentId.split(",") || "";
  //   const [learningOption, setLearningOption] = useState("");

  //   useEffect(() => {
  //     setGroupId(
  //       history &&
  //         history.location &&
  //         history.location.state &&
  //         history.location.state.group
  //         ? history.location.state.group
  //         : query.get("group")
  //     );
  //   }, []);

  return (
    <React.Fragment>
      <section className="page-mid-wraper">
        <Container>
          <>
            <div className="d-flex flex-wrap justify-content-center clr-primary">
              <h2 className="mb-2 font-24">
                {t("Expand your Knowledge base")}
              </h2>
            </div>
            <div className="mb-32 font-16 text-center font-600">
              {t("Select how you would like to get started")}
            </div>
          </>

          <>
            <div className="build-summary-docs px-lg-5 mx-lg-4">
              <div
                className="knowledge-box know-box-new radius-8 p-3 not-hover d-flex align-items-center mb-3"
                onClick={() =>
                  history.push(
                    `${BUILD_KNOWLEDGE_V2.replace(":step", "1")}?type=automated`
                  )
                }
              >
                <figure className="h-auto mb-0">
                  <img src={chatImg2} alt="" />
                </figure>
                <div className="text-left pl-3">
                  <h3 className="font-weight-bold font-1 clr-primary mb-1">
                    {t("Automated Setup")}
                  </h3>
                  <p className="clr-grey-txt7 font-sm font-600">
                    {t(
                      "Let us take care of it. We'll automatically set up your knowledge base for you."
                    )}
                  </p>
                </div>
              </div>
              <div
                className="knowledge-box know-box-new radius-8 p-3 not-hover d-flex align-items-center mb-3"
                onClick={() =>
                  history.push(
                    `${BUILD_KNOWLEDGE_V2.replace(":step", "1")}?type=guided`
                  )
                }
              >
                <figure className="h-auto mb-0">
                  <img src={chatImg1} alt="" />
                </figure>
                <div className="text-left pl-3">
                  <h3 className="font-weight-bold font-1 clr-primary mb-1">
                    {t("Guided Setup")}
                  </h3>
                  <p className="clr-grey-txt7 font-sm font-600">
                    {t(
                      "Follow our step-by-step guide to set up your knowledge base yourself"
                    )}
                  </p>
                </div>
              </div>
            </div>
          </>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default BuildKnowledegBaseOptions;
