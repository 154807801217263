import React, { useState } from 'react'
import "./index.scss";

const LinkOption = (props: any) => {
  const [linkTitle, setLinkTitle] = useState(
    props.currentState.link && props.currentState.link.title
      ? props.currentState.link.title
      : ''
  )
  const [linkTarget, setLinkTarget] = useState(
    props.currentState.link && props.currentState.link.target
      ? props.currentState.link.target
      : ''
  )
  const [targetOption, setTargetOption] = useState(
    props.currentState.link && props.currentState.link.targetOption
      ? props.currentState.link.targetOption
      : '_blank'
  )
  const [showTargetError, setShowTargetError] = useState(false)

  const stopPropagation = (event: any) => {
    event.stopPropagation()
  }

  const onFieldChange = (value: any, key: any) => {
    if (showTargetError) {
      setShowTargetError(false)
    }
    if (key === 'title') {
      setLinkTitle(value)
    } else if (key === 'target') {
      setLinkTarget(value)
    }
  }

  const onChange = () => {
    if (!linkTarget) {
      setShowTargetError(true)
    }
    if (linkTitle && linkTarget) {
      props.onChange('link', linkTitle, linkTarget, targetOption)
      setLinkTitle('')
      setLinkTarget('')
    } else if (!linkTitle && linkTarget) {
      props.onChange('link', linkTarget, linkTarget, targetOption)
      setLinkTitle('')
      setLinkTarget('')
    }
  }

  const renderModal = () => {
    return (
      <div onClick={stopPropagation} className='rdw-link-modal'>
        <label className="textinput-label">
          Video/website link (video links will be previewed in the chat)
        </label>
        <input
          autoFocus
          required
          value={linkTarget}
          onChange={(e) => onFieldChange(e.target.value, 'target')}
          className="custom-input richtext-input"
        />
        {showTargetError && (
          <div className='textinput-error'>This field is required</div>
        )}

        <div className='clearfix' />
        <label className="textinput-label">
          Link title (optional, can be used to create hyperlink tags)
        </label>
        <input
          autoFocus
          required
          value={linkTitle}
          onChange={(e) => onFieldChange(e.target.value, 'title')}
          className="custom-input richtext-input"
        />
        <div className='link-modal-action-bar'>
          <button className='btn btn-link py-0 custom-icon-label-button' onClick={onChange}>
            Add
          </button>
          <button
            className='btn btn-link py-0 custom-icon-label-button'
            onClick={props.doCollapse}
          >
            Cancel
          </button>
        </div>
      </div>
    )
  }

  return (
    <div
      aria-haspopup='true'
      aria-expanded={props.expanded}
      aria-label='rdw-link'
      className='rdw-link-wrapper'
    >
      <div
        onClick={props.onExpandEvent}
        className='rdw-option-wrapper markdown-toolbar-option'
      >
        <img src={props.config.link.icon} alt='' />
      </div>
      {props.expanded ? renderModal() : undefined}
    </div>
  )
}

export default LinkOption
