import * as React from 'react'
import '../Pagination/Pagination.scss'
import cx from 'classnames'

import { useTranslation } from "react-i18next";

export interface ScrollButtonsProps {
  scrollRightValue?: number
  scrollWrapper?: string
  showScrollButtons?: boolean
}

const ScrollButtons: React.FC<ScrollButtonsProps> = ({
  showScrollButtons,
  scrollRightValue,
  scrollWrapper,
}: ScrollButtonsProps) => {
  const { t } = useTranslation('translation');
  // Scroll related code starts from here
  const [scroll, setScroll] = React.useState(0)
  React.useEffect(() => {
    if (!showScrollButtons) {
      return
    }

    setScroll(scrollRightValue)
    return () => {
      setScroll(0)
    }
  }, [scrollRightValue])

  const btnLeftHandler = (e: any) => {
    e.preventDefault()
    const el = document.getElementsByClassName(scrollWrapper)
    let scrollLeft = el[0].scrollLeft
    el[0].scrollLeft -= 200
    setScroll((scrollLeft -= 200))
  }

  const btnRightHandler = (e: any) => {
    e.preventDefault()
    const el = document.getElementsByClassName(scrollWrapper)
    let scrollLeft = el[0].scrollLeft
    el[0].scrollLeft += 200
    setScroll((scrollLeft += 200))
  }
  // Scroll related code ends here

  return (
    <div className='pagination-wraper d-flex flex-wrap justify-content-between align-items-center py-3 mb-2'>
      {showScrollButtons && (
        <button
          onClick={btnLeftHandler}
          data-pos='left'
          className={cx({ 'table-slide tbl-L': true, active: scroll > 0 })}
        >
          <svg width='15.657' height='11' viewBox='0 0 15.657 11'>
            <path
              id='arrow-left'
              d='M.623,9.2,5.7,4.124a.423.423,0,0,1,.6.6L1.944,9.077H15.733a.423.423,0,1,1,0,.846H1.944L6.3,14.278a.423.423,0,0,1-.6.6L.623,9.8a.423.423,0,0,1,0-.6Z'
              transform='translate(-0.499 -4)'
              fill='#cdd5ef'
            />
          </svg>
        </button>
      )}
      {showScrollButtons && (
        <button
          onClick={btnRightHandler}
          data-pos='right'
          className='table-slide active tbl-R'
        >
          <svg width='15.657' height='11' viewBox='0 0 15.657 11'>
            <path
              id='arrow-right'
              d='M16.532,9.2,11.455,4.124a.423.423,0,0,0-.6.6l4.355,4.355H1.423a.423.423,0,1,0,0,.846H15.211l-4.355,4.355a.423.423,0,0,0,.6.6L16.533,9.8a.423.423,0,0,0,0-.6Z'
              transform='translate(-1 -4)'
              fill='#cdd5ef'
            />
          </svg>
        </button>
      )}
    </div>
  )
}

export default ScrollButtons;
