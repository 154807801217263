import React from "react";
import { useTranslation } from "react-i18next";

import { Image } from "react-bootstrap";
import noodleLogo from "./../../assets/images/noodle-text.svg";
import sendEmailImg from "./../../assets/images/walter-head.svg";
import email from "./../../assets/images/email-resend.svg";

import "./styles.scss";
import history from "../../utils/history";

const windowObj: any = window


export const VerifiedEmail = (props: any) => {
  const { t } = useTranslation("translation");
  const query = new URLSearchParams(history.location.search);
  const redUrl = query.get("next_url")
    ? query.get("next_url")
    : windowObj.NEW_PORTAL_URI;
  return (
    <div className="d-flex verify-email-container">
      <div className="green-bg d-flex flex-1 flex-column align-items-center justify-content-center max-col">
        <Image className="top-logo" src={noodleLogo} />
        <div className="text-center px-4">
          <h2 className="text-center ai-head-1 font-weight-bold clr-pink">
            {t("Your Email has been Verified")}
          </h2>
          <figure className="text-center my-3"><Image src={email} alt="" className="email-img"/></figure>
          <div className="pt-2 font-16">
            {t(
              "You're all set! Your email address has been verified and you can now login to the portal"
            )}
          </div>
          <div className="btn-wrapper mx-auto px-0 px-lg-4">
            <button
              className="btn btn-primary w-100 font-600"
              onClick={() => {
                window.location.href = redUrl;
              }}
            >
              {t("Login Now")}
            </button>
          </div>
        </div>
      </div>
      <div className="d-none d-md-flex flex-column align-items-center justify-content-center max-col">
        <h1 className="text-center ai-head-1 font-800">
          {t("Congratulations! You're now ready to")} <br/>
          <span className='clr-blue'> {t("embark on your journey")}</span>
          {t(" to shape the")}
          <span className='clr-pink d-block'> {t("Future of Education!")}</span>
        </h1>
        <figure className="text-center">
          <Image src={sendEmailImg} alt="" className="mw-70"/>
        </figure>
      </div>
    </div>
  );
};

export default VerifiedEmail;
