import React, { useEffect, useState } from "react";
import { Container, Dropdown, Image, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import sortIcon from "../../../assets/New-images/sort-blue-icon.svg";
import arrowLeft from "../../../assets/images/arrow-left.svg";
import { QUIZZALLREVIEWREQUESTSBYID } from "../../../config";

import {
  getAllLevels,
  getAllModulesFromYear,
} from "../../../store/knowledge/actions";
import { getAllQuizzes } from "../../../store/quizzes/actions";
// import CreateQuizModal from "./CreateQuizModal";
import Select from "react-select";

function QuizessPendingReview(props: any) {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [years, setYears] = useState([]);

  const [modules, setModules] = useState([]);

  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [hasTopLevelApplied, setHasTopLevelApplied] = useState(true);
  const { isArchive = false } = props;
  const [filters, setFilters] = useState({
    sort_field: "updated_at",
    sort_order: "dsc",
    topic_ids: null,
    knowledge_group_ids: null,
    q: null,
    includes_all_subgroups: null,
    isArchive: isArchive,
  });
  const [quizzes, setQuizzes] = useState([]);
  const [selectedChannel, setChannels] = useState(null);

  useEffect(() => {
    // setChannels({
    //   value: years[0]?._id,
    //   label: years[0]?.name,
    // });
    // setSelectedGroupId(years[0]?._id);
  }, [years]);

  useEffect(() => {
    getAllYearsFun();
  }, []);

  useEffect(() => {
    // if (!selectedGroupId) {
    //   return;
    // }
    getAllQuizzesFun({
      ...filters,
      knowledge_group_ids: selectedGroupId,
      includes_all_subgroups: hasTopLevelApplied,
    });
  }, [filters, selectedGroupId, hasTopLevelApplied]);

  const getAllQuizzesFun = async (filters: any) => {
    try {
      const response: any = await dispatch(getAllQuizzes(filters));
      const quiz = response.filter((q: any) => {
        return q.reviews_count > 0;
      });
      setQuizzes(quiz);
    } catch (error) {
      console.log("error", error);
    }
  };

  const getAllYearsFun = async (loadSubModules: boolean = true) => {
    try {
      const response: any = await dispatch(getAllLevels());
      setYears([...response]);
      response &&
        response.length > 0 &&
        getAllModulesFromYearFun(
          selectedGroupId ? selectedGroupId : response[0]._id
        );
    } catch (error: any) {
      setYears([]);
    }
  };

  const getAllModulesFromYearFun = async (year: any) => {
    try {
      const response: any = await dispatch(getAllModulesFromYear(year));
      setModules([...response]);
    } catch (error: any) {
      setModules([]);
    }
  };

  /**
   * Set debounce time for while searching in any input
   */
  const [query, setQuery] = useState("");
  const [isTyping, setTyping] = useState(false);
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (isTyping) {
        // setCurrentPage(1);
        setFilters({
          ...filters,
          q: query,
        });
        setTyping(false);
      }
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [query]);

  const onInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setQuery(e.target.value);
    setTyping(true);
  };

  const onSortHandler = (selector: any, direction: string) => {
    setFilters({
      ...filters,
      sort_field: selector,
      sort_order: direction,
    });
  };

  // get module names
  const getLevelNames = (levels: string[]) => {
    let moduleAsString: string[] = [];

    levels.forEach((level) => {
      const moduleObj = modules.find((m: any) => m.value === level);
      if (moduleObj) {
        moduleAsString.push(moduleObj.label);
      }
    });
    return moduleAsString.length === 0
      ? "Uncategorised"
      : moduleAsString.join(", ");
  };

  const quizHtml = (
    <>
      <div className="all-knowledge-search d-flex flex-wrap align-items-center mb-2">
        <Dropdown className="mr-2 sort-dropdown">
          <Dropdown.Toggle
            className="btn btn-outline-primary font-600 py-1 my-1 "
            variant="default"
            id="dropdown-basic"
          >
            <img src={sortIcon} alt="" className="mr-2" />
            {t("Sort")}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => {
                onSortHandler("updated_at", "dsc");
              }}
            >
              {t("Most Recent")}
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                onSortHandler("name", "asc");
              }}
            >
              {t("Ascending")}
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                onSortHandler("name", "dsc");
              }}
            >
              {t("Descending")}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <div className=" d-flex flex-wrap doc-select-dropdown">
          <Select
            className="select-sort select-target select-blue shadow-none radius-8 mr-2"
            classNamePrefix="select-target-prefix"
            isSearchable={false}
            placeholder={t("All Year")}
            options={years?.map((y) => {
              return {
                value: y._id,
                label: y.name,
              };
            })}
            onChange={(e) => {
              setChannels(e);
              setSelectedGroupId(e.value);
            }}
            value={selectedChannel ? selectedChannel : null}
          />
        </div>

        <div className="web-links my-0 mr-0">
          <input
            type="text"
            className="form-control h-auto"
            placeholder={t("Search by quiz")}
            onChange={onInputChangeHandler}
          />
        </div>
      </div>

      <div className="row mt-3 px-3">
        {quizzes.map((quiz: any) => {
          return (
            <div className="col-sm-3 col-lg-3 mb-4">
              <div className="quiz-box" role="button">
                <div className="quiz-ques-length mb-4">
                  <h2 className="font-32 clr-black font-600 mb-0">
                    {quiz.reviews_count}
                  </h2>
                  <div
                    className={
                      quiz.reviews_count === 0
                        ? "error pending-review-indicator"
                        : "pending-review-indicator"
                    }
                  ></div>
                  <p className="clr-grey-txt7 font-600">
                    {t("Pending Review(s)")}
                  </p>
                </div>
                <div className="quiz-name mb-4">
                  <h2 className="mb-1">{quiz.name}</h2>
                  <p className="clr-grey-txt7 font-600">
                    {getLevelNames(
                      quiz.knowledge_group_ids ? quiz.knowledge_group_ids : []
                    )}
                  </p>
                </div>
                <div className="">
                  <button
                    className="btn font-sm font-600 btn-outline-primary border-0 pl-0 pr-1 py-0 mr-2"
                    onClick={(e) => {
                      e?.stopPropagation();
                      history.push(
                        QUIZZALLREVIEWREQUESTSBYID.replace(":quizId", quiz._id)
                      );
                    }}
                  >
                    {t("Review")}
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );

  return (
    <>
      <section className="page-mid-wraper h-without-foter">
        <div>
          <div className="row">
            <div className="col-md-12">
              <button
                className="btn p-0 btn-back"
                onClick={() => {
                  history.goBack();
                }}
              >
                <Image src={arrowLeft} />
                {t("Return to previous page")}
              </button>
            </div>
            <div className="col-md-12">
              <div className="mb-12 d-flex justify-content-between align-items-center">
                <div>
                  <h1 className="mb-2">{t("Quizzes Pending Review")}</h1>
                  <p className="mb-0 font-16 font-600">
                    {t("View all quizzes that are pending review")}
                  </p>
                </div>
              </div>
              <div>
                <Row className="mb-4 position-relative">
                  <div className={"knowledge-viewby col-lg-12 col-md-12"}>
                    {quizHtml}
                  </div>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default QuizessPendingReview;
