import React from "react";
import {
    Modal,
    ModalTitle,
    Button,
    ModalBody,
    ModalFooter,
    Image,
} from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import closeImg from "../../../assets/New-images/build-knowledge-close-img.svg";
import { useTranslation } from "react-i18next";

const DeleteAgentConfirmationPopup = (props: any) => {
    const { t } = useTranslation("translation");
    return (
        <React.Fragment>
            <Modal show={props.show} centered dialogClassName="delete-knowledge-confirmation">
                <ModalHeader className="clr-black pt-4 px-3 justify-content-between">
                    <ModalTitle id="contained-modal-title-vcenter">
                        <h2 className="mb-0 font-1">
                            {t("Confirm Delete")}
                        </h2>
                    </ModalTitle>
                    <button className="btn" onClick={props.onHide}>
                        <img src={closeImg} />
                    </button>
                </ModalHeader>
                <ModalBody>
                    <div className="font-600">
                        Are you sure you want to <span className="clr-red">delete</span> this agent?
                        <br />All content in the agents would also be deleted.
                    </div>
                </ModalBody>
                <ModalFooter className="border-top-0">
                    <button className="btn-outline-danger btn mx-2 font-600 py-1" onClick={(e) => props.confirmHandler(e, props.selectedRow)}>
                        {t("Delete")}
                    </button>
                    <button className="btn-primary btn mx-2 font-600 py-1" onClick={props.onHide}>
                        {t("Cancel")}
                    </button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
};

export default DeleteAgentConfirmationPopup;
