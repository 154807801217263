import bulletlist from "./assets/editor/bullet-list.svg";
import bold from "./assets/editor/Icon-feather-bold.svg";
import italic from "./assets/editor/Icon-feather-italic.svg";
import numberlist from "./assets/editor/number-list.svg";
import strike from "./assets/editor/strike.svg";
import subscript from "./assets/editor/subscript.svg";
import superscript from "./assets/editor/superscript.svg";
import underline from "./assets/editor/underline.svg";

export const OBJECTIVES = [
  // 'Tutoring my students',
  "Tutoring my learners",
  "Answering common queries",
  "Marking of assessments",
  "Student support",
  "Helpdesk support",
  "Employee onboarding",
  "Information management",
  "Mentoring",
];

export const GENERATEDURLS = [
  { name: "https://noodlefactory.ai/products", checked: false },
  { name: "https://noodlefactory.ai/products", checked: false },
  { name: "https://noodlefactory.ai/products", checked: false },
  { name: "https://noodlefactory.ai/products", checked: false },
];

export const PREDICTEDQUESTIONS = [
  "Question text which can span across multiple lines. This card will be scrollable. 0",
  "Question text which can span across multiple lines. This card will be scrollable. 1",
  "Question text which can span across multiple lines. This card will be scrollable. 2",
  "Question text which can span across multiple lines. This card will be scrollable. 3",
  "Question text which can span across multiple lines. This card will be scrollable. 4",
  "Question text which can span across multiple lines. This card will be scrollable. 5",
  "Question text which can span across multiple lines. This card will be scrollable. 6",
  "Question text which can span across multiple lines. This card will be scrollable. 7",
];

export const organizationFlowObject: any = {
  prevRoute: null,
  currentRoute: null,
  nextRoute: null,
  setup_completed: null,
};

export const emojiList = [
  "😀",
  "😁",
  "😂",
  "😃",
  "😉",
  "😋",
  "😎",
  "😍",
  "😗",
  "🤗",
  "🤔",
  "😣",
  "😫",
  "😴",
  "😌",
  "🤓",
  "😛",
  "😜",
  "😠",
  "😇",
  "😷",
  "😈",
  "👻",
  "😺",
  "😸",
  "😹",
  "😻",
  "😼",
  "😽",
  "🙀",
  "🙈",
  "🙉",
  "🙊",
  "👼",
  "👮",
  "🕵",
  "💂",
  "👳",
  "🎅",
  "👸",
  "👰",
  "👲",
  "🙍",
  "🙇",
  "🚶",
  "🏃",
  "💃",
  "⛷",
  "🏂",
  "🏌",
  "🏄",
  "🚣",
  "🏊",
  "⛹",
  "🏋",
  "🚴",
  "👫",
  "💪",
  "👈",
  "👉",
  "👉",
  "👆",
  "🖕",
  "👇",
  "🖖",
  "🤘",
  "🖐",
  "👌",
  "👍",
  "👎",
  "✊",
  "👊",
  "👏",
  "🙌",
  "🙏",
  "🐵",
  "🐶",
  "🐇",
  "🐥",
  "🐸",
  "🐌",
  "🐛",
  "🐜",
  "🐝",
  "🍉",
  "🍄",
  "🍔",
  "🍤",
  "🍨",
  "🍪",
  "🎂",
  "🍰",
  "🍾",
  "🍷",
  "🍸",
  "🍺",
  "🌍",
  "🚑",
  "⏰",
  "🌙",
  "🌝",
  "🌞",
  "⭐",
  "🌟",
  "🌠",
  "🌨",
  "🌩",
  "⛄",
  "🔥",
  "🎄",
  "🎈",
  "🎉",
  "🎊",
  "🎁",
  "🎗",
  "🏀",
  "🏈",
  "🎲",
  "🔇",
  "🔈",
  "📣",
  "🔔",
  "🎵",
  "🎷",
  "💰",
  "🖊",
  "📅",
  "✅",
  "❎",
  "💯",
];

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const supportedFilesKnowledge = [
  // '.doc',
  // '.ppt',
  ".pptx",
  ".docx",
  ".pdf",
  ".txt",
  ".html",
  // '.xlsx'
];

export const supportedFilesKnowledgeForFaQs = [
  ".docx", // qna
  ".pdf", // qna
  ".txt", // qna
  ".xlsx", // qna
];

export const supportedFilesKendra = [
  ".docx", // qna
  ".pdf", // qna
  ".pptx", // qna
];

export const CalendarData = [
  {
    value: 132,
    day: "2016-02-27",
  },
  {
    value: 52,
    day: "2016-05-24",
  },
  {
    value: 160,
    day: "2017-01-28",
  },
  {
    value: 80,
    day: "2015-12-03",
  },
  {
    value: 304,
    day: "2015-04-05",
  },
  {
    value: 35,
    day: "2016-04-08",
  },
  {
    value: 321,
    day: "2018-03-02",
  },
  {
    value: 378,
    day: "2017-04-25",
  },
  {
    value: 22,
    day: "2018-03-16",
  },
  {
    value: 166,
    day: "2016-06-01",
  },
  {
    value: 215,
    day: "2016-01-09",
  },
  {
    value: 109,
    day: "2016-08-02",
  },
  {
    value: 185,
    day: "2015-11-08",
  },
  {
    value: 110,
    day: "2017-04-21",
  },
  {
    value: 222,
    day: "2015-05-17",
  },
  {
    value: 343,
    day: "2015-10-23",
  },
  {
    value: 350,
    day: "2017-01-25",
  },
  {
    value: 292,
    day: "2018-07-07",
  },
  {
    value: 116,
    day: "2015-04-25",
  },
  {
    value: 268,
    day: "2015-06-07",
  },
  {
    value: 138,
    day: "2017-10-29",
  },
  {
    value: 376,
    day: "2016-02-07",
  },
  {
    value: 168,
    day: "2018-03-05",
  },
  {
    value: 234,
    day: "2018-07-01",
  },
  {
    value: 291,
    day: "2016-12-31",
  },
  {
    value: 201,
    day: "2016-10-12",
  },
  {
    value: 343,
    day: "2016-06-07",
  },
  {
    value: 68,
    day: "2018-02-05",
  },
  {
    value: 33,
    day: "2016-03-05",
  },
  {
    value: 103,
    day: "2017-01-11",
  },
  {
    value: 384,
    day: "2016-01-01",
  },
  {
    value: 183,
    day: "2016-02-06",
  },
  {
    value: 344,
    day: "2017-08-20",
  },
  {
    value: 186,
    day: "2015-07-24",
  },
  {
    value: 57,
    day: "2017-08-04",
  },
  {
    value: 93,
    day: "2018-02-23",
  },
  {
    value: 212,
    day: "2016-01-03",
  },
  {
    value: 361,
    day: "2018-03-01",
  },
  {
    value: 47,
    day: "2018-01-06",
  },
  {
    value: 13,
    day: "2016-10-15",
  },
  {
    value: 63,
    day: "2016-09-24",
  },
  {
    value: 370,
    day: "2016-03-31",
  },
  {
    value: 280,
    day: "2015-10-16",
  },
  {
    value: 285,
    day: "2017-06-02",
  },
  {
    value: 240,
    day: "2018-04-18",
  },
  {
    value: 166,
    day: "2017-05-29",
  },
  {
    value: 89,
    day: "2017-07-30",
  },
  {
    value: 86,
    day: "2016-11-08",
  },
  {
    value: 314,
    day: "2016-11-20",
  },
  {
    value: 231,
    day: "2016-03-29",
  },
  {
    value: 222,
    day: "2016-07-28",
  },
  {
    value: 272,
    day: "2015-12-29",
  },
  {
    value: 327,
    day: "2015-11-22",
  },
  {
    value: 265,
    day: "2015-06-05",
  },
  {
    value: 227,
    day: "2016-11-07",
  },
  {
    value: 32,
    day: "2015-10-08",
  },
  {
    value: 356,
    day: "2016-12-28",
  },
  {
    value: 298,
    day: "2018-01-09",
  },
  {
    value: 333,
    day: "2016-07-17",
  },
  {
    value: 4,
    day: "2015-09-18",
  },
  {
    value: 67,
    day: "2016-06-08",
  },
  {
    value: 339,
    day: "2015-10-25",
  },
  {
    value: 136,
    day: "2018-04-25",
  },
  {
    value: 80,
    day: "2015-09-15",
  },
  {
    value: 79,
    day: "2018-04-28",
  },
  {
    value: 302,
    day: "2018-01-25",
  },
  {
    value: 188,
    day: "2017-09-10",
  },
  {
    value: 146,
    day: "2017-05-18",
  },
  {
    value: 329,
    day: "2017-05-02",
  },
  {
    value: 43,
    day: "2016-11-23",
  },
  {
    value: 57,
    day: "2017-07-18",
  },
  {
    value: 306,
    day: "2018-04-23",
  },
  {
    value: 373,
    day: "2016-10-22",
  },
  {
    value: 93,
    day: "2015-10-19",
  },
  {
    value: 50,
    day: "2015-11-04",
  },
  {
    value: 298,
    day: "2016-03-12",
  },
  {
    value: 388,
    day: "2016-05-07",
  },
  {
    value: 262,
    day: "2016-01-10",
  },
  {
    value: 70,
    day: "2017-08-08",
  },
  {
    value: 360,
    day: "2017-08-30",
  },
  {
    value: 163,
    day: "2016-08-28",
  },
  {
    value: 232,
    day: "2017-03-23",
  },
  {
    value: 357,
    day: "2016-02-08",
  },
  {
    value: 220,
    day: "2016-03-26",
  },
  {
    value: 268,
    day: "2016-05-09",
  },
  {
    value: 319,
    day: "2015-11-10",
  },
  {
    value: 121,
    day: "2015-06-27",
  },
  {
    value: 264,
    day: "2018-02-03",
  },
  {
    value: 139,
    day: "2018-04-21",
  },
  {
    value: 397,
    day: "2016-10-17",
  },
  {
    value: 297,
    day: "2017-03-25",
  },
  {
    value: 381,
    day: "2015-04-21",
  },
  {
    value: 307,
    day: "2016-02-03",
  },
  {
    value: 36,
    day: "2016-05-08",
  },
  {
    value: 136,
    day: "2016-12-23",
  },
  {
    value: 17,
    day: "2017-04-06",
  },
  {
    value: 74,
    day: "2018-06-30",
  },
  {
    value: 245,
    day: "2017-04-13",
  },
  {
    value: 119,
    day: "2018-07-04",
  },
  {
    value: 171,
    day: "2018-08-08",
  },
  {
    value: 272,
    day: "2016-09-19",
  },
  {
    value: 14,
    day: "2016-04-26",
  },
  {
    value: 270,
    day: "2016-04-03",
  },
  {
    value: 344,
    day: "2017-07-16",
  },
  {
    value: 259,
    day: "2017-11-07",
  },
  {
    value: 139,
    day: "2017-03-03",
  },
  {
    value: 184,
    day: "2017-09-21",
  },
  {
    value: 396,
    day: "2015-07-15",
  },
  {
    value: 202,
    day: "2016-02-18",
  },
  {
    value: 43,
    day: "2016-12-20",
  },
  {
    value: 357,
    day: "2015-06-03",
  },
  {
    value: 391,
    day: "2016-06-22",
  },
  {
    value: 140,
    day: "2018-01-05",
  },
  {
    value: 15,
    day: "2016-01-02",
  },
  {
    value: 6,
    day: "2018-05-22",
  },
  {
    value: 219,
    day: "2016-11-14",
  },
  {
    value: 368,
    day: "2018-06-14",
  },
  {
    value: 147,
    day: "2015-04-11",
  },
  {
    value: 31,
    day: "2018-04-20",
  },
  {
    value: 224,
    day: "2018-03-27",
  },
  {
    value: 285,
    day: "2015-09-14",
  },
  {
    value: 5,
    day: "2017-07-02",
  },
  {
    value: 343,
    day: "2017-07-24",
  },
  {
    value: 208,
    day: "2016-11-11",
  },
  {
    value: 162,
    day: "2016-07-16",
  },
  {
    value: 233,
    day: "2018-05-11",
  },
  {
    value: 350,
    day: "2017-06-26",
  },
  {
    value: 101,
    day: "2015-09-17",
  },
  {
    value: 32,
    day: "2017-01-02",
  },
  {
    value: 147,
    day: "2016-01-15",
  },
  {
    value: 95,
    day: "2017-09-05",
  },
  {
    value: 227,
    day: "2017-02-08",
  },
  {
    value: 183,
    day: "2015-05-27",
  },
  {
    value: 113,
    day: "2017-03-15",
  },
  {
    value: 287,
    day: "2015-07-20",
  },
  {
    value: 256,
    day: "2015-07-01",
  },
  {
    value: 94,
    day: "2017-02-02",
  },
  {
    value: 370,
    day: "2016-02-20",
  },
  {
    value: 173,
    day: "2017-02-17",
  },
  {
    value: 100,
    day: "2017-11-17",
  },
  {
    value: 16,
    day: "2018-03-18",
  },
  {
    value: 261,
    day: "2018-03-11",
  },
  {
    value: 171,
    day: "2018-06-09",
  },
  {
    value: 61,
    day: "2016-04-19",
  },
  {
    value: 247,
    day: "2018-06-03",
  },
  {
    value: 293,
    day: "2017-06-08",
  },
  {
    value: 192,
    day: "2015-10-14",
  },
  {
    value: 333,
    day: "2017-01-26",
  },
  {
    value: 23,
    day: "2016-05-21",
  },
  {
    value: 42,
    day: "2015-06-02",
  },
  {
    value: 108,
    day: "2018-02-02",
  },
  {
    value: 100,
    day: "2017-12-17",
  },
  {
    value: 229,
    day: "2017-04-19",
  },
  {
    value: 18,
    day: "2017-02-13",
  },
  {
    value: 310,
    day: "2017-01-14",
  },
  {
    value: 231,
    day: "2017-04-30",
  },
  {
    value: 222,
    day: "2017-04-15",
  },
  {
    value: 382,
    day: "2017-04-03",
  },
  {
    value: 71,
    day: "2017-03-27",
  },
  {
    value: 136,
    day: "2016-11-22",
  },
  {
    value: 156,
    day: "2017-05-28",
  },
  {
    value: 237,
    day: "2017-12-25",
  },
  {
    value: 94,
    day: "2018-06-19",
  },
  {
    value: 59,
    day: "2017-06-06",
  },
  {
    value: 298,
    day: "2015-09-29",
  },
  {
    value: 280,
    day: "2016-05-14",
  },
  {
    value: 386,
    day: "2015-08-14",
  },
  {
    value: 46,
    day: "2017-10-05",
  },
  {
    value: 256,
    day: "2018-07-21",
  },
  {
    value: 308,
    day: "2017-05-24",
  },
  {
    value: 76,
    day: "2015-11-24",
  },
  {
    value: 252,
    day: "2017-11-22",
  },
  {
    value: 40,
    day: "2016-11-30",
  },
  {
    value: 142,
    day: "2016-08-27",
  },
  {
    value: 395,
    day: "2015-04-20",
  },
  {
    value: 225,
    day: "2015-05-11",
  },
  {
    value: 253,
    day: "2017-10-09",
  },
  {
    value: 129,
    day: "2016-12-29",
  },
  {
    value: 119,
    day: "2018-03-22",
  },
  {
    value: 263,
    day: "2017-12-10",
  },
  {
    value: 356,
    day: "2016-09-29",
  },
  {
    value: 330,
    day: "2017-10-18",
  },
  {
    value: 330,
    day: "2018-06-20",
  },
  {
    value: 75,
    day: "2016-11-15",
  },
  {
    value: 274,
    day: "2018-05-14",
  },
  {
    value: 219,
    day: "2015-08-09",
  },
  {
    value: 242,
    day: "2017-05-15",
  },
  {
    value: 382,
    day: "2016-12-10",
  },
  {
    value: 132,
    day: "2016-01-16",
  },
  {
    value: 48,
    day: "2016-01-06",
  },
  {
    value: 91,
    day: "2018-01-29",
  },
  {
    value: 201,
    day: "2015-09-09",
  },
  {
    value: 86,
    day: "2017-09-13",
  },
  {
    value: 316,
    day: "2017-11-06",
  },
  {
    value: 173,
    day: "2016-10-19",
  },
  {
    value: 174,
    day: "2015-08-23",
  },
  {
    value: 116,
    day: "2017-03-30",
  },
  {
    value: 273,
    day: "2015-07-02",
  },
  {
    value: 398,
    day: "2017-06-21",
  },
  {
    value: 311,
    day: "2016-03-03",
  },
  {
    value: 146,
    day: "2015-04-03",
  },
  {
    value: 86,
    day: "2016-11-09",
  },
  {
    value: 74,
    day: "2015-09-26",
  },
  {
    value: 358,
    day: "2015-11-17",
  },
  {
    value: 221,
    day: "2016-02-16",
  },
  {
    value: 255,
    day: "2016-01-17",
  },
  {
    value: 166,
    day: "2018-05-08",
  },
  {
    value: 312,
    day: "2017-02-22",
  },
  {
    value: 229,
    day: "2018-02-28",
  },
  {
    value: 146,
    day: "2016-07-03",
  },
  {
    value: 149,
    day: "2016-04-14",
  },
  {
    value: 44,
    day: "2015-07-13",
  },
  {
    value: 64,
    day: "2017-12-06",
  },
  {
    value: 112,
    day: "2017-09-29",
  },
  {
    value: 66,
    day: "2015-04-04",
  },
  {
    value: 357,
    day: "2017-08-17",
  },
  {
    value: 136,
    day: "2016-04-15",
  },
  {
    value: 169,
    day: "2018-07-27",
  },
  {
    value: 11,
    day: "2016-08-24",
  },
  {
    value: 271,
    day: "2017-04-26",
  },
  {
    value: 113,
    day: "2017-09-26",
  },
  {
    value: 24,
    day: "2015-12-27",
  },
  {
    value: 267,
    day: "2017-07-04",
  },
  {
    value: 28,
    day: "2015-04-07",
  },
  {
    value: 383,
    day: "2018-05-09",
  },
  {
    value: 221,
    day: "2018-01-22",
  },
  {
    value: 354,
    day: "2015-05-25",
  },
  {
    value: 399,
    day: "2018-05-12",
  },
  {
    value: 120,
    day: "2018-03-03",
  },
  {
    value: 158,
    day: "2015-10-28",
  },
  {
    value: 157,
    day: "2015-05-29",
  },
  {
    value: 327,
    day: "2016-07-18",
  },
  {
    value: 256,
    day: "2016-11-24",
  },
  {
    value: 254,
    day: "2016-01-24",
  },
  {
    value: 195,
    day: "2016-08-29",
  },
  {
    value: 278,
    day: "2017-01-18",
  },
  {
    value: 97,
    day: "2016-01-18",
  },
  {
    value: 73,
    day: "2016-09-08",
  },
  {
    value: 3,
    day: "2018-04-07",
  },
  {
    value: 373,
    day: "2018-02-17",
  },
  {
    value: 34,
    day: "2015-05-03",
  },
  {
    value: 58,
    day: "2016-04-29",
  },
  {
    value: 275,
    day: "2017-03-04",
  },
  {
    value: 141,
    day: "2016-07-21",
  },
  {
    value: 207,
    day: "2015-10-12",
  },
  {
    value: 48,
    day: "2016-04-27",
  },
  {
    value: 321,
    day: "2017-12-30",
  },
  {
    value: 197,
    day: "2018-07-02",
  },
  {
    value: 281,
    day: "2016-10-18",
  },
  {
    value: 67,
    day: "2015-11-03",
  },
  {
    value: 80,
    day: "2017-05-20",
  },
  {
    value: 7,
    day: "2015-04-01",
  },
  {
    value: 215,
    day: "2015-10-06",
  },
  {
    value: 96,
    day: "2017-09-01",
  },
  {
    value: 35,
    day: "2016-01-19",
  },
  {
    value: 136,
    day: "2018-02-01",
  },
  {
    value: 102,
    day: "2017-01-19",
  },
  {
    value: 38,
    day: "2015-05-15",
  },
  {
    value: 125,
    day: "2017-01-08",
  },
  {
    value: 381,
    day: "2017-01-01",
  },
  {
    value: 33,
    day: "2015-06-18",
  },
  {
    value: 261,
    day: "2018-06-01",
  },
  {
    value: 283,
    day: "2017-12-27",
  },
  {
    value: 319,
    day: "2015-12-28",
  },
  {
    value: 55,
    day: "2017-06-24",
  },
  {
    value: 345,
    day: "2015-10-27",
  },
  {
    value: 130,
    day: "2017-10-28",
  },
  {
    value: 178,
    day: "2018-03-26",
  },
  {
    value: 9,
    day: "2015-08-22",
  },
  {
    value: 386,
    day: "2016-06-28",
  },
  {
    value: 73,
    day: "2016-12-07",
  },
  {
    value: 231,
    day: "2015-09-02",
  },
  {
    value: 166,
    day: "2017-04-24",
  },
  {
    value: 305,
    day: "2018-06-16",
  },
  {
    value: 121,
    day: "2016-01-22",
  },
  {
    value: 1,
    day: "2015-12-17",
  },
  {
    value: 160,
    day: "2018-04-11",
  },
  {
    value: 103,
    day: "2018-02-12",
  },
  {
    value: 291,
    day: "2018-01-08",
  },
  {
    value: 270,
    day: "2018-05-02",
  },
  {
    value: 365,
    day: "2016-06-14",
  },
  {
    value: 155,
    day: "2017-01-13",
  },
  {
    value: 96,
    day: "2018-01-02",
  },
  {
    value: 221,
    day: "2015-08-11",
  },
  {
    value: 280,
    day: "2018-01-14",
  },
  {
    value: 206,
    day: "2017-10-19",
  },
  {
    value: 99,
    day: "2018-06-27",
  },
  {
    value: 30,
    day: "2017-02-28",
  },
  {
    value: 164,
    day: "2017-04-02",
  },
  {
    value: 398,
    day: "2018-07-03",
  },
  {
    value: 109,
    day: "2015-06-04",
  },
  {
    value: 66,
    day: "2016-03-14",
  },
  {
    value: 172,
    day: "2018-05-21",
  },
  {
    value: 255,
    day: "2016-08-26",
  },
  {
    value: 283,
    day: "2017-03-29",
  },
  {
    value: 72,
    day: "2018-01-04",
  },
  {
    value: 379,
    day: "2015-11-28",
  },
  {
    value: 331,
    day: "2015-07-17",
  },
  {
    value: 301,
    day: "2017-02-20",
  },
  {
    value: 10,
    day: "2016-12-13",
  },
  {
    value: 221,
    day: "2018-07-22",
  },
  {
    value: 124,
    day: "2015-06-30",
  },
  {
    value: 33,
    day: "2017-06-29",
  },
  {
    value: 288,
    day: "2016-07-29",
  },
  {
    value: 295,
    day: "2015-04-08",
  },
  {
    value: 83,
    day: "2015-06-12",
  },
  {
    value: 135,
    day: "2018-05-27",
  },
  {
    value: 79,
    day: "2018-08-05",
  },
  {
    value: 154,
    day: "2015-11-05",
  },
  {
    value: 216,
    day: "2018-07-29",
  },
  {
    value: 385,
    day: "2018-02-07",
  },
  {
    value: 68,
    day: "2016-10-06",
  },
  {
    value: 160,
    day: "2015-06-08",
  },
  {
    value: 179,
    day: "2017-10-30",
  },
  {
    value: 21,
    day: "2016-01-28",
  },
  {
    value: 379,
    day: "2015-05-16",
  },
  {
    value: 342,
    day: "2016-07-07",
  },
  {
    value: 66,
    day: "2017-09-20",
  },
  {
    value: 293,
    day: "2018-06-28",
  },
  {
    value: 352,
    day: "2015-05-30",
  },
  {
    value: 231,
    day: "2016-11-13",
  },
  {
    value: 126,
    day: "2018-07-26",
  },
  {
    value: 247,
    day: "2018-07-30",
  },
  {
    value: 13,
    day: "2017-08-28",
  },
  {
    value: 157,
    day: "2016-06-26",
  },
  {
    value: 371,
    day: "2018-08-01",
  },
  {
    value: 42,
    day: "2015-12-07",
  },
  {
    value: 153,
    day: "2017-05-23",
  },
  {
    value: 265,
    day: "2017-08-31",
  },
  {
    value: 117,
    day: "2017-04-01",
  },
  {
    value: 30,
    day: "2017-03-14",
  },
  {
    value: 309,
    day: "2017-07-27",
  },
  {
    value: 145,
    day: "2016-01-12",
  },
  {
    value: 263,
    day: "2015-04-30",
  },
  {
    value: 208,
    day: "2018-04-30",
  },
  {
    value: 134,
    day: "2017-07-21",
  },
  {
    value: 311,
    day: "2017-12-08",
  },
  {
    value: 315,
    day: "2016-10-28",
  },
  {
    value: 244,
    day: "2015-07-29",
  },
  {
    value: 36,
    day: "2015-11-13",
  },
  {
    value: 140,
    day: "2017-03-06",
  },
  {
    value: 122,
    day: "2016-11-16",
  },
  {
    value: 51,
    day: "2016-06-03",
  },
  {
    value: 101,
    day: "2017-09-16",
  },
  {
    value: 358,
    day: "2017-07-08",
  },
  {
    value: 120,
    day: "2015-10-11",
  },
  {
    value: 94,
    day: "2017-07-11",
  },
  {
    value: 157,
    day: "2018-02-26",
  },
  {
    value: 151,
    day: "2015-07-23",
  },
  {
    value: 116,
    day: "2016-06-02",
  },
  {
    value: 245,
    day: "2018-04-27",
  },
  {
    value: 165,
    day: "2016-10-25",
  },
  {
    value: 300,
    day: "2018-04-09",
  },
  {
    value: 4,
    day: "2018-08-02",
  },
  {
    value: 350,
    day: "2015-04-15",
  },
  {
    value: 59,
    day: "2017-07-15",
  },
  {
    value: 54,
    day: "2016-11-21",
  },
  {
    value: 216,
    day: "2016-08-23",
  },
  {
    value: 95,
    day: "2016-04-23",
  },
  {
    value: 337,
    day: "2018-05-19",
  },
  {
    value: 126,
    day: "2017-06-07",
  },
  {
    value: 190,
    day: "2016-01-14",
  },
  {
    value: 306,
    day: "2016-05-23",
  },
  {
    value: 388,
    day: "2017-08-06",
  },
  {
    value: 171,
    day: "2016-09-06",
  },
  {
    value: 282,
    day: "2016-12-08",
  },
  {
    value: 74,
    day: "2017-07-05",
  },
  {
    value: 290,
    day: "2018-01-24",
  },
  {
    value: 181,
    day: "2018-05-20",
  },
  {
    value: 399,
    day: "2016-06-29",
  },
  {
    value: 281,
    day: "2016-12-12",
  },
  {
    value: 277,
    day: "2015-10-04",
  },
  {
    value: 242,
    day: "2018-03-23",
  },
  {
    value: 319,
    day: "2015-08-01",
  },
  {
    value: 64,
    day: "2015-04-10",
  },
  {
    value: 230,
    day: "2016-03-08",
  },
  {
    value: 329,
    day: "2016-09-30",
  },
  {
    value: 276,
    day: "2017-06-25",
  },
  {
    value: 107,
    day: "2017-03-28",
  },
  {
    value: 293,
    day: "2015-09-25",
  },
  {
    value: 140,
    day: "2017-05-22",
  },
  {
    value: 300,
    day: "2018-06-11",
  },
  {
    value: 30,
    day: "2017-01-05",
  },
  {
    value: 330,
    day: "2015-08-26",
  },
  {
    value: 99,
    day: "2016-08-25",
  },
  {
    value: 337,
    day: "2018-08-10",
  },
  {
    value: 202,
    day: "2018-07-20",
  },
  {
    value: 393,
    day: "2016-09-09",
  },
  {
    value: 304,
    day: "2016-02-10",
  },
  {
    value: 80,
    day: "2016-08-22",
  },
  {
    value: 22,
    day: "2016-03-28",
  },
  {
    value: 43,
    day: "2015-06-15",
  },
  {
    value: 121,
    day: "2016-05-31",
  },
  {
    value: 194,
    day: "2015-09-13",
  },
  {
    value: 71,
    day: "2018-03-15",
  },
  {
    value: 380,
    day: "2015-07-04",
  },
  {
    value: 230,
    day: "2015-09-04",
  },
  {
    value: 54,
    day: "2015-07-21",
  },
  {
    value: 228,
    day: "2015-11-27",
  },
  {
    value: 87,
    day: "2015-12-14",
  },
  {
    value: 23,
    day: "2015-06-10",
  },
  {
    value: 93,
    day: "2015-09-16",
  },
  {
    value: 105,
    day: "2016-01-11",
  },
  {
    value: 218,
    day: "2018-05-15",
  },
  {
    value: 134,
    day: "2016-03-16",
  },
  {
    value: 395,
    day: "2017-06-09",
  },
  {
    value: 89,
    day: "2015-05-13",
  },
  {
    value: 203,
    day: "2017-10-16",
  },
  {
    value: 290,
    day: "2016-02-13",
  },
  {
    value: 10,
    day: "2015-07-12",
  },
  {
    value: 302,
    day: "2015-04-13",
  },
  {
    value: 55,
    day: "2015-11-18",
  },
  {
    value: 309,
    day: "2016-02-19",
  },
  {
    value: 245,
    day: "2017-12-26",
  },
  {
    value: 263,
    day: "2017-07-12",
  },
  {
    value: 196,
    day: "2016-05-22",
  },
  {
    value: 75,
    day: "2018-01-11",
  },
  {
    value: 85,
    day: "2017-10-03",
  },
  {
    value: 232,
    day: "2016-06-05",
  },
  {
    value: 154,
    day: "2015-05-05",
  },
  {
    value: 64,
    day: "2015-04-02",
  },
  {
    value: 31,
    day: "2016-02-09",
  },
  {
    value: 118,
    day: "2015-09-28",
  },
  {
    value: 390,
    day: "2017-10-25",
  },
  {
    value: 138,
    day: "2018-07-14",
  },
  {
    value: 103,
    day: "2017-11-03",
  },
  {
    value: 243,
    day: "2015-07-27",
  },
  {
    value: 143,
    day: "2015-05-18",
  },
  {
    value: 141,
    day: "2017-08-16",
  },
  {
    value: 172,
    day: "2015-10-09",
  },
  {
    value: 2,
    day: "2016-05-05",
  },
  {
    value: 154,
    day: "2017-04-18",
  },
  {
    value: 231,
    day: "2017-02-03",
  },
  {
    value: 109,
    day: "2016-04-02",
  },
  {
    value: 244,
    day: "2017-10-10",
  },
  {
    value: 322,
    day: "2016-04-25",
  },
  {
    value: 379,
    day: "2015-11-02",
  },
  {
    value: 46,
    day: "2015-07-09",
  },
  {
    value: 302,
    day: "2017-05-03",
  },
  {
    value: 259,
    day: "2015-04-16",
  },
  {
    value: 153,
    day: "2017-03-11",
  },
  {
    value: 136,
    day: "2015-05-24",
  },
  {
    value: 323,
    day: "2016-08-04",
  },
  {
    value: 200,
    day: "2015-04-24",
  },
  {
    value: 247,
    day: "2015-09-20",
  },
  {
    value: 76,
    day: "2016-10-02",
  },
  {
    value: 315,
    day: "2017-07-28",
  },
  {
    value: 202,
    day: "2016-02-25",
  },
  {
    value: 66,
    day: "2017-09-04",
  },
  {
    value: 266,
    day: "2017-04-16",
  },
  {
    value: 113,
    day: "2018-05-23",
  },
  {
    value: 309,
    day: "2017-06-17",
  },
  {
    value: 342,
    day: "2016-07-19",
  },
  {
    value: 225,
    day: "2016-11-28",
  },
  {
    value: 221,
    day: "2017-08-07",
  },
  {
    value: 275,
    day: "2017-07-06",
  },
  {
    value: 151,
    day: "2017-04-17",
  },
  {
    value: 298,
    day: "2016-12-05",
  },
  {
    value: 330,
    day: "2016-01-08",
  },
  {
    value: 362,
    day: "2018-07-06",
  },
  {
    value: 302,
    day: "2016-04-16",
  },
  {
    value: 284,
    day: "2016-04-30",
  },
  {
    value: 270,
    day: "2016-09-04",
  },
  {
    value: 264,
    day: "2016-05-17",
  },
  {
    value: 135,
    day: "2016-10-24",
  },
  {
    value: 195,
    day: "2015-08-10",
  },
  {
    value: 9,
    day: "2016-06-10",
  },
  {
    value: 353,
    day: "2017-09-25",
  },
  {
    value: 377,
    day: "2015-04-22",
  },
  {
    value: 307,
    day: "2016-02-28",
  },
  {
    value: 183,
    day: "2016-05-18",
  },
  {
    value: 110,
    day: "2015-04-23",
  },
  {
    value: 361,
    day: "2016-08-12",
  },
  {
    value: 127,
    day: "2015-04-06",
  },
  {
    value: 74,
    day: "2017-08-03",
  },
  {
    value: 196,
    day: "2016-04-10",
  },
  {
    value: 241,
    day: "2017-08-11",
  },
  {
    value: 340,
    day: "2015-06-19",
  },
  {
    value: 216,
    day: "2015-05-14",
  },
  {
    value: 260,
    day: "2015-12-24",
  },
  {
    value: 78,
    day: "2018-03-12",
  },
  {
    value: 223,
    day: "2017-02-16",
  },
  {
    value: 312,
    day: "2015-05-07",
  },
  {
    value: 217,
    day: "2015-12-20",
  },
  {
    value: 61,
    day: "2015-08-13",
  },
  {
    value: 287,
    day: "2015-05-26",
  },
  {
    value: 236,
    day: "2015-12-08",
  },
  {
    value: 234,
    day: "2017-11-16",
  },
  {
    value: 22,
    day: "2018-04-10",
  },
  {
    value: 205,
    day: "2017-03-10",
  },
  {
    value: 179,
    day: "2016-12-30",
  },
  {
    value: 297,
    day: "2016-08-17",
  },
  {
    value: 31,
    day: "2018-05-17",
  },
  {
    value: 43,
    day: "2017-10-23",
  },
  {
    value: 115,
    day: "2018-06-08",
  },
  {
    value: 150,
    day: "2017-06-23",
  },
  {
    value: 14,
    day: "2015-04-19",
  },
  {
    value: 50,
    day: "2017-08-27",
  },
  {
    value: 201,
    day: "2016-10-31",
  },
  {
    value: 205,
    day: "2017-05-26",
  },
  {
    value: 366,
    day: "2018-07-23",
  },
  {
    value: 124,
    day: "2016-07-04",
  },
  {
    value: 390,
    day: "2016-05-15",
  },
  {
    value: 153,
    day: "2016-02-22",
  },
  {
    value: 57,
    day: "2015-10-02",
  },
  {
    value: 59,
    day: "2015-09-27",
  },
  {
    value: 393,
    day: "2017-06-20",
  },
  {
    value: 112,
    day: "2016-08-19",
  },
  {
    value: 286,
    day: "2017-11-11",
  },
  {
    value: 110,
    day: "2018-06-24",
  },
  {
    value: 208,
    day: "2016-11-05",
  },
  {
    value: 207,
    day: "2018-06-10",
  },
  {
    value: 365,
    day: "2017-05-09",
  },
  {
    value: 39,
    day: "2016-05-30",
  },
  {
    value: 9,
    day: "2017-10-20",
  },
  {
    value: 31,
    day: "2015-07-30",
  },
  {
    value: 134,
    day: "2016-09-11",
  },
  {
    value: 3,
    day: "2016-02-11",
  },
  {
    value: 57,
    day: "2017-05-05",
  },
  {
    value: 16,
    day: "2015-06-22",
  },
  {
    value: 296,
    day: "2016-12-21",
  },
  {
    value: 288,
    day: "2017-07-14",
  },
  {
    value: 127,
    day: "2016-06-19",
  },
  {
    value: 171,
    day: "2015-09-05",
  },
  {
    value: 221,
    day: "2016-02-23",
  },
  {
    value: 295,
    day: "2017-04-23",
  },
  {
    value: 100,
    day: "2017-05-16",
  },
  {
    value: 8,
    day: "2018-07-09",
  },
  {
    value: 390,
    day: "2015-05-28",
  },
  {
    value: 197,
    day: "2017-05-10",
  },
  {
    value: 287,
    day: "2016-12-01",
  },
  {
    value: 365,
    day: "2015-10-30",
  },
  {
    value: 100,
    day: "2015-04-14",
  },
  {
    value: 342,
    day: "2017-12-24",
  },
  {
    value: 258,
    day: "2017-04-12",
  },
  {
    value: 293,
    day: "2018-07-10",
  },
  {
    value: 15,
    day: "2016-05-13",
  },
  {
    value: 234,
    day: "2017-08-29",
  },
  {
    value: 163,
    day: "2016-11-03",
  },
  {
    value: 133,
    day: "2015-09-01",
  },
  {
    value: 12,
    day: "2015-11-15",
  },
  {
    value: 187,
    day: "2016-12-09",
  },
  {
    value: 110,
    day: "2015-07-03",
  },
  {
    value: 292,
    day: "2016-12-16",
  },
  {
    value: 141,
    day: "2017-12-20",
  },
  {
    value: 291,
    day: "2016-03-13",
  },
  {
    value: 391,
    day: "2016-08-30",
  },
  {
    value: 154,
    day: "2016-05-02",
  },
  {
    value: 322,
    day: "2015-08-07",
  },
  {
    value: 95,
    day: "2015-08-05",
  },
  {
    value: 378,
    day: "2016-10-26",
  },
  {
    value: 222,
    day: "2015-06-25",
  },
  {
    value: 59,
    day: "2018-01-18",
  },
  {
    value: 344,
    day: "2015-05-19",
  },
  {
    value: 374,
    day: "2018-04-16",
  },
  {
    value: 357,
    day: "2015-11-06",
  },
  {
    value: 283,
    day: "2016-06-17",
  },
  {
    value: 306,
    day: "2015-09-08",
  },
  {
    value: 291,
    day: "2018-04-15",
  },
  {
    value: 20,
    day: "2016-06-25",
  },
  {
    value: 76,
    day: "2016-04-05",
  },
  {
    value: 192,
    day: "2017-11-28",
  },
  {
    value: 164,
    day: "2017-05-25",
  },
  {
    value: 112,
    day: "2017-09-12",
  },
  {
    value: 112,
    day: "2015-04-29",
  },
  {
    value: 110,
    day: "2015-10-05",
  },
  {
    value: 336,
    day: "2016-04-09",
  },
  {
    value: 189,
    day: "2017-09-15",
  },
  {
    value: 58,
    day: "2017-06-04",
  },
  {
    value: 348,
    day: "2016-10-23",
  },
  {
    value: 216,
    day: "2018-06-12",
  },
  {
    value: 204,
    day: "2015-10-03",
  },
  {
    value: 353,
    day: "2015-12-31",
  },
  {
    value: 155,
    day: "2017-01-30",
  },
  {
    value: 110,
    day: "2017-08-10",
  },
  {
    value: 399,
    day: "2015-08-06",
  },
  {
    value: 115,
    day: "2018-01-20",
  },
  {
    value: 146,
    day: "2018-04-17",
  },
  {
    value: 350,
    day: "2015-05-10",
  },
  {
    value: 363,
    day: "2016-09-21",
  },
  {
    value: 12,
    day: "2016-02-12",
  },
  {
    value: 18,
    day: "2017-03-18",
  },
  {
    value: 259,
    day: "2016-07-05",
  },
  {
    value: 293,
    day: "2017-06-15",
  },
  {
    value: 378,
    day: "2017-04-05",
  },
  {
    value: 378,
    day: "2017-04-04",
  },
  {
    value: 54,
    day: "2016-03-09",
  },
  {
    value: 296,
    day: "2017-06-22",
  },
  {
    value: 3,
    day: "2016-09-27",
  },
  {
    value: 295,
    day: "2015-11-21",
  },
  {
    value: 3,
    day: "2016-02-26",
  },
  {
    value: 218,
    day: "2018-08-03",
  },
  {
    value: 240,
    day: "2016-07-09",
  },
  {
    value: 48,
    day: "2016-02-15",
  },
  {
    value: 93,
    day: "2017-09-17",
  },
  {
    value: 81,
    day: "2017-05-30",
  },
  {
    value: 2,
    day: "2018-06-26",
  },
  {
    value: 96,
    day: "2018-06-23",
  },
  {
    value: 332,
    day: "2017-09-30",
  },
  {
    value: 210,
    day: "2017-09-27",
  },
  {
    value: 230,
    day: "2018-06-25",
  },
  {
    value: 18,
    day: "2016-07-26",
  },
  {
    value: 136,
    day: "2015-04-28",
  },
  {
    value: 228,
    day: "2016-12-15",
  },
  {
    value: 375,
    day: "2016-07-06",
  },
  {
    value: 252,
    day: "2017-12-04",
  },
  {
    value: 246,
    day: "2017-02-10",
  },
  {
    value: 137,
    day: "2018-02-19",
  },
  {
    value: 84,
    day: "2018-05-25",
  },
  {
    value: 342,
    day: "2015-10-31",
  },
  {
    value: 357,
    day: "2016-12-27",
  },
  {
    value: 327,
    day: "2016-07-08",
  },
  {
    value: 122,
    day: "2016-03-10",
  },
  {
    value: 125,
    day: "2015-08-18",
  },
  {
    value: 147,
    day: "2016-04-17",
  },
  {
    value: 300,
    day: "2018-03-19",
  },
  {
    value: 190,
    day: "2015-10-24",
  },
  {
    value: 147,
    day: "2017-08-24",
  },
  {
    value: 273,
    day: "2015-10-29",
  },
  {
    value: 323,
    day: "2016-09-01",
  },
  {
    value: 116,
    day: "2016-08-15",
  },
  {
    value: 279,
    day: "2016-05-29",
  },
  {
    value: 190,
    day: "2015-09-22",
  },
  {
    value: 24,
    day: "2017-03-13",
  },
  {
    value: 8,
    day: "2017-09-19",
  },
  {
    value: 333,
    day: "2017-06-28",
  },
  {
    value: 225,
    day: "2017-05-08",
  },
  {
    value: 171,
    day: "2015-06-24",
  },
  {
    value: 90,
    day: "2015-08-12",
  },
  {
    value: 47,
    day: "2018-03-07",
  },
  {
    value: 249,
    day: "2015-07-22",
  },
  {
    value: 252,
    day: "2017-05-13",
  },
  {
    value: 102,
    day: "2018-05-10",
  },
  {
    value: 137,
    day: "2017-08-26",
  },
  {
    value: 181,
    day: "2015-10-17",
  },
  {
    value: 399,
    day: "2017-02-24",
  },
  {
    value: 172,
    day: "2015-12-23",
  },
  {
    value: 133,
    day: "2017-11-23",
  },
  {
    value: 226,
    day: "2017-02-25",
  },
  {
    value: 290,
    day: "2018-03-08",
  },
  {
    value: 339,
    day: "2017-05-12",
  },
  {
    value: 9,
    day: "2017-08-02",
  },
  {
    value: 392,
    day: "2016-10-08",
  },
  {
    value: 267,
    day: "2015-05-01",
  },
  {
    value: 35,
    day: "2018-06-04",
  },
  {
    value: 193,
    day: "2017-09-23",
  },
  {
    value: 333,
    day: "2018-01-30",
  },
  {
    value: 319,
    day: "2016-09-28",
  },
  {
    value: 125,
    day: "2015-05-04",
  },
  {
    value: 65,
    day: "2017-04-14",
  },
  {
    value: 253,
    day: "2017-12-16",
  },
  {
    value: 51,
    day: "2016-01-07",
  },
  {
    value: 183,
    day: "2017-10-07",
  },
  {
    value: 36,
    day: "2018-06-29",
  },
  {
    value: 105,
    day: "2018-03-21",
  },
  {
    value: 350,
    day: "2015-11-25",
  },
  {
    value: 126,
    day: "2018-05-05",
  },
  {
    value: 267,
    day: "2016-08-09",
  },
  {
    value: 389,
    day: "2017-12-18",
  },
  {
    value: 17,
    day: "2017-01-27",
  },
  {
    value: 149,
    day: "2016-10-07",
  },
  {
    value: 293,
    day: "2018-02-06",
  },
  {
    value: 162,
    day: "2017-05-27",
  },
  {
    value: 153,
    day: "2016-11-01",
  },
  {
    value: 59,
    day: "2017-10-15",
  },
  {
    value: 103,
    day: "2015-06-06",
  },
  {
    value: 307,
    day: "2016-10-16",
  },
  {
    value: 57,
    day: "2016-06-23",
  },
  {
    value: 390,
    day: "2017-06-30",
  },
  {
    value: 329,
    day: "2017-03-01",
  },
  {
    value: 43,
    day: "2018-03-25",
  },
  {
    value: 143,
    day: "2016-08-13",
  },
  {
    value: 60,
    day: "2016-05-12",
  },
  {
    value: 153,
    day: "2017-11-01",
  },
  {
    value: 184,
    day: "2015-11-14",
  },
  {
    value: 300,
    day: "2016-03-20",
  },
  {
    value: 138,
    day: "2018-04-05",
  },
  {
    value: 18,
    day: "2017-10-21",
  },
  {
    value: 173,
    day: "2016-01-29",
  },
  {
    value: 24,
    day: "2017-03-16",
  },
  {
    value: 52,
    day: "2018-04-08",
  },
  {
    value: 153,
    day: "2017-08-21",
  },
  {
    value: 239,
    day: "2018-05-13",
  },
  {
    value: 218,
    day: "2015-11-16",
  },
  {
    value: 392,
    day: "2018-02-20",
  },
  {
    value: 95,
    day: "2017-08-13",
  },
  {
    value: 160,
    day: "2016-10-13",
  },
  {
    value: 32,
    day: "2018-05-04",
  },
  {
    value: 209,
    day: "2017-12-07",
  },
  {
    value: 19,
    day: "2017-03-07",
  },
  {
    value: 290,
    day: "2018-06-21",
  },
  {
    value: 366,
    day: "2015-07-26",
  },
  {
    value: 160,
    day: "2015-06-20",
  },
  {
    value: 154,
    day: "2017-11-10",
  },
  {
    value: 183,
    day: "2015-11-26",
  },
  {
    value: 346,
    day: "2016-02-04",
  },
  {
    value: 113,
    day: "2016-09-10",
  },
  {
    value: 187,
    day: "2016-04-01",
  },
  {
    value: 28,
    day: "2017-02-12",
  },
  {
    value: 143,
    day: "2016-04-04",
  },
  {
    value: 298,
    day: "2017-12-12",
  },
  {
    value: 161,
    day: "2016-05-25",
  },
  {
    value: 347,
    day: "2015-06-16",
  },
  {
    value: 372,
    day: "2017-08-25",
  },
  {
    value: 196,
    day: "2018-05-03",
  },
  {
    value: 73,
    day: "2015-10-10",
  },
  {
    value: 367,
    day: "2016-02-02",
  },
  {
    value: 64,
    day: "2016-02-01",
  },
  {
    value: 175,
    day: "2016-02-17",
  },
  {
    value: 372,
    day: "2017-01-03",
  },
  {
    value: 5,
    day: "2015-08-24",
  },
  {
    value: 196,
    day: "2017-12-28",
  },
  {
    value: 21,
    day: "2017-11-20",
  },
  {
    value: 47,
    day: "2016-07-12",
  },
  {
    value: 347,
    day: "2018-06-15",
  },
  {
    value: 135,
    day: "2017-03-09",
  },
  {
    value: 362,
    day: "2017-11-26",
  },
  {
    value: 256,
    day: "2017-10-06",
  },
  {
    value: 115,
    day: "2017-11-19",
  },
  {
    value: 182,
    day: "2018-08-04",
  },
  {
    value: 149,
    day: "2015-04-18",
  },
  {
    value: 290,
    day: "2017-09-08",
  },
  {
    value: 278,
    day: "2015-12-19",
  },
  {
    value: 178,
    day: "2016-11-12",
  },
  {
    value: 198,
    day: "2018-05-24",
  },
  {
    value: 302,
    day: "2015-04-12",
  },
  {
    value: 264,
    day: "2018-01-07",
  },
  {
    value: 391,
    day: "2015-05-22",
  },
  {
    value: 139,
    day: "2018-03-31",
  },
  {
    value: 234,
    day: "2018-08-11",
  },
  {
    value: 348,
    day: "2017-04-07",
  },
  {
    value: 194,
    day: "2015-08-08",
  },
  {
    value: 43,
    day: "2017-02-05",
  },
  {
    value: 349,
    day: "2017-03-12",
  },
  {
    value: 88,
    day: "2017-07-17",
  },
  {
    value: 30,
    day: "2015-10-18",
  },
  {
    value: 53,
    day: "2017-08-15",
  },
  {
    value: 323,
    day: "2016-10-30",
  },
  {
    value: 114,
    day: "2015-08-19",
  },
  {
    value: 158,
    day: "2017-11-15",
  },
  {
    value: 49,
    day: "2016-01-05",
  },
  {
    value: 366,
    day: "2015-08-03",
  },
  {
    value: 304,
    day: "2018-06-05",
  },
  {
    value: 373,
    day: "2017-08-19",
  },
  {
    value: 159,
    day: "2018-01-15",
  },
  {
    value: 104,
    day: "2016-04-07",
  },
  {
    value: 18,
    day: "2018-02-15",
  },
  {
    value: 179,
    day: "2016-07-11",
  },
  {
    value: 329,
    day: "2017-11-24",
  },
  {
    value: 33,
    day: "2018-06-18",
  },
  {
    value: 394,
    day: "2016-03-24",
  },
  {
    value: 118,
    day: "2015-11-23",
  },
  {
    value: 118,
    day: "2016-02-29",
  },
  {
    value: 156,
    day: "2018-01-31",
  },
  {
    value: 158,
    day: "2017-04-27",
  },
  {
    value: 284,
    day: "2018-01-28",
  },
  {
    value: 131,
    day: "2017-07-23",
  },
  {
    value: 107,
    day: "2016-09-13",
  },
  {
    value: 10,
    day: "2018-07-17",
  },
  {
    value: 387,
    day: "2018-04-06",
  },
  {
    value: 60,
    day: "2018-01-13",
  },
  {
    value: 4,
    day: "2017-05-21",
  },
  {
    value: 302,
    day: "2017-07-09",
  },
  {
    value: 144,
    day: "2018-02-11",
  },
  {
    value: 361,
    day: "2017-02-09",
  },
  {
    value: 170,
    day: "2016-11-02",
  },
  {
    value: 267,
    day: "2017-07-19",
  },
  {
    value: 24,
    day: "2017-05-01",
  },
  {
    value: 324,
    day: "2017-04-10",
  },
  {
    value: 399,
    day: "2017-02-26",
  },
  {
    value: 380,
    day: "2018-02-18",
  },
  {
    value: 219,
    day: "2015-06-13",
  },
  {
    value: 71,
    day: "2018-01-21",
  },
  {
    value: 132,
    day: "2016-11-26",
  },
  {
    value: 156,
    day: "2016-12-24",
  },
  {
    value: 63,
    day: "2017-08-14",
  },
  {
    value: 271,
    day: "2017-04-28",
  },
  {
    value: 239,
    day: "2017-03-02",
  },
  {
    value: 20,
    day: "2017-02-23",
  },
  {
    value: 348,
    day: "2016-08-18",
  },
  {
    value: 222,
    day: "2016-07-31",
  },
  {
    value: 233,
    day: "2017-10-02",
  },
  {
    value: 213,
    day: "2018-04-29",
  },
  {
    value: 80,
    day: "2015-08-29",
  },
  {
    value: 166,
    day: "2017-12-15",
  },
  {
    value: 320,
    day: "2018-02-09",
  },
  {
    value: 339,
    day: "2016-07-23",
  },
  {
    value: 82,
    day: "2015-06-11",
  },
  {
    value: 118,
    day: "2016-04-20",
  },
  {
    value: 142,
    day: "2016-12-18",
  },
  {
    value: 326,
    day: "2017-04-11",
  },
  {
    value: 90,
    day: "2016-09-26",
  },
  {
    value: 30,
    day: "2018-01-03",
  },
  {
    value: 224,
    day: "2018-03-10",
  },
  {
    value: 243,
    day: "2017-03-21",
  },
  {
    value: 371,
    day: "2017-07-25",
  },
  {
    value: 36,
    day: "2015-11-19",
  },
  {
    value: 39,
    day: "2016-05-04",
  },
  {
    value: 340,
    day: "2017-08-05",
  },
  {
    value: 286,
    day: "2017-10-08",
  },
  {
    value: 60,
    day: "2016-07-25",
  },
  {
    value: 333,
    day: "2017-06-14",
  },
  {
    value: 393,
    day: "2017-04-20",
  },
  {
    value: 201,
    day: "2017-01-04",
  },
  {
    value: 73,
    day: "2017-06-11",
  },
  {
    value: 398,
    day: "2017-12-02",
  },
  {
    value: 27,
    day: "2017-05-06",
  },
  {
    value: 1,
    day: "2016-07-15",
  },
  {
    value: 305,
    day: "2015-12-05",
  },
  {
    value: 7,
    day: "2017-07-07",
  },
  {
    value: 8,
    day: "2016-03-01",
  },
  {
    value: 247,
    day: "2016-02-05",
  },
  {
    value: 205,
    day: "2016-11-17",
  },
  {
    value: 277,
    day: "2018-02-21",
  },
  {
    value: 193,
    day: "2017-10-13",
  },
  {
    value: 229,
    day: "2015-10-01",
  },
  {
    value: 128,
    day: "2016-10-27",
  },
  {
    value: 105,
    day: "2017-09-18",
  },
  {
    value: 250,
    day: "2017-12-13",
  },
  {
    value: 13,
    day: "2015-09-30",
  },
  {
    value: 388,
    day: "2017-11-13",
  },
  {
    value: 42,
    day: "2016-01-30",
  },
  {
    value: 351,
    day: "2018-03-28",
  },
  {
    value: 148,
    day: "2018-02-24",
  },
  {
    value: 365,
    day: "2015-06-26",
  },
  {
    value: 200,
    day: "2015-08-16",
  },
  {
    value: 175,
    day: "2015-10-22",
  },
  {
    value: 205,
    day: "2018-02-10",
  },
  {
    value: 337,
    day: "2016-09-03",
  },
  {
    value: 48,
    day: "2018-03-06",
  },
  {
    value: 8,
    day: "2017-03-20",
  },
  {
    value: 232,
    day: "2018-05-07",
  },
  {
    value: 392,
    day: "2017-09-22",
  },
  {
    value: 291,
    day: "2016-09-20",
  },
  {
    value: 225,
    day: "2017-03-17",
  },
  {
    value: 311,
    day: "2015-12-10",
  },
  {
    value: 173,
    day: "2017-07-26",
  },
  {
    value: 347,
    day: "2016-10-11",
  },
  {
    value: 64,
    day: "2015-08-20",
  },
  {
    value: 165,
    day: "2015-12-13",
  },
  {
    value: 165,
    day: "2018-02-25",
  },
  {
    value: 372,
    day: "2018-03-13",
  },
  {
    value: 34,
    day: "2017-11-30",
  },
  {
    value: 285,
    day: "2016-06-13",
  },
  {
    value: 9,
    day: "2018-01-27",
  },
  {
    value: 363,
    day: "2017-12-05",
  },
  {
    value: 4,
    day: "2015-08-21",
  },
  {
    value: 360,
    day: "2015-10-21",
  },
  {
    value: 24,
    day: "2017-01-17",
  },
  {
    value: 98,
    day: "2015-06-01",
  },
  {
    value: 117,
    day: "2016-04-18",
  },
  {
    value: 50,
    day: "2017-01-21",
  },
  {
    value: 225,
    day: "2015-07-07",
  },
  {
    value: 63,
    day: "2016-05-20",
  },
  {
    value: 315,
    day: "2016-05-19",
  },
  {
    value: 138,
    day: "2018-03-20",
  },
  {
    value: 34,
    day: "2016-06-04",
  },
  {
    value: 365,
    day: "2016-07-30",
  },
  {
    value: 81,
    day: "2017-09-06",
  },
  {
    value: 74,
    day: "2015-12-12",
  },
  {
    value: 248,
    day: "2016-08-21",
  },
  {
    value: 3,
    day: "2017-02-27",
  },
  {
    value: 74,
    day: "2017-11-02",
  },
  {
    value: 387,
    day: "2015-08-04",
  },
  {
    value: 380,
    day: "2016-01-23",
  },
  {
    value: 230,
    day: "2016-07-01",
  },
  {
    value: 293,
    day: "2018-07-12",
  },
  {
    value: 190,
    day: "2015-07-16",
  },
  {
    value: 42,
    day: "2015-08-30",
  },
  {
    value: 26,
    day: "2015-08-02",
  },
  {
    value: 262,
    day: "2017-02-01",
  },
  {
    value: 192,
    day: "2016-12-26",
  },
  {
    value: 85,
    day: "2015-05-23",
  },
  {
    value: 194,
    day: "2016-08-06",
  },
  {
    value: 191,
    day: "2018-03-24",
  },
  {
    value: 301,
    day: "2016-08-07",
  },
  {
    value: 69,
    day: "2017-06-13",
  },
  {
    value: 349,
    day: "2015-09-11",
  },
  {
    value: 39,
    day: "2018-03-04",
  },
  {
    value: 100,
    day: "2018-03-17",
  },
  {
    value: 202,
    day: "2017-10-22",
  },
  {
    value: 174,
    day: "2016-06-06",
  },
  {
    value: 382,
    day: "2016-02-14",
  },
  {
    value: 77,
    day: "2018-06-07",
  },
  {
    value: 310,
    day: "2017-02-21",
  },
  {
    value: 367,
    day: "2018-07-18",
  },
  {
    value: 219,
    day: "2016-04-13",
  },
  {
    value: 132,
    day: "2018-01-16",
  },
  {
    value: 323,
    day: "2018-07-15",
  },
  {
    value: 98,
    day: "2015-11-12",
  },
  {
    value: 154,
    day: "2017-10-27",
  },
  {
    value: 59,
    day: "2017-06-16",
  },
  {
    value: 132,
    day: "2016-03-30",
  },
  {
    value: 220,
    day: "2015-09-10",
  },
  {
    value: 346,
    day: "2017-11-09",
  },
  {
    value: 294,
    day: "2016-08-20",
  },
  {
    value: 24,
    day: "2017-01-29",
  },
  {
    value: 117,
    day: "2017-07-20",
  },
  {
    value: 175,
    day: "2015-08-27",
  },
  {
    value: 369,
    day: "2015-08-28",
  },
  {
    value: 326,
    day: "2017-12-01",
  },
  {
    value: 237,
    day: "2017-09-02",
  },
  {
    value: 53,
    day: "2015-08-17",
  },
  {
    value: 135,
    day: "2015-06-28",
  },
  {
    value: 320,
    day: "2018-07-11",
  },
  {
    value: 61,
    day: "2017-11-14",
  },
  {
    value: 102,
    day: "2017-07-13",
  },
  {
    value: 164,
    day: "2017-06-12",
  },
  {
    value: 96,
    day: "2017-09-03",
  },
  {
    value: 264,
    day: "2018-02-27",
  },
  {
    value: 266,
    day: "2017-02-06",
  },
  {
    value: 135,
    day: "2017-11-29",
  },
  {
    value: 45,
    day: "2018-04-19",
  },
  {
    value: 140,
    day: "2017-05-11",
  },
  {
    value: 62,
    day: "2017-05-04",
  },
  {
    value: 183,
    day: "2016-12-06",
  },
  {
    value: 167,
    day: "2017-08-12",
  },
  {
    value: 71,
    day: "2016-07-14",
  },
  {
    value: 177,
    day: "2015-12-16",
  },
  {
    value: 262,
    day: "2017-11-18",
  },
  {
    value: 0,
    day: "2015-05-12",
  },
  {
    value: 127,
    day: "2015-09-03",
  },
  {
    value: 302,
    day: "2016-09-12",
  },
  {
    value: 82,
    day: "2018-07-31",
  },
  {
    value: 140,
    day: "2018-05-16",
  },
  {
    value: 320,
    day: "2016-08-16",
  },
  {
    value: 220,
    day: "2016-08-08",
  },
  {
    value: 98,
    day: "2017-07-31",
  },
  {
    value: 25,
    day: "2016-07-24",
  },
  {
    value: 61,
    day: "2016-11-19",
  },
  {
    value: 146,
    day: "2017-09-11",
  },
  {
    value: 396,
    day: "2018-04-03",
  },
  {
    value: 337,
    day: "2016-11-29",
  },
  {
    value: 208,
    day: "2016-08-10",
  },
  {
    value: 149,
    day: "2017-11-25",
  },
  {
    value: 226,
    day: "2017-12-31",
  },
  {
    value: 348,
    day: "2016-09-22",
  },
  {
    value: 218,
    day: "2015-04-09",
  },
  {
    value: 59,
    day: "2017-12-09",
  },
  {
    value: 233,
    day: "2016-06-18",
  },
  {
    value: 128,
    day: "2015-12-01",
  },
  {
    value: 349,
    day: "2015-07-10",
  },
  {
    value: 162,
    day: "2017-03-31",
  },
  {
    value: 349,
    day: "2015-07-28",
  },
  {
    value: 369,
    day: "2015-10-20",
  },
  {
    value: 127,
    day: "2018-02-22",
  },
  {
    value: 309,
    day: "2015-11-20",
  },
  {
    value: 11,
    day: "2018-04-02",
  },
  {
    value: 134,
    day: "2016-12-22",
  },
  {
    value: 327,
    day: "2016-03-19",
  },
  {
    value: 310,
    day: "2016-09-15",
  },
  {
    value: 26,
    day: "2018-04-14",
  },
  {
    value: 396,
    day: "2017-05-17",
  },
  {
    value: 332,
    day: "2016-03-17",
  },
  {
    value: 158,
    day: "2015-09-07",
  },
  {
    value: 300,
    day: "2016-02-24",
  },
  {
    value: 284,
    day: "2015-12-25",
  },
  {
    value: 178,
    day: "2017-02-18",
  },
  {
    value: 210,
    day: "2017-11-12",
  },
  {
    value: 92,
    day: "2015-07-31",
  },
  {
    value: 92,
    day: "2016-05-03",
  },
  {
    value: 215,
    day: "2016-04-24",
  },
  {
    value: 132,
    day: "2015-08-31",
  },
  {
    value: 252,
    day: "2016-12-02",
  },
  {
    value: 335,
    day: "2017-09-09",
  },
  {
    value: 263,
    day: "2015-04-26",
  },
  {
    value: 212,
    day: "2018-05-31",
  },
  {
    value: 63,
    day: "2017-03-22",
  },
  {
    value: 75,
    day: "2016-10-09",
  },
  {
    value: 93,
    day: "2016-05-11",
  },
  {
    value: 100,
    day: "2018-07-25",
  },
  {
    value: 15,
    day: "2016-10-05",
  },
  {
    value: 124,
    day: "2015-12-09",
  },
  {
    value: 159,
    day: "2016-02-21",
  },
  {
    value: 259,
    day: "2016-03-11",
  },
  {
    value: 319,
    day: "2017-07-29",
  },
  {
    value: 127,
    day: "2016-07-02",
  },
  {
    value: 379,
    day: "2017-01-24",
  },
  {
    value: 48,
    day: "2016-01-20",
  },
  {
    value: 288,
    day: "2016-10-03",
  },
  {
    value: 264,
    day: "2018-05-18",
  },
  {
    value: 181,
    day: "2016-03-22",
  },
  {
    value: 118,
    day: "2018-07-08",
  },
  {
    value: 117,
    day: "2017-12-14",
  },
  {
    value: 115,
    day: "2016-09-16",
  },
  {
    value: 356,
    day: "2017-03-26",
  },
  {
    value: 293,
    day: "2015-05-20",
  },
  {
    value: 140,
    day: "2017-10-04",
  },
  {
    value: 111,
    day: "2018-01-19",
  },
  {
    value: 387,
    day: "2017-11-05",
  },
  {
    value: 174,
    day: "2015-07-19",
  },
  {
    value: 317,
    day: "2016-12-25",
  },
  {
    value: 191,
    day: "2018-05-01",
  },
  {
    value: 26,
    day: "2018-03-30",
  },
  {
    value: 242,
    day: "2017-03-19",
  },
  {
    value: 23,
    day: "2015-07-08",
  },
  {
    value: 164,
    day: "2017-05-19",
  },
  {
    value: 397,
    day: "2015-08-25",
  },
  {
    value: 142,
    day: "2017-06-27",
  },
  {
    value: 39,
    day: "2016-04-06",
  },
  {
    value: 303,
    day: "2018-08-09",
  },
  {
    value: 382,
    day: "2016-12-04",
  },
  {
    value: 170,
    day: "2017-12-22",
  },
  {
    value: 356,
    day: "2015-07-25",
  },
  {
    value: 176,
    day: "2017-11-27",
  },
  {
    value: 303,
    day: "2017-03-24",
  },
  {
    value: 339,
    day: "2015-10-15",
  },
  {
    value: 240,
    day: "2017-06-19",
  },
  {
    value: 244,
    day: "2015-09-06",
  },
  {
    value: 88,
    day: "2015-11-11",
  },
  {
    value: 117,
    day: "2015-06-29",
  },
  {
    value: 88,
    day: "2015-07-14",
  },
  {
    value: 313,
    day: "2016-08-31",
  },
  {
    value: 17,
    day: "2015-04-17",
  },
  {
    value: 11,
    day: "2017-01-22",
  },
];

export const CHANNELS = [
  { value: null, label: "All Channels" },
  { value: "webchat", label: "Web Chat" },
  { value: "widget", label: "Widget" },
  { value: "direct-chat", label: "Direct Chat" },
  { value: "teams", label: "Teams" },
  { value: "webex", label: "Webex" },
];

export const IGNORED_EXTENSIONS = [
  "aif",
  "cda",
  "mid",
  "midi",
  "mp3",
  "mpa",
  "ogg",
  "wav",
  "wma",
  "wpl",
  "7z",
  "arj",
  "deb",
  "pkg",
  "rar",
  "rpm",
  "tar.gz",
  "z",
  "zip",
  "bin",
  "dmg",
  "iso",
  "toast",
  "vcd",
  "csv",
  "dat",
  "db",
  "dbf",
  "log",
  "mdb",
  "sav",
  "sql",
  "tar",
  "email",
  "eml",
  "emlx",
  "msg",
  "oft",
  "ost",
  "pst",
  "vcf",
  "apk",
  "bat",
  "bin",
  "cgi",
  "pl",
  "exe",
  "gadget",
  "jar",
  "msi",
  "py",
  "wsf",
  "fnt",
  "fon",
  "otf",
  "ttf",
  "bmp",
  "gif",
  "jpeg",
  "jpg",
  "png",
  "ps",
  "psd",
  "svg",
  "tif",
  "tiff",
  "cer",
  "cfm",
  "cgi",
  "pl",
  "css",
  "js",
  "part",
  "py",
  "key",
  "odp",
  "pps",
  "ppt",
  "pptx",
  "c",
  "cgi",
  "pl",
  "class",
  "cpp",
  "cs",
  "h",
  "java",
  "py",
  "sh",
  "swift",
  "vb",
  "ods",
  "xls",
  "xlsm",
  "xlsx",
  "bak",
  "cab",
  "cfg",
  "cpl",
  "cur",
  "dll",
  "dmp",
  "drv",
  "icns",
  "ico",
  "ini",
  "lnk",
  "msi",
  "sys",
  "tmp",
  "3g2",
  "3gp",
  "avi",
  "flv",
  "h264",
  "m4v",
  "mkv",
  "mov",
  "mp4",
  "mpg",
  "mpeg",
  "rm",
  "swf",
  "vob",
  "wmv",
  "doc",
  "docx",
  "odt",
  "pdf",
  "rtf",
  "tex",
  "wpd",
];

export const DocOptions: any[] = [
  { value: "other", label: "Lesson documents (with paragraphs of text)" },
  { value: "lesson_slides", label: "Lesson slides" },
  { value: "ebook", label: "Ebook" },
  { value: "qna", label: "FAQ" },
];

export const sumarieseToolbar: any = {
  options: [
    "history",
    "inline",
    "list",
    // 'link',
    // "embedded",
  ],
  inline: {
    inDropdown: false,
    className: "markdown-toolbar-option",
    component: undefined,
    dropdownClassName: undefined,
    options: [
      "bold",
      "italic",
      // "underline",
      // "strikethrough",
      // "monospace",
      // "superscript",
      // "subscript",
    ],
    bold: { icon: bold, className: "option-bold" },
    italic: { icon: italic, className: "demo-option-custom" },
    underline: { icon: underline, className: "markdown-toolbar-icon" },
    strikethrough: { icon: strike, className: "markdown-toolbar-icon" },
    // monospace: { icon: monospace, className: "markdown-toolbar-icon" },
    superscript: { icon: superscript, className: "markdown-toolbar-icon" },
    subscript: { icon: subscript, className: "markdown-toolbar-icon" },
  },
  list: {
    inDropdown: false,
    className: "markdown-toolbar-option",
    component: undefined,
    dropdownClassName: undefined,
    options: ["unordered", "ordered"],
    unordered: { icon: bulletlist, className: "emo-option-custom" },
    ordered: { icon: numberlist, className: "emo-option-custom" },
    // indent: { icon: indent, className: undefined },
    // outdent: { icon: outdent, className: undefined },
  },
};
