import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import downarrowblk from "../../../../assets/New-images/dropdown-arow.svg";
import addimage from "../../../../assets/images/chevron-down-blk.svg";
import AddTags from "../../../../components/UI/AddTags/AddTags";
import Loading from "../../../../components/UI/Loading/Loading";
import PermissionWrapper from "../../../../components/UI/PermissionWrapper/PermissionWrapper";
import ShowHideChatInput from "../../../../components/UI/ShowHideChatInput/ShowHideChatInput";
import Tags from "../../../../components/UI/Tags/Tags";
import {
  localUserQuestionUpdate,
  updateIntentParentQuestion,
  updateIntentTags,
} from "../../../../store/questions/actions";
import { getAllIntents, getIntent } from "../../../../store/questions/selector";

import { useTranslation } from "react-i18next";

interface Props {}

export const SettingsTab = (props: any) => {
  const { t } = useTranslation("translation");
  const allIntents = useSelector(getAllIntents);
  const intent = useSelector(getIntent);
  const dispatch = useDispatch();
  const [AllIntents, setAllIntents] = useState([]);
  const [isInputDisabled, setisInputDisabled] = useState(null);
  const [parentQuestionValue, setParentQuestionValue] = useState(null);
  const [questionUpdating, setQuestionUpdating] = useState(false);
  const { showsParentAnswer = true } = props;
  useEffect(() => {
    setAllIntents([
      ...allIntents.map((intent: any) => {
        return {
          label: intent.name,
          value: intent._id,
        };
      }),
    ]);
  }, [allIntents]);

  useEffect(() => {
    setisInputDisabled(intent?.is_input_disabled);
    // If intent found updated selected state
    if (intent) {
      const findIntent = allIntents.find(
        (intents: any) => intents._id === intent.parent_id
      );
      if (findIntent) {
        setParentQuestionValue({
          label: findIntent.name,
          value: findIntent._id,
        });
      }
    } else {
      setParentQuestionValue(null);
    }
  }, [intent, allIntents]);

  const chooseQuestionHandler = async (e: any) => {
    setQuestionUpdating(true);
    if (props.editMode) {
      await dispatch(updateIntentParentQuestion(e ? e.value : null));
      if (!e) {
        setParentQuestionValue(null);
      }
    } else {
      dispatch(localUserQuestionUpdate("parent_id", e ? e.value : null));
      if (!e) {
        setParentQuestionValue(null);
      }
    }
    setQuestionUpdating(false);
  };

  const chatInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      localUserQuestionUpdate(
        "is_input_disabled",
        e.target.value === "false"
          ? false
          : e.target.value === "true"
          ? true
          : null
      )
    );
  };

  if (questionUpdating) {
    return <Loading />;
  }

  return (
    <>
      <div className="row">
        {showsParentAnswer && (
          <div className="col-md-5 col-lg-3 mb-4 sugested-next-ques suggested-parent-select-wrapper">
            <h2 className="font-600">{t("Choose Parent Question")}</h2>
            <Form>
              <Select
                isSearchable
                isClearable
                className="select-target"
                classNamePrefix="select-target-prefix"
                components={{
                  DropdownIndicator: () => (
                    <img src={downarrowblk} alt={t("dropdown arrow")} />
                  ),
                  IndicatorSeparator: () => null,
                }}
                placeholder={t("Select a question")}
                onChange={chooseQuestionHandler}
                value={
                  parentQuestionValue && parentQuestionValue.value
                    ? { ...parentQuestionValue }
                    : null
                }
                options={AllIntents}
                // getOptionLabel={(option: any) => option['name']}
                // getOptionValue={(option: any) => option['_id']}
              />
            </Form>
          </div>
        )}

        <div className="col-md-12">
          <ShowHideChatInput
            changeHandler={chatInputHandler}
            isInputDisabled={isInputDisabled}
          />
        </div>

        {/* <div className='col-md-12'>
          <h2 className='mb-3'>Tags</h2>
          
        </div> */}
      </div>
    </>
  );
};

export default SettingsTab;
