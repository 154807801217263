import { applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
const windowObj: any = window
const loggerMiddleware = createLogger({
    duration: true,
    collapsed: true,
    colors: {
        title: () => '#139bfe',
        error: () => '#ff0005',
    },
});

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

const composeEnhancers = windowObj.REACT_DEVELOPMENT_MODE === 'production' ? compose : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const enhancedStore = composeEnhancers(
    windowObj.REACT_DEVELOPMENT_MODE === 'production' ? applyMiddleware(thunk): applyMiddleware(loggerMiddleware as any, thunk));
export { enhancedStore };
