import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import skipImg from "../../../assets/New-images/skip-img.svg";
import qAndNas from "../../../assets/New-images/qnas.svg";
import chevLeft from "../../../assets/New-images/chevron-left-blk.svg";

import ProgressSteps from "../../../components/UI/ProgressSteps/ProgressSteps";
import { BUILD_FINAL, GENERATE_QNA } from "../../../config";

const GenerateKnowledge = (props: any) => {
  const history: any = useHistory();
  const { t } = useTranslation("translation");
  const query = new URLSearchParams(history.location.search);
  const documentId = query.get("references");
  const params: any = useParams();
  const generateType = history.location.state;
  const [groupId, setGroupId] = useState(query.get("group") || "");

  useEffect(() => {
    setGroupId(
      history &&
        history.location &&
        history.location.state &&
        history.location.state.group
        ? history.location.state.group
        : query.get("group")
    );
  }, []);

  return (
    <React.Fragment>
      <section className="page-mid-wraper page-mid-wraper-build-v2">
        <Container>
          {!props.hideHeaders && (
            <>
              <div className="d-flex flex-wrap justify-content-center clr-primary position-relative">
                <button
                  className="btn py-0 mr-2 position-absolute position-l-0 "
                  onClick={() => {
                    history.push(
                      `/build/3/document-summary?references=${params.questionId}&group=${groupId}`
                    );
                  }}
                >
                  <img src={chevLeft} alt="" />
                </button>

                <h1 className="mb-2">
                  {t("Generate Knowledge from Document(s)")}
                </h1>
              </div>
              <div className="mb-32 font-16 text-center">
                {t(
                  "Create question and answer pairs for use in quizzes or tutoring flows"
                )}
              </div>
            </>
          )}
          <>
            <div className="build-summary-docs px-lg-5 mx-lg-4">
              <div
                className="knowledge-box know-box-new radius-8 p-3 not-hover d-flex align-items-center mb-3"
                onClick={() => {
                  history.push(
                    `${GENERATE_QNA.replace(":step", "5")}?question=${
                      params.questionId
                    }&group=${groupId}`,
                    {
                      group: groupId,
                    }
                  );
                }}
              >
                <figure className="h-auto mb-0">
                  <img src={qAndNas} alt="" />
                </figure>
                <div className="text-left pl-3">
                  <h3 className="font-weight-bold font-1 clr-primary mb-1">
                    {t("Generate Question & Answer Pairs")}
                  </h3>
                  <p className="clr-grey-txt7 font-sm font-600">
                    {t(
                      "Our AI tool will generate questions and answers pairs from the document(s) added to the knowledge base."
                    )}
                  </p>
                </div>
              </div>
              <div
                className="knowledge-box know-box-new radius-8 p-3 not-hover d-flex align-items-center mb-3"
                onClick={() => history.push(BUILD_FINAL)}
              >
                <figure className="h-auto mb-0">
                  <img src={skipImg} alt="" />
                </figure>
                <div className="text-left pl-3">
                  <h3 className="font-weight-bold font-1 clr-primary mb-1">
                    {t("Skip this Step")}
                  </h3>
                  {/* <p className="clr-grey-txt7 font-sm font-600">
                    {t(
                      "You may skip generating question and answer pairs for now and do that later."
                    )}
                  </p> */}
                </div>
              </div>
            </div>
          </>
          <div className="pt-2">
            <ProgressSteps currentStep={params.step === "5" && 4} />
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default GenerateKnowledge;
