import { ButtonGroup, Button } from "react-bootstrap";
import Select from "react-select";
import PermissionWrapper from "../../components/UI/PermissionWrapper/PermissionWrapper";

import { useTranslation } from "react-i18next";
import { t } from "i18next";

export { default } from "./OrganizationUsersAdmin";
export const customStyles = {
  header: {
    style: {
      minHeight: "56px",
    },
  },
  headRow: {
    style: {
      borderRadius: "10px",
      backgroundColor: "#0923E6",
      borderBottomWidth: "0",
    },
  },
  expanderRow: {
    style: {
      // backgroundColor: '#0923E6',
    },
  },
  expanderCell: {
    style: {
      "&:hover": {
        backgroundColor: "transparent",
      },
      button: {
        backgroundColor: "transparent !important",
        img: {
          width: "10px",
        },
      },
    },
  },
  expanderButton: {
    style: {
      backgroundColor: "transparent",
      alignItems: "center",
    },
  },
  headCells: {
    style: {
      "&:first-child": {
        borderTopLeftRadius: "5px",
        borderBottomLeftRadius: "0px",
      },
      "&.chart--table-sort-icon": {
        marginLeft: "10px",
      },
      'input[type="checkbox"]': {
        width: "16px",
        height: "16px",
      },
      backgroundColor: "#0923E6",
      width: "250px",
      padding: "20px 20px",
      fontSize: "14px",
      color: "#fff",
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "#FFFFFF",
      },
      div: {
        color: "#fff",
      },
    },
  },
  cells: {
    style: {
      color: "#07032B",
      fontSize: "14px",
      paddingTop: "10px",
      paddingBottom: "10px",
      'input[type="checkbox"]': {
        width: "16px",
        height: "16px",
      },
      "&:nth-child(1)": {
        div: {
          textOverflow: "ellipsis",
          display: " -webkit-box",
          WebkitLineClamp: "2",
          WebkitBoxOrient: "vertical",
        },
      },
      "&:nth-child(2)": {
        div: {
          textOverflow: "ellipsis",
          display: " -webkit-box",
          WebkitLineClamp: "2",
          WebkitBoxOrient: "vertical",
        },
      },
    },
  },
};

export const Email = ({ row }: any) => <div>{row.email}</div>;
export const FirstName = ({ row }: any) => <div>{row.first_name}</div>;
export const LastName = ({ row }: any) => <div>{row.last_name}</div>;
export const Status = ({ row }: any) => {
  const { t } = useTranslation('translation');
  return (<div className="agent-list">
    {row.status === "active" ? (
      <span className="active">{t("Active")}</span>
    ) : row.status === "inactive" ? (
      <span className="in-active">{t("Pending Invite")}</span>
    ) : (
      "-"
    )}
  </div>)
};
export const Permissions = ({ row, addRoleUserToAdmins }: any) => (
  <div className="d-flex flex-wrap">
    <Select
      isSearchable={false}
      className="select-sort select-target"
      classNamePrefix="select-target-prefix"
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
      }}
      onChange={(e: any) => {
        addRoleUserToAdmins(row._id, e.value, row.is_superadmin);
      }}
      placeholder={t("Permission")}
      getOptionLabel={(option: any) => option["label"]}
      getOptionValue={(option: any) => option["value"]}
      options={[
        { value: "admins", label: t("Can Edit") },
        { value: "readonlyadmins", label: t("View Only") },
      ]}
      defaultValue={
        row.role === "readonlyadmin"
          ? { value: "readonlyadmins", label: t("View Only") }
          : row.role === "admin" || row.is_superadmin
          ? { value: "admins", label: t("Can Edit") }
          : null
      }
    />
  </div>
);
export const ActionColumn = ({
  row,
  deleteAdminsFromRolesHandler,
  editAdminToggleHandler,
  resendInvite,
}: any) => (
  <div>
    <ButtonGroup aria-label="Basic example">
      <PermissionWrapper>
        <Button
          variant="edit"
          onClick={() => editAdminToggleHandler(row, true)}
        >
          {t("Edit")}
        </Button>
        {row.status === "inactive" && (
          <Button variant="edit" onClick={() => resendInvite(row)}>
            {t("Resend Invite")}
          </Button>
        )}
        <Button
          variant="delete"
          onClick={() => deleteAdminsFromRolesHandler(row._id, row.role)}
        >
          {t("Remove from agent")}
        </Button>
      </PermissionWrapper>
    </ButtonGroup>
  </div>
);

export const columns = ({
  deleteAdminsFromRolesHandler,
  addAdminToggleHandler,
  editAdminToggleHandler,
  addRoleUserToAdmins,
  resendInvite,
}: any) => [
  {
    name: "Action",
    selector: "",
    sortable: false,
    cell: (row: any) => (
      <ActionColumn
        row={row}
        deleteAdminsFromRolesHandler={deleteAdminsFromRolesHandler}
        addAdminToggleHandler={addAdminToggleHandler}
        editAdminToggleHandler={editAdminToggleHandler}
        resendInvite={resendInvite}
      />
    ),
  },
  {
    name: "Permission",
    selector: "role",
    sortable: false,
    cell: (row: any) => (
      <Permissions row={row} addRoleUserToAdmins={addRoleUserToAdmins} />
    ),
  },
  {
    name: "Email",
    selector: "email",
    sortable: true,
    cell: (row: any) => <Email row={row} />,
  },
  {
    name: "First Name",
    selector: "first_name",
    sortable: true,
    cell: (row: any) => <FirstName row={row} />,
  },
  {
    name: "Last Name",
    selector: "last_name",
    sortable: true,
    cell: (row: any) => <LastName row={row} />,
  },
  {
    name: "Status",
    selector: "status",
    sortable: true,
    cell: (row: any) => <Status row={row} />,
  },
];
