import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

// const embedLink = "https://analytics.noodlefactory.ai/embed/dashboard/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyZXNvdXJjZSI6eyJkYXNoYm9hcmQiOjEwfSwicGFyYW1zIjp7Im9yZ2FuaXNhdGlvbl9pZCI6IjVlMWMyMWQ4ZjM1MTZmZDRjZGM4MzIxZSIsImFnZW50X2lkIjoiNWUxYzIxZDhmMzUxNmZkNGNkYzgzMjFlIn0sImV4cCI6MTcwNTU0OTY3Mn0.v7yvW6jgk2Iuf9xuy-FPNo_WLV-zfHvrNnyTX1MZd_M#bordered=false&titled";
const embedLink = "https://www.noodlefactory.ai";

export const TeamDash = () => {
  const { t } = useTranslation("translation");

  useEffect(() => {
    // get iframe
    // const data: any = await dispatch(getMetaBaseData('overview'))
  }, []);

  return (
    <div className="w-100 h-100">
      <iframe
        id="team-overview"
        src={embedLink}
        title={t("Overview")}
        className="w-100 h-100 border-0"
      ></iframe>
    </div>
  );
};
export default TeamDash;
