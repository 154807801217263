import { convertFromRaw, EditorState } from 'draft-js'
// import { mdToDraftjs } from 'draftjs-md-converter'
import React, { memo, useEffect, useState } from 'react'
import { Image } from 'react-bootstrap'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { emojiList } from '../../../app.constant'
import bulletlist from '../../../assets/editor/bullet-list.svg'
import bold from '../../../assets/editor/Icon-feather-bold.svg'
import image from '../../../assets/editor/Icon-feather-image.svg'
import italic from '../../../assets/editor/Icon-feather-italic.svg'
import link from '../../../assets/editor/Icon-feather-link.svg'
import emoji from '../../../assets/editor/Icon-feather-smile.svg'
import numberlist from '../../../assets/editor/number-list.svg'
import redo from '../../../assets/editor/redo.svg'
import strike from '../../../assets/editor/strike.svg'
import subscript from '../../../assets/editor/subscript.svg'
import superscript from '../../../assets/editor/superscript.svg'
import underline from '../../../assets/editor/underline.svg'
import undo from '../../../assets/editor/undo.svg'
import video from '../../../assets/editor/video.svg'
import closeimg from '../../../assets/images/close-blk.svg'
import ApiUtil from '../../../utils/apiUtil'
import BubbleBreakOption from './CustomOptions/BubbleBreakOption'
import EmojiOption from './CustomOptions/EmojiOptions/EmojiOptions'
import InsertVariableOption from './CustomOptions/InsertVariableOption'
import LinkOption from './CustomOptions/LinkOption/LinkOption'
import StickerOption from './CustomOptions/StickerOption'
import './index.scss'
import mdToDraftjs from './converters/mdToDraftJs'
import SymbolOption from './CustomOptions/SymbolOption/SymbolOption'
import PeriodicTableOption from './CustomOptions/PeriodicTableOption/PeriodicTableOption'

import { useTranslation } from "react-i18next";

const RichTextAreaQuiz = ({
  label,
  description,
  value,
  onChange,
  name,
  placeholder,
  disabled,
  id,
  autoFocus,
  ref,
  required,
  showError,
  errorMessage,
  isQuizAnswer,
  noToolbar,
}: any) => {
  const { t } = useTranslation('translation');
  const [editorState, setEditorState] = useState(null);

  const handleEmbedCallback = (link: any) => {
    console.log("callback", link);
    let videoPattern = /(http(s?):)([/|.|\w|\s|-])*\.(?:mp4)/gim;
    let youtubePattern = /(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/(watch\?v=|embed)(\S+)/gim;
    let vimeoPattern = /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/gim;
    return link
      .replace(
        videoPattern,
        '<video controls> <source src="$&" type="video/mp4"> </video>'
      )
      .replace(youtubePattern, "https://www.youtube.com/embed/$8")
      .replace(vimeoPattern, "https://player.vimeo.com/video/$4");
  };

  const toBase64 = (file: any, callBack: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {
      callBack(file, reader.result);
    };
    reader.onerror = function(error) {
      console.log("Error: ", error);
    };
  };

  const uploadToCloud = (file_data: File) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append('media', file_data)

      ApiUtil.SendAsync<any>({
        url: `media/images/upload`,
        method: 'POST',
        data: formData
      }).then((json: any) => {
        if (json.status === 200) {
          resolve({ data: { link: json.data.data.media.url } })
        } else {
          reject(json)
        }
      })
    })
  }

  const handleLinkCallback = (linkObject: any) => {
    console.log(linkObject);
    return linkObject;
  };

  const toolbarQuiz: any = {
    options: [
      // "inline",
      "list",
      // "link",
      // "embedded",
      // "emoji",
      // "image",
      "history",
    ],
    inline: {
      inDropdown: true,
      className: "markdown-toolbar-option",
      component: undefined,
      dropdownClassName: undefined,
      options: [
        "bold",
        "italic",
        // "underline",
        // "strikethrough",
        // "monospace",
        // "superscript",
        // "subscript",
      ],
      bold: { icon: bold, className: "markdown-toolbar-icon" },
      italic: { icon: italic, className: "markdown-toolbar-icon" },
      underline: { icon: underline, className: "markdown-toolbar-icon" },
      strikethrough: { icon: strike, className: "markdown-toolbar-icon" },
      // monospace: { icon: monospace, className: "markdown-toolbar-icon" },
      superscript: { icon: superscript, className: "markdown-toolbar-icon" },
      subscript: { icon: subscript, className: "markdown-toolbar-icon" },
    },
    list: {
      inDropdown: false,
      className: "markdown-toolbar-option",
      component: undefined,
      dropdownClassName: undefined,
      options: ["unordered", "ordered"],
      unordered: { icon: bulletlist, className: "markdown-toolbar-icon" },
      ordered: { icon: numberlist, className: "markdown-toolbar-icon" },
      // indent: { icon: indent, className: undefined },
      // outdent: { icon: outdent, className: undefined },
    },
    link: {
      inDropdown: false,
      className: "markdown-toolbar-option",
      component: LinkOption,
      popupClassName: undefined,
      dropdownClassName: undefined,
      showOpenOptionOnHover: false,
      defaultTargetOption: "_blank",
      options: ["link"],
      link: { icon: link, className: undefined },
      // unlink: { icon: unlink, className: undefined },
      linkCallback: undefined,
    },
    emoji: {
      icon: emoji,
      className: "markdown-toolbar-option",
      component: undefined,
      popupClassName: undefined,
      emojis: emojiList,
    },
    embedded: {
      icon: video,
      className: "markdown-toolbar-option",
      component: undefined,
      popupClassName: undefined,
      embedCallback: handleEmbedCallback,
      defaultSize: {
        height: "auto",
        width: "auto",
      },
    },
    image: {
      icon: image,
      className: "markdown-toolbar-option",
      component: undefined,
      popupClassName: undefined,
      urlEnabled: true,
      uploadEnabled: true,
      alignmentEnabled: false,
      uploadCallback: uploadToCloud,
      previewImage: true,
      inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
      alt: { present: false, mandatory: false },
      defaultSize: {
        height: "auto",
        width: "400",
      },
    },
    history: {
      inDropdown: false,
      className: "markdown-toolbar-option",
      component: undefined,
      dropdownClassName: undefined,
      options: ["undo", "redo"],
      undo: { icon: undo, className: undefined },
      redo: { icon: redo, className: undefined },
    },
  };

  const toolbar: any = {
    options: [
      "inline",
      "list",
      "link",
      // "embedded",
      "history",
      "image",
      "emoji",
    ],
    inline: {
      inDropdown: true,
      className: "markdown-toolbar-option",
      component: undefined,
      dropdownClassName: undefined,
      options: [
        "bold",
        "italic",
        // "underline",
        // "strikethrough",
        // "monospace",
        // "superscript",
        // "subscript",
      ],
      bold: { icon: bold, className: "markdown-toolbar-icon" },
      italic: { icon: italic, className: "markdown-toolbar-icon" },
      underline: { icon: underline, className: "markdown-toolbar-icon" },
      strikethrough: { icon: strike, className: "markdown-toolbar-icon" },
      // monospace: { icon: monospace, className: "markdown-toolbar-icon" },
      superscript: { icon: superscript, className: "markdown-toolbar-icon" },
      subscript: { icon: subscript, className: "markdown-toolbar-icon" },
    },
    list: {
      inDropdown: true,
      className: "markdown-toolbar-option",
      component: undefined,
      dropdownClassName: undefined,
      options: ["unordered", "ordered"],
      unordered: { icon: bulletlist, className: "markdown-toolbar-icon" },
      ordered: { icon: numberlist, className: "markdown-toolbar-icon" },
      // indent: { icon: indent, className: undefined },
      // outdent: { icon: outdent, className: undefined },
    },
    link: {
      inDropdown: false,
      className: "markdown-toolbar-option",
      component: LinkOption,
      popupClassName: undefined,
      dropdownClassName: undefined,
      showOpenOptionOnHover: false,
      defaultTargetOption: "_blank",
      options: ["link"],
      link: { icon: link, className: undefined },
      // unlink: { icon: unlink, className: undefined },
      linkCallback: undefined,
    },
    emoji: {
      icon: emoji,
      className: "markdown-toolbar-option",
      component: EmojiOption,
      popupClassName: undefined,
      emojis: emojiList,
    },
    embedded: {
      icon: video,
      className: "markdown-toolbar-option",
      component: undefined,
      popupClassName: undefined,
      embedCallback: handleEmbedCallback,
      defaultSize: {
        height: "auto",
        width: "auto",
      },
    },
    image: {
      icon: image,
      className: "markdown-toolbar-option",
      component: undefined,
      popupClassName: undefined,
      urlEnabled: true,
      uploadEnabled: true,
      alignmentEnabled: false,
      uploadCallback: uploadToCloud,
      previewImage: true,
      inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
      alt: { present: false, mandatory: false },
      defaultSize: {
        height: "auto",
        width: "400",
      },
    },
    history: {
      inDropdown: false,
      className: "markdown-toolbar-option",
      component: undefined,
      dropdownClassName: undefined,
      options: ["undo", "redo"],
      undo: { icon: undo, className: undefined },
      redo: { icon: redo, className: undefined },
    },
  };

  return (
    <div className="markdown-container">
      {label && (
        <label className="markdown-label">
          {label}
          <div className="markdown-description">{description}</div>
        </label>
      )}
      <Editor
        stripPastedStyles={true}
        editorState={value}
        toolbar={
          noToolbar ? { options: [] } : isQuizAnswer ? toolbarQuiz : toolbar
        }
        // toolbarClassName="toolbarClassName"
        // wrapperClassName="markdown-editor-wrapper"
        // editorClassName="rdw-storybook-editor"

        wrapperClassName='wrapper-class markdown-editor-wrapper'
        editorClassName='editor-class'
        toolbarClassName='toolbar-class'
        
        onEditorStateChange={(state) => onChange(state, id)}
        // customBlockRenderFunc={getBlockRenderFunc}
        toolbarCustomButtons={
          noToolbar
            ? []
            : isQuizAnswer
            ? [<SymbolOption />, <PeriodicTableOption />]
            : [
                <SymbolOption />,
                <PeriodicTableOption />,
                <StickerOption />,
                <BubbleBreakOption />,
                <InsertVariableOption />,
              ]
        }
        placeholder={
          placeholder
            ? placeholder
            : t("Type here. Quick tip: Click on 🔗 in the above toolbar to insert video, image and website links.")
        }
      />
      {showError && <div className="markdown-error">{errorMessage}</div>}
    </div>
  );
};


export default RichTextAreaQuiz;
