import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { useHistory, useLocation } from "react-router-dom";
import classNames from "classnames";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import cx from "classnames";

import backArrow from "../../../assets/images/arrow-left.svg";
import PDFViewer from "../../../components/UI/PDFViewer/PDFViewer";
import QuizDocumentPreview from "../QuizDocumentPreview";
import {
  getQuizDocumentsContents,
  getQuizDocumentTableJobsByJobId,
  quizDocumentQuestions,
  getQuizDocumentTableJobsQuestions,
  updateQuiz as updateQuizAction,
} from "../../../store/quizzes/actions";
import Loading from "../../../components/UI/Loading/Loading";
import "./index.scss";
import { HelperService } from "../../../services/helper.service";
import QuizService from "../../../services/QuizService";
//customtemplates
const quizService = new QuizService();
const helperService = new HelperService();

export const ImportFilePreview = (props: any) => {
  const history = useHistory();
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const [documentContent, setDocumentContent] = useState(null);
  const [isLoading, setIsloading] = useState(null);
  const [extractedTables, setExtractedTables] = useState([]);
  const [extractionStatus, setExtractionStatus] = useState("");
  const { docId }: any = useParams();
  const useLocatio = useLocation();
  let invervalGetQna: any = null;
  const query = new URLSearchParams(useLocatio.search);
  const queryParams = new URLSearchParams(props.location.search);
  const createQuiz = query.get("through");
 
  const [selectedFileType, setSelectedFileType] = useState(
    props.location?.state?.selectedFileType
      ? props.location?.state?.selectedFileType
      : false
  );
  const [selectedFile, setSelectedFile] = useState<any>(
    props.location?.state?.selectedFile
      ? props.location?.state?.selectedFile
      : null
  );
  const [selectedFileName, setSelectedFileName] = useState<any>(
    props.location?.state?.selectedFileName
      ? props.location?.state?.selectedFileName
      : null
  );

  const [createQuizGenerateQna, setCreateQuizGenerateQna] = useState(false);
  const helper = new HelperService();
  const extractTablesFromDocument = async (
    documentId: string,
    jobId: string
  ) => {
    const requestOptions = {
      method: "GET",
    };

    try {
      const jobResponse: any = await dispatch(
        getQuizDocumentTableJobsByJobId(documentId, jobId)
      );

      setExtractionStatus(jobResponse.job_status);
      if (jobResponse.job_status === "SUCCEEDED") {
        setExtractedTables(jobResponse.tables);
      } else if (jobResponse.job_status === "IN_PROGRESS") {
        setTimeout(() => {
          extractTablesFromDocument(documentId, jobId);
        }, 5000);
      } else if (jobResponse.job_status === "FAILED") {
        helper.showToaster("Oops! Could not extract tables.");
      }
    } catch (e) {}
  };

  const getQuizDocumentContent = async (documentId: any) => {
    setIsloading(true);
    try {
      const documentContent: any = await dispatch(
        getQuizDocumentsContents(documentId)
      );
      setIsloading(false);
      setDocumentContent(documentContent);
    } catch (error) {
      console.log("[ error ]", error);
    }
  };

  const getJobId = async () => {
    let documentId = docId;
    try {
      const jobResponse: any = await dispatch(
        getQuizDocumentTableJobsQuestions(documentId)
      );
      extractTablesFromDocument(documentId, jobResponse.job_id);
    } catch (e) {}
  };

  const handleGeneratedQuestions = async (
    questions: any,
    content: any = null,
    documentId: any = null,
    documentType: any = null
  ) => {
    let quizArray: any = { questions: [] };
    const query = new URLSearchParams(useLocatio.search);
    const group = query.get("group");
    const createQuiz = query.get("through");
    const groupQuery = group ? `?group=${group}` : "";

    questions.forEach(function (element: any) {
      let quiz = {
        ...element,
        id: element.id ? element.id : uuidv4(),
      };
      if (quiz.ideal_answer === null) {
        quiz.ideal_answer = "";
      }

      quizArray.questions.push({
        ...quiz,
        text: quiz.text.replaceAll("\n", "<br>"),
      });
    });

    if (createQuiz === "create-quiz") {
      if (selectedFileType === "customtemplates") {
        history.push("/quizzes/edit/" + queryParams.get('quizId'), {
          selectedFileType: selectedFileType === "customtemplates",
          documentContent: content,
          quizArray,
          selectedFile: selectedFile,
          documentId: documentId,
          documentType: documentContent.documentType,
        });
        return;
      }

      try {
        await dispatch(
          updateQuizAction(queryParams.get('quizId'), quizArray)
        );
        history.push("/quizzes/edit/" + queryParams.get('quizId'), {
          selectedFileType: selectedFileType,
        });
      } catch (e) {}
    } else {
      history.push("/quizzes/edit/" + queryParams.get('quizId') + groupQuery, { //uuiddv4(),
        selectedFileType: true,
        documentContent: content,
        quizArray,
        selectedFile: "customtemplates",
        documentId: documentId,
        documentType,
        isMgs: true,
        createFromTestFile: true,
        group: group,
        quizId: props.location.state?.quizId,
        school: props.location.state?.school,
      });
    }
  };

  const getQna = async (jobId: any, content: any = "") => {
    const resQna: any = await quizService.getQnaGenFromJobId(jobId);
    if (resQna?.data?.data?.quiz_extraction_job?.status === "completed") {
      handleGeneratedQuestions(
        resQna?.data?.data?.quiz_extraction_job?.questions,
        content ? content : documentContent.document_content,
        docId
      );
      setIsloading(false);
      clearInterval(invervalGetQna);
    }
    return resQna;
  };

  const startGenQna = async (content: any = "") => {
    try {
      setIsloading(true);
      const res: any = await quizService.startQnaDocGen({
        document_content: content ? content : documentContent.document_content,
      });
      const jobId = res?.data?.data?.quiz_extraction_job?._id;
      if (jobId) {
        await getQna(jobId, content);
        invervalGetQna = setInterval(async () => {
          await getQna(jobId, content);
        }, 15 * 1000);
      }
    } catch (error) {
      helperService.showToaster(
        t("Something went wrong while fetching questions.")
      );
      setIsloading(false);
    }
  };

  const getQuizDocumentsFromContent = async () => {
    try {
      // await SetExtractQuestionClicked(true);
      let content = "";
      const contents = document.querySelectorAll(".pre-quiz-info-extract");
      contents.forEach((element) => {
        content += element.innerHTML + "\n";
      });
      setCreateQuizGenerateQna(false);
      await startGenQna(content);
    } catch (e) {
      console.log("error", e);
      // setIsLoad(false);
    }
  };

  useEffect(() => {
    if (docId) {
      getQuizDocumentContent(docId);
    }
  }, [docId]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <section
      className={classNames({
        "page-mid-wraper": true,
        "h-without-foter": true,
        preview: true,
      })}
    >
      <Container>
        <Row>
          <Col md={12}>
            <button
              className="btn p-0 btn-back"
              onClick={() => {
                history.goBack();
              }}
            >
              <img src={backArrow} alt="" />
              {t("Return to previous page")}
            </button>
            <div
              className={cx({
                "text-center": createQuiz === "create-quiz",
              })}
            >
              <h1
                className={cx({
                  "align-items-center m-0": true,
                  "clr-primary font-weight-bold": createQuiz === "create-quiz",
                })}
              >
                {t("Manage Uploaded Test")}
              </h1>
              <h2 className="mb-24 font-weight-normal">
                {t("Edit the text extracted from the uploaded test document.")}
              </h2>
            </div>
          </Col>
        </Row>
        <div
          className={`upload-document-page-container add-faq-container ${
            createQuiz === "create-quiz" ? "create-quiz-upload-doc" : ""
          }`}
        >
          <div className="page-content-container">
            <Row>
              <Col md={6}>
                <PDFViewer documentId={docId} pageWidth={330} />
              </Col>
              <Col md={6}>
                <QuizDocumentPreview
                  documentContent={documentContent?.document_content}
                  documentType={documentContent?.document_type}
                  extractedTablesData={extractedTables}
                  extractionStatus={extractionStatus}
                  extractTables={getJobId}
                  hideExtractBtn={true}
                  hideTable={createQuiz === "create-quiz" ? false : true}
                  createQuizGenerateQna={createQuizGenerateQna}
                  getQuizDocumentsFromContent={getQuizDocumentsFromContent}
                  hideTableInfoBlock={
                    createQuiz === "create-quiz" ? true : false
                  }
                />
              </Col>
            </Row>
          </div>
          <div className="page-footer my-2 justify-content-end">
            <Button
              className="btn btn-primary"
              onClick={() => {
                if (createQuiz === "create-quiz") {
                  setCreateQuizGenerateQna(true);
                } else {
                  startGenQna();
                }
              }}
            >
              {t("Generate Questions")}
            </Button>
          </div>
        </div>
      </Container>
    </section>
  );
};
