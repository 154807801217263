// Export action types for questions edit and create screen
export const FETCH_KNOWLEDGE_BY_ID = "FETCH_KNOWLEDGE_BY_ID";
export const FETCH_KNOWLEDGE_BY_ID_SUCCESS = "FETCH_KNOWLEDGE_BY_ID_SUCCESS";
export const FETCH_KNOWLEDGE_BY_ID_FAIL = "FETCH_KNOWLEDGE_BY_ID_FAIL";

//  Fetch all intents
export const FETCH_ALL_INTENTS = "FETCH_ALL_INTENTS";
export const FETCH_ALL_INTENTS_SUCCESS = "FETCH_ALL_INTENTS_SUCCESS";
export const FETCH_ALL_INTENTS_FAIL = "FETCH_ALL_INTENTS_FAIL";

//  Fetch all button options
export const FETCH_ALL_BUTTON_OPTIONS = "FETCH_ALL_BUTTON_OPTIONS";
export const FETCH_ALL_BUTTON_OPTIONS_SUCCESS = "FETCH_ALL_BUTTON_OPTIONS_SUCCESS";
export const FETCH_ALL_BUTTON_OPTIONS_SUCCESS_FAIL = "FETCH_ALL_BUTTON_OPTIONS_SUCCESS_FAIL";

//  Fetch all user groups options
export const FETCH_ALL_USER_GROUP_OPTIONS = "FETCH_ALL_USER_GROUP_OPTIONS";
export const FETCH_ALL_USER_GROUP_OPTIONS_SUCCESS = "FETCH_ALL_USER_GROUP_OPTIONS_SUCCESS";
export const FETCH_ALL_USER_GROUP_OPTIONS_SUCCESS_FAIL = "FETCH_ALL_USER_GROUP_OPTIONS_SUCCESS_FAIL";

//  update questions to local state
export const LOCAL_QUESTION_UPDATE = "LOCAL_QUESTION_UPDATE";
