import React from 'react'
import { Button, Image, Modal, ModalFooter, Row, Col } from 'react-bootstrap'
import ModalBody from 'react-bootstrap/ModalBody'
import ModalHeader from 'react-bootstrap/ModalHeader'
import ModalTitle from 'react-bootstrap/ModalTitle'
import closeimg from '../../../assets/images/close-blk.svg'
import welcomeGif from '../../assets/images/switch-old-portal.gif'
import { useTranslation } from "react-i18next";


export default function WelcomeModal(props: any) {
  const { t } = useTranslation('translation');
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      dialogClassName="welcome-modal"
    >
      <ModalHeader className="pt-4 justify-content-start">
        <ModalTitle className='px-3'>
          <h1>{t("Surprise!")} 🎉</h1>
          {/* <Button onClick={props.onHide}>
            <Image src={closeimg} />
          </Button> */}
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <div className='px-3'>
          <Row>
            <Col className='mb-4 col-12'>
              {t("Welcome to Walter’s new look, designed to make your teaching experience even better.")}
              {t("Besides the new features we’re stoked to share with you soon, all current features in the previous portal are available here.")}
            </Col>
            <Col className='mb-4 col-12'>
              {t("Too soon? To switch back to the previous interface, click the dropdown menu of your Account name and hit")}
              <strong> {t("‘Switch to old portal’")}</strong>.
            </Col>
            <Col className="col-lg-7">
              <Image src={welcomeGif} alt={t("welcome")}/>
            </Col>
          </Row>
        </div>
      </ModalBody>
      <ModalFooter className="border-0 justify-content-center px-3 mb-3 mx-4 flex-nowrap">
        <button className="btn-primary btn" onClick={props.onHide}>{t("Experience Now!")}</button>
      </ModalFooter>
    </Modal>
  )
}
