import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import qAndNas from "../../../assets/New-images/qnas.svg";
import skipImg from "../../../assets/New-images/skip-img.svg";
import summariseIntoSingleImg from "../../../assets/New-images/summarise-into-single-img.svg";
import {
  BUILD_FINAL
} from "../../../config";

const LearningOutcomes = (props: any) => {
  const history: any = useHistory();
  const { t } = useTranslation("translation");
  const query = new URLSearchParams(history.location.search);
  const documentId = query.get("references");
  const params: any = useParams();
  const [groupId, setGroupId] = useState(query.get("group") || "");
  //

  useEffect(() => {
    setGroupId(
      history &&
        history.location &&
        history.location.state &&
        history.location.state.group
        ? history.location.state.group
        : query.get("group")
    );
  }, []);

  return (
    <React.Fragment>
      <section className="page-mid-wraper">
        <Container>
          {!props.hideHeaders && (
            <>
              <div className="d-flex flex-wrap justify-content-center clr-primary">
                <h1 className="mb-2">
                  {t("What would you like to do with your document(s)?")}
                </h1>
              </div>
              <div className="mb-32 font-16 text-center">
                {t("Choose an action to perform with the uploaded/selected document(s) from the knowledge base")}
              </div>
            </>
          )}
          <>
            <div className="build-summary-docs px-lg-5 mx-lg-4">
              <div
                className="knowledge-box know-box-new radius-8 p-3 not-hover d-flex align-items-center mb-3"
                onClick={() =>
                  props.setLearningOption('learning')
                }
              >
                <figure className="h-auto mb-0">
                  <img src={summariseIntoSingleImg} alt="" />
                </figure>
                <div className="text-left pl-3">
                  <h3 className="font-weight-bold font-1 clr-primary mb-1">
                    {t(
                      "Generate Learning Outcomes with Tutoring Questions"
                    )}
                  </h3>
                  <p className="clr-grey-txt7 font-sm font-600">
                    {t(
                      "Generate learning outcomes using selected/uploaded documents, and create tutoring questions incorporating both the documents and learning outcomes for comprehensive learning."
                    )}
                  </p>
                </div>
              </div>
              <div
                className="knowledge-box know-box-new radius-8 p-3 not-hover d-flex align-items-center mb-3"
                onClick={() =>
                  props.setLearningOption('tutoring')
                }
              >
                <figure className="h-auto mb-0">
                  <img src={qAndNas} alt="" />
                </figure>
                <div className="text-left pl-3">
                  <h3 className="font-weight-bold font-1 clr-primary mb-1">
                    {t("Generate Tutoring Questions")}
                  </h3>
                  <p className="clr-grey-txt7 font-sm font-600">
                    {t(
                      "Each uploaded file will be summarised individually, and both original and summarised docs are saved."
                    )}
                    <span className="d-block">
                      {t(
                        "Create tutoring questions by incorporating both the document(s) and learning outcomes for comprehensive learning."
                      )}
                    </span>
                  </p>
                </div>
              </div>
              <div
                className="knowledge-box know-box-new radius-8 p-3 not-hover d-flex align-items-center mb-3"
                onClick={() => history.push(BUILD_FINAL)}
              >
                <figure className="h-auto mb-0">
                  <img src={skipImg} alt="" />
                </figure>
                <div className="text-left pl-3">
                  <h3 className="font-weight-bold font-1 clr-primary mb-1">
                    {t("Complete Setup")}
                  </h3>
                </div>
              </div>
            </div>
          </>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default LearningOutcomes;
