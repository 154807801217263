/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import {
  Accordion, Dropdown
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  addRoleUserToAdmins,
  deleteLearnerToClasses,
  resendInvite
} from "../../../../store/knowledge/actions";
import { getAgentFromStorage } from "../../../../utils/appUtils";

import * as _ from "lodash";
import { useParams } from "react-router";
import sortIcon from "../../../../assets/New-images/sort-blue-icon.svg";
import AdminLearnerTile from "../../../../components/UI/AdminLearnerTile/AdminLearnerTile";
import ConfirmationModal from "../../../../components/UI/ConfirmationModal/ConfirmationModal";
import Loading from "../../../../components/UI/Loading/Loading";
import Pagination from "../../../../components/UI/Pagination/Pagination";
import PermissionWrapper from "../../../../components/UI/PermissionWrapper/PermissionWrapper";
import { HelperService } from "../../../../services/helper.service";
import AddLearnerToAgent from "../Learners/AddLearnerToAgent";
import BulkUploadUsers from "../Learners/BulkUploadUsers";
import AddExistingUsersToClass from "./AddExistingUsersToClass";
import { useTranslation } from "react-i18next";

const ClassLearners = (props: any) => {
  const { t } = useTranslation('translation');
  const windoeObj: any = window;
  const helperService = new HelperService();
  const [iframeLoading, setiframeLoading] = useState(false);
  const dispatch = useDispatch();
  const { classId }: any = useParams();
  const [users, setUsers] = useState([]);
  const [paginationData, setPaginationData] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editData, setEditData] = useState(null);
  const [showAdminModal, setShowAdminModal] = useState(false);
  const [filters, setFilters] = useState({
    page: 1,
    page_size: 20,
  });
  const [groups, setGroups] = useState<any>([]);
  const [searchedGroups, setSearchedGroups] = useState<any>([]);
  const [paginationState, setPaginationState] = useState(null);
  const [paginatedGroups, setPaginatedGroups] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [showExistingUserModal, setShowExistingUserModal] = useState(false);

  useEffect(() => {
    if (!props.data) {
      return;
    }
    setGroups([...props.data]);
    setSearchedGroups([...props.data]);
    paginate([...props.data])
  }, [props]);

  const func = async () => {
    const agentId: string = getAgentFromStorage();
    if (!agentId) {
      return;
    }
    // try {
    //   setiframeLoading(true);
    //   const response: any = await dispatch(
    //     getAdminsBasedOnRoles({
    //       ...filters,
    //       agentId,
    //     }, false)
    //   );
    //   setUsers(response.users);
    //   setPaginationData(response.pagination);
    //   setiframeLoading(false);
    // } catch (error: any) {
    //   setiframeLoading(true);
    // }
  };

  const iframeLoadedHandler = () => {
    setiframeLoading(false);
  };

  const addRoleUserToAdminsHandler = async (
    id: string,
    role: string,
    isSuperadmin: boolean
  ) => {
    const agentId: string = getAgentFromStorage();
    if (!agentId) {
      return;
    }
    try {
      setiframeLoading(true);
      const response: any = await dispatch(
        addRoleUserToAdmins(agentId, id, role, isSuperadmin)
      );
      func();
      setiframeLoading(false);
    } catch (error: any) {
      setiframeLoading(false);
    }
  };

  const deleteAdminsFromRolesHandler = async (
    eve: any,
    e: {
      id: string;
      role: string;
    }
  ) => {
    setAdminDeleteModalShow(false);
    try {
      setiframeLoading(true);
      const response: any = await dispatch(
        deleteLearnerToClasses(classId, e.id, "students")
      );
      props.getClassDetails && props.getClassDetails();
      setiframeLoading(false);
    } catch (error: any) {
      setiframeLoading(false);
    }
  };

  const addAdminToggleHandler = () => {
    setEditMode(false);
    setEditData(null);
    setShowAdminModal(!showAdminModal);
  };

  const confirmHandler = () => {
    props.getClassDetails && props.getClassDetails();
    setShowAdminModal(false);
  };

  const editAdminToggleHandler = (data: any, isEdit: boolean) => {
    setEditMode(true);
    setEditData(data);
    setShowAdminModal(!showAdminModal);
  };

  const [deletedRowId, setDeletedRowId] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const resendInviteHandler = async (e: any) => {
    try {
      setDeletedRowId(e);
      setModalShow(true);
    } catch (error: any) {}
  };

  const [deletedAdminId, setDeletedAdminId] = useState(null);
  const [adminDeleteModalShow, setAdminDeleteModalShow] = useState(false);
  const deleteAdminHandler = async (id: string, role: string) => {
    try {
      setDeletedAdminId({
        id,
        role,
      });
      setAdminDeleteModalShow(true);
    } catch (error: any) {}
  };

  const deleteConfirmed = async (
    e: React.ChangeEvent<HTMLInputElement>,
    selectedRow: any
  ) => {
    e.preventDefault();
    setModalShow(false);
    await dispatch(resendInvite(selectedRow._id));
    func();
  };

  const [uploadListModalShow, setUploadListModalShow] = useState(false);
  const uploadListModalShowHandler = async () => {
    setUploadListModalShow(!uploadListModalShow);
  };

  const searchHandler = (e: React.FormEvent<HTMLInputElement>) => {
    const term = e.currentTarget.value;
    const searchedQuestionData =
      e.currentTarget.value.trim().length > 0
        ? groups.filter(
            (v: any) =>
              (v.first_name &&
                v.first_name.toLowerCase().indexOf(term.toLowerCase()) > -1) ||
              (v.email &&
                v.email.toLowerCase().indexOf(term.toLowerCase()) > -1) ||
              (v.last_name &&
                v.last_name.toLowerCase().indexOf(term.toLowerCase()) > -1)
          )
        : groups;
    setSearchedGroups(searchedQuestionData);
    paginate(searchedQuestionData)
  };

  const sort = (name: string, order: any) => {
    let sortedList = [...searchedGroups];
    const updatedList = _.orderBy(sortedList, [name], [order]);
    setSearchedGroups(updatedList);
    paginate(updatedList)
  };

  useEffect(() => {
    searchedGroups && searchedGroups.length > 0 && paginate(searchedGroups)
    return () => {}
  }, [currentPage, perPage])

  const paginate = (comments: any[]) => {
    const paginationData = helperService.paginator(
      comments,
      currentPage,
      perPage
    )
    setPaginationState({
      ...paginationState,
      page: currentPage,
      page_size: perPage,
      total_items: comments.length,
      total_pages: paginationData.total_pages,
    })
    setPaginatedGroups([...paginationData.data])
  }

  /**
   *
   * @param page Pagination on change of page
   */
  const handlePageChange = async (page: number) => {
    await setCurrentPage(page)
  }

  const addExistingUsersToGroup = () => {
    setShowExistingUserModal(!showExistingUserModal);
  };

  return (
    <>
      {iframeLoading && <Loading />}

      <AddExistingUsersToClass
        show={showExistingUserModal}
        onHide={addExistingUsersToGroup}
        confirmHandler={() => {
          addExistingUsersToGroup();
          props.confirmHandler && props.confirmHandler();
        }}
        classes={[]}
      />

      <AddLearnerToAgent
        show={showAdminModal}
        onHide={addAdminToggleHandler}
        agentId={getAgentFromStorage()}
        confirmHandler={confirmHandler}
        editMode={editMode}
        editData={editData}
        classes={[]}
        groups={[]}
        classMode
      />

      <BulkUploadUsers
        show={uploadListModalShow}
        onHide={uploadListModalShowHandler}
      />

      <ConfirmationModal
        message={t("Are you sure you want to remove this user?")}
        size={"md"}
        show={adminDeleteModalShow}
        selectedRow={deletedAdminId}
        onHide={() => setAdminDeleteModalShow(false)}
        confirmHandler={deleteAdminsFromRolesHandler}
      />

      <ConfirmationModal
        message={t("Are you sure want to resend the invite?")}
        size={"md"}
        show={modalShow}
        selectedRow={deletedRowId}
        onHide={() => setModalShow(false)}
        confirmHandler={deleteConfirmed}
        buttonText={t("Resend Invite")}
      />

      <div className="all-knowledge-search d-flex flex-wrap align-items-center">
        <Dropdown className="sort-dropdown">
          <Dropdown.Toggle
            className="btn btn-outline-primary font-600 py-1 my-1 mr-3"
            variant="success"
            id="dropdown-basic"
          >
            <img src={sortIcon} alt="" className="mr-2" />
            {t("Sort")}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => {
                sort("first_name", "asc");
              }}
            >
              {t("Ascending")}
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                sort("first_name", "desc");
              }}
            >
              {t("Descending")}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <div className="web-links my-1">
          <input
            type="text"
            className="form-control h-auto"
            placeholder={t("Search for user")}
            onChange={searchHandler}
          />
        </div>

        <PermissionWrapper>
          <Dropdown className="sort-dropdown ml-auto">
            <Dropdown.Toggle
              className="btn btn-outline-primary font-600 border-0 py-1"
              variant="default"
              id="dropdown-basic"
            >
              {t("+ Add")}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={addAdminToggleHandler}>
                {t("Add User by Email")}
              </Dropdown.Item>
              <Dropdown.Item onClick={addExistingUsersToGroup}>
                {t("Add Existing User")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </PermissionWrapper>
      </div>
      {/* <div className="d-flex flex-wrap justify-content-between align-items-center mt-2 pl-lg-3">
        <div className="d-flex align-items-center mt-1">
          <div className="custom-checkbox mr-3">
            <input
              type="checkbox"
              className="mr-2"
              id="select-all"
              checked={
                allKnowledgeSuccess &&
                allKnowledgeSuccess.intents &&
                allKnowledgeSuccess.intents.length > 0 &&
                selectedNodes.length ===
                  allKnowledgeSuccess.intents.length
              }
              onChange={onSelectAllRows}
            />
            <label htmlFor="select-all" className="mb-0">
              Select all
            </label>
          </div>
        </div>
      </div> */}
      <Accordion
        // defaultActiveKey={
        //   allKnowledgeSuccess && allKnowledgeSuccess.intents.length > 0
        //     ? allKnowledgeSuccess.intents[0]._id
        //     : null
        // }
        className="accordion-viewby mt-3 update-class"
      >
        {paginatedGroups.map((user: any, index: number) => {
          return (
            <AdminLearnerTile
              user={user}
              editAdminToggleHandler={editAdminToggleHandler}
              deleteAdminHandler={deleteAdminHandler}
              classMode
              isLearner
            />
          );
        })}
      </Accordion>
      {searchedGroups && searchedGroups.length > 0 && paginationState && (
        <Pagination
          currentPage={currentPage}
          pagination={{
            ...paginationState,
          }}
          paginationPerPage={perPage}
          pageChange={(newPerPage: number, page: number) => {
            setPerPage(newPerPage);
            setCurrentPage(1);
          }}
          onChangePage={handlePageChange}
          hideSuggestedQuestion
        />
      )}
    </>
  );
};

export default ClassLearners;
