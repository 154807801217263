import React, { useState, useEffect } from 'react'
import { Modal, ModalFooter } from 'react-bootstrap'
import ModalBody from 'react-bootstrap/ModalBody'
import ModalHeader from 'react-bootstrap/ModalHeader'
import ModalTitle from 'react-bootstrap/ModalTitle'
import * as _ from 'lodash';

import { useTranslation } from "react-i18next";

export default function SuggestTagsModal(props: any) {
  const { t } = useTranslation('translation');
  const [tags, setTags] = useState([
    {
      value: '',
    },
  ])

  useEffect(() => {
    if (!props.show) {
      return
    }
    setTags([
      {
        value: '',
      },
    ])
  }, [ props.show ])

  const tagsChangeHandler = (e: any, index: number) => {
    const oldtags = [...tags]
    oldtags[index].value = e.target.value
    setTags([...oldtags])
  }

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      const oldtags = [...tags]
      oldtags.unshift({ value: '' })
      setTags([...oldtags])
    }
  }

  const assignTagsHandler = (e: any) => {
    e.preventDefault()
    let allTags: string[] = [];
    tags.map(t => {

      if (t.value && t.value.trim().length > 0) {
        allTags.push(t.value)
      }
    })
    allTags = _.uniq(allTags)
    if (allTags.length > 0) {
      props.confirmSuggestTags(allTags)
      props.onHide()
    }
  } 

  return (
    <Modal
      show={props.show}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      dialogClassName='suggest-tags'
    >
      <ModalHeader className='py-4 justify-content-center border-bottom'>
        <ModalTitle id='contained-modal-title-vcenter'>
          {/* <Button onClick={props.onHide}>
            <Image src={closeimg} />
          </Button> */}
          <h2 className='mb-0'>{t("Suggest Tags")}</h2>
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        {tags.map((tag: any, index: number) => {
          // value
          return (
            <div className='px-4'>
              <label className='tag-lbl'>
                {index+1}.{' '}
                <input
                  type='text'
                  value={tag.value}
                  onChange={(e) => tagsChangeHandler(e, index)}
                  onKeyDown={handleKeyDown}
                />
              </label>
              {index === 0 && (
                <div className='text-right mb-2'>
                  <small>
                    {t("Press enter to add another tag")}
                  </small>
                </div>
              )}
            </div>
          )
        })}
      </ModalBody>
      <ModalFooter className='border-0 justify-content-center px-3 mb-3 mx-4 flex-nowrap'>
        <button className='btn-primary btn mx-2' onClick={assignTagsHandler}>
          {t("Assign")}
        </button>
        <button className='btn-outline-primary btn mx-2' onClick={props.onHide}>
          {t("Cancel")}
        </button>
      </ModalFooter>
    </Modal>
  )
}
