import React, { useEffect, useState } from "react";
import { Container, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Select from "react-select";
import backimg from "../../../assets/images/arrow-left.svg";
import downarrowblue from "../../../assets/New-images/dropdown-arow-blue.svg";
import Loading from "../../../components/UI/Loading/Loading";
import PDFViewer from "../../../components/UI/PDFViewer/PDFViewer";
import PermissionWrapper from "../../../components/UI/PermissionWrapper/PermissionWrapper";
import QAnsAList from "../../Knowledge/GenerateQuestionsAnswers/partials/QAnsAList";
import { getStickerSets } from "../../../store/common/actions";
import {
  bulkAssignModules,
  generateQuestionsAndAnswersBasedOnDocument,
  getSectionsBasedOnDocumentId,
  resetAnswersScreen,
} from "../../../store/knowledge/actions";
import {
  getUserDocuments,
  iskeyWordsLoading,
  isKnowledgeCreated,
  isKnowledgeCreating,
} from "../../../store/knowledge/selector";

import { useTranslation } from "react-i18next";
import cx from "classnames";
import * as _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { convertDataToMarkdownFormat } from "../../../utils/appUtils";
import { updateQuiz, generateQuestionBank } from "../../../store/quizzes/actions";
import { HelperService } from "../../../services/helper.service";
import { GET_QUIZ_BYID, QUIZZES, QUIZZES_BETA } from "../../../config";
import { getQuizByQuizId } from "../../../store/dashboard/actions";

export const CreateQuizFromQuestionId = (props: any) => {
  let defaultActiveKey = "qnas";
  const isMgs = process.env.REACT_APP_CISCO === "mgs";
  if (isMgs) {
    defaultActiveKey = "sections";
  } else {
    defaultActiveKey = props.manageDocument ? "qnas" : "questions";
  }
  const { t } = useTranslation("translation");
  const helper = new HelperService();
  const history = useHistory();
  const params: any = useParams();
  const [preview, setpreview] = React.useState(false);
  const [answerData, setAnswerData] = React.useState(null);
  const [answerGenerationProcessing, SetAnswerGenerationProcessing] = useState(false);
  const [isSaved, setIsSaved] = useState(true);
  const [activeTab, setActiveTab] = useState(defaultActiveKey);
  const [loading, setLoading] = useState(false);
  const [generatedQuestions, setGeneratedQuestions] = useState([]);
  const [updatedQuestions, setUpdatedQuestions] = useState([]);
  const dispatch = useDispatch();
  const userDocuments = useSelector(getUserDocuments);
  const knowledgeCreating = useSelector(isKnowledgeCreating);
  const knowledgeCreated = useSelector(isKnowledgeCreated);
  const keyWordsLoading = useSelector(iskeyWordsLoading);
  const [sections, setSections] = useState([]);
  const [sectionsFetched, setSectionsFetched] = useState(false);
  const [selectedSection, setSelectedSection] = useState(null);
  const [hasSectionSaved, setHasSectionSaved] = useState([]);
  const [selectedQuestions, setSelectedQuestions] = useState([]);



  useEffect(() => {
    setpreview(false);
    setHasSectionSaved([]);
    if (userDocuments.length === 0) {
      dispatch(resetAnswersScreen());
    }
    // if (!useLocatio.search) {
    //   history.goBack();
    // }
    // const query = new URLSearchParams(useLocatio.search);
    // const params = query.get("question").split(",");
    // if (params.length === 0) {
    //   history.goBack();
    //   return;
    // }

    generateQuestionsAndAnswersBasedOnDocumentFn();
    if (!keyWordsLoading) {
      //   // ASK user before leave and then update
      //   dispatch(updateOrganizationFlow({
      //     ...organizationFlowObject,
      //     prevRoute: `/knowledge/generate-questions${history.location.search}`,
      //     currentRoute: `${history.location.pathname}${history.location.search}`,
      //     nextRoute: '/knowledge/all',
      //     setup_completed: false
      //   }));
    }

    dispatch(getStickerSets());
  }, []);

  const generateQuestionsAndAnswersBasedOnDocumentFn = async () => {
    setSectionsFetched(false);
    try {
      const sectionResponse: any = await dispatch(
        getSectionsBasedOnDocumentId([params.questionId])
      );
      setSections([...sectionResponse]);
      setSelectedSection(
        sectionResponse.length > 0 ? sectionResponse[0] : null
      );
      dispatch(
        generateQuestionsAndAnswersBasedOnDocument(
          userDocuments,
          [params.questionId],
          sectionResponse.length > 0 ? sectionResponse[0] : null
        )
      );
      setSectionsFetched(true);
    } catch (error) {
      setSectionsFetched(true);
      dispatch(
        generateQuestionsAndAnswersBasedOnDocument(
          userDocuments,
          [params.questionId],
          null
        )
      );
    }
  };

  const generateQuestionsBasedOnSelection = async (sectionResponse: any) => {
    dispatch(
      generateQuestionsAndAnswersBasedOnDocument(
        userDocuments,
        [params.questionId],
        sectionResponse
      )
    );
  };

  useEffect(() => {
    setIsSaved(false);
    const questionsFetched = userDocuments.filter(
      (document: any) => document._id === params.questionId
    );
    setGeneratedQuestions([..._.uniqBy(questionsFetched, "_id")]);
    setUpdatedQuestions([..._.uniqBy(questionsFetched, "_id")]);
  }, [userDocuments]);

  const saveKnowledge = async (e: any) => {
    e.preventDefault();

    if (selectedQuestions.length === 0) {
      return;
    }

    const questions: any = [];
    selectedQuestions.forEach((ques: any) => {
      questions.push({
        difficulty_level: 1,
        id: uuidv4(),
        ideal_answer: ques.answer,
        invalid: false,
        keywords: null,
        score: 1,
        scored_by: "similar_match",
        text: convertDataToMarkdownFormat(ques.question),
        topic: "",
        topic_id: "",
        type: "text",
      });
    });
   
    try {
      await setLoading(true);
      if ( props.location.state && props.location.state.createFromUploadedDocument ) {
        const response: any = await dispatch(
          generateQuestionBank({ questions: questions })
        );

        if ( response && props.location.state.queryString ) {
          const query = new URLSearchParams(props.location.state.queryString);
          const group: string = query.get("group");
          const nodes: string[] = response.quiz_questions.map((q: any) => q.id);
          group && (await dispatch(bulkAssignModules(group, nodes, "question")));
        }
        if (isMgs) {
          history.push(QUIZZES);
        } else {
          history.push(QUIZZES_BETA + "?tab=bank");
        }
      } else {
        const { quizDetail }: any = await dispatch(getQuizByQuizId(props.location.state.quizId))
        // quizDetail.questions.forEach((q: any) => {
        //   questions.push(q)
        // })
        const updatedQuestions = quizDetail.questions.concat(questions)
        
        await dispatch(
          updateQuiz(props.location.state.quizId, {
            questions: updatedQuestions,
          })
        );
        history.push(props.location.state.isEditQuiz ? GET_QUIZ_BYID.replace(":quizId", props.location.state.quizId) : QUIZZES_BETA)
      }
      await setLoading(false);
    } catch (error: any) {
      await setLoading(false);
    }
    return;
  };

  const updatedAnswersHandler = (data: any) => {
    const oldQuestions = [...generatedQuestions];
    const document = oldQuestions.find((d) => d._id === data.documentId);
    document.qas =
      data.qas && data.qas.length > 0
        ? _.orderBy([...data.qas], "sort_order", "asc")
        : [];
    document.keywordsLoading = data.keywordsLoading
      ? data.keywordsLoading
      : false;
    document.qasFetchError = data.qasFetchError ? data.qasFetchError : false;
    document.last_offset = data.last_offset ? data.last_offset : 0;
    document.retryExecution = data.retryExecution ? data.retryExecution : false;
    setUpdatedQuestions([...oldQuestions]);
    const keywordsLoading = oldQuestions.filter(
      (document: any) =>
        document.qas && document.qas.length === 0 && !document.qasFetchError
    );
    SetAnswerGenerationProcessing(keywordsLoading.length > 0 ? true : false);
  };

  const previewToggleHandler = async (answerData: any) => {
    if (answerData.hasOwnProperty("keyword")) {
      await setAnswerData({
        ...answerData,
      });
    }
    setpreview(!preview);
  };

  return (
    <>
      {loading && <Loading />}

      <section
        className={cx({
          "page-mid-wraper pb-0": true,
          "h-without-foter": activeTab === "qnas",
          "knowledge-detailby-id": props && props.hideHeaders,
        })}
      >
        <Container>
          <div className="row">
            <div className="col-md-12">
              <button
                className="btn p-0 btn-back"
                // onClick={() => history.push(`/knowledge/upload-documents`)}
                onClick={() => history.goBack()}
              >
                <Image src={backimg} /> {t("Return to previous page")}
              </button>
            </div>
          </div>
          <div className="d-flex flex-wrap justify-content-between">
            <h1 className="mb-2">{t("Select Quiz Questions")}</h1>
          </div>
          <div className="mb-32 font-16">
            {t("Select the Questions and Answers to use as quiz questions.")}
          </div>

          <div className="pdf-section-wrapper">
            <div>
              {generatedQuestions.map((document: any, index: number) => {
                return (
                  <div
                    className="document-section-wrapper row"
                    key={document._id}
                  >
                    <div className="col-md-5">
                      <Select
                        isDisabled={
                          knowledgeCreating || answerGenerationProcessing
                        }
                        className="select-target mb-2 select-blue shadow-none radius-8"
                        classNamePrefix="select-target-prefix"
                        components={{
                          DropdownIndicator: () => (
                            <img
                              src={downarrowblue}
                              alt={t("dropdown arrow")}
                            />
                          ),
                          IndicatorSeparator: () => null,
                          ClearIndicator: () => null,
                        }}
                        placeholder={"Select Section"}
                        options={[...sections]}
                        value={selectedSection || null}
                        onChange={(e: any) => {
                          setSelectedSection({ ...e });
                          generateQuestionsBasedOnSelection({ ...e });
                        }}
                      />

                      <PDFViewer documentId={document._id} qnaScreen />
                    </div>

                    <div className="col-md-7">
                      <div key={document._id} title={document.name}>
                        <QAnsAList
                          key={document._id}
                          index={index}
                          {...document}
                          setAnswerData={previewToggleHandler}
                          documentLength={userDocuments.length}
                          updatedAnswersHandler={updatedAnswersHandler}
                          section={selectedSection}
                          setAnswerGenerationProcessing={
                            SetAnswerGenerationProcessing
                          }
                          isQuizView
                          setSelectedQuestions={setSelectedQuestions}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <footer>
            <Container>
              <div className="d-flex align-items-center justify-content-end px-2">
                <PermissionWrapper>
                  <button
                    className="btn btn-primary third-step"
                    onClick={saveKnowledge}
                    disabled={
                      knowledgeCreating ||
                      answerGenerationProcessing ||
                      selectedQuestions.length === 0
                    }
                  >
                    {t("Review Question & Answer")}
                  </button>
                </PermissionWrapper>
              </div>
            </Container>
          </footer>
        </Container>
      </section>
    </>
  );
};

export default CreateQuizFromQuestionId;
