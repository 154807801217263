import ApiUtil from "../utils/apiUtil";
import {
  getAgentFromStorage,
  serialize,
  serializeFilter,
} from "../utils/appUtils";
import DataApiUtil from "../utils/dataApiUtils";

type Type = any;

export default class DashboardService {
  async getDashboardUserReports(): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `report/user-reports/summary?agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async getDashboardUserSessionReports(): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `report/session-reports/summary?agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async getDashboardActiveUserSessionReports(filtersData: any): Promise<Type> {
    const copiedFilter = { ...filtersData };
    delete copiedFilter.filter_type;
    delete copiedFilter.type;
    // report/report-data/user-summary
    const res = await DataApiUtil.SendAsync<any>({
      url: `/report/report-data/active-users-sessions?${serialize(
        copiedFilter
      )}&agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async getDashboardUniqueActiveUsersSummary(filtersData: any): Promise<Type> {
    const copiedFilter = { ...filtersData };
    delete copiedFilter.filter_type;
    delete copiedFilter.type;
    const res = await DataApiUtil.SendAsync<any>({
      url: `/report/report-data/user-summary?${serialize(
        copiedFilter
      )}&agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async getDashboardWeeklyActiveUserSessionReports(
    filtersData: any
  ): Promise<Type> {
    const copiedFilter = { ...filtersData };
    delete copiedFilter.filter_type;
    delete copiedFilter.type;
    // report/report-data/user-summary
    const res = await DataApiUtil.SendAsync<any>({
      url: `/report/report-data/weekly-active-users-sessions?${serialize(
        copiedFilter
      )}&agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async getDashboardAverageMonthlyActiveUsersSessionReports(
    filtersData: any
  ): Promise<Type> {
    const res = await DataApiUtil.SendAsync<any>({
      url: `/report/report-data/monthly-active-users-sessions?${serialize(
        filtersData
      )}&agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async getDashboardUserSessionReportsWeekly(filtersData: any): Promise<Type> {
    const res = Promise.all([
      await DataApiUtil.SendAsync<any>({
        url: `/report/report-data/weekly-sessions?filter_from_date=${
          filtersData.filter_from_date
        }&filter_to_date=${
          filtersData.filter_to_date
        }&agent_id=${getAgentFromStorage()}`,
        method: "GET",
      }),
      await DataApiUtil.SendAsync<any>({
        url: `/report/report-data/weekly-users?filter_from_date=${
          filtersData.filter_from_date
        }&filter_to_date=${
          filtersData.filter_to_date
        }&agent_id=${getAgentFromStorage()}`,
        method: "GET",
      }),
    ]);
    return res;
  }

  async getDashboardConversationTurnsReports(): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `report/reports/conversation-turns?agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async getDashboardQuizSummary(filter: any): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `report/reports/quiz-summary?${serialize(
        filter
      )}&agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async getDashboardQuizList(filter: any): Promise<Type> {
    const res = await DataApiUtil.SendAsync<any>({
      url: `/report/report-data/quiz-list?${serialize(
        filter
      )}&agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async getDashboardTopQuestions(limit: any, filtersData: any): Promise<Type> {
    const copiedFilter = { ...filtersData };
    delete copiedFilter.filter_channel;
    delete copiedFilter.channel;
    const res = await DataApiUtil.SendAsync<any>({
      url: `report/report-data/top-questions?${serialize(
        copiedFilter
      )}&limit=${limit}&agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async getLatestComments(filtersData: any): Promise<Type> {
    const copied = { ...filtersData };
    delete copied.connection_ids;
    const res = await DataApiUtil.SendAsync<any>({
      url: `report/report-data/latest-comments?${serialize(
        copied
      )}&agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async getQuizSubmissions(filtersData: any): Promise<Type> {
    const copied = { ...filtersData };
    delete copied.frequency;
    delete copied.filter_frequency;
    const res = await DataApiUtil.SendAsync<any>({
      url: `report/report-data/quiz-submissions?${serialize(
        copied
      )}&agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async getWeeklyQuizSubmissions(filtersData: any): Promise<Type> {
    const copied = { ...filtersData };
    delete copied.frequency;
    delete copied.filter_frequency;
    delete copied.connection_ids;
    const res = await DataApiUtil.SendAsync<any>({
      url: `report/report-data/weekly-quiz-submissions?${serialize(
        copied
      )}&agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async getRatingsSummary(filtersData: any): Promise<Type> {
    const copied = { ...filtersData };
    delete copied.connection_ids;
    const res = await DataApiUtil.SendAsync<any>({
      url: `report/report-data/rating-summary?${serialize(
        copied
      )}&agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async getConversationTurnSummary(filtersData: any): Promise<Type> {
    const res = await DataApiUtil.SendAsync<any>({
      url: `report/report-data/conversation-turn-summary?${serialize(
        filtersData
      )}&agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async getMatchedAndUnmatchedQuestions(filtersData: any): Promise<Type> {
    const res = await DataApiUtil.SendAsync<any>({
      url: `report/report-data/question-summary?${serialize(
        filtersData
      )}&agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async getConversationTurns(filtersData: any): Promise<Type> {
    const res = await DataApiUtil.SendAsync<any>({
      url: `report/report-data/conversation-turns?${serialize(
        filtersData
      )}&agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async getAllUsers(): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `organisation/users?page_size=1000000&agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async getAllGroupUsers(filtersData: any): Promise<Type> {
    const res = await DataApiUtil.SendAsync<any>({
      url: `report/report-data/group-sessions-list?${serializeFilter(
        filtersData
      )}&agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async getAllGroupUsersModal(
    filtersData: any,
    _id: any,
    filter_is_attempted: any
  ): Promise<Type> {
    const res = await DataApiUtil.SendAsync<any>({
      url: `report/report-data/group-session-detail-users-list/${_id}?${serializeFilter(
        filtersData
      )}&agent_id=${getAgentFromStorage()}&filter_is_attempted=${filter_is_attempted}`,
      method: "GET",
    });
    return res;
  }

  async getTopUsers(
    filtersData: any,
    page: number = null,
    page_size: number = null
  ): Promise<Type> {
    const pagination = page ? `page=${page}&page_size=${page_size}&` : "";
    const sort =
      filtersData.sort_field && filtersData.sort_order
        ? `&sort=${filtersData.sort_field}::${filtersData.sort_order}`
        : "";
    const copied = { ...filtersData };
    delete copied.filter_channel;
    // delete copied.channel;
    delete copied.page;
    delete copied.page_size;
    delete copied.sort_order;
    delete copied.sort_field;
    const res = await DataApiUtil.SendAsync<any>({
      url: `report/report-data/report-users?${pagination}${serialize(
        copied
      )}&agent_id=${getAgentFromStorage()}${sort}`,
      method: "GET",
    });
    return res;
  }

  async getReportsAverageUsageDuration(filtersData: any): Promise<Type> {
    const res = await DataApiUtil.SendAsync<any>({
      url: `report/report-data/usage-duration?${serialize(
        filtersData
      )}&agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async getDailyReturningUsers(filtersData: any): Promise<Type> {
    const res = await DataApiUtil.SendAsync<any>({
      url: `report/report-data/returning-users?${serialize(
        filtersData
      )}&agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async getMonthlyReturningUsers(filtersData: any): Promise<Type> {
    const res = await DataApiUtil.SendAsync<any>({
      url: `report/report-data/monthly-returning-users?${serialize(
        filtersData
      )}&agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async getClasses(): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `course/classes?agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async getTopUsersReports(filter: any): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `report/user-reports?${serialize(
        filter
      )}&limit=10&agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async getQuizDetailById(quizId: any): Promise<Type> {
    const hasQueryParamPresent = quizId.split("?");
    const res = await ApiUtil.SendAsync<any>({
      url: `quiz/quizzes/${
        hasQueryParamPresent.length > 1 ? hasQueryParamPresent[0] : quizId
      }?agent_id=${getAgentFromStorage()}${
        hasQueryParamPresent.length > 1 ? "&" + hasQueryParamPresent[1] : ""
      }`,
      method: "GET",
    });
    return res;
  }

  async getQuizDetailByQuizId(quizId: any, filter: any): Promise<Type> {
    const otherFilter = {
      connection_ids: filter.connection_ids,
      filter_from_date: filter.filter_from_date,
      filter_to_date: filter.filter_to_date,
      channel: filter.channel,
      is_attempted: filter.filter_is_attempted,
    };
    const pagination = filter.page
      ? `&page=${filter.page}&page_size=${filter.page_size}`
      : "";
    const sort =
      filter.sort_field && filter.sort_order
        ? `&sort=${filter.sort_field}::${filter.sort_order}`
        : "";
    const res = await DataApiUtil.SendAsync<any>({
      url: `report/report-data/quiz-detail-top-user/${quizId}?${serialize(
        otherFilter
      )}${pagination}${sort}&agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async getQuizDetailByUser(
    quizId: any,
    userId: any,
    userType: string
  ): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `quiz/quizzes/${quizId}/submissions?user_id=${userId}&user_type=${userType}&agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async getStudentsYetToAttempt(quizId: any, filter: any): Promise<Type> {
    const otherFilter = {
      connection_ids: filter.connection_ids,
      from_date: filter.filter_from_date,
      to_date: filter.filter_to_date,
      is_attempted: filter.filter_is_attempted,
    };
    // channel: filter.channel,
    const pagination = filter.page
      ? `&page=${filter.page}&page_size=${filter.page_size}`
      : "";
    const res = await DataApiUtil.SendAsync<any>({
      url: `report/report-data/quiz-detail-user-submission/${quizId}?${serialize(
        otherFilter
      )}${pagination}&agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async getQuizTotalNUmberOfSubmissionsCount(
    quizId: any,
    filter: any
  ): Promise<Type> {
    const otherFilter = {
      filter_connection_ids: filter.connection_ids,
      filter_from_date: filter.filter_from_date,
      filter_to_date: filter.filter_to_date,
      filter_channel: filter.channel,
    };
    const res = await DataApiUtil.SendAsync<any>({
      url: `report/report-data/quiz-detail-submission-summary/${quizId}?${serialize(
        otherFilter
      )}&agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async getQuizDetailFirstSubmissionSummary(
    quizId: any,
    filter: any
  ): Promise<Type> {
    const otherFilter = {
      filter_connection_ids: filter.connection_ids,
      filter_from_date: filter.filter_from_date,
      filter_to_date: filter.filter_to_date,
      filter_channel: filter.channel,
    };
    const res = await DataApiUtil.SendAsync<any>({
      url: `report/report-data/quiz-detail-first-submission-summary/${quizId}?${serialize(
        otherFilter
      )}&agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async exportReportUsingDateAndType(
    report_name: string,
    file_format: string,
    filter?: any
  ): Promise<Type> {
    const copiedFilter = { ...filter };
    delete copiedFilter.sort_field;
    delete copiedFilter.sort_order;

    const sort =
      filter.sort_field && filter.sort_order
        ? `&sort=${filter.sort_field}::${filter.sort_order}`
        : "";

    const res = await DataApiUtil.SendAsync<any>({
      url: `report/report-data/${report_name}/download/${file_format}?${serialize(
        copiedFilter
      )}${sort}&agent_id=${getAgentFromStorage()}`,
      method: "GET",
      responseType: "blob",
    });
    return res;
  }

  async getLearnerSubmissions(user_id: string): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `learning/submissions?user_id=${user_id}&agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async getLearnerSessionsReport(user_id: string): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `learning/student-sessions-report?user_id=${user_id}&agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async getStudentSessionSummaryReport(session_id: string): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `learning/student-session-summary-report?session_id=${session_id}&agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async getMetaBaseData(type: string): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `report/embedded-dashboards/${type}?&agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async getUserFeedbacks(filters: any): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `chatlog/feedbacks?page=${filters.page}&page_size=${
        filters.page_size
      }&agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async getFeedbacks(filters: any): Promise<Type> {
    const otherFilters = {
      filter_from_date: filters.from_date,
      filter_to_date: filters.to_date,
    };
    const paginate = {
      page: filters.page,
      page_size: filters.page_size,
    };
    const res = await DataApiUtil.SendAsync<any>({
      url: `report/report-data/feedbacks?${serializeFilter(
        otherFilters
      )}&${serializeFilter(paginate)}&agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async getUserConversations(filters: any): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `report/conversation-reports?${serializeFilter(
        filters
      )}&agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async downloadUserConversations(filters: any): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `report/conversation-reports/download?${serializeFilter(
        filters
      )}&agent_id=${getAgentFromStorage()}`,
      method: "POST",
      responseType: "blob",
    });
    return res;
  }

  async getReportsUnmatchedQuestions(filters: any): Promise<Type> {
    const otherFilter = {
      connection_ids: filters.connection_ids,
      status: filters.status,
      from_date: filters.from_date,
      to_date: filters.to_date,
    };
    const pagination = filters.page
      ? `&page=${filters.page}&page_size=${filters.page_size}`
      : "";
    const sortFilters = filters.sort_field
      ? `&sort=${filters.sort_field}::${filters.sort_order}`
      : "";
    const res = await DataApiUtil.SendAsync<any>({
      url: `report/report-data/unanswered-questions?${serialize(
        otherFilter
      )}${pagination}${sortFilters}&agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async getReportsAnswersFromOutsideSource(filters: any): Promise<Type> {
    const otherFilter = {
      from_date: filters.from_date,
      to_date: filters.to_date,
      filter_connection_ids: filters.filter_connection_ids,
    };
    const question_text = filters.filter_question_text
      ? `&filter_question_text=${filters.filter_question_text}`
      : "";
    const pagination = filters.page
      ? `&page_size=${filters.page_size}&page=${filters.page}`
      : "";
    const sort =
      filters.sort_field && filters.sort_order
        ? `&sort=${filters.sort_field}::${filters.sort_order}`
        : "";
    const filter_category = filters.filter_category
      ? `&filter_category=${filters.filter_category}`
      : "";
    const filter_status = filters.filter_status
      ? `&filter_status=${filters.filter_status}`
      : "";
    const res = await DataApiUtil.SendAsync<any>({
      url: `/report/report-data/unanswered-questions?agent_id=${getAgentFromStorage()}${pagination}&${serialize(
        otherFilter
      )}${question_text}${sort}${filter_category}${filter_status}`,
      method: "GET",
    });
    return res;
  }

  async getSubmissionReviewCount(quizId: string = null, filter: any): Promise<Type> {
    let query: any = {
      agent_id: getAgentFromStorage()
    }

    if (quizId) {
      query["quiz_id"] = quizId;
    }

    if (filter?.filter_from_date) {
      query["from_date"] = filter.filter_from_date;
    }
    if (filter?.filter_to_date) {
      query["to_date"] = filter.filter_to_date;
    }

    const res = await ApiUtil.SendAsync<any>({
      url: `learning/submission-review-count?${serializeFilter(query)}`,
      method: "GET",
    });
    return res;
  }

  async sendReminders(quiz_id: string, data: string[]): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `learning/quizzes/${quiz_id}/attempt-reminders?agent_id=${getAgentFromStorage()}`,
      method: "POST",
      data: {
        user_ids: data,
      },
    });
    return res;
  }

  async getQuestionCorrectnessReport(
    quizId: string,
    filtersData: any
  ): Promise<Type> {
    const copiedFilter = { ...filtersData };
    delete copiedFilter.filter_type;
    delete copiedFilter.type;
    const res = await ApiUtil.SendAsync<any>({
      url: `learning/quizzes/${quizId}/question-correctness-report?${serializeFilter(
        copiedFilter
      )}&agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async getQuestionAnalyticsReport(
    quizId: string,
    filtersData: any
  ): Promise<Type> {
    const copiedFilter = { ...filtersData };
    delete copiedFilter.filter_from_date;
    delete copiedFilter.filter_to_date;
    delete copiedFilter.filter_type;
    delete copiedFilter.type;
    const res = await ApiUtil.SendAsync<any>({
      url: `learning/quizzes/${quizId}/question-analytics-report?${serializeFilter(
        copiedFilter
      )}&agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async getQuestionAnalyticsSubmissionReport(
    quizId: string,
    filtersData: any
  ): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `learning/quizzes/${quizId}/submissions?${serializeFilter(
        filtersData
      )}&agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async getReportQuizSubmissionsList(filtersData: any): Promise<Type> {
    const res = await DataApiUtil.SendAsync<any>({
      url: `report/report-data/quiz-submissions-list?${serializeFilter(
        filtersData
      )}&agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  serializeFilter = (filter: any) => {
    return Object.keys(filter)
      .map((key) => {
        if (Array.isArray(filter[key])) {
          return filter[key]
            .map(
              (value: any) =>
                `${encodeURIComponent(key)}[]=${encodeURIComponent(value)}`
            )
            .join("&");
        }
        return `${encodeURIComponent(key)}=${encodeURIComponent(filter[key])}`;
      })
      .join("&");
  };

  async getDiscussion(filter: any): Promise<Type> {
    const filterString = serializeFilter(filter);
    const res = await ApiUtil.SendAsync<any>({
      url: `discussion/threads?thread_type=discussion&agent_id=${getAgentFromStorage()}&${filterString}`,
      method: "GET",
    });
    return res;
  }

  async getLearnerQuestions(filter: any): Promise<Type> {
    const filterString = serializeFilter(filter);
    const res = await ApiUtil.SendAsync<any>({
      url: `discussion/threads?thread_type=unanswered_question&agent_id=${getAgentFromStorage()}&${filterString}`,
      method: "GET",
    });
    return res;
  }

  async getDiscussionDetails(id: string, filter: string): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `discussion/threads/${id}/posts?post_type=answer&agent_id=${getAgentFromStorage()}&${filter}`,
      method: "GET",
    });
    return res;
  }

  async setApproval(
    thread_id: string,
    post_id: string,
    data: any
  ): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `discussion/threads/${thread_id}/posts/${post_id}/approvals?agent_id=${getAgentFromStorage()}`,
      method: "POST",
      data: data,
    });
    return res;
  }

  async saveDiscussion(id: string, data: any): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `discussion/threads/${id}?&agent_id=${getAgentFromStorage()}`,
      method: "PUT",
      data: data,
    });
    return res;
  }

  async deleteDiscussion(id: string): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `discussion/threads/${id}?&agent_id=${getAgentFromStorage()}`,
      method: "DELETE",
    });
    return res;
  }

  async createDiscussion(data: any): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `discussion/threads?&agent_id=${getAgentFromStorage()}`,
      method: "POST",
      data: data,
    });
    return res;
  }

  async ExportDiscussions(data: any): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `discussion/threads/download?&agent_id=${getAgentFromStorage()}`,
      method: "POST",
      data: data,
      responseType: "blob"
    });
    return res;
  }

  async getBotResponses(filters: any): Promise<Type> {
    const otherFilter = {
      from_date: filters.from_date,
      to_date: filters.to_date,
      filter_class_id: filters.filter_connection_ids,
    };
    // const question_text = filters.filter_question_text
    //   ? `&filter_question_text=${filters.filter_question_text}`
    //   : "";
    const pagination = filters.page
      ? `&page_size=${filters.page_size}&page=${filters.page}`
      : "";
    const sort =
      filters.sort_field && filters.sort_order
        ? `&sort=${filters.sort_field}::${filters.sort_order}`
        : "";
    const filter_thread_type = filters.filter_thread_type
      ? `&filter_thread_type=${filters.filter_thread_type}`
      : "";
    const filter_has_intent_id = filters.filter_has_intent_id
      ? `&filter_has_intent_id=${filters.filter_has_intent_id}`
      : "";
    const res = await DataApiUtil.SendAsync<any>({
      url: `/report/report-data/bot-responses?agent_id=${getAgentFromStorage()}${pagination}&${serialize(
        otherFilter
      )}${sort}${filter_thread_type}${filter_has_intent_id}`,
      method: "GET",
    });
    return res;
  }
}
