import classNames from "classnames";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import { questionTypesGuide, scoringGuide, indexData } from "./Data/guideData";
import chevLeft from "../../../assets/New-images/chevron-left-blk.svg";
import "./style.scss";
import { useTranslation } from "react-i18next";



const QuizGuide = () => {
  const { t } = useTranslation();
  let history = useHistory();
  // use the same keys from dashboard sidebar (identifier) to navigate to the right content
  let glossaryMap: any = {
    questionTypes: {
      label: t("Question Types"),
      data: questionTypesGuide,
    },
    scoring: {
      label: t("Scoring Methods (score by)"),
      data: scoringGuide,
    },
  };
  const formDefinitionContent = (data: any) => {
    if (data.text) {
      return <p className="glossary-term-definition">{data.text}</p>;
    } else if (data.question) {
      return <h4>{data.question}</h4>;
    } else if (data.image) {
      return (
        <div className="glossary-img-container">
          <img src={data.image} alt="glossary" className="glossary-img" />
        </div>
      );
    } else if (data.link) {
      return (
        <a className="glossary-term-definition" href={data.link}>
          {data.linkText}
        </a>
      );
    } else if (data.html) {
      return (
        <>
          {data.html}
        </>
      );
    }
  };

  return (
    <section
      className={classNames({
        "page-mid-wraper": true,
        "h-without-foter": true,
      })}
    >
      <Container>
        <Row>
          <Col md={12}>
            <h1 className="mb-24 d-flex align-items-center">
              <button
                className="btn py-0"
                onClick={() => {
                  history.goBack()
                }}
              >
                <img src={chevLeft} alt="" />
              </button>
              {t("Guide - Quiz")}
            </h1>
          </Col>
          <Col md={12}>
            <div className="quiz-guide-page-container">
              <div className="page-content-container">
                <div className="glossary-header">
                  <div className="glossary-heading"></div>
                  <div className="search-bar-container">
                    {/* <TextInput placeholder="Search" /> */}
                  </div>
                </div>
                <div className="glossary-body">
                  <section className="glossary-index">
                    <h2 className="glossary-subheading">{t('Index')}</h2>
                    {indexData.map((category) => (
                      <div className="glossary-index-category-container">
                        <h3 className="glossary-index-catergory-heading">
                          <HashLink smooth to={`/quiz-guide#${category.id}`} className="hash-link">
                            {category.title}
                          </HashLink>
                        </h3>
                        {category.items &&
                          category.items.map((item) => (
                            <div>
                              <HashLink
                                smooth
                                to={`/quiz-guide#${item.anchor}`}
                              >
                                {item.label}
                              </HashLink>
                            </div>
                          ))}
                      </div>
                    ))}
                  </section>
                  {Object.keys(glossaryMap).map((identifier) => (
                    <section className="glossary-section">
                      <h2 className="glossary-subheading" id={identifier}>
                        {glossaryMap[identifier].label}
                      </h2>
                      {glossaryMap[identifier].data.map((item: any) => (
                        <div className="glossary-definition-pair">
                          {item.term && (
                            <h3 className="glossary-term" id={item.id}>
                              {item.term}
                            </h3>
                          )}
                          {item.definition.map((definitionItem: any) =>
                            formDefinitionContent(definitionItem)
                          )}
                        </div>
                      ))}
                    </section>
                  ))}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default QuizGuide;
