import { OverlayTrigger, Tooltip, Accordion, Image } from "react-bootstrap";

import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import inteApis from "../../services/IntegrationService";
import "./styles.scss";
import Backstep from "../../assets/images/Backstep.svg";
import DashboardLink from "../../assets/images/Link-dashboard.svg";
import copyIcon from "../../assets/images/copy-icon.svg";
import { capitalizeFirstLetter } from "../../utils/appUtils";
import CanvasLink from "../../assets/images/canvas-admin.svg";
import { useHistory } from "react-router";
import { CopyContant } from "../../utils/copyContant";
import { useDispatch } from "react-redux";
import { getAllAgents } from "../../store/agents/actions";
// Blackboard
import BlackboardApiModal from "./Blackboard/BlackboardApiModal";
import BlackboardLtiModal from "./Blackboard/BlackboardLtiModal";
// Canvas
import CanvasLtiModal from "./Canvas/CanvasLtiModal";
import CanvasApiModal from "./Canvas/CanvasApiModal";
// // D2l
import Desire2learnLtiModal from "./Desire2learn/Desire2learnLtiModal";
import Desire2learnApiModal from "./Desire2learn/Desire2learnApiModal";
// // Canvas
import MoodleLtiModal from "./Moodle/MoodleLtiModal";
// import MoodleInteractionModal from "./Moodle/MoodleInteractionModal";

export type FormData = {
  LMS_URL: string;
  Client_ID: string;
  Client_Secret?: string;
  Deployment_ID?: string;
  Agent_ID?: string;
};

const IntegrationType: React.FC = (props: any) => {
  const { t } = useTranslation("translation");
  const history = useHistory();
  const dispatch = useDispatch();

  const [showsLtiModal, setShowsLtiModal] = useState(false);
  const [showsApiModal, setShowsApiModal] = useState(false);
  const [integrateSpecificAgent, setTntegrateSpecificAgent] = useState(false);
  const [integrationType, setIntegrationType] = useState(null);
  const [tooltipMessage, setTooltipMessage] = useState(t("Copy"));
  const [allAgents, setAllAgents] = useState([]);
  const [integrations, setIntegrations] = useState({
    adminEducator: null,
    walterEducator: null,
  });
  const integrationProvider: string = props?.match?.params?.type;
  const allIntegrationSettings: {
    [key: string]: { label: string; adminlti: any; api: any };
  } = {
    canvas: {
      label: "Canvas",
      adminlti: CanvasLtiModal,
      api: CanvasApiModal,
    },
    blackboard: {
      label: "Blackboard",
      adminlti: BlackboardLtiModal,
      api: BlackboardApiModal,
    },
    moodle: {
      label: "Moodle",
      adminlti: MoodleLtiModal,
      api: null,
    },
    desire2learn: {
      label: "D2L Brightspace",
      adminlti: Desire2learnLtiModal,
      api: Desire2learnApiModal,
    },
  };
  const integrationSettings =
    integrationProvider in allIntegrationSettings
      ? allIntegrationSettings[integrationProvider]
      : {
          label: "",
          adminlti: null,
          api: null,
        };

  const [formData, setFormData] = useState<FormData>({
    LMS_URL: "",
    Client_ID: "",
    Deployment_ID: "",
    Client_Secret: "",
    Agent_ID: "",
  });

  useEffect(() => {
    if (integrationType) {
      // inteApis.deleteIntegration(integrations?.[integrationType as keyof typeof integrations]?._id)
      setFormData({
        LMS_URL:
          integrations?.[integrationType as keyof typeof integrations]?.params
            ?.base_url,
        Client_ID:
          integrations?.[integrationType as keyof typeof integrations]?.params
            ?.client_id,
        Deployment_ID:
          integrations?.[integrationType as keyof typeof integrations]?.params
            ?.deployment_id,
        Client_Secret:
          integrations?.[integrationType as keyof typeof integrations]?.params
            ?.client_secret,
        Agent_ID:
          integrations?.[integrationType as keyof typeof integrations]?.params
            ?.target_agent_id,
      });
    }
  }, [integrationType]);

  useEffect(() => {
    const fetchAllIntegrations = async () => {
      let existingIntegrations = await fetchIntegrations();
      if (
        integrationSettings.adminlti &&
        !existingIntegrations.find(
          (integration: any) => integration.integration_type === "adminlti",
        )
      ) {
        createEmptyLtiIntegration("adminlti");
      }
    };

    fetchAllIntegrations();
    fetchAllAgents();
  }, []);

  const fetchAllAgents = async () => {
    try {
      const fetchedAgents: any = await dispatch(getAllAgents());
      const formattedAgents =
        fetchedAgents
          ?.filter((agent: { status: string }) => agent.status !== "inactive")
          ?.map((agent: { _id: string; name: string }) => ({
            value: agent._id,
            label: agent.name,
          })) || [];
      setAllAgents(formattedAgents);
    } catch (e: any) {
      console.error("Error fetching agents:", e);
      setAllAgents([]);
    }
  };

  const fetchIntegrations = async (integrationType: string = null) => {
    const { status, data } = await inteApis.getIntegrations(
      integrationProvider,
      integrationType,
    );
    let integrations: Array<object> = [];
    if (status === 200) {
      // Check if data.data.integrations is available then loop through integrations
      setIntegrations((prev: any) => {
        const newState = { ...prev };
        if (data?.data?.integrations) {
          integrations = data.data.integrations;
          integrations.forEach((integration: any) => {
            if (integration.integration_type === "api") {
              newState.walterEducator = integration;
            } else if (integration.integration_type === "adminlti") {
              newState.adminEducator = integration;
            }
          });
        }

        return newState;
      });
      return integrations;
    }
  };

  const handleIntegration = async () => {
    if (integrationType === "adminEducator") {
      if (integrations.adminEducator) {
        handleUpdateIntegration();
      } else {
        handleCreateIntegration("adminlti");
      }
    } else if (integrationType === "walterEducator") {
      if (integrations.walterEducator) {
        handleUpdateIntegration();
      } else {
        handleCreateIntegration("api");
      }
    }
  };

  const createEmptyLtiIntegration = async (integrationType: string) => {
    const { status, data } = await inteApis.createIntegration({
      provider: integrationProvider,
      integration_type: integrationType,
      params: {},
    });
    if (status === 200) {
      fetchIntegrations(integrationType);
    }
  };

  const handleCreateIntegration = async (integrationType: string) => {
    const bodyData: {
      base_url: string;
      client_id: string;
      target_agent_id?: string;
      client_secret?: string;
      deployment_id?: string;
    } = {
      base_url: formData.LMS_URL,
      client_id: formData.Client_ID,
    };

    if (integrationType === "adminlti") {
      if (integrateSpecificAgent) {
        bodyData.target_agent_id = formData.Agent_ID;
      } else {
        bodyData.target_agent_id = null;
      }
    }

    if (formData.Client_Secret) {
      bodyData.client_secret = formData.Client_Secret;
    } else {
      bodyData.deployment_id = formData.Deployment_ID;
    }
    const { status, data } = await inteApis.createIntegration({
      provider: integrationProvider,
      integration_type: integrationType,
      params: bodyData,
    });
    if (status === 200) {
      fetchIntegrations(integrationType);
      setFormData({
        LMS_URL: "",
        Client_ID: "",
        Deployment_ID: "",
        Client_Secret: "",
        Agent_ID: "",
      });
    }
  };

  const handleUpdateIntegration = async () => {
    const bodyData: {
      base_url: string;
      client_id: string;
      target_agent_id?: string;
      client_secret?: string;
      deployment_id?: string;
    } = {
      base_url: formData.LMS_URL,
      client_id: formData.Client_ID,
    };

    if (integrationType === "adminEducator") {
      if (integrateSpecificAgent) {
        bodyData.target_agent_id = formData.Agent_ID;
      } else {
        bodyData.target_agent_id = null;
      }
    }

    if (formData.Client_Secret) {
      bodyData.client_secret = formData.Client_Secret;
    } else {
      bodyData.deployment_id = formData.Deployment_ID;
    }

    const { status, data } = await inteApis.updateIntegration(
      integrations?.[integrationType as keyof typeof integrations]?._id,
      { params: bodyData },
    );
    if (status === 200) {
      await fetchIntegrations();
    }
  };

  const handleInputChange = async (event: any, name: string) => {
    setFormData({
      ...formData,
      [name]: event.target.value,
    });
  };

  const handleChangeTooltip = async (text: string) => {
    await CopyContant(text);
    setTooltipMessage(t("Copied"));
    setTimeout(() => {
      setTooltipMessage(t("Copy"));
    }, 500);
  };

  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      {t(tooltipMessage)}
    </Tooltip>
  );

  const renderCopy = (text: string): JSX.Element => (
    <>
      <em>{text}</em>&nbsp;
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip}
      >
        <Image
          className="cursor-pointer"
          onClick={() => handleChangeTooltip(text)}
          src={copyIcon}
        />
      </OverlayTrigger>
    </>
  );

  return (
    <div className="channels tw-px-6">
      <div className="integrationBox-heading">
        <h1>
          <Image
            className="cursor-pointer"
            onClick={() => {
              history.goBack();
            }}
            src={Backstep}
          />
          {integrationSettings.label}
        </h1>
      </div>

      <div className="pl-3 canvas-configure">
        {t(
          `Configure the sections below to finish setting up Noodle Factory with ${integrationSettings.label}.`,
        )}
      </div>

      <div className="color p-3">
        {/*LTI Integration*/}
        {integrationSettings && integrationSettings.adminlti && (
          <>
            {showsLtiModal &&
              React.createElement(integrationSettings.adminlti, {
                integrationType: integrationType,
                closeModal: () => {
                  setShowsLtiModal(false);
                },
                integrateSpecificAgent: integrateSpecificAgent,
                setTntegrateSpecificAgent: setTntegrateSpecificAgent,
                renderCopy: renderCopy,
                data: integrations?.adminEducator?.params,
                handleIntegration: handleIntegration,
                formData: formData,
                setFormData: setFormData,
                handleInputChange: handleInputChange,
                allAgents: allAgents,
              })}

            <div className="acc-item">
              <Accordion.Toggle
                eventKey="0"
                className="acc-toggle p-3 justify-content-between w-100"
              >
                <div className="d-flex justify-content-center align-items-center">
                  <Image src={CanvasLink} />
                  <div className="text-left pl-3">
                    <div>
                      <b>
                        {t(
                          `Establish Connection with ${integrationSettings.label}`,
                        )}
                      </b>
                    </div>
                    <div className="subtitle">
                      {t(
                        `Set up LTI 1.3 integration between Noodle Factory and ${integrationSettings.label}`,
                      )}
                    </div>
                  </div>
                </div>
                <button
                  className={classNames({
                    "tw-px-4 tw-py-2.5 tw-text-white tw-bg-primary tw-rounded-[4px] tw-flex tw-items-center tw-gap-4":
                      true,
                    "tw-px-4 tw-py-2.5 tw-text-white tw-bg-primary conected-tw-btn tw-rounded-[4px] tw-flex tw-items-center tw-gap-4":
                      integrations?.adminEducator?.params?.is_connected,
                  })}
                  onClick={() => {
                    setIntegrationType("adminEducator");
                    setShowsLtiModal(true);
                  }}
                >
                  {integrations?.adminEducator?.params?.is_connected
                    ? t("Connected")
                    : t("Configure")}
                </button>
              </Accordion.Toggle>
            </div>
          </>
        )}

        {/*Content integration*/}
        {integrationSettings && integrationSettings.api && (
          <>
            {showsApiModal &&
              React.createElement(integrationSettings.api, {
                integrationType: integrationType,
                closeModal: () => {
                  setShowsApiModal(false);
                },
                renderCopy: renderCopy,
                data: integrations?.walterEducator?.params,
                handleIntegration: handleIntegration,
                formData: formData,
                setFormData: setFormData,
                handleInputChange: handleInputChange,
              })}

            <div className="acc-item">
              <Accordion.Toggle
                eventKey="0"
                className="acc-toggle p-3 justify-content-between w-100"
              >
                <div className="d-flex justify-content-center align-items-center">
                  <Image src={DashboardLink} />
                  <div className="text-left pl-3">
                    <div>
                      <b>{t("Enable Content Integration")}</b>
                    </div>
                    <div className="subtitle">
                      {t(
                        `Allow Noodle Factory to access and sync content from ${integrationSettings.label}`,
                      )}
                    </div>
                  </div>
                </div>
                <button
                  onClick={() => {
                    setIntegrationType("walterEducator");
                    setShowsApiModal(true);
                  }}
                  className={classNames({
                    "tw-px-4 tw-py-2.5 tw-text-white tw-bg-primary tw-rounded-[4px] tw-flex tw-items-center tw-gap-4":
                      true,
                    "tw-px-4 tw-py-2.5 tw-text-white tw-bg-primary conected-tw-btn tw-rounded-[4px] tw-flex tw-items-center tw-gap-4":
                      integrations?.walterEducator?.params?.is_connected,
                  })}
                >
                  {integrations?.walterEducator?.params?.is_connected
                    ? t("Connected")
                    : t("Configure")}
                </button>
              </Accordion.Toggle>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default IntegrationType;
