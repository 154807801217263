import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { getCommonUserDetails } from "../../store/common/selector";
import "../TeachersToolkit/index.scss";

import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import contextialiseChatImg from "../../assets/New-images/contextualised-chat-img.svg";
import createQuizImg from "../../assets/New-images/create-quiz.svg";
import genQueAnsImg from "../../assets/New-images/gen-que-ans-img.svg";
import learningOutcomesImg from "../../assets/New-images/learning-outcomes-img.svg";
import summariseDocImg from "../../assets/New-images/summarise-doc-img.svg";
import arrowPopup from "../../assets/images/arrow-popup.svg";

import {
  TOOLKIT_GENERATE_LEARNING_OUTCOMES,
  TOOLKIT_GENERATE_QNA_UPLOAD_DOC,
  TOOLKIT_SETUP_CONTEXTUALISED_CHAT,
  TOOLKIT_SUMMARISE_DOCUMENTS,
} from "../../config";
import CreateQuizModalTeachersToolkit from "./popups/createQuizModalTeachersToolkit";
import SelectModalModule from "./popups/selectModalModule";
import StartVideoPopup from "./popups/StartVideoPopup";

export const TeachersToolkit = (props: any) => {
  const { t } = useTranslation("translation");
  const user = useSelector(getCommonUserDetails);
  const history = useHistory();
  const useLocatio = useLocation();
  const dispatch = useDispatch();
  const query = new URLSearchParams(useLocatio.search);
  const groupId = query.get("group");
  const reference = query.get("reference");
  const [showSelectModule, setShowSelectModule] = useState(false);
  const [selectedModule, setSelectedModule] = useState(null);
  const [showCreateQuizModal, setShowCreateQuizModal] = useState(false);
  const [header, setHeader] = useState("");
  const [showVideo, setIsShowVideo] = useState(false);

  const [subHeader, setSubHeader] = useState("");
  const [selectedFlow, setSelectedFlow] = useState("");
  useEffect(() => {
    if (reference === "create-quiz") {
      setShowCreateQuizModal(true);
      setSelectedModule(groupId ? groupId : null);
    }
  }, []);

  const confirmCategoryUpdate = async (
    selectedOption: any,
    createButtons: boolean = false,
    key: string
  ) => {
    if (selectedOption) {
      await setSelectedModule(selectedOption);
      await setShowSelectModule(false);
      setHeader("");
      setSubHeader("");
      setSelectedFlow("");
      switch (key) {
        case "CONTEXTUALISED_CHAT":
          history.push(
            `${TOOLKIT_SETUP_CONTEXTUALISED_CHAT}?group=${selectedOption}`
          );
          break;
        case "LEARNING_OUTCOMES":
          history.push(
            `${TOOLKIT_GENERATE_LEARNING_OUTCOMES}?group=${selectedOption}`
          );
          break;
        case "GENERATE_KNOWLEDGE":
          history.push(
            `${TOOLKIT_GENERATE_QNA_UPLOAD_DOC}?group=${selectedOption}`
          );
          break;
        case "SUMMARISE_DOCUMENTS":
          history.push(
            `${TOOLKIT_SUMMARISE_DOCUMENTS}?group=${selectedOption}`
          );
          break;
        case "CREATE_QUIZ":
          if (reference !== "create-quiz") {
            setShowCreateQuizModal(true);
          }
          break;
        default:
          break;
      }
    }
  };

  const showSelectModulesHandler = (
    heading: string,
    subHeading: string,
    key: string
  ) => {
    setSelectedFlow(key);
    setHeader(heading);
    setSubHeader(subHeading);
    if (!groupId) {
      setShowSelectModule(true);
    } else {
      confirmCategoryUpdate(groupId, false, key);
    }
  };

  return (
    <>
      <SelectModalModule
        show={showSelectModule}
        onHide={() => {
          setShowSelectModule(false);
          setHeader("");
          setSubHeader("");
          setSelectedFlow("");
        }}
        confirmCategoryUpdate={confirmCategoryUpdate}
        header={header}
        subHeader={subHeader}
        isBuildKnowledge
        selectedFlow={selectedFlow}
      />
      <StartVideoPopup
        show={showVideo}
        close={() => setIsShowVideo(false)}
      />

      <CreateQuizModalTeachersToolkit
        show={showCreateQuizModal}
        onHide={() => {
          if (reference === "create-quiz") {
            history.goBack();
          } else {
            setShowCreateQuizModal(false);
          }
        }}
        selectedGroupId={selectedModule}
        createSmartQuiz={reference === "create-quiz" ? true : false}
      />
      <h2 className="font-weight-bold pt-3">
        {t("What would you like to do today?")}
      </h2>
      <div>
        <Row className={"mt-4 row-12"}>
          <Col md={4} className="mb-3 mb-md-0">
            <div
              className="teachers-toolkit-box p-3"
              onClick={() => { history.push(`/knowledge/setup`); }}
            >
              <div className="start-here">
                {t("Start here")}
              </div>
              <div className="inner-box ">
                <img src={contextialiseChatImg} alt="" />
              </div>
              <div className="inner-text">
                {/* To remove text-nowrap */}
                <h2 className="">{t("Set Up Contextualised Chat")}</h2>
                {/* To remove text-nowrap */}
                <span className="description">
                  {t(
                    "Expand your chatbot knowledge by adding documents into your knowledge base"
                  )}
                </span>
                <div className="start-video d-flex cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsShowVideo(true)
                  }}
                >
                  <span className="pr-2 font-600">
                    {t("See how it works ")}
                  </span>
                  <img src={arrowPopup} alt="" />
                </div>
              </div>

            </div>
          </Col>
          <Col md={4} className="mb-3 mb-md-0">
            <div
              className="teachers-toolkit-box"
              onClick={() => {
                showSelectModulesHandler(
                  t("Set Learning Outcomes"),
                  t("Select the knowledge group to update learning outcomes"),
                  "LEARNING_OUTCOMES"
                );
              }}
            >
              <div className="inner-box">
                <img src={learningOutcomesImg} alt="" />
              </div>
              <div className="inner-text">
                <h2 className="">{t("Set Learning Outcomes")}</h2>
                <span className="description">
                  {t(
                    "Define learning outcomes to ensure generated knowledge aligns with your objectives"
                  )}
                </span>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div
              className="teachers-toolkit-box"
              onClick={() => {
                showSelectModulesHandler(
                  t("Create a Quiz"),
                  t("Select the knowledge group to create quiz"),
                  "CREATE_QUIZ"
                );
              }}
            >
              <div className="inner-box">
                <img src={createQuizImg} alt="" />
              </div>
              <div className="inner-text">
                <h2 className="">{t("Create a Quiz")}</h2>
                <span className="description">
                  {t(
                    "Empower your teaching with interactive assessments to access your students’ understanding"
                  )}
                </span>
              </div>
            </div>
          </Col>
        </Row>
        <Row className={"mt-3 mt-md-4 row-8"}>
          <Col md={6} className="mb-3">
            <div
              className="teachers-toolkit-box"
              onClick={() => {
                showSelectModulesHandler(
                  t("Generate Knowledge"),
                  t("Select the knowledge group to generate knowledge"),
                  "GENERATE_KNOWLEDGE"
                );
              }}
            >
              <div className="inner-box">
                <img src={genQueAnsImg} alt="" />
              </div>
              <div className="inner-text-2">
                <h2 className="">{t("Generate Knowledge")}</h2>
                <span className="description">
                  {t(
                    "Extract key points from your documents and turn them into knowledge to use for your chatbot"
                  )}
                </span>
              </div>
            </div>
          </Col>
          <Col md={6} className="mb-3">
            <div
              className="teachers-toolkit-box "
              onClick={() => {
                showSelectModulesHandler(
                  t("Summarise Document(s)"),
                  t("Select the Knowledge group to save summarised document"),
                  "SUMMARISE_DOCUMENTS"
                );
              }}
            >
              <div className="inner-box">
                <img src={summariseDocImg} alt="" />
              </div>
              <div className="inner-text-2">
                <h2 className="">{t("Summarise Document(s)")}</h2>
                <span className="description">
                  {t(
                    "Effortlessly create new documents by merging multiple documents or summarizing them individually"
                  )}
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default TeachersToolkit;
