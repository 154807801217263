import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import chatImg1 from "../../assets/New-images/chatbotImg1.svg";
import chatImg2 from "../../assets/New-images/chatbotImg2.svg";
import docsummaryImg from "../../assets/New-images/doc-summary-img.svg";
import buildQuiz from "../../assets/New-images/build-quiz-summarise.svg";

import { useTranslation } from "react-i18next";
import Loading from "../../components/UI/Loading/Loading";
import { CHAT_CUSTOMISATION, QUIZZES_BETA, SUPPORT } from "../../config";
// import { cancelTokenSource } from "../../axios-global";

export const SummariseKandraDocument = (props: any) => {
  const { t } = useTranslation("translation");
  const history = useHistory();
  const params: any = useParams();

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <React.Fragment>
      <section className="page-mid-wraper h-without-foter">
        <Container>
          <div className="d-flex flex-wrap justify-content-between">
            <h1 className="mb-2">{t("Setup Contextualised Chat")}</h1>
          </div>
          <div className="mb-32 font-16">
            {t("Utilise the documents in your knowledge base to establish the context for your chatbot.")}
          </div>

          <div className="pdf-section-wrapper">
            <>
              <div className="document-section-wrapper row">
                <div className="col-md-12 d-flex flex-column text-center">
                  <div className="doc-processing d-flex flex-wrap align-items-center p-0">
                    {/* <h1 className="col-msg font-600">
                      {t("Congratulations! You have successfully built your knowledge base.")}
                    </h1> */}
                    <h2 className="col-msg font-1 font-600">
                      {t(
                        "Your document has been saved and will be used to set up the context for your chatbot."
                      )}
                    </h2>
                  </div>
                  <div className="my-4">
                    <img src={docsummaryImg} alt="" style={{
                        maxHeight: '250px' 
                    }}/>
                    <div className="font-16 mt-3">
                      {t(
                        "You will receive a notification when the context setup has been completed."
                      )}
                      <br />
                      {t(
                        "Please ensure that your notifications are on so you don’t miss out on the update."
                      )}{" "}
                      <span className="clr-primary font-weight-bold cursor-pointer" onClick={() =>
                          history.push("/notification", {
                            activetab: "preference",
                          })
                        }>
                        {t("Check notification settings")}
                      </span>
                    </div>
                  </div>
                  <div className="p-3 radius-8 mt-auto text-left">
                    <h2 className="font-1 mb-3">
                      <b>{t("Try out our other features or learn how to setup your chatbot")}</b>
                    </h2>
                    <Row>
                      <Col>
                        <div className="knowledge-box know-box-new radius-8 p-3 not-hover d-flex align-items-center" onClick={() => {
                            history.push(QUIZZES_BETA)
                        }}>
                          <figure className="h-auto mb-0">
                            <img src={buildQuiz} alt="" />
                          </figure>
                          <div className="text-left pl-2">
                            <h3 className="font-weight-bold font-sm clr-primary mb-1">
                              {t("Build a Quiz")}
                            </h3>
                            <p className="clr-grey-txt7">
                              {t("View your other files")}
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="knowledge-box know-box-new radius-8 p-3 not-hover d-flex align-items-center" onClick={() => history.push(CHAT_CUSTOMISATION)}>
                          <figure className="h-auto mb-0">
                            <img src={chatImg1} alt="" />
                          </figure>
                          <div className="text-left pl-2">
                            <h3 className="font-weight-bold font-sm clr-primary mb-1">
                              {t("Manage your chatbot")}
                            </h3>
                            <p className="clr-grey-txt7">
                              {t("To match your your brand")}
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="knowledge-box know-box-new radius-8 p-3 not-hover d-flex align-items-center"  onClick={() => history.push(SUPPORT)}>
                          <figure className="h-auto mb-0">
                            <img src={chatImg2} alt="" />
                          </figure>
                          <div className="text-left pl-2">
                            <h3 className="font-weight-bold font-sm clr-primary mb-1">
                              {t("Learn how to setup")}
                            </h3>
                            <p className="clr-grey-txt7">
                              {t("View our video guides")}
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    {/* <div className="font-600 font-sm mt-3">
                      {t(
                        "Please ensure that your notifications are on so that you don’t miss out the update."
                      )}{" "}
                      <span className="clr-primary font-weight-bold">
                        {t("Check notification settings")}
                      </span>
                    </div> */}
                  </div>
                </div>
              </div>
            </>
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default SummariseKandraDocument;
