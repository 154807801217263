export const customStyles = {
    header: {
      style: {
        minHeight: '56px',
      },
    },
    headRow: {
      style: {
        borderRadius: '10px',
        backgroundColor: '#0923E6',
        borderBottomWidth: '0',
      },
    },
    expanderRow: {
      style: {
        // backgroundColor: '#0923E6',
      },
    },
    expanderCell: {
      style: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
        button: {
          backgroundColor: 'transparent !important',
          img: {
            width: '10px',
          },
        },
      },
    },
    expanderButton: {
      style: {
        backgroundColor: 'transparent',
        alignItems: 'center',
      },
    },
    headCells: {
      style: {
        '&:first-child': {
          borderTopLeftRadius: '5px',
          borderBottomLeftRadius: '0px',
        },
        '&.chart--table-sort-icon': {
          marginLeft: '10px',
        },
        'input[type="checkbox"]': {
          width: '16px',
          height: '16px',
        },
        backgroundColor: '#0923E6',
        width: '300px',
        padding: '20px 20px',
        fontSize: '14px',
        color: '#fff',
        '&:not(:last-of-type)': {
          borderRightStyle: 'solid',
          borderRightWidth: '1px',
          borderRightColor: '#FFFFFF',
        },
        div: {
          color: '#fff',
        },
      },
    },
    cells: {
      style: {
        color: '#07032B',
        fontSize: '14px',
        paddingTop: '10px',
        paddingBottom: '10px',
        'input[type="checkbox"]': {
          width: '16px',
          height: '16px',
        },
        '&:nth-child(4)': {
          div: {
            textOverflow: 'ellipsis',
            display: ' -webkit-box',
            WebkitLineClamp: '2',
            WebkitBoxOrient: 'vertical',
          },
        },
      },
    },
  }