/* eslint-disable jsx-a11y/anchor-is-valid */
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { MANAGEKNOWLEDGEGROUP } from "../../../config";
import {
  getAllLevels,
  getAllModulesFromYear,
} from "../../../store/knowledge/actions";
import plusIcon from "../../../assets/New-images/plus-blue.svg";

import { useTranslation } from "react-i18next";

function ManageLevelCompact({
  updateParentHandler,
  onSelectGroupId,
  selectedGroupId,
  setSelectedParentId,
  setSelectedModuleName,
  editMode,
  intent,
  newModuleAdded
}: any) {
  const { t } = useTranslation('translation');
  const history = useHistory();
  const dispatch = useDispatch();
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [modules, setModules] = useState([]);

  useEffect(() => {
    getAllYearsFun();
  }, []);

  useEffect(() => {
    if ( newModuleAdded ) {
      getAllYearsFun()
    }
  }, [ newModuleAdded ]);

  const getAllYearsFun = async (loadSubModules: boolean = true) => {
    try {
      const response: any = await dispatch(getAllLevels());
      setYears([...response.filter((res: any) => res.group_type !== "system")]);
      response &&
        response.length > 0 &&
        getAllModulesFromYearFun(selectedYear ? selectedYear : response[0]);
    } catch (error: any) {
      setYears([]);
    }
  };

  const getAllModulesFromYearFun = async (year: any) => {
    setSelectedYear(year);
    try {
      const response: any = await dispatch(getAllModulesFromYear(year._id));
      setModules([...response]);
    } catch (error: any) {
      setModules([]);
    }
  };
  return (
    <div className="level-tab-wraper">
      <div className="levels-row mb-4 d-flex align-items-center flex-wrap">
        <h2 className="mb-0 mr-3 font-1">{t("Level:")}</h2>
        {years.map((year: any) => {
          return (
            <button
              className={classNames({
                "btn btn-tabs ": true,
                active: selectedYear && year._id === selectedYear._id,
              })}
              onClick={() => {
                getAllModulesFromYearFun(year);
              }}
            >
              {year?.name}
            </button>
          );
        })}
        {selectedYear && selectedYear._id && (
          <button
            className="btn btn-link"
            onClick={() => {
              // updateParentHandler(
              //   selectedYear.name,
              //   selectedYear._id,
              //   true,
              //   0
              // );
              history.push(MANAGEKNOWLEDGEGROUP + "?level=" + selectedYear._id);
            }}
          >
            {t("+ Edit")}
          </button>
        )}
      </div>
      {years.length > 0 && (
        <div className="knowledge-box p-0 build-knowbox text-left d-flex flex-column bg-white mb-4">
          <div className="d-flex justify-content-between align-items-center px-3 py-2 bg-grey-head">
            <h2 className="mb-0 font-1">{t("Modules")}</h2>
            <button
              className="btn btn-link font-sm px-0 py-1"
              onClick={() => {
                updateParentHandler(null, selectedYear._id, false, -1);
              }}
            >
              {t("+ Add")}
            </button>
          </div>
          {modules.length > 0 && (
            <div className="modules-list flex-grow-1 scroll-wrap">
              <ul className="list-unstyled">
                {modules.map((level: any) => {
                  return (
                    <li
                      key={
                        "child_padding_dev_" +
                        level.value +
                        "_" +
                        Math.floor(Math.random() * 9999)
                      }
                      style={{
                        paddingLeft: `${
                          level.depth > 0 ? level.depth * 10 + 10 : 10
                        }px`,
                      }}
                      className={classNames({
                        "clr-grey-txt": level.depth > 0,
                        active:
                          selectedGroupId === level.value ||
                          (editMode &&
                            intent &&
                            intent.knowledge_group_ids &&
                            intent.knowledge_group_ids.indexOf(level.value) >=
                              0),
                      })}
                    >
                      <a
                        href="javascript:void(0);"
                        onClick={() => {
                          onSelectGroupId(level.value);
                        }}
                        className={classNames({
                          "clr-grey-txt": level.depth > 0,
                        })}
                      >
                        {level?.label}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          {modules.length === 0 && (
            <div className="empty-knowledge text-center my-5">
              <button
                className="btn"
                onClick={() => {
                  setSelectedParentId(selectedYear._id);
                  setSelectedModuleName("");
                  // updateModalHandler();
                }}
              >
                <img src={plusIcon} alt="" />
              </button>
              <h3 className="h6 clr-primary mb-0 font-weight-bold">
                {t("Click on Add a Module")}
              </h3>
            </div>
          )}
          {/* <div className="text-center px-3 py-2">
              <button className="btn btn-link">Manage Access</button>
            </div> */}
          {/* empty state */}
        </div>
      )}
    </div>
  );
}

export default ManageLevelCompact;
