import React, { ReactElement, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import { getCommonUserDetails } from "../../../store/common/selector";
import "./styles.scss";
import { Button } from "react-bootstrap";
import CommonService from "../../../services/CommonService";
import { HelperService } from "../../../services/helper.service";
import greenCheckIcon from '../../../assets/images/green-check.svg'

const commonService = new CommonService();
const helperService = new HelperService();

export default function EmailVerifyReminder(): ReactElement {
  const { t } = useTranslation("translation");
  const userDetails = useSelector(getCommonUserDetails);
  const [resendCount, setResendCount] = useState(0);
  let interval: any;

  const resendEmail = async () => {
    try {
      await commonService.resendCurentUserEmail();
      helperService.showToasterIcon(t("Verification Email has been Sent"), "success", greenCheckIcon);
      setResendCount(60);
    } catch (error) {
      helperService.showToaster("error", error);
    }
  };


  const startCountDown = () => {
    interval = setInterval(() => {
      if (resendCount <= 0) {
        clearInterval(interval);
      }
      setResendCount((prev: number) => prev - 1);
    }, 1000);
  };

  useEffect(() => {
    if(resendCount === 60) {
      startCountDown()
    }
  }, [resendCount]);

  useEffect(() => {
    return () => clearInterval(interval);
  }, []);


  if (userDetails?.is_email_verified) {
    return null;
  }

  return (
    <div className="email-notify d-flex w-100 align-items-center align-items-center justify-content-center">
      {t(
        "You have not verified your email. Please verify your email to gain full access to our platform."
      )}
      <Button variant="link" onClick={() => resendEmail()}  disabled={resendCount > 0}>
        {t("Resend verification Email")} {resendCount > 0 && `(${resendCount})`}
      </Button>
    </div>
  );
}
