import React, { ReactElement, useEffect, useState } from 'react'

import { useTranslation } from "react-i18next";

interface Props {
  open: boolean
  message: string
}

export default function PasswordExpiryNotification({
  open,
  message,
}: Props): ReactElement {
  const { t } = useTranslation('translation');
  const [show, setshow] = useState(false)

  useEffect(() => {
    setshow(open)
    return () => {
      setshow(false)
    }
  }, [open])

  if (!show) {
    return <></>
  }

  return (
    <div className='password-notify'>
      {message}
      <button className='btn py-0' onClick={() => setshow(false)}>
        {t("Dismiss")}
      </button>
    </div>
  )
}
