import { HelperService } from './../../services/helper.service';
import * as type from "./actionType";
import { Url } from '../../app.interface';
import _ from 'lodash';
import { IntentInterface } from '../conversation/reducer';

const urlObj: Url = {
  name: 'https://',
  valid: true,
  editMode: true,
  generatedUrls: [],
  weblinks: [],
  selectedlinks: [],
  paginatedWebLinks: [],
  pagination: null,
}
const helperService = new HelperService();

interface KnowledgeReducerState {
  loading: boolean,
  documents: any[],
  saveKnowledge: boolean,
  knowledgeSaved: boolean,
  knowledgeError: boolean,
  links: Url[],
  isWebsiteStoring: boolean;
  isWebsiteStoringError: boolean;
  isWebsiteStored: boolean;
  allKnowledgeLoading: boolean;
  allKnowledgeError: boolean;
  allKnowledge?: {
    intents?: IntentInterface[],
    pagination?: {
      page?: number;
      page_size?: number;
      total_items?: number;
      total_pages?: number;
    }
  };
  allSuggestions?: {
    intents?: IntentInterface[],
    pagination?: {
      page?: number;
      page_size?: number;
      total_items?: number;
      total_pages?: number;
    }
  };
  topics: any[];
  topicsLoading: boolean;
  keyWordsLoading: boolean;
  documentFaqs: {
    loading: boolean,
    data?: {
      last_offset?: number;
      limit?: number;
      qnas?: {
        answer?: string,
        question?: string
      }[];
      questions?: string[]
    },
    document?: any
  },
  manageAccessData: object

}

const initialState: KnowledgeReducerState = {
  loading: true,
  documents: [],
  saveKnowledge: false,
  knowledgeSaved: false,
  knowledgeError: false,
  links: [urlObj],
  isWebsiteStoring: false,
  isWebsiteStoringError: false,
  isWebsiteStored: false,
  allKnowledgeLoading: false,
  allKnowledgeError: false,
  allKnowledge: {
    pagination: null,
    intents: []
  },
  allSuggestions: {
    pagination: null,
    intents: []
  },
  topics: [],
  topicsLoading: false,
  keyWordsLoading: false,
  documentFaqs: {
    loading: false,
    data: null
  },
  manageAccessData: null
};

const KnowledgeReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case type.GENERATE_ANSWERS_RELOADED:
    case type.RESET_DOCUMENTS:
      return {
        ...state,
        documents: [],
        links: [...[urlObj]],
        isWebsiteStoring: false,
        isWebsiteStoringError: false,
        isWebsiteStored: false,
      };
    case type.STORE_DOCUMENTS:
      return {
        ...state,
        documents: _.uniqBy(state.documents.concat(action.payload), '_id')
      };
    case type.UPDATE_ALL_DOCUMENTS: {
      // console.log(' UPDATE_ALL_DOCUMENTS ', action.documents)
      return {
        ...state,
        documents: action.documents
      };
    }
    case type.INIT_STORE_FAQ_RESPONSES_FOR_DOCUMENT:
    case type.INIT_STORE_KEYWORDS_FOR_DOCUMENT_SUCCESS:
    case type.STORE_KEYWORDS_FOR_DOCUMENT_SUCCESS:
    case type.LOCAL_DOCUMENT_UPDATE:
      const oldDocument = [...state.documents];
      const document = oldDocument.find(d => d._id === action.payload.documentId);
      document.keywords = action.payload.keywords && action.payload.keywords.length > 0 ? [...action.payload.keywords] : (document.keywords ? document.keywords : []);
      document.keywordsQnas = action.payload.keywordsQnas && action.payload.keywordsQnas.length > 0 ? [...action.payload.keywordsQnas] : (document.keywordsQnas ? document.keywordsQnas : []);
      document.keywordsLoading = action.payload.keywordsLoading ? action.payload.keywordsLoading : false;
      document.keywordFetchError = action.payload.keywordFetchError ? action.payload.keywordFetchError : false;
      document.last_offset = action.payload.last_offset ? action.payload.last_offset : 0;
      document.retryExecution = action.payload.retryExecution ? action.payload.retryExecution : false;
      return {
        ...state,
        saveKnowledge: action.payload.saveKnowledge ? action.payload.saveKnowledge : false,
        documents: [...oldDocument]
      };
    case type.INIT_STORE_QUESTIONS_FOR_KEYWORDS: {
      const oldDocument = [...state.documents];
      const document = oldDocument.find(d => d._id === action.payload.documentId);
      document.questionsLoading = true
      document.qas = []
      return {
        ...state,
        documents: [...oldDocument]
      };
    }
    case type.UPDATE_DOCUMENT_AFTER_INTERVAL: {
      const oldDocument = [...state.documents];
      const document = oldDocument.find(d => d._id === action.payload.documentId);
      document.keywordFetchError = action.payload.keywordFetchError;
      return {
        ...state,
        documents: [...oldDocument]
      };
    }
    case type.INIT_STORE_QUESTIONS_FOR_KEYWORDS_SUCCESS: {
      const oldDocument = [...state.documents];
      const document = oldDocument.find(d => d._id === action.payload.documentId);
      document.questionsLoading = false
      return {
        ...state,
        documents: [...oldDocument]
      };
    }
    case type.STORE_QUESTIONS_FOR_KEYWORDS_SUCCESS:
    case type.STORE_QUESTIONS_FOR_KEYWORDS_UPDATE: {
      const oldDocument = [...state.documents];
      const document = oldDocument.find(d => d._id === action.payload.documentId);
      if ( document ) {
        document.qas = !document.qas || document.qas.length === 0 ? [] : document.qas;
        document.qas = document.qas.concat([...action.payload.qas]);
        document.retryExecution = action.payload.retryExecution ? action.payload.retryExecution: false;
        document.last_offset = action.payload.last_offset ? action.payload.last_offset: 0;
        document.qasFetchError = action.payload.qasFetchError ? action.payload.qasFetchError: false;
      }
      return {
        ...state,
        documents: [...oldDocument]
      };
    }
    case type.STORE_FAQ_RESPONSES_FOR_DOCUMENT_SUCCESS:
    case type.LOCAL_ANSWWER_UPDATE: {
      const oldDocument = [...state.documents];
      const document = oldDocument.find(d => d._id === action.payload.documentId);
      document.qas = [...action.payload.qas];
      document.keywordsLoading = false;
      return {
        ...state,
        documents: [...oldDocument],
        keyWordsLoading: action.payload.hasOwnProperty('keyWordsLoading') ? action.payload.keyWordsLoading : false,
      };
    }
    case type.INIT_GENERATE_ANSWERS_SCREEN: {
      const oldDocument = [...state.documents];
      return {
        ...state,
        documents: action.payload.hasOwnProperty('keyWordsLoading') ? [ ..._.uniqBy(oldDocument, '_id')] : oldDocument,
        keyWordsLoading: action.payload.hasOwnProperty('keyWordsLoading') ? action.payload.keyWordsLoading : false,
        saveKnowledge: false,
        knowledgeSaved: false,
        knowledgeError: false,
      }
    }
    case type.INIT_SAVE_RESPONSE_TO_KNOWLEDGE:
      return {
        ...state,
        saveKnowledge: true
      }
    case type.SAVE_RESPONSE_TO_KNOWLEDGE_SUCCESS: {
      return {
        ...state,
        saveKnowledge: false,
        knowledgeSaved: true,
        knowledgeError: false,
      }
    }
    case type.SAVE_RESPONSE_TO_KNOWLEDGE_FAIL:
      return {
        ...state,
        saveKnowledge: false,
        knowledgeSaved: false,
        knowledgeError: true,
      }
    case type.STORE_QUESTIONS_FOR_KEYWORDS_FAIL:
      return {
        ...state
      }
    case type.LOCAL_UPDATE_URL_DATA:
      return {
        ...state,
        links: [...action.payload]
      }
    case type.PAGINATE_WEB_LINKS: {
      const oldLinks = [...state.links];
      const oldLink = oldLinks[action.payload.index];
      const paginator = helperService.paginator([...oldLink.weblinks], action.payload.pageNumber, action.payload.pageSize)
      oldLink.pagination = paginator;
      oldLink.paginatedWebLinks = oldLink.paginatedWebLinks.concat([...paginator.data]);
      return {
        ...state,
        links: [...oldLinks]
      }
    }
    case type.INIT_GENERATE_WEBLINKS_FROM_URL: {
      const oldLinks = [...state.links];
      const oldLink = oldLinks[action.payload.index];
      oldLink.weblinksLoading = action.payload.loading;
      const linksAsObject = action.payload.links.map((link: string, index: number) => {
        return { checked: index === 0 ? true : false, url: link }
      })
      oldLink.weblinks = [...linksAsObject];
      oldLink.editMode = action.payload.editMode;
      const paginator = helperService.paginator([...linksAsObject], 1, 5);
      oldLink.pagination = paginator;
      oldLink.paginatedWebLinks = paginator.data;
      return {
        ...state,
        links: [...oldLinks],
      }
    }
    case type.INIT_STORE_WEBSITE_TO_SERVER:
      return {
        ...state,
        isWebsiteStoring: true,
        isWebsiteStoringError: false,
        isWebsiteStored: false,
      }
    case type.STORE_WEBSITE_TO_SERVER_SUCCESS:
      return {
        ...state,
        isWebsiteStoring: false,
        isWebsiteStoringError: false,
        isWebsiteStored: true,
      }
    case type.STORE_WEBSITE_TO_SERVER_FAIL:
      return {
        ...state,
        isWebsiteStoring: false,
        isWebsiteStoringError: true,
        isWebsiteStored: false,
      }
    case type.INIT_ALL_KNOWLEDGE:
    case type.CHUNK_KNOWLEDGE_STARTED:
      return {
        ...state,
        allKnowledgeLoading: true,
        allKnowledgeError: false
      }
    case type.FETCH_ALL_KNOWLEDGE_SUCCESS:
      return {
        ...state,
        allKnowledgeLoading: false,
        allKnowledge: { ...action.payload }
      }
    case type.FETCH_ALL_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        allKnowledgeLoading: false,
        allSuggestions: { ...action.payload }
      }
    case type.FETCH_ALL_SUGGESTIONS_ERROR:
    case type.FETCH_ALL_KNOWLEDGE_ERROR:
      return {
        ...state,
        allKnowledgeLoading: false,
        allKnowledgeError: false,
        allKnowledge: {},
        allSuggestions: {},
      }
      case type.FETCH_TOPICS_ERROR:
    case type.FETCH_TOPICS_SUCCESS:
      return {
        ...state,
        topics: action.payload.topics,
        topicsLoading: action.payload.topicsLoading
      }
    case type.CHUNK_KNOWLEDGE_FINISHED:
    case type.UPDATE_KNOWLEDGE_BY_ID_ERROR:
      return {
        ...state,
        allKnowledgeLoading: false,
      }
    case type.INIT_KNOWLEDGE_BY_ID:
      return {
        ...state,
        allKnowledgeLoading: true,
        allKnowledgeError: false
      }
    case type.UPDATE_KNOWLEDGE_BY_ID_SUCCESS: {
      const oldKnowledges = { ...state.allKnowledge };
      const intentIndex = oldKnowledges.intents.findIndex(intent => intent._id === action.payload._id);
      if (intentIndex >= 0) {
        oldKnowledges.intents[intentIndex] = { ...action.payload };
      }
      return {
        ...state,
        allKnowledgeLoading: false,
        allKnowledge: { ...oldKnowledges }
      }
    }
    case type.UPDATE_BULK_KNOWLEDGE_BY_ID_SUCCESS: {
      const oldKnowledges = { ...state.allKnowledge };
      const intentIndex = oldKnowledges.intents.findIndex(intent => intent._id === action.payload._id);
      if (intentIndex >= 0) {
        oldKnowledges.intents[intentIndex] = { ...action.payload };
      }
      return {
        ...state,
        allKnowledge: { ...oldKnowledges }
      }
    }
    case type.UPDATE_SUGGESTION_BY_ID: {
      const oldSuggestions = { ...state.allSuggestions };
      const intentIndex = oldSuggestions.intents.findIndex(intent => intent._id === action.payload._id);
      if (intentIndex >= 0) {
        oldSuggestions.intents[intentIndex] = { ...action.payload };
      }
      return {
        ...state,
        allKnowledgeLoading: false,
        allSuggestions: { ...oldSuggestions }
      }
    }
    case type.DELETE_KNOWLEDGE_BY_ID_SUCCESS: {
      const oldKnowledges = { ...state.allKnowledge };
      const intentIndex = oldKnowledges.intents.findIndex(intent => intent._id === action.payload.intentId);
      if (intentIndex >= 0) {
        oldKnowledges.intents.splice(intentIndex, 1);
      }
      return {
        ...state,
        allKnowledgeLoading: false,
        allKnowledge: { ...oldKnowledges }
      }
    }
    case type.DOCUMENT_FAQ_INIT: {
      return {
        ...state,
        documentFaqs: { ...action.payload }
      };
    }
    case type.FETCH_BOT_DATA_FAIL: // later update this with error code
    case type.MANAGE_ACCESS_RIGHTS_DATA: {
      return {
        ...state,
        manageAccessData: {...action.payload}
      }
    }
    default:
      return {
        ...state,
        loading: false
      };
  }
};

export default KnowledgeReducer;
