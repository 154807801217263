import React from 'react'
import { Button, Image, Modal, ModalFooter } from 'react-bootstrap'
import ModalBody from 'react-bootstrap/ModalBody'
import ModalHeader from 'react-bootstrap/ModalHeader'
import ModalTitle from 'react-bootstrap/ModalTitle'
import closeimg from '../../../assets/images/close-blk.svg'
import popupimg from '../../../assets/images/pixeltrue-meditation.svg'

import { useTranslation } from "react-i18next";
import KnowledgeSearchModalComponent from './KnowledgeSearchModalComponent'

export default function KnowledgeSearchModal(props: any) {
  const { t } = useTranslation('translation');
  return (
    <Modal
      {...props}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      dialogClassName="modal-960"
    >
      <ModalHeader className="pt-4 justify-content-between" onHide={props.onHide}>
        <ModalTitle id='contained-modal-title-vcenter'>
            <h2 className="mb-0">{t("Select from Knowledge Base")}</h2>
            {/* Sub title */}
            <p className='mb-0 font-sm'>{t("Select the knowledge you would like to link the Quiz Question to")}</p>
        </ModalTitle>
        <Button onClick={props.onHide}>
            <Image src={closeimg}/>
          </Button>
      </ModalHeader>
      <ModalBody className='know-search-modalbody'>
        <KnowledgeSearchModalComponent knowledgeConfirmHandler={props.confirmHandler} />
      </ModalBody>
    </Modal>
  )
}
