import React, { useState } from "react";
import { Modal, ModalFooter, Button, Image } from "react-bootstrap";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import * as _ from "lodash";
import closeimg from "../../../assets/images/close-blk.svg";

import { useTranslation } from "react-i18next";

export default function ModalCreate(props: any) {
  const { t } = useTranslation('translation');
  const [name, setName] = useState(null);

  return (
    <Modal
      show={props.show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="suggest-tags"
      onHide={props.onHide}
      onEntered={()=> setName("")}
    >
      <ModalHeader className="py-3 justify-content-center border-bottom">
        <ModalTitle
          id="contained-modal-title-vcenter"
          className="d-flex align-items-center justify-content-between w-100"
        >
          <h2 className="mb-0 font-1 font-600">
          {t("Add New Learning Outcome")}

          </h2>
          <Button onClick={props.onHide}>
            <Image src={closeimg} />
          </Button>
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <div className="form-group px-sm-4">
          <input
            type="text"
            className="form-control radius-2"
            placeholder={t('Type new learning outcome')}
            value={name || ""}
            onChange={(e) => setName(e?.target?.value)}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          className="btn-outline-primary btn mx-2 font-600"
          onClick={props.onHide}
        >
          {t("Cancel")}
        </button>
        <button
          className="btn-primary btn mx-2 font-600"
          onClick={() => {
             props.addOutcome(name)
          }}
          disabled={!name || name.trim().length === 0}
        >
          {t("Add")}
        </button>
      </ModalFooter>
    </Modal>
  );
}
