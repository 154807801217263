import React from "react";
import { EditorState, Modifier } from "draft-js";

import { useTranslation } from "react-i18next";

const BubbleBreakOption = (props: any) => {
  const { t } = useTranslation('translation');
  const addBubbleBreak = () => {
    const { editorState, onChange } = props;
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      " || ",
      editorState.getCurrentInlineStyle()
    );
    onChange(EditorState.push(editorState, contentState, "insert-characters"));
  };

  return (
    <div
      title={t("Bubble break")}
      onClick={addBubbleBreak}
      className="rdw-option-wrapper markdown-toolbar-option"
    >
      ||
    </div>
  );
};

export default BubbleBreakOption;
