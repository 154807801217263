import React, { useEffect, useState } from "react";
import { Form, Image } from "react-bootstrap";
import Select, { components } from "react-select";
import { Container, Draggable } from "react-smooth-dnd";
import baricon from "../../../assets/New-images/drag-icon.svg";
import downarrowblk from "../../../assets/New-images/dropdown-arow.svg";
import deleteicon from "../../../assets/New-images/delete-icon-blk.svg";
import { ButtonsOptions } from "../../../services/interfaces/button-options.interface";
import { HelperService } from "../../../services/helper.service";
import { useSelector } from "react-redux";
import { getAllUserGroupsSelector } from "../../../store/notifications/selector";
import { AllUserGroups } from "../../../store/questions/selector";
import PermissionWrapper from "../PermissionWrapper/PermissionWrapper";

import { useTranslation } from "react-i18next";
import { uuid4 } from "@sentry/utils";

interface Props {
  hideGroup?: boolean;
  buttonOptions: ButtonsOptions[];
  buttons: ButtonsOptions[];
  localUserQuestionUpdate: (type: string, data: ButtonsOptions[]) => void;
  isActivityDetected?: (
    selectedButtonTarget: any,
    data: ButtonsOptions[]
  ) => void;
}

const helperService = new HelperService();

export const ButtonsGroup = (props: Props) => {
  const { t } = useTranslation("translation");
  const allGroups = useSelector(AllUserGroups);
  const [buttonsList, setButtonOptionsList] = useState([]);
  const [buttonOptions, setButtonOptions] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedButtonTarget, setSelectedButtonTarget] = useState({
    group_ids: null,
    label: null,
    name: null,
    reference: null,
    reference_id: null,
    type: "form",
  });

  useEffect(() => {
    setButtonOptionsList(props.buttons);
  }, [props.buttons]);

  useEffect(() => {
    if (!buttonOptions.length) {
      setButtonOptions([ ...props.buttonOptions ]);
    }
  }, [ props.buttonOptions ]);

  const chooseButtonGrouphandler = (e: any, index: number) => {
    const oldButtonsList = [...buttonsList];
    oldButtonsList[+index].group_ids = e.map((g: any) => g.value);
    setButtonOptionsList([...oldButtonsList]);
    props.localUserQuestionUpdate("buttons", oldButtonsList);
  };

  ///////// drag and drop function to arrange values  ///////////
  const applyDrag = (arr: any, dragResult: any) => {
    const { removedIndex, addedIndex, payload } = dragResult;
    if (removedIndex === null && addedIndex === null) return arr;

    const result = [...arr];
    let itemToAdd = payload;

    if (removedIndex !== null) {
      itemToAdd = result.splice(removedIndex, 1)[0];
    }

    if (addedIndex !== null) {
      result.splice(addedIndex, 0, itemToAdd);
    }
    setButtonOptionsList([...result]);
    props.localUserQuestionUpdate("buttons", result);
    return result;
  };

  ///////// Change handlers for adding questions in loop ///////////
  const chooseButtonTargetHandler = (e: ButtonsOptions) => {
    const target = {
      ...selectedButtonTarget,
      reference_id: e.reference_id,
      name: e.name,
      type: e.type,
      label: selectedButtonTarget.label,
    };
    setSelectedButtonTarget(target);
    if (props.isActivityDetected) {
      props.isActivityDetected(target, buttonsList);
    }
  };

  const chooseButtonGroupsHandler = (e: any) => {
    setSelectedGroups([...e]);
    const target = {
      ...selectedButtonTarget,
      group_ids: e.map((g: any) => g.value),
    };
    setSelectedButtonTarget(target);
    if (props.isActivityDetected) {
      props.isActivityDetected(target, buttonsList);
    }
  };

  const chooseButtonLabelHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const target = {
      ...selectedButtonTarget,
      label: e.target.value,
    };
    setSelectedButtonTarget(target);
    if (props.isActivityDetected) {
      props.isActivityDetected(target, buttonsList);
    }
  };

  const addButtonOptionsToListHandler = (e: any) => {
    e.preventDefault();
    if (selectedButtonTarget.reference_id && selectedButtonTarget.label) {
      const oldButtonsList = [...buttonsList];
      oldButtonsList.push({
        ...selectedButtonTarget,
        name: selectedButtonTarget.name,
        type: selectedButtonTarget.type,
      });
      setButtonOptionsList([...oldButtonsList]);
      props.localUserQuestionUpdate("buttons", oldButtonsList);
      setSelectedButtonTarget({
        ...selectedButtonTarget,
        label: null,
        name: null,
        reference: null,
        reference_id: null,
      });
      setSelectedGroups([]);
      if (props.isActivityDetected) {
        props.isActivityDetected(null, []);
      }
    }
  };

  ///////// Change handlers from loop ///////////
  const changeLabelHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const oldButtonsList = [...buttonsList];
    oldButtonsList[+e.target.dataset.index].label = e.target.value;
    setButtonOptionsList([...oldButtonsList]);
    props.localUserQuestionUpdate("buttons", oldButtonsList);
  };

  const chooseSelectedButtonHandler = (e: any, index: number) => {
    const oldButtonsList = [...buttonsList];
    oldButtonsList[+index].reference_id = e.reference_id;
    oldButtonsList[+index].type = e.type;
    setButtonOptionsList([...oldButtonsList]);
    props.localUserQuestionUpdate("buttons", oldButtonsList);
  };

  const deleteButtonOptionsHandler = (index: number) => {
    const oldButtonsList = [...buttonsList];
    oldButtonsList.splice(index, 1);
    setButtonOptionsList([...oldButtonsList]);
    props.localUserQuestionUpdate("buttons", oldButtonsList);
    if (props.isActivityDetected) {
      props.isActivityDetected(null, oldButtonsList);
    }
  };

  const userInputHandler = (e: any) => {
    console.log(e);
  };

  const SuggestQuestionButton = (props: any) => {
    return (
      <components.MenuList {...props}>
        <div
          className=""
          onClick={async () => {
            let oldButtonOptions = [...buttonOptions];
            let id = uuid4();
            oldButtonOptions.push({
              label: `Suggest Question: ${props.selectProps.inputValue}`,
              name: props.selectProps.inputValue,
              reference: `suggestquestion::${id}`,
              reference_id: id,
              type: "question",
            });
            await setButtonOptions([...oldButtonOptions]);
            const target = {
              ...selectedButtonTarget,
              label: `${props.selectProps.inputValue}`,
              name: props.selectProps.inputValue,
              reference: `suggestquestion::${id}`,
              reference_id: id,
              type: "question",
            };
            setSelectedButtonTarget(target);
            if (props.isActivityDetected) {
              props.isActivityDetected(target, buttonsList);
            }
          }}
        >
          {t("Suggest Question: ")}
          {props.selectProps.inputValue}
        </div>
      </components.MenuList>
    );
  };  

  return (
    <>
      {/* --------------------------------------- Buttons list wrapper -------------------------------------------------- */}
      <div className="web-links d-flex align-items-center add">
        <input
          type="text"
          className="form-control"
          placeholder={t("Type button label here…")}
          value={selectedButtonTarget.label || ""}
          onChange={chooseButtonLabelHandler}
        />
        <Form>
          <Select
            isSearchable
            className="select-target"
            classNamePrefix="select-target-prefix"
            components={{
              DropdownIndicator: () => (
                <img src={downarrowblk} alt={t("dropdown arrow")} />
              ),
              IndicatorSeparator: () => null,
              // MenuList: SuggestQuestionButton,
              NoOptionsMessage: SuggestQuestionButton,
            }}
            placeholder={t("Select button target")}
            onChange={chooseButtonTargetHandler}
            value={
              selectedButtonTarget && selectedButtonTarget.reference_id
                ? {
                    ...selectedButtonTarget,
                    label: helperService.findValueFromObject(
                      buttonOptions,
                      selectedButtonTarget.reference_id,
                      "reference_id",
                      "label"
                    ),
                  }
                : null
            }
            options={buttonOptions}
            getOptionValue={(option: ButtonsOptions) => option["reference_id"]}
            // defaultInputValue
          />
          {!props.hideGroup && (
            <Select
              isSearchable
              isMulti
              className="select-target mt-3"
              classNamePrefix="select-target-prefix"
              components={{
                DropdownIndicator: () => (
                  <img src={downarrowblk} alt="dropdown arrow" />
                ),
                IndicatorSeparator: () => null,
                ClearIndicator: () => null,
              }}
              defaultValue={[...selectedGroups]}
              value={[...selectedGroups]}
              placeholder={t("Select user groups")}
              options={[...allGroups]}
              onChange={chooseButtonGroupsHandler}
            />
          )}
        </Form>
        <PermissionWrapper>
          <button
            className="btn btn-outline-primary border-0 mr-2 font-sm"
            onClick={addButtonOptionsToListHandler}
          >
            {t("Add")}
          </button>
        </PermissionWrapper>
      </div>
      {/* --------------------------------------- Drag and drop question wrapper -------------------------------------------------- */}
      <Container
        dragHandleSelector=".column-drag-handle"
        onDrop={(e) => applyDrag(buttonsList, e)}
      >
        {buttonsList.map((buttonsOption: ButtonsOptions, index: number) => {
          const selectedGroups: any = [];
          buttonsOption.group_ids &&
            buttonsOption.group_ids.length > 0 &&
            buttonsOption.group_ids.forEach((id: any) => {
              const group = allGroups.find((ug: any) => ug.value === id);
              if (group) {
                selectedGroups.push(group);
              }
            });
          return (
            <Draggable
              key={"draggable_" + buttonsOption.reference_id + "_i_" + index}
            >
              <div className="draggable-item web-links d-flex align-items-center">
                <button className="btn border-0 px-0 mr-3 mr-md-4 column-drag-handle">
                  <Image src={baricon} />
                </button>
                <input
                  key={
                    "button_ref_" + buttonsOption.reference_id + "_i_" + index
                  }
                  type="text"
                  className="form-control"
                  placeholder={t("Type button label here…")}
                  data-index={index}
                  value={buttonsOption.label || ""}
                  onChange={changeLabelHandler}
                />

                <Form>
                  <Select
                    key={
                      "select_ref_" + buttonsOption.reference_id + "_i_" + index
                    }
                    isSearchable
                    className="select-target"
                    classNamePrefix="select-target-prefix"
                    components={{
                      DropdownIndicator: () => (
                        <img src={downarrowblk} alt="dropdown arrow" />
                      ),
                      IndicatorSeparator: () => null,
                    }}
                    placeholder={t("Select button target")}
                    onChange={(e) => chooseSelectedButtonHandler(e, index)}
                    value={{
                      ...buttonsOption,
                      label: helperService.findValueFromObject(
                        buttonsOption.type === "question"
                          ? [
                              ...buttonOptions,
                              ...[
                                {
                                  ...{
                                    ...buttonsOption,
                                    label: `Suggest Question: ${buttonsOption.name}`
                                  },
                                },
                              ],
                            ]
                          : [...buttonOptions],
                        buttonsOption.reference_id,
                        "reference_id",
                        "label"
                      ),
                    }}
                    options={
                      buttonsOption.type === "question"
                        ? [
                            ...buttonOptions,
                            ...[
                              {
                                ...{
                                  ...buttonsOption,
                                  label: `Suggest Question: ${buttonsOption.name}`
                                },
                              },
                            ],
                          ]
                        : [...buttonOptions]
                    }
                    getOptionValue={(option: ButtonsOptions) =>
                      option["reference_id"]
                    }
                  />

                  {!props.hideGroup && (
                    <Select
                      isSearchable
                      isMulti
                      className="select-target mt-3"
                      classNamePrefix="select-target-prefix"
                      components={{
                        DropdownIndicator: () => (
                          <img src={downarrowblk} alt="dropdown arrow" />
                        ),
                        IndicatorSeparator: () => null,
                        ClearIndicator: () => null,
                      }}
                      defaultValue={[...selectedGroups]}
                      value={[...selectedGroups]}
                      placeholder={t("Select user groups")}
                      options={[...allGroups]}
                      onChange={(e) => chooseButtonGrouphandler(e, index)}
                    />
                  )}
                </Form>
                <PermissionWrapper>
                  <button
                    className="btn border-0 mr-2"
                    type="button"
                    onClick={() => deleteButtonOptionsHandler(index)}
                  >
                    <Image src={deleteicon} />
                  </button>
                </PermissionWrapper>
              </div>
            </Draggable>
          );
        })}
      </Container>
    </>
  );
};

export default ButtonsGroup;
