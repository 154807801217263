import { useDispatch } from "react-redux";
import redCross from "../../../assets/images/red-cross.svg";
import singleGreenCheck from "../../../assets/images/single-green-check.svg";
import greyCross from "../../../assets/images/grey-cross.svg";
import singleGreyCheck from "../../../assets/images/single-gray-check.svg";
import { createKnowledgeGroup } from "../../../store/knowledge/actions";
import React, { useState } from "react";
import { HelperService } from "../../../services/helper.service";

type PropsType = {
  setEditId?: React.Dispatch<React.SetStateAction<string>>;
  editId: string;
  depth: any;
  setSelectedModule: any;
  newListExpandAddHandler?: (id: string) => void;
};
function GroupUpdater(props: PropsType) {
  const {
    setEditId,
    editId,
    depth,
    setSelectedModule,
    newListExpandAddHandler,
  } = props;
  const dispatch = useDispatch();
  const helperService = new HelperService();
  const [loading, setLoading] = useState(false);
  const [moduleName, setModuleName] = useState("");
  const addKnowledgeGroup = async () => {
    try {
      setLoading(true);
      let res = null;
      res = await dispatch(
        createKnowledgeGroup({
          name: moduleName,
          parent_id: editId,
        })
      );

      if (res) {
        setModuleName("");
        setEditId("");
        const data: any = res;
        setSelectedModule(data?.group._id);
        newListExpandAddHandler(data?.group._id);
        newListExpandAddHandler(editId);
        setLoading(false);
        helperService.showToaster("Success", "success");
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (moduleName && moduleName.trim().length > 0) {
      addKnowledgeGroup();
    }
  };
  return (
    <div className="group-selected update-name-input d-flex groups-item tree-p ">
      <form onSubmit={handleSubmit}style={{ paddingLeft: `${depth + 1}rem` }}
        className="input-edit-group d-flex flex-1 justify-content-between align-items-center ">
      
        <input
          type="text"
          className=""
          value={moduleName}
          onChange={(e) => {
            setModuleName(e.target.value);
          }}
          autoFocus
        />
        <div className="edit-actions">
          {loading ? (
            <>
            <img
              id="add-new-knowledge-btn"
              src={singleGreyCheck}
              alt="confirm"
              className="mr-2"
            />
            <img
            id="remove-add-knowledge-bar"
            src={greyCross}
            alt="discard"
          />
          </>
          ) : (
            <>
            <img
                  id="add-new-knowledge-btn"
                  src={singleGreenCheck}
                  alt="confirm"
                  typeof="submit"
                  className="mr-2"
                  onClick={() => {
                    addKnowledgeGroup();
                  }}
                />
            <img
            id="remove-add-knowledge-bar"
            src={redCross}
            alt="discard"
            onClick={() => setEditId("")}
          />
          </>
          )}

         
        </div>
     
      </form>
    </div>
  );
}

export default GroupUpdater;
