import React, { useEffect, useState } from 'react'
import { EditorState, Modifier } from 'draft-js'

import variable from '../../../../assets/editor/variable.svg'

interface Props {}

export default function InsertVariableOption(props: any) {
  const [expanded, setExpanded] = useState(false)

  // useEffect(() => {
  //   props.modalHandler.registerCallBack(expandCollapse);

  //   return () => {
  //     props.modalHandler.deregisterCallBack(expandCollapse);
  //   };
  // }, []);

  const stopPropagation = (event: any) => {
    event.stopPropagation()
  }

  const renderModal = () => {
    return (
      <div
        onClick={stopPropagation}
        className='rdw-link-modal rdw-variable-menu'
      >
        {options.map((item: any, index: number) => (
          <div
            className='rdw-variable-menu-item'
            onClick={() => {
              onVariableChange(item.value)
            }}
            key={item.label + '__' + index}
          >
            {item.label}
          </div>
        ))}
      </div>
    )
  }

  const onVariableChange = (value: any) => {
    const { editorState, onChange } = props
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      `${value} `,
      editorState.getCurrentInlineStyle()
    )
    onChange(EditorState.push(editorState, contentState, 'insert-characters'))
    doCollapse()
  }

  const expandCollapse = () => {
    if (!expanded) {
      props.modalHandler.closeAllModals()
    }
    setExpanded((expanded) => !expanded)
  }

  const doCollapse = () => {
    setExpanded(false)
  }

  let options = [
    {
      value: '{{user.email}}',
      label: 'user email',
    },
    {
      value: '{{user.first_name}}',
      label: 'user first name',
    },
    {
      value: '{{user.last_name}}',
      label: 'user last name',
    },
    {
      value: '{{user.name}}',
      label: 'user full name',
    },
    {
      value: '{{organisation.name}}',
      label: 'organisation name',
    },
  ]

  return (
    <div
      aria-haspopup='true'
      aria-expanded={expanded}
      aria-label='rdw-link'
      className='rdw-link-wrapper'
    >
      <div
        onClick={expandCollapse}
        className='rdw-option-wrapper markdown-toolbar-option'
      >
        <img src={variable} alt='@' />
      </div>
      {expanded ? renderModal() : undefined}
    </div>
  )
}
