import React, { useEffect, useState } from "react";
import collIcon from "../../../assets/images/caret-right-fill.svg";
import expandIcon from "../../../assets/images/caret-down-fill.svg";
import threeDot from "../../../assets/images/Threedots.svg";
import GroupUpdater from "./GroupUpdater";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useListDropdownHandler from "../../../utils/useListDropdownHandler";

function KnowledgeDynamicList({
  data,
  editId,
  setEditId,
  selectedModule,
  setSelectedModule,
  setAddSelectId,
  addSelectId,
}: any) {
  const [expandedItems, setExpandedItems] = useListDropdownHandler(data, selectedModule)
  const { t } = useTranslation("translation");

  const toggleExpand = (itemId: string) => {
    if (expandedItems.includes(itemId)) {
      setExpandedItems(expandedItems.filter((id) => id !== itemId));
    } else {
      setExpandedItems([...expandedItems, itemId]);
    }
  };

  const newListExpandAddHandler = (newItemId:string)=>{
    setExpandedItems([...expandedItems, newItemId]);
  }


  // Recursive function to render the nested list
  const RenderList = (item: any) => {
    const isExpanded = expandedItems.includes(item._id);

    return (
      <>
        <div key={item._id}>
          <div
            className="font-16 flex align-items-center justify-content-between  Relative knowlage-list"
            style={{
              paddingLeft: `${item.depth + 1}rem`,
              backgroundColor: `${
                item._id === selectedModule ? "#E6EDFF" : ""
              } `,
            }}
            onClick={() => setSelectedModule(item._id)}
          >
            {item.name}
            <div className={`action-add ${selectedModule===item._id && 'show-action'} `}>
              <Dropdown>
                <Dropdown.Toggle id="dropdown">
                  <img
                    className="add-action-img"
                    src={threeDot}
                    alt="threedot"
                  />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      setEditId(item._id);
                      setAddSelectId("");
                    }}
                  >
                    {t("Add New Group")}{" "}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {item.children.length > 0 ? (
                <img
                  className="show-more-arrow"
                  src={isExpanded ? expandIcon : collIcon}
                  alt="collapse/expand"
                  onClick={() => toggleExpand(item._id)}
                />
              ) : (
                <div className="emptyspce"></div>
              )}
              {addSelectId === item._id && (
                <button
                  onClick={() => {
                    setEditId(item._id);
                    setAddSelectId("");
                  }}
                >
                {t("Add New Group")}  
                </button>
              )}
            </div>
          </div>

          {editId === item._id && (
            <GroupUpdater
              editId={editId}
              setEditId={setEditId}
              depth={item.depth === 0 ? 0.5 : item.depth}
              setSelectedModule={setSelectedModule}
              newListExpandAddHandler= {newListExpandAddHandler}
            />
          )}
          {item.children.length > 0 && isExpanded && (
            <div>{item.children.map((child: any) => RenderList(child))}</div>
        )}
        </div>
      </>
    );
  };

  return (
    <div>
      {data.map((item: any) => {
        return <div>{RenderList(item)}</div>;
      })}
    </div>
  );
}

export default KnowledgeDynamicList;
