import * as _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Button, Container, Image } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import backblue from "../../../assets/images/arrow-left.svg";

import { useTranslation } from "react-i18next";
import Pagination from "../../../components/UI/Pagination/Pagination";
import QuestionListSkeleton from "../../../components/UI/Skeletons/QuestionListSkeleton/QuestionListSkeletons";
import { ChartTableSortIcon } from "../../Knowledge/AllKnowledge";
import { customStyles, getColumns } from "./utils";
import Loading from "../../../components/UI/Loading/Loading";
import {
  getAllDocuments,
  getAllDocumentsNewFlow,
} from "../../../store/knowledge/actions";

export const CreateQuizFromDocument = (props: any) => {
  const { t } = useTranslation("translation");
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [documents, setAllDocuments] = useState([]);
  const [pagination, setPagination] = useState(null);
  const tableWrapperRef = useRef(null);
  const [scrollRight, setScrollRight] = useState(0);

  useEffect(() => {
    fun();
    // Table wrapper reference to check scroll left value of an element
    if (
      tableWrapperRef &&
      tableWrapperRef.current &&
      tableWrapperRef.current.children[0]
    ) {
      setScrollRight(tableWrapperRef.current.children[0].scrollLeft);
    }

    return () => {
      setScrollRight(0);
    };
  }, [perPage, currentPage]);

  const fun = async () => {
    setLoading(true);
    try {
      const { documents, pagination }: any = await dispatch(
        getAllDocumentsNewFlow({
          page: currentPage,
          page_size: perPage,
        })
      );
      setAllDocuments(documents);
      setPagination(pagination);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  /**
   *
   * @param page Pagination on change of page
   */
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };
  /**
   * Used to paginate data based on per page and page number
   * @param newPerPage
   * @param page
   */
  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
  };

  const documentHandler = (documentId: string) => {
    history.push({
      pathname: "/quiz-questions/" + documentId,
      state: { quizId: props.location.state.quizId, type: "existing" },
    });
  }

  // if (loading) {
  //   return <Loading />;
  // }

  return (
    <React.Fragment>
      {/* ------------------------------ Knowledge from scratch ( Edit Mode ) -------------------------------------- */}
      <section className="page-mid-wraper h-without-foter">
        <Container>
          <div className="row">
            <div className="col-md-12">
              <button
                className="btn p-0 btn-back"
                onClick={() => {
                  history.goBack();
                }}
              >
                <Image src={backblue} /> {t("Return to previous page")}
              </button>
              <h1>
                {t("Create Quiz from an uploaded Document")}
              </h1>
              <h2 className="font-weight-normal mb-3">
                {t("Select from uploaded Documents")}
              </h2>
              <div className="uploaded-doc-table" ref={tableWrapperRef}>
                <DataTable
                  className="table-wraper border-0"
                  noContextMenu
                  noHeader
                  fixedHeader
                  fixedHeaderScrollHeight="370px"
                  columns={getColumns({
                    documentHandler
                  })}
                  data={documents}
                  customStyles={customStyles}
                  sortIcon={<ChartTableSortIcon />}
                  paginationPerPage={perPage}
                  paginationDefaultPage={1}
                  paginationResetDefaultPage={false}
                  paginationTotalRows={totalRows}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  progressPending={loading} //
                  progressComponent={
                    <div style={{ padding: 10, width: "100%" }}>
                      <QuestionListSkeleton count={12} />
                    </div>
                  }
                  defaultSortAsc={true}
                  // sortFunction={customSort}
                  // onSort={onSortHandler}
                  // conditionalRowStyles={conditionalRowStyles}
                />
                {/* -----------------  Pagination   --------------------------- */}
                {pagination && (
                  <Pagination
                    scrollWrapper="table-wraper"
                    scrollRightValue={scrollRight}
                    hideSuggestedQuestion={true}
                    showScrollButtons={false}
                    currentPage={currentPage}
                    pagination={pagination}
                    paginationPerPage={perPage}
                    pageChange={handlePerRowsChange}
                    onChangePage={handlePageChange}
                  />
                )}
              </div>
            </div>
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default CreateQuizFromDocument;
