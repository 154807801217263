import React, { useState, useEffect} from 'react'
// import DateFnsUtils from "@date-io/date-fns";
import { addDays, subDays} from 'date-fns'
import { DateRangePicker } from 'react-date-range'
import moment from 'moment';
import closeImg from '../../../assets/images/close-red.svg';
import checkImg from '../../../assets/images/check-green.svg';

import { useTranslation } from "react-i18next";

interface Props {
  hasDatePickerValueChanges?: boolean;
  dateValue?: {
    startDate?: any;
    endDate?: any;
  };
  onDateChange?: (e: any) => void;
  onCloseDateRangeHandler?: () => void;
}

const DateRangePickerComponent = ({
  hasDatePickerValueChanges,
  dateValue,
  onDateChange,
  onCloseDateRangeHandler
}: Props) => {
  const { t } = useTranslation('translation');
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    }
  ])

  useEffect(() => {
    if ( dateValue ) {
      setState([
        {
          startDate: dateValue && dateValue.startDate ? dateValue.startDate : new Date(),
          endDate: dateValue && dateValue.endDate ? dateValue.endDate : new Date(),
          key: 'selection',
        }
      ])
    }
  }, [])

  const changeHandler = (item: any) => {
    setState([
      {
        startDate: item.selection.startDate,
        endDate: item.selection.endDate,
        key: 'selection',
      }
    ])
   
  }

  const sendDateRangeHandler = () => {
    if ( onDateChange && state && state.length ) {
      onDateChange({
        filter_from_date: moment(state[0].startDate).format('YYYY-MM-DD'),
        filter_to_date: moment(state[0].endDate).format('YYYY-MM-DD')
      });
      onCloseDateRangeHandler();
    }
  }

  return (
    <>
    <div className="cstm-date-wraper"> 
      <div className="d-flex align-items-center mb-3">
        <span className="mr-auto pl-3">{t("Custom Date Range")}</span>
        <button className="btn">
          <img src={checkImg} alt = "Check" onClick={sendDateRangeHandler} />
        </button>
        <button className="btn">
          <img src={closeImg} alt = "Close" onClick={onCloseDateRangeHandler} />
        </button>
      </div>
      <DateRangePicker
        onChange={changeHandler}
        moveRangeOnFirstSelection={false}
        months={2}
        ranges={state}
        direction="horizontal"
        maxDate={new Date()}
      />
      </div>
    </>
  );
}

export default DateRangePickerComponent
