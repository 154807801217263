import React from "react";

import "./style.scss";

const ButtonToggle = ({ label, buttons, onChange, value }: any) => {
  return (
    <div className="button-toggle-wrapper">
      {label && <label className="button-toggle-label">{label}</label>}
      <div className="button-toggle-switch">
        {buttons.map((button: any) => (
          <button
            className={`button-toggle${
              value === button.value ? " active" : ""
            }`}
            onClick={() => onChange(button.value)}
          >
            {button.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default ButtonToggle;
