export const sunConfig = [
  ["undo", "redo"],
  // ["formatBlock"],
  [
    ":t-More Text-default.more_text",
    "bold",
    "underline",
    "italic",
    "strike",
    "subscript",
    "superscript",
  ],
  ["fontColor", "hiliteColor"],
  ["align", "horizontalRule", "list", "lineHeight"],
  // ['removeFormat'],
  ["outdent", "indent"],
  ["blockquote"],
  ["table", "link", "image" /**"video"**/ /** 'math' */], // You must add the 'katex' library at options to use the 'math' plugin.
  ["fullScreen", "preview"],
  // ['save', 'template']
];

export const sunIcons = {
  bold: '<i class="editor-icons icon-bold" />',
  underline: '<i class="editor-icons icon-underline" />',
  italic: '<i class="editor-icons icon-italic" />',
  strike: '<i class="editor-icons icon-strike" />',
  subscript: '<i class="editor-icons icon-subscript" />',
  superscript: '<i class="editor-icons icon-superscript" />',
  outdent: '<i class="editor-icons icon-outdent" />',
  indent: '<i class="editor-icons icon-indent" />',
  table: '<i class="editor-icons icon-table" />',
  link: '<i class="editor-icons icon-link" />',
  image: '<i class="editor-icons icon-image" />',
  video: '<i class="editor-icons icon-video" />',
  blockquote: '<i class="editor-icons icon-quote" />',
  fullScreen: '<i class="editor-icons icon-fullscreen" />',
  preview: '<i class="editor-icons icon-view" />',
  undo: '<i class="editor-icons icon-undo" />',
  redo: '<i class="editor-icons icon-redo" />',
  lineHeight: '<i class="editor-icons icon-line-spacing" />',
  list: '<i class="editor-icons icon-number-list />',
  fontColor: '<i class="editor-icons icon-text-color />',
  hiliteColor: '<i class="editor-icons icon-highlight />',
};
