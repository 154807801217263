import axios from 'axios';

type Type = any;
const windowObj: any = window;
export default class AuthService {

  async getTokenFromOneAuth(code: any): Promise<Type> {
    const res = await axios({
      url: windowObj.REACT_APP_ONE_AUTH_DOMAIN + "/oauth/token",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: {
        client_id: windowObj.REACT_APP_ONE_AUTH_CLIENTID,
        grant_type: "authorization_code",
        code,
        redirect_uri: window.location.origin + windowObj.SUB_DOMAIN + "/",
      }
    });
    return res;
  }

}
