import React from 'react'
import { Image } from 'react-bootstrap'
import unauthorized from '../../../assets/images/unauthorized.svg'
import { logoutUser } from '../../../store/auth/actions';
import { useDispatch } from 'react-redux';

import { useTranslation } from "react-i18next";

type Props = {}

type State = {}

export const AccessDenied = (props: Props) => {
  const { t } = useTranslation('translation');
  const dispatch = useDispatch();
  const logoutHandler = () => {
    dispatch(logoutUser());
  }
  return (
    <>
      <section className='access-denied-wrap'>
        <div className='row no-gutters h-100'>
          <div className='col-md-6 col-content-right d-flex align-items-center flex-wrap'>
            <div>
              <h1 className='mb-24'>{t("Access Denied :(")}</h1>
              <div className='font-1'>{t("We are sorry!")}</div>
              <div className='font-1 mb-32'>
                {t("You do not have permission to view this page.")}
              </div>
              <div className='font-1 mb-32'>{t("Please contact the administrator to request the access.")}</div>
              <button className='btn btn-primary mt-4' onClick={logoutHandler}>{t("Logout")}</button>
            </div>
          </div>

          <div className='col-md-6 col-img-left d-flex align-items-center'>
            <div className='position-relative'>
              <Image src={unauthorized} />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default AccessDenied
