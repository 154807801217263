import {
  Button,
  Modal,
  ModalFooter,
  Tooltip,
  Form,
} from "react-bootstrap";
import ModalBody from "react-bootstrap/ModalBody";
import {useTranslation} from "react-i18next";
import {FormData} from '../IntegrationType'
import IntegrationModalHeader from '../Modal/IntegrationModalHeader'
import {useState} from "react";
import TextInput from "../../../components/UI/OldFormBuilder/TextInput";
import Dropdown from "../../../components/UI/OldFormBuilder/Dropdown";

type LtiSetup = {
  integrationType: string;
  closeModal: () => void;
  integrateSpecificAgent: boolean;
  setTntegrateSpecificAgent: (value: boolean) => void;
  renderCopy: (text: string) => JSX.Element;
  data: LtiSetupData;
  handleIntegration: () => Promise<void>;
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
  allAgents: any[];
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>, name: string) => void;
}

type LtiSetupData = {
  redirect_uri: string,
  target_link_uri: string,
  openid_connect_initiation_url: string,
}

const MoodleLtiModal = (props: LtiSetup) => {
  const {t} = useTranslation("translation");
  const [showsInstructionsModal, setShowsInstructionsModal] = useState(true);
  const [showsTestConnectModal, setShowsTestConnectModal] = useState(false);
  const closeModal = () => {
    setShowsInstructionsModal(false);
    setShowsTestConnectModal(false);
    props.closeModal();
  };
  const toolName = "Walter AI";

  return (
      <>
        <Modal
            className="modal-width"
            aria-labelledby="contained-modal-title-vcenter"
            show={showsInstructionsModal}
            onHide={() => {
            }}
            centered
        >
          <IntegrationModalHeader
              modalTitle={t("Enable Walter AI Tutor in Moodle")}
              closeModal={ closeModal }
          />

          <ModalBody className="integration-modal-body">
            <div className="tw-flex  tw-gap-4 canvas-manual-integration">
              <div className="tw-px-4 tw-py-4 w-90 left-content-wrap">
                <h2 className="mb-2">{t("Step 1: Set up external tool in Moodle")}</h2>
                <p className="mb-0 subhEad">{t("To set up Walter AI as an external tool in Moodle, please do the following")}:</p>
                <ol>
                  <li>{t("Log in to Moodle as an admin")}.</li>
                  <li>{t("Click on")} <strong>Site administration</strong> {t("link on navigation")}.</li>
                  <li>{t("Site administration page, choose tab")} <strong>Plugins</strong>, {t("then click on")} <strong>External tool &gt; Manage tools</strong>.</li>
                  <li>{t("Within the Add tool box, click on the link")} <strong>configure a tool manually</strong>.</li>
                  <li>
                    {t("Fill in the fields to configure the external tool")}
                    <p className="mb-0 subhEad">{t("Under section")} <strong>Tool settings</strong>:</p>
                    <ul>
                      <li>
                        <span className="label">Tool name: </span>
                        {props.renderCopy(toolName)}
                      </li>
                      <li>
                        <span className="label">Tool URL: </span>
                        {props.renderCopy(props?.data?.target_link_uri)}
                      </li>
                      <li>
                        <span className="label">Tool description: </span>
                        {props.renderCopy("Noodle Factory - Walter AI tools")}
                      </li>
                      <li>
                        <span className="label">LTI version: </span>
                        <em>LTI 1.3</em>
                      </li>
                      <li>
                        <span className="label">Public key type: </span>
                        <em>Keyset URL</em>
                      </li>
                      <li>
                        <span className="label">Public keyset: </span>
                        {props.renderCopy("https://oneauth.noodlefactory.ai/auth/.well-known/jwks.json")}
                      </li>
                      <li>
                        <span className="label">Initiate login URL: </span>
                        {props.renderCopy(props?.data?.openid_connect_initiation_url)}
                      </li>
                      <li>
                        <span className="label">Redirection URI(s): </span>
                        {props.renderCopy(props?.data?.redirect_uri)}
                      </li>
                      <li>
                        <span className="label">Custom parameters: </span>
                        {t("Leave it empty")}
                      </li>
                      <li>
                        <span className="label">Tool configuration usage: </span>
                        <em>Show in activity chooser and as a preconfigured tool</em>
                      </li>
                      <li>
                        <span className="label">Default launch container: </span>
                        <em>Embed, without blocks</em>
                      </li>
                    </ul>

                    <p className="mb-0 subhEad">{t("Under section")} <strong>Privacy</strong>, {t("select the following options")}:</p>
                    <ul>
                    <li>
                        <span className="label">Share launcher’s name with tool:</span> <em>Always</em>
                      </li>
                      <li>
                        <span className="label">Share launcher’s email with tool:</span> <em>Always</em>
                      </li>
                    </ul>
                  </li>

                  <li>{t("Click on")} <strong>Save changes</strong>.</li>
                  <li>{t("Go back to")} <strong>Manage tools</strong>, {t("find the newly added tool and click on View configuration details icon, then fill in the form on the right the following details:")}.
                    <ul>
                      <li><em>Moodle URL</em>: {t("This is the URL of your Moodle LMS instance")}</li>
                      <li><em>Client ID</em>: {t("Get the value from Tool configuration details popup in Moodle")}</li>
                      <li><em>Deployment ID</em>: {t("Get the value from Tool configuration details popup in Moodle")}</li>
                    </ul>
                  </li>
                </ol>
              </div>


              <div className="whit-box tw-px-4 right-content-wrap w-10">
                <div>
                  <h2 className="mb-2">{t("Required fields for Noodle Factory")}</h2>
                  <p className="mb-0">{t("As you complete each step, please fill up following values to set up the integration on Noodle Factory")}</p>
                  <form>
                    <div className="flex-one mt-2">
                      <h3 className="mb-2">{t("Moodle URL")}</h3>
                      <TextInput
                          inputType="text"
                          placeholder={t("Moodle URL")}
                          autoFocus={false}
                          id="client-id-1"
                          value={props.formData?.LMS_URL}
                          inputChange={(e, name) => props.handleInputChange(e, name)}
                          max={undefined}
                          min={undefined}
                          tabIndex={undefined}
                          label=""
                          description=""
                          inputClass=""
                          name="LMS_URL"
                          disabled={false}
                          onKeyPress={() => {
                          }}
                          useTextArea={false}
                          errorMessage="" ref={undefined} onPaste={undefined} onFocus={undefined} onBlur={undefined}
                          onKeyUp={undefined} spellCheck={undefined} required={undefined} showError={undefined}/>
                    </div>
                    <div className="flex-one mt-2">
                      <h3 className="mb-2">{t("Client ID")}</h3>
                      <TextInput
                          inputType="text"
                          placeholder={t("Client ID")}
                          autoFocus={false}
                          id="client-id-2"
                          value={props.formData?.Client_ID}
                          inputChange={(e, name) => props.handleInputChange(e, name)}
                          max={undefined}
                          min={undefined}
                          tabIndex={undefined}
                          label=""
                          description=""
                          inputClass=""
                          name="Client_ID"
                          disabled={false}
                          onKeyPress={() => {
                          }}
                          useTextArea={false}
                          errorMessage="" ref={undefined} onPaste={undefined} onFocus={undefined} onBlur={undefined}
                          onKeyUp={undefined} spellCheck={undefined} required={undefined} showError={undefined}/>
                    </div>
                    <div className="flex-one mt-2">
                      <h3 className="mb-2">Deployment ID</h3>
                      <TextInput
                          inputType="text"
                          placeholder="Deployment ID"
                          autoFocus={false}
                          id="client-id-3"
                          value={props.formData?.Deployment_ID}
                          inputChange={(e, name) => props.handleInputChange(e, name)}
                          max={undefined}
                          min={undefined}
                          tabIndex={undefined}
                          label=""
                          description=""
                          inputClass=""
                          name="Deployment_ID"
                          disabled={false}
                          onKeyPress={() => {
                          }}
                          useTextArea={false}
                          errorMessage="" ref={undefined} onPaste={undefined} onFocus={undefined} onBlur={undefined}
                          onKeyUp={undefined} spellCheck={undefined} required={undefined} showError={undefined}/>
                    </div>
                  </form>

                  </div>
                    <div className="fOrm-box">
                      <div className="tOggle-box">
                        <div className="text mt-2"><h3>{t("Integrate to Specific Agent")}</h3></div>
                        <button className="icon-button"><Form.Check // prettier-ignore
                          className="custom-toggle font-weight-bold font-14 text-nowrap mt-1"
                          type="switch"
                          id="toggle-skip-summary"
                            // label="Skip Summary Review"
                          defaultChecked={props.integrateSpecificAgent}
                          onChange={() => props.setTntegrateSpecificAgent(!props.integrateSpecificAgent)}
                          value={String(props.integrateSpecificAgent)}
                        /></button>
                      </div>
                      {props.integrateSpecificAgent &&
                        <div className="flex-one mt-2">
                          <Dropdown
                            placeHolder={`${t("Select Agent")}`}
                            label=""
                            defaultValue={undefined}
                            description=""
                            dropdownClassNamePrefix=""
                            isClearable={false}
                            isSearchable={false}
                            data={props.allAgents}
                            dropdownContainerClassName=""
                            onChange={(data: { value: string }) => props.setFormData({
                              ...props.formData,
                              Agent_ID: data?.value
                            })}
                            isMandatory={false}
                            value={undefined}
                            isDisabled={false}
                            menuPosition="bottom"
                            isMulti={undefined}
                            customStyles={undefined}
                            isNested={undefined}
                            onInputChange={() => {
                            }}
                            isCreatable={undefined}
                            onCreateOption={undefined}
                            menuIsOpen={undefined}
                          />
                        </div>
                      }
                      <p>{t("Only enable this if you are using a single agent for all courses")}</p>
                    </div>
                </div>

            </div>
          </ModalBody>
          < ModalFooter>
            <Button
                variant="outline-primary"
                onClick={closeModal}
            >
              {t("Cancel")}
            </Button>
            <Button
                variant="primary"
                disabled={!props.formData.LMS_URL || !props.formData.Client_ID || !props.formData.Deployment_ID}
                onClick={() => {
                  props.handleIntegration();
                  setShowsInstructionsModal(false);
                  setShowsTestConnectModal(true);
                }}
            >
              {t("Next Step")}
            </Button>
          </ModalFooter>
        </Modal>


        <Modal
            className="modal-width max-content"
            aria-labelledby="contained-modal-title-vcenter"
            show={showsTestConnectModal}
            onHide={() => {
            }}
            centered
        >
          <IntegrationModalHeader
              modalTitle={t("Enable Walter AI Tutor in Moodle")}
              closeModal={props?.closeModal}
          />
          <ModalBody className="integration-modal-body">
            <div className="tw-flex tw-gap-4 canvas-manual-integration">
              <div className="tw-px-4 tw-py-4 w-100">
                <h2 className="mb-2">{t("Step 2: Enable Walter AI for teachers in a course")}</h2>
                <p className="mb-0 subhEad">{t("After previous steps, Walter AI is enabled as an external tool in Moodle. Teacher can easily enable it in a course with the following steps")}:</p>
                <ol>
                  <li>{t("Log in Moodle as a teacher.")}</li>
                  <li>{t("Go to a course and turn on editing mode.")}</li>
                  <li>{t("Add an activity to where you want to place the tool")}</li>
                  <li>{t("In the Add an activity or resource popup, choose")} <em>{toolName}</em></li>
                  <li>
                    {t("In the Adding a new External tool form, fill in the fields:")}
                    <ul>
                      <li>{t("Under")} <strong>General &gt; Activity name</strong>: {props.renderCopy("Walter AI Tutor")} ({t("or type in another name for the activity")})
                      </li>
                      <li>{t("Under")} <strong>Common module settings &gt; Availability</strong>: <em>Hide on course page</em></li>
                    </ul>
                  </li>
                  <li>{t("Click on")} <strong>Save and return to course</strong></li>
                </ol>

                <h2 className="mb-2">{t("Step 3: Enable Walter AI for students")}</h2>
                <p className="mb-0 subhEad">{t("Once teachers complete setting up knowledge for Walter AI Tutor, teachers can enable it for students to start using it")}:</p>
                <ol>
                  <li>{t("Log in Moodle as a teacher.")}</li>
                  <li>{t("Go to a course and turn on editing mode.")}</li>
                  <li>{t("Click on the three dots icon beside the tool added in previous step, then choose")} <strong>Edit settings</strong></li>
                  <li>{t("Under")} <strong>Common module settings &gt; Availability</strong>, select <em>Show on course page</em></li>
                  <li>{t("Click on")} <strong>Save and return to course</strong></li>
                  <li>{t("The tool will be visible to students to use")}</li>
                </ol>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
                variant="primary"
                onClick={closeModal}
            >
              {t("Complete")}
            </Button>
          </ModalFooter>
        </Modal>
      </>
  );
};
export default MoodleLtiModal;
