import React, {useState } from "react";
import { useTranslation } from "react-i18next";
import {  Accordion } from "react-bootstrap";
import Collapse from "../../assets/images/collapse-qna.svg";
import Expand from "../../assets/images/expand-qna.svg";
import "./styles.scss";

export const QnA = ({ q }: any) => {
  const { t } = useTranslation("translation");
  const [isExpand, setIsExpand] = useState(true);

  return (
    <Accordion className="w-100 qna-container">
      <Accordion.Toggle
        onClick={() => setIsExpand(!isExpand)}
        eventKey="0"
        className="w-100 acc-question d-flex justify-content-between"
      >
        {t(q?.q)}
        {isExpand ? (
          <img width="14" src={Expand} alt="" />
        ) : (
          <img width="14" src={Collapse} alt="" />
        )}
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="0">{q.a()}</Accordion.Collapse>
    </Accordion>
  );
};

export default QnA;
