import { AppStateType } from "../rootReducer";

export const getAuthSate = (state: AppStateType) =>
  state.auth;
export const getToken = (state: AppStateType) =>
  state.auth.token;
export const IsLoggedIn = (state: AppStateType) =>
  state.auth.isLoggedIn;
export const IsLoggedProcessing = (state: AppStateType) =>
  state.auth.loginProcessStarted;
