import { AnyARecord } from "dns";
import React from "react";

import "./style.scss";

const IconLabelButton = ({
  showIcon,
  iconClass,
  buttonLabel,
  onClick,
  useDownload,
  fileSrc,
  isNegative,
  buttonClass,
  title,
  isDisabled,
}: any) => {
  return (
    <button
      disabled={isDisabled}
      title={title}
      onClick={onClick}
      className={`${
        buttonClass ? `${buttonClass} ` : ""
      }custom-icon-label-button${isNegative ? " custom-icon-label-red" : ""}`}
    >
      {showIcon && (
        <i className={`custom-icon-label-button-icon ${iconClass}`} />
      )}
      {!useDownload && !fileSrc && buttonLabel}
      {useDownload && (
        <a className="download-link" href={fileSrc} download>
          {buttonLabel}
        </a>
      )}
      {!useDownload && fileSrc && (
        <a className="download-link" href={fileSrc} target="_blank">
          {buttonLabel}
        </a>
      )}
    </button>
  );
};

export default IconLabelButton;
