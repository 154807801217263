/* eslint-disable jsx-a11y/anchor-is-valid */
import download from 'downloadjs'
import * as htmlToImage from 'html-to-image'
import * as _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  DashboardFilters
} from '../../../../components/UI/Dashboard'
import { getTopUsers } from '../../../../store/dashboard/actions'
import '../../index.scss'
import DashboardNavigation from '../DashboardNavigation'
import QuizSubmissions from '../partials/QuizSubmissions/QuizSubmissions'
import QuizSummary from '../partials/QuizSummary/QuizSummary'

import { useTranslation } from "react-i18next";

const DashboardQuizzes = (props: any) => {
  const { t } = useTranslation('translation');
  const dispatch = useDispatch()
  const [chartDownloading, setChartDownloading] = useState(false)
  const [filtersData, setFiltersData] = useState({
    // type: 'daily',
    filter_from_date: null,
    filter_to_date: null,
    channel: null,
  })
  const [loading, setLoading] = useState(false)
  const [topUsersData, setTopUsersData] = useState([])

  const hasFilterUpdated = async (e: any) => {
    await setFiltersData(e)
  }

  useEffect(() => {
    if (
      loading ||
      !(filtersData.filter_from_date || filtersData.filter_to_date)
    ) {
      return
    }

    fun()
  }, [filtersData])

  const fun = async () => {
    await setLoading(true)
    try {
      const topUsersData: any = await dispatch(getTopUsers(filtersData, 1, 100000))
      const avgUsageDuration: any = []
      topUsersData.rows.forEach((d: any) => {
        avgUsageDuration.push({
          x: moment(new Date(d.last_active)).format('YYYY-MM-DD'),
          y: d.average_duration.toFixed(2),
        })
      })
      await setTopUsersData(_.orderBy(avgUsageDuration, ['x'], ['asc']))
      await setLoading(false)
    } catch (e: any) {
      await setLoading(false)
    }
  }

  const onDownloadChart = async () => {
    await setChartDownloading(true)
    htmlToImage
      .toJpeg(document.getElementById('avg-usage-duration'))
      .then(async function (dataUrl) {
        await download(dataUrl, 'avg-usage-duration.png')
        await setChartDownloading(false)
      })
      .catch(async (e: any) => {
        await setChartDownloading(false)
      })
  }

  return (
    <section className='dashborad-wraper'>
      <div className='page-mid-wraper'>
        {/*--------- top-navigation -----------*/}
        <DashboardFilters hasFilterUpdated={hasFilterUpdated} />
        <DashboardNavigation />

        {/*-------- welcome text ------------*/}
        <div className='welcome-wrap welcome-subtext d-flex flex-md-nowrap flex-wrap mb-4'>
          <h2>{t("Quizzes")}</h2>
        </div>

        <QuizSubmissions filtersData={filtersData} />
        <QuizSummary filtersData={filtersData} />
      </div>
    </section>
  )
}
export default DashboardQuizzes
