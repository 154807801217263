import React, {
  useState,
  useEffect,
  useRef,
  TextareaHTMLAttributes,
} from 'react'

const AutoTextArea = (props: any) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const [text, setText] = useState(null)
  //   const [textAreaHeight, setTextAreaHeight] = useState('auto')
  //   const [parentHeight, setParentHeight] = useState('auto')
  useEffect(() => {
    setTimeout(() => {
      if ( textAreaRef && textAreaRef.current ) {
        setText(textAreaRef ? textAreaRef.current.defaultValue + ' ' : '')
        // textAreaRef.current.checkValidity();
        // console.log('textAreaRef.current', textAreaRef.current.id)
      }
    }, 400)
  }, [])

  useEffect(() => {
    // Will keep it for furthur use
    // setParentHeight(`${textAreaRef.current!.scrollHeight}px`)
    // setTextAreaHeight(`${textAreaRef.current!.scrollHeight}px`)
    textAreaRef.current.style.overflow = 'hidden'
    textAreaRef.current.style.resize = 'none'
    textAreaRef.current.style.height = '30px'
    textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 'px'
  }, [ props ])

  const onChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    // setTextAreaHeight('auto')
    // setParentHeight(`${textAreaRef.current!.scrollHeight}px`)
    setText(event.target.value)
    props.onChangeHandler(event)
  }

  const onBlurHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    // setTextAreaHeight('auto')
    // setParentHeight(`${textAreaRef.current!.scrollHeight}px`)
    setText(event.target.value + 'akki')
  }

  return (
    <textarea
      {...props}
      ref={textAreaRef}
      onChange={onChangeHandler}
      // onBlur={onBlurHandler}
      // onFocus={props.onFocus}
    />
  )
}

export default AutoTextArea
