import React from "react";
import {
  Modal,
  ModalTitle,
  Button,
  ModalBody,
  ModalFooter,
  Image,
} from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import closeImg from "../../../../assets/New-images/build-knowledge-close-img.svg";
import { useTranslation } from "react-i18next";

const GenerateKnowledgePopup = (props: any) => {
  const { t } = useTranslation("translation");
  return (
    <React.Fragment>
      <Modal show={props.show} centered>
        <ModalHeader className="clr-black pt-4 px-3 justify-content-between">
          <ModalTitle id="contained-modal-title-vcenter">
            <h2 className="mb-0 font-1">
              {t("Proceed without Saving Questions")}
            </h2>
          </ModalTitle>
          <button className="btn" onClick={props.onHide}>
            <img src={closeImg} />
          </button>
        </ModalHeader>
        <ModalBody>
          <div className="font-600">
           
              "Your question(s) have not been saved. If you proceed without saving, your unsaved questions <span className="clr-red">will be deleted</span>. Do you want to continue?"
            
          </div>
        </ModalBody>
        <ModalFooter className="justify-content-center border-top-0">
          <button className="btn-outline-primary btn mx-2 font-600 py-1" onClick={props.onProceed}>
            {t("Yes")}
          </button>
          <button className="btn-primary btn mx-2 font-600 py-1" onClick={props.onHide}>
            {t("Cancel")}
          </button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default GenerateKnowledgePopup;
