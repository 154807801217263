import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import IconLabelButton from "../IconLabelButton";
import TextInput from "../TextInput";
const MatchEditor = ({ data, onChange, headerValues, onHeaderChange, viewOnly= false }: any) => {
  const { t } = useTranslation();
  const [editorData, setEditorData] = useState([]);
  const [tableHeader, setTableHeader] = useState(
    headerValues ? headerValues : ["", ""]
  );

  useEffect(() => {
    if (data) {
      setEditorData(data);
    } else {
      let temp = [];
      temp.push({
        term: "",
        definition: "",
      });
      setEditorData(temp);
    }
  }, []);

  useEffect(() => {
    onChange(editorData);
  }, [editorData]);

  useEffect(() => {
    onHeaderChange(tableHeader);
  }, [tableHeader]);

  const onHeaderCellChange = (value: any, headerIndex: any) => {
    let temp = [...tableHeader];
    temp[headerIndex] = value;
    setTableHeader(temp);
  };

  const onRowAdd = () => {
    let temp = [...editorData];
    temp.push({
      term: "",
      definition: "",
    });

    setEditorData(temp);
  };

  const onFieldChange = (value: any, rowIndex: any, type: any) => {
    let temp = [...editorData];
    temp[rowIndex][type] = value;
    setEditorData(temp);
  };

  const onDelete = (rowIndex: any) => {
    let temp = [...editorData];
    temp.splice(rowIndex, 1);
    setEditorData(temp);
  };

  return (
    <div className="match-editor-wrapper">
      <div className="match-editor">
        <div className="match-row flex-container">
          {tableHeader &&
            tableHeader.map((headerName: any, index: any) => (
              <div className="match-header-wrapper">
                <TextInput
                  disabled={viewOnly}
                  id={`header${index.toString()}`}
                  key={`header${index.toString()}`}
                  value={headerName}
                  inputChange={(event: any) =>
                    onHeaderCellChange(event.target.value, index)
                  }
                  // onInputClick={onInputClick}
                  placeholder={t("Type header text here")}
                />
              </div>
            ))}
        </div>
        {editorData.map((item, rowIndex) => (
          <div key={rowIndex.toString()} className="match-row flex-container">
            <div className="match-input-wrapper">
              <TextInput
                disabled={viewOnly}
                useTextArea
                placeholder={t("Type Text here")}
                value={item.term}
                inputChange={(event: any) =>
                  onFieldChange(event.target.value, rowIndex, "term")
                }
              />
            </div>
            <div className="match-input-wrapper">
              <TextInput
                disabled={viewOnly}
                useTextArea
                placeholder={t("Type Text here")}
                value={item.definition}
                inputChange={(event: any) =>
                  onFieldChange(event.target.value, rowIndex, "definition")
                }
              />
            </div>
            {editorData.length > 1 && !viewOnly && (
              <button
                className="match-row-delete"
                onClick={() => onDelete(rowIndex)}
              >
                <i className="icon-delete" />
              </button>
            )}
          </div>
        ))}
      </div>
      {!viewOnly && 
        <IconLabelButton onClick={onRowAdd} buttonLabel="Add row" />
      }
      
    </div>
  );
};

export default MatchEditor;
