import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ButtonsGroup from '../../../../components/UI/ButtonsGroup/ButtonsGroup'
import { ButtonsOptions } from '../../../../services/interfaces/button-options.interface'
import { localUserQuestionUpdate } from '../../../../store/questions/actions'
import {
    getButtonOptions,
    getIntent
} from '../../../../store/questions/selector'

import { useTranslation } from "react-i18next";

interface Props {}

export const ButtonsTab = (props: any) => {
  const { t } = useTranslation('translation');
  const dispatch = useDispatch()
  const intent = useSelector(getIntent)
  const buttonOptions = useSelector(getButtonOptions)
  const [buttonsList, setButtonOptionsList] = useState([])

  useEffect(() => {
    setButtonOptionsList((intent && intent?.buttons) || [])
  }, [intent])

  const localUserQuestionUpdateHandler = (
    type: string,
    buttons: ButtonsOptions[]
  ) => {
    dispatch(localUserQuestionUpdate(type, buttons))
  }

  return (
    <>
      <div className='sugested-next-ques'>
        <h3 className='mb-3'>
          {t("Add buttons to suggest next question(s) to the user")}
        </h3>
        <ButtonsGroup
          buttonOptions={[...buttonOptions]}
          buttons={buttonsList ? [...buttonsList] : []}
          localUserQuestionUpdate={localUserQuestionUpdateHandler}
          isActivityDetected={props.isActivityDetected}
        />
      </div>
    </>
  )
}

export default ButtonsTab
