import React, { ReactElement, useEffect, useState } from 'react'
import SemiCircleProgressBar from 'react-progressbar-semicircle'
import { useDispatch } from 'react-redux'
import { MATCHED_UNMATCHED_QUESTIONS } from '../../../../config/index'
import { useHistory } from 'react-router';
import {
  getRatingsSummary
} from '../../../../store/dashboard/actions'
import InfoIconDetails from '../InfoIconDetails/InfoIconDetails';

import { useTranslation } from "react-i18next";

interface Props {
  filtersData?: {
    filter_from_date?: string
    filter_to_date?: string
    channel?: string
  }
}

function ActiveUsers({ filtersData }: Props): ReactElement {
  const { t } = useTranslation('translation');
  const [loading, setLoading] = useState(false)
  const [ratingsData, setRatingsData] = useState(null)
  const dispatch = useDispatch()
  const history = useHistory()
  useEffect(() => {
    // console.log('[ Ratings filtersData ]', filtersData)
    if (
      loading ||
      !filtersData.filter_from_date ||
      !filtersData.filter_to_date
    ) {
      return
    }

    const fetchRatingsData = async (filtersData: any) => {
      setLoading(true)
      try {
        const ratingsData: any = await dispatch(getRatingsSummary(filtersData))
        await setRatingsData(ratingsData)
        setLoading(false)
        // console.log(
        //   'src_components_UI_Dashboard_Ratings_Ratings.tsx',
        //   ratingsData
        // )
      } catch (e: any) {
        setLoading(false)
        console.log(
          '[[[ src_components_UI_Dashboard_ActiveUsers_ActiveUsers.tsx ]]]',
          e
        )
      }
    }
    fetchRatingsData(filtersData)
  }, [filtersData])

  return (
    <div className='col-sm-6 col-md-4 col-lg-2' onClick={() => {
      history.push(MATCHED_UNMATCHED_QUESTIONS)
    }}>
      <div className='graph-box'>
        <h3>{t("Avg Rating")}<InfoIconDetails
            message={t('Average of the ratings given by the learners to the chatbot.')}
          /></h3>
        <div className='row'>
          <div className='col-4'>
            {!loading && (
              <h2>
                {ratingsData &&
                ratingsData.ratingSummary &&
                ratingsData.ratingSummary.summary.rating_average
                  ? ratingsData?.ratingSummary?.summary?.rating_average.toFixed(
                      2
                    )
                  : 0}
              </h2>
            )}
            {/* {baseDataLoading && (
                    <div className='spinner-border spinner-border-sm text-primary'></div>
                  )} */}
            {/* <div className='growth-percent growth-down'>14%</div> */}
          </div>
          <div className='col-8 text-right'>
            <div style={{ width: '100%', height: '50px' }}>
              {ratingsData &&
              ratingsData.ratingSummary &&
              ratingsData.ratingSummary.summary.rating_average ? (
                <SemiCircleProgressBar
                  percentage={((ratingsData.ratingSummary.summary.rating_average.toFixed(2)/ 5) * 100).toFixed(0)}
                  stroke='#0992E6'
                  diameter='85'
                  strokeWidth='12'
                  showPercentValue={false}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ActiveUsers
