import { combineReducers } from "redux";

import GreetingReducer from "./greetings/reducer";
import KnowledgeReducer from "./knowledge/reducer";
import QuestionReducer from "./questions/reducer";
import AuthReducer from './auth/reducer';
import CommonReducer from "./common/reducer";
import FallbackReducer from "./fallback/reducer";
import Dashboardreducer from "./dashboard/reducer";
import ConversationFlowReducer from "./conversation/reducer";
import NotificationsReducer from "./notifications/reducer";
import QuizReducer from "./quizzes/reducer";

export const rootReducer = combineReducers({
  greeting: GreetingReducer,
  fallback: FallbackReducer,
  dashboard: Dashboardreducer,
  knowledge: KnowledgeReducer,
  question: QuestionReducer,
  auth: AuthReducer,
  flow: ConversationFlowReducer,
  common: CommonReducer,
  notifications: NotificationsReducer,
  quiz: QuizReducer,
});
export type RootReducerType = typeof rootReducer;
export type AppStateType = ReturnType<RootReducerType>;