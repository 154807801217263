import React, { useEffect, useState } from "react";
import "../../TeachersToolkit/index";
// import { TOOLKIT_GENERATE_QNA_LEARNING_OUTCOMES } from "../../../../../config";
// import ToolKitProgress from "../../../../../../Shared/ToolKitProgress";
import { useTranslation } from "react-i18next";
import ToolKitProgress from "../../TeachersToolkit/pages/Shared/ToolKitProgress";
import { BUILD_FINAL, LEARNING_OUTCOMES } from "../../../config";
import { useLocation } from "react-router";

export const BuildKnowledgeProcessDocument = (props: any) => {
  const { t } = useTranslation("translation");
  const useLocatio = useLocation();
  const query = new URLSearchParams(useLocatio.search);
  const type = query.get("type");

  return (
    <ToolKitProgress
      redirectUrl={type === "automated" ? BUILD_FINAL : LEARNING_OUTCOMES}
      progressStatement={
        type === "automated"
          ? t(
              "Sit back and relax, Let us take care of it. We'll automatically set up your AI assistant for you"
            )
          : t("Preparing document(s) to generate knowledge")
      }
      waitText={t(
        "Please wait while we setup your AI assistant. This can take up to 10 minutes. "
      )}
      notificationText={t(
        "You will receive a notification when the knowledge has been generated."
      )}
      progressHeading={
        type === "automated"
          ? t("Automated Setup")
          : t("Processing document(s)")
      }
      buildKnowledge
    />
  );
};

export default BuildKnowledgeProcessDocument;
