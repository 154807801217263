import { useEffect, useState } from "react";
import { Container, Image, Spinner } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import backblue from "../../assets/images/arrow-left.svg";
import arrowUp from "../../assets/New-images/chevron-up-blue.svg";
import arrowDown from "../../assets/New-images/chevron-down-blue.svg";
import greenCheck from "../../assets/images/single-green-check.svg";
import closeimg from "../../assets/New-images/close-icon-red.svg";
import AddKeyPhraseModal from "./AddKeyPhraseModal";
import Moment from "react-moment";
import downarrow from "../../assets/New-images/button-down-grey.svg";
import uparrow from "../../assets/New-images/button-right-grey.svg";
import editIcon from "../../assets/New-images/edit-pencil.svg";
import trash from "../../assets/New-images/trash.svg";
import QuestionListSkeleton from "../../components/UI/Skeletons/QuestionListSkeleton/QuestionListSkeletons";
import { customStyles, expandedDocumentStyles } from "./KeyPhraseUtils";
import ConversationFlowService from "../../services/ConversationFlowService";
import { KeyPhrase, LinkedDocument } from "../../types/conversation";
import AddDocumentModal from "./AddDocumentModal";

type CustomMap = {
  [key: string]: any;
};

const ManageKeyPhrase = (props: any) => {
  const { t } = useTranslation("translation");
  const history = useHistory();
  const [showKeyPhraseModal, setShowKeyPhraseModal] = useState(false);
  const [allTags, setAllTags] = useState([]);
  const [pending, setPending] = useState(true);
  const [keyPhrases, setKeyPhrases] = useState<KeyPhrase[]>([]);
  const service = new ConversationFlowService();
  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const [currentKeyPhrase, setCurrentKeyPhrase] = useState<KeyPhrase>();
  const [deletingMap, setDeletingMap] = useState<CustomMap>({});
  const [upLoadingMap, setUpLoadingMap] = useState<CustomMap>({});
  const [downLoadingMap, setDownLoadingMap] = useState<CustomMap>({});
  const [keyPhraseFilter, setKeyPhraseFilter] = useState("");
  const [editStatusMap, setEditStatusMap] = useState<CustomMap>({});
  const [editingStatusMap, setEditingStatusMap] = useState<CustomMap>({});
  const [deletingStatusMap, setDeletingStatusMap] = useState<CustomMap>({});
  const [editValueMap, setEditValueMap] = useState<CustomMap>({});

  const sortDocumentsByPriority = (
    document_meta: LinkedDocument[],
    document_priority: string[]
  ) => {
    return document_meta.sort((a, b) => {
      return (
        document_priority.indexOf(a._id) - document_priority.indexOf(b._id)
      );
    });
  };

  useEffect(() => {
    service.getDocumentRelevanceTune().then(({ data }) => {
      if (Array.isArray(data.data)) {
        data.data = data.data.map((keyPhrase) => ({
          ...keyPhrase,
          document_meta: sortDocumentsByPriority(
            keyPhrase.document_meta,
            keyPhrase.document_priority
          ),
        }));
        setKeyPhrases(data.data);
        setPending(false);
      } else {
        setPending(false);
      }
    });
  }, []);

  const confirmedTagsHandler = async (e: any, tags: any[]) => {
    service
      .createDocumentRelevanceTune(
        tags.map((tag) => ({ keyphrase: tag.label }))
      )
      .then(({ data }) => {
        setKeyPhrases([...keyPhrases, ...data.data]);
        e.preventDefault();
        setShowKeyPhraseModal(false);

        if (tags.length === 1) {
          setCurrentKeyPhrase(data.data[0]);
          setShowDocumentModal(true);
        }
      });
  };

  const addDocumentsToKeyPhrase = (selectedDocuments: any) => {
    service
      .updateDocumentRelevanceTune(currentKeyPhrase.relevance_tune_id, {
        keyphrase: currentKeyPhrase.keyphrase,
        document_priority: selectedDocuments.map((sel: any) => sel._id),
      })
      .then(({ data }) => {
        setKeyPhrases(
          keyPhrases.map((key) =>
            key.relevance_tune_id === currentKeyPhrase.relevance_tune_id
              ? data.data
              : key
          )
        );
        setShowDocumentModal(false);
      });
  };

  const onDeleteKeyPhrase = (data: KeyPhrase) => {
    deletingStatusMap[data.relevance_tune_id] = true;
    setDeletingStatusMap({
      ...deletingStatusMap,
    });

    service
      .deleteDocumentRelevanceTune(data.relevance_tune_id)
      .then(() => {
        setKeyPhrases(
          keyPhrases.filter(
            (key) => key.relevance_tune_id !== data.relevance_tune_id
          )
        );

        deletingStatusMap[data.relevance_tune_id] = true;
        setDeletingStatusMap({
          ...deletingStatusMap,
        });
      })
      .catch(() => {
        deletingStatusMap[data.relevance_tune_id] = true;
        setDeletingStatusMap({
          ...deletingStatusMap,
        });
      });
  };

  const onUpdateKeyPhrase = (data: KeyPhrase) => {
    editingStatusMap[data.relevance_tune_id] = true;
    setEditingStatusMap({
      ...editingStatusMap,
    });

    service
      .updateDocumentRelevanceTune(data.relevance_tune_id, {
        keyphrase: editValueMap[data.relevance_tune_id],
        document_priority: data.document_priority,
      })
      .then(() => {
        editingStatusMap[data.relevance_tune_id] = false;
        setEditingStatusMap({
          ...editingStatusMap,
        });

        editStatusMap[data.relevance_tune_id] = false;
        setEditStatusMap({ ...editStatusMap });

        setKeyPhrases((prev) => {
          return prev.map((t) =>
            t.relevance_tune_id === data.relevance_tune_id
              ? {
                  ...t,
                  keyphrase: editValueMap[data.relevance_tune_id],
                }
              : t
          );
        });
      })
      .catch(() => {
        editingStatusMap[data.relevance_tune_id] = false;
        setEditingStatusMap({
          ...editingStatusMap,
        });
      });
  };

  const onDeleteDocument = (data: KeyPhrase, id: string) => {
    const documents = data.document_meta.filter((doc) => doc._id !== id);
    setDeletingMap((prevMap) => {
      prevMap[`${data.relevance_tune_id}${id}`] = true;
      return { ...prevMap };
    });

    service
      .updateDocumentRelevanceTune(data.relevance_tune_id, {
        keyphrase: data.keyphrase,
        document_priority: documents.map((doc) => doc._id),
      })
      .then(() => {
        setKeyPhrases(
          keyPhrases.map((key) =>
            key.relevance_tune_id === data.relevance_tune_id
              ? {
                  ...key,
                  document_meta: documents,
                }
              : key
          )
        );
        setDeletingMap((prevMap) => {
          prevMap[`${data.relevance_tune_id}${id}`] = false;
          return { ...prevMap };
        });
      })
      .catch(() => {
        setDeletingMap((prevMap) => {
          prevMap[`${data.relevance_tune_id}${id}`] = false;
          return { ...prevMap };
        });
      });
  };

  const onMoveUpDocument = (data: KeyPhrase, id: string) => {
    const index = data.document_meta.findIndex((doc) => doc._id === id);
    if (index === 0) return;
    const tempData = { ...data, document_meta: [...data.document_meta] };
    [tempData.document_meta[index - 1], tempData.document_meta[index]] = [
      tempData.document_meta[index],
      tempData.document_meta[index - 1],
    ];

    setUpLoadingMap((prevMap) => {
      prevMap[`${data.relevance_tune_id}${id}`] = true;
      return { ...prevMap };
    });

    service
      .updateDocumentRelevanceTune(data.relevance_tune_id, {
        keyphrase: tempData.keyphrase,
        document_priority: tempData.document_meta.map((doc) => doc._id),
      })
      .then(() => {
        setKeyPhrases(
          keyPhrases.map((key) =>
            key.relevance_tune_id === data.relevance_tune_id
              ? {
                  ...key,
                  document_meta: tempData.document_meta,
                }
              : key
          )
        );
        setUpLoadingMap((prevMap) => {
          prevMap[`${data.relevance_tune_id}${id}`] = false;
          return { ...prevMap };
        });
      })
      .catch(() => {
        setUpLoadingMap((prevMap) => {
          prevMap[`${data.relevance_tune_id}${id}`] = false;
          return { ...prevMap };
        });
      });
  };

  const onMoveDownDocument = (data: KeyPhrase, id: string) => {
    const index = data.document_meta.findIndex((doc) => doc._id === id);
    if (index === data.document_meta.length - 1) return;
    const tempData = { ...data, document_meta: [...data.document_meta] };
    [tempData.document_meta[index + 1], tempData.document_meta[index]] = [
      tempData.document_meta[index],
      tempData.document_meta[index + 1],
    ];

    setDownLoadingMap((prevMap) => {
      prevMap[`${data.relevance_tune_id}${id}`] = true;
      return { ...prevMap };
    });

    service
      .updateDocumentRelevanceTune(data.relevance_tune_id, {
        keyphrase: tempData.keyphrase,
        document_priority: tempData.document_meta.map((doc) => doc._id),
      })
      .then(() => {
        setKeyPhrases(
          keyPhrases.map((key) =>
            key.relevance_tune_id === data.relevance_tune_id
              ? {
                  ...key,
                  document_meta: tempData.document_meta,
                }
              : key
          )
        );
        setDownLoadingMap((prevMap) => {
          prevMap[`${data.relevance_tune_id}${id}`] = false;
          return { ...prevMap };
        });
      })
      .catch(() => {
        setDownLoadingMap((prevMap) => {
          prevMap[`${data.relevance_tune_id}${id}`] = false;
          return { ...prevMap };
        });
      });
  };

  const ExpandedComponent = ({ data }: any) => {
    return (
      <>
        <DataTable
          className="submissions-data"
          noContextMenu
          noHeader
          noDataComponent={() => <></>}
          columns={[
            {
              name: t("Priority"),
              selector: "keyphrase",
              format: (row: LinkedDocument, index: number) => {
                return `${index + 1}.`;
              },
            },
            {
              name: t("Linked Document(s)"),
              selector: "name",
              sortable: true,
              grow: 2,
            },
            {
              name: t("Actions"),
              selector: "name",
              sortable: true,
              format: (row: LinkedDocument, index: number, ...props: any) => {
                return (
                  <div className="d-flex align-items-center">
                    {!upLoadingMap[`${data.relevance_tune_id}${row._id}`] ===
                    true ? (
                      <button
                        className="btn"
                        onClick={() => onMoveUpDocument(data, row._id)}
                      >
                        <Image src={arrowUp} alt="arrow up" />
                      </button>
                    ) : (
                      <div className="spinner-container">
                        <Spinner animation="border" size="sm" />
                      </div>
                    )}
                    {!downLoadingMap[`${data.relevance_tune_id}${row._id}`] ===
                    true ? (
                      <button
                        className="btn"
                        onClick={() => onMoveDownDocument(data, row._id)}
                      >
                        <Image src={arrowDown} alt="arrow down" />
                      </button>
                    ) : (
                      <div className="spinner-container">
                        <Spinner animation="border" size="sm" />
                      </div>
                    )}
                    {deletingMap[`${data.relevance_tune_id}${row._id}`] ===
                    true ? (
                      <div className="spinner-container">
                        <Spinner animation="border" size="sm" />
                      </div>
                    ) : (
                      <button
                        className="btn"
                        onClick={() => onDeleteDocument(data, row._id)}
                      >
                        <Image src={trash} alt="trash" />
                      </button>
                    )}
                  </div>
                );
              },
            },
          ]}
          data={data.document_meta}
          customStyles={expandedDocumentStyles}
        />
        <div className="d-flex justify-content-center">
          <button
            className="float-end btn btn-link add-page"
            onClick={() => {
              setCurrentKeyPhrase(data);
              setShowDocumentModal(true);
            }}
          >
            + {t("Add Document(s)")}
          </button>
        </div>
      </>
    );
  };

  return (
    <section className="page-mid-wraper h-without-foter position-relative">
      <div className="row">
        <div className="col-md-12">
          <button
            className="btn p-0 btn-back"
            onClick={() => {
              history.goBack();
            }}
          >
            <Image src={backblue} /> {t("Return to previous page")}
          </button>

          <h1 className="mb-2">{t("Manage Key Phrases")}</h1>
          <h2 className="mb-32 font-weight-normal">
            {t(
              "Tune your chatbot's responses by defining key phrases and linking relevant document(s) to prioritise for contextualised chat"
            )}
          </h2>
        </div>
      </div>
      <div className="row mt-6 w-full d-flex justify-content-between">
        <div className="col-md-3 web-links">
          <input
            type="text"
            className="form-control h-auto search-keyphrase-input"
            placeholder={t("Search Key Phrases")}
            value={keyPhraseFilter}
            onChange={(e) => setKeyPhraseFilter(e.currentTarget.value)}
          />
        </div>
        <button
          className="float-end btn btn-link add-page"
          onClick={() => setShowKeyPhraseModal(true)}
        >
          {t("+ Add Key Phrase")}
        </button>
      </div>
      <div className="row mt-4">
        <div className="col-md-12">
          <DataTable
            noHeader
            className="all-submissions"
            columns={[
              {
                name: t("Key Phrase"),
                selector: "keyphrase",
                sortable: true,
                grow: 2,
                format: (row: KeyPhrase) => {
                  return (
                    <div className="d-flex flex-row align-items-center justify-content-start h-full w-full">
                      {editStatusMap[row.relevance_tune_id] === true ? (
                        <input
                          style={{ flex: 1 }}
                          value={editValueMap[row.relevance_tune_id]}
                          onChange={(e) => {
                            editValueMap[row.relevance_tune_id] =
                              e.currentTarget.value;
                            setEditValueMap({ ...editValueMap });
                          }}
                          autoFocus
                        />
                      ) : (
                        <div>{row.keyphrase}</div>
                      )}
                    </div>
                  );
                },
                wrap: false,
              },
              {
                name: t("Linked Document(s)"),
                selector: "keyphrase",
                sortable: true,
                grow: 2,
                format: (row: KeyPhrase) => {
                  if (row.document_meta.length === 0) return <div> - </div>;
                  return (
                    <div className="d-flex flex-column" style={{ gap: 4 }}>
                      {row.document_meta.map((doc, index) => (
                        <div>{doc.name}</div>
                      ))}
                    </div>
                  );
                },
              },
              {
                name: t("Actions"),
                selector: "keyphrase",
                sortable: true,
                wrap: true,
                format: (row: any, index: any) => {
                  return (
                    <div className="d-flex align-items-center">
                      {editStatusMap[row.relevance_tune_id] === true ? (
                        <>
                          {editingStatusMap[row.relevance_tune_id] ===
                          true ? (
                            <div className="spinner-container">
                              <Spinner animation="border" size="sm" />
                            </div>
                          ) : (
                            <button
                              className="btn"
                              onClick={() => {
                                onUpdateKeyPhrase(row);
                              }}
                            >
                              <img src={greenCheck} alt="" width={16} />
                            </button>
                          )}

                          <button
                            className="btn"
                            onClick={() => {
                              editingStatusMap[row.relevance_tune_id] = false;
                              setEditingStatusMap({ ...editingStatusMap });

                              editStatusMap[row.relevance_tune_id] = false;
                              setEditStatusMap({ ...editStatusMap });
                            }}
                          >
                            <img src={closeimg} alt="" width={14} />
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            className="btn"
                            onClick={() => {
                              editStatusMap[row.relevance_tune_id] = true;
                              editValueMap[row.relevance_tune_id] =
                                row.keyphrase;
                              setEditStatusMap({ ...editStatusMap });
                            }}
                          >
                            <img src={editIcon} alt="" />
                          </button>

                          {deletingStatusMap[row.relevance_tune_id] ===
                          true ? (
                            <div className="spinner-container">
                              <Spinner animation="border" size="sm" />
                            </div>
                          ) : (
                            <button
                              className="btn"
                              onClick={() => {
                                onDeleteKeyPhrase(row);
                              }}
                            >
                              <img src={trash} alt="" />
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  );
                },
                minWidth: "165px",
              },
            ]}
            data={keyPhrases.filter(
              (keyPhrase) =>
                keyPhrase.keyphrase
                  .toLowerCase()
                  .indexOf(keyPhraseFilter.toLocaleLowerCase()) !== -1
            )}
            customStyles={customStyles}
            expandableRows={true}
            expandableRowsComponent={<ExpandedComponent />}
            expandableIcon={{
              collapsed: <img src={uparrow} alt={t("collapsed")} />,
              expanded: <img src={downarrow} alt={t("expanded")} />,
            }}
            progressPending={pending}
            progressComponent={
              <div style={{ padding: 10, width: "100%" }}>
                <QuestionListSkeleton count={12} />
              </div>
            }
            noContextMenu
            fixedHeader
            fixedHeaderScrollHeight="500px"
          />
        </div>
      </div>

      <AddKeyPhraseModal
        show={showKeyPhraseModal}
        onHide={() => setShowKeyPhraseModal(false)}
        confirmhandler={confirmedTagsHandler}
        selectedTags={allTags}
      />
      <AddDocumentModal
        show={showDocumentModal}
        selectedDocuments={currentKeyPhrase?.document_meta}
        onHide={() => setShowDocumentModal(false)}
        onAdd={(selectedDocuments: any) =>
          addDocumentsToKeyPhrase(selectedDocuments)
        }
      />
    </section>
  );
};

export default ManageKeyPhrase;
