import React, { useState } from 'react'
import { Container, Image } from 'react-bootstrap'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getMetaBaseData } from '../../../../store/dashboard/actions'
import Loading from '../../../../components/UI/Loading/Loading'
import DashboardNavigation from '../DashboardNavigation';

import { useTranslation } from "react-i18next";

function ContentMetaBase() {
  const { t } = useTranslation('translation');
  const [embedLink, setEmbedLink] = useState()
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  useEffect(() => {
    const fun = async () => {
      try {
        setLoading(true)
        const data: any = await dispatch(getMetaBaseData('content'))
        setEmbedLink(data.embedded_dashboard.url)
        setLoading(false)
      } catch (error: any) {
        setLoading(false)
      }
    }
    fun()
    return () => {}
  }, [])

  if (loading) {
    return <Loading />
  }

  return (
    <section className='dashborad-wraper'>
      <div className='container h-100'>
        <DashboardNavigation />
        <div className='mt-4 iframe-wraper'>
          <iframe
              id='myframe'
              src={embedLink}
              title={t('Forms')}
              className='test-bot-iframe'
          ></iframe>
          {/* <div className='row h-100'>
            <div className='col-md-12 h-100'>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  )
}

export default ContentMetaBase
