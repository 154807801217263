import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { useDispatch } from "react-redux";
import closeimg from "../../../../assets/images/close-red.svg";
import search from "../../../../assets/images/search-blk.svg";
import AutoTextArea from "../../../../components/UI/AutoResizeTextArea/AutoResizeTextArea";
import PermissionWrapper from "../../../../components/UI/PermissionWrapper/PermissionWrapper";
import { RichTextArea } from "../../../../components/UI/RichTextArea/RichTextArea";
import QuestionListSkeleton from "../../../../components/UI/Skeletons/QuestionListSkeleton/QuestionListSkeletons";
import {
  deleteQuestionFromDocument,
  generateCustomQuestionAnswer,
  loadMoreQuestionsAndAnswersBasedOnDocument,
  updateQnasSortOrder,
  updateQuestionFromDocument,
} from "../../../../store/knowledge/actions";
import { convertDataToMarkdownFormat } from "../../../../utils/appUtils";

import baricon from "../../../../assets/New-images/Icon-drag.svg";
import closeImg from "../../../../assets/New-images/cross-red.svg";
import editImg from "../../../../assets/New-images/edit-pencil.svg";
import { Container, Draggable } from "react-smooth-dnd";
import classNames from "classnames";

import { useTranslation } from "react-i18next";
import * as _ from 'lodash';

interface Props {
  agent_id?: string;
  author_id?: string;
  created_at?: string;
  file_id?: string;
  file_name?: string;
  keywords?: string[];
  name?: string;
  organisation_id?: string;
  qas?: [];
  updated_at?: string;
  _id?: string;
  index?: number;
  section?: any;
  documentLength?: number;
  last_offset?: number;
  retryExecution?: boolean;
  qnasLoading?: boolean;
  questionsLoading?: boolean;
  qasFetchError?: boolean;
  isQuizView?: boolean;
  isSummarised?: boolean;
  setSelectedQuestions?: (questions: any) => void;
  updatedAnswersHandler?: (answers: any) => void;
  setAnswerGenerationProcessing?: (value: boolean) => void;
  setAnswerData?: (answerData: any) => void;
}

const QAnsAList = (props: Props) => {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const [lastOffset, setLastOffset] = useState(0);
  const [Qnas, setQnas] = useState<any[]>(props.qas);
  const [executionStarted, setExecutionStarted] = useState(false);
  const [InternalRef, setInternalRef] = useState(null);
  const [qnasLoading, setQnasLoading] = useState(true);
  const [qnAFetchError, setQnAFetchError] = useState(false);
  const [addNewQuestionAnswer, setAddNewQuestionAnswer] = useState({
    question: "",
    answer: "",
    sort_order: 0,
    show: false,
  });
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [query, setQuery] = useState<any>({
    document_id: "",
    question: "",
    answer: "",
    sort_order: 0,
    _id: "",
  });
  const [isTyping, setTyping] = useState(false);

  useEffect(() => {
    if (
      props.qas &&
      props.qas.length > 0 &&
      !props.retryExecution &&
      !executionStarted
    ) {
      setQnasLoading(false);
    }
    setQnas(props.qas);
  }, [props.qas]);

  useEffect(() => {
    if ( selectedNodes.length  && props.setSelectedQuestions ) {
      let questions: any = [];
      selectedNodes.forEach((node: any) => {
        const question: any = Qnas.find(qn => qn._id === node);
        if ( question ) {
          questions.push( question )
        }
      })
      props.setSelectedQuestions([ ...questions ])
    }
  }, [ selectedNodes ]);

  useEffect(() => {
    return () => {
      if (InternalRef) {
        clearInterval(InternalRef);
      }
    };
  }, []);

  useEffect(() => {
    setLastOffset(props.last_offset ? props.last_offset : lastOffset);
  }, [props.last_offset]);

  useEffect(() => {
    setQnAFetchError(props.qasFetchError);
  }, [props.qasFetchError]);

  useEffect(() => {
    if (props.retryExecution && !executionStarted) {
      const inititateInterval = async (props: any) => {
        let intervalCount = 0;
        let lastOffset = props.last_offset;
        let fetchedQas = [...props.qas];
        let intervalRef = setInterval(async () => {
          intervalCount = intervalCount + 1;
          await setExecutionStarted(true);
          if (intervalCount === 5) {
            clearInterval(intervalRef);
            setQnasLoading(false);

            if (props.setAnswerGenerationProcessing) {
              props.setAnswerGenerationProcessing(false);
            }

            // dispatch(updateDocumentAfterTimeout(props))
            props.updatedAnswersHandler({
              qas: fetchedQas,
              qasFetchError: fetchedQas.length === 0,
              documentId: props._id,
              last_offset: lastOffset,
            });
            return;
          }
          try {
            setQnasLoading(true);
            // console.log(
            //   'INTERVAL startded',
            //   fetchedQas,
            //   'intervalCount',
            //   intervalCount
            // )

            if (props.setAnswerGenerationProcessing) {
              props.setAnswerGenerationProcessing(true);
            }

            const lastOffsetFetched: any = await dispatch(
              loadMoreQuestionsAndAnswersBasedOnDocument(
                props,
                lastOffset,
                10,
                props.section
              )
            );

            fetchedQas = [...fetchedQas.concat(lastOffsetFetched.qas)];
            setQnas(fetchedQas);
            props.updatedAnswersHandler({
              ...lastOffsetFetched,
              qas: fetchedQas,
            });
            setQnAFetchError(lastOffsetFetched.qasFetchError);
            lastOffset = lastOffsetFetched.last_offset;
            setLastOffset(lastOffset);
            if (lastOffsetFetched.questionsLength > 0) {
              setQnasLoading(false);
              clearInterval(intervalRef);
            }
          } catch (error: any) {
            console.log("INTERVAL error", error);
            props.updatedAnswersHandler(error);
            setQnasLoading(false);
            setQnAFetchError(true);
            clearInterval(intervalRef);
          }
          // console.log(`[ CALLED IN every 5 seconds ] :::>>> ${props._id}`, oldCount)
        }, 10000);
        setInternalRef(intervalRef);
      };
      inititateInterval(props);
    }
    if (props.retryExecution) {
      props.updatedAnswersHandler({
        ...props,
        documentId: props._id,
        qas: [...Qnas],
      });
    }
  }, [props.retryExecution]);

  const tryLoadingMoreQnasHandler = async (e: any) => {
    e.preventDefault();
    // offset is 0 and limit is 10 because we want to fetch data from starting point
    try {
      setQnasLoading(true);
      const moreQnasFromDocument: any = await dispatch(
        loadMoreQuestionsAndAnswersBasedOnDocument(props, 0, 10, props.section)
      );
      setQnasLoading(false);
      const fetchedQnas = Qnas.concat(moreQnasFromDocument.qas);
      setQnas([...fetchedQnas]);
      setQnAFetchError(moreQnasFromDocument.qasFetchError);
      setLastOffset(moreQnasFromDocument.last_offset);
      props.updatedAnswersHandler({
        ...moreQnasFromDocument,
        qas: fetchedQnas,
      });
    } catch (e) {
      setQnasLoading(false);
      setQnas([]);
      setQnAFetchError(true);
      props.updatedAnswersHandler(e);
    }
  };

  const loadMoreQnasHandler = async (e: any) => {
    e.preventDefault();
    try {
      setQnasLoading(true);
      const moreQnasFromDocument: any = await dispatch(
        loadMoreQuestionsAndAnswersBasedOnDocument(
          props,
          lastOffset,
          10,
          props.section
        )
      );
      setQnasLoading(false);
      const fetchedQnas = Qnas.concat(moreQnasFromDocument.qas);
      setQnas([...fetchedQnas]);
      setQnAFetchError(moreQnasFromDocument.qasFetchError);
      setLastOffset(moreQnasFromDocument.last_offset);
      props.updatedAnswersHandler({
        ...moreQnasFromDocument,
        qas: fetchedQnas,
      });
    } catch (e) {
      setQnasLoading(false);
      setQnas([]);
      setQnAFetchError(true);
      props.updatedAnswersHandler(e);
    }
  };

  const removeKeywordFromList = (index: number) => {
    const oldAnswers = [...props.qas];
    const splicedData: any = oldAnswers[index];
    oldAnswers.splice(index, 1);
    props.updatedAnswersHandler({
      documentId: props._id,
      qas: oldAnswers,
    });

    // Only delete if id is present otherwise let it be
    if (splicedData._id) {
      dispatch(deleteQuestionFromDocument(props._id, splicedData._id));
    }

    // dispatch({
    //   type: type.LOCAL_ANSWWER_UPDATE,
    //   payload: {
    //     documentId: props._id,
    //     qas: oldAnswers,
    //   },
    // })
  };

  useEffect(() => {
    // Added 500 as debounce time will wait for 500 miliseconds and then we will fetch intents based on query
    const delayDebounceFn = setTimeout(() => {
      if (isTyping) {
        if (query._id) {
          dispatch(
            updateQuestionFromDocument(props._id, query._id, {
              question: query.question,
              answer: convertDataToMarkdownFormat(query.answer),
            })
          );
        }
        setTyping(false);
      }
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [query.answer, query.question]);

  const dataChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const index = +e.target.dataset.index;
    const toUpdateValue = e.target.dataset.value;
    const oldAnswers: any[] = [...props.qas];
    const oldQuestionValue = { ...oldAnswers[index] };
    oldAnswers[index][toUpdateValue] = value;
    props.updatedAnswersHandler({
      documentId: props._id,
      qas: oldAnswers,
    });
    if (oldQuestionValue.question !== e.target.value) {
      // setTyping(true);
      // setQuery({
      //   ...query,
      //   ...oldAnswers[index],
      // });
    }
    // dispatch({
    //   type: type.LOCAL_ANSWWER_UPDATE,
    //   payload: {
    //     documentId: props._id,
    //     qas: oldAnswers,
    //   },
    // })
  };

  const onEditorStateChange = (index: number, name: any) => {
    const value = name;
    const toUpdateValue = "answer";
    const oldAnswers: any[] = [...props.qas];
    const oldAnswerValue = oldAnswers[index];
    const convertedData = convertDataToMarkdownFormat(oldAnswerValue.answer);
    const convertedName = convertDataToMarkdownFormat(name);
    oldAnswers[index][toUpdateValue] = value;
    props.updatedAnswersHandler({
      documentId: props._id,
      qas: oldAnswers,
    });
    if (convertedName !== convertedData) {
      setTyping(true);
      setQuery({
        ...query,
        ...oldAnswers[index],
      });
    }
    // dispatch({
    //   type: type.LOCAL_ANSWWER_UPDATE,
    //   payload: {
    //     documentId: props._id,
    //     qas: oldAnswers,
    //   },
    // })
  };

  const startEditQuestionHandler = (index: number) => {
    const oldAnswers: any[] = [...props.qas];
    const oldAnswerValue = oldAnswers[index];
    oldAnswers[index]["hasEditMode"] = oldAnswerValue.hasOwnProperty(
      "hasEditMode"
    )
      ? !oldAnswerValue.hasEditMode
      : true;
    props.updatedAnswersHandler({
      documentId: props._id,
      qas: oldAnswers,
    });
    setQnas([...oldAnswers]);
    // dispatch({
    //   type: type.LOCAL_ANSWWER_UPDATE,
    //   payload: {
    //     documentId: props._id,
    //     qas: oldAnswers,
    //   },
    // })
  };

  const saveQuestionHandler = (question: any, index: number) => {
    try {
      dispatch(
        updateQuestionFromDocument(props._id, question._id, {
          question: question.question,
          answer: convertDataToMarkdownFormat(question.answer),
        })
      );
      startEditQuestionHandler(index);
    } catch (error) {
      console.log("[ error ]", error);
    }
  };

  const previewToggleHandler = async (index?: number) => {
    if (index >= 0) {
      const oldAnswers: any[] = [...props.qas];
      props.setAnswerData({
        organisation_id: props.organisation_id,
        question: oldAnswers[index].question,
        _id: props._id,
        keyword: convertDataToMarkdownFormat(oldAnswers[index].answer),
        text_block_id: oldAnswers[index].text_block_id
      });
    }
  };

  const addMoreQuestionHandler = (e: any) => {
    e.preventDefault();
    setAddNewQuestionAnswer({
      ...addNewQuestionAnswer,
      show: true,
    });
    
    const oldAnswers: any[] = [...Qnas];
    oldAnswers.unshift({
      question: addNewQuestionAnswer.question,
      answer: addNewQuestionAnswer.answer,
      sort_order: 0,
      newlyAdded: true,
      hasEditMode: true,
    });
    props.updatedAnswersHandler({
      documentId: props._id,
      qas: oldAnswers,
    });
  };

  const generateCustomQuestionAndAnswerHandler = async (index: number) => {
    try {
      if (props.setAnswerGenerationProcessing) {
        props.setAnswerGenerationProcessing(true);
      }
      const oldAnswers: any[] = [...Qnas];
      const questionAnswer = oldAnswers[index];
      const response: any = await dispatch(
        generateCustomQuestionAnswer(props._id, questionAnswer.question)
      );
      delete questionAnswer.newlyAdded;
      questionAnswer._id = response._id;
      questionAnswer.answer = convertDataToMarkdownFormat(response.answer.trim().length ? response.answer : 'We are unable to fetch answer based on your question. Please insert manually.');
      props.updatedAnswersHandler({
        documentId: props._id,
        qas: oldAnswers,
      });
      if (props.setAnswerGenerationProcessing) {
        props.setAnswerGenerationProcessing(false);
      }
    } catch (error) {
      if (props.setAnswerGenerationProcessing) {
        props.setAnswerGenerationProcessing(false);
      }
    }
  };

  // const updateMoreQuestionHandler = (key: string, value: string) => {
  //   setAddNewQuestionAnswer({
  //     ...addNewQuestionAnswer,
  //     [key]: value
  //   })
  //   const oldAnswers: any[] = [...Qnas]
  //   oldAnswers[0] = {...{
  //     [key]: value,
  //     sort_order: 0,
  //   }}
  //   props.updatedAnswersHandler({
  //     documentId: props._id,
  //     qas: oldAnswers
  //   })
  // }

  const applyDrag = (arr: any, dragResult: any) => {
    const updatedArray: any[] = [...arr];
    const { removedIndex, addedIndex, payload } = dragResult;
    const addedSortOrder = arr[addedIndex].sort_order;
    const removedSortOrder = arr[removedIndex].sort_order;
    updatedArray[removedIndex].sort_order = addedSortOrder;
    updatedArray[addedIndex].sort_order = removedSortOrder;

    if (removedIndex === null && addedIndex === null) return updatedArray;

    const result = [...updatedArray];
    let itemToAdd = payload;
    if (removedIndex !== null) {
      itemToAdd = result.splice(removedIndex, 1)[0];
    }

    if (addedIndex !== null) {
      result.splice(addedIndex, 0, itemToAdd);
    }

    if (result && result.length > 0) {
      const toUpdateQna: any[] = [];
      const sort_order = result[0].sort_order;
      result.map((res: any, index: number) => {
        toUpdateQna.push({
          _id: res._id,
          sort_order: sort_order + (index + 1),
        });
        return {
          ...res,
          sort_order: sort_order + (index + 1),
        };
      });

      dispatch(updateQnasSortOrder(props._id, toUpdateQna));
    }

    props.updatedAnswersHandler({
      documentId: props._id,
      qas: [...result],
    });
    setQnas([...result]);
    return result;
  };

  const addToSelectedRows = (event: any, id: string) => {
    let data: any[] = [...selectedNodes];
    if (event.target.checked) {
      const isPresent = data.indexOf(id);
      if (isPresent < 0) {
        data.push(id);
      }
    } else {
      const oldData = [...selectedNodes];
      const index = oldData.indexOf(id);
      oldData.splice(index, 1);
      data = [...oldData];
    }
    setSelectedNodes([..._.uniq(data)]);
  };

  const onSelectAllRows = (event: any) => {
    if (event.target.checked) {
      if (Qnas.length > 0) {
        let data: any[] = [...Qnas.map((intent: any) => intent._id)];
        setSelectedNodes([..._.uniq(data)]);
      }
    } else {
      setSelectedNodes([]);
    }
  };

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-md-12">
          {/* <div className="d-flex flex-wrap justify-content-md-between all-knowledge-search mt-4 mt-md-0">
              <div className="web-links m-0">
                <input type="text" 
                  className="form-control border-0 h-auto" 
                  placeholder="Search by question, answer or topic"
                />
              </div> */}
          {/* <button
                className="btn btn-link py-0"
              >
                Export Table
              </button> */}
          {/* </div> */}
          {!props.isQuizView && (
            <div className="d-flex flex-wrap justify-content-between">
              <div className="my-3 font-16">
                {t("Can’t find your question and answer ?")}
                <button
                  className="btn btn-link py-0"
                  onClick={addMoreQuestionHandler}
                >
                  {t("Add now")}
                </button>
              </div>
            </div>
          )}
          <div className="d-flex align-items-center doc-count"> </div>
        </div>
      </div>

      <div className="generated-questions generated-question-answers drag-questions-wrap select-quiz-ques">
        {/* {addNewQuestionAnswer.show && (
          <ul
          className='list-unstyled ques-list'
          id={props._id + '_KW__add_now'}
          key={props._id + '_KW__add_now'}
        >
          <button className='btn py-0'>
            <Image
              src={closeimg}
              onClick={() => setAddNewQuestionAnswer({
                question: '',
                answer: '',
                sort_order: 0,
                show: false,
              })}
            />
          </button>
          <li>
            <span className='font-weight-bold mr-2'>Q:</span>
            <AutoTextArea
              key={props._id + '_QUE_add_now'}
              type='text'
              className='form-control'
              data-value={'question'}
              value={addNewQuestionAnswer?.question || ''}
              onChangeHandler={(e: any) => {
                updateMoreQuestionHandler('question', e.target.value)
              }}
            />
          </li>
          <li>
            <span className='font-weight-bold mr-2 ans-varient'>A:</span>
            <RichTextArea
              showRemoveIcon={false}
              key={props._id + '_TA_IP_add_now'}
              removeIcon={closeimg}
              index={0}
              item={{
                text: addNewQuestionAnswer?.answer || '',
              }}
              onEditorStateChangeHandler={(updatedIndex, value) =>
                updateMoreQuestionHandler('answer', value)
              }
              onRemoveGeetingHandler={(removedIndex) => {}}
            />
          </li>
        </ul>
        )} */}

        {props.isQuizView && <div className="d-flex flex-wrap justify-content-between align-items-center mt-2 mb-3">
          <div className="d-flex align-items-center">
            <div className="custom-checkbox mr-3">
              <input
                type="checkbox"
                className="mr-2"
                id="select-all"
                checked={Qnas && selectedNodes.length === Qnas.length}
                onChange={onSelectAllRows}
              />
              <label htmlFor="select-all" className="mb-0">
                {t("Select all")}
              </label>
            </div>
          </div>
        </div>}

        {
          qnasLoading && props.isSummarised && (
            <div className="doc-processing d-flex flex-wrap align-items-center p-0">
              <div className="col-status">
                <span className="doc-loader spinner-width-20"></span>
              </div>
              <div className="col-msg pl-3 font-1">
                {t("Generating Q&A from document")}....
                <br />
                {t("While waiting, you may explore other parts of the platform.")}
                <br />
                {t("You will receive a notification when the Q&As have been generated.")}
              </div>
            </div>
          )
        }

        <Container
          dragHandleSelector=".column-drag-handle"
          onDrop={(e) => props.isQuizView ? () => {}: applyDrag(Qnas, e)}
        >
          {Qnas &&
            Qnas.map((question: any, index: number) => {
              return (
                <Draggable
                  key={props._id + "draggable_" + index}
                  className={classNames({
                    "p-0": props.isQuizView,
                  })}
                >
                  <div
                    className={classNames({
                      "draggable-item web-links column-drag-handle": true,
                      "edit-drag": !question.hasEditMode,
                    })}
                  >
                    {!props.isQuizView && (
                      <button className="btn">
                        <Image src={baricon} />
                      </button>
                    )}

                    {props.isQuizView && (
                      <input
                        type="checkbox"
                        className="mr-2 quiz-checkbox"
                        onChange={(e) => {
                          addToSelectedRows(e, question._id);
                        }}
                        checked={selectedNodes.indexOf(question._id) >= 0}
                      />
                    )}

                    <ul
                      className="list-unstyled ques-list"
                      key={props._id + "_ANS_" + index}
                      id={props._id + "_ANS_" + index}
                    >
                      {!props.isQuizView && (
                        <button className="btn py-0">
                          <Image
                            src={closeimg}
                            onClick={() => removeKeywordFromList(index)}
                          />
                        </button>
                      )}
                      {!question.hasOwnProperty("newlyAdded") &&
                        !props.isQuizView &&
                        question.answer && (
                          <button className="btn py-0">
                            <Image
                              src={search}
                              onClick={() => previewToggleHandler(index)}
                            />
                          </button>
                        )}
                      <li>
                        <span className="font-weight-bold mr-2">Q:</span>
                        {/* <div className="divtext" contentEditable onChange={(e: any) => {
                                console.log('e', e)
                              }}>{question?.question || ""}</div> */}
                        {/* <textarea
                              key={props._id + "_QUE_" + index}
                              className="form-control"
                              data-value={"question"}
                              data-index={index}
                              value={question?.question || ""}
                              onChange={(e: any) => {
                                dataChangeHandler(e)
                              }}
                              readOnly={!question.hasEditMode}
                            /> */}

                        <AutoTextArea
                          key={props._id + "_QUE_" + index}
                          type="text"
                          className="form-control"
                          data-value={"question"}
                          data-index={index}
                          value={question?.question || ""}
                          onChangeHandler={dataChangeHandler}
                          readOnly={!question.hasEditMode}
                        />
                      </li>
                      <li>
                        {question.hasOwnProperty("newlyAdded") &&
                          question.newlyAdded &&
                          question.answer.trim().length === 0 &&
                          question.question.trim().length > 0 && (
                            <>
                              <span className="font-weight-bold mr-2 ans-varient">
                                A:
                              </span>
                              <button
                                className="btn btn-link font-sm py-0"
                                onClick={() => {
                                  generateCustomQuestionAndAnswerHandler(index);
                                }}
                              >
                                {t("Generate Answer")}
                              </button>
                            </>
                          )}
                        {/* <input
                              key={props._id + '_IP_' + index}
                              type='text'
                              className='form-control'
                              data-value={'answer'}
                              data-index={index}
                              value={question?.answer || ''}
                              onChange={dataChangeHandler}
                            /> */}

                        {!question.hasOwnProperty("newlyAdded") &&
                          question.answer && (
                            <>
                              <span className="font-weight-bold mr-2 ans-varient">
                                A:
                              </span>
                              <RichTextArea
                                readOnly={!question.hasEditMode}
                                showRemoveIcon={false}
                                key={props._id + "_TA_IP_" + index}
                                index={index}
                                removeIcon={closeimg}
                                item={{
                                  text: question?.answer || "",
                                }}
                                onEditorStateChangeHandler={(
                                  updatedIndex,
                                  value
                                ) => onEditorStateChange(updatedIndex, value)}
                                onRemoveGeetingHandler={(removedIndex) => {}}
                              />
                            </>
                          )}
                      </li>
                      {!props.isQuizView && (
                        <div className="edit-action-btns">
                          <button
                            className="btn py-0"
                            onClick={() => {
                              startEditQuestionHandler(index);
                            }}
                          >
                            <Image src={editImg} alt="" />
                          </button>
                          <button
                            className="btn py-0"
                            onClick={() => {
                              removeKeywordFromList(index);
                            }}
                          >
                            <Image src={closeImg} alt="" />
                          </button>
                        </div>
                      )}
                      {question.hasEditMode && (
                        <div className="text-right edit-done-btns pb-2">
                          <button
                            className="btn btn-primary font-sm font-weight-normal py-1 px-2"
                            onClick={() => {
                              saveQuestionHandler(question, index);
                            }}
                            disabled={question.newlyAdded && !question.answer}
                          >
                            {t("Save")}
                          </button>
                          <button
                            className="btn btn-outline-primary font-sm font-weight-normal py-1 px-2 ml-2"
                            onClick={() => {
                              startEditQuestionHandler(index);
                            }}
                          >
                            {t("Cancel")}
                          </button>
                        </div>
                      )}
                    </ul>
                  </div>
                </Draggable>
              );
            })}
        </Container>

        {qnasLoading && !props.isSummarised && <QuestionListSkeleton />}


        
        {Qnas && Qnas.length > 0 && !qnAFetchError && (
          <button
            className="btn btn-link mb-32 p-0"
            onClick={loadMoreQnasHandler}
          >
            {t("Load More Questions")}
          </button>
        )}
      </div>
      {Qnas &&
        Qnas.length === 0 &&
        (!addNewQuestionAnswer || !addNewQuestionAnswer.show) &&
        qnAFetchError &&
        !qnasLoading && (
          <div className="add-ques-error mb-3">
            {t(
              "We couldn’t generate questions for this document. Please click on the “Add Now” button to type your own question. Alternatively,"
            )}{" "}
            <button
              className="btn btn-link font-sm py-0"
              onClick={addMoreQuestionHandler}
            >
              {t("click here")}
            </button>{" "}
            {t("to start adding.")}
          </div>
        )}

      {Qnas && Qnas.length === 0 && qnAFetchError && !qnasLoading && (
        <PermissionWrapper>
          <button
            className="btn btn-link p-0"
            onClick={tryLoadingMoreQnasHandler}
          >
            {t("Try Loading Questions Again")}
          </button>
        </PermissionWrapper>
      )}
    </React.Fragment>
  );
};

// QAnsAList.propTypes = {

// }

export default QAnsAList;
