import { ButtonsOptions } from '../../services/interfaces/button-options.interface';
import { KnowledgeInterface } from './../../services/interfaces/knowledge.interface';
import * as type from "./actionType";

interface QuestionReducerState {
  loading: boolean,
  knowledgeHasError: boolean,
  intent: KnowledgeInterface;
  intents: KnowledgeInterface[];
  buttonOptions: ButtonsOptions[];
  groups: any[];
}

const initialState: QuestionReducerState = {
  loading: false,
  knowledgeHasError: false,
  intent: null,
  buttonOptions: [],
  groups: [],
  intents: []
};

const QuestionReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case type.FETCH_KNOWLEDGE_BY_ID:
    case type.FETCH_KNOWLEDGE_BY_ID_FAIL:
      return {
        ...state,
        ...action.payload
      }
    case type.FETCH_KNOWLEDGE_BY_ID_SUCCESS:
      return {
        ...state,
        intent: action.payload.intent ? { ...action.payload.intent } : null,
        loading: false,
        knowledgeHasError: false
      };
    case type.LOCAL_QUESTION_UPDATE:
      const oldIntent: any = { ...state.intent };
      oldIntent[action.payload.key] = Array.isArray(action.payload.value) ?
        [...action.payload.value] :
        (typeof action.payload.value === 'boolean' || typeof action.payload.value === 'string' || action.payload.value === null ) ? action.payload.value :
          { ...action.payload.value };
      return {
        ...state,
        intent: { ...oldIntent }
      };
    case type.FETCH_ALL_BUTTON_OPTIONS_SUCCESS:
    case type.FETCH_ALL_BUTTON_OPTIONS_SUCCESS_FAIL:
      return {
        ...state,
        buttonOptions: [...action.payload.buttonOptions]
      }
    case type.FETCH_ALL_USER_GROUP_OPTIONS_SUCCESS:
    case type.FETCH_ALL_USER_GROUP_OPTIONS_SUCCESS_FAIL:
      return {
        ...state,
        groups: [...action.payload.groups]
      }
    case type.FETCH_ALL_INTENTS_SUCCESS:
    case type.FETCH_ALL_INTENTS_FAIL:
      return {
        ...state,
        intents: [...action.payload.intents]
      }
    default:
      return {
        ...state,
      };
  }
};

export default QuestionReducer;
