import React from "react";
import { generateProgrammingLanguageStats } from "@nivo/generators";
import { ResponsivePie  } from '@nivo/pie';

function PieChart(props: any) {
  const commonProperties = {
    width: 250,
    height: 200,
    margin: { top: 15, right: 0, bottom: 15, left: 0 },
    data: props.data ? props.data : [],
    animate: false,
    activeOuterRadiusOffset: 8,
    enableArcLinkLabels: false,
    enableArcLabels: false
  };
//   generateProgrammingLanguageStats(true, 9).map(({ label, ...d }) => ({
//     id: label,
//     ...d,
//   }))
  return <ResponsivePie {...commonProperties} colors={{ datum: 'data.color' }} />;
}

export default PieChart;
