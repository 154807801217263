import React, { useState, useEffect } from "react";
import { Modal, ModalFooter, Button, Image } from "react-bootstrap";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import * as _ from "lodash";
import { useDispatch } from "react-redux";
import {
  createKnowledgeGroup,
  updateKnowledgeGroup,
} from "../../../store/knowledge/actions";
import closeimg from "../../../assets/images/close-blk.svg";

import { useTranslation } from "react-i18next";

export default function UpdateModuleModal(props: any) {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const [moduleName, setModuleName] = useState(null);
  const [loading, setLoading] = useState(false);
  const isMgs = process.env.REACT_APP_CISCO === "mgs";

  useEffect(() => {
    if (!props.show) {
      return;
    }
    setModuleName(props.name ? props.name : null);
  }, [props.show]);

  const changeHandler = (e: any) => {
    setModuleName(e.target.value);
  };

  const confirmRenameHandler = async () => {
    try {
      // console.log(props.editMode);
      let res = null;
      if (props.editMode) {
        res = await dispatch(
          updateKnowledgeGroup(
            {
              name: moduleName,
            },
            props.parent_id
          )
        );
      } else {
        res = await dispatch(
          createKnowledgeGroup({
            name: moduleName,
            parent_id: props.parent_id,
          })
        );
      }
      props.confirmHandler(res);
    } catch (error: any) {}
  };

  const getTopicName = () => {
    if (props.editMode) {
      return props.moduleDepth === 0 || !props.moduleDepth
        ? "Modules"
        : props.moduleDepth === 1
        ? "Topic"
        : props.moduleDepth > 1
        ? "Sub Topic"
        : "Modules";
    } else {
      return props.moduleDepth === 0
        ? "Topic"
        : props.moduleDepth > 0
        ? "Sub Topic"
        : isMgs
        ? "Chapters"
        : "Modules";
    }
  };

  return (
    <Modal
      show={props.show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="suggest-tags"
      onHide={props.onHide}
    >
      <ModalHeader className="py-3 justify-content-center border-bottom">
        <ModalTitle
          id="contained-modal-title-vcenter"
          className="d-flex align-items-center justify-content-between w-100"
        >
          <h2 className="mb-0 font-1 font-600">
            {props.name ? t("Update") : t("Add")}{" "}
            {props.isLevel
              ? isMgs
                ? t("Subject")
                : t("Level")
              : getTopicName()}
          </h2>
          <Button onClick={props.onHide}>
            <Image src={closeimg} />
          </Button>
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <div className="form-group px-sm-4">
          <label className="font-600 clr-black">
            {t("Name of")}{" "}
            {props.isLevel
              ? isMgs
                ? t("Subject")
                : t("Level")
              : getTopicName()}
          </label>
          <input
            type="text"
            className="form-control radius-2"
            value={moduleName || ""}
            onChange={changeHandler}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          className="btn-outline-primary btn mx-2 font-600"
          onClick={props.onHide}
          disabled={loading}
        >
          {t("Cancel")}
        </button>
        <button
          className="btn-primary btn mx-2 font-600"
          onClick={confirmRenameHandler}
          disabled={!moduleName || moduleName.trim().length === 0 || loading}
        >
          {props.name ? t("Update") : t("Create")}
        </button>
      </ModalFooter>
    </Modal>
  );
}
