import { useEffect, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import chevLeft from "../../../assets/New-images/chevron-left-blk.svg";
import backimg from "../../../assets/images/arrow-left.svg";
import { getQuizByQuizId } from "../../../store/dashboard/actions";
import { getQuizFeatures } from "../../../store/quizzes/actions";
import QuizSubmissionList from "../QuizSubmissionList/QuizSubmissionList";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import ReviewSubmissionsList from "../ReviewSubmissions/ReviewSubmissions";

function QuizzesAllReviewRequestById(props: any) {
  const { t } = useTranslation();
  const params: any = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [enableLearning, setEnableLearning] = useState(false);
  const [quizDetail, setQuizDetail] = useState(null);
  const [decimalPlaces, setDecimalPlaces] = useState("2");

  useEffect(() => {
    if (!params.quizId) {
      history.goBack();
      return;
    }

    getQuiz(params.quizId);
    getLearningFeature();
  }, []);

  const getLearningFeature = async () => {
    try {
      const response: any = await dispatch(getQuizFeatures());
      if (response.features && response.features.length !== 0) {
        for (let i = 0; i < response.features.length; i++) {
          if (response.features[i].name === "learning") {
            setEnableLearning(true);
            break;
          }
        }
      } else {
        setEnableLearning(false);
      }
    } catch (error) {
      setEnableLearning(false);
    }
  };

  const getQuiz = async (quizId: string) => {
    try {
      //   setIsLoading(true);
      const { quizDetail }: any = await dispatch(getQuizByQuizId(quizId));
      setQuizDetail(quizDetail);

      if (
        "score_decimal_number" in quizDetail &&
        quizDetail.score_decimal_number !== null
      ) {
        setDecimalPlaces(quizDetail.score_decimal_number.toString());
      } else {
        setDecimalPlaces("2");
      }
    } catch (error) {}
  };

  // if ( IsLoading ) {
  //   {console.log(' Loading ....... ')}
  //   <Loading />
  // }

  return (
    <>
      <section
        className={classNames({
          "page-mid-wraper h-without-foter ": true,
        })}
      >
        <div>
          <div className="row">
            <div className="col-md-12">
              <button
                className="btn p-0 btn-back"
                onClick={() => history.goBack()}
              >
                <Image src={backimg} /> {t("Return to previous page")}
              </button>
            </div>
          </div>

          <Row>
            <Col md={12}>
              <div className="">
                <ReviewSubmissionsList
                  enableLearning={enableLearning}
                  quizId={
                    props.quizId ? props.quizId : props.match.params.quizId
                  }
                  decimalPlaces={decimalPlaces}
                />
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
}

export default QuizzesAllReviewRequestById;
