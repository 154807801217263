import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Pagination from "../../../../components/UI/Pagination/Pagination";
import QuestionListSkeleton from "../../../../components/UI/Skeletons/QuestionListSkeleton/QuestionListSkeletons";
import { getUserFeedbacks } from "../../../../store/dashboard/actions";
import Select from "react-select";
import {
  getAllButtonOptions,
  getAllIntents,
  getAllUserGroups,
  getSuggestedKnowledgeFeedback,
  updateUserFeedback,
} from "../../../../store/questions/actions";
import { ChartTableSortIcon } from "../../../Knowledge/AllKnowledge";
import KnowledgeCreateFromScratch from "../../../Knowledge/KnowledgeCreateFromScratch/KnowledgeCreateFromScratch";
import { customStyles } from "../MatchedUnmatchedQuestions/MatchedUnmatchedUtils";
import AddToExistingIntent from "../MatchedUnmatchedQuestions/partials/AddToExisting";
import { conditionalRowStyles, userFeedbackColumns } from "./reportsUtils";
import cx from "classnames";
import { getStickerSets } from "../../../../store/common/actions";
import { getAllUserGroupOptions } from "../../../../store/notifications/actions";

import { useTranslation } from "react-i18next";
import ExportReportShared from "../../../../components/UI/ExportReportShared/ExportReportShared";
import { isNull } from "lodash";
import moment from "moment";
import { subDays } from "date-fns";
import DateRangePickerComponent from "../../../../components/UI/DateRangePicker/DateRangePicker";

export const UserFeedback = (props: any) => {
  const { t } = useTranslation("translation");
  const history = useHistory();
  const dispatch = useDispatch();
  const [perPage, setPerPage] = useState(25);
  const [existingRecord, setExistingRecord] = useState(null);
  const [matchedRecords, setMatchedRecords] = useState([]);
  const [matchedRecordsPagination, setMatchedRecordsPagination] =
    useState(null);
  //   const [unmatchedRecords, setUnMatchedRecords] = useState(30)
  //   const [unmatchedRecordsPagination, setUnMatchedRecordsPagination] = useState(30)
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const tableWrapperRef = useRef(null);
  const [filters, setFilters] = useState({
    page: null,
    page_size: null,
    from_date: null,
    to_date: null,
  });
  const [scrollRight, setScrollRight] = useState(0);
  const [showTagsModal, setShowTagsModal] = useState(false);
  const [datePickerClicked, setDatePickerClicked] = useState(false);
  const [customDatePickerValue, setCustomDatePickerValue] = useState(false);
  const [dateParameters, setDateParameters] = useState(null);

  useEffect(() => {
    // Table wrapper reference to check scroll left value of an element
    if (
      tableWrapperRef &&
      tableWrapperRef.current &&
      tableWrapperRef.current.children[0]
    ) {
      setScrollRight(tableWrapperRef.current.children[0].scrollLeft);
    }

    dispatch(getAllUserGroups());
    dispatch(getStickerSets());
    dispatch(getAllUserGroupOptions());
    dispatch(getAllButtonOptions());
    dispatch(getAllIntents());

    return () => {
      setScrollRight(0);
    };
  }, []);

  useEffect(() => {
    if (loading || !dateParameters || !dateParameters.value) {
      return;
    }

    if (
      dateParameters &&
      dateParameters.value &&
      dateParameters.value === "custom"
    ) {
      return setCustomDatePickerValue(true);
    }

    setTimeout(async () => {
      const filter: any = {
        ...filters,
        from_date:
          dateParameters && dateParameters.value
            ? moment(subDays(new Date(), +dateParameters.value)).format(
                "YYYY-MM-DD"
              )
            : null,
        to_date:
          dateParameters && dateParameters.value
            ? moment(new Date()).format("YYYY-MM-DD")
            : null,
      };

      setCustomDatePickerValue(false);
      await setFilters(filter);
    }, 100);
  }, [dateParameters]);

  useEffect(() => {
    if (
      loading ||
      !props.filtersData.filter_from_date ||
      !props.filtersData.filter_to_date
    ) {
      return;
    }

    const fn = async () => {
      const filter = {
        ...filters,
        from_date: props.filtersData.filter_from_date,
        to_date: props.filtersData.filter_to_date,
      };

      if (props.filtersData.dateParameters) {
        await setDateParameters(props.filtersData.dateParameters);
      }

      await setFilters(filter);
    };
    fn();
    setDatePickerClicked(false);
    return () => {};
  }, [props.filtersData]);

  useEffect(() => {
    getMatchedFunctions();
  }, [filters]);

  const i18nName = (cols: any) => {
    if (cols && cols.length > 0) {
      return cols.map((col: any) => ({ ...col, name: t(col.name) }));
    }
    return cols;
  };

  const getMatchedFunctions = async () => {
    if (loading || !filters.from_date || !filters.to_date) {
      return;
    }
    try {
      setLoading(true);
      const matchedQuestions: any = await dispatch(
        getUserFeedbacks({
          ...filters,
          page: currentPage,
          page_size: perPage,
        })
      );
      setMatchedRecords(matchedQuestions.rows);
      setMatchedRecordsPagination(matchedQuestions.pagination);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };

  /**
   *
   * @param page Pagination on change of page
   */
  const handlePageChange = async (page: number) => {
    await setCurrentPage(page);
    await setFilters({
      ...filters,
      page: page,
    });
  };

  /**
   * Used to paginate data based on per page and page number
   * @param newPerPage
   * @param page
   */
  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    await setPerPage(newPerPage);
    await setCurrentPage(page);
    await setFilters({
      ...filters,
      page: page,
      page_size: newPerPage,
    });
  };

  /**
   * Custom sort function
   * @param rows
   * @param selector
   * @param direction
   * @returns
   */
  const customSort = (rows: any[], selector: string, direction: string) => {
    return rows;
  };

  const addToExistingIntent = (e: any) => {
    setExistingRecord(e);
    setShowTagsModal(!showTagsModal);
  };

  const confirmAddToExistingIntent = (e: any) => {
    setShowTagsModal(!showTagsModal);
    editKnowledgehandler({
      _id: e._id,
      existingRecord: {
        ...existingRecord,
        question: existingRecord.question_text,
        answer: existingRecord.reply_text,
      },
    });
  };

  const acknowledgeConfirmed = async (selectedRow: any) => {
    await dispatch(updateUserFeedback(selectedRow));
  };

  /**
   * Edit mode activate or de activate
   */
  const [editMode, setEditMode] = useState(false);
  const [selectedIntent, setSelectedIntent] = useState(null);
  const editKnowledgehandler = (intent: any) => {
    const editModeCheck = !editMode;
    setSelectedIntent(intent);
    setEditMode(editModeCheck);
  };

  const resetEditKnowledgehandler = (action: any) => {
    const editModeCheck = !editMode;
    setSelectedIntent(null);
    setEditMode(editModeCheck);
    // From edit mode if any intent gets deleted then we have to reload data
    if (action === "reload") {
      //   dispatch(
      //     getAllKnowledge(
      //       3,
      //       currentPage,
      //       perPage,
      //       query,
      //       topicIds,
      //       {},
      //       selectedNodes.length > 0
      //     )
      //   )
    }
  };

  const dateChangehandler = async (e: any) => {
    if (!e.filter_from_date || !e.filter_to_date) {
      return;
    }

    await setFilters({
      ...filters,
      from_date: e.filter_from_date,
      to_date: e.filter_to_date,
    });
  };

  const closeDateRangeHandler = () => {
    setCustomDatePickerValue(false);
  };

  return (
    <section
      className={cx({
        "h-without-foter": true,
        "dashborad-wraper": !editMode,
      })}
    >
      <div className="p-0">
        <AddToExistingIntent
          onHide={addToExistingIntent}
          show={showTagsModal}
          confirmhandler={confirmAddToExistingIntent}
          selectedTags={[]}
        />

        <div className="welcome-subtext d-flex flex-md-nowrap flex-wrap mb-4">
          <h2>{t("Feedback")}</h2>
          <div className="d-flex flex-wrap sugested-next-ques"></div>
          {!loading && (
            <div className="d-flex flex-wrap sugested-next-ques ml-auto">
              <Select
                className="select-sort select-target"
                classNamePrefix="select-target-prefix"
                components={{
                  IndicatorSeparator: () => null,
                }}
                value={dateParameters ? { ...dateParameters } : null}
                onChange={(e: any) => {
                  setDatePickerClicked(e.value === "custom");
                  setDateParameters(e);
                }}
                options={[
                  { value: "60", label: "Last 60 days" },
                  { value: "30", label: "Last 30 days" },
                  { value: "7", label: "Last 7 days" },
                  { value: "custom", label: "Custom" },
                ]}
              />
              <ExportReportShared
                name={"feedbacks"}
                filtersData={filters}
                removedKeyFromObject={"page, page_size"}
              />
            </div>
          )}
        </div>

        <div
          className={cx("right-animate", {
            show: editMode,
          })}
        >
          {editMode && (
            <KnowledgeCreateFromScratch
              resetEditKnowledgehandler={resetEditKnowledgehandler}
              selectedIntent={selectedIntent}
              editMode={editMode}
            />
          )}
        </div>

        {/* {loading && (
          <div className='d-flex justify-content-center'>
            <div className='spinner-border spinner-border-lg text-primary'></div>
          </div>
        )} */}
        {/* {!loading && ( */}

        <React.Fragment>
          {customDatePickerValue && datePickerClicked && (
            <DateRangePickerComponent
              dateValue={{
                startDate:
                  filters && filters.from_date
                    ? new Date(filters.from_date)
                    : new Date(),
                endDate:
                  filters && filters.to_date
                    ? new Date(filters.to_date)
                    : new Date(),
              }}
              onDateChange={dateChangehandler}
              onCloseDateRangeHandler={closeDateRangeHandler}
            />
          )}
          <div>
            <DataTable
              className="table-wraper bot-exp-user-feedback-min-height"
              noContextMenu
              noHeader
              fixedHeader
              fixedHeaderScrollHeight="700px"
              columns={i18nName(
                userFeedbackColumns({
                  editKnowledgehandler,
                  addToExistingIntent: addToExistingIntent,
                  acknowledgeConfirmed,
                })
              )}
              data={matchedRecords ? matchedRecords : []}
              customStyles={customStyles}
              sortIcon={<ChartTableSortIcon />}
              paginationPerPage={perPage}
              paginationDefaultPage={1}
              paginationResetDefaultPage={false}
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              progressPending={loading} //
              progressComponent={
                <div style={{ padding: 10, width: "100%" }}>
                  <QuestionListSkeleton count={12} />
                </div>
              }
              defaultSortAsc={false}
              conditionalRowStyles={conditionalRowStyles}
              noDataComponent={t("There are no records to display")}
              // sortFunction={customSort}
              // onSort={onSortHandler}
            />

            {/* -----------------  Pagination   --------------------------- */}
            {matchedRecords &&
              matchedRecords.length > 0 &&
              matchedRecordsPagination && (
                <Pagination
                  // showScrollButtons={true}
                  hideSuggestedQuestion={false}
                  suggestedQuestionText={"Reached Fallback"}
                  //   scrollRightValue={scrollRight}
                  //   scrollWrapper={'table-wraper'}
                  currentPage={currentPage}
                  pagination={matchedRecordsPagination}
                  paginationPerPage={perPage}
                  pageChange={handlePerRowsChange}
                  onChangePage={handlePageChange}
                />
              )}
          </div>
        </React.Fragment>
        {/* )} */}
      </div>
    </section>
  );
};

export default UserFeedback;
