/* eslint-disable no-script-url */
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Modal, ModalFooter } from "react-bootstrap";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import { useDispatch } from "react-redux";
import {
  getAllLevels,
  getAllModulesFromYear,
} from "../../../store/knowledge/actions";

import { useTranslation } from "react-i18next";
import closeImg from "../../../assets/images/close-blk.svg";
import UpdateModuleModal from "./UpdateModuleModal";

interface Props {}

const MoveSelectedModal = (props: any) => {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [modules, setModules] = useState([]);
  const [showRenameModal, setShowRenameModal] = React.useState(false);
  const [selectedParentId, setSelectedParentId] = React.useState(null);
  const [selectedModuleName, setSelectedModuleName] = React.useState("");
  const [moduleEditMode, setModuleEditMode] = React.useState(false);
  const [moduleDepth, setModuleDepth] = React.useState(0);
  const [selectedGroupId, setSelectedGroupId] = useState(null);

  useEffect(() => {
    if (!props.show) {
      return;
    }
    getAllYearsFun();
    setSelectedGroupId(props.groupId ? props.groupId : null);
  }, [props.show]);

  const onSelectGroupId = (level: string) => {
    setSelectedGroupId(level);
  };

  const getAllYearsFun = async (loadSubModules: boolean = true) => {
    try {
      const response: any = await dispatch(getAllLevels());
      setYears([...response.filter((res: any) => res.group_type !== "system")]);
      response &&
        response.length > 0 &&
        getAllModulesFromYearFun(selectedYear ? selectedYear : response[0]);
    } catch (error: any) {
      setYears([]);
    }
  };

  const getAllModulesFromYearFun = async (year: any) => {
    setSelectedYear(year);
    try {
      const response: any = await dispatch(getAllModulesFromYear(year._id));
      setModules([...response]);
    } catch (error: any) {
      setModules([]);
    }
  };

  const confirmCategoryUpdatehandler = (
    selectedGroupId: any,
    extraParams: boolean = false
  ) => {
    setSelectedGroupId(selectedGroupId);
    props.confirmCategoryUpdate(selectedGroupId, extraParams);
  };

  const updateModalHandler = () => {
    setShowRenameModal(!showRenameModal);
  };

  const updateParentHandler = (
    name?: string,
    parent_id?: string,
    isEdit: boolean = false,
    depth: number = 0
  ) => {
    setSelectedParentId(parent_id);
    setSelectedModuleName(name);
    setModuleEditMode(isEdit);
    setModuleDepth(depth);
    updateModalHandler();
  };

  const confirmRenameHandler = async (data: any) => {
    getAllYearsFun(false);
    updateModalHandler();
  };

  const isGroupInvalid = !selectedGroupId || selectedGroupId === "undefined";

  return (
    <>
      <UpdateModuleModal
        show={showRenameModal}
        onHide={updateModalHandler}
        name={selectedModuleName}
        parent_id={selectedParentId}
        editMode={moduleEditMode}
        confirmHandler={confirmRenameHandler}
        moduleDepth={moduleDepth}
      />
      <Modal
        show={!showRenameModal && props.show}
        size={props.size}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="move-selected-modal"
        onHide={props.onHide}
      >
        <ModalHeader className="clr-black pt-4 px-4 justify-content-between">
          <ModalTitle id="contained-modal-title-vcenter">
            <h2 className="mb-0 font-1">
              {props.isBuildKnowledge
                ? t("Save Document(s) to")
                : t("Save Knowledge to")}
            </h2>
            <p className="font-16 mb-0">
              {props.isBuildKnowledge
                ? t("Select Module to save document in")
                : t("Select location to save Knowledge and document")}
            </p>
          </ModalTitle>
          <button className="btn" onClick={props.onHide}>
            <img src={closeImg} alt="" />
          </button>
        </ModalHeader>
        <ModalBody className="px-4">
          <div className="level-tab-wraper">
            <div className="levels-row mb-4 d-flex align-items-center flex-wrap">
              <h2 className="mb-0 mr-3 font-1">{t("Level:")}</h2>
              {years.map((year: any) => {
                return (
                  <button
                    className={classNames({
                      "btn btn-tabs ": true,
                      active: selectedYear && year._id === selectedYear._id,
                    })}
                    onClick={() => {
                      getAllModulesFromYearFun(year);
                    }}
                  >
                    {year?.name}
                  </button>
                );
              })}
            </div>
            {years.length > 0 && (
              <div className="knowledge-box p-0 build-knowbox text-left d-flex flex-column bg-white mb-4">
                <div className="d-flex justify-content-between align-items-center px-3 py-2 bg-grey-head">
                  <h2 className="mb-0 font-1">{t("Modules")}</h2>
                  {props.showAddModuleBtn && (
                    <button
                      className="btn btn-link font-sm px-0 py-1"
                      onClick={() => {
                        updateParentHandler(null, selectedYear._id, false, -1);
                      }}
                    >
                      {t("+ Add")}
                    </button>
                  )}
                </div>
                {modules.length > 0 && (
                  <div className="modules-list flex-grow-1 scroll-wrap">
                    <ul className="list-unstyled">
                      {modules.map((level: any) => {
                        return (
                          <li
                            key={
                              "child_padding_dev_" +
                              level.value +
                              "_" +
                              Math.floor(Math.random() * 9999)
                            }
                            style={{
                              paddingLeft: `${
                                level.depth > 0 ? level.depth * 10 + 10 : 10
                              }px`,
                            }}
                            className={classNames({
                              "clr-grey-txt": level.depth > 0,
                              active: selectedGroupId === level.value,
                            })}
                          >
                            <a
                              href="javascript:void(0);"
                              onClick={() => {
                                onSelectGroupId(level.value);
                              }}
                              className={classNames({
                                "clr-grey-txt": level.depth > 0,
                              })}
                            >
                              {level?.label}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
                {modules.length === 0 && (
                  <div className="empty-knowledge text-center my-5">
                    <p className="h6 clr-primary mb-0 font-weight-bold text-center">
                      {t("No modules available")}
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>
        </ModalBody>
        <ModalFooter className="border-0 px-4 mb-3">
          {props.summarisedView ? (
            <>
              <button
                className={classNames({
                  "btn ": true,
                  "btn-light btn-outline-primary": isGroupInvalid,
                  "btn-outline-primary": !isGroupInvalid,
                })}
                // disabled={!selectedGroupId}
                onClick={(e) => {
                  if (isGroupInvalid) {
                    return;
                  }
                  confirmCategoryUpdatehandler(selectedGroupId, false);
                }}
              >
                {t("Save without creating Flow")}
              </button>
              <button
                // disabled={!selectedGroupId}
                className={classNames({
                  "btn  font-600 m-0": true,
                  "btn-light btn-outline-primary": isGroupInvalid,
                  "btn-primary": !isGroupInvalid,
                })}
                onClick={(e) => {
                  if (isGroupInvalid) {
                    return;
                  }
                  confirmCategoryUpdatehandler(selectedGroupId, true);
                }}
              >
                {t("Save and Create Flow")}
              </button>
            </>
          ) : (
            <>
              <button
                className={classNames({
                  "btn  font-600 m-0": true,
                  "btn-light btn-outline-primary": isGroupInvalid,
                  "btn-primary": !isGroupInvalid,
                })}
                // disabled={!selectedGroupId}
                onClick={(e) => {
                  if (isGroupInvalid) {
                    return;
                  }
                  confirmCategoryUpdatehandler(selectedGroupId, false);
                }}
              >
                {`${
                  props.isBuildKnowledge ? t("Save and Proceed") : t("Save")
                }`}
              </button>
              {/* <button className="btn-outline-primary btn" onClick={props.onHide}>
                            {t("Cancel")}
                        </button> */}
            </>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default MoveSelectedModal;
