import React, { useEffect, useRef, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import closeImg from "../../../assets/images/close-blk.svg";

const StartVideoPopup = (props: any) => {
  const handleClose = () => props?.close()
  const videoRef = useRef(null);
  const { t } = useTranslation("translation");

  return (
    <Modal
      show={props.show}
      size="xl"
      onHide={props.close}
      centered
    >
      <Modal.Header className="p-2 border-bottom justify-content-start">
        <ModalTitle className="px-2 w-100 d-flex justify-content-between">
          {t("View Video")}
          <button className="btn" onClick={() => props.close()}>
            <img src={closeImg} alt="" />
          </button>
        </ModalTitle>
      </Modal.Header>

      <ModalBody>
        <video controls width="100%">
          <source src="https://media.noodlefactory.ai/static/vid/portal-contextualised-video.mp4" type="video/mp4" />
        </video>
      </ModalBody>
      <ModalFooter>
        <button
          className="btn btn-outline-primary"
          onClick={handleClose}
        >
          {t("Close")}
        </button>
      </ModalFooter>
    </Modal>
  )
}

export default StartVideoPopup
