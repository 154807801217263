import * as Sentry from "@sentry/react";


const initSentry = (sentry_dsn: string, environment: string = "production") => {
  let tracePropagationTargets = [];
  tracePropagationTargets.push(/https:\/\/[\w]+api(us|uk)?\.(staging\.)?noodlefactory\.ai\//);
  if (environment === "development") {
    tracePropagationTargets.push("localhost");
    tracePropagationTargets.push("admin.noodlefactory.local/api/");
  }

  Sentry.init({
    dsn: sentry_dsn,
    integrations: [
      new Sentry.BrowserTracing({}),
      // new Sentry.Replay()
    ],
    tracesSampleRate: .2,
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: tracePropagationTargets,
    // replaysSessionSampleRate: 0.0,
    // replaysOnErrorSampleRate: 0.0,
    environment: environment
  });
}

if (process.env.hasOwnProperty("REACT_APP_SENTRY_DSN") ) {
  const windowObj: any = window;
  if (windowObj.hasOwnProperty("REACT_DEVELOPMENT_MODE") && windowObj.REACT_DEVELOPMENT_MODE !== "development") {
    initSentry(process.env.REACT_APP_SENTRY_DSN, windowObj.REACT_DEVELOPMENT_MODE);
    }
}
