import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getQuizAlerts, updateQuizAlerts } from "../../store/quizzes/actions";

const ReportAlerts = (props: any) => {
  const { t } = useTranslation();
  const [forAttempted, setForAttempted] = useState(false);
  const [forNotAttempted, setForNotAttempted] = useState(false);
  const [isInitial, setIsInitial] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    getReportAlerts();
  }, []);

  const getReportAlerts = async () => {
    let quizId = props.quizId ? props.quizId : props.match.params.quizId;

    try {
      const reponse: any = await dispatch(getQuizAlerts(quizId));
      if (
        reponse.alerts &&
        reponse.alerts.includes("weekly_attempted_report")
      ) {
        setForAttempted(true);
      }
      if (
        reponse.alerts &&
        reponse.alerts.includes("weekly_unattempted_report")
      ) {
        setForNotAttempted(true);
      }
    } catch (error) {}
  };

  const updateReportAlerts = async (attempted: any, unattempted: any) => {
    let quizId = props.quizId ? props.quizId : props.match.params.quizId;

    let updatedAlerts = [];
    if (attempted) {
      updatedAlerts.push("weekly_attempted_report");
    }
    if (unattempted) {
      updatedAlerts.push("weekly_unattempted_report");
    }
    console.log('updatedAlerts', updatedAlerts)
    try {
      const response: any = await dispatch(
        updateQuizAlerts(quizId, updatedAlerts)
      );
    } catch (error) {
      console.log(error)
    }
  };

  const onAlertChange = (event: any, type: any) => {
    if (type === "attempted") {
      setForAttempted(event.target.checked);
      updateReportAlerts(forNotAttempted, event.target.checked);
    } else if (type === "unattempted") {
      setForNotAttempted(event.target.checked);
      updateReportAlerts(forAttempted, event.target.checked);
    }
  };

  return (
    <div>
      <label className="font-16 font-600 mb-1">{t("Report Alerts")}</label>
      <div className="mb-2">{t("Manage quiz alerts")}.</div>

      <div className="custom-checkbox mr-3 font-16 mb-2">
        <input
          onChange={(event: any) => onAlertChange(event, "attempted")}
          checked={forAttempted}
          type="checkbox"
          className="mr-2"
          id="Receive weekly report of users who have attempted the quiz"
        />
        <label
          htmlFor="Receive weekly report of users who have attempted the quiz"
          className="mb-0 clr-grey-text"
        >
          {t("Receive weekly report of users who have attempted the quiz")}
        </label>
      </div>
      <div className="custom-checkbox font-16 mb-2">
        <input
          onChange={(event: any) => onAlertChange(event, "unattempted")}
          checked={forNotAttempted}
          type="checkbox"
          className="mr-2"
          id="Receive weekly report of the users who have not attempted the quiz"
        />
        <label
          htmlFor="Receive weekly report of the users who have not attempted the quiz"
          className="mb-0 clr-grey-text"
        >
          {t("Receive weekly report of the users who have not attempted the quiz")}
        </label>
      </div>
    </div>
  );
};

export default ReportAlerts;
