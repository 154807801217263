import * as type from "./actionType";
import NotificationService from "../../services/NotificationsService";
import CommonService from "../../services/CommonService";
import { HelperService } from "../../services/helper.service";
import KnowledgeService from "../../services/KnowledgeService";

const notificationService = new NotificationService();
const knowledgeService = new KnowledgeService();
const commonService = new CommonService();
const helperService = new HelperService();

type AppDispatchType = any;
export const getNotificationsBasedOnType = (notificationType: string) => async (dispatch: AppDispatchType, getState: any) => {
    try {
        await dispatch({
            type: type.GETALLNOTIFICATIONS,
            payload: {
                loading: true,
                notifications: []
            },
        })
        const response = await notificationService.getNotificationsBasedOnType(notificationType);
        await dispatch({
            type: type.GETALLNOTIFICATIONS,
            payload: {
                loading: false,
                notifications: response.data.data.notifications
            },
        })
        return Promise.resolve();
    } catch (error: any) {
        await dispatch({
            type: type.GETALLNOTIFICATIONS,
            payload: {
                loading: true,
                notifications: []
            },
        })
        return Promise.resolve(false);
    }
};

export const getNotificationsSettings = () => async (dispatch: AppDispatchType, getState: any) => {
    try {
        await dispatch({
            type: type.GETALLNOTIFICATIONSETTING,
            payload: {
                loading: true,
                setting: []
            },
        })
        const response = await notificationService.getNotificationsSettings();
        // console.log('response', response.data.data.setting)
        await dispatch({
            type: type.GETALLNOTIFICATIONSETTING,
            payload: {
                loading: false,
                setting: response.data.data.setting
            },
        })
        return Promise.resolve();
    } catch (error: any) {
        await dispatch({
            type: type.GETALLNOTIFICATIONSETTING,
            payload: {
                loading: false,
                setting: []
            },
        })
        return Promise.resolve(false);
    }
};

export const deleteNotification = (notificationId: string) => async (dispatch: AppDispatchType, getState: any) => {
    try {
        await commonService.deleteNotification(notificationId)
        return Promise.resolve();
    } catch (error: any) {
        return Promise.reject(false);
    }
};

export const readNotification = (id: string, isRead: any) => async (
    dispatch: AppDispatchType,
    getState: any
) => {
    try {
        const response = await commonService.readNotification(id, isRead);
        return Promise.resolve(true);
    } catch (error: any) {
        return Promise.reject(false);
    }
};

export const updateNotificationsSettings = (settings: any) => async (
    dispatch: AppDispatchType,
    getState: any
) => {
    try {
        await dispatch({
            type: type.GETALLNOTIFICATIONSETTING,
            payload: {
                loading: true
            },
        })
        const response = await notificationService.updateNotificationsSettings(settings);
        await dispatch({
            type: type.GETALLNOTIFICATIONSETTING,
            payload: {
                loading: false,
                setting: response.data.data.setting
            },
        })
        return Promise.resolve();
    } catch (error: any) {
        await dispatch({
            type: type.GETALLNOTIFICATIONSETTING,
            payload: {
                loading: false
            },
        })
        helperService.showToaster('Something went wrong while updating settings.');
        return Promise.reject(false);
    }
};

export const getAllUsers = () => async (
    dispatch: AppDispatchType,
    getState: any
) => {
    const { users } = getState().notifications
    if (!users || users.length > 0 ) {
        return;
    }
    try {
        const response = await notificationService.getAllUsers();
        await dispatch({
            type: type.GETALLUSERS,
            payload: {
                users: response.data.data.users.map((item: any) => ({
                    value: item.email,
                    label: item.email,
                }))
            },
        })
        return Promise.resolve();
    } catch (error: any) {
        await dispatch({
            type: type.GETALLUSERS,
            payload: {
                users: []
            },
        })
        return Promise.reject(false);
    }
};
export const getAllButtonOptions = (force = false) => async (
    dispatch: AppDispatchType,
    getState: any
) => {
    const { buttonOptions } = getState().notifications
    if ((!buttonOptions || buttonOptions.length > 0) && !force) {
        return;
    }
    try {
        const response = await knowledgeService.getAllButtonOptions();
        await dispatch({
            type: type.GETALLUSERS,
            payload: {
                buttonOptions: response.data.data.button_options.map((item: any) => ({
                    value: item.reference_id,
                    label: item.label,
                    name: item.name,
                    reference: item.reference,
                    reference_id: item.reference_id,
                    type: item.type,
                }))
            },
        })
        return Promise.resolve();
    } catch (error: any) {
        await dispatch({
            type: type.GETALLUSERS,
            payload: {
                buttonOptions: []
            },
        })
        return Promise.reject(false);
    }
};

export const getAllClasses = () => async (
    dispatch: AppDispatchType,
    getState: any
) => {
    const { classes } = getState().notifications
    if (!classes || classes.length > 0 ) {
        return;
    }
    try {
        const response = await knowledgeService.getAllClasses();
        await dispatch({
            type: type.GETALLUSERS,
            payload: {
                classes: response.data.data.classes.map((item: any) => ({
                    value: item._id,
                    label: item.name,
                }))
            },
        })
        return Promise.resolve();
    } catch (error: any) {
        await dispatch({
            type: type.GETALLUSERS,
            payload: {
                classes: []
            },
        })
        return Promise.reject(false);
    }
};

export const getAllUserGroupOptions = () => async (
    dispatch: AppDispatchType,
    getState: any
) => {
    const { groups } = getState().notifications
    if (!groups || groups.length > 0 ) {
        return;
    }
    try {
        const response = await knowledgeService.getAllUserGroupOptions();
        await dispatch({
            type: type.GETALLUSERGROUPS,
            payload: {
                groups: response.data.data.groups.map((item: any) => ({
                    value: item._id,
                    label: item.name,
                }))
            },
        })
        return Promise.resolve();
    } catch (error: any) {
        await dispatch({
            type: type.GETALLUSERGROUPS,
            payload: {
                groups: []
            },
        })
        return Promise.reject(false);
    }
};

export const sendNotifications = (data: any) => async (
    dispatch: AppDispatchType
) => {
    try {
        await notificationService.sendNotifications(data);
        helperService.showToaster('Notification sent successfully.', 'success');
        return Promise.resolve(true);
    } catch (error: any) {
        helperService.showToaster('Unable to send notification.');
        return Promise.reject(false);
    }
};

export const uploadNotificationHeaderImage = (data: any) => async (
    dispatch: AppDispatchType
) => {
    try {
        const response = await notificationService.uploadNotificationHeaderImage(data);
        return Promise.resolve( response.data.data.media );
    } catch (error: any) {
        helperService.showToaster('Unable to send notification.');
        return Promise.reject(false);
    }
};
