import { FC, useEffect, useState } from "react";
import cx from "classnames";
import closeimg from "../../assets/images/close-blk.svg";
import { useTranslation } from "react-i18next";
import {
  Button,
  Image,
  Modal,
  ModalBody,
  ModalFooter,
  ModalTitle,
} from "react-bootstrap";
import CreatableSelect from "react-select/creatable";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import * as _ from "lodash";
import Tags from "../../components/UI/Tags/Tags";
import "./index.css";

const AddKeyPhraseModal = (props: any) => {
  const { t } = useTranslation("translation");
  const [tags, setTags] = useState([]);

  const [selectedTags, setSelectedTags] = useState([]);
  useEffect(() => {
    if (!props.show) {
      return;
    }
    setTags([...props.selectedTags]);
    setSelectedTags([...props.selectedTags]);
  }, [props.selectedTags, props.show]);

  const handleChange = (newValue: any, actionMeta: any) => {
    setTags([...newValue]);
    setSelectedTags([...newValue]);
  };

  const removeTagsHandler = (e: any, value: any) => {
    const oldTags = [...tags];
    const hasTagPresent = oldTags.findIndex(
      (tag) => tag.value.toLowerCase() === value.toLowerCase()
    );
    if (hasTagPresent >= 0) {
      oldTags.splice(hasTagPresent, 1);
      setTags([...oldTags]);
      setSelectedTags([...oldTags]);
    }
  };

  return (
    <Modal
      className="add-tag-modal"
      show={props.show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalHeader
        className={cx({
          "pt-3": true,
          "justify-content-between": true,
          "px-4": true,
        })}
        onHide={props.onHide}
      >
        <div style={{ fontSize: 18 }}>{t("Add Key Phrases")}</div>
        <Button onClick={props.onHide}>
          <Image src={closeimg} />
        </Button>
      </ModalHeader>
      <ModalBody>
        <div className="changepurpose-select-tag">
          <CreatableSelect
            isMulti
            // defaultMenuIsOpen
            controlShouldRenderValue={false}
            isClearable={false}
            className="add-tag-select"
            classNamePrefix="add-tags"
            onChange={handleChange}
            // onInputChange={handleInputChange}
            defaultValue={selectedTags}
            value={selectedTags}
            options={[...tags]}
            placeholder={t("Search or create key phrase")}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
          />
        </div>
        <div className="knowledge-tags d-flex flex-wrap align-items-center mt-2">
          {tags.length > 0 &&
            tags.map((value, index) => {
              return (
                <Tags
                  key={value + "_" + index}
                  value={value.value}
                  label={value.label}
                  removeTagHandler={removeTagsHandler}
                />
              );
            })}
        </div>
      </ModalBody>
      <ModalFooter className="border-0 justify-content-end px-3 flex-nowrap">
        <button className="btn-outline-primary btn mx-2" onClick={props.onHide}>
          {" "}
          {t("Cancel")}
        </button>
        <button
          className="btn-primary btn mx-2"
          onClick={(e) => props.confirmhandler(e, tags)}
          disabled={tags.length === 0}
        >
          {t("Add Key Phrase")}
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default AddKeyPhraseModal;
