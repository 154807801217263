import * as type from "../actionType";

interface GreetingReducerState {
  botName: string,
  botAvatar: string,
  webBotData: any,
  widgetBotData: any,
  userGreet: any,
  error: any,
  botUpdating: boolean,
  botUpdated: boolean,
  greetUpdating: boolean,
  greetError: any,
  loading: boolean,
}

const initialState: GreetingReducerState = {
  botName: null,
  botAvatar: null,
  webBotData: null,
  widgetBotData: null,
  userGreet: null,
  error: null,
  loading: true,
  botUpdating: false,
  greetUpdating: false,
  greetError: null,
  botUpdated: false
};

const GreetingReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case type.SET_OBJECTIVE:
      return {
        ...state,
        webBotData: action.payload.webBotData
      }
    case type.INIT_BOT_DATA:
    case type.INIT_USER_GREET:
      return {
        ...state,
        loading: true,
        botUpdated: false,
        botUpdating: false,
        greetUpdating: false,
        error: false
      };
    case type.FETCH_BOT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        widgetBotData: action.payload[0].data.data,
        webBotData: action.payload[1].data.data,
      };
    case type.UPDATE_BOT_DATA_SUCCESS:
      return {
        ...state,
        botUpdating: false,
        botUpdated: true,
        widgetBotData: action.payload[0].data.data,
        webBotData: {
          ...state.webBotData,
          agent: {
            settings: action.payload[1].data.data.settings
          }
          // [state.webBotData.agent.settings]: action.payload[1].data.data.settings
        }
      };
    case type.LOCAL_GREETING_BUTTONS_UPDATE:
      const oldIntent = { ...state.userGreet };
      oldIntent.intent[action.payload.key] = (typeof action.payload.value === 'boolean' || action.payload.value === null) ? action.payload.value : [ ...action.payload.value ];
      return {
        ...state,
        userGreet: { ...oldIntent }
      };
    case type.FETCH_USER_GREET_SUCCESS:
      return {
        ...state,
        loading: false,
        userGreet: action.payload.data.data,
      };
    case type.FETCH_USER_GREET_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        userGreet: null
      };
    case type.INIT_UPDATE_USER_GREET_DATA:
    case type.UPDATE_USER_GREET_DATA_FAIL:
      return {
        ...state,
        ...action.payload
      };
    case type.UPDATE_USER_GREET_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        greetUpdating: false,
        userGreet: action.payload.data.data,
      };
    case type.FETCH_BOT_DATA_FAIL: // later update this with error code
    default:
      return {
        ...state,
        loading: false,
        widgetBotData: null,
        webBotData: null,
      };
  }
};

export default GreetingReducer;
