import { convertFromRaw, EditorState } from "draft-js";
// import { mdToDraftjs } from 'draftjs-md-converter'
import React, { memo, useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { emojiList } from "../../../app.constant";
import bulletlist from "../../../assets/editor/bullet-list.svg";
import bold from "../../../assets/editor/Icon-feather-bold.svg";
import image from "../../../assets/editor/Icon-feather-image.svg";
import italic from "../../../assets/editor/Icon-feather-italic.svg";
import link from "../../../assets/editor/Icon-feather-link.svg";
import emoji from "../../../assets/editor/Icon-feather-smile.svg";
import numberlist from "../../../assets/editor/number-list.svg";
import redo from "../../../assets/editor/redo.svg";
import strike from "../../../assets/editor/strike.svg";
import subscript from "../../../assets/editor/subscript.svg";
import superscript from "../../../assets/editor/superscript.svg";
import underline from "../../../assets/editor/underline.svg";
import undo from "../../../assets/editor/undo.svg";
import video from "../../../assets/editor/video.svg";
import closeimg from "../../../assets/images/close-blk.svg";
import ApiUtil from "../../../utils/apiUtil";
import BubbleBreakOption from "./CustomOptions/BubbleBreakOption";
import EmojiOption from "./CustomOptions/EmojiOptions/EmojiOptions";
import InsertVariableOption from "./CustomOptions/InsertVariableOption";
import LinkOption from "./CustomOptions/LinkOption/LinkOption";
import StickerOption from "./CustomOptions/StickerOption";
import "./index.scss";
import mdToDraftjs from "./converters/mdToDraftJs";

import { useTranslation } from "react-i18next";

interface props {
  index: number;
  showRemoveIcon?: boolean;
  readOnly?: boolean;
  removeIcon?: any;
  item: any;
  onEditorStateChangeHandler(id: number, name: any): void;
  onRemoveGeetingHandler(index: number): void;
  placeholder?: string;
  customToolbar?: any;
  invalidInput?: boolean;
  toolbarHidden?: boolean;
}

export const RichTextArea: React.FunctionComponent<props> = ({
  index,
  item,
  onEditorStateChangeHandler,
  onRemoveGeetingHandler,
  showRemoveIcon = true,
  readOnly,
  removeIcon = closeimg,
  placeholder,
  customToolbar,
  invalidInput,
  toolbarHidden
}) => {
  const { t } = useTranslation("translation");
  const handleEmbedCallback = (link: any) => {
    let videoPattern = /(http(s?):)([/|.|\w|\s|-])*\.(?:mp4)/gim;
    let youtubePattern =
      /(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/(watch\?v=|embed)(\S+)/gim;
    let vimeoPattern =
      /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/gim;
    return link
      .replace(
        videoPattern,
        '<video controls> <source src="$&" type="video/mp4"> </video>'
      )
      .replace(youtubePattern, "https://www.youtube.com/embed/$8")
      .replace(vimeoPattern, "https://player.vimeo.com/video/$4");
  };

  const uploadToCloud = (file_data: File) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("media", file_data);

      ApiUtil.SendAsync<any>({
        url: `media/images/upload`,
        method: "POST",
        data: formData,
      }).then((json: any) => {
        if (json.status === 200) {
          resolve({ data: { link: json.data.data.media.url } });
        } else {
          reject(json);
        }
      });
    });
  };

  const toolbar: any = {
    options: [
      "inline",
      "list",
      "link",
      // "embedded",
      "history",
      "image",
      "emoji",
    ],
    inline: {
      inDropdown: false,
      className: "markdown-toolbar-option",
      component: undefined,
      dropdownClassName: undefined,
      options: [
        "bold",
        "italic",
        // "underline",
        // "strikethrough",
        // "monospace",
        "superscript",
        "subscript",
      ],
      bold: { icon: bold, className: "option-bold" },
      italic: { icon: italic, className: "demo-option-custom" },
      underline: { icon: underline, className: "markdown-toolbar-icon" },
      strikethrough: { icon: strike, className: "markdown-toolbar-icon" },
      // monospace: { icon: monospace, className: "markdown-toolbar-icon" },
      superscript: { icon: superscript, className: "markdown-toolbar-icon" },
      subscript: { icon: subscript, className: "markdown-toolbar-icon" },
    },
    list: {
      inDropdown: false,
      className: "markdown-toolbar-option",
      component: undefined,
      dropdownClassName: undefined,
      options: ["unordered", "ordered"],
      unordered: { icon: bulletlist, className: "emo-option-custom" },
      ordered: { icon: numberlist, className: "emo-option-custom" },
      // indent: { icon: indent, className: undefined },
      // outdent: { icon: outdent, className: undefined },
    },
    link: {
      inDropdown: false,
      className: "markdown-toolbar-option",
      component: LinkOption,
      popupClassName: undefined,
      dropdownClassName: undefined,
      showOpenOptionOnHover: false,
      defaultTargetOption: "_blank",
      options: ["link"],
      link: { icon: link, className: undefined },
      // unlink: { icon: unlink, className: undefined },
      linkCallback: undefined,
    },
    emoji: {
      icon: emoji,
      className: "markdown-toolbar-option",
      component: EmojiOption,
      popupClassName: undefined,
      emojis: emojiList,
    },
    embedded: {
      icon: video,
      className: "markdown-toolbar-option",
      component: undefined,
      popupClassName: undefined,
      embedCallback: handleEmbedCallback,
      defaultSize: {
        height: "auto",
        width: "auto",
      },
    },
    image: {
      icon: image,
      className: "markdown-toolbar-option",
      component: undefined,
      popupClassName: undefined,
      urlEnabled: true,
      uploadEnabled: true,
      alignmentEnabled: false,
      uploadCallback: uploadToCloud,
      previewImage: true,
      inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
      alt: { present: false, mandatory: false },
      defaultSize: {
        height: "auto",
        width: "400",
      },
    },
    history: {
      inDropdown: false,
      className: "markdown-toolbar-option",
      component: undefined,
      dropdownClassName: undefined,
      options: ["undo", "redo"],
      undo: { icon: undo, className: undefined },
      redo: { icon: redo, className: undefined },
    },
  };

  const getContentState = (html: any) => {
    if (!(html instanceof EditorState)) {
      const rawData = mdToDraftjs(html, {});
      const contentState = convertFromRaw(rawData);
      if (contentState) {
        return EditorState.createWithContent(contentState);
      }
      return;
    } else {
      return html;
    }
  };

  const [text, setText] = useState(getContentState(item.text));
  useEffect(() => {
    setText(getContentState(item.text));
  }, [item]);

  const onEditorChange = (greetingText: any) => {
    setText(greetingText);
    onEditorStateChangeHandler(index, greetingText);
  };

  return (
    <>
      <div
        className={`text-editor-wrap ${
          invalidInput ? "border border-danger" : ""
        }`}
      >
        <Editor
          readOnly={readOnly}
          editorState={text}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
          onEditorStateChange={onEditorChange}
          stripPastedStyles={true}
          toolbarCustomButtons={
            customToolbar
              ? []
              : [
                  <BubbleBreakOption />,
                  <StickerOption />,
                  <InsertVariableOption />,
                ]
          }
          toolbar={customToolbar ? customToolbar : toolbar}
          placeholder={
            placeholder ||
            t(
              "Type here. Quick tip: Click on 🔗 in the above toolbar to insert video, image and website links."
            )
          }
          toolbarHidden={toolbarHidden}
        />
        {showRemoveIcon && (
          <button className="btn" onClick={() => onRemoveGeetingHandler(index)}>
            <Image src={removeIcon} alt={"Close Editor"} />
          </button>
        )}
      </div>
    </>
  );
};

export default memo(RichTextArea);
