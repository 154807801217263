import React from "react";
import { useTranslation } from "react-i18next";
import OnlySupperAdmin from "../../components/UI/OnlySupperAdmin/OnlySupperAdmin";
import CanvasIntegration from "./Canvas/CanvasIntegration";
import BlackboardIntegration from "./Blackboard/BlackboardIntegration";
import Desire2learnIntegration from "./Desire2learn/Desire2learnIntegration";
import MoodleIntegration from "./Moodle/MoodleIntegration";

export const Integrations = () => {
  const { t } = useTranslation("translation");

  return (
    <OnlySupperAdmin>
      <div className="page-mid-wraper integration">
        <div className="interAtions-app">
          <h3>{t("Integrations and connected apps")}</h3>
          <p>{t("You can manage your connected apps and improve your workflow")}</p>
        </div>

        <div className="tw-grid tw-grid-cols-3 tw-gap-4 md:max-lg:tw-grid-cols-2 max-md:tw-grid-cols-1">
          <CanvasIntegration />
          {/*<BlackboardIntegration />*/}
          <MoodleIntegration />
          <Desire2learnIntegration />
        </div>
      </div>
    </OnlySupperAdmin>
  );
};

export default Integrations;
