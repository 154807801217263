import * as type from "./actionType";

export interface SessionsCount {
  label: string
  session_count: number
  user_count: number
}

export interface QuizSubmissionsInterface {
  quizSubmissions: { date: string; submission_count: number; }[]
}

export interface CommentsInterface {
  _id: string;
  organisation_id: string;
  agent_id: string;
  author_id: string;
  author_email: string;
  rating: number;
  comment: string;
  updated_at: string;
  created_at: string;
}

export interface QuizSummarySubmissionInterface {
  created_at: string;
  last_submission_at: string;
  quiz_id: string;
  quiz_name: string;
  author_count: number;
  average_score: number;
  completion_count: number;
  quiz_score: number;
  submission_count: number;
}

interface DashboardreducerState {
  loading: boolean,
  baseDataLoading: boolean,
  activeUsersSessionsLoading: boolean,
  error: boolean,
  session_users_count: number,
  sessions_count: number,
  activeUsersSessions: SessionsCount[],
  quizSubmissionsSummary: QuizSummarySubmissionInterface[];
  quizSubmissionsSummaryCount: number;
  conversation_turn_average: number,
  conversation_turn_highest: number,
  top_questions: any[]
  quizSubmissions: QuizSubmissionsInterface[],
  comments: CommentsInterface[],
  weekly_sessions_count: {
    rows?: any,
    summary?: {
      current_week_count: number;
      growth: any;
      previous_week_count: number;
    }
  }[],
  weekly_users_count: {
    rows?: any,
    summary?: {
      current_week_count: number;
      growth: any;
      previous_week_count: number;
    }
  }[],
  ratingSummaryLoading: boolean;
  ratingSummary: {
    rows: any[],
    summary: {
      rating_average: number,
      rating_count: number
    }
  },
}

const initialState: DashboardreducerState = {
  loading: null,
  baseDataLoading: false,
  activeUsersSessionsLoading: false,
  error: null,
  session_users_count: null,
  sessions_count: null,
  conversation_turn_average: null,
  conversation_turn_highest: null,
  quizSubmissions: [],
  top_questions: [],
  activeUsersSessions: [],
  comments: [],
  weekly_sessions_count: [],
  weekly_users_count: [],
  quizSubmissionsSummary: [],
  quizSubmissionsSummaryCount: 0,
  ratingSummaryLoading: false,
  ratingSummary: {
    rows: [],
    summary: {
        rating_average: 0,
        rating_count: 0
    }
  },
};

const Dashboardreducer = (state = initialState, action: any) => {
  switch (action.type) {
    case type.FETCH_DASHBOARD_SESSION_COUNT:
      return {
        ...state,
        ...action.payload
      }
    case type.FETCH_RATINGS_SUMMARY:
      return {
        ...state,
        ...action.payload
      }
    default:
      return {
        ...state
      };
  }
};

export default Dashboardreducer;
