import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import chevronDown from "../../../../assets/images/chevron-down-blk.svg";
import chevronUp from "../../../../assets/images/chevron-up-blk.svg";
import avgScoreImg from "../../../../assets/New-images/Avg-score.svg";
import highScoreImg from "../../../../assets/New-images/highest-score.svg";
import medianScoreImg from "../../../../assets/New-images/median-score.svg";
import TotalAvgImg from "../../../../assets/New-images/total-avg.svg";

import DataTable from "react-data-table-component";
import Moment from "react-moment";
import Select from "react-select";
import BarChart from "../../../../components/UI/BarChart/BarChart";
import Pagination from "../../../../components/UI/Pagination/Pagination";
import { HelperService } from "../../../../services/helper.service";
import { getQuestionAnalyticsSubmissionReport } from "../../../../store/dashboard/actions";
import { customStyles } from "../MatchedUnmatchedQuestions/MatchedUnmatchedUtils";
import PieChart from "../../../../components/UI/PieChart/PieChart";
import { QUIZDETAILBYUSER } from "../../../../config";
import {
  fieldOptionsMapMarkBy,
  getNewTableDisplay,
  MarkingSettingsMap,
} from "../../../Quizzes/QuizQuestionEditor/data";

export default function QuizQuestonBreakdownResultTable(props: any) {
  const { t } = useTranslation("translation");
  const helperService = new HelperService();
  const dispatch = useDispatch();
  const history = useHistory();
  const params: any = useParams();
  const [paginationState, setPaginationState] = useState(null);
  const [paginatedComments, setPaginatedComments] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [keywords, setKeywords] = useState([]);
  const [submissions, setSubmissions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasSubmissionLoaded, setHasSubmissionLoaded] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [filterValue, setFilterValue] = useState(null);
  const columns = [
    {
      name: t("Learner Name"),
      selector: "email",
      sortable: false,
      cell: (row: any) => (
        <div>
          {row.author_first_name} {row.author_last_name}
        </div>
      ),
    },
    {
      name: t("Email/Session"),
      selector: "last_active",
      sortable: false,
      cell: (row: any) => <div>{row.author_email}</div>,
    },
    // {
    //   name: t("Class"),
    //   selector: "total_session",
    //   sortable: false,
    //   cell: (row: any) => <div>{row.author_email}</div>,
    // },
    {
      name: t("Submitted on"),
      selector: "total_session",
      sortable: false,
      cell: (row: any) => (
        <div>
          <Moment format="MM/DD/YYYY hh:mm" utc local>
            {row.updated_at}
          </Moment>
        </div>
      ),
    },
    {
      name: t("Actions"),
      selector: "total_session",
      sortable: false,
      cell: (row: any) => (
        <button
          className="btn p-0 clr-primary font-600 font-sm"
          onClick={() => {
            history.push(
              QUIZDETAILBYUSER.replace(":quizId", params.quizId).replace(
                ":userId",
                row.author_email ? row.author_email : "Not Set"
              ) +
                `?user_id=${row.author_id}&user_type=${
                  row.user_type ? row.user_type : "user"
                }`
            );
          }}
        >
          {t("View Submission")}
        </button>
      ),
      //   <CommentsAction row={row} />
    },
  ];

  useEffect(() => {
    if (filterValue) {
      getSubmissionAPI();
    }
    return () => {};
  }, [filterValue]);

  // useEffect(() => {
  //   paginate(submissions);
  //   return () => {};
  // }, [currentPage, perPage]);

  const getAnalyticsSubmissions = async () => {
    if (hasSubmissionLoaded) {
      setToggle(!toggle);
      return;
    }

    try {
      setToggle(true);
      getSubmissionAPI();
    } catch (error) {
      setHasSubmissionLoaded(false);
      setToggle(false);
    }
  };

  const getSubmissionAPI = async () => {
    try {
      setLoading(true);
      setHasSubmissionLoaded(false);
      const { submissions, keywords }: any = await dispatch(
        getQuestionAnalyticsSubmissionReport(params.quizId, {
          question_id: props.question_id,
          result_bucket: filterValue
            ? filterValue.value
            : props.result_buckets.length
            ? props.result_buckets[0].value
            : null,
        })
      );
      setHasSubmissionLoaded(true);
      setLoading(false);
      setKeywords(keywords);
      paginate(submissions);
    } catch (error) {
      setHasSubmissionLoaded(false);
    }
  };

  const paginate = (comments: any[]) => {
    const paginationData = helperService.paginator(
      comments,
      currentPage,
      perPage
    );
    setPaginationState({
      ...paginationState,
      page: currentPage,
      page_size: perPage,
      total_items: comments.length,
      total_pages: paginationData.total_pages,
    });
    setPaginatedComments([...paginationData.data]);
  };

  /**
   *
   * @param page Pagination on change of page
   */
  const handlePageChange = async (page: number) => {
    await setCurrentPage(page);
  };

  return (
    <div className="border mb-3 radius-10">
      <div className="build-knowbox not-hover p-4 radius-10">
        <h2 className="font-600">
          {t("Question ")}
          {props.index + 1}
        </h2>
        <p
          className="font-16"
          dangerouslySetInnerHTML={{ __html: props.text }}
        ></p>
        <Row>
          <Col lg={5}>
            <h2 className="font-600">{t("Question Analytics")}</h2>
            <Row>
              <Col xs={12} className="mb-3">
                <div className="knowledge-box p-3 build-knowbox d-flex align-items-center text-left">
                  <img src={TotalAvgImg} alt="" />
                  <div className="pl-3">
                    <h2 className="clr-grey-txt7 h6 mb-1">
                      {t("Total Submissions")}
                    </h2>
                    <h2 className="mb-0 clr-pink">{props.submission_count} </h2>
                  </div>
                </div>
              </Col>
              <Col md={6} className="mb-3">
                <div className="knowledge-box p-3 build-knowbox d-flex align-items-center text-left">
                  <img src={avgScoreImg} alt="" />
                  <div className="pl-3">
                    <h2 className="clr-grey-txt7 h6 mb-1">
                      {t("Average Score")}
                    </h2>
                    <h2 className="mb-0 clr-pink">
                      {props.average_score.toFixed(props.decimalPlaces)}/
                      {props.score}
                    </h2>
                  </div>
                </div>
              </Col>
              <Col md={6} className="mb-3">
                <div className="knowledge-box p-3 build-knowbox d-flex align-items-center text-left">
                  <img src={highScoreImg} alt="" />
                  <div className="pl-3">
                    <h2 className="clr-grey-txt7 h6 mb-1">
                      {t("Highest Score")}
                    </h2>
                    <h2 className="mb-0 clr-pink">
                      {props.highest_score.toFixed(props.decimalPlaces)}/
                      {props.score}
                    </h2>
                  </div>
                </div>
              </Col>
              <Col md={6} className="mb-3">
                <div className="knowledge-box p-3 build-knowbox d-flex align-items-center text-left">
                  <img src={medianScoreImg} alt="" />
                  <div className="pl-3">
                    <h2 className="clr-grey-txt7 h6 mb-1">
                      {t("Median Score")}
                    </h2>
                    <h2 className="mb-0 clr-pink">
                      {props.median_score.toFixed(props.decimalPlaces)}/
                      {props.score}
                    </h2>
                  </div>
                </div>
              </Col>
              <Col md={6} className="mb-3">
                <div className="knowledge-box p-3 build-knowbox d-flex align-items-center text-left">
                  <img src={highScoreImg} alt="" />
                  <div className="pl-3">
                    <h2 className="clr-grey-txt7 h6 mb-1">
                      {t("Lowest Score")}
                    </h2>
                    <h2 className="mb-0 clr-pink">
                      {props.lowest_score.toFixed(props.decimalPlaces)}/
                      {props.score}
                    </h2>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>

          <Col lg={7} className="mb-3 mb-lg-0">
            <div className="d-flex align-items-start">
              <div className="learners-choices-box radius-10 p-3 w-100">
                <h2 className="font-600 text-center">{t("Learner Choices")}</h2>
                <Row>
                  {props.type !== "radio" && (
                    <Col md={12}>
                      <div
                        className="px-3"
                        style={{ width: "100%", height: "290px" }}
                      >
                        <BarChart
                          axisLeftLegend={t("Learner Count")}
                          axisBottomLegend={t("Options")}
                          noDateLegend
                          data={props.chartData}
                          keys="value"
                          type={"QUIZANALYTICS"}
                          indexBy="id"
                          enableLabel={true}
                        />
                      </div>
                    </Col>
                  )}
                  {props.type === "radio" && (
                    <>
                      <Col md={6}>
                        <PieChart data={props.chartData} />
                      </Col>

                      <Col md={6}>
                        <h2 className="clr-primary mt-3">{t("Legend")}</h2>
                        <ul className="list-unstyled scroll-wrap">
                          {props.chartData.map((chartData: any) => {
                            return (
                              <li className="d-flex align-items-center flex-wrap py-2">
                                <span
                                  className="mark-indicator"
                                  style={{
                                    backgroundColor: chartData.color,
                                  }}
                                ></span>
                                <div className="clr-grey-txt7 font-sm font-weight-bold mr-4">
                                  {chartData?.id}
                                </div>
                                <div className="clr-grey-txt7 font-sm font-600">
                                  {chartData?.value}
                                </div>
                              </li>
                            );
                          })}
                          {/* <li className="d-flex align-items-center flex-wrap py-2">
                            <span className="mark-indicator mark1"></span>
                            <div className="clr-grey-txt7 font-sm font-weight-bold mr-4">
                              {t("1 Mark")}
                            </div>
                            <div className="clr-grey-txt7 font-sm font-600">
                              {t("123 Learners")}
                            </div>
                          </li>
                          <li className="d-flex align-items-center flex-wrap py-2">
                            <span className="mark-indicator mark2"></span>
                            <div className="clr-grey-txt7 font-sm font-weight-bold mr-4">
                              {t("2 Mark")}
                            </div>
                            <div className="clr-grey-txt7 font-sm font-600">
                              {t("123 Learners")}
                            </div>
                          </li>
                          <li className="d-flex align-items-center flex-wrap py-2">
                            <span className="mark-indicator mark3"></span>
                            <div className="clr-grey-txt7 font-sm font-weight-bold mr-4">
                              {t("3 Mark")}
                            </div>
                            <div className="clr-grey-txt7 font-sm font-600">
                              {t("123 Learners")}
                            </div>
                          </li>
                          <li className="d-flex align-items-center flex-wrap py-2">
                            <span className="mark-indicator mark4"></span>
                            <div className="clr-grey-txt7 font-sm font-weight-bold mr-4">
                              {t("4 Mark")}
                            </div>
                            <div className="clr-grey-txt7 font-sm font-600">
                              {t("123 Learners")}
                            </div>
                          </li> */}
                        </ul>
                      </Col>
                    </>
                  )}
                </Row>
              </div>
              {props.submission_count > 0 && (
                <button
                  className="btn ml-3 mt-5"
                  onClick={getAnalyticsSubmissions}
                >
                  <img
                    src={toggle ? chevronUp : chevronDown}
                    alt=""
                    width="26"
                  />
                </button>
              )}
            </div>
          </Col>
        </Row>

        {loading && (
          <div className="d-flex justify-content-center">
            <div className="spinner-border spinner-border-lg text-primary"></div>
          </div>
        )}

        {props.type === "text" && (
          <>
            <div className="my-3">
              <h2 className="mb-1 font-600">{t("Score By")}</h2>
              <div className="font-600 clr-primary font-16">
                {fieldOptionsMapMarkBy[props.scored_by]}
              </div>
            </div>
            <div className="answer-box-wrap border radius-8 mb-3">
              <h2 className="font-600 bg-blue py-2 px-3 mb-0">
                {t("Recommended Answer")}
              </h2>
              <div className="p-3">
                <div className="font-600 clr-primary font-16">
                  {props?.ideal_answer}
                </div>
              </div>
            </div>
          </>
        )}

        {(props.type === "bullet" || props.type === "newbullet") && (
          <>
            <Row>
              <Col className="my-3">
                <h2 className="mb-1 font-600">{t("Marking setting:")}</h2>
                <div className="font-600 clr-primary font-16">
                  {
                    MarkingSettingsMap[
                      props.position_check ? props.position_check : ""
                    ]
                  }
                </div>
              </Col>
              <Col className="my-3">
                <h2 className="mb-1 font-600">{t("Score By")}</h2>
                <div className="font-600 clr-primary font-16">
                  {fieldOptionsMapMarkBy[props.scored_by]}
                </div>
              </Col>
            </Row>
            <div className="answer-box-wrap border radius-8 mb-3">
              <h2 className="font-600 bg-blue py-2 px-3 mb-0">
                {t("Recommended Answer")}
              </h2>
              <div className="p-3">
                <ul className="font-16 mb-0 list-unstyled">
                  {props.hasOwnProperty("ideal_answer_items") &&
                    props.ideal_answer_items.map(
                      (items: any, index: number) => {
                        return (
                          <li
                            className="py-2 clr-primary"
                            key={items.text + "-" + index}
                          >
                            {items?.text}
                          </li>
                        );
                      }
                    )}
                </ul>
              </div>
            </div>
          </>
        )}

        {props.type === "table" && (
          <>
            <Row>
              <Col className="my-3">
                <h2 className="mb-1 font-600">{t("Marking setting:")}</h2>
                <div className="font-600 clr-primary font-16">
                  {
                    MarkingSettingsMap[
                      props.position_check ? props.position_check : ""
                    ]
                  }
                </div>
              </Col>
              <Col className="my-3">
                <h2 className="mb-1 font-600">{t("Score By")}</h2>
                <div className="font-600 clr-primary font-16">
                  {fieldOptionsMapMarkBy[props.scored_by]}
                </div>
              </Col>
            </Row>
            <div>
              <h2 className="font-600 mb-3">{t("Recommended Answer:")}</h2>
              <div className="table-responsive recommended-ans-table">
                {getNewTableDisplay(
                  props.ideal_answer_items,
                  props.answer_key_settings
                )}
              </div>
            </div>
          </>
        )}
      </div>
      {props.submission_count > 0 &&
        paginatedComments &&
        toggle &&
        !loading && (
          <>
            <div className="graph-box mt-3 shadow-none mb-0">
              <div className="blue-filters d-flex flex-wrap justify-content-between align-items-center px-3">
                <h2 className="mb-0 font-600">{t("Result Data")}</h2>
                <div className="d-flex flex-wrap sugested-next-ques">
                  <Select
                    className="select-sort select-target"
                    classNamePrefix="select-target-prefix"
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    value={
                      filterValue
                        ? filterValue
                        : props.result_buckets.length
                        ? props.result_buckets[0]
                        : null
                    }
                    onChange={(e: any) => {
                      setFilterValue(e);
                    }}
                    options={props.result_buckets}
                  />
                </div>
              </div>

              {keywords.length > 0 && (
                <div className="answer-box-wrap border radius-8">
                  <h2 className="font-600 bg-blue py-2 px-3 mb-0">
                    {t("Common Keywords Used")}
                  </h2>
                  <div className="p-3">
                    <Row>
                      {keywords.map((keyword: string, index: number) => {
                        return (
                          <Col md={3} key={keyword + "_" + index}>
                            <div className="font-600 clr-primary font-16">
                              {keyword}
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                  </div>
                </div>
              )}

              <div className="d-flex flex-wrap justify-content-between align-items-center p-3">
                <h2 className="mb-0 font-600">{t("Learner submission")}</h2>
              </div>

              <div className="px-3">
                <DataTable
                  className=""
                  noContextMenu
                  noHeader
                  columns={columns}
                  data={paginatedComments}
                  customStyles={customStyles}
                  // sortIcon={<ChartTableSortIcon />}
                />

                {/* {comments && comments.length > 0 && paginationState && (
                  <Pagination
                    currentPage={currentPage}
                    pagination={{
                      ...paginationState,
                    }}
                    paginationPerPage={perPage}
                    pageChange={(newPerPage: number, page: number) => {
                      setPerPage(newPerPage);
                      setCurrentPage(1);
                    }}
                    onChangePage={handlePageChange}
                    hideSuggestedQuestion
                  />
                )} */}
              </div>
            </div>
          </>
        )}
    </div>
  );
}
