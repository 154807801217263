import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Image,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import cx from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useHistory, useParams } from "react-router";
import chevLeft from "../../assets/New-images/chevron-left-blk.svg";
import backimg from "../../assets/images/arrow-left.svg";
import * as _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import infoIcon from "../../assets/New-images/infor-red-circle.svg";
import { getQuizByQuizId } from "../../store/dashboard/actions";
import ReportAlerts from "./QuizAlerts";
import {
  getAllButtonOptions,
  getAllUserGroups,
} from "../../store/questions/actions";
import {
  generateQuestionBank,
  generateQuestionBankV2,
  getLinkedQuestionsBasedOnQuizAndQuestions,
  getQuizDocumentTableJobsByJobId,
  getQuizDocumentTableJobsQuestions,
  getQuizFeatures,
  getQuizQuestionBankGenerateTags,
  questionBankLinkDocumentSections,
  quizDocumentQuestions,
  updateQuestionFromQuestionBank,
  updateQuiz as updateQuizAction,
} from "../../store/quizzes/actions";
import ScoreRange from "./ScoreRange";

import classNames from "classnames";
import { Container as DragContainer, Draggable } from "react-smooth-dnd";
import ConfirmationModal from "../../components/UI/ConfirmationModal/ConfirmationModal";
import UpdateModuleModal from "../../components/UI/KnowledgeDatatableComponent/UpdateModuleModal";
import ManageLevelCompact from "../../components/UI/ManageLevelCompact/ManageLevelCompact";
import PermissionWrapper from "../../components/UI/PermissionWrapper/PermissionWrapper";
import {
  addEntityToKnowledgeGroup,
  bulkAssignModules,
  exportQuizzesAndQuestions,
} from "../../store/knowledge/actions";
import { getButtonOptions } from "../../store/questions/selector";
import FeedbackAndTutoring from "./FeedbackAndTutoring/FeedbackAndTutoring";
import QuizQuestionEditor from "./QuizQuestionEditor/QuizQuestionEditor";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import QuizDocumentPreview from "./QuizDocumentPreview";
import { HelperService } from "../../services/helper.service";
import {
  IMPORTQUESTIONBANK_PREVIEWS_TAG,
  QUIZZES,
  QUIZZES_BETA,
} from "../../config";
import CreateQuestionsOptions from "./QuizQuestionBank/CreateQuestionsOptions";
import { TwoButtonModal } from "../../components/UI/Common";
import QuizService from "../../services/QuizService";
import Loading from "../../components/UI/Loading/Loading";
import RichTextArea from "../../components/UI/RichTextArea/RichTextArea";
import Toggle from "../../components/UI/Toggle";
import RubricDescription from "./OldPortalComponents/RubricDescription";
import SkillsAssetment from "./OldPortalComponents/SkillsAssetment";
import { convertToEditorFormat, convertToMarkdownFormat } from "../../utils/appUtils";
import SunEditorUI from "../../components/UI/SunEditor/SubEditor";

const quizService = new QuizService();

const DEFAULT_VALUES = [
  {
      "text": "",
      "keyword_scores": {},
      "score": 1
  },
  {
      "text": "",
      "keyword_scores": {},
      "score": 1
  },
  {
      "text": "",
      "keyword_scores": {},
      "score": 1
  }
];

function EditCreateQuiz(props: any) {
  const { t } = useTranslation();
  const helper = new HelperService();
  const emptyQuestion: any = {
    is_saved: false,
    type: "radio",
    text: "",
    score: 1,
    options: [
      {
        text: "",
        is_correct: false,
      },
      {
        text: "",
        is_correct: false,
      },
    ],
  };
  const isMgsFromReactApp = process.env.REACT_APP_CISCO === "mgs";
  const params: any = useParams();
  const history = useHistory();
  const useLocatio = useLocation();
  const dispatch = useDispatch();
  const buttonOptionsSelector = useSelector(getButtonOptions);
  const [IsLoading, setIsLoading] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  const [transformedQuestions, setTransformedQuestions] = useState([]);
  const [savedQuestions, setSavedQuestions] = useState([]);
  const [quizDetail, setQuizDetail] = useState(null);
  const [quizName, setQuizName] = useState("");
  const [displayMode, setDisplayMode] = useState("single_question");
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [toBeDeleted, setToBeDeleted] = useState([]);
  const [addNew, setAddNew] = useState(false);
  const [quizDescription, setQuizDescription] = useState("");
  const [showAnswer, setShowAnswer] = useState(true);
  const [conditions, setConditions] = useState([]);
  const [userGroupData, setUserGroupData] = useState([]);
  const [quizQuestionDropdownData, setQuizQuestionDropdownData] = useState([]);
  const [buttonOptions, setButtonOptions] = useState([]);
  const [topicDropdownData, setTopicDropdownData] = useState([]);
  const [questionIdLabelMap, setQuestionIdLabelMap] = useState({});
  const [hideMarks, setHideMarks] = useState(false);
  const [showScore, setShowScore] = useState(true);
  const [showScoreInPercentage, setShowScoreInPercentage] = useState(false);
  const [questionCount, setQuestionCount] = useState(null);
  const [showMainButton, setShowMainButton] = useState(true);
  const [mainButtonLabel, setMainButtonLabel] = useState("Continue to chat");
  const [markQuizRequired, setMarkQuizRequired] = useState(false);
  const [quizTopic, setQuizTopic] = useState({});
  const [totalQuizScore, setTotalQuizScore] = useState(0);
  const [isWelcomeEnabled, setIsWelcomeEnabled] = useState(true);
  const [selectedQuizLabels, setSelectedQuizLabels] = useState([]);
  const [enableLearning, setEnableLearning] = useState(false);
  const [decimalPlaces, setDecimalPlaces] = useState("2");
  const [updatingQuizQuestionsFromParent, SetUpdatingQuizQuestionsFromParent] =
    useState(false);
  const [extractQuestionClicked, SetExtractQuestionClicked] = useState(false);
  const [hasAnyError, setHasAnyError] = useState([]);
  const [componentHasError, setComponentHasError] = useState([]);
  const [componentTruthy, setComponentTruthy] = useState(false);
  const [internalLoading, setInternalLoading] = useState(false);
  const [openModal, SetOpenModal] = useState(false);
  const [quizSubmitted, setQuizSubmitted] = useState(false);
  const [extractedTables, setExtractedTables] = useState([]);
  const [extractionStatus, setExtractionStatus] = useState("");
  const [activeKey, setActiveKey] = useState("admins");
  const [quizTotalScore, setQuizTotalScore] = useState(0);
  const ref = useRef([]);
  const backupRef = useRef([]);
  let invervalGetQna: any = null;
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [showSaveQuestionsToQuestionBank, setShowSaveQuestionsToQuestionBank] =
    useState(false);
  const [showKNowledgebaseOrNot, setShowKNowledgebaseOrNot] = useState(false);
  const [essayGrader, setEssayGrader] = useState(null);
  const [essayConfig, setEssayConfig] = useState(null);
  const [isSaving, setSaving] = useState(false);

  const [docContent, setDocContent] = useState(
    props.location &&
      props.location.state &&
      props.location.state.documentContent
      ? props.location.state?.documentContent
      : ""
  );
  const [documentId, setDocumentId] = useState([]);

  const [rolePlayGoal, setRolePlayGoal] = useState("");
  const [rolePlayTutorRole, setRolePlayTutorRole] = useState("");
  const [rolePlayLearnerRole, setRolePlayLearnerRole] = useState("");
  const [rolePlayScenarioDescription, setRolePlayScenarioDescription] = useState("");
  const [tableValues, setTableValues] = useState([
    {
        "text": "",
        "keyword_scores": {},
        "score": 1
    },
    {
        "text": "",
        "keyword_scores": {},
        "score": 1
    },
    {
        "text": "",
        "keyword_scores": {},
        "score": 1
    }
  ]);

  const [idealAnswerItems, setIdealAnswerItems] = useState([
      { text: "", keyword_scores: {}, score: 1 },
      { text: "", keyword_scores: {}, score: 1 },
    ]
  );
  const [newTableRowCount, setNewTableRowCount] = useState(0);
  const [newTableColumnCount, setNewTableColumnCount] = useState(3);

  const tabList = [
    {
      label: t("Build Quiz"),
    },
    {
      label: t("Update Settings"),
    },
    {
      label: t("Configure Results"),
    },
    {
      label: t("View All Submissions"),
    },
  ];

  let learningTabList = [
    {
      label: t("Build Quiz"),
    },
    {
      label: t("Update Settings"),
    },
    {
      label: t("Configure Results"),
    },
    {
      label: t("View All Submissions"),
    },
    {
      label: t("Review Submissions"),
    },
    {
      label: t("Report Alerts"),
    },
  ];
  useEffect(() => {
    if (buttonOptionsSelector && buttonOptionsSelector.length) {
      setButtonOptions([...buttonOptionsSelector]);
    }
  }, [buttonOptionsSelector]);

  useEffect(() => {
    let quizId = props.quizId ? props.quizId : props.match.params.quizId;
    const query = new URLSearchParams(useLocatio.search);
    const document = query.get("document");
    if (document) {
      const doc = document.split(",");
      setDocumentId([...doc]);
    }
    if (
      props.location &&
      props.location.state &&
      !props.location.state.selectedFileType
    ) {
      setShowSaveQuestionsToQuestionBank(true);
    }

    if ("undefined" === typeof props.location.state) {
      setShowSaveQuestionsToQuestionBank(true);
    }

    // if (
    //   props.location &&
    //   props.location.state &&
    //   props.location.state.createFromQuestionBank
    // ) {
    //   getQuiz(quizId);
    // }
  }, [props.location]);

  useEffect(() => {
    getTopics();
    let quizId = props.quizId ? props.quizId : props.match.params.quizId;
    if (
      typeof props.location.state !== "undefined" &&
      props.location.state.isMgs
    ) {
    } else {
      getQuiz(quizId);
    }
    dispatch(getAllButtonOptions(true));
    dispatch(getAllUserGroups());
    // getButtonOptions();
    // getUserGroup();
    // getQuizLabels();
    getLearningFeature();
    if (
      typeof props.location.state !== "undefined" &&
      props.location.state.selectedFileType &&
      props.location.state.quizArray &&
      props.location.state.quizArray.questions
    ) {
      backupRef.current = props.location.state.quizArray.questions;
      transformAllQuestions(props.location.state.quizArray.questions);
      generateQuestionIdLabelMap(props.location.state.quizArray.questions);
      transformQuizDropdownData(props.location.state.quizArray.questions);
      setSavedQuestions(props.location.state.quizArray.questions);
      if (!props.location.state?.createFromTestFile) {
        SetOpenModal(true);
        helper.showToaster(
          t(
            'Questions imported successfully. You can now edit the questions/answers. Click on "Save" to save this quiz?'
          ),
          "success"
        );
      }
    }
  }, []);

  useEffect(() => {
    if (topicDropdownData && topicDropdownData.length > 0 && quizDetail) {
      if (quizDetail.topic_id) {
        setQuizTopic({
          value: quizDetail.topic_id,
          label: getLevelNames(topicDropdownData, quizDetail.topic_id),
        });
      }
    }
  }, [topicDropdownData, quizDetail]);

  const getLearningFeature = async () => {
    try {
      const response: any = await dispatch(getQuizFeatures());
      if (response.features && response.features.length !== 0) {
        setEssayGrader(
          response.features.findIndex(
            (feature: any) => feature.name === "essay_grader"
          ) >= 0
        );
        setEssayConfig(
          response.features.find(
            (feature: any) =>
              feature?.name === "essay_grader" && feature?.status === "active"
          )
        );
        for (let i = 0; i < response.features.length; i++) {
          if (response.features[i].name === "learning") {
            setEnableLearning(true);
            break;
          }
        }
      } else {
        setEnableLearning(false);
      }
    } catch (error) {
      setEnableLearning(false);
    }
  };

  const getTopics = async () => {
    try {
      // const response: any = await dispatch(getAllLevels());
    } catch (error) {}
  };


  const onAnswerKeyTableChange = (
    value: any,
    rowCount: any,
    columnCount: any
  ) => {
    setIdealAnswerItems(value);
    setNewTableRowCount(rowCount);
    setNewTableColumnCount(columnCount);
  };

  useEffect(() => {
    if (!quizDetail) return;

    if (quizDetail?.type === "roleplay") {
      setActiveKey("roleplay");
    } else {
      setActiveKey("admins");
    }
  }, [quizDetail]);

  const getQuiz = async (quizId: string) => {
    try {
      setIsLoading(true);
      setIsLoad(false);
      const { quizDetail }: any = await dispatch(getQuizByQuizId(quizId));

      if (quizDetail.type === "roleplay") {
        const { roleplay_goal, roleplay_learner_role, roleplay_scenario_description, roleplay_tutor_role, answer_key_settings, ideal_answer_items } = quizDetail.questions[0];
        setRolePlayGoal(roleplay_goal ?? "");
        setRolePlayLearnerRole(roleplay_learner_role ?? "");
        setRolePlayScenarioDescription(roleplay_scenario_description ?? "");
        setRolePlayTutorRole(roleplay_tutor_role ?? "");
        if (ideal_answer_items && ideal_answer_items.length > 0) {
          setTableValues(ideal_answer_items);
          setIdealAnswerItems(ideal_answer_items);
        }
        if (answer_key_settings) {
          setNewTableRowCount(answer_key_settings.row_count);
        }
      }

      setQuizDetail(quizDetail);
      setQuizName(quizDetail.name);
      setQuizDescription(quizDetail.description ?? "");
      setDisplayMode(quizDetail.display_mode);

      if (quizDetail.display_score != null) {
        setHideMarks(!quizDetail.display_score);
      } else {
        setHideMarks(false);
      }

      if (quizDetail.display_questions_count) {
        setQuestionCount(quizDetail.display_questions_count.toString());
      } else {
        setQuestionCount(null);
      }

      if (
        "score_decimal_number" in quizDetail &&
        quizDetail.score_decimal_number !== null
      ) {
        setDecimalPlaces(quizDetail.score_decimal_number.toString());
      } else {
        setDecimalPlaces("2");
      }

      if (quizDetail && quizDetail.questions.length) {
        const group = quizDetail.questions
          .filter((i: any) => i.type === "group")
          .map((i: any) => i.id);
        let totalScore = 0;
        setQuizTotalScore(
          quizDetail.questions.reduce((score: any, question: any) => {
            totalScore = score;
            const groupChild = group.some((i: any) => i === question.id);
            if (groupChild) {
              return totalScore;
            }
            return totalScore + question.score;
          }, 0)
        );
      }

      if (quizDetail.is_required) {
        setMarkQuizRequired(quizDetail.is_required);
      }

      if (quizDetail.result_settings) {
        setShowAnswer(quizDetail.result_settings.show_answers);
        setShowScore(quizDetail.result_settings.show_scores);
        setShowScoreInPercentage(
          quizDetail.result_settings.show_scores_in_percentage
        );
        setShowMainButton(quizDetail.result_settings.show_button);
        setMainButtonLabel(quizDetail.result_settings.button_label);

        let temp = [];
        temp = addIdToConditions(quizDetail.result_settings.conditions);
        setConditions(temp);
      }

      if (quizDetail.questions && quizDetail.questions.length > 0) {
        backupRef.current = quizDetail.questions;
        transformAllQuestions(quizDetail.questions);
        generateQuestionIdLabelMap(quizDetail.questions);
        transformQuizDropdownData(quizDetail.questions);
        setSavedQuestions(quizDetail.questions);
      } else {
        if (
          typeof props.location.state !== "undefined" &&
          !props.location.state.selectedFileType
        ) {
          addNewQuestion();
        }
      }

      if (quizDetail.questions) {
        computeTotalScore(quizDetail.questions);
      }

      if ("is_welcome_enabled" in quizDetail) {
        setIsWelcomeEnabled(quizDetail.is_welcome_enabled);
      }

      if (quizDetail.labels) {
        let temp = [];
        temp = quizDetail.labels.map((item: any) => ({
          label: item,
          value: item,
        }));
        setSelectedQuizLabels(temp);
      }
      setIsLoading(false);
    } catch (error) {} finally {
      setIsLoading(false);
    }
  };

  // get module names
  const getLevelNames = (topicDropdownData: any, level: any) => {
    let moduleAsString = [];
    // levels.forEach(level => {
    const moduleObj = topicDropdownData.find((m: any) => m.value === level);
    if (moduleObj) {
      moduleAsString.push(moduleObj.label);
    }
    // })
    return moduleAsString.length === 0
      ? "Uncategorised"
      : moduleAsString.join(", ");
  };

  const transformAllQuestions = (questions: any) => {
    let temp: any = {};
    questions.forEach((question: any, index: number) => {
      if (question.parent_id && temp[question.parent_id]) {
        temp[question.parent_id] = {
          ...temp[question.parent_id],
          subquestions:
            "subquestions" in temp[question.parent_id]
              ? temp[question.parent_id].subquestions.concat([question])
              : [].concat([question]),
        };
      } else {
        temp[question.id ? question.id : uuidv4()] = question;
      }
    });
    setTransformedQuestions(temp);
  };

  const computeTotalScore = (questions: any) => {
    let totalScore = 0;

    questions.forEach((question: any) => {
      if (question.score) {
        totalScore += question.score;
      }
    });

    setTotalQuizScore(totalScore);
  };

  const transformQuizDropdownData = (questions: any) => {
    let quizDropdown: any = [];

    questions.forEach((item: any, index: number) => {
      if (item && item.text) {
        quizDropdown.push({
          value: item.id,
          label: <div dangerouslySetInnerHTML={{ __html: item.text }}></div>,
        });
      }
    });

    setQuizQuestionDropdownData(quizDropdown);
  };

  const generateQuestionIdLabelMap = (questions: any) => {
    let tempMap: any = {};
    questions.forEach((question: any, index: number) => {
      tempMap[question.id] = {
        label: <div dangerouslySetInnerHTML={{ __html: question.text }}></div>,
        options: question.options,
        type: question.type,
      };
    });

    setQuestionIdLabelMap(tempMap);
  };

  ///////////////////// QUIZ SETTINGS ////////////////////
  const removeScoreCondition = (conditionIndex: number) => {
    let temp = [...conditions];
    temp.splice(conditionIndex, 1);
    setConditions([...temp]);
  };

  const addScoreRangeCondition = (isFeedback: boolean = false) => {
    if (isFeedback && quizQuestionDropdownData.length === 0) {
      return;
    }
    let temp = [...conditions];
    temp.push({
      id: uuidv4(),
      test: isFeedback
        ? {
            max_score: 0,
            min_score: 0,
            question_id: quizQuestionDropdownData[0].value,
          }
        : {},
      show: {},
    });
    setConditions([...temp]);
  };

  const addIdToConditions = (data: any) => {
    let temp: any = [];
    data.forEach((item: any, index: number) => {
      if (!item.id) {
        temp[index] = {
          ...item,
          id: uuidv4(),
        };
      } else {
        temp[index] = { ...item };
      }
    });
    return temp;
  };

  const onFieldChange = (value: any, key: string) => {
    if (key === "name") {
      setQuizName(value);
    }
  };

  const onQuizDescriptionChange = (value: string) => {
    setQuizDescription(value);
  };

  const transformData = (data: any) => {
    let temp: any = [];
    data.forEach((element: any) => {
      if (!element.buttonId) {
        temp.push({
          ...element,
          buttonId: uuidv4(),
        });
      } else {
        temp.push({
          ...element,
        });
      }
    });
    return temp;
  };

  const onConditionQuestionDropdownChange = (
    value: any,
    key: any,
    conditionIndex: any
  ) => {
    let temp = [...conditions];
    if (key === "quizQuestion") {
      if (value === 0) {
        temp[conditionIndex].test = {};
      } else {
        temp[conditionIndex].test = {
          question_id: value,
        };
      }
    } else if (key === "requiresCorrect") {
      if (value === "correct" || value === "incorrect") {
        temp[conditionIndex].test = {
          question_id: temp[conditionIndex].test.question_id,
          requires_correct: value === "correct",
        };
      } else {
        temp[conditionIndex].test = {
          question_id: temp[conditionIndex].test.question_id,
          option_id: value,
          is_chosen: true,
        };
      }
    }
    setConditions(temp);
  };

  const onConditionFieldChange = (
    value: any,
    key: any,
    conditionIndex: any
  ) => {
    let temp = [...conditions];
    if (key === "minScore") {
      temp[conditionIndex].test = {
        ...temp[conditionIndex].test,
        min_score: parseFloat(value),
      };
    } else if (key === "maxScore") {
      temp[conditionIndex].test = {
        ...temp[conditionIndex].test,
        max_score: parseFloat(value),
      };
    } else if (key === "message") {
      temp[conditionIndex].show.message = value;
    } else if (key === "userGroup") {
      if (value) {
        temp[conditionIndex].trigger = {
          name: "add-user-to-group",
          data: {
            group_id: value,
          },
        };
      } else {
        temp[conditionIndex].trigger = {};
      }
    }
    setConditions([...temp]);
  };

  const onButtonAdd = (data: any, conditionIndex: any) => {
    let temp = [...conditions];
    let tempButtons: any = [];
    if (temp[conditionIndex].show.buttons) {
      tempButtons = [...temp[conditionIndex].show.buttons];
    }
    tempButtons.push(data);

    temp[conditionIndex].show.buttons = tempButtons;

    setConditions(temp);
  };

  const onButtonLabelEdit = (event: any, id: any, conditionIndex: any) => {
    let temp = [...conditions];

    let tempButtons = [...temp[conditionIndex].show.buttons];
    tempButtons[id].label = event.target.value;

    temp[conditionIndex].show.buttons = tempButtons;

    setConditions(temp);
  };

  const onButtonQuestionSelect = (
    value: any,
    index: any,
    conditionIndex: any
  ) => {
    let temp = [...conditions];

    let tempButtons = [...temp[conditionIndex].show.buttons];
    let [button_type, button_value] = value.value.split("::");

    tempButtons[index].type = button_type;

    if ("question" === button_type) {
      tempButtons[index].question = button_value;
    } else {
      tempButtons[index].reference_id = button_value;
    }

    temp[conditionIndex].show.buttons = tempButtons;

    setConditions(temp);
  };

  const deleteButtonRow = (id: any, conditionIndex: any) => {
    let temp = [...conditions];

    let tempButtons = [...temp[conditionIndex].show.buttons];
    tempButtons.splice(id, 1);

    temp[conditionIndex].show.buttons = tempButtons;

    setConditions(temp);
  };

  const onSort = (data: any, conditionIndex: any) => {
    try {
      let temp = [...conditions];
      let condition = temp[conditionIndex];
      if (condition && condition.show && condition.show.buttons) {
        temp[conditionIndex].show.buttons = [...data];
      } else {
        const updatedCondition = {
          ...condition,
          show: {
            ...condition.show,
            buttons: [...data],
          },
        };
        temp[conditionIndex] = updatedCondition;
      }
      setConditions(temp);
    } catch (error) {}
  };
  ///////////////////// QUIZ SETTINGS ////////////////////

  ///////////////////// QUIZ LEVEL ////////////////////
  const [showRenameModal, setShowRenameModal] = React.useState(false);
  const [newModuleAdded, setNewModuleAdded] = React.useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [selectedParentId, setSelectedParentId] = React.useState(null);
  const [selectedModuleName, setSelectedModuleName] = React.useState("");
  const [moduleEditMode, setModuleEditMode] = React.useState(false);
  const [moduleDepth, setModuleDepth] = React.useState(0);
  const onSelectGroupId = (level: string) => {
    setSelectedGroupId(level);
    // if (!props.editMode) {
    //   dispatch(localUserQuestionUpdate("knowledge_group_id", level));
    // }
  };

  const updateParentHandler = (
    name?: string,
    parent_id?: string,
    isEdit: boolean = false,
    depth: number = 0
  ) => {
    setSelectedParentId(parent_id);
    setSelectedModuleName(name);
    setModuleEditMode(isEdit);
    setModuleDepth(depth);
    updateModalHandler();
  };

  const updateModalHandler = () => {
    setNewModuleAdded(false);
    setShowRenameModal(!showRenameModal);
  };

  const confirmRenameHandler = async (data: any) => {
    // getAllYearsFun(false);
    setNewModuleAdded(true);
    setModuleEditMode(false);
    updateModalHandler();
  };

  ///////////////////// QUIZ LEVEL ////////////////////

  ///////////////////// QUIZ LEVEL HANDLER ////////////////////
  const updateQuizLevelHandler = async () => {
    try {
      await dispatch(
        addEntityToKnowledgeGroup(selectedGroupId, "quiz", [quizDetail._id])
      );
      onSelectGroupId(null);
      getQuiz(quizDetail._id);
    } catch (error) {}
  };

  ///////////////////// QUIZ LEVEL HANDLER ////////////////////

  ///////////////////// QUIZ EDITOR ////////////////////
  const setErrorIfAny = async (
    temp: any,
    questionId: any,
    isGenerateKeywords: any,
    isLinkSections: any,
    questionNumber: any
  ) => {
    setIsLoading(false);
    setIsLoad(false);
    const questions: any[] = [...hasAnyError];
    questions.push(temp);
    ref.current.push(temp);
    setHasAnyError(questions);
    const hasInvalidOption = _.uniqBy(ref.current, "id")
      .filter(function (el: any) {
        return el;
      })
      .filter(function (el: any) {
        return el.invalid;
      });
    if (
      ref.current.length === Object.keys(transformedQuestions).length &&
      hasInvalidOption.length === 0
    ) {
      const questionsAfterGrouping = _.uniqBy(ref.current, "id");
      const toBeSaved: any[] = [];

      questionsAfterGrouping.forEach((question: any) => {
        if (question.data) {
          let quiz_question_id: string = null;
          let sub_quiz_question_id: string = null;
          let subQuetion: any = null;
          if (
            transformedQuestions[question.id] &&
            "quiz_question_id" in transformedQuestions[question.id]
          ) {
            quiz_question_id =
              transformedQuestions[question.id].quiz_question_id;
          }
          question.data.forEach((subQuestion: any) => {
            let questionObject: any = {
              ...subQuestion,
              difficulty_level: subQuestion.hasOwnProperty("difficulty_level")
                ? subQuestion.difficulty_level
                : 1,
            };
            if (
              transformedQuestions[question.id] &&
              transformedQuestions[question.id].subquestions.length > 0
            ) {
              subQuetion = transformedQuestions[question.id].subquestions.find(
                (subQue: any) => subQue.id === subQuestion.id
              );
              sub_quiz_question_id =
                subQuetion && "quiz_question_id" in subQuetion
                  ? subQuetion.quiz_question_id
                  : null;
            }

            if (quiz_question_id || sub_quiz_question_id) {
              questionObject = {
                ...questionObject,
                quiz_question_id: sub_quiz_question_id
                  ? sub_quiz_question_id
                  : !subQuetion && quiz_question_id
                  ? quiz_question_id
                  : null,
              };
            }

            // Delete only in case if there's no value
            if (!questionObject.quiz_question_id) {
              delete questionObject.quiz_question_id;
            }

            toBeSaved.push(questionObject);
          });
        } else {
          let quiz_question_id: string = null;
          if (
            transformedQuestions[question.id] &&
            "quiz_question_id" in transformedQuestions[question.id]
          ) {
            quiz_question_id =
              transformedQuestions[question.id].quiz_question_id;
          }
          let questionObject: any = { ...question };
          if (quiz_question_id) {
            questionObject = {
              ...questionObject,
              quiz_question_id: quiz_question_id,
            };
          }
          toBeSaved.push(questionObject);
        }
      });

      // console.log(" YOU ROCK ", toBeSaved);
      // console.log("questionsAfterGrouping", questionsAfterGrouping);
      // console.log("transformedQuestions1", transformedQuestions);

      updateQuizQuestions(
        toBeSaved,
        null,
        (typeof props.location.state !== "undefined" &&
          props.location.state.selectedFileType) ||
          (props.location.state &&
            props.location.state.origin &&
            props.location.state.origin === "create")
      );
    }

    return;
  };

  const updateQuizQuestionsFromParent = async () => {
    ref.current = [];
    // setInternalLoading(true);
    // setIsLoading(true)
    setQuizSubmitted(true);
    setHasAnyError([]);
    setComponentHasError([]);
    setComponentTruthy(true);
    SetUpdatingQuizQuestionsFromParent(true);
    SetExtractQuestionClicked(false);
    return;
  };

  const updateQuizQuestions = async (
    toUpdateQuestions: any,
    operation: any = null,
    replaceHistory: boolean = false
  ) => {
    computeTotalScore(toUpdateQuestions);
    let quizId = props.quizId ? props.quizId : props.match.params.quizId;
    setIsLoading(true);
    setIsLoad(true);
    const query = new URLSearchParams(useLocatio.search);
    const group = query.get("group");
    try {
      if (
        typeof props.location.state !== "undefined" &&
        props.location.state.isMgs
      ) {
        // let saveQuestions = toUpdateQuestions
        if (props.location.state?.school) {
          toUpdateQuestions.forEach(
            (q: any) => (q.custom_attribute_1 = props.location.state?.school)
          );
        }
        const response: any = await dispatch(
          generateQuestionBank({ questions: toUpdateQuestions }, false)
        );
        const finalGroup = props.location.state.group || group;
        let generatedQuestions = response.quiz_questions;
        if (props.location.state && finalGroup && response) {
          const nodes: string[] = response.quiz_questions.map((q: any) => q.id);
          await dispatch(
            bulkAssignModules(finalGroup, nodes, "question", false)
          );

          generatedQuestions = await Promise.all(
            response.quiz_questions.map(async (q: any) => {
              const response: any = await dispatch(
                getQuizQuestionBankGenerateTags(q.id)
              );
              return response?.quiz_question;
            })
          );

          if (props.location.state.isMgs) {
            generatedQuestions = await Promise.all(
              response.quiz_questions.map(async (q: any) => {
                const response: any = await dispatch(
                  questionBankLinkDocumentSections(q.id)
                );
                return response?.quiz_question;
              })
            );
          }
        }
        setIsLoading(false);
        helper.showToaster(t("Saved and Generated Keywords"), "success");
        if (props.location.state?.createFromTestFile) {
          history.push(IMPORTQUESTIONBANK_PREVIEWS_TAG, {
            questionList: generatedQuestions
              .filter((q: any) => !q.parent_id)
              .map((q: any) => q.id),
            selectedFileType: "customtemplates",
            createFromTestFile: true,
            quizId: props.location.state?.quizId,
            school: props.location.state?.school,
            tagReview: true,
          });
          return;
        } else {
          history.push(isMgsFromReactApp ? QUIZZES : QUIZZES_BETA);
        }
      } else {
        const response: any = await dispatch(
          updateQuizAction(quizId, {
            questions: toUpdateQuestions,
          })
        );
        setIsLoading(false);

        transformAllQuestions(response.quiz.questions);
        if (replaceHistory) {
          setIsLoad(false);
          getQuiz(quizId);
          history.replace(
            `/quizzes/edit/${quizId}${
              documentId && documentId.length > 0
                ? `?document=${documentId.join(",")}`
                : ""
            }`,
            undefined
          );
          return;
        } else {
          setIsLoad(false);
          getQuiz(quizId);
        }
      }
    } catch (error) {
      setIsLoading(false);
      setIsLoad(false);
    }
  };

  const onQuizSave = async () => {
    let quizId = props.quizId ? props.quizId : props.match.params.quizId;
    let _conditions: any[] = [...conditions];
    if (_conditions) {
      _conditions.map((part: any) => {
        if (part.test.question_id) {
          part.test.question_id = part.test.question_id.toString();
        }
        if (part.test.option_id) {
          part.test.option_id = part.test.option_id.toString();
        }
        if (part.show.message === null) {
          part.show.message = "";
        }
        if (!part.test.min_score || part.test.min_score === null) {
          part.test.min_score = 0;
        }
        if (!part.test.max_score || part.test.max_score === null) {
          part.test.max_score = 0;
        }
        return part;
      });
    }

    let transformedLabels = [];

    transformedLabels = selectedQuizLabels
      ? selectedQuizLabels.map((item) => item.value)
      : [];

    try {
      const response: any = await dispatch(
        updateQuizAction(quizId, {
          name: quizName,
          display_mode: displayMode,
          description: quizDescription,
          display_questions_count: questionCount ? parseInt(questionCount) : 0,
          display_score: !hideMarks,
          is_required: markQuizRequired,
          is_welcome_enabled: isWelcomeEnabled,
          labels: transformedLabels,
          result_settings: {
            show_answers: showAnswer,
            show_scores: showScore,
            show_scores_in_percentage: showScoreInPercentage,
            show_button: showMainButton,
            button_label: mainButtonLabel,
            conditions: _conditions,
          },
          score_decimal_number: parseInt(decimalPlaces),
        })
      );
      setIsLoading(false);
      getQuiz(quizId);
    } catch (error) {}
  };

  const onDelete = () => {
    let tempQuestions = [...backupRef.current];

    tempQuestions = tempQuestions.filter((question, index) => {
      return !toBeDeleted.includes(question.id);
    });

    backupRef.current = tempQuestions;
    transformAllQuestions(tempQuestions);

    let tempSavedQuestions = [...savedQuestions];
    tempSavedQuestions = tempSavedQuestions.filter((question, index) => {
      return !toBeDeleted.includes(question.id);
    });

    setSavedQuestions(tempSavedQuestions);
    generateQuestionIdLabelMap(tempSavedQuestions);
    transformQuizDropdownData(tempSavedQuestions);

    let toUpdateQuestions: any = [];
    tempSavedQuestions.forEach((question) => {
      if (question !== null) {
        toUpdateQuestions.push(question);
      }
    });
    !props.location.state?.createFromTestFile &&
      updateQuizQuestions(toUpdateQuestions);

    // setOpenConfirm(false);
    setSelectedNodes([]);
    setToBeDeleted([]);
    setOpenDeleteConfirm(false);
  };

  const directQuestionDelete = (questionId: any) => {
    setAddNew(false);
    let tempQuestions = [...backupRef.current];
    tempQuestions.forEach((question, index) => {
      if (questionId === question.id) {
        tempQuestions.splice(index, 1);
      }
    });
    backupRef.current = tempQuestions;
    transformAllQuestions(tempQuestions);

    let tempSavedQuestions = [...savedQuestions];
    tempSavedQuestions.forEach((question, index) => {
      if (questionId === question.id) {
        tempSavedQuestions.splice(index, 1);
      }
    });
    setSavedQuestions(tempSavedQuestions);
    generateQuestionIdLabelMap(tempSavedQuestions);
    transformQuizDropdownData(tempSavedQuestions);

    let toUpdateQuestions: any[] = [];
    tempSavedQuestions.forEach((question) => {
      if (question !== null) {
        toUpdateQuestions.push(question);
      }
    });
    updateQuizQuestions(toUpdateQuestions, "directDelete");
  };

  const onQuestionSort = (data: any) => {
    backupRef.current = data;
    transformAllQuestions(data);

    let tempSavedQuestions = [...data];
    setSavedQuestions(tempSavedQuestions);
    generateQuestionIdLabelMap(tempSavedQuestions);
    transformQuizDropdownData(tempSavedQuestions);

    let toUpdateQuestions: any[] = [];
    tempSavedQuestions.forEach((question) => {
      if (question !== null) {
        toUpdateQuestions.push(question);
      }
    });
    updateQuizQuestions(toUpdateQuestions);
  };

  const onGroupQuestionSave = async (data: any, queIds: any) => {
    setAddNew(false);
    let tempQuestions = [...backupRef.current];
    data.forEach((questionToSave: any, mainIndex: any) => {
      tempQuestions.forEach((question, subIndex) => {
        if (queIds[mainIndex] === question.id) {
          tempQuestions[subIndex] = questionToSave;
        }
      });
    });

    backupRef.current = tempQuestions;
    transformAllQuestions(tempQuestions);

    let tempSavedQuestions = [...savedQuestions];
    data.forEach((questionToSave: any, mainIndex: any) => {
      tempSavedQuestions.forEach((question, subIndex) => {
        if (queIds[mainIndex] === question.id) {
          tempSavedQuestions[subIndex] = questionToSave;
        }
      });
    });
    setSavedQuestions(tempSavedQuestions);
    generateQuestionIdLabelMap(tempSavedQuestions);
    transformQuizDropdownData(tempSavedQuestions);

    let toUpdateQuestions: any[] = [];
    tempSavedQuestions.forEach((question) => {
      if (question !== null) {
        toUpdateQuestions.push(question);
      }
    });
  };

  const onSave = async (
    updatedQuestion: any,
    questionId: any,
    isGenerateKeywords: boolean = false,
    isLinkSections: boolean = false,
    questionNumber: number = null
  ) => {
    return;
  };

  useEffect(() => {
    return () => {
      // cleanup
    };
  }, [ref]);

  const confirmDelete = (questionId: any) => {
    let temp = [];
    if (typeof questionId === "string") {
      temp.push(questionId);
    } else {
      temp = [...questionId];
    }
    setToBeDeleted(temp);
    setOpenDeleteConfirm(true);
  };

  const addNewQuestion = (type: any = null, parentId: any = null) => {
    let emptyOptions: any = [...emptyQuestion.options];

    emptyOptions.forEach((option: any, index: any) => {
      emptyOptions[index] = {
        ...option,
        id: uuidv4(),
      };
    });

    emptyQuestion.options = emptyOptions;

    let tempQuestions = [...backupRef.current];
    let newQ: any = { ...emptyQuestion };
    newQ.id = uuidv4();
    newQ.isNew = true;

    if (type === "sub") {
      newQ.parent_id = parentId;
    }
    tempQuestions.push(newQ);
    transformAllQuestions(tempQuestions);
    backupRef.current = tempQuestions;

    let tempSavedQuestions = [...savedQuestions];
    tempSavedQuestions.push(newQ);
    setSavedQuestions(tempSavedQuestions);
    // generateQuestionIdLabelMap(tempSavedQuestions);
    // transformQuizDropdownData(tempSavedQuestions);

    if (!type) {
      setAddNew(true);
    }
  };

  const transformQuestion = (question: any) => {
    let temp = {
      ...question,
      score: !question.score || question.score === null ? 1 : question.score,
      position_check: question.position_check ? question.position_check : null,
    };
    if (question.options) {
      let opt: any[] = [];
      question.options.forEach((option: any, index: any) => {
        opt[index] = {
          ...option,
          id: option.id || uuidv4(),
          text: option.text,
          is_correct: option.is_correct,
        };
      });
      temp.options = opt;
    }
    return temp;
  };

  const transformButtonOptionsDropdown = (data: any) => {
    let dropdownData: any = [];
    data.forEach((item: any) => {
      item.value = item.reference;
      dropdownData.push(item);
    });
    return dropdownData;
  };

  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDrop = (dropResult: any) => {
    const { removedIndex, addedIndex, payload, element } = dropResult;
    if (addedIndex === undefined) {
      return;
    }
    const temp = reorder(backupRef.current, removedIndex, addedIndex);
    onQuestionSort(temp);
  };

  const getChildPayload = (index: any) => {
    return backupRef.current[index];
  };

  const updateQuiz = (questions: any, content: any = null) => {
    let quizArray: any = { questions: [] };

    questions.forEach(function (element: any) {
      let quiz = {
        ...element,
        id: element.id ? element.id : uuidv4(),
      };
      if (quiz.ideal_answer === null) {
        quiz.ideal_answer = "";
      }

      quizArray.questions.push(quiz);
    });

    backupRef.current = quizArray.questions;
    transformAllQuestions(quizArray.questions);
    generateQuestionIdLabelMap(quizArray.questions);
    transformQuizDropdownData(quizArray.questions);
    setSavedQuestions(quizArray.questions);
    return;
  };
  
  const checkAllQuestionsValidity = () => {
    const incompleteQuestions:any = []; // Array to hold the IDs of incomplete questions
    // Iterate over the array of questions (assuming you have a list of questions)
      Object.values(transformedQuestions)?.map((question:any,index:number) => {
        if(question?.options && !question?.isNew){
          let isCorrect = question?.options?.some((option:any) => option?.is_correct === true);
          if (!isCorrect) {
            incompleteQuestions?.push(`#${index+1}`); // Add the question ID to the array if invalid
          }
        }
    });

  
    // If there are incomplete questions, show a toaster message
    if (incompleteQuestions?.length > 0) {
      if(incompleteQuestions?.length == 1){
        helper.showToaster(
          t(
            `The quiz item ${incompleteQuestions?.join(", ")} is incomplete. Please complete all required items before submitting.`
          ),
          "error"
        );
      }else{
        helper.showToaster(
          t(
            `The following quiz items are incomplete: ${incompleteQuestions?.join(", ")}. Please complete all required items before submitting.`
          ),
          "error"
        );
      }
    }
  };
  
  // Call the function when the page loads
  useEffect(() => {
    if(Object.values(transformedQuestions)?.length > 0 && !addNew) {
    checkAllQuestionsValidity();
    }
  }, [transformedQuestions]);

  const renderItem = (question: any, index: any, questions: any) => {
    return (
      <div className="sortable-item">
        <QuizQuestionEditor
          checkAllQuestionsValidity={checkAllQuestionsValidity}
          questions={questions}
          essayConfig={essayConfig? essayConfig?.params: null}
          isNew={question.isNew ? question.isNew : false}
          key={question.id || "que_" + index}
          questionNumber={index}
          questionId={question.id || index}
          data={transformQuestion(question)}
          enableLearning={enableLearning}
          activateEditAsProps={
            typeof props.location.state !== "undefined" &&
            props.location.state.selectedFileType
          }
          showEditableIfError={typeof props.location.state === "undefined"}
          updatingQuizQuestionsFromParent={updatingQuizQuestionsFromParent}
          SetUpdatingQuizQuestionsFromParent={
            SetUpdatingQuizQuestionsFromParent
          }
          hasAnyError={hasAnyError}
          onSetErrorIfAny={setErrorIfAny}
          updateQuizQuestionsFromParent={updateQuizQuestionsFromParent}
          parentRef={ref}
          hasLastIndex={index + 1 === Object.keys(transformedQuestions).length}
          // defaultEditable={index === 0}
          topicDropdownData={topicDropdownData}
          onQuestionSave={onSave}
          onQuestionDelete={confirmDelete}
          addNew={addNew}
          directQuestionDelete={directQuestionDelete}
          quizQuestionDropdownData={quizQuestionDropdownData}
          allQuestions={backupRef.current}
          questionIdLabelMap={questionIdLabelMap}
          addNewQuestion={addNewQuestion}
          onGroupQuestionSave={onGroupQuestionSave}
          addToSelectedRows={addToSelectedRows}
          selectedNodes={selectedNodes}
          showCheckbox={showSaveQuestionsToQuestionBank}
          isEssayGrader={essayGrader}
          enableDrag
        />
      </div>
    );
  };

  const getBuildQuizHtml = () => (
    <>
      {componentHasError.length > 0 &&
        typeof props.location.state !== "undefined" &&
        props.location.state.selectedFileType &&
        quizSubmitted && (
          <div className="card-error">
            {t(
              "The questions were imported successfully but there are some errors in few of the questions. Please resolve the errors in the highlighted questions before saving"
            )}
          </div>
        )}

      <div>
        {backupRef.current.length === 0 && (
          <div className="page-banner text-center">
            <p>
              {t(
                "You don't have any question yet. Click the button below to add a question."
              )}
            </p>
            <button
              onClick={addNewQuestion}
              className="btn btn-outline-primary"
            >
              + {t("Create question from scratch")}
            </button>
          </div>
        )}

        {Object.keys(transformedQuestions).length > 0 && (
          <>
            <DragContainer
              onDrop={onDrop}
              getChildPayload={getChildPayload}
              dragHandleSelector=".drag-handle"
            >
              {Object.keys(transformedQuestions).map(
                (questionId: any, index: any) => {
                  return (
                    <Draggable key={questionId}>
                      {renderItem(
                        transformedQuestions[questionId],
                        index,
                        Object.values(transformedQuestions)
                      )}
                    </Draggable>
                  );
                }
              )}
            </DragContainer>
            <div className="add-btn-container text-center">
              <button
                onClick={addNewQuestion}
                className="btn btn-outline-primary"
              >
                + {t("Create question from scratch")}
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );

  ///////////////////// QUIZ EDITOR ////////////////////

  const updateCombinedQuizSettings = async () => {
    let quizId = props.quizId ? props.quizId : props.match.params.quizId;
    let _conditions: any[] = [...conditions];
    if (_conditions) {
      _conditions.map((part: any) => {
        if (part.test.question_id) {
          part.test.question_id = part.test.question_id.toString();
        }
        if (part.test.option_id) {
          part.test.option_id = part.test.option_id.toString();
        }
        if (part.show.message === null) {
          part.show.message = "";
        }
        if (!part.test.min_score || part.test.min_score === null) {
          part.test.min_score = 0;
        }
        if (!part.test.max_score || part.test.max_score === null) {
          part.test.max_score = 0;
        }
        return part;
      });
    }

    let transformedLabels = [];
    transformedLabels = selectedQuizLabels
      ? selectedQuizLabels.map((item) => item.value)
      : [];

    try {
      setIsLoading(true);
      const response: any = await dispatch(
        updateQuizAction(quizId, {
          name: quizName,
          display_mode: displayMode,
          description: quizDescription,
          display_questions_count: questionCount ? parseInt(questionCount) : 0,
          display_score: !hideMarks,
          is_required: markQuizRequired,
          is_welcome_enabled: isWelcomeEnabled,
          labels: transformedLabels,
          result_settings: {
            show_answers: showAnswer,
            show_scores: showScore,
            show_scores_in_percentage: showScoreInPercentage,
            show_button: showMainButton,
            button_label: mainButtonLabel,
            conditions: _conditions,
          },
          score_decimal_number: parseInt(decimalPlaces),
        })
      );
      setIsLoading(false);
      getQuiz(quizId);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const updateRolePlay = async () => {
    const quizId = props.quizId ? props.quizId : props.match.params.quizId;
    const { id: questionId, type, text } = quizDetail.questions[0];

    setSaving(true);
    const scores = idealAnswerItems.filter((_, index) => index % 3 === 2).map((t) => Number.parseFloat(t.text));
    const score = scores.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    const emptyFields = idealAnswerItems.filter((_, index) => index % 3 === 1).find((t) => t.text.length === 0)
    if (emptyFields) {
      helper.showToaster(t("Description of Assessment Criteria is required."));
      setSaving(false);
      return;
    }

    try {
      const response: any = await dispatch(
        updateQuizAction(quizId, {
          questions: [{
            id: questionId,
            type,
            text,
            roleplay_goal: rolePlayGoal,
            roleplay_tutor_role: rolePlayTutorRole,
            roleplay_learner_role: rolePlayLearnerRole,
            roleplay_scenario_description: rolePlayScenarioDescription,
            score: score || 1,
            position_check: "rubric_description",
            scored_by: "roleplay_grader",
            answer_key_settings: {
              headers: [
                  "Assessment Criteria",
                  "Description of Assessment Criteria",
                  "Score for Criteria(Optional)"
              ],
              column_headers: idealAnswerItems.filter((_, index) => index % 3 === 0).map((t) => t.text),
              row_count: newTableRowCount,
              column_count: newTableColumnCount,
            },
            ideal_answer_items: idealAnswerItems,
          }]
        })
      );
    } catch (e) {

    } finally {
      setSaving(false);
    }
  }

  const fetchAllLinkedQuizQuestionsHandler = async () => {
    setIsLoading(true);
    let quizId = props.quizId ? props.quizId : props.match.params.quizId;
    await dispatch(getLinkedQuestionsBasedOnQuizAndQuestions(quizId));
    getQuiz(quizId);
  };

  const getQna = async (jobId: any) => {
    const resQna: any = await quizService.getQnaGenFromJobId(jobId);
    if (resQna?.data?.data?.quiz_extraction_job?.status === "completed") {
      const questions = resQna?.data?.data?.quiz_extraction_job?.questions;
      let quizId = props.quizId ? props.quizId : props.match.params.quizId;
      let quizArray: any = { questions: [] };
      questions.forEach(function (element: any) {
        // console.log('[ element ]', element.text.replaceAll('\n', "<br>"))
        let quiz: any = {
          ...element,
          text: element.text
            ? element.text.replaceAll("\n", "<br>")
            : element.text,
          id: element.id ? element.id : uuidv4(),
        };

        delete quiz.tags;
        if (quiz.ideal_answer === null) {
          quiz.ideal_answer = "";
        }
        quiz.score = element.score ? element.score : 0;
        if (!("difficulty_level" in element)) {
          quiz.difficulty_level = 1;
        }

        quizArray.questions.push(quiz);
      });
      if (
        typeof props.location.state !== "undefined" &&
        props.location.state.isMgs
      ) {
        transformAllQuestions(quizArray.questions);
      } else {
        const response: any = await dispatch(
          updateQuizAction(quizId, {
            questions: quizArray.questions,
          })
        );
        transformAllQuestions(response.quiz.questions);
        getQuiz(quizId);
      }
      setIsLoading(false);
      setIsLoad(false);
      clearInterval(invervalGetQna);
    }
    return resQna;
  };

  const startGenQna = async (content: any) => {
    setIsLoad(true);
    try {
      await SetExtractQuestionClicked(true);
      const res: any = await quizService.startQnaDocGen({
        document_content: content,
      });
      const jobId = res?.data?.data?.quiz_extraction_job?._id;
      if (jobId) {
        await getQna(jobId);
        invervalGetQna = setInterval(async () => {
          await getQna(jobId);
        }, 15 * 1000);
      }
    } catch (error) {
      helper.showToaster(t("Something went wrong while fetching questions."));
      setIsLoad(false);
    }
  };

  const getQuizDocumentsFromContent = async () => {
    try {
      await SetExtractQuestionClicked(true);
      let content = "";
      const contents = document.querySelectorAll(".pre-quiz-info-extract");
      contents.forEach((element) => {
        content += element.innerHTML + "\n";
      });
      setDocContent(content);

      await startGenQna(content);
      // const { questions }: any = await dispatch(
      //   quizDocumentQuestions({
      //     document_content: content,
      //   })
      // );
      // // console.log('quizDocumentQuestionsResponse', questions);
      // let quizId = props.quizId ? props.quizId : props.match.params.quizId;
      // let quizArray: any = { questions: [] };
      // questions.forEach(function (element: any) {
      //   // console.log('[ element ]', element.text.replaceAll('\n', "<br>"))
      //   let quiz: any = {
      //     ...element,
      //     text: element.text
      //       ? element.text.replaceAll("\n", "<br>")
      //       : element.text,
      //     id: element.id ? element.id : uuidv4(),
      //   };

      //   delete quiz.tags;
      //   if (quiz.ideal_answer === null) {
      //     quiz.ideal_answer = "";
      //   }
      //   quiz.score = element.score ? element.score : 0;
      //   if (!("difficulty_level" in element)) {
      //     quiz.difficulty_level = 1;
      //   }

      //   quizArray.questions.push(quiz);
      // });
      // if (
      //   typeof props.location.state !== "undefined" &&
      //   props.location.state.isMgs
      // ) {
      //   transformAllQuestions(quizArray.questions);
      // } else {
      //   const response: any = await dispatch(
      //     updateQuizAction(quizId, {
      //       questions: quizArray.questions,
      //     })
      //   );
      //   transformAllQuestions(response.quiz.questions);
      //   getQuiz(quizId);
      // }
      // setIsLoading(false);
    } catch (e) {
      console.log("error", e);
      setIsLoad(false);
    }
  };

  const getJobId = async () => {
    let documentId = props.location.state.documentId;
    try {
      const jobResponse: any = await dispatch(
        getQuizDocumentTableJobsQuestions(documentId)
      );
      extractTablesFromDocument(documentId, jobResponse.job_id);
    } catch (e) {}
  };

  const extractTablesFromDocument = async (
    documentId: string,
    jobId: string
  ) => {
    const requestOptions = {
      method: "GET",
    };
    console.log(documentId, jobId);

    try {
      const jobResponse: any = await dispatch(
        getQuizDocumentTableJobsByJobId(documentId, jobId)
      );

      setExtractionStatus(jobResponse.job_status);
      if (jobResponse.job_status === "SUCCEEDED") {
        setExtractedTables(jobResponse.tables);
      } else if (jobResponse.job_status === "IN_PROGRESS") {
        setTimeout(() => {
          extractTablesFromDocument(documentId, jobId);
        }, 5000);
      } else if (jobResponse.job_status === "FAILED") {
        helper.showToaster("Oops! Could not extract tables.");
      }
    } catch (e) {}
  };

  const addToSelectedRows = (event: any, id: string) => {
    let data: any[] = [...selectedNodes];
    if (event.target.checked) {
      const isPresent = data.indexOf(id);
      if (isPresent < 0) {
        data.push(id);
      }
    } else {
      const oldData = [...selectedNodes];
      const index = oldData.indexOf(id);
      oldData.splice(index, 1);
      data = [...oldData];
    }
    setSelectedNodes([..._.uniq(data)]);
  };

  const onSelectAllRows = (event: any) => {
    if (event.target.checked) {
      if (Object.keys(transformedQuestions).length > 0) {
        const quizzes: any = Object.keys(transformedQuestions);
        setSelectedNodes([..._.uniq(quizzes)]);
      }
    } else {
      setSelectedNodes([]);
    }
  };

  const saveQuestionsToQuestionBank = async () => {
    if (!selectedNodes.length) {
      return;
    }

    let questions: any = [];
    const transformedQuestion: any = { ...transformedQuestions };
    let quizId = props.quizId ? props.quizId : props.match.params.quizId;
    selectedNodes.forEach((id: string) => {
      if (
        transformedQuestion[id] &&
        transformedQuestion[id].text.trim().length
      ) {
        questions.push(transformedQuestion[id]);
      }
    });

    if (questions.filter((q: any) => "quiz_question_id" in q).length) {
      setShowKNowledgebaseOrNot(true);
    } else {
      updateQuestionsToQuestionBank();
    }
  };

  const updateQuestionsToQuestionBank = async () => {
    let questions: any = [];
    const transformedQuestion: any = { ...transformedQuestions };
    let quizId = props.quizId ? props.quizId : props.match.params.quizId;
    selectedNodes.forEach((id: string) => {
      if (
        transformedQuestion[id] &&
        transformedQuestion[id].text.trim().length
      ) {
        questions.push(transformedQuestion[id]);
      }
    });

    let quizQuestionIds: any[] = [];

    if (questions.length) {
      const createResponse: any = await dispatch(
        generateQuestionBankV2(
          quizId,
          {
            question_ids: questions.map((d: any) => d.id),
          },
          false
        )
      );
      //
      createResponse?.quiz_questions.forEach((el: any) => {
        quizQuestionIds.push(el.id);
      });
      // for await (const question of questions) {
      // if ("quiz_question_id" in question) {
      //   const updateResponse: any = await dispatch(
      //     updateQuestionFromQuestionBank(
      //       question.id,
      //       {
      //         quiz_id: quizId,
      //         questions: [question],
      //       },
      //       false
      //     )
      //   );
      //   quizQuestionIds.push(updateResponse.quiz_question._id);
      //   console.log("[ updateResponse ]", updateResponse);

      // } else {

      // }
      // }
      await getQuiz(quizId);
      setSelectedNodes([]);
      helper.showToaster(t("Quiz questions has been saved."), "success");

      if (
        quizDetail.knowledge_group_ids &&
        quizDetail.knowledge_group_ids.length &&
        quizQuestionIds.length
      ) {
        await dispatch(
          bulkAssignModules(
            quizDetail.knowledge_group_ids[0],
            quizQuestionIds,
            "question",
            false
          )
        );
      }
    }
  };

  const addMoreQuestionToQuizHandler = async (question_ids: any[], quizId: any) => {
    const ids = question_ids.map((e: any) => e.rowId);
    setIsLoad(true);
    try {
      if (ids && ids.length > 0) {
        await dispatch(
          updateQuizAction(quizId, {
            question_ids: ids,
          })
        );
        getQuiz(quizId);
      }
    } catch (error) {
      setIsLoad(false);
    }
  };

  const downloadQuizzesHandler = async (
    exportQuiz: boolean = false,
    fileName: string
  ) => {
    let quizData: any = {};
    const quizId = props.match.params.quizId;
    quizData.quizzes = [];
    if (exportQuiz) {
      quizData.quizzes.push({
        _id: quizId,
        question_ids: [],
      });
    } else {
      if (selectedNodes && selectedNodes.length > 0) {
        quizData.quizzes.push({
          _id: quizId,
          question_ids: [...selectedNodes],
        });
      }
    }
    try {
      const blob: any = await dispatch(exportQuizzesAndQuestions(quizData));
      if (!blob) {
        return;
      }
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${fileName}.docx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      setSelectedNodes([]);
    } catch (error) {
      setSelectedNodes([]);
    }
  };

  const QuestionHtml = () => {
    return (
      <>
        <div className="pl-lg-3">
          <div className="d-flex flex-wrap justify-content-between mb-3 align-items-start">
            <div>
              <h1 className="mb-0">
                {typeof props.location.state !== "undefined" &&
                props.location.state.isMgs
                  ? t("Generate Questions")
                  : t("Quiz Questions")}
              </h1>
              <div className="font-16">
                {typeof props.location.state !== "undefined" &&
                props.location.state.isMgs
                  ? t(
                      "Edit the text extracted from the uploaded test document."
                    )
                  : t("Manage your quiz questions and answers")}
              </div>
            </div>
            <div className="action-btn-container d-flex align-items-center">
              {/* {showSaveQuestionsToQuestionBank && !toolkitSmartQuiz && (
                <CreateQuestionsOptions
                  knowledge_group_ids={
                    quizDetail &&
                    quizDetail.knowledge_group_ids &&
                    quizDetail.knowledge_group_ids.length
                      ? quizDetail.knowledge_group_ids[0]
                      : null
                  }
                  quizId={props.match.params.quizId}
                  addNewQuestion={addNewQuestion}
                />
              )} */}
              {typeof props.location.state !== "undefined" &&
                props.location.state.selectedFileType &&
                !props.location.state?.createFromTestFile && (
                  <button
                    disabled={
                      props.location.state &&
                      props.location.state.isMgs &&
                      IsLoading
                    }
                    onClick={
                      typeof props.location.state !== "undefined" &&
                      props.location.state.selectedFileType
                        ? updateQuizQuestionsFromParent
                        : onQuizSave
                    }
                    // disabled={internalLoading}
                    className="btn btn-primary font-600 font-sm mr-2"
                  >
                    {props.location.state && props.location.state.isMgs
                      ? t("Save Questions")
                      : t("Save Quiz")}
                  </button>
                )}
              <button
                className="btn btn-link"
                onClick={() => downloadQuizzesHandler(true, quizName)}
              >
                {t("Export Quiz")}
              </button>
            </div>
          </div>

          {showSaveQuestionsToQuestionBank && (
            <div className="d-flex flex-wrap justify-content-between align-items-center mb-3 pb-1">
              <div className="font-weight-bold">
                {t("Quiz Total Score:")}
                <span className="clr-primary font-16 ml-2">
                  {quizTotalScore}
                </span>
              </div>
              {showSaveQuestionsToQuestionBank && (
                <CreateQuestionsOptions
                  knowledge_group_ids={
                    quizDetail &&
                    quizDetail.knowledge_group_ids &&
                    quizDetail.knowledge_group_ids.length
                      ? quizDetail.knowledge_group_ids[0]
                      : null
                  }
                  quizId={props.match.params.quizId}
                  addNewQuestion={addNewQuestion}
                  // toolkitSmartQuiz={toolkitSmartQuiz}
                  queTypes={
                    props.location &&
                    props.location.state &&
                    props.location.state.queTypes
                  }
                  addMoreQuestionToQuiz={addMoreQuestionToQuizHandler}
                  document_Id={documentId}
                />
              )}
            </div>
          )}

          <div className="d-flex flex-wrap justify-content-between align-items-center mb-2">
            <div className="d-flex align-items-center">
              <div className="custom-checkbox mr-3">
                <input
                  type="checkbox"
                  className="mr-2"
                  id="select-all"
                  checked={
                    Object.keys(transformedQuestions).length > 0 &&
                    selectedNodes.length ===
                      Object.keys(transformedQuestions).length
                  }
                  onChange={onSelectAllRows}
                />
                <label htmlFor="select-all" className="mb-0">
                  {t("Select all")}
                </label>
              </div>

              {selectedNodes && selectedNodes.length > 0 && (
                <PermissionWrapper>
                  <button
                    className="btn btn-suggest py-0 font-sm"
                    onClick={saveQuestionsToQuestionBank}
                  >
                    {t("Save Selected to Question Bank")}
                  </button>

                  <button
                    className="btn btn-suggest py-0 font-sm"
                    onClick={() => {
                      downloadQuizzesHandler(false, quizName);
                    }}
                  >
                    {t("Export Selected")}
                  </button>

                  <button
                    className="btn btn-outline-danger border-0 py-0 font-600 font-sm"
                    onClick={() => {
                      confirmDelete(selectedNodes);
                    }}
                  >
                    {t("Delete Selected")}
                  </button>
                </PermissionWrapper>
              )}
            </div>
          </div>

          {typeof props.location.state !== "undefined" &&
            props.location.state.selectedFileType && (
              <div className="extract-quiz-wrapper d-flex flex-wrap edit-extract-quiz">
                <QuizDocumentPreview
                  documentContent={
                    docContent
                    // props.location.state.documentContent
                  }
                  documentType={props.location.state.documentType}
                  internalLoading={internalLoading}
                  // isLoading={isLoading}
                  getQuizDocumentsFromContent={getQuizDocumentsFromContent}
                  extractTables={getJobId}
                  extractedTablesData={extractedTables}
                  extractionStatus={extractionStatus}
                />
                <div className="extract-quiz-questions mt-3 mt-md-0">
                  {getBuildQuizHtml()}
                </div>
              </div>
            )}

          {!(
            typeof props.location.state !== "undefined" &&
            props.location.state.selectedFileType
          ) && getBuildQuizHtml()}
        </div>
      </>
    );
  };

  const getQuizHtml = () => {
    if (
      typeof props.location.state !== "undefined" &&
      props.location.state.isMgs
    ) {
      return <>{QuestionHtml()}</>;
    } else {
      return (
        <Tabs
          defaultActiveKey={activeKey}
          activeKey={activeKey}
          onSelect={(k) => {
            setActiveKey(k);
          }}
        >
          {quizDetail?.type !== "roleplay" && <Tab eventKey="admins" title={t("Questions")}>
            {QuestionHtml()}
          </Tab>}
          {quizDetail?.type === "roleplay" && <Tab eventKey="roleplay" title={t("Role Play")}>
            <div className="d-flex flex-wrap justify-content-between mb-24 align-items-start">
              <div>
                <h2 className="mb-0">{t("Role Play Context")}</h2>
                <div className="font-16">
                  {t("Set the scene and define the key details for your role play scenario.")}
                </div>
              </div>
              <button
                className="btn btn-primary font-600"
                onClick={updateRolePlay}
                disabled={isSaving}
              >
                {t("Save changes")}
              </button>
            </div>
            <div className="tw-flex tw-flex-col tw-gap-4 tw-text-text">
              <div>
                <div className="tw-text-sm tw-font-bold">{t("Goal of Role Play")}</div>
                <div className="tw-text-xs">{t("Describe what is the end goal of the role play")}</div>
                <textarea
                  className="mt-3 tw-border tw-b order-border tw-rounded-lg tw-text-sm tw-w-full tw-px-3 tw-py-2 tw-outline-none"
                  placeholder="Define the intent and the objective of the role play"
                  value={rolePlayGoal}
                  onChange={(e) => setRolePlayGoal(e.currentTarget.value)}
                />
              </div>

              <div>
                <div className="tw-text-sm tw-font-bold">{t("Configure Tutor’s Role")}</div>
                <div className="tw-text-xs">{t("Describe how the chatbot will interact with the learner in this role play.")}</div>
                <textarea
                  className="mt-3 tw-border tw-b order-border tw-rounded-lg tw-text-sm tw-w-full tw-px-3 tw-py-2 tw-outline-none"
                  placeholder="What part will the tutor play in this role play scenario?"
                  value={rolePlayTutorRole}
                  onChange={(e) => setRolePlayTutorRole(e.currentTarget.value)}
                />
              </div>

              <div>
                <div className="tw-text-sm tw-font-bold">{t("Define Learner’s Role")}</div>
                <div className="tw-text-xs">{t("Describe the role that best aligns with your learner’s goals and the learning experience you want to create.")}</div>
                <textarea
                  className="mt-3 tw-border tw-b order-border tw-rounded-lg tw-text-sm tw-w-full tw-px-3 tw-py-2 tw-outline-none"
                  placeholder="What part will the learner play in this role play scenario?"
                  value={rolePlayLearnerRole}
                  onChange={(e) => setRolePlayLearnerRole(e.currentTarget.value)}
                />
              </div>

              <div className="suneditor-container">
                <div className="tw-text-sm tw-font-bold">{t("Scenario Description")}</div>
                <div className="tw-text-xs mb-3">{t("Describe the scenario your learner will engage in. This sets the stage for the role play interaction.")}</div>

                <SunEditorUI
                  name={`question-editor`}
                  editorType="sun"
                  value={rolePlayScenarioDescription}
                  onChange={(content: string) => setRolePlayScenarioDescription(content)}
                  placeholder={t("Type question here")}
                />
              </div>

              <div className="tw-flex tw-items-center tw-justify-between">
                <div>
                  <div className="tw-text-sm tw-font-bold">{t("Skills Assessment")}</div>
                </div>
              </div>

              <div className="rubric-scoring-table tw-relative tw-pb-4">
                <SkillsAssetment
                  maxCharCount={4000}
                  onHeaderChange={() => {}}
                  onColumnHeaderChange={() => {}}
                  onAnswerKeyTableChange={onAnswerKeyTableChange}
                  tableValues={tableValues}
                  horizontalHeaderValues={[
                    "Assessment Criteria",
                    "Description of Assessment Criteria",
                    "Score for Criteria (Optional)",
                  ]}
                  verticalHeaderValues={[
                    "",
                    "",
                    "",
                  ]}
                  scoredBy={{
                    "value": "similar_match",
                    "label": "Matching similar sentences"
                  }}
                  enableVerticalHeader={false}
                  essayGrader
                  defaultRowCount={newTableRowCount}
                />
              </div>
            </div>
          </Tab>}
          {quizDetail?.type !== "roleplay" && <Tab eventKey="feedback" title={t("Feedback & Tutoring")}>
            <FeedbackAndTutoring
              isFeedback
              addScoreRangeCondition={addScoreRangeCondition}
              conditions={conditions}
              transformData={transformData}
              buttonOptions={buttonOptions}
              quizQuestionDropdownData={quizQuestionDropdownData}
              removeScoreCondition={removeScoreCondition}
              onConditionFieldChange={onConditionFieldChange}
              onConditionQuestionDropdownChange={
                onConditionQuestionDropdownChange
              }
              onButtonAdd={onButtonAdd}
              topicDropdownData={topicDropdownData}
              userGroupData={userGroupData}
              onButtonLabelEdit={onButtonLabelEdit}
              onButtonQuestionSelect={onButtonQuestionSelect}
              onSort={onSort}
              deleteButtonRow={deleteButtonRow}
              questionIdLabelMap={questionIdLabelMap}
              totalQuizScore={totalQuizScore}
              updateCombinedQuizSettings={updateCombinedQuizSettings}
              quizId={props.quizId ? props.quizId : props.match.params.quizId}
              transformedQuestions={transformedQuestions}
              getQuiz={getQuiz}
              fetchAllLinkedQuizQuestions={fetchAllLinkedQuizQuestionsHandler}
            />
            <div className="d-flex align-items-center justify-content-center px-2">
              <PermissionWrapper>
                <button
                  className="btn btn-outline-primary third-step mr-3"
                  onClick={() => {
                    addScoreRangeCondition(true);
                  }}
                >
                  + {t("Add Another Response")}
                </button>
              </PermissionWrapper>
            </div>
          </Tab>}
          <Tab eventKey="level" title={t("Level")}>
            <>
              <ManageLevelCompact
                updateParentHandler={updateParentHandler}
                onSelectGroupId={onSelectGroupId}
                selectedGroupId={selectedGroupId}
                setSelectedParentId={setSelectedParentId}
                setSelectedModuleName={setSelectedModuleName}
                intent={quizDetail}
                editMode={true}
                newModuleAdded={newModuleAdded}
              />
              <div className="d-flex flex-wrap justify-content-between mb-24 align-items-start">
                <button
                  className="btn btn-primary font-600 ml-auto"
                  onClick={updateQuizLevelHandler}
                >
                  {t("Save changes")}
                </button>
              </div>
            </>
          </Tab>
          <Tab eventKey="settings" title={quizDetail?.type !== "roleplay" ? t("Quiz Settings") : t("Role Play Settings")}>
            <>
              <div className="d-flex flex-wrap justify-content-between mb-24 align-items-start">
                <div>
                  <h2 className="mb-0">{quizDetail?.type !== "roleplay" ? t("Quiz Settings") : t("Role Play Settings")}</h2>
                  <div className="font-16">
                    {quizDetail?.type !== "roleplay" ? t("Manage quiz mode and details.") : t("Manage role play details.")}
                  </div>
                </div>
                <button
                  className="btn btn-primary font-600"
                  onClick={updateCombinedQuizSettings}
                >
                  {t("Save changes")}
                </button>
              </div>
              <Row>
                <Col md={6}>
                  <div className="form-group mb-32">
                    <label className="font-16 font-600">{quizDetail?.type !== "roleplay" ? t("Quiz Name") : t("Role Play Name")}</label>
                    <input
                      type="text"
                      placeholder={quizDetail?.type !== "roleplay" ? "Name of role play" : "Name of quiz"}
                      className="form-control h-auto py-1"
                      value={quizName || ""}
                      onChange={(e: any) => {
                        onFieldChange(e.target.value, "name");
                      }}
                    />
                    {quizDetail?.type !== "roleplay" && <div className="d-flex flex-wrap mt-2">
                      <div className="custom-checkbox mr-3 font-16">
                        <input
                          type="checkbox"
                          className="mr-2"
                          id="quiz-required"
                          checked={markQuizRequired}
                          onChange={(event: any) => {
                            setMarkQuizRequired(event.target.checked);
                          }}
                        />
                        <label
                          htmlFor="quiz-required"
                          className="mb-0 clr-grey-text"
                        >
                          {t("Mark quiz as required")}
                        </label>
                      </div>
                      <div className="custom-checkbox font-16">
                        <input
                          type="checkbox"
                          className="mr-2"
                          id="welcome-screen"
                          checked={isWelcomeEnabled}
                          onChange={(event: any) => {
                            setIsWelcomeEnabled(event.target.checked);
                          }}
                        />
                        <label
                          htmlFor="welcome-screen"
                          className="mb-0 clr-grey-text"
                        >
                          {t("Show welcome screen")}
                        </label>
                      </div>
                    </div>}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-group mb-32">
                    <label className="font-16 font-600">
                      {quizDetail?.type !== "roleplay" ? t("Quiz Description") : t("Role Play Description")}
                    </label>
                    <textarea
                      placeholder={quizDetail?.type !== "roleplay" ? "Description of quiz" : "Description of role play"}
                      className="form-control radius-10 border-grey"
                      value={quizDescription || ""}
                      onChange={(e: any) => {
                        onQuizDescriptionChange(e.target.value);
                      }}
                    ></textarea>
                  </div>
                </Col>
                {quizDetail?.type !== "roleplay" && <Col md={6}>
                  <div className="form-group mb-32">
                    <label className="font-16 font-600">
                      {t("Number of Question shown")}
                    </label>
                    <div>
                      {t(
                        "You can create a pool of questions and select how many of them you want to show in the quiz. The questions will be selected randomly. All questions will be shown if no value is entered."
                      )}
                    </div>
                    <div className="d-flex align-items-center mt-2">
                      {/* <span className="border-grey form-control font-sm clr-black h-auto w-auto py-1 mr-1">
                    123
                  </span> */}

                      <input
                        className="border-grey form-control font-sm clr-black h-auto w-auto py-1 mr-1"
                        type="number"
                        name="no_of_question_shown"
                        value={questionCount || 0}
                        onChange={(e: any) => setQuestionCount(e.target.value)}
                        min="1"
                        max={backupRef.current.length.toString()}
                      />
                      <button
                        className="btn clr-red border-0 font-sm py-0 font-600"
                        onClick={() => {
                          setQuestionCount(null);
                        }}
                      >
                        {t("Clear")}
                      </button>
                    </div>
                  </div>
                </Col>}
                {quizDetail?.type !== "roleplay" && <Col md={6}>
                  <div className="form-group mb-32">
                    <label className="font-16 font-600">
                      {t("Number of decimal places in score")}
                    </label>
                    <div>
                      {t(
                        "You can set how many decimal places the quiz score should have."
                      )}
                    </div>
                    <div className="d-flex align-items-center mt-2">
                      <input
                        className="border-grey form-control font-sm clr-black h-auto w-auto py-1 mr-1"
                        type="number"
                        name="no_of_decimal_shown"
                        value={decimalPlaces}
                        onChange={(e: any) => setDecimalPlaces(e.target.value)}
                        min="1"
                        max="5"
                      />
                    </div>
                  </div>
                </Col>}
              </Row>

              <div className="mb-32">
                {quizDetail?.type !== "roleplay" && <>
                  <label className="font-16 font-600 mb-0">
                    {t("Quiz Format")}
                  </label>
                  <div className="upload-list-msg d-flex align-items-center font-12 py-2 my-3">
                    <img src={infoIcon} alt="" className="mr-3" />
                    {t(
                      "Warning: Select “One at a time” or “Conversational” if your quiz has logic/branching. Logic added to a quiz question will not work in “All in one page”"
                    )}
                  </div>
                  <ButtonGroup className="quiz-btn-group border-grey radius-8 w-100 mb-3">
                    {[
                      {
                        value: "single_question",
                        label: t("One at a time"),
                      },
                      {
                        value: "continuous_scroll",
                        label: t("All in one page"),
                      },
                      {
                        value: "conversational",
                        label: t("Conversational"),
                      },
                    ].map((d: any) => {
                      return (
                        <Button
                          onClick={() => {
                            setDisplayMode(d.value);
                          }}
                          variant="default"
                          className={displayMode === d.value ? "active" : ""}
                        >
                          {d.label}
                        </Button>
                      );
                    })}
                  </ButtonGroup>

                  <div className="custom-checkbox mr-3 font-16 mb-32">
                    <input
                      id="Hide marks per question"
                      checked={hideMarks}
                      onChange={(event) => setHideMarks(event.target.checked)}
                      type="checkbox"
                      className="mr-2"
                    />
                    <label
                      htmlFor="Hide marks per question"
                      className="mb-0 clr-grey-text"
                    >
                      {t("Hide marks per question")}
                    </label>
                  </div>
                </>}

                <div>
                  <label className="font-16 font-600 mb-1">
                    {t("Configure Results")}
                  </label>
                  <div className="mb-3">
                    {quizDetail?.type !== "roleplay" ? t(
                      "Customise messages, buttons and more in the quiz results screen. User will see this after the quiz completion along with exercise reports."
                    ) : t(
                      "Customise messages, buttons and more in the role play results screen. User will see this after the role play completion along with exercise reports."
                    )}
                  </div>
                  <div className="mb-32">
                    <div className="custom-checkbox mr-3 font-16 mb-2">
                      <input
                        id="Show scores in the quiz results"
                        checked={showScore}
                        onChange={(event) => setShowScore(event.target.checked)}
                        type="checkbox"
                        className="mr-2"
                      />
                      <label
                        htmlFor="Show scores in the quiz results"
                        className="mb-0 clr-grey-text"
                      >
                        {quizDetail?.type !== "roleplay" ? t("Show scores in the quiz results") : t("Show scores in the role play results")}
                      </label>
                    </div>

                    <div className="custom-checkbox mr-3 font-16 mb-2">
                      <input
                        id="Show scores in percentage in the quiz results"
                        checked={showScoreInPercentage}
                        onChange={(event) =>
                          setShowScoreInPercentage(event.target.checked)
                        }
                        type="checkbox"
                        className="mr-2"
                      />
                      <label
                        htmlFor="Show scores in percentage in the quiz results"
                        className="mb-0 clr-grey-text"
                      >
                        {quizDetail?.type !== "roleplay" ? t("Show scores in percentage in the quiz results") : t("Show scores in percentage in the role play results")}
                      </label>
                    </div>

                    {quizDetail?.type !== "roleplay" && <div className="custom-checkbox font-16 mb-2">
                      <input
                        onChange={(event: any) =>
                          setShowAnswer(event.target.checked)
                        }
                        checked={showAnswer}
                        type="checkbox"
                        className="mr-2"
                        id="Show answers in the quiz results"
                      />
                      <label
                        htmlFor="Show answers in the quiz results"
                        className="mb-0 clr-grey-text"
                      >
                        {t("Show answers in the quiz results")}
                      </label>
                    </div>}

                    {quizDetail?.type !== "roleplay" && <div className="custom-checkbox mr-3 font-16 mb-2">
                      <input
                        onChange={(event: any) =>
                          setShowMainButton(event.target.checked)
                        }
                        checked={showMainButton}
                        type="checkbox"
                        className="mr-2"
                        id="Show main button in the quiz results"
                      />
                      <label
                        htmlFor="Show main button in the quiz results"
                        className="mb-0 clr-grey-text"
                      >
                        {t("Show main button in the quiz results")}
                      </label>
                    </div>}
                  </div>

                  {showMainButton && quizDetail?.type !== "roleplay" && (
                    <div className="form-group mb-32">
                      <label className="font-16 font-600">
                        {t("Button label")}
                      </label>
                      <input
                        type="text"
                        placeholder="Button label"
                        className="form-control h-auto py-1"
                        value={mainButtonLabel || ""}
                        onChange={(e: any) => {
                          setMainButtonLabel(e.target.value);
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>

              {quizDetail?.type !== "roleplay" && <div>
                <div className="d-flex flex-wrap justify-content-between mb-3 align-items-center">
                  <label className="font-16 font-600 mb-0">
                    {t("Post Quiz Response")}
                  </label>
                  <button
                    className="btn clr-primary font-600 py-0"
                    onClick={() => addScoreRangeCondition(false)}
                  >
                    {" "}
                    + {t("Add Another Response")}
                  </button>
                </div>

                {conditions.map((item, index) => {
                  return (
                    <ScoreRange
                      key={item.id ? item.id : index.toString()}
                      buttonsData={item.show.buttons ? item.show.buttons : []}
                      buttonOptions={buttonOptions}
                      quizQuestionDropdownData={quizQuestionDropdownData}
                      conditionData={item}
                      conditionIndex={index}
                      removeScoreCondition={removeScoreCondition}
                      onConditionFieldChange={onConditionFieldChange}
                      onConditionQuestionDropdownChange={
                        onConditionQuestionDropdownChange
                      }
                      onButtonAdd={onButtonAdd}
                      topicDropdownData={topicDropdownData}
                      userGroupData={userGroupData}
                      onButtonLabelEdit={onButtonLabelEdit}
                      onButtonQuestionSelect={onButtonQuestionSelect}
                      onSort={onSort}
                      deleteButtonRow={deleteButtonRow}
                      questionIdLabelMap={questionIdLabelMap}
                      totalQuizScore={totalQuizScore}
                    />
                  );
                })}
              </div>}

              {quizDetail?.type !== "roleplay" && quizDetail && <ReportAlerts quizId={quizDetail._id} />}
            </>
          </Tab>
        </Tabs>
      );
    }
  };

  const saveQuestionsToBank = () => {
    updateQuizQuestionsFromParent();
  };

  if (isLoad || IsLoading) {
    return <Loading />;
  }

  return (
    <>
      <TwoButtonModal
        show={showKNowledgebaseOrNot}
        leftButtonText={t("Cancel")}
        rightButtonText={t("Save")}
        title={t("Save to knowledge base")}
        message={`<span>There are selected question(s) have already been saved in question bank. By choosing to save, the saved question <b style="color: #FF009E;">would be overwritten.</b></span>`}
        innerHtml={true}
        size={"lg"}
        leftButtonClassName="btn btn-outline-primary mr-3"
        rightButtonClassName="btn btn-primary mr-3"
        onClickClose={() => {
          setShowKNowledgebaseOrNot(false);
        }}
        onClickLeftButton={() => {
          setShowKNowledgebaseOrNot(false);
        }}
        onClickRightButton={() => {
          setShowKNowledgebaseOrNot(false);
          updateQuestionsToQuestionBank();
        }}
      />

      <section
        className={classNames({
          "page-mid-wraper": true,
          "create-test-file": props.location.state?.createFromTestFile,
          "h-without-foter":
            activeKey !== "admins" || props.location.state?.createFromTestFile, //  && activeKey !== "feedback",
        })}
      >
        <UpdateModuleModal
          show={showRenameModal}
          onHide={updateModalHandler}
          name={selectedModuleName}
          parent_id={selectedParentId}
          editMode={moduleEditMode}
          confirmHandler={confirmRenameHandler}
          moduleDepth={moduleDepth}
        />

        <div>
          {typeof props.location.state !== "undefined" &&
            props.location.state.isMgs && (
              <>
                <div className="row">
                  <div className="col-md-12">
                    <button
                      className="btn p-0 btn-back"
                      // onClick={() => history.push(`/knowledge/upload-documents`)}
                      onClick={() => history.goBack()}
                    >
                      <Image src={backimg} /> {t("Return to previous page")}
                    </button>
                  </div>
                </div>
              </>
            )}

          <Row>
            {quizDetail && (
              <Col md={12}>
                <h1 className="mb-24 d-flex align-items-center">
                  <button
                    className="btn py-0"
                    onClick={() => {
                      props.location.state && props.location.state.isMgs
                        ? history.push("/quizzes")
                        : history.push("/beta/quizzes");
                    }}
                  >
                    <img src={chevLeft} alt="" />
                  </button>
                  {quizDetail?.name}
                </h1>
              </Col>
            )}
            <Col md={12}>
              <div className="">
                {getQuizHtml()}
                <ConfirmationModal
                  message={t("Are you sure you want to delete this question?")}
                  size={"md"}
                  show={openDeleteConfirm}
                  onHide={() => setOpenDeleteConfirm(false)}
                  confirmHandler={onDelete}
                />
              </div>
            </Col>
          </Row>
        </div>
      </section>
      {activeKey === "admins" && (
        <footer>
          <div>
            <div className="d-flex align-items-center justify-content-end px-2">
              <PermissionWrapper>
                {!props.location.state?.createFromTestFile && (
                  <button
                    className="btn btn-outline-primary third-step mr-3"
                    onClick={() => {
                      props.location.state && props.location.state.isMgs
                        ? history.push("/quizzes")
                        : history.push("/beta/quizzes");
                    }}
                    // disabled={
                    //   knowledgeCreating || answerGenerationProcessing
                    // }
                  >
                    {props.location.state && props.location.state.isMgs
                      ? t("Return to manage questions")
                      : t("Return to manage quizzes")}
                  </button>
                )}
                {props.location.state?.createFromTestFile && (
                  <button
                    className="btn btn-outline-primary third-step mr-3"
                    onClick={() => !IsLoading && saveQuestionsToBank()}
                  >
                    {t("Save and Generate Keywords")}
                  </button>
                )}
                {/* {showSaveQuestionsToQuestionBank && (
                  <button
                    className="btn btn-outline-primary third-step mr-3"
                    onClick={saveQuestionsToQuestionBank}
                    disabled={!selectedNodes.length}
                  >
                    {t("Save selected question/s to Question Bank")}
                  </button>
                )} */}
              </PermissionWrapper>
            </div>
          </div>
        </footer>
      )}
    </>
  );
}

export default EditCreateQuiz;