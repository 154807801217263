import Modal from "react-bootstrap/Modal";
import "./index.scss";

interface IConfirmationModalProps {
  show: boolean;
  innerHtml?: boolean;
  size: "sm" | "lg" | "xl";
  dialogClassName?: string;
  title: string;
  titleClassName?: string;
  message: string;
  messageClassName?: string;
  leftButtonText: string;
  leftButtonClassName?: string;
  rightButtonText: string;
  rightButtonClassName?: string;
  onClickLeftButton: (e?: any) => void;
  onClickRightButton: (e?: any) => void;
  onClickClose?: () => void;
}

export default function TwoButtonModal(props: IConfirmationModalProps) {
  return (
    <Modal
      show={props.show}
      onHide={props.onClickClose}
      size={props.size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName={
        props.dialogClassName ? props.dialogClassName : "modal two-button-modal"
      }
    >
      <Modal.Header className="pt-3 modal-header" closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className={
            props.titleClassName ? props.titleClassName : "modal-title"
          }
        >
          {props.title}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="body">
        <hr />
        {props.innerHtml && (
          <div
            className={
              props.titleClassName ? props.titleClassName : "px-4 modal-message"
            }
            dangerouslySetInnerHTML={{
              __html: props.message,
            }}
          ></div>
        )}
        {!props.innerHtml && (
          <div
            className={
              props.titleClassName ? props.titleClassName : "px-4 modal-message"
            }
          >
            {props.message}
          </div>
        )}
        <hr />
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-end flex-nowrap modal-footer">
        {props.leftButtonText && <button
          className={
            props.leftButtonClassName
              ? props.leftButtonClassName
              : "modal-button left"
          }
          onClick={props.onClickLeftButton}
        >
          {props.leftButtonText}
        </button>}
       {props.rightButtonText &&
        <button
          className={
            props.leftButtonClassName
              ? props.rightButtonClassName
              : "modal-button right"
          }
          onClick={props.onClickRightButton}
        >
          {props.rightButtonText}
        </button>}
      </Modal.Footer>
    </Modal>
  );
}
