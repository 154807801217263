import React from "react";
import {
  Modal,
  ModalTitle,
  Button,
  ModalBody,
  ModalFooter,
  Image,
} from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import closeImg from "../../../../assets/New-images/build-knowledge-close-img.svg";
import { useTranslation } from "react-i18next";

const OverrideUserScorePopup = (props: any) => {
  const { t } = useTranslation("translation");
  return (
    <React.Fragment>
      <Modal show={props.show} centered dialogClassName={"modal two-button-modal"}>
        <ModalHeader className="clr-black pt-4 px-3 justify-content-between">
          <ModalTitle id="contained-modal-title-vcenter">
            <h2 className="mb-0 font-1">{t("Override Learner’s Score")}</h2>
          </ModalTitle>
          <button className="btn" onClick={props.onHide}>
            <img src={closeImg} />
          </button>
        </ModalHeader>
        <ModalBody>
          <div className="font-600">
            {t(
              "Would you like to override the current learner score with correct score?"
            )}
          </div>
        </ModalBody>
        <ModalFooter className="border-top-0">
          <button
            className="btn-outline-primary btn mx-2 font-600 py-1"
            onClick={() => props.onProceed(false)}
          >
            {t("No")}
          </button>
          <button
            className="btn-primary btn mx-2 font-600 py-1"
            onClick={() => props.onProceed(true)}
          >
            {t("Yes")}
          </button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default OverrideUserScorePopup;
