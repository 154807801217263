import {useState} from 'react'
import { Modal, Form } from 'react-bootstrap'
import { useTranslation } from "react-i18next";

interface IConfirmationModalProps {
  show: boolean;
  onClickDelete: (del: boolean) => void;
  onClickCancel: () => void;
  onClickClose?: () => void;
}

export default function DeleteConfirmModal(props: IConfirmationModalProps) {
  const { t } = useTranslation('translation');
  const [deleteIntents, setdeleteIntents] = useState(false)

  return (
    <Modal
      show={props.show}
      onHide={props.onClickClose}
      size={"lg"}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName={"modal two-button-modal"}
    >
      <Modal.Header className="pt-3 modal-header" closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="modal-title"
        >
          {t("Confirm Delete")}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="body">
        <hr />
          <div
            className="px-4 modal-message"
          >
            {t('Are you sure you want to delete? Deleted Items will be ')} <b className="clr-red">{t('deleted permanently.')}</b>
          </div>
          <Form.Group className='mt-4 pref-check mx-3'>
            <Form.Check
              type='checkbox'
              label={t('Delete all the intents associated with this documents as well')}
              name='associated_intent'
              onChange={(e) => setdeleteIntents(e.target.checked)}
            />
          </Form.Group>
        <hr />
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-end flex-nowrap modal-footer">
        <button
          className="btn btn-outline-danger font-weight-normal mx-2"
          onClick={() => props.onClickDelete(deleteIntents)}
        >
          {t("Delete")}
        </button>
        <button
          className="btn btn-outline-primary font-weight-normal mx-2"
          onClick={props.onClickCancel}
        >
          {t("Cancel")}
        </button>
      </Modal.Footer>
    </Modal>
  )
}
