import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import App from "./App";
import "./index.css";
//import "./fonts/Mulish-Regular.ttf"
import {store} from "./store";
import history from "./utils/history";
import {Auth0Provider} from "./utils/react-auth0-wrapper";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";

import "./utils/sentry";
import Signup from "./routes/Signup";
import {AWS_SELF_SIGNUP, SELF_SIGNUP, TEAM_DASH} from "./config";
import TeamDash from "./routes/TeamDash";

// const store = createStore(reducer, applyMiddleware(thunk));
// const app = (
//   <Provider store={store}>
//     <Router>
//       <Auth0ProviderWithHistory>
//         <App />
//       </Auth0ProviderWithHistory>
//     </Router>
//   </Provider>
// );

const onRedirectCallback = (appState?: { targetUrl: string }) => {
  history.push(
      appState && appState.targetUrl
          ? appState.targetUrl
          : window.location.pathname
  );
};

const windowObj: any = window;
const SSO_CLIENTID = windowObj.SSO_CLIENTID;
const SSO_DOMAIN = windowObj.SSO_DOMAIN;
const SSO_AUDIENCE = windowObj.SSO_AUDIENCE;
const urlParams = new URLSearchParams(window.location.search);
const token = urlParams.get("t");

ReactDOM.render(
    <Provider store={store}>
      <Router basename={windowObj.SUB_DOMAIN || '/admin'}>
        <Switch>
          <Route path={AWS_SELF_SIGNUP}>
            <Signup/>
          </Route>
          <Route path={SELF_SIGNUP}>
            <Signup/>
          </Route>
          <Route path={TEAM_DASH}>
            <TeamDash/>
          </Route>
          <Route path="*">
            {token ? (
                <App/>
            ) : (
                <Auth0Provider
                    domain={SSO_DOMAIN}
                    client_id={SSO_CLIENTID}
                    redirect_uri={window.location.origin + windowObj.SUB_DOMAIN + "/"}
                    audience={SSO_AUDIENCE}
                    onRedirectCallback={onRedirectCallback}
                >
                  <App/>
                </Auth0Provider>
            )}
          </Route>
        </Switch>
      </Router>
    </Provider>,
    document.getElementById("root")
);

// ReactDOM.render(app, document.getElementById("root"));

// const rootElement = document.getElementById("root");
// render(
//   <Provider store={store}>
//     <App />
//   </Provider>,
//   rootElement
// );

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
