import { AppStateType } from "../rootReducer";
export const getUserDocuments = (state: AppStateType) =>
  state.knowledge.documents;
export const isKnowledgeCreating = (state: AppStateType) =>
  state.knowledge.saveKnowledge;
export const isKnowledgeCreated = (state: AppStateType) =>
  !state.knowledge.saveKnowledge && !state.knowledge.knowledgeError && state.knowledge.knowledgeSaved;
export const isKnowledgeCreationError = (state: AppStateType) =>
  !state.knowledge.saveKnowledge && !state.knowledge.knowledgeSaved && state.knowledge.knowledgeError;
export const getlinks = (state: AppStateType) =>
  state.knowledge.links;
export const getManageAccessRight = (state: AppStateType) =>
  state.knowledge.manageAccessData;

export const isWebsiteStoring = (state: AppStateType) =>
  state.knowledge.isWebsiteStoring && !state.knowledge.isWebsiteStoringError && !state.knowledge.isWebsiteStored
export const isWebsiteStoringSuccess = (state: AppStateType) =>
  !state.knowledge.isWebsiteStoring && !state.knowledge.isWebsiteStoringError && state.knowledge.isWebsiteStored
export const isWebsiteStoringError = (state: AppStateType) =>
!state.knowledge.isWebsiteStoring && state.knowledge.isWebsiteStoringError && !state.knowledge.isWebsiteStored

// All knowledge
export const isAllKnowledgeLoading = (state: AppStateType) =>
  !state.knowledge.allKnowledgeError && state.knowledge.allKnowledgeLoading
export const isAllKnowledgeSuccess = (state: AppStateType) => state.knowledge.allKnowledge
export const isAllKnowledgeError = (state: AppStateType) =>
state.knowledge.allKnowledgeError && !state.knowledge.allKnowledgeLoading
export const getAllTopicsSelector = (state: AppStateType) => state.knowledge.topics
export const isTopicsLoading = (state: AppStateType) => state.knowledge.topicsLoading
export const iskeyWordsLoading = (state: AppStateType) => state.knowledge.keyWordsLoading
export const isDocumentFaqsLoading = (state: AppStateType) => state.knowledge.documentFaqs.loading
export const getDocumentFaqs = (state: AppStateType) => state.knowledge.documentFaqs

// All suggestions
export const getAllSuggestionsSelector = (state: AppStateType) => state.knowledge.allSuggestions