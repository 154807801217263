import React, { useEffect } from "react";
import { Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Container, Draggable } from "react-smooth-dnd";
import backimg from "../../../assets/images/arrow-left.svg";
import baricon from "../../../assets/images/bar-icon.svg";
import deleteicon from "../../../assets/images/delete-icon.svg";
import Loading from "../../../components/UI/Loading/Loading";
import PermissionWrapper from "../../../components/UI/PermissionWrapper/PermissionWrapper";
import { IntentInterface } from "../../../services/interfaces/intent.interface";
import {
  getAllSuggestions,
  suggestKnowledgeById,
  updateSuggestionsOrdering,
  updateSuggestionsOrderingLocally,
} from "../../../store/knowledge/actions";
import {
  getAllSuggestionsSelector,
  isAllKnowledgeLoading,
} from "../../../store/knowledge/selector";

import { useTranslation } from "react-i18next";

interface Props {}

export const ManageSuggestions = (props: any) => {
  const { t } = useTranslation('translation');
  const dispatch = useDispatch();
  const getAllSuggestionsData = useSelector(getAllSuggestionsSelector);
  const loading = useSelector(isAllKnowledgeLoading);

  useEffect(() => {
    console.log("into suggestion");
    dispatch(
      getAllSuggestions(
        3,
        {
          sort_field: "order",
          sort_order: "dsc",
          page_size: 100000,
        },
        true
      )
    );
    return () => {
      console.log("cleanup");
    };
  }, []);

  ///////// drag and drop function to arrange values  ///////////
  const applyDrag = (arr: any, dragResult: any) => {
    const { removedIndex, addedIndex, payload } = dragResult;
    if (removedIndex === null && addedIndex === null) return arr;

    const result = [...arr];
    let itemToAdd = payload;

    if (removedIndex !== null) {
      itemToAdd = result.splice(removedIndex, 1)[0];
    }

    if (addedIndex !== null) {
      result.splice(addedIndex, 0, itemToAdd);
    }
    // setIntents([...result])
    dispatch(updateSuggestionsOrderingLocally([...result]));
    return result;
  };

  const deleteSuggestionsHandler = async (id: string) => {
    await dispatch(suggestKnowledgeById(id, false));
    await dispatch(
      getAllSuggestions(
        3,
        {
          sort_field: "order",
          sort_order: "dsc",
          page_size: 100000,
        },
        true
      )
    );
  };

  const updateIntentsHandler = async () => {
    let data: any = [];

    getAllSuggestionsData.intents
      .reverse()
      .forEach((suggestion: IntentInterface, index: number) => {
        data.push({
          intent_id: suggestion._id,
          is_suggested: true,
          order: index + 1,
        });
      });

    await dispatch(updateSuggestionsOrdering(data));
    await dispatch(
      getAllSuggestions(
        3,
        {
          sort_field: "order",
          sort_order: "dsc",
        },
        true
      )
    );
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <section className="page-mid-wraper pb-0">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <button
                className="btn p-0 btn-back"
                onClick={props.resetSuggestionView}
              >
                <Image src={backimg} /> {t("Return to previous page")}
              </button>
              <h1 className="first-step mb-12 d-flex flex-wrap">
                {t("Manage Suggestions")}
              </h1>
              <div className="mt-4 manage-suggestions">
                <Container
                  dragHandleSelector=".column-drag-handle"
                  onDrop={(e) => applyDrag(getAllSuggestionsData.intents, e)}
                >
                  {getAllSuggestionsData &&
                    getAllSuggestionsData.intents &&
                    getAllSuggestionsData.intents.map(
                      (intent: IntentInterface, index: number) => {
                        return (
                          <Draggable
                            key={"draggable_" + intent._id + "_i_" + index}
                          >
                            <div className="draggable-item web-links d-flex align-items-center column-drag-handle">
                              <button className="btn border-0 px-0 mr-3 mr-md-4 ">
                                <Image src={baricon} />
                              </button>
                              {intent?.name}
                              {t("Category:")}{" "}
                              {intent.topic_name ? intent.topic_name : "-"}
                              <PermissionWrapper>
                                <button
                                  className="btn border-0 ml-auto"
                                  onClick={() =>
                                    deleteSuggestionsHandler(intent._id)
                                  }
                                >
                                  <Image src={deleteicon} />
                                </button>
                              </PermissionWrapper>
                            </div>
                          </Draggable>
                        );
                      }
                    )}
                </Container>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer>
        <div className="container">
          <div className="d-flex align-items-center flex-wrap">
            {getAllSuggestionsData.intents &&
              getAllSuggestionsData.intents.length > 0 && (
                <PermissionWrapper>
                  <button
                    className="btn btn-primary mr-3"
                    onClick={updateIntentsHandler}
                  >
                    {t("Save")}
                  </button>
                </PermissionWrapper>
              )}
          </div>
        </div>
      </footer>
    </>
  );
};

export default ManageSuggestions;
