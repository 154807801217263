import React, { useState, useEffect } from "react";
import cx from "classnames";
import Moment from "react-moment";
import collIcon from "../../../assets/images/caret-right-fill.svg";
import expandIcon from "../../../assets/images/caret-down-fill.svg";
import { Modal, ModalFooter } from "react-bootstrap";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import closeImg from "../../../assets/images/close-blk.svg";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getAllModulesSubgroups } from "../../../store/knowledge/actions";

const TreeGroupsModal = (props: any) => {
  const { isOpen, hide, onSave, disableGroup } = props;
  const [selectedGroup, setSelectedGroup] = useState<any>("");
  const [flatGroups, setFlatGroups] = useState([]);
  const [disabledGroups, setDisabledGroups] = useState([]);
  const [expandedGroups, setExpandedGroups] = useState([]);

  const { t } = useTranslation("translation");
  const dispatch = useDispatch();

  const isShowExpand = (g: any) => {
    if (g?.children && g?.children.length > 0) {
      return true;
    }

    return false;
  };

  const getDisableGroup = (disableGroupId: any, groups: any) => {
    let result: any = [];
    const pushToResult = (disableG: any) => {
      for (let i = 0; i < disableG.length; i++) {
        groups.forEach((g: any) => {
          if (g._id === disableG[i]) {
            result.push(g._id);
            g.children.forEach((c: any) => {
              pushToResult(c?._id);
            });
          }
        });
      }
    };
    pushToResult(disableGroupId);
    return result;
  };
  const addOrRemove = (groups: any, id: string) => {
    if (groups.find((gID: any) => gID === id)) {
      return groups.filter((gID: any) => gID !== id);
    } else {
      groups.push(id);
      return groups;
    }
  };

  const toggleGroup = (g: any) => {
    const copy = [...flatGroups];
    let copyExpaned = [...expandedGroups];

    copy.forEach((group: any) => {
      if (group._id === g._id) {
        g.isExpand = !g.isExpand;
        copyExpaned = addOrRemove(copyExpaned, g._id);
      }
    });
    setExpandedGroups(copyExpaned);
    setFlatGroups(copy);
  };

  const getAllYearsFun = async () => {
    try {
      const response: any = await dispatch(getAllModulesSubgroups());
      setFlatGroups(response.filter((g: any) => g.group_type !== "system"));
    } catch (error: any) {
      setFlatGroups([]);
    }
  };

  useEffect(() => {
    if (
      disableGroup &&
      disableGroup.length > 0 &&
      flatGroups &&
      flatGroups?.length > 0
    ) {
      const disabledGroups = getDisableGroup(disableGroup, flatGroups);
      console.log({ disabledGroups });
      setDisabledGroups(disabledGroups);
    }
  }, [flatGroups, disableGroup]);

  const isShown = (g: any) => {
    if (!g.parent_id) {
      return true;
    }
    const parent = flatGroups.find((group: any) => group._id === g.parent_id);
    if (
      expandedGroups.find((eg: any) => eg === parent._id) &&
      isShown(parent)
    ) {
      return true;
    }

    return false;
  };

  const renderFlatGroups = (groups: any) => {
    return (
      groups &&
      groups?.length > 0 && (
        <div>
          {groups.map(
            (g: any, index: any) =>
              // showGroups.find((id) => id === g.parent_id) &&
              (isShown(g) || g.depth === 0) && (
                <div key={g._id}>
                  <div
                    className={`groups-item d-flex justify-content-between align-items-center tree-p tree-p-${
                      g.depth
                    } ${g._id === selectedGroup ? "group-selected" : ""}`}
                    onClick={() => {
                      if (disabledGroups.find((d) => d === g?._id)) {
                        return;
                      }
                      if (g._id === selectedGroup) {
                        setSelectedGroup("");
                      } else {
                        setSelectedGroup(g._id);
                      }
                    }}
                  >
                    <div className="d-flex flex-1">
                      {isShowExpand(g) && (
                        <img
                          src={g?.isExpand ? expandIcon : collIcon}
                          alt="collapse/expand"
                          onClick={() => toggleGroup(g)}
                        />
                      )}
                      <div
                        className={cx("d-flex flex-1 pl-3", {
                          "clr-grey-txt": disabledGroups.find(
                            (d) => d === g?._id
                          ),
                        })}
                      >
                        {g.name}
                      </div>
                    </div>
                    <Moment format="DD/MM/YYYY HH:MM A">
                      {new Date(g?.created_at)}
                    </Moment>
                  </div>
                </div>
              )
          )}
        </div>
      )
    );
  };

  return (
    <Modal
      show={isOpen}
      size="lg"
      onHide={() => hide()}
      onEntered={() => {
        getAllYearsFun();
      }}
    >
      <ModalHeader className="clr-black pt-4 px-4 justify-content-between">
        <ModalTitle id="contained-modal-title-vcenter">
          <h2 className="mb-0 font-1">{t("Move Knowledge Group")}</h2>
        </ModalTitle>
        <button className="btn" onClick={() => hide()}>
          <img src={closeImg} alt="" />
        </button>
      </ModalHeader>
      <ModalBody>
        <div className="pb-3">
          {t(
            "Select the knowledge group you would like to move the knowledge groups to"
          )}
        </div>
        <div className="d-flex justify-content-between align-items-center px-3 py-3 bg-grey-head">
          <h3 className="mb-0 font-1">{t("Knowledge Group Name:")}</h3>
          <h3 className="mb-0 font-1">{t("Date Created")}</h3>
        </div>
        <div className="level-tab-wraper groups-tree">
          {renderFlatGroups(flatGroups)}
        </div>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-outline-primary" onClick={() => hide()}>
          {t("Cancel")}
        </button>
        <button
          className="btn btn-primary"
          disabled={!selectedGroup}
          onClick={() => onSave(selectedGroup)}
        >
          {t("Save")}
        </button>
      </ModalFooter>
    </Modal>
  );
};
export default TreeGroupsModal;
