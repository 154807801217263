import React from "react";
import { Button, Image, Modal, ModalFooter } from "react-bootstrap";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import { useTranslation } from "react-i18next";
import closeimg from "../../../assets/images/close-blk.svg";
import popupimg from "../../../assets/images/pixeltrue-meditation.svg";
import KnowledgeDatatableComponentGridView from "../../../components/UI/KnowledgeDatatableComponent/KnowledgeDatatableComponentGridView";
import AllKnowledge from "../../Knowledge/AllKnowledge/AllKnowledge";
import QuizzesGridView from "../QuizzesGridView";

const CreateQuestionFromQuestionBank = (props: any) => {
  const { t } = useTranslation("translation");
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="modal-960"
    >
      <ModalHeader
        className="pt-4 justify-content-between"
        onHide={props.onHide}
      >
        <ModalTitle id="contained-modal-title-vcenter">
          <h2 className="mb-0">{t("Add from Question Bank")}</h2>
          {/* Sub title */}
          <p className="mb-0 font-sm">
            {t("Select the questions you would like to add to quiz.")}
          </p>
        </ModalTitle>
        <Button onClick={props.onHide}>
          <Image src={closeimg} />
        </Button>
      </ModalHeader>
      <ModalBody className="know-search-modalbody">
        {/* <KnowledgeDatatableComponentGridView
          onTabChanges={() => {}}
          createFromUploadedDocument
          selectedGroupIdForDocument={props.selectedGroupIdForDocument}
          isEditQuiz={props.isEditQuiz}
          quizId={props.quizId}
          toolkitSmartQuizAddMoreQue={props.toolkitSmartQuizAddMoreQue}
          queTypes={props.queTypes}
        /> */}
        <QuizzesGridView
          queTypes={props.queTypes}
          createFromQuestionBank
          //   toolkitSmartQuizAddMoreQue={props.toolkitSmartQuizAddMoreQue}
          quizId={props.quizId}
          addMoreQuestionToQuiz={props.addMoreQuestionToQuiz}
        />
      </ModalBody>
    </Modal>
  );
};

export default CreateQuestionFromQuestionBank;
