import React, { useRef } from "react";
import { useTranslation } from "react-i18next";

import QuizInputCard from "../QuizInputCard";
// import MarkdownEditor from "../MarkdownEditor";

import "./style.scss";

const BulletEditor = ({
  data,
  handleCardDelete,
  onInputEdit,
  handlePaste,
  handleKeyPress,
  shouldAutoFocus,
  identifier,
  spellCheck,
  handleKeyUp,
  turnOnKeywords,
  keywordsInputChange,
  paraData,
  onParaChange,
  pointScoreChange,
}: any) => {
  const showInstruction = useRef(null);
  const { t } = useTranslation()
  return (
    <div className="bullet-editor">
      {/* <div className="side-padding">
        <h4 className="bullet-editor-heading">
          Write any instructions for the points below
        </h4>
        <MarkdownEditor
          placeholder="Type instructions here. Example, 'Answer any 2'"
          key={identifier}
          value={paraData}
          id={identifier}
          onChange={onParaChange}
          noToolbar
        />
        <div className="clearfix" />
        <h4 className="bullet-editor-heading">
          Write the answer key points below
        </h4>
      </div> */}
      <div className="side-padding flex-container">
        <div className="flex-one no-padding">
          <h4 className="bullet-editor-heading">{t('Answer in points')}</h4>
        </div>
        {turnOnKeywords && (
          <React.Fragment>
            <div className="fix-wd-288">
              <h4 className="bullet-editor-heading">{t('Keywords = Score')}</h4>
            </div>
            <div className="fix-wd-100">
              <h4 className="bullet-editor-heading">{t('Score for point')}</h4>
            </div>
          </React.Fragment>
        )}
      </div>
      {data &&
        data.map((content: any, index: any) => {
          if (content.text) {
            showInstruction.current = true;
          } else {
            showInstruction.current = false;
          }
          return (
            <QuizInputCard
              autoFocus={shouldAutoFocus}
              key={index.toString()}
              id={`input${identifier.toString()}${index.toString()}`}
              numberLabel="&#8226;"
              hideClose={index === 0 && data.length === 1 ? true : false}
              placeholder={
                index === 0 ? t("Start typing here") : t("Type next point here")
              }
              type="edit"
              value={content.text}
              onClose={() => handleCardDelete(index)}
              inputChange={(event: any) => onInputEdit(event, index)}
              onPrimaryPaste={(event: any) => handlePaste(event, index)}
              tabIndex={index + 1}
              useTextArea
              // handleKeyPress={(event: any) => handleKeyPress(event, index)}
              spellCheck={spellCheck}
              handleKeyUp={(event: any) => handleKeyUp(event, index)}
              turnOnKeywords={turnOnKeywords}
              keywordsValue={content.keyword_scores}
              keywordsInputChange={(event: any) => keywordsInputChange(event, index)}
              pointScoreValue={content.score}
              pointScoreChange={(event: any) => pointScoreChange(event, index)}
            />
          );
        })}
      {showInstruction.current && (
        <div className="bullet-editor-instruction">
          {t("Press ENTER to type next point")}
        </div>
      )}
    </div>
  );
};

export default BulletEditor;
