import React from "react";
import { useTranslation } from "react-i18next";

import { Image } from "react-bootstrap";
import logoHead from "./../../assets/images/logo-head.svg";

import bubblesPortal from "./../../assets/images/bubbles-portal.png";
import "./styles.scss";
import mpmLaptopImg from "./../../assets/New-images/MPM_pointing_at_laptop.png";


export const ChatImages = (props: any) => {
  const { t } = useTranslation("translation");
  return (
    <>
      {/* <div className="position-relative p-5 chat-container-wrapper w-100">
        <div className="chat-container">
          <div className="d-flex mb-2">
            <Image src={logoHead} className="pr-1" />
            <div className="left-chat">
              {t(
                "I've analysed your documents and generated a knowledge base."
              )}
            </div>
          </div>
          <div className="left-chat left-no-logo mb-2">
            {t(
              "You can now create a ChatGPT-like experience for your students in minutes"
            )}
          </div>
          <div className="right-chat">
            {t(
              "You can now create a ChatGPT-like experience for your students in minutes"
            )}
          </div>
        </div>
        <Image className="portal-bubble-img" src={bubblesPortal} />
        <div className="chat-botom-text text-center pt-3 font-16 font-weight-bold">
          {t(
            "Tranform the way you teach and watch your students thrive with our powerful AL platform, Walter"
          )}
        </div>
      </div> */}
      <div className="ai-greatness-wrap px-4 pt-5 mt-md-3">
        <div>
          <h2 className="text-center ai-head-1 font-800">
            {t("You're")}
            <span className='clr-blue'> {t("on your way")}</span>
            {t(" to")}
            <span className='clr-pink'> {t("AI-Greatness!")}</span>
          </h2>
          <h3 className="text-center ai-head-2 font-800">
            {t("What you will enjoy in a few minutes:")}
          </h3>
        </div>
        <ul className="list-unstyled ai-greatness-btns mt-3">
          <li className="py-2">  
            <button className="btn font-1 clr-pink font-800">
              {t("Seamless learning outcomes")}
            </button>
          </li>  
          <li className="py-2">  
            <button className="btn font-1 clr-pink font-800">
              {t("Effortless AI-generated assessments")}
            </button>
          </li>  
          <li className="py-2">  
            <button className="btn font-1 clr-pink font-800">
              {t("Document summarisation and refinement")}
            </button>
          </li>  
          <li className="py-2">  
            <button className="btn font-1 clr-pink font-800">
              {t("Instant topic FAQs and tutoring questions")}
            </button>
          </li> 
          <li className="py-2">  
            <button className="btn font-1 clr-pink font-800">
              {t("Auto-grading and personalised feedback")}
            </button>
          </li>  
        </ul>
        <h3 className="text-center font-24 font-800"> 
            {t("All with the click of a button!")}
          </h3>
      </div>
      <figure className="text-center mpm-laptop-img mt-auto mb-0">
        <img src={mpmLaptopImg} alt="" className=""/>
      </figure>
    </>
  );
};

export default ChatImages;
