export const SETUISTATE = 'SETUISTATE';
export const FETCH_CURRENT_AGENT = 'FETCH_CURRENT_AGENT';
export const SETCOMMONSTATE = 'SETCOMMONSTATE';
export const STICKER_SETS = 'STICKER_SETS';
export const STICKER_SETS_SUCCESS = 'STICKER_SETS_SUCCESS';
export const STICKER_SETS_FAIL = 'STICKER_SETS_FAIL';
export const STICKERS_SUCCESS = 'STICKERS_SUCCESS';
export const STICKERS_FAIL = 'STICKER_SETS_FAIL';
export const CURRENT_ORGANIZATION_USER = 'CURRENT_ORGANIZATION_USER';
export const ALLNOTIFICATIONS = 'ALLNOTIFICATIONS';
export const ALLSIDEBARNOTIFICATIONS = 'ALLSIDEBARNOTIFICATIONS';
export const UPDATENOTIFICATIONS = 'UPDATENOTIFICATIONS';
export const DELETENOTIFICATIONS = 'DELETENOTIFICATIONS';
export const HASNEWNOTIFICATIONS = 'HASNEWNOTIFICATIONS';
export const SELECT_GROUP_ID = "SELECT_GROUP_ID";
export const SELECT_YEAR = "SELECT_YEAR";
