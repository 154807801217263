import React, { ReactElement } from 'react'
import closeimg from '../../../assets/images/tag-delete.svg'

import { useTranslation } from "react-i18next";

interface Props {
  value?: string
  hideDeleteOption?: any
  label?: string
  removeTagHandler?: (e: any, value: string) => void
}

export default function Tags({
  value,
  label,
  removeTagHandler,
  hideDeleteOption
}: Props): ReactElement {
  const { t } = useTranslation('translation');
  return (
    <div>
      {label}
      {!hideDeleteOption && (
        <button className='btn' onClick={(e) => removeTagHandler(e, value)}>
          <img src={closeimg} alt={t('removeTag')} />
        </button>
      )}
    </div>
  )
}
