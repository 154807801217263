/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { Col, Container, Dropdown, Row, Image } from "react-bootstrap";
import { useHistory, useLocation } from "react-router";
import Select from "react-select";
import { useDispatch } from "react-redux";
import * as _ from "lodash";

import deleteIcon from "../../../../assets/New-images/cross-red.svg";
import editIcon from "../../../../assets/New-images/edit-pencil.svg";
import sortIcon from "../../../../assets/New-images/sort-blue-icon.svg";
import downarrowblk from "../../../../assets/images/chevron-down-blk.svg";
import closeRed from "../../../../assets/New-images/close-icon-red.svg";
import chevblk from "../../../../assets/New-images/dropdown-arow-blue.svg";
import sortAsc from "../../../../assets/images/arrow-sort-asc.svg";
import sortDsc from "../../../../assets/images/arrow-sort-dsc.svg";

import { getClasses } from "../../../../store/dashboard/actions";
import {
  getUserGroups,
  uploadBulkLearners,
} from "../../../../store/knowledge/actions";
import { USER_MANAGEMENT } from "../../../../config";
import Loading from "../../../../components/UI/Loading/Loading";

import { useTranslation } from "react-i18next";

interface Props {}

interface Users {
  classes: any[];
  email: string;
  first_name: string;
  groups: any[];
  last_name: string;
  name: string;
  student_id: string;
  editMode: boolean;
  // student_id: string;
}

export const UploadListOfUsers = (props: any) => {
  const { t } = useTranslation("translation");
  const windoeObj: any = window;
  const history = useHistory();
  const location: any = useLocation();
  const dispatch = useDispatch();
  const [iframeLoading, setiframeLoading] = useState(false);
  const [activeKey, setActiveKey] = useState("admins");
  const [users, setUsers] = useState<any[]>([]);
  const [searchedUsers, setSearchedUsers] = useState<any[]>([]);
  const [user, setOldUser] = useState<any>();
  const [classes, setClasses] = useState([]);
  const [groups, setUserGroups] = useState([]);
  const [filters, setFilters] = useState({
    sort_field: null,
    sort_order: null,
  });
  const [through, setThrough] = useState(null);

  useEffect(() => {
    if (location.state && location.state.users) {
      setUsers(
        location.state.users.map((user: Users) => {
          return {
            ...user,
            editMode: false,
          };
        })
      );
      setSearchedUsers(
        location.state.users.map((user: Users) => {
          return {
            ...user,
            editMode: false,
          };
        })
      );
    }
    if (location) {
      const query = new URLSearchParams(location.search);
      const throughParam = query.get("through");
      if (throughParam) {
        setThrough(throughParam);
      }
    }
  }, [location]);

  useEffect(() => {
    getAllClassesFunction();
    func();
  }, []);

  const uploadBulkLearnersHandler = async (silent: boolean = false) => {
    try {
      setiframeLoading(true);
      const userGroupsRes: any = await dispatch(
        uploadBulkLearners(
          users.map((user: any) => {
            return {
              ...user,
              classes: user.classes.map((u: any) => {
                return {
                  _id: u.value,
                  name: u.label,
                };
              }),
              groups: user.groups.map((u: any) => {
                return {
                  _id: u.value,
                  name: u.label,
                };
              }),
            };
          }),
          through === "admin" ? "import-admins" : "import-users",
          silent
        )
      );
      history.push(USER_MANAGEMENT);
      setiframeLoading(false);
    } catch (error) {
      setiframeLoading(false);
    }
  };

  const func = async () => {
    try {
      const userGroupsRes: any = await dispatch(getUserGroups(true));
      setUserGroups(
        userGroupsRes.map((group: any) => {
          return {
            value: group._id,
            label: group.name,
          };
        })
      );
    } catch (error) {
      setUserGroups([]);
    }
  };

  const getAllClassesFunction = async () => {
    try {
      const { classes }: any = await dispatch(getClasses());
      setClasses(classes);
    } catch (error: any) {
      setClasses([]);
    }
  };

  const cancelUser = (index: number) => {
    const oldData = [...users];
    oldData[index] = {
      ...user,
    };
    oldData[index].editMode = !oldData[index].editMode;
    setUsers([...oldData]);
  };

  const handleEditMode = (index: number) => {
    const oldData = [...users];
    oldData[index].editMode = !oldData[index].editMode;
    setOldUser({ ...oldData[index] });
    setUsers([...oldData]);
  };

  const setClassesOfAUser = (index: number, e: any[]) => {
    const oldData = [...users];
    oldData[index].classes = [...e];
    setUsers([...oldData]);
  };

  const removeClassesOfAUser = (index: number, classIndex: number) => {
    const oldData = [...users];
    const oldClasses = oldData[index].classes;
    oldClasses.splice(classIndex, 1);
    oldData[index].classes = [...oldClasses];
    setUsers([...oldData]);
  };

  const setGroupsOfAUser = (index: number, e: any[]) => {
    const oldData = [...users];
    oldData[index].groups = [...e];
    setUsers([...oldData]);
  };

  const removeGroupsOfAUser = (index: number, classIndex: number) => {
    const oldData = [...users];
    const oldClasses = oldData[index].groups;
    oldClasses.splice(classIndex, 1);
    oldData[index].groups = [...oldClasses];
    setUsers([...oldData]);
  };

  const removeUserFromList = (index: number) => {
    const oldData = [...users];
    oldData.splice(index, 1);
    setUsers([...oldData]);
    setSearchedUsers([...oldData]);
  };

  const handleInputChange = (e: any) => {
    const index: number = +e.target.dataset.index;
    const oldData = [...users];
    oldData[index][e.target.name] = e.target.value;
    setUsers([...oldData]);
  };

  const searchHandler = (e: React.FormEvent<HTMLInputElement>) => {
    const term = e.currentTarget.value;
    const searchedQuestionData =
      e.currentTarget.value.trim().length > 0
        ? users.filter(
            (v: any) =>
              (v.first_name &&
                v.first_name.toLowerCase().indexOf(term.toLowerCase()) > -1) ||
              (v.last_name &&
                v.last_name.toLowerCase().indexOf(term.toLowerCase()) > -1) ||
              (v.email &&
                v.email.toLowerCase().indexOf(term.toLowerCase()) > -1)
          )
        : searchedUsers;
    setUsers(searchedQuestionData);
  };

  const sort = (sort_field: string, sort_order: any) => {
    setFilters({
      ...filters,
      sort_field,
      sort_order,
    });
    let sortedList = [...users];
    const updatedList = _.orderBy(sortedList, [sort_field], [sort_order]);
    setUsers(updatedList);
  };

  const onSelectGridSortBar = (sort_field: string) => {
    if (filters.sort_field === sort_field) {
      if (filters.sort_order === "asc") {
        sort(sort_field, "desc");
      } else {
        sort(sort_field, "asc");
      }
    } else {
      sort(sort_field, "asc");
    }
  };

  const setPermissionsOfAUser = (index: number, e: any) => {
    const oldData = [...users];
    oldData[index].student_id = e === "admin" ? "admin" : "readonlyadmin";
    setUsers([...oldData]);
  };

  return (
    <>
      {iframeLoading && <Loading />}

      {/* upload list of users */}
      <section className="page-mid-wraper h-without-foter">
        <div>
          <div className="row">
            <div className="col-md-12">
              <h1 className="mb-2">{t("Upload list of users")}</h1>
              <h2 className="mb-24 font-weight-normal">
                {t(
                  "Edit and Verify the information extracted from the document"
                )}
              </h2>
              <div className="all-knowledge-search d-flex flex-wrap align-items-center">
                <Dropdown className="sort-dropdown">
                  <Dropdown.Toggle
                    className="btn btn-outline-primary font-600 py-1 my-1 mr-3"
                    variant="success"
                    id="dropdown-basic"
                  >
                    <img src={sortIcon} alt="" className="mr-2" />
                    {t("Sort")}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {/* <Dropdown.Item
                    onClick={() => {
                      onSortHandlerNew("created_at", "desc");
                    }}
                  >
                    Most Recent
                  </Dropdown.Item> */}
                    <Dropdown.Item
                      onClick={() => {
                        // onSortHandlerNew(activeKey === 'answered' ? "updated_at" :"created_at", "asc"); //
                      }}
                    >
                      {t("Ascending")}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        // onSortHandlerNew(activeKey === 'answered' ? "updated_at" :"created_at", "desc"); //
                      }}
                    >
                      {t("Descending")}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <div className="web-links my-1">
                  <input
                    type="text"
                    className="form-control h-auto"
                    placeholder={t("Search for user")}
                    onChange={searchHandler}
                  />
                </div>
                {users.length > 0 && (
                  <div className="ml-auto">
                    <button
                      className="btn btn-outline-primary py-1 mr-3 font-600"
                      onClick={() => {
                        uploadBulkLearnersHandler(true);
                      }}
                    >
                      {t("Save only")}
                    </button>
                    <button
                      className="btn btn-primary py-1 font-600"
                      onClick={() => {
                        uploadBulkLearnersHandler();
                      }}
                    >
                      {t("Save & Send Invite")}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Row className="grid-sorting-bar mt-3 px-0">
            <Col
              className={`${through !== "admin" ? "col-md-2" : "col"} ${
                filters.sort_field === "first_name" && "selected leftmost"
              }`}
              onClick={() => {
                onSelectGridSortBar("first_name");
              }}
            >
              <span>{t("First Name")}</span>
              {filters.sort_field === "first_name" &&
                (filters.sort_order === "asc" ? (
                  <Image className="sort-icon" src={sortAsc} />
                ) : (
                  <Image className="sort-icon" src={sortDsc} />
                ))}
            </Col>
            <Col
              className={`${through !== "admin" ? "col-md-2" : "col"} ${
                filters.sort_field === "last_name" && "selected"
              }`}
              onClick={() => {
                onSelectGridSortBar("last_name");
              }}
            >
              <span>{t("Last Name")}</span>
              {filters.sort_field === "last_name" &&
                (filters.sort_order === "asc" ? (
                  <Image className="sort-icon" src={sortAsc} />
                ) : (
                  <Image className="sort-icon" src={sortDsc} />
                ))}
            </Col>
            <Col
              className={`${through !== "admin" ? "col-md-2" : "col"} ${
                filters.sort_field === "email" && "selected"
              }`}
              onClick={() => {
                onSelectGridSortBar("email");
              }}
            >
              <span>{t("Email")}</span>
              {filters.sort_field === "email" &&
                (filters.sort_order === "asc" ? (
                  <Image className="sort-icon" src={sortAsc} />
                ) : (
                  <Image className="sort-icon" src={sortDsc} />
                ))}
            </Col>
            <Col
              className={`${through !== "admin" ? "col-md-2" : "col"}${
                filters.sort_field === "classes" && "selected"
              }`}
              onClick={() => {
                onSelectGridSortBar("classes");
              }}
            >
              <span>{t("Class")}</span>
              {filters.sort_field === "classes" &&
                (filters.sort_order === "asc" ? (
                  <Image className="sort-icon" src={sortAsc} />
                ) : (
                  <Image className="sort-icon" src={sortDsc} />
                ))}
            </Col>
            {through !== "admin" && (
              <Col
                className={`${through !== "admin" ? "col-md-2" : "col"} ${
                  filters.sort_field === "groups" && "selected"
                }`}
                onClick={() => {
                  onSelectGridSortBar("groups");
                }}
              >
                <span>{t("Learner Group")}</span>
                {filters.sort_field === "groups" &&
                  (filters.sort_order === "asc" ? (
                    <Image className="sort-icon" src={sortAsc} />
                  ) : (
                    <Image className="sort-icon" src={sortDsc} />
                  ))}
              </Col>
            )}
            {through === "admin" && (
              <Col
                className={`${through !== "admin" ? "col-md-2" : "col"} ${
                  filters.sort_field === "student_id" && "selected"
                }`}
                onClick={() => {
                  onSelectGridSortBar("student_id");
                }}
              >
                <span>{t("Permissions")}</span>
                {filters.sort_field === "student_id" &&
                  (filters.sort_order === "asc" ? (
                    <Image className="sort-icon" src={sortAsc} />
                  ) : (
                    <Image className="sort-icon" src={sortDsc} />
                  ))}
              </Col>
            )}
            <Col
              className={`${
                through !== "admin" ? "col-md-2" : "col"
              } non-clickable`}
            >
              <span>{t("Actions")}</span>
            </Col>
          </Row>
          <div className="accordion-viewby list-of-users radius-8">
            {users &&
              users.map((user: Users, index: number) => {
                return (
                  <div
                    className="card shadow-none mb-0 py-2 px-3"
                    key={"user_" + index}
                  >
                    <Row>
                      <Col>
                        <small className="font-sm mb-1 d-block">
                          {t("First Name")}
                        </small>
                        <div className="font-600 font-16">
                          {!user.editMode && <>{user?.first_name}</>}
                          {user.editMode && (
                            <input
                              className="form-control input-edit"
                              data-index={index}
                              type="text"
                              name="first_name"
                              value={user?.first_name}
                              onChange={handleInputChange}
                            />
                          )}
                        </div>
                      </Col>
                      <Col>
                        <small className="font-sm mb-1 d-block">
                          {t("Last Name")}
                        </small>
                        <div className="font-600 font-16">
                          {!user.editMode && <>{user?.last_name}</>}
                          {user.editMode && (
                            <input
                              className="form-control input-edit"
                              data-index={index}
                              type="text"
                              name="last_name"
                              value={user?.last_name}
                              onChange={handleInputChange}
                            />
                          )}
                        </div>
                      </Col>
                      <Col>
                        <small className="font-sm mb-1 d-block">
                          {t("Email")}
                        </small>
                        <div className="font-600 font-16">
                          {!user.editMode && <>{user?.email}</>}
                          {user.editMode && (
                            <input
                              className="form-control input-edit"
                              data-index={index}
                              type="text"
                              name="email"
                              value={user?.email}
                              onChange={handleInputChange}
                            />
                          )}
                        </div>
                      </Col>
                      {user.editMode && (
                        <Col className="doc-select-dropdown">
                          <small className="font-sm mb-1 d-block">
                            {t("Class")}
                          </small>
                          <Select
                            isSearchable={false}
                            isMulti
                            isClearable={false}
                            controlShouldRenderValue={false}
                            className="select-target select-blue shadow-none"
                            classNamePrefix="select-target-prefix"
                            components={{
                              DropdownIndicator: (props: any) => (
                                <img src={chevblk} alt={t("dropdown arrow")} />
                              ),
                              IndicatorSeparator: () => null,
                            }}
                            onChange={(e: any) => {
                              setClassesOfAUser(index, e);
                            }}
                            value={user.classes}
                            defaultValue={user.classes}
                            placeholder="Select Classes"
                            options={[...classes]}
                          />
                          {user.classes.map(
                            (classes: any, classIndex: number) => {
                              return (
                                <div
                                  className=" group-cross-tags d-flex align-items-center font-sm"
                                  key={"user_" + index + "_class_" + classIndex}
                                >
                                  <div className="pl-2 font-sm">
                                    {classes?.label}
                                  </div>
                                  <button
                                    type="button"
                                    className="btn ml-auto py-0"
                                    onClick={() => {
                                      removeClassesOfAUser(index, classIndex);
                                    }}
                                  >
                                    <img src={closeRed} alt="" />
                                  </button>
                                </div>
                              );
                            }
                          )}
                        </Col>
                      )}
                      {!user.editMode && (
                        <Col>
                          <small className="font-sm mb-1 d-block">
                            {t("Class")}
                          </small>
                          {user.classes.map(
                            (classes: any, classIndex: number) => {
                              return (
                                <div
                                  className="font-600 font-16"
                                  key={"user_" + index + "_class_" + classIndex}
                                >
                                  {classes?.label}
                                </div>
                              );
                            }
                          )}
                        </Col>
                      )}
                      {user.editMode && through !== "admin" && (
                        <Col className="doc-select-dropdown">
                          <small className="font-sm mb-1 d-block">
                            {t("Learner Group")}
                          </small>
                          <Select
                            isSearchable={false}
                            isMulti
                            isClearable={false}
                            controlShouldRenderValue={false}
                            className="select-target select-blue shadow-none"
                            classNamePrefix="select-target-prefix"
                            components={{
                              DropdownIndicator: (props: any) => (
                                <img src={chevblk} alt={t("dropdown arrow")} />
                              ),
                              IndicatorSeparator: () => null,
                            }}
                            onChange={(e: any) => {
                              setGroupsOfAUser(index, e);
                            }}
                            value={user.groups}
                            defaultValue={user.groups}
                            placeholder={t("Select Group")}
                            options={[...groups]}
                          />
                          {user.groups.map(
                            (classes: any, classIndex: number) => {
                              return (
                                <div
                                  className=" group-cross-tags d-flex align-items-center font-sm"
                                  key={"user_" + index + "_class_" + classIndex}
                                >
                                  <div className="pl-2 font-sm">
                                    {classes?.label}
                                  </div>
                                  <button
                                    type="button"
                                    className="btn ml-auto py-0"
                                    onClick={() => {
                                      removeGroupsOfAUser(index, classIndex);
                                    }}
                                  >
                                    <img src={closeRed} alt="" />
                                  </button>
                                </div>
                              );
                            }
                          )}
                        </Col>
                      )}
                      {!user.editMode && through !== "admin" && (
                        <Col>
                          <small className="font-sm mb-1 d-block">
                            {t("Learner Group")}
                          </small>
                          {user.groups.map(
                            (classes: any, classIndex: number) => {
                              return (
                                <div
                                  className="font-600 font-16"
                                  key={"user_" + index + "_class_" + classIndex}
                                >
                                  {classes?.label}
                                </div>
                              );
                            }
                          )}
                        </Col>
                      )}
                      {user.editMode && through === "admin" && (
                        <Col className="doc-select-dropdown">
                          <small className="font-sm mb-1 d-block">
                            {t("Permissions")}
                          </small>
                          <Select
                            isSearchable={false}
                            className="select-target select-blue shadow-none"
                            classNamePrefix="select-target-prefix"
                            components={{
                              //DropdownIndicator: () => null,
                              DropdownIndicator: (props: any) => (
                                <img src={chevblk} alt={t("dropdown arrow")} />
                              ),
                              IndicatorSeparator: () => null,
                            }}
                            onChange={(e: any) => {
                              // setSelectedButtonTarget({
                              //   ...selectedButtonTarget,
                              //   permission: e.value,
                              // });
                              setPermissionsOfAUser(index, e.value);
                            }}
                            placeholder={t("Permission")}
                            // getOptionLabel={(option: any) => option["label"]}
                            // getOptionValue={(option: any) => option["value"]}
                            options={[
                              { value: "admin", label: t("Can Edit") },
                              {
                                value: "readonlyadmin",
                                label: t("View Only"),
                              },
                            ]}
                            defaultValue={
                              user.student_id
                                ? user.student_id === "readonlyadmin"
                                  ? {
                                      value: "readonlyadmin",
                                      label: t("View Only"),
                                    }
                                  : user.student_id === "admin"
                                  ? { value: "admin", label: t("Can Edit") }
                                  : null
                                : null
                            }
                          />
                          {/* {user.groups.map(
                           (classes: any, classIndex: number) => {
                             return (
                               <div
                                 className=" group-cross-tags d-flex align-items-center font-sm"
                                 key={"user_" + index + "_class_" + classIndex}
                               >
                                 <div className="pl-2 font-sm">
                                   {classes?.label}
                                 </div>
                                 <button
                                   type="button"
                                   className="btn ml-auto py-0"
                                   onClick={() => {
                                     removeGroupsOfAUser(index, classIndex);
                                   }}
                                 >
                                   <img src={closeRed} alt="" />
                                 </button>
                               </div>
                             );
                           }
                         )} */}
                        </Col>
                      )}
                      {!user.editMode && through === "admin" && (
                        <Col>
                          <small className="font-sm mb-1 d-block">
                            {t("Permissions")}
                          </small>
                          {user?.student_id && <div>{user.student_id}</div>}
                          {/* {user.groups.map(
                            (classes: any, classIndex: number) => {
                              return (
                                <div
                                  className="font-600 font-16"
                                  key={"user_" + index + "_class_" + classIndex}
                                >
                                  {classes?.label}
                                </div>
                              );
                            }
                          )} */}
                        </Col>
                      )}

                      {!user.editMode && (
                        <Col className="text-right pr-0">
                          <button
                            className="btn"
                            onClick={() => {
                              handleEditMode(index);
                            }}
                          >
                            <img src={editIcon} alt="" />
                          </button>
                          <button
                            className="btn"
                            onClick={() => {
                              removeUserFromList(index);
                            }}
                          >
                            <img src={deleteIcon} alt="" />
                          </button>
                        </Col>
                      )}

                      {user.editMode && (
                        <Col className="text-right pr-0">
                          <button
                            className="btn btn-link font-sm"
                            onClick={() => {
                              handleEditMode(index);
                            }}
                          >
                            {t("Save")}
                          </button>
                          <button
                            className="btn btn-link font-sm"
                            onClick={() => {
                              cancelUser(index);
                            }}
                          >
                            {t("Cancel")}
                          </button>
                        </Col>
                      )}
                    </Row>
                  </div>
                );
              })}
          </div>
        </div>
      </section>
      {/* end */}

      {/* <section className="page-mid-wraper h-without-foter pb-0 p-0">
        <div className="container h-100 p-0">
          <div className="row h-100">
            <div className="col-md-12 h-100">
              <iframe
                id="myframe"
                src={windoeObj.OLD_PORTAL_URI + "user-management?viewMode=true"}
                title="Forms"
                className="test-bot-iframe"
                onLoad={iframeLoadedHandler}
              ></iframe>
            </div>
          </div>
        </div>
      </section> */}
      {/* <footer></footer> */}
    </>
  );
};

export default UploadListOfUsers;
