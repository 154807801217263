import axios from 'axios'
import { store } from './store';
import { logoutUser } from './store/auth/actions';
import ApiUtil from "./utils/apiUtil";


const windowObj: any = window;
const DataApiInstance = axios.create({
    baseURL: windowObj.APP_DATA_API_URL
})

// // Global authorization for all request
DataApiInstance.interceptors.request.use(
    async (req: any) => {
        // console.log('req', req)
        // Use below code to check 401 issue
        if (windowObj.REACT_APP_SSO_PROVIDER === 'oneauth') {
            const token =
                windowObj.REACT_APP_SSO_PROVIDER === 'oneauth'
                    ? localStorage.getItem('token')
                    : ""
            req.headers.Authorization = `Bearer ${token}`
        }else if (windowObj.REACT_APP_SSO_PROVIDER === "auth0") {
            const token = ApiUtil.getTokenSilently && await ApiUtil.getTokenSilently();
            if(token) {
                req.headers.Authorization = `Bearer ${token}`;
            } else {
                req.headers.Authorization = DataApiInstance.defaults.headers?.common?.Authorization
              }
          }
        return req
    },
    (err: any) => {
        console.log('aa', err);
        throw err
    }
)

// Dispatcher from store
// direct access to redux store.
const { dispatch } = store;

// Response interceptor to track all api response here 
DataApiInstance.interceptors.response.use((res: any) => res, (error: any) => {
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // console.log(error.response.data);
        // console.log(error.response.headers);
        // user has been logged out in case of 401 
        if (error.response.status === 401 && windowObj.REACT_APP_SSO_PROVIDER === 'oneauth') {
            dispatch(logoutUser());
        }
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        // console.log(error.request);
    } else {
        // Something happened in setting up the request that triggered an Error
        // dispatch(logoutUser());
        // console.log('Error', error);
        // console.log('Error', error.message);
    }
});

export default DataApiInstance
