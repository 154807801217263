import MultipleChoice from "../../../../assets/quizGuide/multiple-choice.png";
import TopicAccesses from "../../../../assets/glossary/learning-topic-accesses.png";
import ChatbotRating from "../../../../assets/glossary/chatbot-rating.png";
import i18n from "../../../../components/UI/Internationalisation/i18n";
import EssayCriterionTable from "./essayCriterion";

const { t } = i18n;
export const indexData = [
  {
    title: t("Question Types"),
    id: "questionTypes",
    items: [
      {
        label: t("Multiple Choice (select one)"),
        anchor: "radio",
      },
      {
        label: t("Checkboxes (select many)"),
        anchor: "checkbox",
      },
      {
        label: t("Text (paragraph)"),
        anchor: "text",
      },
      {
        label: t("Text (points)"),
        anchor: "bullet",
      },
      {
        label: t("Table"),
        anchor: "table",
      },
      {
        label: t("Header/Description"),
        anchor: "description",
      },
      {
        label: t("Matching"),
        anchor: "matching",
      },
      {
        label: t("Group Question"),
        anchor: "group",
      }
    ],
  },
  {
    title: t("Scoring Methods (score by)"),
    id: "scoring",
    items: [
      {
        label: t("Matching similar sentences"),
        anchor: "similar_match",
      },
      {
        label: t("Matching exactly with ideal answer"),
        anchor: "exact_match",
      },
      {
        label: t("Keywords"),
        anchor: "keywords",
      },
      {
        label: t("Essay Grader (Beta)"),
        anchor: "essay_grader",
      },
    ],
  },
];

export const questionTypesGuide = [
  {
    definition: [
      {
        text: "This dropdown is to select the type of question you want to create. You can create multiple choice questions, short/long text questions, table questions and questions that requires matching two items. Let's go through each option one by one.",
      },
    ],
  },
  {
    term: t("Multiple Choice (select one)"),
    id: "radio",
    definition: [
      {
        text: t("Choose this option if you want to create a multiple choice question with only one correct answer. User will be shown the options you define and can) select only one."),
      },
      {
        text: t("Following will be the user view of a multiple choice question."),
      },
      {
        image: MultipleChoice,
      },
    ],
  },
  {
    term: t("Checkboxes"),
    id: "checkbox",
    definition: [
      {
        text: t("Choose this question type when you want to give user multiple options and user can select one or more out of those."),
      },
    ],
  },
  {
    term: t("Text (paragraph)"),
    id: "text",
    definition: [
      {
        text: t("As the name suggests, this question type is ideal when you want the user to enter a short/long text answer to the question. You can use this type for) essay type questions as well."),
      },
    ],
  },
  {
    term: t("Text (points)"),
    id: "bullet",
    definition: [
      {
        text: t("You can choose this type when you want the user to enter the answer in points. This is recommended user is expected to enter answer as a list or a set) of steps/sequence."),
      },
    ],
  },
  {
    term: t("Table"),
    id: "table",
    definition: [
      {
        text: t("As the name suggests, this question type is perfect when you want users to enter answers in table format."),
      },
    ],
  },
  {
    term: t("Header/Description"),
    id: "description",
    definition: [
      {
        text: t("Want users to list items with their descriptions? Then, this is the question type for you. You can use this type when you expect users to list some) terms/items with their description/explanation."),
      },
    ],
  },
  {
    term: t("Group Question"),
    id: "group",
    definition: [
      {
        text: t("You can choose this when you want to create multiple sub-questions (say 1a, 1b, 1c, etc) under a main question (say question 1).")
      },
    ],
  },
  {
    term: t("Matching"),
    id: "matching",
    definition: [
      {
        text: t("Have questions that require matching items? This one is a perfect fit for 'match the following...' type of questions."),
      },
    ],
  },
];

export const scoringGuide = [
  {
    definition: [
      {
        text: "",
      },
    ],
  },
  {
    term: t("Matching similar sentences"),
    id: "similar_match",
    definition: [
      {
        text:
          t("This is the default scoring style where we check the similarity between the answer key and the answer submitted by the user. If the user answer is very close or same as the answer key, we reward the score."),
      },
    ],
  },
  {
    term: t("Matching exactly with ideal answer"),
    id: "exact_match",
    definition: [
      {
        text:
          t("Choose this scoring method when the user answer must be exactly the same as the answer key. This is recommended for factual/objective type questions that have a specific answer."),
      },
    ],
  },
  {
    term: t("Keywords"),
    id: "keywords",
    definition: [
      {
        text:
        t("Want to score the answer only when certain keywords are present in the user answer? This is the scoring method for you. You can list the keywords with their respective score in the keywords table. User answer will be searched for these keywords to assign score."),
      },
      {
        question:
          t("How do I add different variations of a keyword in the keyword table?"),
      },
      {
        text:
          t("You can specify variations of a keyword in the same cell separated by a comma."),
      },
    ],
  },
  {
    term: "Essay Grader (Beta)",
    id: "essay_grader",
    definition: [
      {
        text:t("Grade an essay using our essay grading model and predefined criteria."),
      },
      {
        html: <EssayCriterionTable />,
      }
    ],
  },
];
