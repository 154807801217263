import classNames from "classnames";
import { useEffect, useState } from "react";
import {
  Button,
  Image,
  Modal,
  ModalFooter,
  ModalTitle,
  Spinner,
} from "react-bootstrap";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import closeimg from "../../../assets/images/close-blk.svg";
import { IMPORTQUESTIONBANK, INTEGRATED_SOURCES, IMPORT_QUIZ } from "../../../config";
import { HelperService } from "../../../services/helper.service";
import crownImg from "../../../assets/New-images/tootlkit-crown-img.svg";
import { addEntityToKnowledgeGroup } from "../../../store/knowledge/actions";
import { createQuizButtons, createQuizz } from "../../../store/quizzes/actions";
import { CREATE_SMART_QUIZ, GUIDED_QUIZESS_IMPORT } from "../../../config";

export default function CreateQuizModalTeachersToolkit(props: any) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const useLocatio = useLocation();
  const helperService = new HelperService();
  const query = new URLSearchParams(useLocatio.search);
  const group = query.get("group");
  const [quiz, setQuiz] = useState({
    step: 1,
    name: null,
    description: null,
    shouldCreateButton: true,
    displayMode: null,
    questionMode: "one_by_one",
    quizMode: null,
    questionTypes: [],
  });
  const [loading, setLoading] = useState(false);
  const [queType, setQueType] = useState([
    {
      isChecked: false,
      text: t("Multiple Choice Questions"),
      type: "quiz_mcq",
    },
    { isChecked: false, text: t("Open-Ended Questions"), type: "quiz_text" },
  ]);
  const [groupId, setGroupId] = useState(group ? group : null);

  useEffect(() => {
    if (props.show) {
      setQuiz({
        step: 1,
        name: null,
        description: null,
        shouldCreateButton: true,
        displayMode: null,
        questionMode: "one_by_one",
        quizMode: props.createSmartQuiz === true ? "smart_quiz" : null,
        questionTypes: [],
      });
      setQueType([
        {
          isChecked: false,
          text: t("Multiple Choice Questions"),
          type: "quiz_mcq",
        },
        {
          isChecked: false,
          text: t("Open-Ended Questions"),
          type: "quiz_text",
        },
      ]);
      if (props.selectedGroupId) {
        setGroupId(props.selectedGroupId);
      }
      setLoading(false);
    }
  }, [props.show]);

  useEffect(() => {
    const type = queType
      .filter((e: any) => e.isChecked === true)
      .map((e) => e.type);
    setQuiz({
      ...quiz,
      questionTypes: [...type],
    });
  }, [queType]);

  // useEffect(() => {
  //   if (props.CreateSmartQuiz === true) {
  //     setQuiz({
  //       ...quiz,
  //       quizMode: "smart_quiz",
  //       step: 2,
  //     });
  //   }
  // }, [props.CreateSmartQuiz]);

  const selectedQueType = (event: any, i: any) => {
    let temp = [...queType];
    temp[i].isChecked = event;
    setQueType(temp);
  };

  const nextStepHandler = async () => {
    if (quiz.step === 1 && (!quiz.name || !quiz.displayMode)) {
      helperService.showToaster(
        !quiz.name
          ? "Please enter exercise name."
          : !quiz.displayMode
          ? "Please Choose exercise format"
          : ""
      );
      return;
    }

    // ROLE PLAY
    if (quiz.step === 1 && quiz.displayMode === "roleplay") {
      setLoading(true);

      const response: any = await dispatch(
        createQuizz({
          name: quiz.name,
          type: "roleplay",
          display_mode: "conversational_v2",
        })
      );

      if (props.selectedGroupId) {
        await dispatch(
          addEntityToKnowledgeGroup(props.selectedGroupId, "quiz", [
            response._id,
          ], false, true)
        );
      }
      if (quiz.shouldCreateButton) {
        await createQuizButton(response);
      }

      history.push({
        pathname: `/quizzes/edit/${response._id}?type=build`,
        state: { origin: "create" },
      });

      setLoading(false);
      return;
    }

    // ENABLE THIS WHEN YOU NEED SEOND STEP
    if (
      quiz.step === 2 &&
      (!quiz.name || !quiz.displayMode || !quiz.questionMode)
    ) {
      return;
    }
    const question_ids =
      (props?.selectedQuestionsFromBank &&
        props.selectedQuestionsFromBank.map((q: any) => q?.rowId)) ||
      [];

    if (quiz.step === 2 || (quiz.step === 1 && question_ids.length > 0)) {
      setLoading(true);
      let response: any = {};
      try {
        if (quiz.questionMode !== "from_question_bank") {
          response = await dispatch(
            createQuizz(
              props.selectedQuestionsFromBank
                ? {
                    name: quiz.name,
                    description: quiz.description ? quiz.description : "",
                    display_mode: quiz.displayMode,
                    question_ids: props.selectedQuestionsFromBank.map(
                      (q: any) => q?.rowId
                    ),
                  }
                : {
                    name: quiz.name,
                    description: quiz.description ? quiz.description : "",
                    display_mode: quiz.displayMode,
                  }
            )
          );
        }

        if (groupId && quiz.questionMode !== "from_question_bank") {
          await dispatch(
            addEntityToKnowledgeGroup(groupId, "quiz", [response._id], true)
          );
        }

        if (quiz.shouldCreateButton) {
          createQuizButton(response);
        } else {
          setLoading(false);
          if (quiz.questionMode === "one_by_one") {
            history.push({
              pathname: `/quizzes/edit/${response._id}?type=build`,
              state: { origin: "create" },
            });
          } else if (quiz.questionMode === "from_document") {
            history.push({
              pathname: CREATE_SMART_QUIZ,
              state: {
                groupId: groupId,
                questionType: quiz.questionTypes,
                quizId: response._id,
              },
              search: `?quizId=${response._id}`,
            });
          } else if (quiz.questionMode === "import_from_document") {
            history.push({
              pathname: "/import-quiz",
              state: { quizId: response._id },
              search: `?type=quiz&quizId=${response._id}`,
            });
          } else if (quiz.questionMode === "from_question_bank") {
            history.push({
              pathname: `${GUIDED_QUIZESS_IMPORT}`,
              state: {
                groupId: groupId,
                name: quiz.name,
                description: quiz.description,
                displayMode: quiz.displayMode,
                createButtons: false,
              },
              search: `?groupId=${groupId}&name=${quiz.name}&
                description=${quiz.description}&displayMode=${quiz.displayMode}&createButtons=false`
            });
          } else if (quiz.questionMode === "from_integrated_sources") {
            history.push({
              pathname: INTEGRATED_SOURCES,
              state: { quizId: response._id },
              search: `?type=quiz&quizId=${response._id}`,
            });
          }
        }
      } catch (error) {
        setLoading(false);
      }
    } else {
      setQuiz({
        ...quiz,
        step: quiz.step + 1,
      });
    }

    // Old Functionality for reference
    // if (quiz.step === 2 && !quiz.name) {
    //   helperService.showToaster(!quiz.name && t("Please enter quiz name."));
    //   return;
    // }

    // if (quiz.step === 2 && quiz.quizMode === "smart_quiz") {
    //   if (quiz.questionTypes.length === 0) {
    //     helperService.showToaster(t("Please choose question type."));
    //     return;
    //   }
    // }

    // try {
    //   setLoading(true);
    //   let response: any = {};
    //   if (quiz.displayMode !== "import_from_que_bank") {
    //     response = await dispatch(
    //       createQuizz(
    //         props.selectedQuestionsFromBank
    //           ? {
    //               name: quiz.name,
    //               description: quiz.description ? quiz.description : "",
    //               display_mode: "conversational",
    //               question_ids: props.selectedQuestionsFromBank,
    //             }
    //           : {
    //               name: quiz.name,
    //               description: quiz.description ? quiz.description : "",
    //               display_mode: "conversational",
    //             }
    //       )
    //     );
    //   }
    //   if (groupId && quiz.displayMode !== "import_from_que_bank") {
    //     await dispatch(
    //       addEntityToKnowledgeGroup(groupId, "quiz", [response._id], true)
    //     );
    //   }
    //   setLoading(false);
    //   if (quiz.step === 2 && quiz.quizMode === "smart_quiz") {
    //     history.push({
    //       pathname: CREATE_SMART_QUIZ,
    //       state: {
    //         groupId: groupId,
    //         questionType: quiz.questionTypes,
    //         quizId: response._id,
    //       },
    //     });
    //   } else if (quiz.step === 2 && quiz.quizMode === "guided") {
    //     if (quiz.displayMode === "import_from_que_bank") {
    //       history.push({
    //         pathname: `${GUIDED_QUIZESS_IMPORT}`,
    //         state: {
    //           groupId: groupId,
    //           name: quiz.name,
    //           description: quiz.description,
    //           displayMode: quiz.displayMode,
    //         },
    //       });
    //     } else if (quiz.displayMode === "import_test") {
    //       history.push(
    //         IMPORT_QUIZ +
    //           // IMPORTQUESTIONBANK
    //           "?group=" +
    //           groupId,
    //         {
    //           quizId: response._id, //call from create/edit quiz
    //         }
    //       );
    //     }
    //   }
    // } catch (error) {
    //   setLoading(false);
    // }
  };

  const createQuizButton = async (data: any) => {
    try {
      let details: any = {};
      let response: any = {};

      details.buttons = [
        {
          group_ids: [],
          label: quiz.displayMode === "roleplay" ? `Role Play: ${quiz.name}` : `Quiz: ${quiz.name}`,
          reference_id: data._id,
          type: "quiz",
        },
      ];
      details.type = 3;
      details.name = `${quiz.name} Quiz`;
      details.parent_id = "";
      details.questions = [
        {
          text: quiz.displayMode === "roleplay" ? `Role Play: ${quiz.name}` : `Quiz: ${quiz.name}`,
        },
      ];
      details.responses = [
        {
          text: "Please select the button below.",
        },
      ];
      if (quiz.questionMode !== "from_question_bank") {
        response = await dispatch(createQuizButtons(details));
      }
      if (
        groupId &&
        response &&
        response.intent &&
        response.intent._id &&
        quiz.questionMode !== "from_question_bank"
      ) {
        await dispatch(
          addEntityToKnowledgeGroup(groupId, "intent", [response.intent._id])
        );
      }
      setLoading(false);
      if (quiz.questionMode === "one_by_one") {
        history.push({
          pathname: `/quizzes/edit/${data._id}?type=build`,
          state: { origin: "create" },
        });
      } else if (quiz.questionMode === "from_document") {
        history.push({
          pathname: CREATE_SMART_QUIZ,
          state: {
            groupId: groupId,
            questionType: quiz.questionTypes ? quiz.questionTypes : null,
            quizId: data._id, 
          },
          search: `?type=quiz&quizId=${data._id}`,
        });
      } else if (quiz.questionMode === "import_from_document") {
        history.push({
          pathname: "/import-quiz",
          state: { quizId: data._id },
          search: `?type=quiz&quizId=${data._id}`,
        });
      } else if (quiz.questionMode === "from_question_bank") {
        history.push({
          pathname: `${GUIDED_QUIZESS_IMPORT}`,
          state: {
            groupId: groupId,
            name: quiz.name,
            description: quiz.description,
            displayMode: quiz.displayMode,
            createButtons: true,
          },
          search: `?groupId=${groupId}&name=${quiz.name}&
                description=${quiz.description}&displayMode=${quiz.displayMode}&createButtons=false`
        });
      } else if (quiz.questionMode === "from_integrated_sources") {
        history.push({
          pathname: INTEGRATED_SOURCES,
          state: { quizId: data._id },
          search: `?type=quiz&quizId=${data._id}`,
        });
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Modal
      show={props.show}
      centered
      dialogClassName={quiz.step === 2 ? "modal-550" : "modal-683"}
      onHide={props.onHide}
    >
      <ModalHeader className="clr-black pt-4 justify-content-between mb-3">
        {/* <ModalTitle id="contained-modal-title-vcenter font-18"> */}
        <h2 className="mb-0 font-18">{quiz.step === 2 ? t("Create a quiz") : t("Create an exercise")}</h2>
        {/* </ModalTitle> */}
        <button onClick={props.onHide} className="btn">
          <Image src={closeimg} />
        </button>
      </ModalHeader>
      <ModalBody className="py-0">
        {quiz.step === 2 && (
          <>
            <div className="form-group">
              <label className="font-14">{t("Add questions to quiz")} </label>
              <p className="clr-grey-txt7 font-600">
                {t("How would you like to add questions into the quiz")}
              </p>
              <div className="row px-2 justify-content-center">
                <div
                  className="col-6 col-md-6 text-center px-2"
                  onClick={() => {
                    setQuiz({
                      ...quiz,
                      questionMode: "from_document",
                    });
                  }}
                >
                  <div
                    className={classNames({
                      "py-4 px-3 border-grey radius-8 tw-min-h-40 tw-flex tw-flex-col tw-justify-center tw-cursor-pointer d-flex align-items-center":
                        true,
                      "tw-bg-primary tw-text-white": quiz.questionMode === "from_document",
                    })}
                  >
                    <span>
                      <h3>{t("Generate from Document(s)")}</h3>
                      <p className="tw-mt-2">
                        {t(
                          "Upload or select document(s) to generate exercise questions"
                        )}
                      </p>
                    </span>
                  </div>
                </div>
                <div
                  className="col-6 col-md-6 text-center px-2"
                  onClick={() => {
                    setQuiz({
                      ...quiz,
                      questionMode: "from_question_bank",
                    });
                  }}
                >
                  <div
                    className={classNames({
                      "py-4 px-3 border-grey radius-8 tw-min-h-40 tw-flex tw-flex-col tw-justify-center tw-cursor-pointer d-flex align-items-center":
                        true,
                      "tw-bg-primary tw-text-white":
                        quiz.questionMode === "from_question_bank",
                    })}
                  >
                    <span>
                      <h3>{t("Add from Question Bank")}</h3>
                      <p className="tw-mt-2">
                        {t("Select exercise questions from question bank")}
                        {/* {" "} */}
                        {/* <br /> {t("(Requires certain formatting)")} */}
                      </p>
                    </span>
                  </div>
                </div>
              </div>

              <div className="row px-2 mt-3 justify-content-center">
                <div
                  className="col-6 col-md-6 text-center px-2"
                  onClick={() => {
                    setQuiz({
                      ...quiz,
                      questionMode: "import_from_document",
                    });
                  }}
                >
                  <div
                    className={classNames({
                      "py-4 px-3 border-grey radius-8 tw-min-h-40 tw-flex tw-flex-col tw-justify-center tw-cursor-pointer d-flex align-items-center":
                        true,
                      "tw-bg-primary tw-text-white":
                        quiz.questionMode === "import_from_document",
                    })}
                  >
                    <span>
                      <h3>{t("Upload a Test")}</h3>
                      <p className="tw-mt-2">
                        {t(
                          "Extract exercise questions from an uploaded test document"
                        )}
                        {/* {" "} */}
                        {/* <br /> {t("(Requires certain formatting)")} */}
                      </p>
                    </span>
                  </div>
                </div>
                <div
                  className="col-6 col-md-6 text-center px-2"
                  onClick={() => {
                    setQuiz({
                      ...quiz,
                      questionMode: "from_integrated_sources",
                    });
                  }}
                >
                  <div
                    className={classNames({
                      "py-4 px-3 border-grey radius-8 tw-min-h-40 tw-flex tw-flex-col tw-justify-center tw-cursor-pointer d-flex align-items-center":
                        true,
                      "tw-bg-primary tw-text-white":
                        quiz.questionMode === "from_integrated_sources",
                    })}
                  >
                    <span>
                      <h3>{t("Import from Integrated Source")}</h3>
                      <p className="tw-mt-2">
                        <span>
                          {t("Upload and generate questions from a document")}
                        </span>
                        <br />
                        <span>{t("(Requires certain formatting)")}</span>
                      </p>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="font-12 clr-grey-txt7 font-600 pb-3 text-center d-flex flex-wrap align-items-center justify-content-center">
              {t("or would you like to create from scratch? ")}
              <button
                className={classNames({
                  "btn btn-link p-0 font-12 ml-2": true,
                  "font-weight-bolder":
                    quiz && quiz.questionMode === "one_by_one",
                })}
                onClick={async () => {
                  await setQuiz({
                    ...quiz,
                    questionMode: "one_by_one",
                  });
                  nextStepHandler();
                }}
              >
                {t("Create from scratch")}
              </button>
            </div>
          </>
        )}

        {quiz.step === 1 && (
          <>
            <div className="form-group">
              <label className="font-600">{t("Exercise Name")}</label>
              <input
                type="text"
                className="form-control radius-2 h-auto px-2 py-1"
                placeholder={t("Enter name of exercise")}
                value={quiz.name || ""}
                onChange={(e: any) => {
                  setQuiz({
                    ...quiz,
                    name: e.target.value,
                  });
                }}
              />
            </div>
            <div className="form-group">
              <label className="font-600">
                {t("Exercise Description/Instructions (Optional)")}{" "}
              </label>
              <input
                type="text"
                className="form-control radius-2 h-auto px-2 py-1"
                placeholder={t("Enter description/instructions")}
                value={quiz.description || ""}
                onChange={(e: any) => {
                  setQuiz({
                    ...quiz,
                    description: e.target.value,
                  });
                }}
              />
            </div>
            <div className="custom-control custom-radio my-3">
              <input
                type="checkbox"
                className="custom-control-input"
                id="custom-radio1"
                checked={quiz.shouldCreateButton || false}
                onChange={(e: any) => {
                  setQuiz({
                    ...quiz,
                    shouldCreateButton: e.target.checked,
                  });
                }}
              />
              <label className="custom-control-label" htmlFor="custom-radio1">
                {t("Create a button for this exercise")}
              </label>
            </div>
            <div className="form-group">
              <label className="font-600">{t("Exercise Format")}</label>
              <div className={
                classNames(
                  props.isRolePlayEnabled ? "tw-grid-cols-2" : "tw-grid-cols-3",
                  "tw-grid tw-gap-2"
                )
              }>
                <div
                  onClick={() => {
                    setQuiz({
                      ...quiz,
                      displayMode: "single_question",
                    });
                  }}
                  className="text-center mb-3 mb-sm-0"
                >
                  <div
                    className={classNames({
                      "py-4 px-3 border-grey radius-8 tw-min-h-40 tw-flex tw-flex-col tw-justify-center tw-cursor-pointer": true,
                      "tw-bg-primary tw-text-white": quiz.displayMode === "single_question",
                    })}
                  >
                    <h3>{t("One question at a time")}</h3>
                    <p className="tw-mt-2">
                      {t(
                        "Questions will be shown one by one to the user with next and previous buttons. "
                      )}
                    </p>
                  </div>
                </div>
                <div
                  onClick={() => {
                    setQuiz({
                      ...quiz,
                      displayMode: "continuous_scroll",
                    });
                  }}
                  className="text-center mb-3 mb-sm-0"
                >
                  <div
                    className={classNames({
                      "py-4 px-3 border-grey radius-8 tw-min-h-40 tw-flex tw-flex-col tw-justify-center tw-cursor-pointer": true,
                      "tw-bg-primary tw-text-white":
                        quiz.displayMode === "continuous_scroll",
                    })}
                  >
                    <h3>{t("All question at once")}</h3>
                    <p className="tw-mt-2">
                      {t(
                        "All questions will be shown to the user one below the other in a single page. "
                      )}
                    </p>
                  </div>
                </div>
                <div
                  onClick={() => {
                    setQuiz({
                      ...quiz,
                      displayMode: "conversational",
                    });
                  }}
                  className="text-center mb-3 mb-sm-0"
                >
                  <div
                    className={classNames({
                      "py-4 px-3 border-grey radius-8 tw-min-h-40 tw-flex tw-flex-col tw-justify-center tw-cursor-pointer": true,
                      "tw-bg-primary tw-text-white": quiz.displayMode === "conversational",
                    })}
                  >
                    <h3>{t("Conversational")}</h3>
                    <p className="tw-mt-2">
                      {t(
                        "Questions will be shown one by one to the user in the chat like a conversation. "
                      )}
                    </p>
                  </div>
                </div>
                {props.isRolePlayEnabled && <div
                  onClick={() => {
                    setQuiz({
                      ...quiz,
                      displayMode: "roleplay",
                    });
                  }}
                  className="text-center mb-3 mb-sm-0"
                >
                  <div
                    className={classNames({
                      "py-4 px-3 border-grey radius-8 tw-min-h-40 tw-flex tw-flex-col tw-justify-center tw-cursor-pointer": true,
                      "tw-bg-primary tw-text-white": quiz.displayMode === "roleplay",
                    })}
                  >
                    <h3>{t("Role play")}</h3>
                    <p className="tw-mt-2">
                      {t(
                        "Simulate real-world interactions with practical scenarios, guiding learners through adaptive question flow or opting for a conversational approach."
                      )}
                    </p>
                  </div>
                </div>}
              </div>
            </div>
          </>
        )}
      </ModalBody>
      <ModalFooter className=" border-0 pb-3">
        <Button
          className={`btn-primary btn my-0 font-600 ${
            loading ? "next-btn-disable" : ""
          }`}
          onClick={nextStepHandler}
          disabled={loading}
        >
          {loading && (
            <div className="spinner-border spinner-border-sm next-spinner"></div>
          )}
          {!loading && t("Next")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
