import React, { useEffect, useState } from "react";
import {
  ModalTitle,
  Button,
  ModalBody,
  ModalFooter,
  Image,
  Modal,
} from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import closeImg from "../../../../assets/New-images/build-knowledge-close-img.svg";
import { useTranslation } from "react-i18next";
import Checkbox from "../../../Quizzes/OldPortalComponents/Checkbox";

const SmartQuizPopup = (props: any) => {
  const { t } = useTranslation("translation");
  const [queType, setQueType] = useState([
    { isChecked: false, text: t("Multiple Choice Questions"), type: "quiz_mcq" },
    { isChecked: false, text: t("Open-Ended Questions"), type: "quiz_text" },
  ]);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    setDisabled(!queType.some((e: any) => e.isChecked === true));
  }, [queType]);

  const selectedQueType = (event: any, i: any) => {
    let temp = [...queType];
    temp[i].isChecked = event;
    setQueType(temp);
  };

  const onProceedHandler = (type: any, hide: boolean = false) => {
    if (hide) {
      setQueType([
        {
          isChecked: false,
          text: t("Multiple Choice Questions"),
          type: "quiz_mcq",
        },
        { isChecked: false, text: t("Open-Ended Questions"), type: "quiz_text" },
      ]);
    }
    props.onProceed(
      type.filter((e: any) => e.isChecked === true),
      hide
    );
  };


  return (
    <React.Fragment>
      <Modal show={props.show} centered>
        <ModalHeader className="clr-black pt-3 pb-1 justify-content-between">
          <ModalTitle id="contained-modal-title-vcenter">
            <h2 className="mb-0 font-1">{t("Smart Quiz ")}</h2>
          </ModalTitle>
          <button className="btn" onClick={props.onHide}>
            <img src={closeImg} alt="" />
          </button>
        </ModalHeader>
        <ModalBody>
          <h3 className="font-600 mb-3">
            {t("Would you like us to automatically create a quiz for you?")}
          </h3>
          <div>
            <label className="font-400">{t("Select type of questions included in quiz")}</label>
            <div className="pl-3">
              {queType &&
                queType.map((item: any, index: any) => {
                  return (
                    <div className="custom-checkbox my-1 mb-2 d-flex align-items-center font-300">
                      <input
                        type="checkbox"
                        // disabled
                        className="mr-2"
                        checked={item.isChecked}
                        onChange={(e) =>
                          selectedQueType(e.target.checked, index)
                        }
                      />
                      <label className="mb-0">{item?.text}</label>
                    </div>
                  );
                })}
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="p-1">
          <button
            className="btn-outline-primary btn ml-3 mr-0"
            onClick={() => onProceedHandler(queType, true)}
          >
            {t("No, Thanks")}
          </button>
          <button
            className="btn-primary btn ml-2 mr-2"
            onClick={() => onProceedHandler(queType, false)}
            disabled={disabled}
          >
            {t("Create Quiz")}
          </button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default SmartQuizPopup;
