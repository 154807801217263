import React, { useEffect, useState } from "react";
import IconLabelButton from "../IconLabelButton";
import { v4 as uuidv4 } from "uuid";

import TextInput from "../TextInput";

import "./style.scss";

const HeaderDescriptionEditor = ({
  disableHeader,
  onTableChange,
  initialRowCount,
  tableValues,
  hideActions = false,
  hideAddRow = false,
}: any) => {
  const [tableMatrix, setTableMatrix] = useState(
    tableValues ? tableValues : [["", ""]]
  );

  useEffect(() => {
    if (initialRowCount) {
      let temp = new Array(initialRowCount).fill(new Array(2).fill(""));
      setTableMatrix(temp);
    }
  }, []);

  useEffect(() => {
    onTableChange(tableMatrix);
  }, [tableMatrix]);

  const onRowCellChange = (value: any, rowIndex: any, rowCellindex: any) => {
    let temp = [...tableMatrix];
    temp[rowIndex][rowCellindex] = value;
    setTableMatrix(temp);
  };

  const addNewRow = () => {
    let temp = [...tableMatrix];
    temp.push(new Array(2).fill(""));
    setTableMatrix(temp);
  };

  const handleEnter = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      return;
    }
  };

  const insertNewRow = (targetRowIndex: any) => {
    let temp = [...tableMatrix];
    temp.splice(targetRowIndex + 1, 0, new Array(2).fill(""));
    setTableMatrix(temp);
  };

  const removeRow = (targetRowIndex: any) => {
    let temp = [...tableMatrix];
    temp.splice(targetRowIndex, 1);
    setTableMatrix(temp);
  };

  return (
    <div>
      <div className="flex-container no-justify-flex">
        <div className="description-editor-wrapper">
          <div className="description-editor-body-wrapper">
            {tableMatrix &&
              tableMatrix.map((tableRow: any, rowIndex: any) => (
                <div
                  key={rowIndex.toString()}
                  className="description-editor-row"
                >
                  <div className="header-description-pair">
                    {tableRow.map((tableCell: any, rowCellIndex: any) => (
                      <div
                        key={`row${rowIndex.toString()}${rowCellIndex.toString()}`}
                        className={
                          rowCellIndex === 0
                            ? "table-header-cell-wrapper"
                            : "table-row-cell-wrapper"
                        }
                      >
                        <TextInput
                          useTextArea
                          id={`row${rowIndex.toString()}${rowCellIndex.toString()}`}
                          value={tableCell}
                          inputChange={(event: any) =>
                            onRowCellChange(
                              event.target.value,
                              rowIndex,
                              rowCellIndex
                            )
                          }
                          // onInputClick={onInputClick}
                          // max={2}
                          min={rowCellIndex === 0 ? 1 : 2}
                          max={rowCellIndex === 0 ? 1 : 20}
                          placeholder={
                            rowCellIndex === 0
                              ? "Type header text here"
                              : "Type text here"
                          }
                          onKeyPress={handleEnter}
                          disabled={
                            disableHeader && rowCellIndex === 0 ? true : false
                          }
                        />
                      </div>
                    ))}
                  </div>
                  {!hideActions && (
                    <div className="description-editor-row-actions">
                      <button
                        onClick={() => {
                          insertNewRow(rowIndex);
                        }}
                        className="toolbar-btn"
                        title="Insert row below"
                      >
                        <i className="icon-add toolbar-icon" />
                      </button>
                      {tableMatrix.length > 1 && (
                        <button
                          onClick={() => {
                            removeRow(rowIndex);
                          }}
                          className="toolbar-btn"
                          title="Remove row"
                        >
                          <i className="icon-delete toolbar-icon" />
                        </button>
                      )}
                    </div>
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="quarter-clearfix" />
      {!hideAddRow && (
        <IconLabelButton
          showIcon
          iconClass="icon-add"
          buttonLabel="Add row"
          onClick={addNewRow}
        />
      )}
    </div>
  );
};

export default HeaderDescriptionEditor;
