import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import IconLabelButton from "../IconLabelButton";
import TextInput from "../TextInput";

import "./style.scss";

const TableEditor = ({
  disableHeader,
  headerValues,
  onHeaderChange,
  onTableChange,
  initialRowCount,
  tableValues,
  initialColumnCount,
  disableColumnAdd,
  enableColumnHeader,
  updateEnableColumnHeader,
  columnHeaderValues,
}: any) => {
  const { t } = useTranslation();
  const [tableMatrix, setTableMatrix] = useState(
    tableValues?.length ? tableValues : [["", ""]]
  );
  const [tableHeader, setTableHeader] = useState(
    headerValues ? headerValues : ["", ""]
  );
  const [isColumnHeader, setIsColumnHeader] = useState(enableColumnHeader);

  useEffect(() => {
    tableValues?.length && setTableMatrix(tableValues);
  }, [tableValues]);
  
  useEffect(() => {
    
    if (enableColumnHeader) {
      let rows = tableValues ? tableValues : [];
      if (!rows.length) {
        for (let i = 0; i < columnHeaderValues.length; i++) {
          rows.push(new Array(headerValues.length - 1).fill(""));
        }
      }
      rows.map((row: any, index: any) => row.unshift(columnHeaderValues[index]));
      setTableMatrix(rows);
    } else if (initialRowCount) {
      let temp = new Array(initialRowCount).fill(
        new Array(headerValues.length).fill("")
      );
      setTableMatrix(temp);
    }
  }, []);

  useEffect(() => {
    !disableHeader && onHeaderChange(tableHeader);
  }, [tableHeader]);

  useEffect(() => {
    !disableHeader &&
      updateEnableColumnHeader &&
      updateEnableColumnHeader(isColumnHeader);
  }, [isColumnHeader]);

  useEffect(() => {
    onTableChange(tableMatrix, isColumnHeader);
  }, [tableMatrix]);

  const onHeaderCellChange = (value: any, headerIndex: any) => {
    let temp = [...tableHeader];
    temp[headerIndex] = value;
    setTableHeader(temp);
  };

  const onRowCellChange = (value: any, rowIndex: any, rowCellindex: any) => {
    let temp = [...tableMatrix];
    temp[rowIndex][rowCellindex] = value;
    setTableMatrix(temp);
  };

  const onInputClick = (event: any) => {
    event.target.select();
  };

  const addNewRow = () => {
    let temp = [...tableMatrix];
    temp.push(new Array(tableHeader.length).fill(""));
    setTableMatrix(temp);
  };

  const addNewColumn = () => {
    let temp = [...tableMatrix];
    let tempHeader = [...tableHeader];
    temp.forEach((item) => item.push(""));
    tempHeader.push("");
    setTableMatrix(temp);
    setTableHeader(tempHeader);
  };

  const handleEnter = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      return;
    }
  };

  const insertNewRow = (targetRowIndex: any) => {
    let temp = [...tableMatrix];
    temp.splice(targetRowIndex + 1, 0, new Array(tableHeader.length).fill(""));
    setTableMatrix(temp);
  };

  const removeRow = (targetRowIndex: any) => {
    let temp = [...tableMatrix];
    temp.splice(targetRowIndex, 1);
    setTableMatrix(temp);
  };

  const removeColumn = (columnIndex: any) => {
    let temp = [...tableMatrix];
    let tempHeader = [...tableHeader];
    temp.forEach((item) => item.splice(columnIndex, 1));
    tempHeader.splice(columnIndex, 1);
    setTableMatrix(temp);
    setTableHeader(tempHeader);
  };

  const convertToHeader = () => {
    setIsColumnHeader(true);
  };

  const convertToColumn = () => {
    setIsColumnHeader(false);
  };

  return (
    <div>
      <div className="flex-container no-justify-flex">
        <div className="table-editor-wrapper">
          <div className="table-editor-header-row">
            {disableHeader &&
              tableHeader &&
              tableHeader.map((headerName: any, index: any) => (
                <div
                  key={index.toString()}
                  className="table-header-disabled-cell"
                >
                  {headerName}
                </div>
              ))}
            {!disableHeader &&
              tableHeader &&
              tableHeader.map((headerName: any, index: any) => (
                <div className="table-header-cell-wrapper">
                  <TextInput
                    id={`header${index.toString()}`}
                    key={`header${index.toString()}`}
                    value={headerName}
                    inputChange={(event: any) =>
                      onHeaderCellChange(event.target.value, index)
                    }
                    // onInputClick={onInputClick}
                    placeholder={t("Type header text here")}
                  />
                  <div className="column-toolbar">
                    {index === 0 && !isColumnHeader && (
                      <button
                        onClick={convertToHeader}
                        className="toolbar-btn column-convert-header-btn"
                        title="Convert to header"
                      >
                        <i className="icon-indent toolbar-icon" />
                      </button>
                    )}
                    {index === 0 && isColumnHeader && (
                      <button
                        onClick={convertToColumn}
                        className="toolbar-btn column-convert-header-btn"
                        title="Convert to column"
                      >
                        <i className="icon-outdent toolbar-icon" />
                      </button>
                    )}
                    <button
                      onClick={() => {
                        removeColumn(index);
                      }}
                      className="toolbar-btn column-remove-btn"
                      title="Remove column"
                    >
                      <i className="icon-delete toolbar-icon" />
                    </button>
                  </div>
                </div>
              ))}
          </div>
          <div className="table-editor-body-wrapper">
            {tableMatrix &&
              tableMatrix.map((tableRow: any, rowIndex: any) => (
                <div key={rowIndex} className="table-editor-row">
                  {tableRow.map((tableCell: any, rowCellIndex: any) => (
                    <div
                      className={
                        isColumnHeader && rowCellIndex === 0
                          ? "column-header-cell-wrapper"
                          : "table-row-cell-wrapper"
                      }
                    >
                      <TextInput
                        useTextArea
                        id={`row${rowIndex.toString()}${rowCellIndex.toString()}`}
                        key={`row${rowIndex.toString()}${rowCellIndex.toString()}`}
                        value={tableCell}
                        inputChange={(event: any) =>
                          onRowCellChange(
                            event.target.value,
                            rowIndex,
                            rowCellIndex
                          )
                        }
                        // onInputClick={onInputClick}
                        // max={2}
                        min={2}
                        placeholder={t("Type text here")}
                        onKeyPress={handleEnter}
                      />
                    </div>
                  ))}
                  <div className="table-editor-row-actions">
                    <button
                      onClick={() => {
                        insertNewRow(rowIndex);
                      }}
                      className="toolbar-btn"
                      title="Insert row below"
                    >
                      <i className="icon-add toolbar-icon" />
                    </button>
                    {tableMatrix.length > 1 && (
                      <button
                        onClick={() => {
                          removeRow(rowIndex);
                        }}
                        className="toolbar-btn"
                        title="Remove row"
                      >
                        <i className="icon-delete toolbar-icon" />
                      </button>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>
        {!disableColumnAdd && (
          <div className="table-editor-add-column-btn-wrapper">
            <IconLabelButton
              showIcon
              iconClass="icon-add"
              buttonLabel={t("Add column")}
              onClick={addNewColumn}
            />
          </div>
        )}
      </div>
      <div className="quarter-clearfix" />
      <IconLabelButton
        showIcon
        iconClass="icon-add"
        buttonLabel={t("Add row")}
        onClick={addNewRow}
      />
    </div>
  );
};

export default TableEditor;
