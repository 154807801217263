import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import { Image } from "react-bootstrap";
import noodleLogo from "./../../assets/images/noodle-text.svg";
import portalImg from "./../../assets/images/portal-img.png";
import schoolLogos from "./../../assets/images/school-logos.png";
import "./styles.scss";
import ChatImages from "./ChatImages";
import CommonService from "../../services/CommonService";
import classNames from "classnames";
import history from "../../utils/history";

const commonService = new CommonService();
const windowObj: any = window

export const EmailInput = (props: any) => {
  const { t } = useTranslation("translation");
  const { value, onChange, form, gotoInfo, isMobile } = props;
  const [emailInvalid, setEmailInValid] = useState(false);
  const query = new URLSearchParams(history.location.search);
  const redUrl = query.get("next_url")
  ? query.get("next_url")
  : windowObj.NEW_PORTAL_URI;
  useEffect(() => {
    return () => {
      setEmailInValid(false);
    }
  }, [])

  const checkIfEMailHasAlreadyRegisteredAction = async () => {
    try {
      await commonService.checkIfEMailHasAlreadyRegistered(value);
      setEmailInValid(false);
      gotoInfo();
    } catch (error) {
      setEmailInValid(true);
    }
  };
  return (
    <div
      className={cx({
        "d-flex": true,
        "flex-column": isMobile,
      })}
    >
      <div className="green-bg d-flex flex-1 flex-column align-items-center justify-content-center max-col">
        <Image className="top-logo" src={noodleLogo} />
        <div
          className={cx({
            "content-wrapper w-100": true,
            mobile: isMobile,
          })}
        >
          <h1 className="pink-color-text">
            {t("Ready to be supercharged?")}
          </h1>
          <div className="email-wrapper">
            <div className="p-2">{t("Email")}</div>
            <input
              type="text"
              value={value}
              className={classNames({
                "form-control h-auto w-100 pt-2": true,
                "border-danger": form.errors.email || emailInvalid,
              })}
              onChange={(e: any) => {
                onChange(e?.target?.value);
              }}
            />
            {form.errors.email && (
              <div className="pt-2 clr-red">{t(form.errors.email)}</div>
            )}
            {emailInvalid && (
              <div className="pt-2 clr-red">
                {t("This email has already been registered")}
              </div>
            )}
          </div>
          <div className="btn-wrapper">
            <button
              className="btn btn-primary w-100 font-600 py-1 py-lg-2"
              disabled={form.errors.email || !value}
              onClick={checkIfEMailHasAlreadyRegisteredAction}
            >
              {t("Create An Account")}
            </button>
          </div>
          {
            isMobile && (
              <div className="have-an-account d-flex align-items-center">
                {t("Already have an account?")}
                <button
                  className="btn clr-blue font-weight-bold p-0 font-sm ml-1"
                  onClick={() => {
                    window.location.href = redUrl;
                  }}
                >
                  {t("Click here to login")}
                </button>
              </div>
            )
          }
        </div>
      </div>
      <div className="d-flex flex-column align-items-center justify-content-center max-col pt-4 pt-md-0 px-3 px-md-0">
        <h2 className="text-center mb-3 ai-head-2 font-800">
          {t("Elevate Your Teaching with")}
          <span className='clr-pink'> {t("AI-Powered Efficiency")}</span>
        </h2>
        <Image src={portalImg} className="mw-70"/>
        <h3 className="text-center py-4 font-1 font-weight-bold mb-0 mt-3">
          {t("Trusted by over 30,000 educators worldwide")}
        </h3>
        <Image src={schoolLogos} className="mw-70"/>
      </div>
      {isMobile && <ChatImages />}
    </div>
  );
};

export default EmailInput;
