import {Button, Form, Modal, ModalFooter} from "react-bootstrap";
import ModalBody from "react-bootstrap/ModalBody";
import { useTranslation } from "react-i18next";
import { FormData } from "../IntegrationType";
import IntegrationModalHeader from "../Modal/IntegrationModalHeader";
import TextInput from "../../../components/UI/OldFormBuilder/TextInput";
import Dropdown from "../../../components/UI/OldFormBuilder/Dropdown";

type ApiSetup = {
  integrationType: string;
  closeModal: () => void;
  renderCopy: (text: string) => JSX.Element;
  data: ApiSetupData;
  handleIntegration: () => Promise<void>;
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
  handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    name: string,
  ) => void;
};

type ApiSetupData = {
  redirect_uri: string;
  target_link_uri: string;
  openid_connect_initiation_url: string;
};

const Desire2learnApiModal = (props: ApiSetup) => {
  const { t } = useTranslation("translation");

  return (
    <Modal
      className="modal-width"
      aria-labelledby="contained-modal-title-vcenter"
      show={true}
      onHide={() => {}}
      centered
    >
      <IntegrationModalHeader
        modalTitle="Enable Content Integration with D2L Brightspace"
        closeModal={props?.closeModal}
      />

      <ModalBody className="integration-modal-body">
        <div className="tw-flex  tw-gap-4 canvas-manual-integration">
          <div className="tw-px-4 tw-py-4 w-90 left-content-wrap">
            <h2 className="mb-2">
              {t("Configure OAuth 2.0 integration in")} D2L Brightspace
            </h2>
            <ol>
              <li>{t("Log in to Brightspace as an admin")}.</li>
              <li>
                {t("Click on gear icon on top right corner, under")}&nbsp;
                <strong>Organization Related</strong>, {t("select")}&nbsp;
                <strong>Manage Extensibility</strong>
              </li>
              <li>
                {"In"} <strong>Manage Extensibility</strong>{" "}
                {t("page, click on tab")} <strong>OAuth 2.0</strong>.
              </li>
              <li>
                {t("Click on button")} <strong>Register an app</strong>,&nbsp;
                {t("then fill in the form as following")}:
                <ul>
                  <li>
                    <span className="label">Application Name: </span>&nbsp;
                    {props.renderCopy("Noodle Factory Content Integration")}
                  </li>
                  <li>
                    <span className="label">Redirect URI: </span>&nbsp;
                    <ul>
                      <li>
                        {t("For Asia Pacific and Europe")}:{" "}
                        {props.renderCopy(
                          "https://portalapi.noodlefactory.ai/api/v1/integration/desire2learn-integrations/callback",
                        )}
                      </li>
                      <li>
                        {t("For	North America")}:{" "}
                        {props.renderCopy(
                          "https://portalapius.noodlefactory.ai/api/v1/integration/desire2learn-integrations/callback",
                        )}
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span className="label">Scope: </span>&nbsp;
                    {props.renderCopy(
                      "core:*:* managefiles:*:read organizations:organization:read orgunits:course:read",
                    )}
                  </li>
                  <li>
                    <span className="label">Access Token Lifetime: </span>&nbsp;
                    {props.renderCopy("7200")}
                  </li>
                  <li>
                    <span className="label">Description: </span>&nbsp;
                    {props.renderCopy("Noodle Factory Content Integration")}
                  </li>
                  <li>
                    <span className="label">Prompt for user content: </span>
                    &nbsp;
                    {t("Enable this check")}
                  </li>
                  <li>
                    <span className="label">Enable refresh tokens: </span>&nbsp;
                    {t("Enable this check")}
                  </li>
                  <li>
                    <span className="label">{"Check"}: </span>{" "}
                    <em>I accept the Non-Commercial Developer Agreement*</em>{" "}
                    {"and click on button"} <em>Register</em>
                  </li>
                </ul>
              </li>
              <li>
                {t("Fill in the form on the right:")}
                <ul>
                  <li>
                    <em>Brightspace LMS URL</em>:{" "}
                    {t("This is the URL of your D2L Brightspace instance")}
                  </li>
                  <li>
                    <em>Client ID</em>:{" "}
                    {t(
                      "Get the value of Client ID in the details of the app you just registered"
                    )}
                  </li>
                  <li>
                    <em>Client Secret</em>:{" "}
                    {t(
                      "Get the value of Client Secret in the details of the app you just registered"
                    )}
                  </li>
                </ul>
              </li>
            </ol>
          </div>

          <div className="whit-box tw-px-4 right-content-wrap w-10">
            <div>
              <h2 className="mb-2">
                {t("Required fields for Noodle Factory")}
              </h2>
              <p className="mb-0">
                {t(
                  "As you complete each step, please fill up following values to set up the integration on Noodle Factory",
                )}
              </p>
              <form>
                <div className="flex-one mt-2">
                  <h3 className="mb-2">Brightspace LMS URL</h3>
                  <TextInput
                    inputType="text"
                    placeholder={t("Canvas LMS URL")}
                    autoFocus={false}
                    id="client-id-1"
                    value={props.formData?.LMS_URL}
                    inputChange={(e, name) => props.handleInputChange(e, name)}
                    max={undefined}
                    min={undefined}
                    tabIndex={undefined}
                    label=""
                    description=""
                    inputClass=""
                    name="LMS_URL"
                    disabled={false}
                    onKeyPress={() => {}}
                    useTextArea={false}
                    errorMessage=""
                    ref={undefined}
                    onPaste={undefined}
                    onFocus={undefined}
                    onBlur={undefined}
                    onKeyUp={undefined}
                    spellCheck={undefined}
                    required={undefined}
                    showError={undefined}
                  />
                </div>
                <div className="flex-one mt-2">
                  <h3 className="mb-2">{t("Client ID")}</h3>
                  <TextInput
                    inputType="text"
                    placeholder="Client ID"
                    autoFocus={false}
                    id="client-id-2"
                    value={props.formData?.Client_ID}
                    inputChange={(e, name) => props.handleInputChange(e, name)}
                    max={undefined}
                    min={undefined}
                    tabIndex={undefined}
                    label=""
                    description=""
                    inputClass=""
                    name="Client_ID"
                    disabled={false}
                    onKeyPress={() => {}}
                    useTextArea={false}
                    errorMessage=""
                    ref={undefined}
                    onPaste={undefined}
                    onFocus={undefined}
                    onBlur={undefined}
                    onKeyUp={undefined}
                    spellCheck={undefined}
                    required={undefined}
                    showError={undefined}
                  />
                </div>
                <div className="flex-one mt-2">
                  <h3 className="mb-2">Client Secret</h3>
                  <TextInput
                    inputType="text"
                    placeholder="Client Secret"
                    autoFocus={false}
                    id="client-id-3"
                    value={
                      props.integrationType === "walterEducator"
                        ? props.formData?.Client_Secret
                        : props.formData?.Deployment_ID
                    }
                    inputChange={(e, name) => props.handleInputChange(e, name)}
                    max={undefined}
                    min={undefined}
                    tabIndex={undefined}
                    label=""
                    description=""
                    inputClass=""
                    name="Client_Secret"
                    disabled={false}
                    onKeyPress={() => {}}
                    useTextArea={false}
                    errorMessage=""
                    ref={undefined}
                    onPaste={undefined}
                    onFocus={undefined}
                    onBlur={undefined}
                    onKeyUp={undefined}
                    spellCheck={undefined}
                    required={undefined}
                    showError={undefined}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </ModalBody>
      {/* {popUpState === "" && ( */}
      <ModalFooter>
        <Button variant="outline-primary" onClick={props.closeModal}>
          {t("Cancel")}
        </Button>
        <Button
          variant="primary"
          disabled={
            !props.formData.LMS_URL ||
            !props.formData.Client_ID ||
            !props.formData.Client_Secret
          }
          onClick={() => {
            props.handleIntegration();
            props.closeModal();
          }}
        >
          {t("Complete")}
        </Button>
      </ModalFooter>
      {/* )} */}
    </Modal>
  );
};
export default Desire2learnApiModal;
