import React from 'react';

export default function EssayCriterionTable() {
  return (
    <table className="quiz-table">
      <thead className="quiz-table-horizontal-header">
        <tr>
          <th className="quiz-table-header-cell">
            <div className="textinput-container">
              <div className="custom-input">Criterion</div>
            </div>
          </th>
          <th className="quiz-table-header-cell">
            <div className="textinput-container">
              <div className="custom-input">Poor (1)</div>
            </div>
          </th>
          <th className="quiz-table-header-cell">
            <div className="textinput-container">
              <div className="custom-input">Average (2)</div>
            </div>
          </th>
          <th className="quiz-table-header-cell">
            <div className="textinput-container">
              <div className="custom-input">Good (3)</div>
            </div>
          </th>
          <th className="quiz-table-header-cell">
            <div className="textinput-container">
              <div className="custom-input">Excellent (4)</div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr className="quiz-table-row">
          <th className="quiz-table-header-cell">
            <div className="textinput-container">
              <div className="custom-input">Structure and Organization</div>
            </div>
          </th>
          <td className="quiz-table-row-cell">
            <div className="textinput-container">
              <div className="custom-input">There is no clear introduction, structure, or conclusion.</div>
            </div>
          </td>
          <td className="quiz-table-row-cell">
            <div className="textinput-container">
              <div className="custom-input">The introduction states the main topic. A conclusion is included.</div>
            </div>
          </td>
          <td className="quiz-table-row-cell">
            <div className="textinput-container">
              <div className="custom-input">The introduction states the main topic and provides an overview of the paper. A conclusion is included.</div>
            </div>
          </td>
          <td className="quiz-table-row-cell">
            <div className="textinput-container">
              <div className="custom-input">The introduction is inviting, states the main topic, and provides an overview of the paper. Information is relevant and presented in a logical order. The conclusion is strong.</div>
            </div>
          </td>
        </tr>
        <tr className="quiz-table-row">
          <th className="quiz-table-header-cell">
            <div className="textinput-container">
              <div className="custom-input">Sources and Evidence</div>
            </div>
          </th>
          <td className="quiz-table-row-cell">
            <div className="textinput-container">
              <div className="custom-input">The author did not use concepts and theories from class to develop ideas that are appropriate for the argument presented in the response.</div>
            </div>
          </td>
          <td className="quiz-table-row-cell">
            <div className="textinput-container">
              <div className="custom-input">The author follows expectations regarding the uses of concepts and theories from class to develop ideas that are appropriate for the argument presented in the response.</div>
            </div>
          </td>
          <td className="quiz-table-row-cell">
            <div className="textinput-container">
              <div className="custom-input">The author demonstrates consistent use of concepts and theories from class to develop ideas that are appropriate for the argument presented in the response.</div>
            </div>
          </td>
          <td className="quiz-table-row-cell">
            <div className="textinput-container">
              <div className="custom-input">The author demonstrates skillful use of and insight into concepts and theories from class to develop ideas that are relevant to the argument presented in the response. All sources are properly cited in-text and referenced at the end of the document.</div>
            </div>
          </td>
        </tr>
        <tr className="quiz-table-row">
          <th className="quiz-table-header-cell">
            <div className="textinput-container">
              <div className="custom-input">Grammar, Syntax and Mechanics</div>
            </div>
          </th>
          <td className="quiz-table-row-cell">
            <div className="textinput-container">
              <div className="custom-input">The author makes numerous errors in grammar, mechanics, and/or spelling that interfere with understanding.</div>
            </div>
          </td>
          <td className="quiz-table-row-cell">
            <div className="textinput-container">
              <div className="custom-input">The author makes several errors in grammar, mechanics, and/or spelling that interfere with understanding.</div>
            </div>
          </td>
          <td className="quiz-table-row-cell">
            <div className="textinput-container">
              <div className="custom-input">The author makes a few errors in grammar, mechanics, and/or spelling, but they do not interfere with understanding.</div>
            </div>
          </td>
          <td className="quiz-table-row-cell">
            <div className="textinput-container">
              <div className="custom-input">The author makes no errors in grammar, mechanics, and/or spelling.</div>
            </div>
          </td>
        </tr>
        <tr className="quiz-table-row">
          <th className="quiz-table-header-cell">
            <div className="textinput-container">
              <div className="custom-input">Application</div>
            </div>
          </th>
          <td className="quiz-table-row-cell">
            <div className="textinput-container">
              <div className="custom-input">The author's strategy and choice of action is not expressed at all in the response.</div>
            </div>
          </td>
          <td className="quiz-table-row-cell">
            <div className="textinput-container">
              <div className="custom-input">The author's strategy and choice of action is expressed in a somewhat convincing manner through the use of adequate supporting reasons.</div>
            </div>
          </td>
          <td className="quiz-table-row-cell">
            <div className="textinput-container">
              <div className="custom-input">The author's strategy and choice of action is expressed in a convincing manner through the use of adequate supporting reasons.</div>
            </div>
          </td>
          <td className="quiz-table-row-cell">
            <div className="textinput-container">
              <div className="custom-input">The author's strategy and choice of action is expressed in an articulate and convincing manner through the use of adequate supporting reasons.</div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
