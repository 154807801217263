/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Tab,
  Tabs,
  Image,
  Card,
  Dropdown,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import downarrowblk from "../../assets/images/chevron-down-blk.svg";
import uparrowblk from "../../assets/images/chevron-up-blk.svg";
import ConfirmationModal from "../../components/UI/ConfirmationModal/ConfirmationModal";
import { DashboardFilters } from "../../components/UI/Dashboard";
import Pagination from "../../components/UI/Pagination/Pagination";
import PermissionWrapper from "../../components/UI/PermissionWrapper/PermissionWrapper";
import QuestionListSkeleton from "../../components/UI/Skeletons/QuestionListSkeleton/QuestionListSkeletons";
import { getReportsUnmatchedQuestions } from "../../store/knowledge/actions";
import { getAllOrganisationUsers } from "../../store/agents/actions";

import {
  BulkDeleteUnansweredQuestions,
  getSuggestedKnowledgeFeedback,
} from "../../store/questions/actions";
import { ChartTableSortIcon } from "../Knowledge/AllKnowledge";
import AddAnswerModal from "./AddAnswer";
import {
  columns,
  conditionalRowStyles,
  customStyles,
  ExpandableComponent,
} from "./ManageUnansweredUtils";
import Select from "react-select";
import sortIcon from "../../assets/New-images/sort-blue-icon.svg";
import backblue from "../../assets/images/arrow-left.svg";
import downarrowblue from "../../assets/New-images/dropdown-arow-blue.svg";
import Moment from "react-moment";
import classNames from "classnames";
import { getClasses } from "../../store/dashboard/actions";
import * as _ from "lodash";
import { capitalizeFirstLetter } from "../../utils/appUtils";

import { useTranslation } from "react-i18next";
import ManageAnswersByOpenAI from "./ManageAnswersByOpenAI";
import KnowledgeCreateFromScratch from "../Knowledge/KnowledgeCreateFromScratch/KnowledgeCreateFromScratch";
import AddAnswerFromKnowledgeBase from "./AddAnswerFromKnowledgeBase";
import UsersAskedModal from "./popups/UsersAskedModal";

const ManageUnansweredKnowledge = (props: any) => {
  const { t } = useTranslation("translation");
  const history = useHistory();
  const dispatch = useDispatch();
  const [perPage, setPerPage] = useState(30);
  const [matchedRecords, setMatchedRecords] = useState([]);
  const [classes, setClasses] = useState([]);
  const [matchedRecordsPagination, setMatchedRecordsPagination] =
    useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [showAddAnswerModal, setShowAddAnswerModal] = useState(false);
  const [filters, setFilters] = useState({
    connection_ids: null,
    page: null,
    status: null,
    page_size: null,
    sort_field: null,
    sort_order: null,
    from_date: null,
    to_date: null,
  });
  const [totalRows, setTotalRows] = useState(0);
  const [questionAnswerData, setQuestionAnswerData] = useState(null);
  const [activeKey, setActiveKey] = useState(null);
  const [userList, setUserList] = useState([]);
  const [showSideWrap, setshowSideWrap] = useState();
  const [modalData, setModalData] = useState({
    users_asked: null,
    date: null,
  });
  const [sessionUsersShow, setSessionUsersShow] = useState(false);

  useEffect(() => {
    if (!filters.from_date || !filters.to_date) {
      return;
    }
    getMatchedFunctions();
  }, [filters]);

  useEffect(() => {
    getAllUsers();
    getClassesFun();
  }, []);

  const getAllUsers = async () => {
    try {
      const response: any = await dispatch(getAllOrganisationUsers());
      const data: any = [];
      response.users.forEach((element: any) => {
        data.push({
          _id: element._id,
          email: element.email,
          name: element.first_name + " " + element.last_name,
        });
      });
      setUserList(data);
    } catch (error) {
      setUserList([]);
    }
  };

  const getClassesFun = async () => {
    try {
      const { classes }: any = await dispatch(getClasses());
      setClasses(classes);
    } catch (error: any) {
      setClasses([]);
    }
  };

  const getMatchedFunctions = async () => {
    try {
      setLoading(true);
      const matchedQuestions: any = await dispatch(
        getReportsUnmatchedQuestions({
          ...filters,
          page: currentPage,
          page_size: perPage,
        })
      );
      let allRows = matchedQuestions.rows.map((row: any) => {
        let askedByUsers = [];
        if (row.asked_by_users && row.asked_by_users.length > 0) {
          askedByUsers = row.asked_by_users.map((user: any) => {
            return {
              ...user,
              user_email: userList.find((u: any) => u._id === user.user_id)
                ?.email,
            };
          });
        }
        return {
          ...row,
          defaultExpanded: false,
          text: capitalizeFirstLetter(row.text),
          asked_by_users: askedByUsers,
        };
      });
      setMatchedRecords(allRows);
      setMatchedRecordsPagination(matchedQuestions.pagination);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };

  const ViewSuggestedAnswer = (id: string) => {
    const oldRecords = [
      ...matchedRecords.map((record) => {
        return {
          ...record,
          // defaultExpanded: false
        };
      }),
    ];
    const recordIndex = oldRecords.findIndex(
      (intent: any) => intent._id === id
    );
    if (recordIndex >= 0) {
      oldRecords[recordIndex].defaultExpanded =
        !oldRecords[recordIndex].defaultExpanded;
    }
    setMatchedRecords([...oldRecords]);
  };

  /**
   *
   * @param page Pagination on change of page
   */
  const handlePageChange = async (page: number) => {
    await setCurrentPage(page);
    await setFilters({
      ...filters,
      page: page,
    });
  };

  /**
   * Used to paginate data based on per page and page number
   * @param newPerPage
   * @param page
   */
  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    await setPerPage(newPerPage);
    await setCurrentPage(page);
    await setFilters({
      ...filters,
      page: page,
      page_size: newPerPage,
    });
  };

  /**
   * Used to sort data using API
   * @param param0
   * @param direction
   */
  const onSortHandler = async ({ selector }: any, direction: string) => {
    await setFilters({
      ...filters,
      sort_field: selector === "_id" ? "question" : selector,
      sort_order: direction === "desc" ? "dsc" : "asc",
    });
  };

  const onSortHandlerNew = async (selector: any, direction: string) => {
    await setFilters({
      ...filters,
      sort_field: selector === "_id" ? "question" : selector,
      sort_order: direction === "desc" ? "dsc" : "asc",
    });
  };

  /**
   * Custom sort function
   * @param rows
   * @param selector
   * @param direction
   * @returns
   */
  const customSort = (rows: any[], selector: string, direction: string) => {
    return rows;
  };

  const confirmAddToExistingIntent = (e: any) => {};

  const hasFilterUpdated = async (e: any) => {
    setPerPage(30);
    setCurrentPage(1);
    await setFilters({
      ...filters,
      from_date: e.filter_from_date,
      to_date: e.filter_to_date,
      connection_ids: e.connection_ids,
      status: e.status,
      page: 1,
      page_size: 30,
    });
  };

  const addAnswerModalHandler = (e: any) => {
    if (e && e.question) {
      setQuestionAnswerData(e);
    }
    setShowAddAnswerModal(!showAddAnswerModal);
  };

  const confirmAnswerModalHandler = (e: any) => {
    setShowAddAnswerModal(!showAddAnswerModal);
    getMatchedFunctions();
  };

  const [modalShow, setModalShow] = useState(false);
  const [deletedRowId, setDeletedRowId] = useState(null);
  const suggestedKnowledgeFeedback = async (e: any) => {
    try {
      setDeletedRowId(e);
      modalhandler(true);
    } catch (error: any) {}
  };

  const modalhandler = (value: boolean) => {
    setModalShow(value);
  };

  const deleteConfirmed = async (
    e: React.ChangeEvent<HTMLInputElement>,
    selectedRow: any
  ) => {
    e.preventDefault();
    setModalShow(false);
    await dispatch(getSuggestedKnowledgeFeedback(selectedRow));
    getMatchedFunctions();
  };

  /**
   * Set multiple rows in state on change of checkbox
   * @param param0
   */
  const [selectedRows, setSelectedRows] = useState([]);
  const onSelectedRowsHandler = ({
    selectedRows,
  }: {
    allSelected: boolean;
    selectedCount: number;
    selectedRows: any[];
  }) => {
    const ids = selectedRows.map((r) => r._id);
    setSelectedRows(ids);
  };

  // Show delete modal
  const [singleDeleteModalShow, setSingleDeleteModalShow] = useState(false);
  const SingleDeleteModalhandler = (value: boolean) => {
    setSingleDeleteModalShow(value);
  };
  const [singleDeletedRowId, setSingleDeletedRowId] = useState([]);
  const deleteSingleKnowledgeHandler = (id: string) => {
    setSingleDeletedRowId([id]);
    setSingleDeleteModalShow(true);
  };
  const confirmSingleDeleteKnowledgeHandler = async (e: any, data: any) => {
    setSingleDeleteModalShow(false);
    try {
      await setLoading(true);
      await dispatch(BulkDeleteUnansweredQuestions(data));
      getMatchedFunctions();
      await setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };

  // Show delete modal
  const [bulkDeleteModalShow, setBulkDeleteModalShow] = useState(false);
  const BulkDeleteModalhandler = (value: boolean) => {
    setBulkDeleteModalShow(value);
  };
  const [bulkDeletedRowId, setBulkDeletedRowId] = useState([]);
  const deleteKnowledgeHandler = (id: string[]) => {
    setBulkDeletedRowId(id);
    setBulkDeleteModalShow(true);
  };
  const confirmDeleteKnowledgeHandler = async (e: any, data: any) => {
    setBulkDeleteModalShow(false);
    try {
      await setLoading(true);
      await dispatch(BulkDeleteUnansweredQuestions(data));
      setSelectedRows([]);
      getMatchedFunctions();
      await setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };

  const getClassesNames = (connection_ids: string[]) => {
    let className: any = [];
    connection_ids.forEach((connection) => {
      const clasPresent = classes.find(
        (u: any) => u.connectionIds.split(",").indexOf(connection) >= 0
      );
      if (clasPresent) {
        className.push(clasPresent.name);
      }
    });
    return className.join(", ");
  };

  const addToSelectedRows = (event: any, id: string) => {
    let data: any[] = [...selectedRows];
    if (event.target.checked) {
      const isPresent = data.indexOf(id);
      if (isPresent < 0) {
        data.push(id);
      }
    } else {
      const oldData = [...selectedRows];
      const index = oldData.indexOf(id);
      oldData.splice(index, 1);
      data = [...oldData];
    }
    setSelectedRows([..._.uniq(data)]);
  };

  const onSelectAllRows = (event: any) => {
    if (event.target.checked) {
      if (matchedRecords && matchedRecords.length > 0) {
        let data: any[] = [...matchedRecords.map((intent: any) => intent._id)];
        setSelectedRows([..._.uniq(data)]);
      }
    } else {
      setSelectedRows([]);
    }
  };

  const resetEditKnowledgehandler = () => {
    setShowAddAnswerModal(!showAddAnswerModal);
  };

  const renderHtml = (
    <>
      <div className="all-knowledge-search d-flex flex-wrap align-items-center">
        {/* <button className="btn btn-outline-primary font-600 py-1 my-1 mr-3">
          <img src={sortIcon} alt="" className="mr-2" />
          Sort
        </button> */}

        <Dropdown className="sort-dropdown">
          <Dropdown.Toggle
            className="btn btn-outline-primary font-600 py-1 my-1 mr-3"
            variant="success"
            id="dropdown-basic"
          >
            <img src={sortIcon} alt="" className="mr-2" />
            {t("Sort")}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {/* <Dropdown.Item
              onClick={() => {
                onSortHandlerNew("created_at", "desc");
              }}
            >
              Most Recent
            </Dropdown.Item> */}
            <Dropdown.Item
              onClick={() => {
                onSortHandlerNew(
                  activeKey === "answered" ? "updated_at" : "created_at",
                  "asc"
                ); //
              }}
            >
              {t("Ascending")}
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                onSortHandlerNew(
                  activeKey === "answered" ? "updated_at" : "created_at",
                  "desc"
                ); //
              }}
            >
              {t("Descending")}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        {/* <div className="web-links my-1">
          <input
            type="text"
            className="form-control h-auto"
            placeholder="Search for Questions"
          />
        </div> */}
        <div className="ml-auto d-flex flex-wrap doc-select-dropdown">
          <DashboardFilters
            hasFilterUpdated={hasFilterUpdated}
            hideChannels
            showAnsweredStatus={true}
          />
        </div>
      </div>
      <div className="d-flex flex-wrap justify-content-between align-items-center mt-1">
        <div className="custom-checkbox mt-3">
          <input
            type="checkbox"
            className="mr-2"
            id="select-all"
            checked={
              selectedRows.length === matchedRecords.length &&
              matchedRecords.length > 0
            }
            onChange={onSelectAllRows}
          />
          <label htmlFor="select-all" className="mb-0">
            {t("Select all")}
          </label>
        </div>
        {selectedRows && selectedRows.length > 0 && (
          <PermissionWrapper>
            <div className="">
              <button
                className="btn btn-outline-danger border-0 py-0 font-600 font-sm"
                onClick={() => deleteKnowledgeHandler(selectedRows)}
              >
                {t("Delete Selected")}
              </button>
            </div>
          </PermissionWrapper>
        )}
      </div>
      <div className="accordion-viewby mt-3 scroll-wrap unanswered-wraper">
        {matchedRecords.map((row: any) => {
          const records = matchedRecords.find(
            (intent: any) => intent._id === row._id
          );

          return (
            <Card key={row._id}>
              <Card.Header>
                <input
                  type="checkbox"
                  className="mr-2"
                  onChange={(e) => {
                    addToSelectedRows(e, row._id);
                  }}
                  checked={selectedRows.indexOf(row._id) >= 0}
                />
                <Row className="align-items-center">
                  <Col md={3} className="my-2 my-md-0">
                    <div className="font-sm clr-grey-txt">
                      {getClassesNames(row.connection_ids)}
                    </div>
                    <div>{row.text ? row.text : ""}</div>
                  </Col>

                  {row.status === "answered" && (
                    <Col
                      md={2}
                      className="my-1 my-md-0"
                      style={{ paddingLeft: "0px", paddingRight: "0px" }}
                    >
                      <>
                        <div className="font-sm">{t("Acknowledgement")}</div>
                        <div className="font-weight-normal font-sm">
                          <Moment utc local format="DD/MM/YYYY">
                            {row.updated_at}
                          </Moment>
                        </div>
                      </>
                    </Col>
                  )}
                  {row.status === "unanswered" && (
                    <Col
                      md={2}
                      className="my-1 my-md-0"
                      style={{ paddingLeft: "0px", paddingRight: "0px" }}
                    >
                      <div className="font-sm">{t("Asked by")}</div>
                      <div className="font-weight-normal font-sm">
                        <div>
                          {row.asked_by_users.length === 1 ? (
                            row.asked_by_users[0].user_email
                          ) : row.asked_by_users.length > 1 ? (
                            <button
                              onClick={(e) => {
                                setSessionUsersShow(true);
                                setModalData({
                                  users_asked: row.asked_by_users,
                                  date: row.updated_at,
                                });
                              }}
                              className="btn btn-link py-0 font-weight-normal"
                            >
                              {`Multiple Users (${row.asked_by_users.length})`}
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </Col>
                  )}

                  <Col md={2} className="my-1 my-md-0">
                    <div className="font-sm text-center">{t("Last Asked")}</div>
                    <div className="font-weight-normal font-sm text-center">
                      <Moment utc local format="DD/MM/YYYY">
                        {row.created_at}
                      </Moment>
                    </div>
                  </Col>

                  <Col md={5} className="my-2 my-md-0">
                    <div className="text-right d-flex justify-content-end align-items-center text-nowrap">
                      {row.status === "unanswered" && (
                        <PermissionWrapper>
                          <button
                            onClick={(e) => {
                              addAnswerModalHandler({
                                question: row.text,
                                answer: null,
                                intent_id: row._id,
                              });
                            }}
                            className="btn btn-link py-0 font-weight-normal"
                          >
                            {t("Add Answer")}
                          </button>
                        </PermissionWrapper>
                      )}
                      <PermissionWrapper>
                        <button
                          onClick={(e) => {
                            ViewSuggestedAnswer(row._id);
                          }}
                          className={classNames({
                            "btn btn-link py-0 font-weight-normal": true,
                            "clr-grey-txt":
                              row.status === "unanswered" &&
                              row.suggested_answers.length === 0,
                          })}
                        >
                          {row.defaultExpanded ? "Hide" : "View"} (
                          {row.suggested_answers.length}){" "}
                          {t("Suggested Answers")}
                        </button>
                      </PermissionWrapper>

                      <button
                        className="btn btn-outline-danger border-0 py-0 font-weight-normal"
                        onClick={() => deleteSingleKnowledgeHandler(row._id)}
                      >
                        {t("Delete")}
                      </button>
                    </div>
                  </Col>
                </Row>
              </Card.Header>

              {row &&
                row.suggested_answers.length > 0 &&
                row.defaultExpanded && (
                  <Card.Body>
                    <Row className="mb-2 d-none d-sm-flex">
                      <Col sm={6} className="my-2 my-sm-0">
                        <div className="font-sm font-600">
                          {t("Suggested Answer")}
                        </div>
                      </Col>
                      <Col sm={6} className="my-2 my-sm-0">
                        <div className="font-sm font-600">
                          {
                            // row.status === "answered" ? 'Suggested by' : 'Created by'
                            "Suggested by"
                          }
                        </div>
                      </Col>
                    </Row>
                    {row &&
                      row.suggested_answers.map((record: any) => {
                        return (
                          <Row className="align-items-center py-1">
                            <Col sm={6} className="my-2 my-sm-0">
                              <div className="font-weight-normal font-sm">
                                {record?.comment}
                              </div>
                            </Col>
                            <Col sm={3} className="my-2 my-sm-0">
                              <div className="font-weight-normal font-sm">
                                {record.author_email}

                                {/* <Moment format="DD-MM-YYYY">{record.created_at}</Moment> */}
                              </div>
                            </Col>
                            <Col sm={3} className="my-2 my-sm-0">
                              <PermissionWrapper>
                                <button
                                  onClick={(e) => {
                                    addAnswerModalHandler({
                                      selectedAnswer: records,
                                      question: records.text,
                                      answer: record.comment,
                                      intent_id:
                                        record.conversation_feedback_id,
                                      author_id: record.author_id,
                                    });
                                  }}
                                  className="btn btn-link py-0 font-weight-normal"
                                >
                                  {t("Add as Answer")}
                                </button>
                                <button
                                  onClick={(e) => {
                                    suggestedKnowledgeFeedback({
                                      selectedAnswer: records,
                                      question: records.text,
                                      answer: record.comment,
                                      intent_id: record.author_id,
                                    });
                                  }}
                                  className="btn btn-outline-danger border-0 py-0 font-weight-normal bg-none"
                                >
                                  {t("Reject")}
                                </button>
                              </PermissionWrapper>
                            </Col>
                          </Row>
                        );
                      })}
                  </Card.Body>
                )}
            </Card>
          );
        })}
      </div>
      {showAddAnswerModal && (
        <div className="edit-knowledge-sidewrap edit-knowledge-sidewrap-openAI unanswered-edit-Knowledge">
          <AddAnswerFromKnowledgeBase
            resetEditKnowledgehandler={confirmAnswerModalHandler}
            questionAnswerData={questionAnswerData}
            addAnswerFromPopup
            editMode={false}
            viewall
          />
        </div>
      )}
      <UsersAskedModal
        modalData={modalData}
        show={sessionUsersShow}
        onHide={() => setSessionUsersShow(false)}
        users={userList}
        classes={classes}
        // attempted={modalData.filter_is_attempted}
      />
    </>
  );

  return (
    <section className="page-mid-wraper h-without-foter position-relative">
      {/* <AddAnswerModal
        show={showAddAnswerModal}
        onHide={addAnswerModalHandler}
        questionAnswerData={questionAnswerData}
        confirmAnswerModalHandler={confirmAnswerModalHandler}
      /> */}

      <ConfirmationModal
        message={t("Are you sure you want to delete these questions?")}
        size={"md"}
        show={bulkDeleteModalShow}
        selectedRow={bulkDeletedRowId}
        onHide={() => BulkDeleteModalhandler(false)}
        confirmHandler={confirmDeleteKnowledgeHandler}
      />

      <ConfirmationModal
        message={t("Are you sure you want to delete this question?")}
        size={"md"}
        show={singleDeleteModalShow}
        selectedRow={singleDeletedRowId}
        onHide={() => SingleDeleteModalhandler(false)}
        confirmHandler={confirmSingleDeleteKnowledgeHandler}
      />

      <ConfirmationModal
        message={t("Are you sure you want to reject this suggested answer?")}
        size={"md"}
        show={modalShow}
        selectedRow={deletedRowId}
        onHide={() => modalhandler(false)}
        confirmHandler={deleteConfirmed}
        buttonText={t("Yes, Reject")}
      />

      <Container>
        <div className="row">
          <div className="col-md-12">
            <button
              className="btn p-0 btn-back"
              onClick={() => {
                history.goBack();
              }}
            >
              <Image src={backblue} /> {t("Return to previous page")}
            </button>
            <h1 className="mb-2">{t("Manage Unanswered Knowledge")}</h1>
            <h2 className="mb-32 font-weight-normal">
              {t(
                "Questions asked by students in which the AI is unable to answer them. Questions answered would be saved into knowledge"
              )}
            </h2>
            <div className="knowledge-viewby">
              <h2 className="mb-0 mr-3 font-1 d-md-inline-block">
                {t("View:")}{" "}
              </h2>
              <Tabs
                defaultActiveKey="all"
                onSelect={(k) => {
                  setActiveKey(k);
                  setSelectedRows([]);
                }}
              >
                {/* <Tab eventKey="all" title={t("All")}>
                    {renderHtml}
                  </Tab> */}
                <Tab eventKey="all" title={t("Unanswered")}>
                  {renderHtml}
                  {matchedRecords &&
                    matchedRecords.length > 0 &&
                    matchedRecordsPagination && (
                      <Pagination
                        // showScrollButtons={true}
                        hideSuggestedQuestion={true}
                        //   scrollRightValue={scrollRight}
                        //   scrollWrapper={'table-wraper'}
                        currentPage={currentPage}
                        pagination={matchedRecordsPagination}
                        paginationPerPage={perPage}
                        pageChange={handlePerRowsChange}
                        onChangePage={handlePageChange}
                      />
                    )}
                </Tab>
                {/* <Tab
                  eventKey={"answered"}
                  title={t(
                    "Answers generated from sources outside of knowledge base"
                  )}
                >
                  <ManageAnswersByOpenAI />
                </Tab> */}

                {/* <Tab eventKey="answered" title={t("Acknowledged")}>
                    {renderHtml}
                  </Tab> */}
              </Tabs>
            </div>

            {selectedRows && selectedRows.length > 0 && (
              <PermissionWrapper>
                <div className="delete-rows">
                  <Button
                    variant="delete"
                    onClick={() => deleteKnowledgeHandler(selectedRows)}
                  >
                    {t("Delete Selected")}
                  </Button>
                </div>
              </PermissionWrapper>
            )}

            {/* d-none */}
            <div className="d-none">
              <DataTable
                className="table-wraper"
                noContextMenu
                noHeader
                fixedHeader
                fixedHeaderScrollHeight="700px"
                columns={columns({
                  addAnswerModalHandler,
                  ViewSuggestedAnswer,
                  unmatched: true,
                })}
                data={matchedRecords ? matchedRecords : []}
                selectableRows
                selectableRowSelected={(row) =>
                  selectedRows.indexOf(row._id) >= 0
                }
                onSelectedRowsChange={onSelectedRowsHandler}
                customStyles={customStyles}
                sortIcon={<ChartTableSortIcon />}
                paginationPerPage={perPage}
                paginationDefaultPage={1}
                paginationResetDefaultPage={false}
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                progressPending={loading} //
                progressComponent={
                  <div style={{ padding: 10, width: "100%" }}>
                    <QuestionListSkeleton count={12} />
                  </div>
                }
                defaultSortAsc={false}
                // sortFunction={customSort}
                onSort={onSortHandler}
                expandableRows
                expandableRowsHideExpander
                expandableRowExpanded={(row) => row.defaultExpanded}
                expandableRowsComponent={
                  matchedRecords && (
                    <ExpandableComponent
                      matchedRecords={matchedRecords ? matchedRecords : []}
                      addAnswerModalHandler={addAnswerModalHandler}
                      suggestedKnowledgeFeedback={suggestedKnowledgeFeedback}
                    />
                  )
                }
                expandableIcon={{
                  collapsed: <img src={downarrowblk} alt={t("collapsed")} />,
                  expanded: <img src={uparrowblk} alt={t("expanded")} />,
                }}
                conditionalRowStyles={conditionalRowStyles}
              />

              {/* -----------------  Pagination   --------------------------- */}
              {matchedRecords &&
                matchedRecords.length > 0 &&
                matchedRecordsPagination && (
                  <Pagination
                    // showScrollButtons={true}
                    hideSuggestedQuestion={true}
                    //   scrollRightValue={scrollRight}
                    //   scrollWrapper={'table-wraper'}
                    currentPage={currentPage}
                    pagination={matchedRecordsPagination}
                    paginationPerPage={perPage}
                    pageChange={handlePerRowsChange}
                    onChangePage={handlePageChange}
                  />
                )}
            </div>

            {/* <ManageSuggestedKnowledge /> */}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default ManageUnansweredKnowledge;
