import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";

export const customStyles = {
  header: {
    style: {
      minHeight: "56px",
    },
  },
  headRow: {
    style: {
      borderRadius: "10px",
      backgroundColor: "#E6EDFF",
      borderBottomWidth: "0",
    },
  },
  expanderRow: {
    style: {
      // backgroundColor: '#E6EDFF;',
    },
  },
  expanderCell: {
    style: {
      "&:hover": {
        backgroundColor: "transparent",
      },
      button: {
        backgroundColor: "transparent !important",
        img: {
          width: "10px",
        },
      },
    },
  },
  expanderButton: {
    style: {
      backgroundColor: "transparent",
      alignItems: "center",
    },
  },
  headCells: {
    style: {
      "&:first-child": {
        borderTopLeftRadius: "8px",
        borderBottomLeftRadius: "0px",
      },
      "&:last-child": {
        borderTopRightRadius: "8px",
        borderBottomLeftRadius: "0px",
      },
      "&.chart--table-sort-icon": {
        marginLeft: "10px",
      },
      'input[type="checkbox"]': {
        width: "16px",
        height: "16px",
      },
      backgroundColor: "#E6EDFF",
      //width: "250px",
      padding: "20px 20px",
      fontSize: "16px",
      color: "#000000",
      fontWeight:"600",
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "0px",
        borderRightColor: "#FFFFFF",
      },
      div: {
        color: "#000000",
      },
    },
  },
  cells: {
    style: {
      color: "#000000",
      fontSize: "14px",
      paddingTop: "10px",
      paddingBottom: "10px",
      fontWeight:"600 !important",
      backgroundColor:"#FAFAFD",
      'input[type="checkbox"]': {
        width: "16px",
        height: "16px",
      },
      // "&:nth-child(1)": {
      //   div: {
      //     textOverflow: "ellipsis",
      //     display: " -webkit-box",
      //     WebkitLineClamp: "2",
      //     WebkitBoxOrient: "vertical",
      //   },
      // },
    },
  },
};

export const DocumentName = ({ row }: any) => {
  return <div className="">{row.file_name}</div>;
};

export const UploadedAt = ({ row }: any) => (
    <div>
      <Moment utc local format={'DD-MM-yyyy hh:mm A'}>{row.created_at}</Moment>
    </div>
  )

  export const ActionColumn = ({ row, documentHandler }: any) => {
    const { t } = useTranslation('translation');
    return (
        <Button
        variant='suggest'
        className='px-0 py-1 font-weight-bold'
        onClick={() => {
            documentHandler(row._id)
        }}
      >
        {t("Use Document")}
      </Button>
    )
  }
  
export const getColumns = ({
    documentHandler
}: any) => [
  {
    name: "Document Name",
    selector: "name",
    sortable: false,
    cell: (row: any) => <DocumentName row={row} />,
  },
  {
    name: "Uploaded At",
    selector: "updated_at",
    sortable: false,
    cell: (row: any) => <UploadedAt row={row} />,
  },
  {
    name: "Action",
    selector: "",
    sortable: false,
    cell: (row: any) => (
      <ActionColumn
        row={row}
        documentHandler={documentHandler}
      />
    ),
  },
];
