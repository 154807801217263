/* eslint-disable react/jsx-no-target-blank */
import { default as classNames } from "classnames";
import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import buildDragDropImg from "../../../assets/New-images/build-drag-drop.svg";
import closeimg from "../../../assets/New-images/close-icon-red.svg";
import fileSizeExceedsCross from "../../../assets/New-images/file-size-exceeds-cross.svg";
import Gcheckimg from "../../../assets/New-images/green-check.svg";
import infoImg from "../../../assets/New-images/info-file-img.svg";
import pptimg from "../../../assets/images/pptx.svg";
import pdfimg from "../../../assets/images/pdf.svg";
import docximg from "../../../assets/images/docx.svg";
import DragAndDrop from "../../../components/UI/DragAndDrop";
import {
  BUILD_MULTI_DOCUMENTS_SECTION,
  BUILD_SUMMARISE_DOCUMENT,
  BUILD_SUMMARISE_MULTI_DOCUMENTS_SECTION,
} from "../../../config";
import { HelperService } from "../../../services/helper.service";
import {
  bulkAssignModules,
  removeDocumentByDocumentId,
  resetDocuments,
  uploadFilesToStorage,
  validateDocuments,
} from "../../../store/knowledge/actions";
// import { getUserDocuments } from "../../../../store/knowledge/selector";

import { useTranslation } from "react-i18next";
import ProgressSteps from "../../../components/UI/ProgressSteps/ProgressSteps";
import { supportedFilesKendra } from "../../../app.constant";
import { getAgentFeatures } from "../../../store/common/actions";
import MoveSelectedModal from "../../../components/UI/KnowledgeDatatableComponent/MoveSelectedModal";

interface Props {}

export const UploadDocument = (props: any) => {
  const { t } = useTranslation("translation");
  const helper = new HelperService();
  let re = /(?:\.([^.]+))?$/;
  const dispatch = useDispatch();
  const useLocatio = useLocation();
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [isFileInValid, setIsFileInValid] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [hasFileUploaded, setHasFileUploaded] = useState(false);
  //   const userDocuments = useSelector(getUserDocuments);
  const history = useHistory();
  const [documents, setDocuments] = React.useState([]);
  const [faqOption, setFaqOption] = React.useState("upload");
  const [uploadUrl, setUploadURL] = React.useState({
    url: "",
    valid: false,
  });
  const [loading, setLoading] = useState(false);
  const [isBtnDisable, setIsBtnDisable] = useState(true);
  const [isLanguageError, setIsLanguageError] = useState(null);
  const [isFileValidating, setIsFileValidating] = useState(false);
  const [showNote, setShowNote] = useState(false);
  const [fileSize, setFileSize] = useState(null);
  const [newDocAdded, setNewDocAdded] = useState(false);

  const params: any = useParams();

  useEffect(() => {
    const fn = async () => {
      await dispatch(resetDocuments());
      setDocuments([]);
      const agentFeatures: any = await dispatch(getAgentFeatures());
      readableBytes(
        agentFeatures.filter((e: any) => e.name === "knowledge_management")
      );
    };
    fn();
  }, []);

  const readableBytes = (feature: any) => {
    if (feature.length === 0) {
      setFileSize(10);
    } else {
      const bytes = feature[0]?.params?.max_file_size;
      setFileSize((bytes / Math.pow(1024, 2)).toFixed());
    }
  };

  useEffect(() => {
    setIsBtnDisable(
      documents.some(function (doc: any) {
        return doc.sizeError || doc.isLangError;
      })
    );
  }, [documents]);

  const getFileDocumentsHandler = async (d: File[]) => {
    setIsFileInValid(false);
    setIsFileUploading(false);
    let totalDocuments = 0;
    totalDocuments = documents.length + d.length;
    if (totalDocuments > 5) {
      helper.showToaster(
        t("Failed to upload. Maximum number of document reached.")
      );
      return;
    }
    const oldDocuments = [...documents];

    await setDocuments([
      ...oldDocuments,
      ...d.map((doc: any) => {
        doc.sizeError = doc.size / (1024 * 1024) > fileSize;
        return doc;
      }),
    ]);
    setIsBtnDisable(false);
    setHasFileUploaded(false);
    // dispatch(actions.setDocuments(d))
  };

  /**
   *
   * KNOWLEDGE_REVIEW_FAQ
   */
  const processDocuments = async (selectedOption: string = "") => {
    if (hasFileUploaded) {
      const ID = documents.map((d: any) => d._id).join(",");
      history.push(
        `${BUILD_MULTI_DOCUMENTS_SECTION.replace(":step", "2")}?group=${
          props.group
        }&documents=${ID}`
      );
      return;
    }

    if (documents.length === 0) {
      setIsFileInValid(true);
      setIsBtnDisable(true);
      helper.showToaster(t("Please choose documents before proceeding."));
      return;
    } else if (documents && documents[0].sizeError) {
      setIsBtnDisable(true);
      helper.showToaster(t("File not uploaded. File size is more than 10 MB."));
      return;
    } else {
      setIsFileUploading(true);
      setHasFileUploaded(false);
      const query = new URLSearchParams(useLocatio.search);
      const group = query.get("group");
      const oldDocuments = [...documents];
      const uploadedIds = [];
      const removeIndexes = [];
      const succeededDocuments = [];
      for (let index = 0; index < oldDocuments.length; index++) {
        const document = documents[index];
        if (!document.isUploaded && !document.isError && !document.sizeError) {
          document.isUploading = true;
          const uploadedDocument: any = await dispatch(
            uploadFilesToStorage(
              document,
              (event: any) => {
                // console.log('event', event);
                // console.log('event', event);
                // console.log('Math.round((100 * event.loaded) / event.total)', Math.round((100 * event.loaded) / event.total));
                const prevResult = document;
                prevResult.uploadProgress = Math.round(
                  (100 * event.loaded) / event.total
                );
                setDocuments([...documents]);
              },
              "other",
              "newupload",
              group,
              // props.isContexualised ? "v1" : "v2",
              "v3"
            )
          );
          const prevResult = document;
          if (
            uploadedDocument &&
            !uploadedDocument._id
            // || (uploadedDocument.status && uploadedDocument.status.code !== 200)
            // )
          ) {
            document.isUploading = false;
            document.isUploaded = false;
            document.isError = true;
            document.message =
              uploadedDocument.status && uploadedDocument.status.message
                ? uploadedDocument.status.message
                : t("File type not supported");
            helper.showToaster(
              uploadedDocument.status && uploadedDocument.status.message
                ? uploadedDocument.status.message
                : `${document?.name} ` +
                    t(
                      `upload failed. Word count limit exceeded. Please adjust and try again`
                    )
            );
            removeIndexes.push(index);
          } else {
            document.isUploaded = true;
            document.isUploading = false;
            document.isError = false;
            document._id = uploadedDocument._id;
            uploadedIds.push(uploadedDocument._id);
            succeededDocuments.push(document);
          }
          await setDocuments([...documents]);
        }
      }
      setIsFileUploading(false);
      setHasFileUploaded(true);
      await setDocuments([...succeededDocuments]);
      if (uploadedIds.length) {
        const query = new URLSearchParams(history.location.search);
        const group = query.get("group") || selectedOption;
        if (group) {
          dispatch(bulkAssignModules(group, uploadedIds, "document", false));
        }
        history.push(
          `${BUILD_SUMMARISE_MULTI_DOCUMENTS_SECTION.replace(
            ":step",
            "3"
          ).replace(":subStep", "2")}?group=${
            selectedOption ? selectedOption : props.group
          }&documents=${uploadedIds.join(",")}&type=${props.type}`
        );
      }
    }
  };

  const handleDocRemove = async (
    e: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    const newDocuments = [...documents];
    const slicedDoc = newDocuments.splice(index, 1);
    slicedDoc[0].isLangError && setShowNote(false);
    if (slicedDoc && slicedDoc[0] && slicedDoc[0].isUploaded) {
      dispatch(removeDocumentByDocumentId(slicedDoc[0]._id));
    }
    await showNoteFunc(newDocuments);
    await setDocuments(newDocuments);
  };

  const showNoteFunc = (d: any) => {
    let show = d.some((o: any) => o?.isLangError);
    show && setShowNote(true);
  };

  const validateDocument = async () => {
    if (documents.length > 0) {
      setIsFileValidating(true);
      const val = await Promise.all(
        documents &&
          documents.map(async (doc: any, index: number) => {
            const response: any = await dispatch(
              validateDocuments(doc, doc.name, (event: any) => {
                const prevResult = doc;
                prevResult.progress = Math.round(
                  (100 * event.loaded) / event.total
                );
                setDocuments([...documents]);
              })
            );
            if (!response.isError) {
              // Document suceessfully uploaded
              doc.isLangError = false;
            } else {
              // Document Upload failed
              doc.isLangError = true;
            }
            return doc;
          })
      );

      setDocuments([...val]);
      let validity = !val.some((o: any) => o?.isLangError);
      await setIsLanguageError(!validity);
      await setShowNote(!validity);
      setIsFileValidating(false);
      if (validity) {
        await setNewDocAdded(true);
      }
      // validity && (await processDocuments());
    }
  };

  const confirmCategoryUpdate = async (
    selectedOption: any,
    createButtons: boolean = false
  ) => {
    await setNewDocAdded(false);
    if (selectedOption) {
      processDocuments(selectedOption);
    }
  };

  const RenderSelectedDocuments =
    documents &&
    documents.map((doc: any, index: number) => {
      let img = pptimg;
      if (doc.name.split(".").pop().includes("ppt")) {
        img = pptimg;
      }
      if (doc.name.split(".").pop().includes("doc")) {
        img = docximg;
      }
      if (doc.name.split(".").pop().includes("pdf")) {
        img = pdfimg;
      }
      return (
        <div
          className={`${doc.sizeError ? "f-error " : ""}${
            doc.isLangError ? "info-doc-msg " : ""
          }files d-flex align-items-center border-grey radius-4 px-3 py-2`}
          key={index}
        >
          <div className="d-flex align-items-center w-auto">
            <Image src={img} />
            <div className="mx-3 font-sm">
              <strong>{doc.name}</strong>
              {!doc.sizeError && (
                <span className="font-12 clr-black font-600">
                  {helper.formatBytes(doc.size)}
                </span>
              )}

              {/* <ProgressBar now={doc.progress} /> */}
              {doc.isError && <span>{doc.message}</span>}
            </div>
          </div>

          <div className="ml-auto w-auto d-flex align-items-center">
            {doc.sizeError && (
              <>
                <div className="f-error d-flex align-items-center font-12 font-600">
                  <img src={fileSizeExceedsCross} className="mr-1" />
                  {t("File size exceeds limit.")}
                </div>
              </>
            )}
            {doc.progress === 100 &&
              !isLanguageError &&
              doc.uploadProgress === 100 &&
              !isFileValidating && (
                <div className="doc-success-msg d-flex align-items-center font-600 clr-green font-sm">
                  <Image src={Gcheckimg} alt="" className="mr-2" />
                  {t("Upload Success")}
                </div>
              )}
            {
              // doc.progress < 100 ||
              isFileValidating && (
                <div className="doc-checking-msg d-flex align-items-center font-600 clr-black font-sm">
                  <span className="doc-loader spinner-width-20 mr-2"></span>
                  {t("Checking document")}
                </div>
              )
            }

            {!doc.isUploading && !isFileUploading && !isFileValidating && (
              <button
                className="btn p-0 ml-3 btn-red-close"
                onClick={(e) => handleDocRemove(e, index)}
              >
                <Image src={closeimg} />
              </button>
            )}
          </div>
        </div>
      );
    });

  const dragDropClass = classNames({
    "invalid-file": isFileInValid,
  });

  return (
    <>
      <MoveSelectedModal
        show={newDocAdded}
        onHide={() => setNewDocAdded(false)}
        confirmCategoryUpdate={confirmCategoryUpdate}
        isBuildKnowledge
        showAddModuleBtn
      />
      <div>
        <div className="row page-mid-wraper-build-v2">
          <div className="col-md-5">
            <div className={dragDropClass}>
              <div className="custom-file-wrap new-custom-file mw-100 border-0">
                <DragAndDrop
                  getFileDocumentsHandler={getFileDocumentsHandler}
                  supportedTypes={supportedFilesKendra.join(", ")}
                  //   maxFiles={3}
                  message={
                    "<h2 className='mb-1'>" +
                    t("Drop your files here or click to browse.") +
                    "</h2><div>" +
                    t("Supported File types: .docx, pdf, pptx") +
                    "</div>"
                  }
                  fileSizeMessage=""
                  fileImage={buildDragDropImg}
                  {...props}
                />
              </div>
            </div>
          </div>
          <div className="col-md-7 mt-3 mt-md-0">
            <>
              <p className="clr-grey-txt7 font-16 mb-3">
                {t("You can upload one or a maximum of ")}
                <span className="clr-primary font-weight-bold">{t("5 ")}</span>
                {t(
                  "document(s) and the maximum file size for each document is "
                )}
                <span className="clr-primary font-weight-bold">
                  {fileSize ? t(`${fileSize}MB`) : t("10MB")}
                </span>
              </p>
            </>
            {showNote && (
              <div className="d-flex align-items-center font-16 info-doc-msg radius-8 py-2 px-3">
                <Image src={infoImg} alt="" className="mr-3" />
                <div>
                  {t(
                    `Our chatbot does not support the language of the following document(s), 
                  which may result in subpar responses. For a full list of supported languages, 
                  please `
                  )}
                  <span
                    className="clr-primary font-weight-bold cursor-pointer"
                    onClick={() => {
                      window.open(
                        "http://3359170.hs-sites.com/en/knowledge/supported-languages",
                        "_blank"
                      );
                    }}
                  >
                    {t("click here")}
                  </span>
                </div>
              </div>
            )}{" "}
            <div className="selected-files mt-3 w-100">
              {RenderSelectedDocuments}
            </div>
          </div>
        </div>
      </div>
      <div className="text-right">
        <button
          className="btn btn-primary mt-4"
          onClick={() => {
            validateDocument();
          }}
          disabled={
            isFileUploading ||
            isFileValidating ||
            isBtnDisable ||
            !documents.length
          }
        >
          {t("Upload Document")}
        </button>
      </div>
      {/* <ul className="list-unstyled progress-steps d-flex align-items-center justify-content-center mt-4">
        <li className="active"></li>
        <li></li>
        <li></li>
        <li></li>
      </ul> */}
      <ProgressSteps currentStep={1} steps={4} />
    </>
  );
};

export default UploadDocument;
