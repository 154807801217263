import { uuid4 } from "@sentry/utils";
import * as type from "./actionType";
import * as actionTypes from "../../store/actionType";
import { HelperService } from "./../../services/helper.service";
import UploadFilesService from "../../services/UploadService";
import KnowledgeService from "../../services/KnowledgeService";
import { ButtonsOptions } from "../../services/interfaces/button-options.interface";
import { convertArrayToMarkdownFormat } from "../../utils/appUtils";

type AppDispatchType = any;

const knowledgeService = new KnowledgeService();
const uploadService = new UploadFilesService();
const helperService = new HelperService();

export const getKnowledgeById =
  (intentId: string) => async (dispatch: AppDispatchType) => {
    dispatch({
      type: type.FETCH_KNOWLEDGE_BY_ID,
      payload: {
        loading: true,
        knowledgeHasError: false,
      },
    });
    try {
      const response = await knowledgeService.getKnowledgeById(intentId);
      dispatch({
        type: type.FETCH_KNOWLEDGE_BY_ID_SUCCESS,
        payload: {
          intent: {
            ...response.data.data.intent,
            buttons:
              response.data.data.intent.buttons &&
              response.data.data.intent.buttons.length
                ? response.data.data.intent.buttons.map((btn: any) => {
                    let id = uuid4();
                    if (
                      (btn.type === "suggest" || btn.type === "question") &&
                      btn.reference_id === "None"
                    ) {
                      return {
                        ...btn,
                        label: `${btn.label}`,
                        name: btn.question,
                        reference: `suggestquestion::${id}`,
                        reference_id: id,
                      };
                    } else {
                      return {
                        ...btn,
                      };
                    }
                  })
                : [],
          },
        },
      });
    } catch (error: any) {
      dispatch({
        type: type.FETCH_KNOWLEDGE_BY_ID_FAIL,
        payload: {
          loading: false,
          knowledgeHasError: true,
        },
      });
    }
  };

export const getAllButtonOptions =
  (force: boolean = false, topic: any = null) =>
  async (dispatch: AppDispatchType, getState: any) => {
    const { question } = getState();
    try {
      // fetch options only when you have no options available
      if ((question && question.buttonOptions.length === 0) || force) {
        const response = await knowledgeService.getAllButtonOptions(topic);
        dispatch({
          type: type.FETCH_ALL_BUTTON_OPTIONS_SUCCESS,
          payload: {
            buttonOptions: response.data.data.button_options,
          },
        });
      } else {
        dispatch({
          type: type.FETCH_ALL_BUTTON_OPTIONS_SUCCESS,
          payload: {
            buttonOptions: question.buttonOptions,
          },
        });
      }
    } catch (e: any) {
      dispatch({
        type: type.FETCH_ALL_BUTTON_OPTIONS_SUCCESS_FAIL,
        payload: {
          buttonOptions: [],
        },
      });
    }
  };

export const getAllUserGroups =
  (force: boolean = false) =>
  async (dispatch: AppDispatchType, getState: any) => {
    const { question } = getState();
    try {
      // fetch options only when you have no options available
      if ((question && question.groups.length === 0) || force) {
        // TODO: Add this code later whenever we do new UI for groups
        const response = await knowledgeService.getAllUserGroupOptions();
        dispatch({
          type: type.FETCH_ALL_USER_GROUP_OPTIONS_SUCCESS,
          payload: {
            groups: response.data.data.groups.map((item: any) => ({
              value: item._id,
              label: item.name,
            })),
          },
        });
      } else {
        dispatch({
          type: type.FETCH_ALL_USER_GROUP_OPTIONS_SUCCESS,
          payload: {
            groups: question.groups,
          },
        });
      }
    } catch (e: any) {
      dispatch({
        type: type.FETCH_ALL_USER_GROUP_OPTIONS_SUCCESS_FAIL,
        payload: {
          groups: [],
        },
      });
    }
  };

export const getAllIntents =
  (force: boolean = false, intentType: number = 3) =>
  async (dispatch: AppDispatchType, getState: any) => {
    const { question } = getState();
    try {
      // fetch intents only when you have no options available
      if (force) {
        const response = await knowledgeService.getAllKnowledge(
          intentType,
          1,
          Number.MAX_SAFE_INTEGER,
          ""
        );
        dispatch({
          type: type.FETCH_ALL_INTENTS_SUCCESS,
          payload: {
            intents: response.data.data.intents,
          },
        });
      } else {
        if (question && question.intents.length === 0) {
          const response = await knowledgeService.getAllKnowledge(
            intentType,
            1,
            Number.MAX_SAFE_INTEGER,
            ""
          );
          dispatch({
            type: type.FETCH_ALL_INTENTS_SUCCESS,
            payload: {
              intents: response.data.data.intents,
            },
          });
        } else {
          dispatch({
            type: type.FETCH_ALL_INTENTS_SUCCESS,
            payload: {
              intents: question.intents,
            },
          });
        }
      }
    } catch (e: any) {
      dispatch({
        type: type.FETCH_ALL_INTENTS_FAIL,
        payload: {
          intents: [],
        },
      });
    }
  };

export const updateIntentParentQuestion =
  (questionId: string) => async (dispatch: AppDispatchType, getState: any) => {
    const state = getState();
    try {
      const response = await knowledgeService.updateIntentParentQuestion(
        state.question.intent._id,
        questionId
      );
      // Update knowledge all screen
      dispatch({
        type: type.FETCH_KNOWLEDGE_BY_ID_SUCCESS,
        payload: {
          intent: response.data.data.intent,
        },
      });
      dispatch({
        type: actionTypes.UPDATE_KNOWLEDGE_BY_ID_SUCCESS,
        payload: response.data.data.intent,
      });
      helperService.showToaster("Updated successfully.", "success");
      return Promise.resolve(true);
    } catch (e: any) {
      helperService.showToaster(
        "Something went wrong while updating parent question."
      );
      return Promise.resolve(false);
    }
  };

export const updateIntent =
  (fromSection: string | null = null) =>
  async (dispatch: AppDispatchType, getState: any) => {
    const state = getState();
    try {
      const elements = state.flow.elements || [];
      const isRootNodeElement = elements.find(
        (el: any) => el.data.reference_id === state.question.intent._id
      );
      const intent = {
        ...state.question.intent,
      };
      if (
        fromSection &&
        fromSection === "conversation" &&
        isRootNodeElement &&
        isRootNodeElement.data.is_welcome
      ) {
        delete intent.questions;
      } else {
        if (!intent || !intent.questions || intent.questions.length === 0) {
          throw Error("Please add atleast one question.");
        }
      }
      if (!intent || !intent.responses || intent.responses.length === 0) {
        throw Error("Please add atleast one response.");
      }

      const response = await knowledgeService.updateCompleteIntent(
        intent._id,
        intent
      );

      dispatch({
        type: type.FETCH_KNOWLEDGE_BY_ID_SUCCESS,
        payload: {
          intent: response.data.data.intent,
        },
      });
      // Update knowledge all screen
      dispatch({
        type: actionTypes.UPDATE_KNOWLEDGE_BY_ID_SUCCESS,
        payload: response.data.data.intent,
      });
      helperService.showToaster("Updated successfully.", "success");
      return Promise.resolve(true);
    } catch (e: any) {
      helperService.showToaster(
        e.message ? e.message : "Something went wrong while updating intent."
      );
      return Promise.resolve(false);
    }
  };

export const createIntent =
  () => async (dispatch: AppDispatchType, getState: any) => {
    const state = getState();
    try {
      if (
        !state.question.intent ||
        !state.question.intent.questions ||
        state.question.intent.questions.length === 0
      ) {
        throw Error("Please add atleast one question.");
      }
      if (
        !state.question.intent ||
        !state.question.intent.responses ||
        state.question.intent.responses.length === 0
      ) {
        throw Error("Please add atleast one response.");
      }

      const response: any = await knowledgeService.createNewIntent({
        questions: state.question.intent.questions,
        buttons:
          state.question.intent.buttons && state.question.intent.buttons.length
            ? state.question.intent.buttons.map((btn: any) => {
                if (btn.type === "suggest") {
                  return {
                    group_ids: btn.group_ids,
                    label: btn.label,
                    question: btn.name,
                    type: "question",
                  };
                } else {
                  return {
                    ...btn,
                  };
                }
              })
            : [],
        responses: await convertArrayToMarkdownFormat(
          state.question.intent.responses
        ),
        name: state.question.intent.questions[0].text,
        type: 3,
        is_input_disabled: state.question.intent.hasOwnProperty(
          "is_input_disabled"
        )
          ? state.question.intent.is_input_disabled
          : null,
        tags: state.question.intent.hasOwnProperty("tags")
          ? state.question.intent.tags
          : [],
        parent_id: state.question.intent.hasOwnProperty("parent_id")
          ? state.question.intent.parent_id
          : null,
      });

      if (
        state.question &&
        state.question.intent &&
        state.question.intent.knowledge_group_id
      ) {
        await knowledgeService.addEntityToKnowledgeGroup(
          state.question.intent.knowledge_group_id,
          "intent",
          [response.data.data.intent._id]
        );
      }

      helperService.showToaster("Created successfully.", "success");
      return Promise.resolve(true);
    } catch (e: any) {
      helperService.showToaster(
        e.message ? e.message : "Something went wrong while updating intent."
      );
      return Promise.reject(false);
    }
  };

export const createSmallTalkIntent =
  () => async (dispatch: AppDispatchType, getState: any) => {
    const state = getState();
    try {
      if (
        !state.question.intent ||
        !state.question.intent.questions ||
        state.question.intent.questions.length === 0
      ) {
        throw Error("Please add atleast one question.");
      }
      if (
        !state.question.intent ||
        !state.question.intent.responses ||
        state.question.intent.responses.length === 0
      ) {
        throw Error("Please add atleast one response.");
      }
      const response: any = await knowledgeService.createNewIntent({
        questions: state.question.intent.questions,
        buttons: state.question.intent.buttons,
        responses: await convertArrayToMarkdownFormat(
          state.question.intent.responses
        ),
        name: state.question.intent.questions[0].text,
        type: 4,
        is_input_disabled: state.question.intent.hasOwnProperty(
          "is_input_disabled"
        )
          ? state.question.intent.is_input_disabled
          : null,
        tags: state.question.intent.hasOwnProperty("tags")
          ? state.question.intent.tags
          : [],
        parent_id: state.question.intent.hasOwnProperty("parent_id")
          ? state.question.intent.parent_id
          : null,
      });

      // if ( state.question && state.question.intent && state.question.intent.knowledge_group_id ) {
      //     await knowledgeService.addEntityToKnowledgeGroup(state.question.intent.knowledge_group_id, 'intent', [ response.data.data.intent._id ]);
      // }

      helperService.showToaster("Created successfully.", "success");
      return Promise.resolve(true);
    } catch (e: any) {
      helperService.showToaster(
        e.message ? e.message : "Something went wrong while updating intent."
      );
      return Promise.reject(false);
    }
  };

export const updateIntentTags =
  (tags: string[]) => async (dispatch: AppDispatchType, getState: any) => {
    const state = getState();
    try {
      const response = await knowledgeService.addTagsToIntent(
        state.question.intent._id,
        tags
      );
      dispatch({
        type: type.FETCH_KNOWLEDGE_BY_ID_SUCCESS,
        payload: {
          intent: response.data.data.intent,
        },
      });
      // Update knowledge all screen
      dispatch({
        type: actionTypes.UPDATE_KNOWLEDGE_BY_ID_SUCCESS,
        payload: response.data.data.intent,
      });
      helperService.showToaster("Updated successfully.", "success");
      return Promise.resolve(true);
    } catch (e: any) {
      helperService.showToaster(
        e.message ? e.message : "Something went wrong while updating intent."
      );
      return Promise.resolve(false);
    }
  };

export const localUserQuestionUpdate =
  (key: string, value: any) => async (dispatch: AppDispatchType) => {
    dispatch({
      type: type.LOCAL_QUESTION_UPDATE,
      payload: {
        key,
        value,
      },
    });
  };

export const createIntentFromManageAnswer =
  (intent: any, learnerQuestions: boolean = false) => async (dispatch: AppDispatchType, getState: any) => {
    const state = getState();
    try {
      if (
        // (!intent || !intent.questions || intent.questions.length === 0) &&
        (!state ||
          !state.question ||
          !state.question.intent ||
          !state.question.intent.questions ||
          state.question.intent.questions.length === 0)) {
        throw Error("Please add atleast one question.");
      }
      if (
        // (!intent || !intent.responses || intent.responses.length === 0) &&
        (!state ||
          !state.question ||
          !state.question.intent ||
          !state.question.intent.responses ||
          state.question.intent.responses.length === 0)
      ) {
        throw Error("Please add atleast one response.");
      }

      const buttons =
        // intent.buttons && intent.buttons.length
        //   ? intent.buttons
        //   : 
          state.question &&
            state.question.intent &&
            state.question.intent.buttons ? state.question.intent.buttons : [];

      const questions =
        // intent.questions && intent.questions.length > 0
        //   ? intent.questions
        //   : 
          state.question &&
            state.question.intent &&
            state.question.intent.questions &&
            state.question.intent.questions.length > 0
          ? state.question.intent.questions
          : [];
          
      const response: any = await knowledgeService.createNewIntent({
        questions: questions,
        buttons:
          buttons && buttons.length
            ? buttons.map((btn: any) => {
                if (btn.type === "suggest" || btn.type === "question") {
                  return {
                    group_ids: btn.group_ids,
                    label: btn.label,
                    question: btn.name || btn.question,
                    type: "question",
                  };
                } else {
                  return {
                    ...btn,
                  };
                }
              })
            : [],
        responses: await convertArrayToMarkdownFormat(
          // intent.responses || 
          state.question.intent.responses
        ),
        name:
          // intent.questions[0].text || 
          state.question.intent.questions[0].text,
        type: 3,
        is_input_disabled: 
        // intent.hasOwnProperty("is_input_disabled")
        //   ? intent.is_input_disabled
        //   : 
          state.question.intent.hasOwnProperty("is_input_disabled")
          ? state.question.intent.is_input_disabled
          : null,
        tags: 
        // intent.hasOwnProperty("tags")
        //   ? intent.tags
        //   : 
          state.question.intent.hasOwnProperty("tags")
          ? state.question.intent.tags
          : [],
        parent_id: 
        // intent.hasOwnProperty("parent_id")
        //   ? intent.parent_id
        //   : 
          state.question.intent.hasOwnProperty("parent_id")
          ? state.question.intent.parent_id
          : null,
      });

      // const unasweredQuestionId = intent.unaswered_question_id
      //   ? intent.unaswered_question_id
      //   : intent.suggested_answer_id;
      // if (unasweredQuestionId && !learnerQuestions) {
      //   await knowledgeService.updateKnowledgeAnswerStatus(
      //     unasweredQuestionId,
      //     response.data.data.intent._id
      //   );
      // }

      // if (unasweredQuestionId && intent.author_id && !learnerQuestions) {
      //   await knowledgeService.updateSuggestedAnswerStatus(
      //     unasweredQuestionId,
      //     intent.author_id,
      //     response.data.data.intent._id
      //   );
      // }

      if (intent && intent.knowledge_group_id) {
        await knowledgeService.addEntityToKnowledgeGroup(
          intent.knowledge_group_id,
          "intent",
          [response.data.data.intent._id]
        );
      }

      if (intent && intent.answers_by_openAI && !learnerQuestions) {
        await knowledgeService.updateKnowledgeAnswerStatus(
          intent.conversation_log_id,
          response.data.data.intent._id
        );
      }

      helperService.showToaster("Created successfully.", "success");
      return Promise.resolve(response.data.data.intent._id);
    } catch (e: any) {
      helperService.showToaster(
        e.message ? e.message : "Something went wrong while updating intent."
      );
      return Promise.reject(false);
    }
  };

export const getSuggestedKnowledgeFeedback =
  (intent: any) => async (dispatch: AppDispatchType, getState: any) => {
    // suggestedKnowledgeFeedback({
    //     selectedAnswer: records,
    //     question: records.text,
    //     answer: record.comment,
    //     intent_id: record.author_id,
    //   });

    try {
      await knowledgeService.updateSuggestedAnswerStatus(
        intent.selectedAnswer._id,
        intent.intent_id,
        null
      );
      return Promise.resolve(true);
    } catch (e: any) {
      helperService.showToaster(
        e.message ? e.message : "Something went wrong while updating details."
      );
      return Promise.reject(false);
    }
  };

export const BulkDeleteUnansweredQuestions =
  (data: any) => async (dispatch: AppDispatchType, getState: any) => {
    try {
      for (let index = 0; index < data.length; index++) {
        await knowledgeService.bulkDeleteUnansweredQuestions(data[index]);
      }
      return Promise.resolve(true);
    } catch (e: any) {
      helperService.showToaster(
        e.message ? e.message : "Something went wrong while deleting questions."
      );
      return Promise.reject(false);
    }
  };

export const deleteAnswersFromOutsideSource =
  (_id: string) => async (dispatch: AppDispatchType, getState: any) => {
    try {
      await knowledgeService.deleteAnswersFromOutsideSource(_id);
      return Promise.resolve(true);
    } catch (e: any) {
      helperService.showToaster(
        e.message ? e.message : "Something went wrong while deleting questions."
      );
      return Promise.reject(false);
    }
  };

export const getParaPhrasesBasedOnIntent =
  (intentID: string) => async (dispatch: AppDispatchType, getState: any) => {
    try {
      const response = await knowledgeService.getParaPhrasesBasedOnIntent(
        intentID
      );
      return Promise.resolve(response.data.data.intent);
    } catch (e: any) {
      return Promise.reject([]);
    }
  };

export const updateUserFeedback =
  (intentID: string) => async (dispatch: AppDispatchType, getState: any) => {
    try {
      const response = await knowledgeService.updateUserFeedback(intentID);
      return Promise.resolve(response.data.data.intent);
    } catch (e: any) {
      return Promise.reject([]);
    }
  };

export const getParaPhrasesWithoutIntent =
  (questions: string[]) => async (dispatch: AppDispatchType, getState: any) => {
    try {
      const response = await knowledgeService.getParaPhrasesWithoutIntent(
        questions
      );
      return Promise.resolve(response.data.data);
    } catch (e: any) {
      return Promise.reject([]);
    }
  };
