import { useEffect, useState } from "react";
import { Button, Image, Modal, ModalFooter } from "react-bootstrap";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import Select from "react-select";
import downarrowblk from "../../../../assets/images/chevron-down-blk.svg";
import closeimg from "../../../../assets/images/close-blk.svg";
import closeRed from "../../../../assets/New-images/close-icon-red.svg";

import PermissionWrapper from "../../../../components/UI/PermissionWrapper/PermissionWrapper";
import { HelperService } from "../../../../services/helper.service";
import { getAllOrganisationUsers } from "../../../../store/agents/actions";
import { addLearnerToClasses, addUsersAndClassesToLearnerGroup } from "../../../../store/knowledge/actions";

import { useTranslation } from "react-i18next";

const AddExistingUsersToGroup = (props: any) => {
  const { t } = useTranslation('translation');
  const dispatch = useDispatch();
  const helper = new HelperService();
  const { groupId }: any = useParams();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [creatableValue, setCreatableValue] = useState(undefined);
  const [selectedButtonTarget, setSelectedButtonTarget] = useState({
    user_ids: [],
    group_ids: [],
  });

  useEffect(() => {
    if (!props.show) {
      return;
    }
    setSelectedButtonTarget({
      user_ids: [],
      group_ids: [],
    });
    getAllUsers();
  }, [props]);

  const getAllUsers = async () => {
    try {
      const response: any = await dispatch(getAllOrganisationUsers());
      const data: any = [];
      response.users.forEach((element: any) => {
        data.push({
          _id: element._id,
          label: element.email,
          value: element.email,
        });
      });
      setUsers(data);
    } catch (error) {
      setUsers([]);
    }
  };

  const createOrUpdateAgent = async () => {
    console.log('selectedButtonTarget', selectedButtonTarget);

    if (
      !selectedButtonTarget.group_ids.length &&
      !selectedButtonTarget.user_ids.length
    ) {
      return;
    }

    let classUsers: any = [];
    if (selectedButtonTarget && selectedButtonTarget.group_ids.length) {
      let students: any = [];
      let admins: any = [];
      selectedButtonTarget.group_ids.forEach((classData: any) => {
        students.push(classData.studentGroupId);
        // admins = admins.concat(classDropdownData[classData.value].adminGroupId);
      });
      // if (shouldAddAdmins) {
      //   classUsers = students.concat(admins);
      // } else {
        classUsers = students;
      // }
    }

    let temp: any = {
      user_ids: selectedButtonTarget.user_ids.map(d => d._id),
      group_ids: classUsers,
    };

    try {
      setLoading(true);

      await dispatch(
        addUsersAndClassesToLearnerGroup(groupId, temp)
      );
      
      // For classes
      // if (selectedButtonTarget.group_ids.length > 0 ) {
      //   for await (const userClass of selectedButtonTarget.user_ids) {
      //     for await (const group of selectedButtonTarget.group_ids) {
      //       await dispatch(
      //         addLearnerToClasses(
      //           group.value,
      //           userClass._id,
      //           "students"
      //         )
      //       );
      //     }
      //   }
      // }

      // For users
      // if (selectedButtonTarget.user_ids.length > 0 ) {
      //   for await (const userClass of selectedButtonTarget.user_ids) {
      //     await dispatch(
      //       addUsersAndClassesToLearnerGroup(groupId, {
      //         user_ids: [
      //           userClass._id
      //         ],
      //       })
      //     );
      //   }
      // }

      props.confirmHandler();
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };

  const removeClassesOfAUser = (classIndex: number) => {
    const oldData = [...selectedButtonTarget.group_ids];
    const deletedData: any = oldData.splice(classIndex, 1);
    // if (props.editMode) {
    //   dispatch(
    //     deleteLearnerToClasses(
    //       deletedData[0].value,
    //       props.editData._id,
    //       "students"
    //     )
    //   );
    // }
    setSelectedButtonTarget({
      ...selectedButtonTarget,
      group_ids: [...oldData],
    });
  };

  const removeUsersOfAUser = (classIndex: number) => {
    const oldData = [...selectedButtonTarget.user_ids];
    const deletedData: any = oldData.splice(classIndex, 1);
    // if (props.editMode) {
    //   dispatch(
    //     deleteLearnerToClasses(
    //       deletedData[0].value,
    //       props.editData._id,
    //       "students"
    //     )
    //   );
    // }
    setSelectedButtonTarget({
      ...selectedButtonTarget,
      user_ids: [...oldData],
    });
  };


  return (
    <Modal
      show={props.show}
      size={props.size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="modal-400"
      onHide={props.onHide}
    >
      <ModalHeader className="justify-content-start border-bottom py-2 align-items-center">
        <ModalTitle
          className="h5 py-1 font-600"
          id="contained-modal-title-vcenter"
        >
          {t("Add Learner")}
        </ModalTitle>
        <Button onClick={props.onHide} className="ml-auto">
          <Image src={closeimg} alt="" />
        </Button>
      </ModalHeader>
      <ModalBody>
        <div className="mb-12">
          <h3 className="mb-1">{t("Add a class into group")}</h3>
          <Select
            isSearchable
            isMulti
            isClearable={false}
            controlShouldRenderValue={false}
            className="select-target radius-2"
            classNamePrefix="select-target-prefix"
            components={{
              DropdownIndicator: (props: any) => (
                <img src={downarrowblk} alt={t("dropdown arrow")} />
              ),
              IndicatorSeparator: () => null,
            }}
            onChange={(e: any) => {
              setSelectedButtonTarget({
                ...selectedButtonTarget,
                group_ids: e,
              });
            }}
            value={selectedButtonTarget.group_ids || []}
            // defaultValue={user.classes}
            placeholder={t("Classes")}
            options={[...props.classes]}
          />
        </div>

        {selectedButtonTarget.group_ids.map((classes: any, classIndex: number) => {
          return (
            <div
              className=" group-cross-tags d-flex align-items-center font-sm pl-2"
              key={"_class_" + classIndex}
            >
              <div className="font-600 font-16">{classes?.label}</div>
              <button
                type="button"
                className="btn ml-auto py-0"
                onClick={() => {
                  removeClassesOfAUser(classIndex);
                }}
              >
                <img src={closeRed} alt="" />
              </button>
            </div>
          );
        })}
        {/* <div className="group-cross-tags d-flex align-items-center clr-black font-sm pl-3">
          <div>Learner Group A</div>
          <button type="button" className="btn ml-auto py-0"><img src={closeRed} alt=""/></button>
        </div>
        <div className="group-cross-tags d-flex align-items-center clr-black font-sm pl-3">
          <div>Learner Group A</div>
          <button type="button" className="btn ml-auto py-0"><img src={closeRed} alt=""/></button>
        </div> */}

        <div className="mb-12 mt-3">
          <h3 className="mb-1">{t("Add by user email")}</h3>
          <Select
            isSearchable
            isMulti
            isClearable={false}
            controlShouldRenderValue={false}
            className="select-target radius-2"
            classNamePrefix="select-target-prefix"
            components={{
              DropdownIndicator: (props: any) => (
                <img src={downarrowblk} alt={t("dropdown arrow")} />
              ),
              IndicatorSeparator: () => null,
            }}
            onChange={(e: any) => {
              setSelectedButtonTarget({
                ...selectedButtonTarget,
                user_ids: e, // .map((d: any) => d._id)
              });
            }}
            value={selectedButtonTarget.user_ids || []}
            placeholder={t("Select Users")}
            options={users}
          />
        </div>

        {selectedButtonTarget.user_ids.map((classes: any, classIndex: number) => {
          return (
            <div
              className=" group-cross-tags d-flex align-items-center font-sm pl-2"
              key={"_class_" + classIndex}
            >
              <div className="font-600 font-16">{classes?.label}</div>
              <button
                type="button"
                className="btn ml-auto py-0"
                onClick={() => {
                  removeUsersOfAUser(classIndex);
                }}
              >
                <img src={closeRed} alt="" />
              </button>
            </div>
          );
        })}

      </ModalBody>
      <ModalFooter>
        <PermissionWrapper>
          <button
            disabled={loading}
            className="btn-outline-primary btn font-600 py-1"
            onClick={props.onHide}
          >
            {t("Cancel")}
          </button>
          <button
            className="btn btn-primary ml-3 font-600 py-1"
            onClick={createOrUpdateAgent}
            disabled={loading}
          >
            {props.editMode ? t("Update") : t("Add")}
          </button>
        </PermissionWrapper>
      </ModalFooter>
    </Modal>
  );
};

export default AddExistingUsersToGroup;
