export const customStyles = {
  header: {
    style: {
      minHeight: "56px",
    },
  },
  headRow: {
    style: {
      // borderRadius: "10px",
      backgroundColor: "#0923E6",
      borderBottomWidth: "0",
    },
  },
  expanderRow: {
    style: {
      // backgroundColor: '#0923E6',
    },
  },
  expanderCell: {
    style: {
      "&:hover": {
        backgroundColor: "transparent",
      },
      button: {
        backgroundColor: "transparent !important",
        padding: "9px 16px",
        // img: {
        //   // width: "10px",
        // },
      },
    },
  },
  expanderButton: {
    style: {
      backgroundColor: "transparent",
      alignItems: "center",
    },
  },
  headCells: {
    style: {
      "&:first-child": {
        borderTopLeftRadius: "18px",
      },
      "&:last-child": {
        borderTopRightRadius: "18px",
      },
      "&.chart--table-sort-icon": {
        marginLeft: "10px",
      },
      'input[type="checkbox"]': {
        width: "16px",
        height: "16px",
      },
      backgroundColor: "#0923E6",
      width: "250px",
      padding: "10px 10px",
      fontSize: "14px",
      color: "#fff",
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "#FFFFFF",
      },
      div: {
        color: "#fff",
      },
    },
  },
  cells: {
    style: {
      width: "100%",
      color: "#07032B",
      fontSize: "14px",
      padding: "10px 10px",
      'input[type="checkbox"]': {
        width: "16px",
        height: "16px",
      },
      alignItems: "start",
      p: {
        fontSize: "14px",
      },
    },
  },
  rows: {
    style: {
      width: "100%",
      minHeight: "auto",
      alignItems: "center",
    },
  },
};

export const expandedDocumentStyles = {
  header: {
    style: {
      minHeight: "56px",
    },
  },
  headRow: {
    style: {
      borderRadius: "10px",
      backgroundColor: "#E6EDFF",
      borderBottomWidth: "0",
    },
  },
  expanderRow: {
    style: {
      // backgroundColor: '#0923E6',
    },
  },
  expanderCell: {
    style: {
      "&:hover": {
        backgroundColor: "transparent",
      },
      button: {
        backgroundColor: "transparent !important",
        padding: "9px 16px",
      },
    },
  },
  expanderButton: {
    style: {
      backgroundColor: "transparent",
      alignItems: "center",
    },
  },
  headCells: {
    style: {
      "&.chart--table-sort-icon": {
        marginLeft: "10px",
      },
      'input[type="checkbox"]': {
        width: "16px",
        height: "16px",
      },
      backgroundColor: "#E6EDFF",
      padding: "10px 10px",
      fontSize: "14px",
      color: "#0E0748",
      borderRadius: "0px",
      fontWeight: "700",
      div: {
        color: "#0E0748",
      },
    },
  },
  cells: {
    style: {
      color: "#07032B",
      fontSize: "14px",
      padding: "10px 10px",
      'input[type="checkbox"]': {
        width: "16px",
        height: "16px",
      },
      p: {
        fontSize: "14px",
      },
      alignItems: "start",
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "#CDD5EF",
      },
    },
  },
};
