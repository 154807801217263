import React, { useEffect, useState } from "react";
import IconLabelButton from "../IconLabelButton";
import TextInput from "../TextInput";
import Popover from "react-awesome-popover";

import "./style.scss";

const QuizTableEditor = (props: any) => {
  const [horizontalHeader, setHorizontalHeader] = useState(
    props.horizontalHeaderValues ? props.horizontalHeaderValues : ["", "", ""]
  );
  const [verticalHeader, setVerticalHeader] = useState(
    props.verticalHeaderValues ? props.verticalHeaderValues : [""]
  );
  const [tableData, setTableData] = useState(
    props.tableValues
      ? props.tableValues
      : [
          { text: "", score: 1, keyword_scores: "" },
          { text: "", score: 1, keyword_scores: "" },
          { text: "", score: 1, keyword_scores: "" },
          { text: "", score: 1, keyword_scores: "" },
        ]
  );
  const [controlColumnCount, setControlColumnCount] = useState(
    props.columnCount
      ? props.columnCount
      : props.horizontalHeaderValues.length - 1
  );
  const [controlRowCount, setControlRowCount] = useState(
    props.verticalHeaderValues
      ? props.verticalHeaderValues.length
      : props.rowCount
  );

  useEffect(() => {
    if (props.horizontalHeaderValues.length === props.columnCount) {
      let temp = [...props.horizontalHeaderValues];
      temp.unshift("");
      setHorizontalHeader(temp);
    }
  }, []);

  useEffect(() => {
    !props.disableHeader && props.onHeaderChange(horizontalHeader);
  }, [horizontalHeader]);

  useEffect(() => {

    props.onColumnHeaderChange && props.onColumnHeaderChange(verticalHeader);
  }, [verticalHeader]);

  useEffect(() => {
  
    props.onAnswerKeyTableChange(
      tableData,
      controlRowCount,
      controlColumnCount
    );
  }, [tableData]);

  const onHorizontalHeaderCellChange = (value: any, headerIndex: any) => {
    let temp = [...horizontalHeader];
    temp[headerIndex] = value;
    setHorizontalHeader(temp);
  };

  const onVerticalHeaderCellChange = (value: any, headerIndex: any) => {
    let temp = [...verticalHeader];
    temp[headerIndex] = value;
    setVerticalHeader(temp);
  };

  const onRowCellChange = (value: any, index: any) => {
    let temp = [...tableData];
    temp[index].text = value;
    setTableData(temp);
  };

  const handleHorizontalHeaderCellPaste = (event: any, pastedHeaderIndex: any) => {
    let pastedContent = event.clipboardData.getData("text");
    let splitRows = pastedContent.split("\n");
    let horizontalHeaderRow = splitRows.shift();
    let pastedTableBodyData: any = [];
    if (splitRows.length && splitRows[0].split("\t").length > 1) {
      let verticalHeaderRow: any = [];
      splitRows.forEach((row: any, rowIndex: any) => {
        let splitCells = row.split("\t");
        if (pastedHeaderIndex === 0) {
          verticalHeaderRow.push(splitCells.shift());
        }
        if (rowIndex === 0 && splitCells.length) {
          setControlColumnCount(splitCells.length);
        }
        splitCells.forEach((cell: any) => {
          pastedTableBodyData.push({
            text: cell,
            score: 1,
            keyword_scores: "",
          });
        });
      });
      setTableData(pastedTableBodyData);
      setVerticalHeader(verticalHeaderRow);
    }
    if (
      horizontalHeaderRow.length &&
      horizontalHeaderRow.split("\t").length > 1
    ) {
      let updatedHorizontalHeader = pastedHeaderIndex === 0 ? [] : [""];
      let headerCells = horizontalHeaderRow.split("\t");
      headerCells.forEach((cell: any) => {
        updatedHorizontalHeader.push(cell);
      });
      setHorizontalHeader(updatedHorizontalHeader);
    }
  };

  const handleVerticalHeaderCellPaste = (event: any) => {
    let pastedContent = event.clipboardData.getData("text");
    let splitRows = pastedContent.split("\n");
    let pastedTableBodyData: any = [];
    if (splitRows.length && splitRows[0].split("\t").length > 1) {
      let verticalHeaderRow: any = [];
      splitRows.forEach((row: any, rowIndex: any) => {
        let splitCells = row.split("\t");
        verticalHeaderRow.push(splitCells.shift());
        if (rowIndex === 0 && splitCells.length) {
          setControlColumnCount(splitCells.length);
        }
        splitCells.forEach((cell: any) => {
          pastedTableBodyData.push({
            text: cell,
            score: 1,
            keyword_scores: "",
          });
        });
      });
      setTableData(pastedTableBodyData);
      setVerticalHeader(verticalHeaderRow);
    }
  };

  const handleRowCellPaste = (event: any) => {
    let pastedContent = event.clipboardData.getData("text");
    let splitRows = pastedContent.split("\n");
    let pastedTableBodyData: any = [];
    if (splitRows.length && splitRows[0].split("\t").length > 1) {
      splitRows.forEach((row: any, index: any) => {
        let splitCells = row.split("\t");
        if (index === 0 && splitCells.length) {
          let difference = splitCells.length - controlColumnCount;
          if (difference > 0) {
            let updatedHorizontalHeader = [...horizontalHeader];
            for (let i = 0; i < difference; i++) {
              updatedHorizontalHeader.push("");
            }
            setHorizontalHeader(updatedHorizontalHeader);
          }
          setControlColumnCount(splitCells.length);
        }
        splitCells.forEach((cell: any) => {
          pastedTableBodyData.push({
            text: cell,
            score: 1,
            keyword_scores: "",
          });
        });
      });
      setTableData(pastedTableBodyData);
    }
  };

  const onCellKeywordChange = (value: any, index: any) => {
    let temp = [...tableData];
    temp[index].keyword_scores = value;
    setTableData(temp);
  };

  const onCellScoreChange = (value: any, index: any) => {
    let temp = [...tableData];
    temp[index].score = value;
    setTableData(temp);
  };

  const addColumn = () => {
    let tempTableData = [...tableData];
    for (let i = 1; i <= controlRowCount; i++) {
      tempTableData.splice(i * controlColumnCount + (i - 1), 0, {
        text: "",
        score: 1,
        keyword_scores: "",
      });
    }
    setTableData(tempTableData);
    let tempHorizontalHeader = [...horizontalHeader];
    tempHorizontalHeader.push("");
    setHorizontalHeader(tempHorizontalHeader);
    setControlColumnCount((data: any) => data + 1);
  };

  const removeColumn = (targetColumn: any) => {
    let tempTableData = [...tableData];
    let startIndex = targetColumn - 1;
    for (let i = 1; i <= controlRowCount; i++) {
      tempTableData.splice(startIndex, 1);
      startIndex += controlColumnCount - 1;
    }
    setTableData(tempTableData);
    let tempHorizontalHeader = [...horizontalHeader];
    tempHorizontalHeader.splice(targetColumn, 1);
    setHorizontalHeader(tempHorizontalHeader);
    setControlColumnCount((data: any) => data - 1);
  };

  const addRow = (targetIndex: any) => {
    let tempTableData = [...tableData];
    let tempVerticalHeader = [...verticalHeader];
    if (targetIndex || targetIndex === 0) {
      for (
        let i = (targetIndex + 1) * controlColumnCount;
        i < controlColumnCount * (targetIndex + 2);
        i++
      ) {
        tempTableData.splice(i, 0, {
          text: "",
          score: 1,
          keyword_scores: "",
        });
      }
      tempVerticalHeader.splice(targetIndex + 1, 0, "");
    } else {
      for (let i = 1; i <= controlColumnCount; i++) {
        tempTableData.push({
          text: "",
          score: 1,
          keyword_scores: "",
        });
      }
      tempVerticalHeader.push("");
    }
    setTableData(tempTableData);
    setVerticalHeader(tempVerticalHeader);
    setControlRowCount((data: any) => data + 1);
  };

  const removeRow = (targetRow: any) => {
    let tempTableData = [...tableData];
    let startIndex = targetRow * controlColumnCount;
    for (let i = 1; i <= controlColumnCount; i++) {
      tempTableData.splice(startIndex, 1);
    }
    setTableData(tempTableData);
    let tempVerticalHeader = [...verticalHeader];
    tempVerticalHeader.splice(targetRow, 1);
    setVerticalHeader(tempVerticalHeader);
    setControlRowCount((data: any) => data - 1);
  };
  

  const divideArray = () => {
    let temp = [...tableData];
    const result = new Array(Math.ceil(temp.length / controlColumnCount))
      .fill(null)
      .map((_) => temp.splice(0, controlColumnCount));
      console.log("result",result);
      
    return result;
  };

  const getTableBody = () => {
    let body = [];
    body = divideArray().map((row, rowIndex: any) => (
      <tr className="quiz-table-row">
        <th className="quiz-table-header-cell">
          <TextInput
            disabled={props.viewOnly}
            placeholder="Row header text here"
            useTextArea
            value={verticalHeader[rowIndex]}
            inputChange={(event: any) =>
              onVerticalHeaderCellChange(event.target.value, rowIndex)
            }
            onPaste={handleVerticalHeaderCellPaste}
          />
        </th>
        {row.map((cell, cellIndex: any) => (
          <td className="quiz-table-row-cell">
            <TextInput
              disabled={props.viewOnly}
              useTextArea
              value={cell.text}
              inputChange={(event: any) =>
                onRowCellChange(
                  event.target.value,
                  cellIndex + controlColumnCount * rowIndex
                )
              }
              onPaste={handleRowCellPaste}
            />
            {props.scoredBy.value === "enhanced_keywords" && (
              <div className="quiz-table-cell-toolbar">
                <Popover
                  placement={"top-end"}
                  overlayColor={"rgba(0,0,0,0)"}
                  className="custom-popover"
                  arrowProps={{
                    className: "quiz-table-popover-arrow",
                  }}
                  arrow={false}
                >
                  {!props.viewOnly && 
                    <button
                      className="toolbar-btn cell-property-btn"
                      title="Edit keywords and score"
                    >
                      <i className="icon-edit-line toolbar-icon" />
                    </button>
                  }
                  <div className="popover-box">
                    <TextInput
                      disabled={props.viewOnly}
                      useTextArea
                      label="Keywords"
                      value={cell.keyword_scores}
                      inputChange={(event: any) =>
                        onCellKeywordChange(
                          event.target.value,
                          cellIndex + controlColumnCount * rowIndex
                        )
                      }
                    />
                    <div className="half-clearfix" />
                    <TextInput
                      disabled={props.viewOnly}
                      label="Score"
                      inputType="number"
                      value={cell.score}
                      inputChange={(event: any) =>
                        onCellScoreChange(
                          event.target.value,
                          cellIndex + controlColumnCount * rowIndex
                        )
                      }
                    />
                  </div>
                </Popover>
              </div>
            )}
          </td>
        ))}
        {!props.viewOnly && 
          <div className="quiz-table-row-toolbar">
            <button
              onClick={() => {
                addRow(rowIndex);
              }}
              className="toolbar-btn row-add-btn"
              title="Add row"
            >
              <i className="icon-add-simple toolbar-icon" />
            </button>
            {controlRowCount > 1 && (
              <button
                onClick={() => {
                  removeRow(rowIndex);
                }}
                className="toolbar-btn row-remove-btn"
                title="Remove row"
              >
                <i className="icon-delete toolbar-icon" />
              </button>
            )}
          </div>
        }
        
      </tr>
    ));
    return body;
  };

  return (
    <div className="quiz-table-master-container">
      <div className="quiz-table-wrapper">
        <table className="quiz-table">
          <thead className="quiz-table-horizontal-header">
            {horizontalHeader.map((value: any, index: any) => (
              <th className="quiz-table-header-cell">
                <TextInput
                  disabled={props.viewOnly}
                  placeholder="Column header text here"
                  value={value}
                  inputChange={(event: any) =>
                    onHorizontalHeaderCellChange(event.target.value, index)
                  }
                  onPaste={(event: any) =>
                    handleHorizontalHeaderCellPaste(event, index)
                  }
                />
                {index !== 0 && controlColumnCount > 1 && !props.viewOnly && (
                  <button
                    onClick={() => {
                      removeColumn(index);
                    }}
                    className="toolbar-btn column-remove-btn"
                    title="Remove column"
                  >
                    <i className="icon-delete toolbar-icon" />
                  </button>
                )}
              </th>
            ))}
          </thead>
          <tbody>{getTableBody()}</tbody>
        </table>
        {!props.viewOnly && 
          <IconLabelButton
            buttonClass="column-add-btn"
            onClick={addColumn}
            showIcon
            iconClass="icon-add-simple"
          />
        }
        
      </div>
      {/* <div className="half-clearfix" />
      <IconLabelButton
        onClick={() => addRow("")}
        showIcon
        iconClass="icon-add"
        buttonLabel="Add row"
      /> */}
    </div>
  );
};

export default QuizTableEditor;
