import { t } from "i18next";

const SessionDate = ({ row }: any) => {
  return <div>{row.session_date}</div>;
};

export const columns = () => {
  return [
    {
      name: t("Session"),
      selector: "session_date",
      sortable: true,
      cell: (row: any) => <SessionDate row={row} />,
    },
    {
      name: t("Email"),
      selector: "email",
      sortable: true,
      cell: (row: any) => <div>{row.email}</div>,
    },
    {
      name: t("Name"),
      selector: "name",
      sortable: true,
      cell: (row: any) => <div>{row.name}</div>,
    },
    {
      name: t("Tutorial Group"),
      selector: "tutorial_group",
      sortable: true,
      cell: (row: any) => <div>{row.tutorial_group}</div>,
    },
    {
      name: t("Assessment Name"),
      selector: "assessment_name",
      sortable: true,
      cell: (row: any) => <div>{row.assessment_name}</div>,
    },
    {
      name: t("Learner Score"),
      selector: "student_score",
      sortable: true,
      cell: (row: any) => <div>{row.student_score}</div>,
    },
    {
      name: t("Max Quiz Score"),
      selector: "assessment_total_score",
      sortable: false,
      cell: (row: any) => <div>{row.assessment_total_score}</div>,
    },
  ];
};

export const customStyles = {
  header: {
    style: {
      minHeight: "56px",
    },
  },
  headRow: {
    style: {
      borderRadius: "10px",
      backgroundColor: "#0923E6",
      borderBottomWidth: "0",
    },
  },
  expanderRow: {
    style: {
      // backgroundColor: '#0923E6',
    },
  },
  expanderCell: {
    style: {
      "&:hover": {
        backgroundColor: "transparent",
      },
      button: {
        backgroundColor: "transparent !important",
        img: {
          width: "10px",
        },
      },
    },
  },
  expanderButton: {
    style: {
      backgroundColor: "transparent",
      alignItems: "center",
    },
  },
  headCells: {
    style: {
      "&:first-child": {
        borderTopLeftRadius: "5px",
        borderBottomLeftRadius: "0px",
      },
      "&.chart--table-sort-icon": {
        marginLeft: "10px",
      },
      'input[type="checkbox"]': {
        width: "16px",
        height: "16px",
      },
      backgroundColor: "#0923E6",
      //width: '300px',
      padding: "20px 20px",
      fontSize: "14px",
      color: "#fff",
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "#FFFFFF",
      },
      div: {
        color: "#fff",
      },
      "&:nth-child(2)": {
        minWidth: "230px",
      },
      "&:nth-child(3)": {
        minWidth: "220px",
      },
      "&:nth-child(5)": {
        minWidth: "200px",
      },
    },
  },
  cells: {
    style: {
      color: "#07032B",
      fontSize: "14px",
      paddingTop: "10px",
      paddingBottom: "10px",
      'input[type="checkbox"]': {
        width: "16px",
        height: "16px",
      },
      "&:nth-child(2)": {
        minWidth: "230px",
      },
      "&:nth-child(3)": {
        minWidth: "220px",
      },
      "&:nth-child(5)": {
        minWidth: "200px",
      },
      "&:nth-child(4)": {
        div: {
          textOverflow: "ellipsis",
          display: " -webkit-box",
          WebkitLineClamp: "2",
          WebkitBoxOrient: "vertical",
        },
      },
    },
  },
};
