import React from "react";

import Equal from "../../../../../assets/images/editor/equal.png";
import Omega from "../../../../../assets/images/editor/omega.png";
import Sum from "../../../../../assets/images/editor/sum-of.png";
import Implies from "../../../../../assets/images/editor/implies.png";
import SquareRoot from "../../../../../assets/images/editor/square-root.png";
import Set from "../../../../../assets/images/editor/set.png";
import Bracket from "../../../../../assets/images/editor/bracket.png";

export const symbolSets: any = [
  {
    name: "General",
    thumbnail: SquareRoot,
  },
  {
    name: "Symbol",
    thumbnail: Equal,
  },
  {
    name: "Arrow",
    thumbnail: Implies,
  },
  {
    name: "Greek",
    thumbnail: Omega,
  },
  {
    name: "Brackets",
    thumbnail: Bracket,
  },
  {
    name: "Operator",
    thumbnail: Sum,
  },
];

export const symbolsData: any = {
  General: [
    "√",
    "x/y",
    "∛",
    "∜",
    "≠",
    "=",
    "<",
    ">",
    "≤",
    "≥",
    "-",
    "+",
    "x",
    "÷",
    "*",
    "/",
    "∓",
    "±",
    ".",
    "^",
    "%",
    "∈",
    "∉",
    "⊂",
    "∪",
    "∩",
    "∅",
    "∞",
    "π",
  ],
  Symbol: [
    "+",
    "-",
    "±",
    "∓",
    "x",
    "÷",
    "*",
    ".",
    "/",
    "∘",
    "^",
    "∖",
    ".",
    "&",
    "|",
    "∞",
    "π",
    "∅",
    "∂",
    "∝",
    "∆",
    "∇",
    "°",
    "'",
    "=",
    "≠",
    "~",
    "≈",
    "≡",
    "≅",
    "≄",
    "≆",
    "≇",
    "≉",
    "≌",
    "≁",
    "∀",
    "∃",
    "∄",
    "∈",
    "∉",
    "∊",
    "∋",
    "∌",
    "∍",
    "∟",
    "∠",
    "∡",
    "∢",
    "∩",
    "∪",
    "∴",
    "∵",
    "∶",
    "∷",
  ],
  Arrow: [
    "→",
    "←",
    "↦",
    "↑",
    "↓",
    "↔",
    "↚",
    "↛",
    "↠",
    "↣",
    "↮",
    "⇎",
    "⇵",
    "⇶",
    "⤡",
    "⤢",
    "⤣",
    "⤤",
    "⤥",
    "⤦",
    "↕",
    "↖",
    "↗",
    "↘",
    "↙",
    "↚",
    "↛",
    "↜",
    "↝",
    "↞",
    "↟",
    "↠",
    "↡",
    "↢",
    "↣",
    "↤",
    "↥",
    "↦",
    "↧",
    "↨",
    "↩",
    "↪",
    "↫",
    "↬",
    "↭",
    "↮",
    "↯",
    "↰",
    "↱",
    "↲",
    "↳",
    "↴",
    "↵",
    "↶",
    "↷",
    "↸",
    "↹",
    "↺",
    "↻",
    "↼",
    "↽",
    "↾",
    "↿",
    "⇀",
    "⇁",
    "⇂",
    "⇃",
    "⇄",
    "⇅",
    "⇆",
    "⇇",
    "⇈",
    "⇉",
    "⇊",
    "⇋",
    "⇌",
    "⇍",
    "⇎",
    "⇏",
    "⇐",
    "⇑",
    "⇒",
    "⇓",
    "⇔",
    "⇕",
    "⇖",
    "⇗",
    "⇘",
    "⇙",
    "⇚",
    "⇛",
    "⇜",
    "⇝",
    "⇞",
    "⇟",
    "⇠",
    "⇡",
    "⇢",
    "⇣",
    "⇤",
    "⇥",
    "⇦",
    "⇧",
    "⇨",
    "⇩",
    "⇪",
    "⇫",
    "⇬",
    "⇭",
    "⇮",
    "⇯",
    "⇰",
    "⇱",
    "⇲",
    "⇳",
    "⇴",
    "⇵",
    "⇶",
    "⇷",
    "⇸",
    "⇹",
    "⇺",
    "⇻",
    "⇼",
    "⇽",
    "⇾",
    "⇿",
  ],
  Greek: [
    "Α",
    "Β",
    "Γ",
    "Δ",
    "Ε",
    "Ζ",
    "Η",
    "Θ",
    "Ι",
    "Κ",
    "Λ",
    "Μ",
    "Ν",
    "Ξ",
    "Ο",
    "Π",
    "Ρ",
    "Σ",
    "Τ",
    "Υ",
    "Φ",
    "Χ",
    "Ψ",
    "Ω",
    "α",
    "β",
    "γ",
    "δ",
    "ϵ",
    "ζ",
    "η",
    "θ",
    "ι",
    "κ",
    "λ",
    "μ",
    "ν",
    "ξ",
    "ο",
    "π",
    "ρ",
    "σ",
    "τ",
    "υ",
    "ϕ",
    "χ",
    "ψ",
    "ω",
    "𝟘",
    "𝟙",
    "𝟚",
    "𝟛",
    "𝟜",
    "𝟝",
    "𝟞",
    "𝟟",
    "𝟠",
    "𝟡",
    "𝔸",
    "𝔹",
    "ℂ",
    "𝔻",
    "𝔼",
    "𝔽",
    "𝔾",
    "ℍ",
    "𝕀",
    "𝕁",
    "𝕂",
    "𝕃",
    "𝕄",
    "ℕ",
    "𝕆",
    "ℙ",
    "ℚ",
    "ℝ",
    "𝕊",
    "𝕋",
    "𝕌",
    "𝕍",
    "𝕎",
    "𝕏",
    "𝕐",
    "ℤ",
    "𝕒",
    "𝕓",
    "𝕔",
    "𝕕",
    "𝕖",
    "𝕗",
    "𝕘",
    "𝕙",
    "𝕚",
    "𝕛",
    "𝕜",
    "𝕝",
    "𝕞",
    "𝕟",
    "𝕠",
    "𝕡",
    "𝕢",
    "𝕣",
    "𝕤",
    "𝕥",
    "𝕦",
    "𝕧",
    "𝕨",
    "𝕩",
    "𝕪",
    "𝕫",
    "ℼ",
    "ℿ",
    "⅀",
  ],
  Brackets: [
    "[",
    "]",
    "{",
    "}",
    "(",
    ")",
    "[]",
    "{}",
    "()",
    "||",
    "﹙",
    "﹚",
    "（",
    "）",
    "﹛",
    "﹜",
    "｛",
    "｝",
    "〈",
    "〉",
    "〈",
    "〉",
    "〱",
    "︵",
    "︶",
    "︷",
    "︸",
    "《",
    "》",
    "﹝",
    "﹞",
    "〔",
    "〕",
    "【",
    "】",
    "︻",
    "︼",
    "﹤",
    "﹥",
    "＜",
    "＞",
    "‹",
    "›",
    "«",
    "»",
    "﹁",
    "﹂",
    "「",
    "」",
    "﹃",
    "﹄",
    "『",
    "』",
    "︿",
    "﹀",
    "︽",
    "︾",
    "︹",
    "︺",
    "〘",
    "〙",
    "〚",
    "〛",
    "〖",
    "〗",
  ],
  Operator: ["∏", "∐", "∑", "⋃", "⋂"],
};
