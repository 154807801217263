const ProgressSteps = (props: any) => {
  const steps = props.steps ? props.steps : 6;
  const renderList = () => {
    var list = [];
    for (var i = 0; i < steps; i++) {
      list.push(
        <li className={`${i < props.currentStep ? "active" : ""}`} key={i}></li>
      );
    }
    return list;
  };
  return (
    <>
      <ul className="list-unstyled progress-steps d-flex align-items-center justify-content-center mt-4">
        {renderList()}
      </ul>
    </>
  );
};

export default ProgressSteps;
