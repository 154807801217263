import {
    Form
  } from "react-bootstrap";
  
  import TextInput from "../../../components/UI/OldFormBuilder/TextInput/index";
  import Dropdown from "../../../components/UI/OldFormBuilder/Dropdown/index"
  import "../styles.scss";
  import { useTranslation } from "react-i18next";
  import { FormData } from '../IntegrationType'

  type FormProps = {
    integrateSpecificAgent: boolean;
    setTntegrateSpecificAgent: (value: boolean) => void;
    integrationType:string;
    formData:FormData;
    setFormData:(value: FormData) => void;
    handleInputChange:(event: React.ChangeEvent<HTMLInputElement>,name:string) => void;
    allAgents:any[]
  }

const CanvasConfigureForm = (props:FormProps) => {
    const { t } = useTranslation("translation");
  return (
    <div className="whit-box tw-px-4 right-content-wrap w-10">
      <div>
        <h2 className="mb-2">{t("Required fields for Noodle Factory")}</h2>
        <p className="mb-0">{t("As you complete each step, please fill up following values to set up the integration on Noodle Factory")}</p>
        <form>
          <div className="flex-one mt-2">
            <h3 className="mb-2">Canvas LMS URL</h3>
            <TextInput
              inputType="text"
              placeholder="Canvas LMS URL"
              autoFocus={false}
              id="client-id-1"
              value={props.formData?.LMS_URL}
              inputChange={(e, name) => props.handleInputChange(e, name)}
              max={undefined}
              min={undefined}
              tabIndex={undefined}
              label=""
              description=""
              inputClass=""
              name="LMS_URL"
              disabled={false}
              onKeyPress={() => { }}
              useTextArea={false}
              errorMessage="" ref={undefined} onPaste={undefined} onFocus={undefined} onBlur={undefined} onKeyUp={undefined} spellCheck={undefined} required={undefined} showError={undefined} />
          </div>
          <div className="flex-one mt-2">
            <h3 className="mb-2">Client ID</h3>
            <TextInput
              inputType="text"
              placeholder="Client ID"
              autoFocus={false}
              id="client-id-2"
              value={props.formData?.Client_ID}
              inputChange={(e, name) => props.handleInputChange(e, name)}
              max={undefined}
              min={undefined}
              tabIndex={undefined}
              label=""
              description=""
              inputClass=""
              name="Client_ID"
              disabled={false}
              onKeyPress={() => { }}
              useTextArea={false}
              errorMessage="" ref={undefined} onPaste={undefined} onFocus={undefined} onBlur={undefined} onKeyUp={undefined} spellCheck={undefined} required={undefined} showError={undefined} />
          </div>
          <div className="flex-one mt-2">
            <h3 className="mb-2">{props.integrationType === "walterEducator" ? "Client Secret" : "Deployment ID"}</h3>
            <TextInput
              inputType="text"
              placeholder={props.integrationType === "walterEducator" ? "Client Secret" : "Deployment ID"}
              autoFocus={false}
              id="client-id-3"
              value={props.integrationType === "walterEducator" ? props.formData?.Client_Secret : props.formData?.Deployment_ID}
              inputChange={(e, name) => props.handleInputChange(e, name)}
              max={undefined}
              min={undefined}
              tabIndex={undefined}
              label=""
              description=""
              inputClass=""
              name={props.integrationType === "walterEducator" ? "Client_Secret" : "Deployment_ID"}
              disabled={false}
              onKeyPress={() => { }}
              useTextArea={false}
              errorMessage="" ref={undefined} onPaste={undefined} onFocus={undefined} onBlur={undefined} onKeyUp={undefined} spellCheck={undefined} required={undefined} showError={undefined} />
          </div>
        </form>
      </div>
      {props.integrationType === "adminEducator" &&
        <>
          <div className="fOrm-box">
            <div className="tOggle-box">
              <div className="text mt-2"><h3>{t("Integrate to Specific Agent")}</h3></div>
              <button className="icon-button"><Form.Check // prettier-ignore
                className="custom-toggle font-weight-bold font-14 text-nowrap mt-1"
                type="switch"
                id="toggle-skip-summary"
                // label="Skip Summary Review"
                defaultChecked={props.integrateSpecificAgent}
                onChange={() => props.setTntegrateSpecificAgent(!props.integrateSpecificAgent)}
                value={String(props.integrateSpecificAgent)}
              /></button>
            </div>
            {props.integrateSpecificAgent &&
              <div className="flex-one mt-2">
                <Dropdown
                  placeHolder={`${t("Select Agent")}`}
                  label=""
                  defaultValue={undefined}
                  description=""
                  dropdownClassNamePrefix=""
                  isClearable={false}
                  isSearchable={false}
                  data={props.allAgents}
                  dropdownContainerClassName=""
                  onChange={(data: { value: string }) => props.setFormData({ ...props.formData, Agent_ID: data?.value })}
                  isMandatory={false}
                  value={undefined}
                  isDisabled={false}
                  menuPosition="bottom"
                  isMulti={undefined}
                  customStyles={undefined}
                  isNested={undefined}
                  onInputChange={() => { }}
                  isCreatable={undefined}
                  onCreateOption={undefined}
                  menuIsOpen={undefined}
                />
              </div>
            }
            <p>{t("Only enable this if you are using a single agent for all courses")}</p>
          </div>
        </>
      }
    </div>
  )
}

export default CanvasConfigureForm
