import React from "react";
import { useHistory } from "react-router";
import { UPDATE_LEARNER_GROUP } from "../../../config";

import { useTranslation } from "react-i18next";

function LearnerGroupTile({ item, deleteLearnerGroup, openDuplicateModal}: any) {
  const { t } = useTranslation('translation');
  const history = useHistory();
  return (
    <div className="containerClass" key={item._id}>
      <div className="header">
        <div>
          <h3 className="font-600 mb-1">
            {t('Group Name')}
          </h3>
          <h2 className="font-600">{item.name}</h2>
        </div>
        <div>
          <h3 className="font-600 mb-1">{t('Number Of Users')}</h3>
          <h4 className="font-weight-normal">{item.user_count} user(s)</h4>
        </div>
      </div>
      <div className="content">
        <h3>{t("Knowledge Group Access")}</h3>
        <div>
          {item.knowledge_groups && item.knowledge_groups.map((knowledgeItem: any, index: number) => {
            return (
              <div className="moduleWrapper" key={index}>
                {knowledgeItem.name}
              </div>
            );
          })}
          {
            (!item.knowledge_groups || item.knowledge_groups.length === 0) && item.access_all_knowledge && (
              <div className="moduleWrapper" >
                {t("All")}
              </div>
            )
          }
        </div>
        <div className="editContainer justify-content-end">
          <button
            className="btn btn-outline-primary font-weight-normal"
            onClick={() => openDuplicateModal && openDuplicateModal(item._id)}
          >
            {t("Duplicate")}
          </button>
          {!item.is_system_group && (
            <button
              className="btn btn-outline-primary font-weight-normal mx-2 py-1 py-md-2"
              onClick={() => history.push(UPDATE_LEARNER_GROUP.replace(':groupId', item._id))}
            >
              {t("Edit Learner Group")}
            </button>
          )}

          <button
            className="btn btn-outline-primary font-weight-normal"
            onClick={() =>
              history.push("/usergroup/class-access/learner/" + item._id)
            }
          >
            {t("Edit Access Rights")}
          </button>
          {!item.is_system_group && deleteLearnerGroup && (
            <button
              className="btn btn-outline-danger font-weight-normal mx-2 py-1 py-md-2"
              onClick={() => deleteLearnerGroup(item._id)}
            >
              {t("Delete Group")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default LearnerGroupTile;
