import React, { useEffect, useState } from "react";
import { Col, Image, Row } from "react-bootstrap";
import Select from "react-select";

import downarrowblk from "../../../assets/images/chevron-down-blk.svg";
import deleteicon from "../../../assets/New-images/delete-icon-blk.svg";

import { useDispatch, useSelector } from "react-redux";
import { HelperService } from "../../../services/helper.service";
import { ButtonsOptions } from "../../../services/interfaces/button-options.interface";
import { AllUserGroups } from "../../../store/questions/selector";

import { useTranslation } from "react-i18next";
import { getSectionsBasedOnDocumentId } from "../../../store/knowledge/actions";
import { updateQuestionBankLinkDocumentSections } from "../../../store/quizzes/actions";

interface Props {
  hideGroup?: boolean;
  buttonOptions: ButtonsOptions[];
  buttons: ButtonsOptions[];
  localUserQuestionUpdate: (type: string, data: ButtonsOptions[]) => void;
  onSelectKnowledge?: (id: string) => void;
  isActivityDetected?: (
    selectedButtonTarget: any,
    data: ButtonsOptions[]
  ) => void;
  addSectionSuccessCb?: any
}

const helperService = new HelperService();

export const QuizzesDocumentGroup = (props: any) => {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const [allDocuments, setAllDocuments] = useState([]);
  const allGroups = useSelector(AllUserGroups);
  const [buttonsList, setButtonOptionsList] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [knowledgeItems, setKnowledgeItems] = useState([
    { label: t("Question"), value: "intent" },
    { label: t("Form"), value: "form" },
    { label: t("Quiz"), value: "quiz" },
  ]);
  const [selectedButtonTarget, setSelectedButtonTarget] = useState({
    document: null,
    documentId: null,
    section: null,
    sections: [],
    sectionId: null,
    group_ids: null,
    label: null,
    name: null,
    reference: null,
    reference_id: null,
    type: "form",
    knowledgeType: null,
    buttonOptions: [],
  });
  const [showSearchModal, setShowSearchModal] = useState(false);
  const knowledgeItemsAsObj: any = {
    intent: { label: t("Question"), value: "intent" },
    form: { label: t("Form"), value: "form" },
    quiz: { label: t("Quiz"), value: "quiz" },
  };

  useEffect(() => {
    
    if (props.question && props.question.document_sections) {
      showSelectedButtonOptions();
    }
  }, [ props.question ]);

  useEffect(() => {
    setAllDocuments([
      ...props.allDocuments.map((d: any) => {
        return {
          ...d,
          label: d.name,
          value: d._id,
        };
      }),
    ]);
  }, [props.allDocuments]);

  const showSelectedButtonOptions  = async () => {
    const buttonOptions: any[] = [];
    for await (const document of props.question.document_sections) {
      const sections: any = await dispatch(
        getSectionsBasedOnDocumentId([document.document_id])
      );
      buttonOptions.push({
        ...selectedButtonTarget,
        document: {
          label: document.document_name,
          value: document.document_id,
        },
        documentId: document.document_id,
        section: {
          ...document,
          label: document.name,
          value: document._id,
        },
        sectionId: document._id,
        sections: sections.map((section: any) => {
          return {
            ...section,
            label: section.name,
            value: section._id,
          };
        }),
      });
    }
    setButtonOptionsList([...buttonOptions]);
  };

  const getButtonOptionsOnTheBasisOfType = (type: string = null) => {
    const data: any = [];
    return data;
  };

  const chooseKnowledgeHandler = async (e: any) => {
    const sections: any = await dispatch(getSectionsBasedOnDocumentId([e._id]));
    setSelectedButtonTarget({
      ...selectedButtonTarget,
      document: e,
      documentId: e._id,
      sections: sections,
    });
  };

  ///////// drag and drop function to arrange values  ///////////
  const applyDrag = (arr: any, dragResult: any) => {
    const { removedIndex, addedIndex, payload } = dragResult;
    if (removedIndex === null && addedIndex === null) return arr;

    const result = [...arr];
    let itemToAdd = payload;

    if (removedIndex !== null) {
      itemToAdd = result.splice(removedIndex, 1)[0];
    }

    if (addedIndex !== null) {
      result.splice(addedIndex, 0, itemToAdd);
    }
    // setButtonOptionsList([...result]);
    props.localUserQuestionUpdate("buttons", result);
    return result;
  };

  ///////// Change handlers for adding questions in loop ///////////
  const chooseSectionTargetHandler = (e: any) => {
    setSelectedButtonTarget({
      ...selectedButtonTarget,
      sectionId: e._id,
      section: e,
    });
  };

  const addSectionsToListHandler = async (e: any) => {
    e.preventDefault();
    if (selectedButtonTarget.sectionId && selectedButtonTarget.documentId) {
      const oldButtonsList = [...buttonsList];
      oldButtonsList.push(selectedButtonTarget);
      setButtonOptionsList([...oldButtonsList]);
      setSelectedButtonTarget({
        ...selectedButtonTarget,
        documentId: null,
        document: null,
        sections: [],
        section: null,
        sectionId: null,
        label: null,
        name: null,
        reference: null,
        reference_id: null,
      });

      if (props.documentId) {
        const res: any = await dispatch(
          updateQuestionBankLinkDocumentSections(props.documentId, {
            document_section_ids: oldButtonsList.map((l) => l.sectionId),
          })
        );
        props.addSectionSuccessCb && props.addSectionSuccessCb(oldButtonsList.map((sec: any)=> ({
          document_name: sec?.document?.label,
          document_id: sec?.documentId,
          name: sec?.section?.label,
          _id: sec?.documentId
        })))  
      }
    }
  };

  ///////// Change handlers from loop ///////////
  const chooseSelectedSectionHandler = (e: any, index: number) => {
    const oldButtonsList = [...buttonsList];
    oldButtonsList[+index].sectionId = e._id;
    oldButtonsList[+index].section = e;
    setButtonOptionsList([...oldButtonsList]);
    if (props.documentId) {
      dispatch(
        updateQuestionBankLinkDocumentSections(props.documentId, {
          document_section_ids: oldButtonsList.map((l) => l.sectionId),
        })
      );
    }
  };

  const chooseSelectedKnowledgeHandler = async (e: any, index: number) => {
    const sections: any = await dispatch(getSectionsBasedOnDocumentId([e._id]));
    const oldButtonsList = [...buttonsList];
    oldButtonsList[+index].document = e;
    oldButtonsList[+index].documentId = e._id;
    oldButtonsList[+index].sections = sections;
    setButtonOptionsList([...oldButtonsList]);
    if (props.documentId) {
      dispatch(
        updateQuestionBankLinkDocumentSections(props.documentId, {
          document_section_ids: oldButtonsList.map((l) => l.sectionId),
        })
      );
    }
  };

  const deleteButtonOptionsHandler = async (index: number) => {
    const oldButtonsList = [...buttonsList];
    oldButtonsList.splice(index, 1);
    setButtonOptionsList([...oldButtonsList]);
    if (props.documentId) {
      await dispatch(
        updateQuestionBankLinkDocumentSections(props.documentId, {
          document_section_ids: oldButtonsList.map((l) => l.sectionId),
        })
      );
      props.addSectionSuccessCb && props.addSectionSuccessCb(oldButtonsList.map((sec: any)=> ({
        document_name: sec?.document?.label,
        document_id: sec?.documentId,
        name: sec?.section?.label,
        _id: sec?.documentId
      })))      }
  };

  return (
    <>
      {/* --------------------------------------- Buttons list wrapper -------------------------------------------------- */}
      <div className="form-group mb-0">
        <div className="d-flex align-items-center justify-content-between mb-2"></div>
        <Row className="no-gutters mb-4 align-items-center">
          <Col
            md={5}
            className="pr-md-2 mb-2 mb-md-0 d-flex align-items-center"
          >
            <Select
              isSearchable={false}
              // menuIsOpen
              className="select-target w-100 quiz-select"
              classNamePrefix="select-target-prefix"
              components={{
                DropdownIndicator: () => (
                  <img src={downarrowblk} alt={t("dropdown arrow")} />
                ),
                IndicatorSeparator: () => null,
                ClearIndicator: () => null,
              }}
              options={[...allDocuments]}
              placeholder={t("Document")}
              //   getOptionValue={(option: any) => option["value"]}
              //   getOptionLabel={(option: any) => option["label"]}
              onChange={chooseKnowledgeHandler}
              value={
                selectedButtonTarget.document
                  ? selectedButtonTarget.document
                  : null
              }
            />
          </Col>

          <Col sm={6} md={6} className="pr-md-2 mb-2 mb-md-0">
            <Select
              isSearchable={false}
              // defaultMenuIsOpen={true}
              className="select-target w-100 quiz-select"
              classNamePrefix="select-target-prefix"
              components={{
                DropdownIndicator: () => (
                  <img src={downarrowblk} alt={t("dropdown arrow")} />
                ),
                IndicatorSeparator: () => null,
                ClearIndicator: () => null,
                // Menu: CustomMenu,
                // MenuList: SelectMenuButton,
              }}
              options={
                selectedButtonTarget && selectedButtonTarget.sections.length
                  ? [...selectedButtonTarget.sections]
                  : []
              }
              placeholder={t("Section")}
              onChange={chooseSectionTargetHandler}
              value={
                selectedButtonTarget && selectedButtonTarget.section
                  ? selectedButtonTarget.section
                  : null
              }
            />
          </Col>
          <Col sm={1} md={1}>
            <button
              className="btn font-12 py-0 clr-primary font-weight-bold ml-auto"
              onClick={addSectionsToListHandler}
            >
              {t("Add")}
            </button>
          </Col>
        </Row>
      </div>

      {/* <Container
        dragHandleSelector=".column-drag-handle"
        onDrop={(e) => applyDrag(buttonsList, e)}
      > */}
      {buttonsList.map((buttonsOption: any, index: number) => {
        return (
          // <Draggable
          //   key={"draggable_" + buttonsOption._id + "_i_" + index}
          // >
          <Row className="no-gutters mb-4 align-items-center">
            <Col
              md={6}
              className="pr-md-2 mb-2 mb-md-0 d-flex align-items-center"
            >
              {/* <button className="btn py-0 column-drag-handle">
                    <img src={dragIcon} alt="" />
                  </button> */}
              <Select
                key={
                  "select_ref_knowleedge_" + buttonsOption._id + "_i_" + index
                }
                isSearchable={false}
                className="select-target w-100 quiz-select"
                classNamePrefix="select-target-prefix"
                components={{
                  DropdownIndicator: () => (
                    <img src={downarrowblk} alt={t("dropdown arrow")} />
                  ),
                  IndicatorSeparator: () => null,
                  ClearIndicator: () => null,
                }}
                options={[...allDocuments]}
                placeholder={"Knowledge"}
                onChange={(e: any) => {
                  chooseSelectedKnowledgeHandler(e, index);
                }}
                value={buttonsOption.document ? buttonsOption.document : null}
              />
            </Col>
            <Col sm={5} md={5} className="pr-md-2 mb-2 mb-md-0">
              <Select
                key={"select_ref_" + buttonsOption._id + "_i_" + index}
                isSearchable={false}
                className="select-target w-100 quiz-select"
                classNamePrefix="select-target-prefix"
                components={{
                  DropdownIndicator: () => (
                    <img src={downarrowblk} alt={t("dropdown arrow")} />
                  ),
                  IndicatorSeparator: () => null,
                  ClearIndicator: () => null,
                }}
                options={buttonsOption.sections}
                placeholder={t("Section")}
                onChange={(e) => chooseSelectedSectionHandler(e, index)}
                value={buttonsOption.section || null}
              />
            </Col>
            <Col sm={1} md={1}>
              <button
                className="btn font-12 py-0 clr-primary font-weight-bold ml-auto"
                onClick={() => deleteButtonOptionsHandler(index)}
              >
                <Image src={deleteicon} />
              </button>
            </Col>
          </Row>
          // </Draggable>
        );
      })}
      {/* </Container> */}
    </>
  );
};

export default QuizzesDocumentGroup;
