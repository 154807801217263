/* eslint-disable jsx-a11y/anchor-is-valid */
import { default as classNames, default as cx } from "classnames";
import * as _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import {
  Accordion,
  AccordionContext,
  Card,
  Col,
  Dropdown,
  Row,
  useAccordionToggle,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import emptyKnowledge from "../../../assets/New-images/empty-knowledge.svg";
import sortIcon from "../../../assets/New-images/sort-blue-icon.svg";
import {
  KNOWLEDGE_KNOWLEDGE_CREATE_FROM_SCRATCH,
  MANAGEKNOWLEDGEGROUP,
} from "../../../config";
import "../../../routes/Knowledge/AllKnowledge/AllKnowledge.scss";
import { getCommonState } from "../../../store/common/selector";
import {
  bulkAssignModules,
  bulkSuggestKnowledgeByIds,
  deleteKnowledgeById,
  deleteKnowledgeGroup,
  getAllDocumentsNewFlow,
  getAllKnowledge,
  getAllLevels,
  getAllModulesFromYear,
  getUnansweredQuestionCount,
  suggestKnowledgeById,
} from "../../../store/knowledge/actions";
import {
  isAllKnowledgeError,
  isAllKnowledgeLoading,
  isAllKnowledgeSuccess,
  isTopicsLoading,
} from "../../../store/knowledge/selector";
import { getAllUserGroupOptions } from "../../../store/notifications/actions";
import { getAllIntents as getAllIntentsSelector } from "../../../store/questions/selector";
import Pagination from "../Pagination/Pagination";

import { useTranslation } from "react-i18next";

function KnowledgeSearchModalComponent(props: any) {
  const { t } = useTranslation("translation");
  const commonState = useSelector(getCommonState);
  const history = useHistory();
  const useLocatio = useLocation();
  const dispatch = useDispatch();
  const [perPage, setPerPage] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const allKnowledgeSuccess = useSelector(isAllKnowledgeSuccess);
  const allKnowledgeLoading = useSelector(isAllKnowledgeLoading);
  const topicsLoading = useSelector(isTopicsLoading);
  const allKnowledgeError = useSelector(isAllKnowledgeError);
  const allIntents = useSelector(getAllIntentsSelector);

  const [suggestionMode, setSuggestionMode] = useState(false);
  const [scrollRight, setScrollRight] = useState(0);
  const [activeKey, setActiveKey] = useState(null);
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [modules, setModules] = useState([]);
  const [showRenameModal, setShowRenameModal] = React.useState(false);
  const [selectedParentModule, setSelectedParentModule] = useState<any>();
  const [topicIds, setTopicIds] = useState("");
  const [selectedKnowledge, setSelectedKnowledge] = useState(null);

  useEffect(() => {
    if (!topicsLoading) {
      // dispatch(getAllTopics());
      // dispatch(getAllIntents());
      dispatch(getAllUserGroupOptions()); // user groups
    }
    setSelectedNodes([]);
    setHasTopLevelApplied(false);
    getAllYearsFun();
  }, []);

  const isBranchUnderSelectedModule = (level: any) => {
    if (!selectedParentModule) {
      return false;
    }

    const findParent = (module: any) => {
      const parent = modules.find((m) => m._id === module.parent_id);
      return parent;
    };

    let parent = findParent(level);

    while (parent) {
      if (parent._id === selectedParentModule._id) {
        return true;
      }
      parent = findParent(parent);
    }
    return false;
  };

  const getAllYearsFun = async (loadSubModules: boolean = true) => {
    try {
      const response: any = await dispatch(getAllLevels());
      setYears([...response]);

      const query = new URLSearchParams(useLocatio.search);
      const parent = query.get("parent");
      if (parent) {
        const parentVal = response.find((par: any) => par._id === parent);
        if (parentVal) {
          getAllModulesFromYearFun(parentVal);
        } else {
          response &&
            response.length > 0 &&
            getAllModulesFromYearFun(selectedYear ? selectedYear : response[0]);
        }
      } else {
        response &&
          response.length > 0 &&
          getAllModulesFromYearFun(selectedYear ? selectedYear : response[0]);
      }
    } catch (error: any) {
      setYears([]);
    }
  };

  const getAllModulesFromYearFun = async (year: any) => {
    setSelectedYear(year);
    try {
      const query = new URLSearchParams(useLocatio.search);
      const group = query.get("group");
      const response: any = await dispatch(getAllModulesFromYear(year._id));
      setModules([...response]);
      if (group) {
        setSelectedGroupId(group);
      } else {
        if (response && response.length > 0) {
          onSelectGroupId(response[0].value);
          if (response[0].depth === 0) {
            setSelectedParentModule(response[0]);
          }
        } else {
          onSelectGroupId(year._id);
        }
      }
    } catch (error: any) {
      setModules([]);
    }
  };

  const [deleteModuleModalShow, setDeleteModuleModalShow] = useState(false);
  const [deletedModuleId, setDeletedModuleId] = useState([]);
  const deleteModuleModalHandler = () => {
    setDeleteModuleModalShow(!deleteModuleModalShow);
  };
  const confirmDeleteModuleHandler = async (e: any, deleteId: string) => {
    try {
      const response: any = await dispatch(deleteKnowledgeGroup(deleteId));
      getAllYearsFun(false);
      deleteModuleModalHandler();
    } catch (error: any) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    setTotalRows(allKnowledgeSuccess?.pagination?.total_items);
  }, [allKnowledgeSuccess]);
  /**
   *
   * @param page Pagination on change of page
   */
  const handlePageChange = (page: number) => {
    // e.preventDefault();
    // console.log('[[[[[ DISPATCHED ]]]]]', currentPage, page)
    setCurrentPage(page);
    // console.log('in')
    dispatch(
      getAllKnowledge(
        3,
        page,
        perPage,
        query,
        topicIds,
        {},
        selectedNodes.length > 0,
        props.documentId,
        selectedGroupId,
        hasTopLevelApplied
      )
    );
  };
  /**
   * Used to paginate data based on per page and page number
   * @param newPerPage
   * @param page
   */
  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
    // console.log('in')
    dispatch(
      getAllKnowledge(
        3,
        page,
        newPerPage,
        query,
        topicIds,
        {},
        selectedNodes.length > 0,
        props.documentId,
        selectedGroupId,
        hasTopLevelApplied
      )
    );
  };

  /**
   * Set debounce time for while searching in any input
   */
  const [query, setQuery] = useState("");
  const [isTyping, setTyping] = useState(false);
  useEffect(() => {
    // Added 500 as debounce time will wait for 500 miliseconds and then we will fetch intents based on query
    const delayDebounceFn = setTimeout(() => {
      if (isTyping) {
        setCurrentPage(1);
        // console.log('in')
        dispatch(
          getAllKnowledge(
            3,
            1,
            perPage,
            query,
            topicIds,
            {},
            selectedNodes.length > 0,
            props.documentId,
            selectedGroupId ? selectedGroupId : null,
            query && query.length > 0 ? true : false
          )
        );
        setTyping(false);
      }
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [query]);

  const onInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setQuery(e.target.value);
    setTyping(true);
  };
  /**
   * State to manage tags | categories as Nodes | Tags whatever we can say
   */
  const [selectedNodes, setSelectedNodes] = useState([]);

  /**
   * Used to sort data using API
   * @param param0
   * @param direction
   */
  const onSortHandler = (selector: any, direction: string) => {
    // For now we are allowing server side sorting on above two fields
    // console.log('in')
    dispatch(
      getAllKnowledge(
        3,
        currentPage,
        perPage,
        query,
        topicIds,
        {
          sort_field: selector === "name" ? "question" : selector,
          sort_order: direction === "desc" ? "dsc" : "asc",
        },
        selectedNodes.length > 0,
        props.documentId,
        selectedGroupId,
        hasTopLevelApplied
      )
    );
  };

  /**
   * Used to sort data using API
   * @param param
   * @param direction
   */
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [hasTopLevelApplied, setHasTopLevelApplied] = useState(true);

  useEffect(() => {
    // if (allKnowledgeLoading) {
    //   return
    // }
    if (!selectedGroupId) {
      return;
    }

    dispatch(
      getAllKnowledge(
        3,
        currentPage,
        perPage,
        query,
        "",
        {},
        selectedNodes.length > 0,
        props.documentId,
        !props.documentId ? selectedGroupId : null,
        hasTopLevelApplied
      )
    );
  }, [selectedGroupId]);

  const onSelectGroupId = (level: string) => {
    // For now we are allowing server side sorting on above two fields
    setSelectedGroupId(level);
  };

  /**
   * Custom sort function
   * @param rows
   * @param selector
   * @param direction
   * @returns
   */
  const customSort = (rows: any[], selector: string, direction: string) => {
    return rows;
  };

  const resetSuggestionView = () => {
    setSuggestionMode(!suggestionMode);
  };

  const btnLeftRightHandler = (e: any) => {
    const el = document.getElementsByClassName("table-wraper");
    let scrollLeft = el[0].scrollLeft;
    if (e.target.dataset.pos === "right") {
      el[0].scrollLeft += 200;
      setScrollRight((scrollLeft += 200));
    } else {
      el[0].scrollLeft -= 200;
      setScrollRight((scrollLeft -= 200));
    }
  };

  // Show bulk assign category modal
  const [bulkAssignCategoryShow, setBulkAssignCategoryShow] = useState(false);
  const [bulkCategoryIds, setBulkCategoryIds] = useState([]);
  const BulkAssignCategoryhandler = (value: boolean) => {
    setBulkAssignCategoryShow(value);
  };
  const bulkAssignCategoryKnowledgeHandler = (id: string[]) => {
    setBulkCategoryIds(id);
    setBulkAssignCategoryShow(true);
  };

  const confirmCategoryUpdateHandler = async (selectedOption: any) => {
    try {
      BulkAssignCategoryhandler(false);
      await dispatch(bulkAssignModules(selectedOption, selectedNodes));
      setSelectedNodes([]);
      getAllYearsFun();
    } catch (error: any) {
      setSelectedNodes([]);
    }
  };

  const addToSelectedRows = (event: any, id: string) => {
    let data: any[] = [...selectedNodes];
    if (event.target.checked) {
      const isPresent = data.indexOf(id);
      console.log(isPresent);
      if (isPresent < 0) {
        data.push(id);
      }
    } else {
      const oldData = [...selectedNodes];
      const index = oldData.indexOf(id);
      oldData.splice(index, 1);
      data = [...oldData];
    }
    setSelectedNodes([..._.uniq(data)]);
  };

  const onSelectAllRows = (event: any) => {
    if (event.target.checked) {
      if (
        allKnowledgeSuccess &&
        allKnowledgeSuccess.intents &&
        allKnowledgeSuccess.intents.length > 0
      ) {
        let data: any[] = [
          ...allKnowledgeSuccess.intents.map((intent: any) => intent._id),
        ];
        setSelectedNodes([..._.uniq(data)]);
      }
    } else {
      setSelectedNodes([]);
    }
  };

  const ContextAwareToggle = ({
    children,
    eventKey,
    callback,
    is_suggested,
    breadcrumbs,
  }: any) => {
    const currentEventKey = useContext(AccordionContext);
    setActiveKey(currentEventKey);
    const decoratedOnClick = useAccordionToggle(eventKey, () => {});
    return (
      <>
        <input
          type="checkbox"
          className="mr-2"
          onChange={(e) => {
            addToSelectedRows(e, eventKey);
          }}
          checked={selectedNodes.indexOf(eventKey) >= 0}
        />

        <span
          className={`${is_suggested ? "is_suggested" : ""}`}
          onClick={decoratedOnClick}
        >
          {children}
          <small className="d-block k-breadcrumb">
            {breadcrumbs.map((b: any) => b.name).join("/")}
          </small>
        </span>
      </>
    );
  };

  // get module names
  const getLevelNames = (levels: string[]) => {
    let moduleAsString: string[] = [];
    levels.forEach((level) => {
      const moduleObj = modules.find((m: any) => m.value === level);
      if (moduleObj) {
        moduleAsString.push(moduleObj.label);
      }
    });
    return moduleAsString.length === 0
      ? "Uncategorised"
      : moduleAsString.join(", ");
  };

  const knowledgeHtml = (
    <>
      <div className="all-knowledge-search d-flex flex-wrap align-items-center mb-2">
        <Dropdown className="mr-2 sort-dropdown">
          <Dropdown.Toggle
            className="btn btn-outline-primary font-600 py-1 my-1 "
            variant="default"
            id="dropdown-basic"
          >
            <img src={sortIcon} alt="" className="mr-2" />
            {t("Sort")}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => {
                onSortHandler("updated_at", "desc");
              }}
            >
              {t("Most Recent")}
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                onSortHandler("name", "asc");
              }}
            >
              {t("Ascending")}
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                onSortHandler("name", "desc");
              }}
            >
              {t("Descending")}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <div className="web-links my-0 mr-0">
          <input
            type="text"
            className="form-control h-auto"
            placeholder={t("Search for Questions")}
            onChange={onInputChangeHandler}
          />
        </div>
        <button
          className={classNames({
            "btn ml-auto font-600 radius-8": true,
            "btn-light": !selectedKnowledge,
            "btn-primary": selectedKnowledge,
          })}
          disabled={!selectedKnowledge}
          onClick={() => {
            props.knowledgeConfirmHandler(selectedKnowledge);
          }}
        >
          {t("Select Knowledge")}
        </button>
        {/* <PermissionWrapper>

          <Dropdown className="sort-dropdown ml-auto">
            <Dropdown.Toggle
              className="btn btn-outline-primary font-600 border-0 py-1"
              variant="default"
              id="dropdown-basic"
            >
              {t("+ Add")}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  history.push(
                    KNOWLEDGE_KNOWLEDGE_CREATE_FROM_SCRATCH +
                      (!hasTopLevelApplied
                        ? `?group=${selectedGroupId}&parent=${selectedYear._id}`
                        : "")
                  );
                }}
              >
                {t("Create from Scratch")}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  history.push(
                    KNOWLEDGE +
                      (!hasTopLevelApplied
                        ? `?group=${selectedGroupId}&parent=${selectedYear._id}`
                        : "")
                  );
                }}
              >
                {t("Create from Document")}
              </Dropdown.Item>
              
            </Dropdown.Menu>
          </Dropdown>
        </PermissionWrapper> */}
      </div>
      <div className="d-flex flex-wrap justify-content-between align-items-center mt-1">
        <div className="d-flex align-items-center">
          {/* <div className="custom-checkbox mr-3">
            <input
              type="checkbox"
              className="mr-2"
              id="select-all"
              checked={
                allKnowledgeSuccess &&
                allKnowledgeSuccess.intents &&
                allKnowledgeSuccess.intents.length > 0 &&
                selectedNodes.length === allKnowledgeSuccess.intents.length
              }
              onChange={onSelectAllRows}
            />
            <label htmlFor="select-all" className="mb-0">
              {t("Select all")}
            </label>
          </div> */}
          {/* {selectedNodes && selectedNodes.length > 0 && (
            <PermissionWrapper>
              <button
                className="btn btn-suggest py-0 font-sm"
                onClick={(e) => suggestBulkKnowledgeHandler(e, selectedNodes)}
              >
                {t("Suggest Selected")}
              </button>

              <button
                className="btn btn-suggest py-0 font-sm"
                onClick={(e) =>
                  bulkAssignCategoryKnowledgeHandler(selectedRows)
                }
              >
                {t("Move Selected")}
              </button>
              <button
                className="btn btn-outline-danger border-0 py-0 font-600 font-sm"
                onClick={() => {
                  setDeletedRowId(selectedNodes);
                  setShowDeleteConfirmationModal(true);
                }}
              >
                {t("Delete Selected")}
              </button>
            </PermissionWrapper>
          )} */}
        </div>
      </div>
      <Accordion
        defaultActiveKey={
          allKnowledgeSuccess && allKnowledgeSuccess.intents.length > 0
            ? allKnowledgeSuccess.intents[0]._id
            : null
        }
        className="accordion-viewby mt-3 scroll-wrap"
      >
        {allKnowledgeSuccess &&
          allKnowledgeSuccess.intents &&
          allKnowledgeSuccess.intents.map((knowledge: any, index: number) => {
            return (
              <Card
                className={cx({
                  active: activeKey === "0" || knowledge._id === selectedKnowledge,
                  notactive: activeKey !== "0",
                })}
                key={knowledge?._id}
                onClick={() => {
                  setSelectedKnowledge(knowledge._id);
                }}
              >
                <Card.Header>
                  <span
                    className={`${
                      knowledge.is_suggested ? "is_suggested" : ""
                    }`}
                  >
                    {knowledge.questions && knowledge.questions.length > 0
                      ? knowledge.questions[knowledge.questions.length - 1].text
                      : ""}
                    <small className="d-block k-breadcrumb">
                      {knowledge.breadcrumbs &&
                        knowledge.breadcrumbs.length > 0 &&
                        knowledge.breadcrumbs.map((b: any) => b.name).join("/")}
                    </small>
                  </span>
                </Card.Header>
              </Card>
            );
          })}
      </Accordion>

      {/* empty state */}
      {allKnowledgeSuccess &&
        allKnowledgeSuccess.intents.length === 0 &&
        !props.manageDocument && (
          <div className="empty-knowledge text-center my-5">
            <h3 className="h6 clr-grey-txt7 mb-3">
              {t("You have yet to create knowledge")}
            </h3>
            <figure className="h-auto">
              <img src={emptyKnowledge} alt="" />
            </figure>
            <div className="clr-primary font-weight-bold">
              {/* Drop a document here <span className="clr-grey-txt7">or</span>{" "} <br /> */}
              <Link
                to={
                  KNOWLEDGE_KNOWLEDGE_CREATE_FROM_SCRATCH +
                  (!hasTopLevelApplied
                    ? `?group=${selectedGroupId}&parent=${
                        selectedYear ? selectedYear._id : ""
                      }`
                    : "")
                }
              >
                {t("Click here to get started")}
              </Link>
            </div>
          </div>
        )}

      {allKnowledgeSuccess &&
        allKnowledgeSuccess.pagination &&
        allKnowledgeSuccess.intents.length > 0 && (
          <Pagination
            showScrollButtons={false}
            scrollRightValue={scrollRight}
            scrollWrapper={"table-wraper"}
            currentPage={currentPage}
            pagination={allKnowledgeSuccess.pagination}
            paginationPerPage={perPage}
            pageChange={handlePerRowsChange}
            onChangePage={handlePageChange}
          />
        )}
    </>
  );

  if (allKnowledgeLoading && !allKnowledgeError) {
    // return <Loading />;
  }

  return (
    <>
      <>
        <div>
          <div className="levels-row mb-4 d-flex align-items-center flex-wrap">
            <h2 className="mb-0 mr-3 font-1">{t("Level:")}</h2>
            {years.map((year: any) => {
              return (
                <button
                  className={classNames({
                    "btn btn-tabs ": true,
                    active: selectedYear && year._id === selectedYear._id,
                  })}
                  onClick={() => {
                    setHasTopLevelApplied(true);
                    getAllModulesFromYearFun(year);
                    setSelectedKnowledge(null)
                  }}
                >
                  {year?.name}
                </button>
              );
            })}
            {/* {selectedYear &&
              selectedYear._id &&
              selectedYear &&
              selectedYear.group_type !== "system" && (
                <button
                  className="btn btn-link"
                  onClick={() => {
                    history.push(
                      MANAGEKNOWLEDGEGROUP + "?level=" + selectedYear._id
                    );
                  }}
                >
                  + Edit
                </button>
              )} */}
          </div>
          <Row className="mb-4 position-relative">
            {selectedYear && selectedYear.group_type !== "system" && (
              <Col lg={3} md={4} className="knowledge-modules mb-4 mb-md-0">
                <div className="knowledge-box p-0 build-knowbox text-left d-flex flex-column">
                  <div className="d-flex justify-content-between align-items-center px-3 py-2">
                    <h2 className="mb-0 font-1">{t("Modules")}</h2>
                    {/* <button
                        className="btn btn-link font-sm px-0 py-1"
                        onClick={() => {
                          updateParentHandler(
                            null,
                            selectedYear._id,
                            false,
                            -1
                          );
                        }}
                      >
                        {t("+ Add")}
                      </button> */}
                  </div>
                  {modules.length > 0 && (
                    <div className="modules-list flex-grow-1 scroll-wrap">
                      <ul className="list-unstyled">
                        {modules.map((level: any) => {
                          return (
                            <li
                              onClick={() => {
                                if (level.depth === 0) {
                                  setSelectedParentModule(level);
                                  setSelectedKnowledge(null)
                                }
                              }}
                              key={
                                "child_padding_dev_" +
                                level.value +
                                "_" +
                                Math.floor(Math.random() * 9999)
                              }
                              style={{
                                paddingLeft: `${
                                  level.depth > 0 ? level.depth * 10 + 10 : 10
                                }px`,
                                display: `${
                                  isBranchUnderSelectedModule(level) ||
                                  level.depth === 0
                                    ? "block"
                                    : "none"
                                }`,
                              }}
                              className={classNames({
                                "clr-grey-txt": level.depth > 0,
                                active: selectedGroupId === level.value,
                              })}
                            >
                              <a
                                href="javascript:void(0);"
                                onClick={() => {
                                  setSelectedNodes([]);
                                  setHasTopLevelApplied(false);
                                  onSelectGroupId(level.value);
                                }}
                                className={classNames({
                                  "clr-grey-txt": level.depth > 0,
                                })}
                              >
                                {level?.label}
                              </a>
                              {/* <Dropdown className="ml-auto action-dropdown">
                                <Dropdown.Toggle
                                  variant="default"
                                  id="dropdown-basic"
                                >
                                  <svg
                                    width="14"
                                    height="4"
                                    viewBox="0 0 14 4"
                                    fill="none"
                                  >
                                    <path
                                      d="M2 3.5C1.17157 3.5 0.5 2.82843 0.5 2C0.5 1.17157 1.17157 0.5 2 0.5C2.82843 0.5 3.5 1.17157 3.5 2C3.5 2.82843 2.82843 3.5 2 3.5ZM7 3.5C6.17157 3.5 5.5 2.82843 5.5 2C5.5 1.17157 6.17157 0.5 7 0.5C7.82843 0.5 8.5 1.17157 8.5 2C8.5 2.82843 7.82843 3.5 7 3.5ZM12 3.5C11.1716 3.5 10.5 2.82843 10.5 2C10.5 1.17157 11.1716 0.5 12 0.5C12.8284 0.5 13.5 1.17157 13.5 2C13.5 2.82843 12.8284 3.5 12 3.5Z"
                                      fill="#6B7085"
                                    />
                                  </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    onClick={() => {
                                      updateParentHandler(
                                        level.name,
                                        level._id,
                                        true,
                                        level.depth === 0 ? -1 : level.depth
                                      );
                                    }}
                                  >
                                    {t("Edit")}
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => {
                                      setDeleteModuleModalShow(true);
                                      setDeletedModuleId(level._id);
                                    }}
                                  >
                                    {t("Delete")}
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => {
                                      updateParentHandler(
                                        "",
                                        level._id,
                                        false,
                                        level.depth
                                      );
                                    }}
                                  >
                                    {t("Add")}{" "}
                                    {level.depth === 0 || !level.depth
                                      ? "Topic"
                                      : level.depth > 0
                                      ? "Subtopic"
                                      : "Module"}
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown> */}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
                </div>
              </Col>
            )}

            <div
              className={classNames({
                "knowledge-viewby": true,
                "col-lg-9 col-md-8":
                  selectedYear && selectedYear.group_type !== "system",
                "col-lg-12 col-md-12":
                  selectedYear && selectedYear.group_type === "system",
              })}
            >
              <div className="knowledge-box px-3 py-2 build-knowbox text-left">
                {knowledgeHtml}
              </div>
            </div>
          </Row>
        </div>
      </>
    </>
  );
}

export default KnowledgeSearchModalComponent;
