/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, ReactElement } from "react";
import { Col, Row } from "react-bootstrap";
import {
  TOOLKIT_GENERATE_QNA_UPLOAD_DOC,
  TOOLKIT_SETUP_CONTEXTUALISED_CHAT,
} from "../../../../config";
import Setupimg from "../../../../assets/images/quick-setup.svg";
import Qaimg from "../../../../assets/images/quick-q&a.svg";
import Quizimg from "../../../../assets/images/quick-quiz.svg";
import { useHistory, useLocation } from "react-router-dom";

import { useTranslation } from "react-i18next";
import SelectModalModule from "../../../../routes/TeachersToolkit/popups/selectModalModule";
import CreateQuizModalTeachersToolkit from "../../../../routes/TeachersToolkit/popups/createQuizModalTeachersToolkit";

function QuickStart(props: any): ReactElement {
  const { t } = useTranslation("translation");
  const history = useHistory();
  const [showSelectModule, setShowSelectModule] = useState(false);
  const [header, setHeader] = useState("");
  const [subHeader, setSubHeader] = useState("");
  const [selectedFlow, setSelectedFlow] = useState("");
  const [selectedModule, setSelectedModule] = useState(null);
  const [showCreateQuizModal, setShowCreateQuizModal] = useState(false);
  const [quickStartOptions, setQuickStartOptions] = useState<
      "chat" | "qa" | "quizzes"
  >("chat");
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const groupId = query.get("group");
  const reference = query.get("reference");

  const confirmCategoryUpdate = async (
      selectedOption: any,
      createButtons: boolean = false,
      key: string
  ) => {
    if (selectedOption) {
      setSelectedModule(selectedOption);
      setShowSelectModule(false);
      setHeader("");
      setSubHeader("");
      setSelectedFlow("");
      switch (key) {
        case "CONTEXTUALISED_CHAT":
          history.push(
              `${TOOLKIT_SETUP_CONTEXTUALISED_CHAT}?group=${selectedOption}`
          );
          break;
        case "GENERATE_KNOWLEDGE":
          history.push(
              `${TOOLKIT_GENERATE_QNA_UPLOAD_DOC}?group=${selectedOption}`
          );
          break;
        case "CREATE_QUIZ":
          if (reference !== "create-quiz") {
            setShowCreateQuizModal(true);
          }
          break;
        default:
          break;
      }
    }
  };
  const showSelectModulesHandler = (
      heading: string,
      subHeading: string,
      key: string
  ) => {
    setSelectedFlow(key);
    setHeader(heading);
    setSubHeader(subHeading);
    if (!groupId) {
      setShowSelectModule(true);
    } else {
      confirmCategoryUpdate(groupId, false, key);
    }
  };

  return (
      <>
        <SelectModalModule
            show={showSelectModule}
            onHide={() => {
              setShowSelectModule(false);
              setHeader("");
              setSubHeader("");
              setSelectedFlow("");
            }}
            confirmCategoryUpdate={confirmCategoryUpdate}
            header={header}
            subHeader={subHeader}
            isBuildKnowledge
            selectedFlow={selectedFlow}
        />
        <CreateQuizModalTeachersToolkit
            show={showCreateQuizModal}
            onHide={() => {
              if (reference === "create-quiz") {
                history.goBack();
              } else {
                setShowCreateQuizModal(false);
              }
            }}
            selectedGroupId={selectedModule}
            createSmartQuiz={reference === "create-quiz" ? true : false}
        />

        <Row className={"mt-4"}>
          <Col md={12} className="mb-4">
            <div className="quick-start-box overflow-hidden p-0 d-flex  align-items-center text-left">
              <div className="inner-box">
                <h2 className="mb-0 font-18">{t("Quick Start")} </h2>
                {/* <img src={knowledgeImg} alt="" /> */}
                <div className="inner-text" onClick={() => {}}>
                  <div
                      className={`button-action d-flex align-items-center radius-8 mt-16 ${
                          quickStartOptions === "chat" && "active"
                      } `}
                      onClick={() => {
                        setQuickStartOptions("chat");
                      }}
                  >
                  <span className="number-col d-flex align-items-center font-800 justify-content-center">
                    {t("1")}
                  </span>
                    <span className="text font-16">
                    {t("Set Up Contextualised Chat")}
                  </span>
                  </div>
                  <div
                      className={`button-action d-flex align-items-center radius-8 mt-16 ${
                          quickStartOptions === "qa" && "active"
                      } `}
                      onClick={() => {
                        setQuickStartOptions("qa");
                      }}
                  >
                  <span className="number-col d-flex align-items-center font-800 justify-content-center">
                    {t("2")}
                  </span>
                    <span className="text font-16"> {t("Generate Q&As")} </span>
                  </div>
                  <div
                      className={`button-action d-flex align-items-center radius-8 mt-16 ${
                          quickStartOptions === "quizzes" && "active"
                      } `}
                      onClick={() => {
                        setQuickStartOptions("quizzes");
                      }}
                  >
                  <span className="number-col d-flex align-items-center font-800 justify-content-center">
                    {t("3")}
                  </span>
                    <span className="text font-16">{t("Create Quizzes")} </span>
                  </div>
                </div>
              </div>
              <div className="image-block">
                {quickStartOptions === "chat" && (
                    <div className="set-up">
                      <div className="d-flex align-items-center image-textouter">
                        <img
                            src={Setupimg}
                            alt=""
                            height={"240px"}
                            width={"240px"}
                        />
                        <div className="content-overview">
                          <h2 className="mb-0">
                            {t("Set Up Contextualised Chat")}{" "}
                          </h2>
                          <p className="font-14 mt-1">
                            {t(`  Upload your content to serve as sources for your AI
                        assistant’s responses.`)}
                          </p>
                          {quickStartOptions === "chat" && (
                              <button
                                  className="btn-primary btn mr-0 font-14 font-600"
                                  onClick={() => { history.push(`/knowledge/setup/chat`) }}
                              >
                                {t("Set Up Contextualised Chat")}
                              </button>
                          )}
                        </div>
                      </div>
                    </div>
                )}
                {quickStartOptions === "qa" && (
                    <div className="Generate">
                      <div className="d-flex align-items-center image-textouter">
                        <img
                            src={Qaimg}
                            alt="q&a"
                            height={"240px"}
                            width={"240px"}
                        />
                        <div className="content-overview">
                          <h2 className="mb-0">{t("Generate Q&As")} </h2>
                          <p className="font-14 mt-1">
                            {t(`Extract essential points from your content and create
                        Q&A flashcards.`)}
                          </p>
                          <button
                              className="btn-primary btn mr-0 font-14"
                              onClick={() => { history.push(`/knowledge/setup/generate-qa`) }}
                          >
                            {t("Generate Q&As")}
                          </button>
                        </div>
                      </div>
                    </div>
                )}
                {quickStartOptions === "quizzes" && (
                    <div className="Create">
                      <div className="d-flex align-items-center image-textouter">
                        <img
                            src={Quizimg}
                            alt=""
                            height={"240px"}
                            width={"240px"}
                        />
                        <div className="content-overview">
                          <h2 className="mb-0">{t("Create Quiz")} </h2>
                          <p className="font-14 mt-1">
                            {t("Create quizzes to assess your learners' understanding.")}

                          </p>
                          <button
                              className="btn-primary btn mr-0 font-14"
                              onClick={() => { history.push(`/knowledge/setup/quiz`) }}
                             
                          >
                            {t("Create Quiz")}
                          </button>
                        </div>
                      </div>
                    </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </>
  );
}

export default QuickStart;
