import axios, { AxiosResponse } from "axios";
import { RequestObject } from "../types/axios";
import AppInstance from '../axios-data-instance';

export default class DataApiUtil {
  private constructor() { }

  /**
   * To make generic  api call ie. post get etc.
   * @param requestObject
   */
  static async SendAsync<T>(
    requestObject: RequestObject
  ): Promise<AxiosResponse<T>> {
    try {
      const res = await AppInstance(requestObject);
      if (res.status < 200 && res.status > 300) {
        throw console.error(`Error-${res.status}: ${res.statusText}`);
      }
      return res;
    } catch (error: any) {
      // Handling in axios interceptor
      throw error;
    }
  }
}
