import integrationService from "../../../../../services/IntegrationService";

const service = integrationService;

export const getProviderIntegration = async (provider: string, requests_new_state: boolean = false) => {
  try {
    const response: any = await service.getProviderIntegration(provider, requests_new_state);
    if (response && response.status && response.status === 400) {
      return { isIntegrated: false, integrationData: null };
    } else if (
      response &&
      response.status &&
      response.status.code === 200 &&
      response.data
    ) {
      return { isIntegrated: true, integrationData: response.data };
    }
  } catch (error) {
    return { isIntegrated: false, integrationData: null };
  }
};

export const getContentUploadIntegrations = async () => {
  try {
    const { status, data } = await service.getIntegrations(null, "api");
    if (status === 400) {
      return [];
    } else if (
        status === 200 && data
    ) {
      return data.data.integrations.filter((integration: any) => ["canvas", "blackboard", "desire2learn", "moodle"].includes(integration.provider));
    }
  } catch (error) {
  }
  return [];
};


export const checkIntegration = async (props: any) => {
  try {
    const integrations = await getContentUploadIntegrations();

    if (integrations.length > 0) {
      props.setIsCanvasIntegrated({
        ...props.isCanvasIntegrated,
        intregate: true,
        data: null,
      });

      return;
    }
  } catch (error) {
  }

  props.setIsCanvasIntegrated({
    intregate: false,
    data: null,
    show: false,
  });
};
