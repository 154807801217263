import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import { LANGUAGE_OPTIONS } from "../../components/Header/ChangeLanguage";
import EmailInput from "./EmailInput";
import Info from "./Info";
import { plans } from "./Info";
import { useHistory } from "react-router";
import { t } from "i18next";

const STEPS = {
  EMAIL: "EMAIL",
  INFO: "INFO",
  SEND_EMAIL: "SEND_EMAIL",
};

// @ts-nocheck
const formValidationSchema: any = Yup.object().shape({
  first_name: Yup.string().required(""),
  last_name: Yup.string().required(""),
  country: Yup.string().required(""),
  email: Yup.string().email(t("Invalid email")).required(""),
  reEmail: Yup.string()
    .email("Invalid email")
    .required("")
    .test('sameEmail', t("Email should be same"), (value: any, context: any) => {
      const {
        parent: { email },
      } = context
      return email === value
    }),
  password: Yup.string()
    .required("")
    .test("isValidPw", t("Password is not valid"), (val) =>
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#$])[A-Za-z\d@$!%*?&#$]{8,}$/.test(val)
    ),
  rePassword: Yup.string()
  .required("")
  .test('samePassword', t("Password should be same"), (value: any, context: any) => {
    const {
      parent: { password },
    } = context
    return password === value
  }),
  name: Yup.string().required(""),
  product_definition_id: Yup.string().nullable( true ),
  lang: Yup.string().required(""),
});

export const Signup = (props: any) => {
  const history = useHistory()
  const [step, setStep] = useState(STEPS.EMAIL);
  const {isMobile} = props
  const form = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      reEmail: "",
      password: "",
      name: "",
      product_definition_id: plans[0].value,
      lang: "en",
      country: "",
      subscription_id: ""
    },
    validationSchema: formValidationSchema,
    onSubmit: (values) => {},
  });

  useEffect(() => {
    let plan = null;
    const query = new URLSearchParams(history?.location?.search);
    plan = query.get("product") ? query.get("product") : null;
    form.setFieldValue("product_definition_id", plan);
    const subscription_id = query.get("subscription") ? query.get("subscription") : null;
    form.setFieldValue("subscription_id", subscription_id);
  }, []);

  useEffect(() => {
    let selectedLanguage = LANGUAGE_OPTIONS.find((l) => l.value === props.selectedLanguageCode);
    if (selectedLanguage !== undefined) {
      form.setFieldValue("lang", selectedLanguage.value);
    }
  }, [ props.selectedLanguageCode ]);

  return (
    <div className="sign-up-container">
      {step === STEPS.EMAIL && (
        <EmailInput
          value={form.values.email}
          onChange={(value: any) => form.setFieldValue("email", value)}
          form={form}
          gotoInfo={() => setStep(STEPS.INFO)}
          isMobile={isMobile}
        />
      )}
      {step === STEPS.INFO && (
        <Info
          form={{...form}}
          selectedLanguageCode={props.selectedLanguageCode}
          back={() => {
            setStep(STEPS.EMAIL);
          }}
          isMobile={isMobile}
        />
      )}
    </div>
  );
};

export default Signup;
