import { Button, ButtonGroup, OverlayTrigger, Popover } from "react-bootstrap";
import Moment from "react-moment";
import PermissionWrapper from "../../components/UI/PermissionWrapper/PermissionWrapper";

import { useTranslation } from "react-i18next";

export const customStyles = {
  header: {
    style: {
      minHeight: "56px",
    },
  },
  headRow: {
    style: {
      borderRadius: "10px",
      backgroundColor: "#0923E6",
      borderBottomWidth: "0",
    },
  },
  expanderRow: {
    style: {
      // backgroundColor: '#0923E6',
    },
  },
  expanderCell: {
    style: {
      "&:hover": {
        backgroundColor: "transparent",
      },
      button: {
        backgroundColor: "transparent !important",
        img: {
          width: "10px",
        },
      },
    },
  },
  expanderButton: {
    style: {
      backgroundColor: "transparent",
      alignItems: "center",
    },
  },
  headCells: {
    style: {
      "&:first-child": {
        borderTopLeftRadius: "5px",
        borderBottomLeftRadius: "0px",
      },
      "&.chart--table-sort-icon": {
        marginLeft: "10px",
      },
      'input[type="checkbox"]': {
        width: "16px",
        height: "16px",
      },
      backgroundColor: "#0923E6",
      //width: "250px",
      padding: "20px 20px",
      fontSize: "14px",
      color: "#fff",
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "#FFFFFF",
      },
      div: {
        color: "#fff",
      },
    },
  },
  cells: {
    style: {
      color: "#07032B",
      fontSize: "14px",
      paddingTop: "10px",
      paddingBottom: "10px",
      'input[type="checkbox"]': {
        width: "16px",
        height: "16px",
      },
      "&:nth-child(1)": {
        div: {
          textOverflow: "ellipsis",
          display: " -webkit-box",
          WebkitLineClamp: "2",
          WebkitBoxOrient: "vertical",
        },
      },
      "&:nth-child(2)": {
        div: {
          textOverflow: "ellipsis",
          display: " -webkit-box",
          WebkitLineClamp: "2",
          WebkitBoxOrient: "vertical",
        },
      },
    },
  },
};

export const conditionalRowStyles = [
  {
    when: (row: any) => row.status === "answered",
    style: {
      backgroundColor: "#FFF8FC",
    },
  },
];

export const ExpandableComponent = ({
  data,
  matchedRecords,
  addAnswerModalHandler,
  suggestedKnowledgeFeedback,
}: any) => {
  const { t } = useTranslation('translation');
  const records = matchedRecords.find((intent: any) => intent._id === data._id);
  return (
    <div className="expand-wraper">
      {records &&
        records.suggested_answers.map((record: any) => {
          return (
            <div className="view-suggest" key={record.conversation_feedback_id}>
              <div className="row">
                <div className="col-5 col-lg-3">{record?.comment}</div>
                <div className="col-3 col-lg-2">
                  <span>{record.author_email}</span>
                  <span>
                    <Moment format="DD-MM-YYYY">{record.created_at}</Moment>
                  </span>
                </div>
                <div className="col-3 col-lg-2">
                  <PermissionWrapper>
                    <ButtonGroup aria-label="Basic example">
                      <Button
                        variant="suggest"
                        onClick={(e) => {
                          addAnswerModalHandler({
                            selectedAnswer: records,
                            question: records.text,
                            answer: record.comment,
                            intent_id: record.conversation_feedback_id,
                          });
                        }}
                      >
                        {t("Accept")}
                      </Button>
                      <Button
                        variant="delete"
                        onClick={(e) => {
                          suggestedKnowledgeFeedback({
                            selectedAnswer: records,
                            question: records.text,
                            answer: record.comment,
                            intent_id: record.conversation_feedback_id,
                          });
                        }}
                      >
                        {t("Reject")}
                      </Button>
                    </ButtonGroup>
                  </PermissionWrapper>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export const PopoverComponent = (data: any) => {
  return (
    <Popover id="popover-basic-" {...data.popoverComponentProps}>
      {/* <Popover.Title as='h3'>Popover right</Popover.Title> */}
      <Popover.Content
        dangerouslySetInnerHTML={{
          __html: data.entity ? data.entity : "",
        }}
      ></Popover.Content>
    </Popover>
  );
};

export const Answer = ({ row }: any) => (
  <OverlayTrigger
    trigger={["hover", "focus"]}
    placement="auto"
    overlay={(d) => (
      <PopoverComponent
        popoverComponentProps={d}
        row={row}
        entity={row.answer}
      />
    )}
  >
    <div
      data-tag="allowRowEvents"
      style={{
        color: "#07032B",
        overflow: "hidden",
        textOverflow: "ellipses",
      }}
    >
      {row.answer ? row.answer : ""}
    </div>
  </OverlayTrigger>
);

export const Question = ({ row }: any) => (
  <OverlayTrigger
    trigger={["hover", "focus"]}
    placement="auto"
    overlay={(d) => (
      <PopoverComponent popoverComponentProps={d} row={row} entity={row.text} />
    )}
  >
    <div
      data-tag="allowRowEvents"
      style={{
        color: "#07032B",
        overflow: "hidden",
        textOverflow: "ellipses",
      }}
    >
      {row.text ? row.text : ""}
    </div>
  </OverlayTrigger>
);

export const LastAsked = ({ row }: any) => (
  <div>
    <Moment utc local format="DD/MM/YYYY">
      {row.created_at}
    </Moment>
  </div>
);

export const TimesAsked = ({ row }: any) => (
  <div>
    {row.ask_count}
    {/* <Moment format='DD/MM/YYYY'>{row.ask_count}</Moment> */}
  </div>
);

export const SuggestedAnswerCount = ({ row }: any) => (
  <div>{row.suggested_answers.length}</div>
);

export const SuggestionDetails = ({ row }: any) => (
  <div>{/* {row.text} */}</div>
);

// <Button
//             variant="suggest"
//             onClick={(e) => {
//               addAnswerModalHandler({
//                 question: row.question,
//                 answer: row.suggested_answer,
//                 intent_id: row.intent_id,
//               });
//             }}
//           >
//             Add
//           </Button>
//           <Button
//             variant="delete"
//             onClick={(e: any) => {
//               suggestedKnowledgeFeedback(row._id);
//               //   e.preventDefault()
//               //   deleteDocumentHandler(row._id, false)
//             }}
//           >
//             Reject
//           </Button>

export const columns = ({
  addAnswerModalHandler,
  ViewSuggestedAnswer,
}: any) => [
  {
    name: "Question",
    selector: "text",
    sortable: true,
    cell: (row: any) => <Question row={row} />,
  },
  // {
  //   name: 'Answer',
  //   selector: 'answer',
  //   sortable: true,
  //   cell: (row: any) => <Answer row={row} />,
  // },
  {
    name: "Last Asked",
    selector: "created_at",
    sortable: true,
    cell: (row: any) => <LastAsked row={row} />,
  },
  {
    name: "Times Asked",
    selector: "ask_count",
    sortable: true,
    cell: (row: any) => <TimesAsked row={row} />,
  },
  {
    name: "Action",
    selector: "",
    sortable: false,
    cell: (row: any) => (
      <>
        <ButtonGroup aria-label="Basic example">
          {row.status === "unanswered" && row.suggested_answers.length > 0 && (
            <Button
              variant="suggest"
              onClick={(e) => {
                ViewSuggestedAnswer(row._id);
              }}
            >
              View ({row.suggested_answers.length}) Suggested Answers
            </Button>
          )}

          {row.status === "unanswered" && (
            <PermissionWrapper>
              <Button
                variant="suggest"
                onClick={(e) => {
                  addAnswerModalHandler({
                    question: row.text,
                    answer: null,
                    intent_id: row._id,
                  });
                }}
              >
                Add Answer
              </Button>
            </PermissionWrapper>
          )}
        </ButtonGroup>
      </>
    ),
  },
  // {
  //   name: "Suggested Answer Count",
  //   selector: "suggested_answers",
  //   sortable: false,
  //   cell: (row: any) => <SuggestedAnswerCount row={row} />,
  // },
  // {
  //   name: "Suggestion Details",
  //   selector: "text",
  //   sortable: false,
  //   cell: (row: any) => <SuggestionDetails row={row} />,
  // },
];

export const SuggestedAnswer = ({ row }: any) => (
  <OverlayTrigger
    trigger={["hover", "focus"]}
    placement="auto"
    overlay={(d) => (
      <PopoverComponent
        popoverComponentProps={d}
        row={row}
        entity={row.suggested_answer}
      />
    )}
  >
    <div
      data-tag="allowRowEvents"
      style={{
        color: "#07032B",
        overflow: "hidden",
        textOverflow: "ellipses",
      }}
    >
      {row.suggested_answer ? row.suggested_answer : ""}
    </div>
  </OverlayTrigger>
);

export const SuggestedQuestion = ({ row }: any) => (
  <OverlayTrigger
    trigger={["hover", "focus"]}
    placement="auto"
    overlay={(d) => (
      <PopoverComponent
        popoverComponentProps={d}
        row={row}
        entity={row.question}
      />
    )}
  >
    <div
      data-tag="allowRowEvents"
      style={{
        color: "#07032B",
        overflow: "hidden",
        textOverflow: "ellipses",
      }}
    >
      {row.question ? row.question : ""}
    </div>
  </OverlayTrigger>
);

export const SuggestedByComponent = ({ row }: any) => (
  <div>{row.suggested_by}</div>
);
export const SuggestedOnComponent = ({ row }: any) => (
  <div>
    <Moment utc local format="DD/MM/YYYY">
      {row.suggested_on}
    </Moment>
  </div>
);
export const suggestedAnswerColumns = ({
  addAnswerModalHandler,
  suggestedKnowledgeFeedback,
}: any) => [
  {
    name: "Question",
    selector: "question",
    sortable: true,
    cell: (row: any) => <SuggestedQuestion row={row} />,
  },
  {
    name: "Suggested Answer",
    selector: "suggested_answer",
    sortable: true,
    cell: (row: any) => <SuggestedAnswer row={row} />,
  },
  {
    name: "Suggested By",
    selector: "suggested_by",
    sortable: true,
    cell: (row: any) => <SuggestedByComponent row={row} />,
  },
  {
    name: "Suggested On(DD/MM/YYYY)",
    selector: "suggested_on",
    sortable: true,
    cell: (row: any) => <SuggestedOnComponent row={row} />,
  },
  {
    name: "Action",
    selector: "",
    sortable: false,
    cell: (row: any) => (
      <>
        <ButtonGroup aria-label="Basic example">
          <Button
            variant="suggest"
            onClick={(e) => {
              addAnswerModalHandler({
                question: row.question,
                answer: row.suggested_answer,
                intent_id: row.intent_id,
              });
            }}
          >
            Add
          </Button>
          <Button
            variant="delete"
            onClick={(e: any) => {
              suggestedKnowledgeFeedback(row._id);
              //   e.preventDefault()
              //   deleteDocumentHandler(row._id, false)
            }}
          >
            Reject
          </Button>
        </ButtonGroup>
      </>
    ),
  },
];
