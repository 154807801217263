import { useState, useEffect } from 'react';

interface Item {
  _id: string;
  children?: Item[];
}

const useListDropdownHandler = (data: Item[], selectedModule: string | null) => {
  const [expandedItems, setExpandedItems] = useState<string[]>([]);

  useEffect(() => {
    if (expandedItems.length < 1 && selectedModule) {
      setExpandedItems(findParentIds(data, selectedModule));
    }
  }, [selectedModule, data]);


  // Find all parent ids of the selected module. it is used to open the dropdown when user already selected a module from knowledge page.
  const findParentIds = (items: Item[], targetId: string): string[] => {
    const result: string[] = [];

    const traverse = (item: Item): boolean => {
      if (item._id === targetId) {
        return true;
      }
      if (item.children && item.children.length > 0) {
        for (const child of item.children) {
          if (traverse(child)) {
            result.push(item._id);
            return true;
          }
        }
      }
      return false;
    };

    for (const item of items) {
      if (traverse(item)) {
        break;
      }
    }

    return result.reverse();
  };

  return [expandedItems, setExpandedItems] as const;
};

export default useListDropdownHandler;