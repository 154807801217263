import { initReactI18next } from "react-i18next";
import i18n from "i18next";

import HttpApi from "i18next-http-backend";

const windowObj: any = window;
let  localBaseUrl = 'https://locales.staging.noodlefactory.ai'
if(windowObj.REACT_DEVELOPMENT_MODE === 'production') { 
  localBaseUrl = 'https://locales.noodlefactory.ai'
}

i18n
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    lng: "en",
    fallbackLng: false,
    debug: false, // windowObj.REACT_DEVELOPMENT_MODE === 'staging'
    lowerCaseLng: true,
    load: 'currentOnly',
    // have a common namespace used around the full app
    ns: ["translation"],
    defaultNS: "translation",

    // allow keys to be phrases having `:`, `.`
    nsSeparator: false,
    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: `${localBaseUrl}/locales/admin-portal/translations/{{lng}}/translation.json`,
    },

    react: {
      useSuspense: false, // TODO: enable React Suspense
    },
  });

export default i18n;
