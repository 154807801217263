import cx from "classnames";
import * as _ from "lodash";
import React, { useEffect, useState } from "react";
import { Container, Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Loading from "../../../components/UI/Loading/Loading";
import PDFViewer from "../../../components/UI/PDFViewer/PDFViewer";
import PermissionWrapper from "../../../components/UI/PermissionWrapper/PermissionWrapper";
import { BUILD_FINAL } from "../../../config/index";
import { getStickerSets } from "../../../store/common/actions";
import {
  bulkAssignModules,
  generateQuestionsAndAnswersBasedOnDocument,
  getDocumentFromID,
  getSectionsBasedOnDocumentId,
  resetAnswersScreen,
  saveQuestionsAndAnswers,
} from "../../../store/knowledge/actions";
import {
  getUserDocuments,
  isKnowledgeCreated,
  isKnowledgeCreating,
  iskeyWordsLoading,
} from "../../../store/knowledge/selector";

import { useTranslation } from "react-i18next";
import MoveSelectedModal from "../../../components/UI/KnowledgeDatatableComponent/MoveSelectedModal";
import { HelperService } from "../../../services/helper.service";

import classNames from "classnames";
import Select from "react-select";
import chevLeft from "../../../assets/New-images/chevron-left-blk.svg";
import downarrowblue from "../../../assets/New-images/dropdown-arow-blue.svg";
import ProgressSteps from "../../../components/UI/ProgressSteps/ProgressSteps";
import { DocumentPreview } from "../../Knowledge/GenerateAnswers/partials/DocumentPreview";
import QAnsAListSummarised from "../../Knowledge/GenerateQuestionsAnswers/partials/QAnsAListSummarised";
import GenerateKnowledgePopup from "./Popups/GenerateKnowledgePopup";
import SmartQuizPopup from "./Popups/SmartQuizPopup";

const helper = new HelperService();
export const GenerateQna = (props: any) => {
  const { t } = useTranslation("translation");
  const isMgs = process.env.REACT_APP_CISCO === "mgs";
  const history: any = useHistory();
  const params: any = useParams();
  const query = new URLSearchParams(history.location.search);
  const [preview, setpreview] = React.useState(false);
  const [answerData, setAnswerData] = React.useState(null);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [answerGenerationProcessing, SetAnswerGenerationProcessing] =
    useState(false);
  const [isSaved, setIsSaved] = useState(true);
  let defaultActiveKey = "qnas";
  if (isMgs) {
    defaultActiveKey = "sections";
  } else {
    defaultActiveKey = props.manageDocument ? "qnas" : "questions";
  }
  const [activeTab, setActiveTab] = useState(defaultActiveKey);
  const [loading, setLoading] = useState(false);
  const [generatedQuestions, setGeneratedQuestions] = useState([]);
  const [updatedQuestions, setUpdatedQuestions] = useState([]);
  const dispatch = useDispatch();
  const userDocuments = useSelector(getUserDocuments);
  const knowledgeCreating = useSelector(isKnowledgeCreating);
  const knowledgeCreated = useSelector(isKnowledgeCreated);
  const keyWordsLoading = useSelector(iskeyWordsLoading);
  const [sections, setSections] = useState([]);
  const [sectionsFetched, setSectionsFetched] = useState(false);
  const [selectedSection, setSelectedSection] = useState(null);
  const [hasSectionSaved, setHasSectionSaved] = useState([]);
  const useLocatio = useLocation();
  const [modalShow, setModalShow] = useState(false);
  const [urlMap, setUrlMap] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(0);
  const [documentSectionData, setDocumentSectionData] = useState(null);
  const [paramsQueIds, setParamsQueIds] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [addNewQuestionAnswer, setAddNewQuestionAnswer] = useState({
    question: "",
    answer: "",
    sort_order: 0,
    show: false,
  });

  const [groupId, setGroupId] = useState(query.get("group") || "");
  const [showSmartQuizModal, setShowSmartQuizModal] = useState(false);
  const [genAssessmentQna, setGenAssessmentQna] = useState(false);
  const [assessemntQueType, setAssessemntQueType] = useState([]);

  useEffect(() => {
    setGroupId(
      history &&
        history.location &&
        history.location.state &&
        history.location.state.group
        ? history.location.state.group
        : query.get("group")
    );
  }, []);

  useEffect(() => {
    setpreview(false);
    setHasSectionSaved([]);
    if (userDocuments.length === 0) {
      dispatch(resetAnswersScreen());
    }
    if (!useLocatio.search) {
      history.goBack();
    }

    const query = new URLSearchParams(useLocatio.search);
    const paramsUrl = query.get("question").split(",");
    setParamsQueIds(paramsUrl);

    if (paramsUrl.length === 0) {
      history.goBack();
      return;
    }

    generateQuestionsAndAnswersBasedOnDocumentFn();
    if (!keyWordsLoading) {
      //   // ASK user before leave and then update
      //   dispatch(updateOrganizationFlow({
      //     ...organizationFlowObject,
      //     prevRoute: `/knowledge/generate-questions${history.location.search}`,
      //     currentRoute: `${history.location.pathname}${history.location.search}`,
      //     nextRoute: '/knowledge/all',
      //     setup_completed: false
      //   }));
    }

    dispatch(getStickerSets());
  }, []);

  const generateQuestionsAndAnswersBasedOnDocumentFn = async () => {
    const query = new URLSearchParams(useLocatio.search);
    const params = query.get("question").split(",");

    setSectionsFetched(false);
    try {
      // const sectionResponse: any = await dispatch(
      //   getSectionsBasedOnDocumentId(params)
      // );
      // setSections([...sectionResponse]);
      // setSelectedSection(
      //   sectionResponse.length > 0 ? sectionResponse[0] : null
      // );

      const Documents = [];
      let index = 0;
      for await (const document of params) {
        const fetchedDocument: any = await dispatch(
          getDocumentFromID(document)
        );
        Documents.push({
          ...fetchedDocument,
          documentIndex: index,
          label: fetchedDocument.name,
          value: fetchedDocument._id,
        });
        index++;
      }

      // const document: any = await dispatch(getDocumentFromID(params[0]));
      // setGeneratedQuestions([
      //   {
      //     ...Documents,
      //     qnas: [],
      //   },
      // ]);
      // setUpdatedQuestions([
      //   {
      //     ...Documents,
      //     qnas: [],
      //   },
      // ]);
      // dispatch(
      //   generateQuestionsAndAnswersBasedOnDocument(
      //     userDocuments,
      //     params,
      //     sectionResponse.length > 0 ? sectionResponse[0] : null
      //   )
      // )

      setDocuments([...Documents]);
      paginateDocuments(Documents, 0);
      setSectionsFetched(true);
    } catch (error) {
      setSectionsFetched(true);
      // dispatch(
      //   generateQuestionsAndAnswersBasedOnDocument(userDocuments, params, null)
      // );
    }
  };

  const paginateDocuments = async (documents: any[] = [], index: number) => {
    let docuData: any = { ...documentSectionData };
    if (
      !documentSectionData ||
      (docuData && !docuData[documents[index]._id]) ||
      (docuData &&
        docuData[documents[index]._id] &&
        !docuData[documents[index]._id].hasOwnProperty("hasApiCalled"))
    ) {
      const sectionResponse: any = await dispatch(
        getSectionsBasedOnDocumentId([documents[index]._id])
      );

      setSections([...sectionResponse]);
      setSelectedSection(
        sectionResponse.length > 0 ? sectionResponse[0] : null
      );
      setDocumentSection(sectionResponse, documents[index]._id);
    }
    setGeneratedQuestions([
      {
        ...documents[index],
        qnas: [],
      },
    ]);
    setUpdatedQuestions([
      {
        ...documents[index],
        qnas: [],
      },
    ]);

    setSelectedDocument(documents[index]);
    setSelectedDocumentIndex(index);
    // await getDocumentSections(documents[index]._id);
    // getDocumentValidationsHandler(documents.map((doc: any) => doc._id));
    // getDocumentValidationsHandler(documents[index]._id);
  };

  const setDocumentSection = async (
    fetchedSections: any[],
    questionId: string
  ) => {
    const temp: { [id: string]: any } = {};

    temp[questionId] = {
      ...temp[questionId],
    };

    if (fetchedSections.length > 0) {
      temp[questionId].hasApiCalled = true;
      temp[questionId].questions = [];
      await setDocumentSectionData({ ...documentSectionData, ...temp });
      return;
    }
  };

  const getDocumentSections = async (questionId: any) => {
    setSectionsFetched(false);
    try {
      // const sectionResponse: any = await dispatch(
      //   getSectionsBasedOnDocumentId([questionId])
      // );
      // console.log("sectionResponse", sectionResponse);
      // setSections([...sectionResponse]);
      // setSelectedSection(
      //   sectionResponse.length > 0 ? sectionResponse[0] : null
      // );
    } catch (error) {
      setSectionsFetched(true);
    }
  };

  const generateQuestionsBasedOnSelection = async (sectionResponse: any) => {
    const query = new URLSearchParams(useLocatio.search);
    const params = query.get("question").split(",");
    dispatch(
      generateQuestionsAndAnswersBasedOnDocument(
        userDocuments,
        params,
        sectionResponse
      )
    );
  };

  useEffect(() => {
    // setIsSaved(false);
    // const query = new URLSearchParams(useLocatio.search);
    // const params = query.get("question").split(",");
    // const questionsFetched = userDocuments.filter(
    //   (document: any) => document._id === params[0]
    // );
    // setGeneratedQuestions([..._.uniqBy(questionsFetched, "_id")]);
    // setUpdatedQuestions([..._.uniqBy(questionsFetched, "_id")]);
  }, [userDocuments]);

  const saveKnowledge = async (
    selectedOption: string,
    createButtons: boolean,
    queType: any[] = []
  ) => {
    if (!selectedOption) {
      helper.showToaster("Please choose a module before saving.");
      return;
    }
    setShowSmartQuizModal(false);
    // setModalShow(false);
    try {
      const query = new URLSearchParams(useLocatio.search);
      const references = query.get("references")?.split(",");
      const type = query.get("type");
      await setLoading(true);
      await setIsSaved(true);

      await dispatch(
        saveQuestionsAndAnswers(
          updatedQuestions,
          createButtons,
          true,
          selectedOption,
          queType,
          queType.length > 0
        )
      );
      if (selectedOption) {
        await dispatch(
          bulkAssignModules(
            selectedOption,
            // paramsQueIds.length > 0 ? paramsQueIds : [params.questionId],
            paramsQueIds,
            "document",
            false
          )
        );
      }
      // if (references) {
      //   await dispatch(
      //     bulkAssignModules(selectedOption, references, "document", false)
      //   );
      // }
      await setLoading(false);
      const sectionSaved = [...hasSectionSaved];
      if (selectedSection) {
        sectionSaved.push(selectedSection.value);
      }
      setHasSectionSaved([...sectionSaved]);
      if (sections.length === 1) {
        if (!documents[selectedDocumentIndex + 1]) {
          history.push(`${BUILD_FINAL}?group=${groupId}&type=${type}`);
        } else {
          await saveSectionDetails(false, false, selectedDocumentIndex + 1);
        }
      }
    } catch (error: any) {
      setModalShow(false);
      await setLoading(false);
    }
    return;
  };

  const updatedAnswersHandler = (data: any) => {
    const oldQuestions = [...generatedQuestions];
    const document = oldQuestions.find((d) => d._id === data.documentId);
    document.qas =
      data.qas && data.qas.length > 0
        ? _.orderBy([...data.qas], "sort_order", "asc")
        : [];
    document.keywordsLoading = data.keywordsLoading
      ? data.keywordsLoading
      : false;
    document.qasFetchError = data.qasFetchError ? data.qasFetchError : false;
    document.last_offset = data.last_offset ? data.last_offset : 0;
    document.retryExecution = data.retryExecution ? data.retryExecution : false;
    setUpdatedQuestions([...oldQuestions]);

    const temp = { ...documentSectionData };
    temp[data.documentId].questions = [...document.qas];
    setDocumentSectionData(temp);

    const keywordsLoading = oldQuestions.filter(
      (document: any) =>
        document.qas && document.qas.length === 0 && !document.qasFetchError
    );
    SetAnswerGenerationProcessing(keywordsLoading.length > 0 ? true : false);
  };

  const previewToggleHandler = async (answerData: any) => {
    if (answerData.hasOwnProperty("keyword")) {
      await setAnswerData({
        ...answerData,
      });
    }
    setpreview(!preview);
  };

  const tabsChangeHandler = (e: any) => {
    setActiveTab(e);
  };

  const [createButtons, setCreateButtons] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const confirmCategoryUpdateHandler = async (
    selectedOption: any,
    createButtons: boolean = false
  ) => {
    try {
      if (selectedOption) {
        await setSelectedOption(selectedOption);
      }
      if (createButtons) {
        await setCreateButtons(createButtons);
      }
      if (selectedOption) {
        await setModalShow(false);
        await setShowSmartQuizModal(true);
      }
      // await saveKnowledge(selectedOption, createButtons);
    } catch (error: any) {}
  };

  const saveSectionDetails = async (
    generateTags = false,
    knowledgeMode = false,
    index: number,
    saveToKnowledgeBase: boolean = false
  ) => {
    if (!selectedDocument || !selectedDocument.value) {
      console.log("-- track --", selectedDocument);
      return;
    }

    try {
      setLoading(true);
      if (saveToKnowledgeBase) {
        await setModalShow(true);
      } else {
        await paginateDocuments(documents, index);
        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
    }
  };

  const skipDocument = () => {
    const docuData = { ...documentSectionData };
    if (docuData[documents[selectedDocumentIndex]._id].questions.length >= 1) {
      setShowModal(true);
    } else {
      saveSectionDetails(false, false, selectedDocumentIndex + 1);
    }
  };

  const addMoreQuestionHandler = (e: any, documentId: any) => {
    e.preventDefault();
    const Qnas = [...documentSectionData[documentId].questions];
    setAddNewQuestionAnswer({
      ...addNewQuestionAnswer,
      show: true,
    });
    const oldAnswers: any[] = [...Qnas];
    oldAnswers.unshift({
      question: addNewQuestionAnswer.question,
      answer: addNewQuestionAnswer.answer,
      sort_order: 0,
      newlyAdded: true,
      hasEditMode: true,
    });
    updatedAnswersHandler({
      documentId: documentId,
      qas: oldAnswers,
    });
  };

  const generateSmartQuiz = async (queType: any, hide: boolean = false) => {
    if (hide) {
      setShowSmartQuizModal(false);
    }
    // await setGenAssessmentQna(true);
    // await setAssessemntQueType()
    await saveKnowledge(selectedOption, createButtons, !hide && queType);
  };

  const hideSmartQuizHandler = () => {
    setGenAssessmentQna(false);
  };

  return (
    <>
      {/* <Prompt
        when={!isSaved}
        message="Your questions and answers aren't saved yet. Are you sure you want to leave?"
      /> */}
      {loading && <Loading />}
      {/* <Loading /> */}
      <aside
        className={cx({
          "notification-wrap": true,
          open: preview,
        })}
      >
        <DocumentPreview
          answerData={answerData}
          preview={preview}
          previewToggleHandler={previewToggleHandler}
        />
      </aside>

      <MoveSelectedModal
        show={modalShow}
        onHide={() => {
          setLoading(false);
          setModalShow(false);
        }}
        confirmCategoryUpdate={confirmCategoryUpdateHandler}
        summarisedView
        groupId={groupId}
      />

      <SmartQuizPopup
        show={showSmartQuizModal}
        onHide={() => setShowSmartQuizModal(false)}
        onProceed={generateSmartQuiz}
      />

      {showModal && (
        <GenerateKnowledgePopup
          show
          onHide={() => {
            setShowModal(false);
          }}
          onProceed={() => {
            if (!documents[selectedDocumentIndex + 1]) {
              history.push(BUILD_FINAL);
            } else {
              saveSectionDetails(false, false, selectedDocumentIndex + 1);
            }
            setShowModal(false);
          }}
        />
      )}

      <section
        className={cx({
          "page-mid-wraper page-mid-wraper-build-v2 pb-0": true,
          "h-without-foter": activeTab === "qnas",
          "knowledge-detailby-id": props && props.hideHeaders,
        })}
      >
        <Container>
          <div className="d-flex flex-wrap justify-content-center clr-primary position-relative">
            <button
              className="btn py-0 mr-2 position-absolute position-l-0"
              onClick={() => {
                history.goBack();
              }}
            >
              <img src={chevLeft} alt="" />
            </button>
            <h1 className="mb-2">{t("Generate Knowledge from Document(s)")}</h1>
          </div>
          <div className="mb-32 font-16 text-center">
            {t(
              "Create question and answer pairs for use in quizzes or tutoring flows"
            )}
          </div>

          <Tabs
            defaultActiveKey={defaultActiveKey}
            onSelect={tabsChangeHandler}
            activeKey={activeTab}
          >
            {!isMgs && (
              <Tab eventKey="questions" title={t("Generate Knowledge")}>
                <div className="pdf-section-wrapper">
                  <div>
                    {generatedQuestions.map((document: any, index: number) => {
                      return (
                        <div
                          className="document-section-wrapper row"
                          key={document._id}
                        >
                          <div className="col-md-5">
                            {paramsQueIds.length > 1 && (
                              <div className="font-16 font-weight-bold clr-black mt-md-0">
                                {/* {urlMap && <>{urlMap[documentId]?.name}</>} */}
                                <Select
                                  className="select-target px-0 select-blue shadow-none"
                                  classNamePrefix="select-target-prefix"
                                  value={selectedDocument}
                                  // isDisabled
                                  components={{
                                    DropdownIndicator: () => (
                                      <img src={downarrowblue} alt="" />
                                    ),
                                    IndicatorSeparator: () => null,
                                    ClearIndicator: () => null,
                                  }}
                                  onChange={(e) => {
                                    saveSectionDetails(
                                      false,
                                      false,
                                      e.documentIndex
                                    );
                                  }}
                                  placeholder={t("Document")}
                                  options={documents}
                                />
                              </div>
                            )}
                            {/* Instead of pdf viewer we have to show all text */}
                            <div className="mt-3">
                              <PDFViewer
                                setUrlMapForParent={setUrlMap}
                                // documentId={params.questionId}
                                documentId={document._id}
                              />
                            </div>
                          </div>

                          <div className="col-md-7">
                            <div key={document._id} title={document.name}>
                              <QAnsAListSummarised
                                key={document._id}
                                index={index}
                                isSummarised
                                {...document}
                                setAnswerData={previewToggleHandler}
                                documentLength={userDocuments.length}
                                updatedAnswersHandler={updatedAnswersHandler}
                                section={selectedSection}
                                setAnswerGenerationProcessing={
                                  SetAnswerGenerationProcessing
                                }
                                qas={[
                                  ...documentSectionData[document._id]
                                    ?.questions,
                                ]}
                                addMoreQuestionHandler={addMoreQuestionHandler}
                                buildKnowledge={true}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Tab>
            )}
          </Tabs>

          <footer>
            <Container>
              <div className="d-flex align-items-center justify-content-end px-2">
                <PermissionWrapper>
                  {paramsQueIds.length > 1 && (
                    <PermissionWrapper>
                      <div>
                        <button
                          className={classNames({
                            "btn mr-3 font-weight-bold": true,
                            "btn-light": !documents[selectedDocumentIndex - 1],
                            "btn-outline-primary":
                              documents[selectedDocumentIndex - 1],
                          })}
                          onClick={() => {
                            if (documents[selectedDocumentIndex - 1]) {
                              saveSectionDetails(
                                false,
                                false,
                                selectedDocumentIndex - 1
                              );
                            }
                          }}
                        >
                          {t("Previous Document")}
                        </button>
                        {/* {documents[selectedDocumentIndex + 1] && ( */}
                        <button
                          className={classNames({
                            "btn mr-3 font-weight-bold": true,
                            "btn-light": !documents[selectedDocumentIndex + 1],
                            "btn-outline-primary": true,
                          })}
                          // disabled={!documents[selectedDocumentIndex + 1]}
                          onClick={() => {
                            // saveSectionDetails(
                            //   false,
                            //   false,
                            //   selectedDocumentIndex + 1
                            // );
                            // setShowModal(true)
                            if (documents[selectedDocumentIndex + 1]) {
                              skipDocument();
                            }
                          }}
                        >
                          {t("Skip to Next Document")}
                        </button>
                        {/* )} */}
                        {/* {documents[selectedDocumentIndex + 1] && ( */}
                        <button
                          className="btn btn-primary mr-3"
                          onClick={() => {
                            saveSectionDetails(
                              false,
                              false,
                              selectedDocumentIndex + 1,
                              true
                            );
                          }}
                          disabled={
                            knowledgeCreating ||
                            answerGenerationProcessing ||
                            (documentSectionData &&
                              selectedDocument &&
                              !documentSectionData[selectedDocument._id]
                                .questions.length)
                          }
                        >
                          {t("Save to Knowledge Base")}
                        </button>
                        {/* )} */}
                      </div>
                    </PermissionWrapper>
                  )}
                  {paramsQueIds.length <= 1 && (
                    <PermissionWrapper>
                      {" "}
                      <button
                        className="btn btn-outline-primary third-step mr-3"
                        onClick={() => {
                          history.push(BUILD_FINAL);
                        }}
                        disabled={
                          knowledgeCreating || answerGenerationProcessing
                        }
                      >
                        {t("Skip This Step")}
                      </button>
                      <button
                        className="btn btn-primary third-step"
                        // onClick={() => setModalShow(true)}
                        onClick={() => {
                          setModalShow(true);
                          // history.push(BUILD_FINAL)
                        }}
                        disabled={
                          knowledgeCreating ||
                          answerGenerationProcessing ||
                          (documentSectionData &&
                            selectedDocument &&
                            !documentSectionData[selectedDocument._id].questions
                              .length)
                        }
                      >
                        {t("Save to Knowledge Base")}
                      </button>
                    </PermissionWrapper>
                  )}
                </PermissionWrapper>
              </div>
            </Container>
            <ProgressSteps currentStep={4} steps={4} />
          </footer>
        </Container>
      </section>
    </>
  );
};

export default GenerateQna;
