import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { useDispatch } from "react-redux";
import closeimg from "../../../../assets/images/close-red.svg";
import search from "../../../../assets/images/search-blk.svg";
import AutoTextArea from "../../../../components/UI/AutoResizeTextArea/AutoResizeTextArea";
import { RichTextArea } from "../../../../components/UI/RichTextArea/RichTextArea";
import {
  deleteQuestionFromDocument,
  generateCustomQuestionAnswer,
  generateQuestionAnswerBasedonNumberOfInput,
  loadMoreQuestionsAndAnswersBasedOnDocument,
  updateQnasSortOrder,
  updateQuestionFromDocument,
} from "../../../../store/knowledge/actions";
import { convertDataToMarkdownFormat } from "../../../../utils/appUtils";

import classNames from "classnames";
import { Container, Draggable } from "react-smooth-dnd";
import closeImg from "../../../../assets/New-images/cross-red.svg";
import editImg from "../../../../assets/New-images/edit-pencil.svg";
import baricon from "../../../../assets/New-images/Icon-drag.svg";
import errorInfo from "../../../../assets/New-images/error-info.svg";

import * as _ from "lodash";
import { useTranslation } from "react-i18next";
import { HelperService } from "../../../../services/helper.service";
import { orderBy } from "lodash";
import Select from "react-select";
import axios from "axios";
import downarrowblue from "../../../../assets/New-images/dropdown-arow-blue.svg";

interface Props {
  agent_id?: string;
  author_id?: string;
  created_at?: string;
  file_id?: string;
  file_name?: string;
  keywords?: string[];
  name?: string;
  organisation_id?: string;
  qas?: [];
  updated_at?: string;
  type?: string;
  _id?: string;
  index?: number;
  section?: any;
  documentLength?: number;
  last_offset?: number;
  retryExecution?: boolean;
  qnasLoading?: boolean;
  questionsLoading?: boolean;
  qasFetchError?: boolean;
  isQuizView?: boolean;
  isSummarised?: boolean;
  isSummarisedWithTypeSummary?: boolean;
  setSelectedQuestions?: (questions: any) => void;
  updatedAnswersHandler?: (answers: any) => void;
  setAnswerGenerationProcessing?: (value: boolean) => void;
  addMoreQuestionHandler?: (e: any, _id: string) => void;
  setAnswerData?: (answerData: any) => void;
  toolkitGenerateQna?: boolean;
  buildKnowledge?: boolean;
  lastQuesRef?: any;
}

const helper = new HelperService();

const QAnsAListSummarised = (props: Props) => {
  const { t } = useTranslation("translation");
  let setInterval: any = null;
  const dispatch = useDispatch();
  const [lastOffset, setLastOffset] = useState(0);
  const [generateQnaForm, setGenerateQnaForm] = useState<any>({
    generate: props.toolkitGenerateQna ? 10 : null,
    generateLoading: false,
    apiCount: 0,
    error: false,
    retry: null,
    retryLoading: false,
    summaryLoading: false,
  });
  const [Qnas, setQnas] = useState<any[]>(props.qas);
  const [executionStarted, setExecutionStarted] = useState(false);
  const [InternalRef, setInternalRef] = useState(null);
  const [qnasLoading, setQnasLoading] = useState(true);
  const [qnAFetchError, setQnAFetchError] = useState(false);
  const [addNewQuestionAnswer, setAddNewQuestionAnswer] = useState({
    question: "",
    answer: "",
    sort_order: 0,
    show: false,
  });
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [query, setQuery] = useState<any>({
    document_id: "",
    question: "",
    answer: "",
    sort_order: 0,
    _id: "",
  });
  const [isTyping, setTyping] = useState(false);
  const questionTypes = [
    {
      value: "tutoring",
      label: t("tutoring"),
    },
    {
      value: "socratic",
      label: t("socratic"),
    },
  ];
  const [questionType, setQuestionType] = useState(questionTypes[0]);
  const [hasGenerateTriggred, setHasGenerateTriggred] = useState(false);

  let source = axios.CancelToken.source();

  useEffect(() => {
    if (props.toolkitGenerateQna || props.buildKnowledge) {
      generateQuestionsHandler("generate", 10);
      return () => {
        source.cancel("CANCEL FROM QnaListSummarised");
      };
    }
  }, [props.toolkitGenerateQna, props.buildKnowledge]);

  useEffect(() => {
    if (
      props.qas &&
      props.qas.length > 0 &&
      !props.retryExecution &&
      !executionStarted
    ) {
      setQnasLoading(false);
      setHasGenerateTriggred(true);
    } else {
      setHasGenerateTriggred(false);
    }
    setQnas(props.qas);
  }, [props.qas]);

  useEffect(() => {
    return () => {
      if (InternalRef) {
        clearInterval(InternalRef);
      }
    };
  }, []);

  useEffect(() => {
    setLastOffset(props.last_offset ? props.last_offset : lastOffset);
  }, [props.last_offset]);

  useEffect(() => {
    setQnAFetchError(props.qasFetchError);
  }, [props.qasFetchError]);

  useEffect(() => {}, [props.retryExecution]);

  const removeKeywordFromList = (index: number) => {
    const oldAnswers = [...props.qas];
    const splicedData: any = oldAnswers[index];
    oldAnswers.splice(index, 1);
    props.updatedAnswersHandler({
      documentId: props._id,
      qas: oldAnswers,
    });

    // Only delete if id is present otherwise let it be
    if (splicedData._id) {
      dispatch(deleteQuestionFromDocument(props._id, splicedData._id));
    }
  };

  const dataChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const index = +e.target.dataset.index;
    const toUpdateValue = e.target.dataset.value;
    const oldAnswers: any[] = [...props.qas];
    const oldQuestionValue = { ...oldAnswers[index] };
    oldAnswers[index][toUpdateValue] = value;
    props.updatedAnswersHandler({
      documentId: props._id,
      qas: oldAnswers,
    });
    if (oldQuestionValue.question !== e.target.value) {
    }
  };

  const onEditorStateChange = (index: number, name: any) => {
    const value = name;
    const toUpdateValue = "answer";
    const oldAnswers: any[] = [...props.qas];
    const oldAnswerValue = oldAnswers[index];
    const convertedData = convertDataToMarkdownFormat(oldAnswerValue.answer);
    const convertedName = convertDataToMarkdownFormat(name);
    oldAnswers[index][toUpdateValue] = value;
    props.updatedAnswersHandler({
      documentId: props._id,
      qas: oldAnswers,
    });
    if (convertedName !== convertedData) {
      setTyping(true);
      setQuery({
        ...query,
        ...oldAnswers[index],
      });
    }
    // dispatch({
    //   type: type.LOCAL_ANSWWER_UPDATE,
    //   payload: {
    //     documentId: props._id,
    //     qas: oldAnswers,
    //   },
    // })
  };

  const startEditQuestionHandler = (index: number) => {
    const oldAnswers: any[] = [...props.qas];
    const oldAnswerValue = oldAnswers[index];
    oldAnswers[index]["hasEditMode"] = oldAnswerValue.hasOwnProperty(
      "hasEditMode"
    )
      ? !oldAnswerValue.hasEditMode
      : true;
    props.updatedAnswersHandler({
      documentId: props._id,
      qas: oldAnswers,
    });
    setQnas([...oldAnswers]);
    // dispatch({
    //   type: type.LOCAL_ANSWWER_UPDATE,
    //   payload: {
    //     documentId: props._id,
    //     qas: oldAnswers,
    //   },
    // })
  };

  const saveQuestionHandler = (question: any, index: number) => {
    try {
      dispatch(
        updateQuestionFromDocument(props._id, question._id, {
          question: question.question,
          answer: convertDataToMarkdownFormat(question.answer),
        })
      );
      startEditQuestionHandler(index);
    } catch (error) {
      console.log("[ error ]", error);
    }
  };

  const previewToggleHandler = async (index?: number) => {
    if (index >= 0) {
      const oldAnswers: any[] = [...props.qas];
      props.setAnswerData({
        organisation_id: props.organisation_id,
        question: oldAnswers[index].question,
        _id: props._id,
        keyword: convertDataToMarkdownFormat(oldAnswers[index].answer),
        text_block_id: oldAnswers[index].text_block_id,
      });
    }
  };

  const generateCustomQuestionAndAnswerHandler = async (index: number) => {
    try {
      if (props.setAnswerGenerationProcessing) {
        props.setAnswerGenerationProcessing(true);
      }
      const oldAnswers: any[] = [...Qnas];
      const questionAnswer = oldAnswers[index];
      const response: any = await dispatch(
        generateCustomQuestionAnswer(props._id, questionAnswer.question)
      );
      delete questionAnswer.newlyAdded;
      questionAnswer._id = response._id;
      questionAnswer.answer = convertDataToMarkdownFormat(
        response.answer.trim().length
          ? response.answer
          : "We are unable to fetch answer based on your question. Please insert manually."
      );
      props.updatedAnswersHandler({
        documentId: props._id,
        qas: oldAnswers,
      });
      if (props.setAnswerGenerationProcessing) {
        props.setAnswerGenerationProcessing(false);
      }
    } catch (error) {
      if (props.setAnswerGenerationProcessing) {
        props.setAnswerGenerationProcessing(false);
      }
    }
  };

  // const updateMoreQuestionHandler = (key: string, value: string) => {
  //   setAddNewQuestionAnswer({
  //     ...addNewQuestionAnswer,
  //     [key]: value
  //   })
  //   const oldAnswers: any[] = [...Qnas]
  //   oldAnswers[0] = {...{
  //     [key]: value,
  //     sort_order: 0,
  //   }}
  //   props.updatedAnswersHandler({
  //     documentId: props._id,
  //     qas: oldAnswers
  //   })
  // }

  const applyDrag = (arr: any, dragResult: any) => {
    const updatedArray: any[] = [...arr];
    const { removedIndex, addedIndex, payload } = dragResult;
    const addedSortOrder = arr[addedIndex].sort_order;
    const removedSortOrder = arr[removedIndex].sort_order;
    updatedArray[removedIndex].sort_order = addedSortOrder;
    updatedArray[addedIndex].sort_order = removedSortOrder;

    if (removedIndex === null && addedIndex === null) return updatedArray;

    const result = [...updatedArray];
    let itemToAdd = payload;
    if (removedIndex !== null) {
      itemToAdd = result.splice(removedIndex, 1)[0];
    }

    if (addedIndex !== null) {
      result.splice(addedIndex, 0, itemToAdd);
    }

    if (result && result.length > 0) {
      const toUpdateQna: any[] = [];
      const sort_order = result[0].sort_order;
      result.map((res: any, index: number) => {
        toUpdateQna.push({
          _id: res._id,
          sort_order: sort_order + (index + 1),
        });
        return {
          ...res,
          sort_order: sort_order + (index + 1),
        };
      });

      dispatch(updateQnasSortOrder(props._id, toUpdateQna));
    }

    props.updatedAnswersHandler({
      documentId: props._id,
      qas: [...result],
    });
    setQnas([...result]);
    return result;
  };

  const generateQuestionsHandler = async (
    formValue: string = "generate",
    max_que: number = 1,
    byPassedGenerateQnaForm = {}
  ) => {
    setHasGenerateTriggred(true);
    try {
      let byPassedGenerateQnaFormAsObject: any = { ...generateQnaForm };
      if (Object.keys(byPassedGenerateQnaForm).length) {
        byPassedGenerateQnaFormAsObject = { ...byPassedGenerateQnaForm };
      }
      setGenerateQnaForm({
        ...byPassedGenerateQnaFormAsObject,
        generateLoading: true,
      });
      if (props.setAnswerGenerationProcessing) {
        props.setAnswerGenerationProcessing(true);
      }
      let maxQue = max_que;
      const response: any = await dispatch(
        generateQuestionAnswerBasedonNumberOfInput(
          props._id,
          +generateQnaForm[formValue] <= 0
            ? max_que
            : +generateQnaForm[formValue],
          source,
          questionType?.value
        )
      );

      if (!response.length && byPassedGenerateQnaFormAsObject.apiCount === 3) {
        setGenerateQnaForm({
          ...byPassedGenerateQnaFormAsObject,
          error: true,
          apiCount: 0,
        });
        if (setInterval) {
          clearTimeout(setInterval);
        }
        return;
      }

      if (!response.length) {
        setInterval = setTimeout(() => {
          const obj: any = {
            ...byPassedGenerateQnaFormAsObject,
            apiCount: byPassedGenerateQnaFormAsObject.apiCount + 1,
          };
          setGenerateQnaForm(obj);
          generateQuestionsHandler("generate", maxQue, obj);
        }, 10 * 1000);
      } else {
        props.updatedAnswersHandler({
          documentId: props._id,
          qas: [...response],
        });
        if (props.setAnswerGenerationProcessing) {
          props.setAnswerGenerationProcessing(false);
        }
        setGenerateQnaForm({
          ...byPassedGenerateQnaFormAsObject,
          generateLoading: false,
          apiCount: 0,
          error: false,
        });
      }
    } catch (error: any) {
      if (props.setAnswerGenerationProcessing) {
        props.setAnswerGenerationProcessing(false);
      }
      setGenerateQnaForm({
        ...generateQnaForm,
        error: true,
      });
    }
  };

  const reGenerateQuestionsHandler = async () => {
    try {
      setGenerateQnaForm({
        ...generateQnaForm,
        retryLoading: true,
      });
      setHasGenerateTriggred(false);

      if (props.setAnswerGenerationProcessing) {
        props.setAnswerGenerationProcessing(true);
      }
      const response: any = await dispatch(
        generateQuestionAnswerBasedonNumberOfInput(
          props._id,
          generateQnaForm["retry"] ? +generateQnaForm["retry"] : 2,
          source,
          questionType.value
        )
      );
      const fetchedQnas = orderBy(
        Qnas.concat(response),
        ["sort_order"],
        ["asc"]
      );
      setQnas([...fetchedQnas]);
      props.updatedAnswersHandler({
        documentId: props._id,
        qas: fetchedQnas,
      });
      setHasGenerateTriggred(true);
      setGenerateQnaForm({
        ...generateQnaForm,
        retryLoading: false,
      });
    } catch (error: any) {
      if (props.setAnswerGenerationProcessing) {
        props.setAnswerGenerationProcessing(false);
      }
      setGenerateQnaForm({
        ...generateQnaForm,
        retryLoading: false,
      });
      setHasGenerateTriggred(true);
    }
  };

  const refreshQuestionsHandler = async () => {
    try {
      setGenerateQnaForm({
        ...generateQnaForm,
        summaryLoading: true,
      });
      setHasGenerateTriggred(false);

      if (props.setAnswerGenerationProcessing) {
        props.setAnswerGenerationProcessing(true);
      }
      const { qas }: any = await dispatch(
        loadMoreQuestionsAndAnswersBasedOnDocument(
          props,
          0,
          Number.MAX_SAFE_INTEGER,
          null
        )
      );
      setQnas([...qas]);
      props.updatedAnswersHandler({
        documentId: props._id,
        qas: qas,
      });
      setHasGenerateTriggred(true);
      setGenerateQnaForm({
        ...generateQnaForm,
        summaryLoading: false,
      });
    } catch (error: any) {
      if (props.setAnswerGenerationProcessing) {
        props.setAnswerGenerationProcessing(false);
      }
      setHasGenerateTriggred(true);
      setGenerateQnaForm({
        ...generateQnaForm,
        summaryLoading: false,
      });
    }
  };

  return (
    <React.Fragment>
      {props.isSummarisedWithTypeSummary &&
        props.isSummarised &&
        Qnas &&
        Qnas.length > 0 && (
          <>
            {!generateQnaForm.summaryLoading && (
              <div>
                {t("Click 'Refresh' to check if there are any unloaded Q&A")}{" "}
                <span>
                  {" "}
                  <button
                    className="btn btn-link third-step mr-3"
                    onClick={() => {
                      refreshQuestionsHandler();
                    }}
                  >
                    {t("Refresh")}
                  </button>
                </span>
              </div>
            )}

            {generateQnaForm.summaryLoading && (
              <div className="doc-processing d-flex flex-wrap align-items-center p-0 justify-content-center mb-3">
                <div className="col-status">
                  <span className="doc-loader spinner-width-20"></span>
                </div>
                <div className="pl-3 font-1">
                  {t("Generating tutoring questions")}....
                </div>
              </div>
            )}
          </>
        )}

      {!generateQnaForm.generateLoading &&
        !generateQnaForm.error &&
        (!Qnas || Qnas.length === 0) && (
          <div className="row">
            <div className="col-md-12">
              <div className="mb-3">
                <h2 className="clr-primary">
                  {t("How many questions would you like to generate?")}
                </h2>
                <div className="font-16 d-inline-block mr-2">
                  {t("I want to generate a maximum of")}
                  <input
                    type="text"
                    className="clr-primary text-center radius-2 mx-2 px-1 border-grey py-2"
                    style={{ width: "2.5rem" }}
                    defaultValue={generateQnaForm.generate}
                    onChange={(event) => {
                      if (event.target.value.match(/^[0-9]*(\.[0-9]{0,2})?$/)) {
                        if (+event.target.value > 50) {
                          helper.showToaster(
                            t(
                              "Maximum number of question that can be generated is 50."
                            )
                          );
                          return;
                        }

                        setGenerateQnaForm({
                          ...generateQnaForm,
                          generate: event.target.value,
                        });
                      }
                    }}
                  />
                  {props.type === "summary" && (
                    <div className="d-inline-block" style={{ width: "110px" }}>
                      <Select
                        menuPosition="fixed"
                        classNamePrefix="select-target-qna"
                        className="select-target-qnas select-blue"
                        isSearchable={false}
                        components={{
                          DropdownIndicator: () => (
                            <img src={downarrowblue} alt="" />
                          ),
                          IndicatorSeparator: () => null,
                          ClearIndicator: () => null,
                        }}
                        options={questionTypes}
                        onChange={(e: any) => {
                          setQuestionType(e);
                        }}
                        value={questionType}
                      />
                    </div>
                  )}
                  {props.type === "summary"
                    ? t(" questions")
                    : t("tutoring questions")}
                </div>
                <div className="font-16 d-inline-block mr-2 pb-4">
                  {t(
                    "Note: Number of questions generated may vary based on document length."
                  )}
                </div>
                <button
                  className="btn btn-primary font-600"
                  onClick={() => {
                    generateQuestionsHandler("generate");
                  }}
                >
                  {t("Generate")}
                </button>
              </div>
              <div className="d-flex align-items-center doc-count"> </div>
            </div>
          </div>
        )}

      <div className="generated-questions generated-question-answers drag-questions-wrap select-quiz-ques">
        {Qnas && Qnas.length > 0 && (
          //   <button
          //     className="btn btn-link mb-32 p-0"
          //     onClick={loadMoreQnasHandler}
          //   >
          //     {t("Load More Questions")}
          //   </button>
          <>
            {generateQnaForm.retryLoading && props.isSummarised && (
              <div className="doc-processing d-flex flex-wrap align-items-center p-0 mb-3">
                <div className="col-status">
                  <span className="doc-loader spinner-width-20"></span>
                </div>
                <div className="pl-1 font-1 font-600">
                  {t("Generating tutoring questions")}....
                </div>
              </div>
            )}

            {!generateQnaForm.retryLoading && (
              <div className="mb-3 d-flex flex-wrap justify-content-between">
                <div className="font-16 font-600 d-inline-block mr-2">
                  {t("Generate another")}
                  <input
                    type="text"
                    className="clr-primary text-center radius-2 mx-2 px-1 border-grey py-2"
                    style={{ width: "2.5rem" }}
                    defaultValue={
                      generateQnaForm.retry ? generateQnaForm.retry : 2
                    }
                    onChange={(event) => {
                      if (event.target.value.match(/^[0-9]*(\.[0-9]{0,2})?$/)) {
                        if (+event.target.value > 50) {
                          helper.showToaster(
                            t(
                              "Maximum number of question that can be generated is 50."
                            )
                          );
                          return;
                        }

                        setGenerateQnaForm({
                          ...generateQnaForm,
                          retry: event.target.value,
                        });
                      }
                    }}
                  />
                  {props.type === "summary" && (
                    <div className="d-inline-block" style={{ width: "110px" }}>
                      <Select
                        menuPosition="fixed"
                        classNamePrefix="select-target-qna"
                        className="select-target-qnas select-blue"
                        isSearchable={false}
                        components={{
                          DropdownIndicator: () => (
                            <img src={downarrowblue} alt="" />
                          ),
                          IndicatorSeparator: () => null,
                          ClearIndicator: () => null,
                        }}
                        options={questionTypes}
                        onChange={(e: any) => {
                          setQuestionType(e);
                        }}
                        value={questionType}
                      />
                    </div>
                  )}

                  {props.type === "summary"
                    ? t(" questions")
                    : t("  tutoring questions")}
                </div>
                <button
                  className="btn btn-link font-600"
                  onClick={reGenerateQuestionsHandler}
                >
                  {t("Generate Questions")}
                </button>
              </div>
            )}
          </>
        )}
        {generateQnaForm.error && (
          <div className="doc-processing d-flex flex-wrap align-items-center p-0 mb-3">
            <img src={errorInfo} alt="Error info" />
            <div className="col-msg pl-3 font-1">
              <div className="mb-1">
                {!props.toolkitGenerateQna && (
                  <span>
                    <span
                      className="font-600"
                      style={{
                        color: "#D00026",
                      }}
                    >
                      {t("Opps, we have encountered an error.")}
                    </span>
                    <br />
                  </span>
                )}

                {t(
                  "Walter was unable to generate questions and answers. Would you like to try again?"
                )}
              </div>
              <button
                className="btn btn-primary font-600"
                onClick={() => {
                  setGenerateQnaForm({
                    ...generateQnaForm,
                    apiCount: 0,
                    error: false,
                  });
                  generateQuestionsHandler("generate");
                }}
              >
                {t("Try Again")}
              </button>
            </div>
          </div>
        )}

        {(!Qnas || Qnas.length === 0) &&
          props.isSummarised &&
          generateQnaForm.generateLoading &&
          !generateQnaForm.error && (
            <div className="doc-processing d-flex flex-wrap align-items-center p-0">
              <div className="col-status">
                <span className="doc-loader spinner-width-20"></span>
              </div>
              <div className="col-msg pl-3 font-1">
                {t(
                  "Generating Q&A from document....This process might take up to 10 mins While waiting, you may explore other parts of the platform. You will receive a notification when the Q&As have been generated."
                )}
              </div>
            </div>
          )}

        <Container
          dragHandleSelector=".column-drag-handle"
          onDrop={(e) => (props.isQuizView ? () => {} : applyDrag(Qnas, e))}
          nonDragAreaSelector={".que-ans-edit"}
        >
          {Qnas &&
            Qnas.map((question: any, index: number) => {
              return (
                <Draggable
                  key={props._id + "draggable_" + index}
                  className={classNames({
                    "p-0": props.isQuizView,
                    "pr-0": true,
                  })}
                >
                  <div
                    className={classNames({
                      "draggable-item web-links column-drag-handle": true,
                      "edit-drag": !question.hasEditMode,
                    })}
                  >
                    {!props.isQuizView && (
                      <button className="btn">
                        <Image src={baricon} />
                      </button>
                    )}

                    <ul
                      className="list-unstyled ques-list"
                      key={props._id + "_ANS_" + index}
                      id={props._id + "_ANS_" + index}
                    >
                      {/* {!props.isQuizView && (
                        <button className="btn py-0">
                          <Image
                            src={closeimg}
                            onClick={() => removeKeywordFromList(index)}
                          />
                        </button>
                      )} */}
                      {!question.hasOwnProperty("newlyAdded") &&
                        !props.isQuizView &&
                        question.answer && (
                          <button className="btn py-0">
                            <Image
                              src={search}
                              onClick={() => previewToggleHandler(index)}
                            />
                          </button>
                        )}
                      <li className={question.hasEditMode && "que-ans-edit"}>
                        <span className="font-weight-bold mr-2">Q:</span>
                        {/* <div className="divtext" contentEditable onChange={(e: any) => {
                                console.log('e', e)
                              }}>{question?.question || ""}</div> */}
                        {/* <textarea
                              key={props._id + "_QUE_" + index}
                              className="form-control"
                              data-value={"question"}
                              data-index={index}
                              value={question?.question || ""}
                              onChange={(e: any) => {
                                dataChangeHandler(e)
                              }}
                              readOnly={!question.hasEditMode}
                            /> */}

                        <AutoTextArea
                          key={props._id + "_QUE_" + index}
                          type="text"
                          className="form-control"
                          data-value={"question"}
                          data-index={index}
                          value={question?.question || ""}
                          onChangeHandler={dataChangeHandler}
                          readOnly={!question.hasEditMode}
                        />
                        
                      </li>
                      <li className={question.hasEditMode && "que-ans-edit"}>
                        {question.hasOwnProperty("newlyAdded") &&
                          question.newlyAdded &&
                          question.answer.trim().length === 0 &&
                          question.question.trim().length > 0 && (
                            <>
                              <span className="font-weight-bold mr-2 ans-varient">
                                A:
                              </span>
                              <button
                                className="btn btn-link font-sm py-0"
                                onClick={() => {
                                  generateCustomQuestionAndAnswerHandler(index);
                                }}
                              >
                                {t("Generate Answer")}
                              </button>
                            </>
                          )}
                        {/* <input
                              key={props._id + '_IP_' + index}
                              type='text'
                              className='form-control'
                              data-value={'answer'}
                              data-index={index}
                              value={question?.answer || ''}
                              onChange={dataChangeHandler}
                            /> */}

                        {!question.hasOwnProperty("newlyAdded") &&
                          question.answer && (
                            <>
                              <span className="font-weight-bold mr-2 ans-varient">
                                {t("A:")}
                              </span>
                              <RichTextArea
                                readOnly={!question.hasEditMode}
                                showRemoveIcon={false}
                                key={props._id + "_TA_IP_" + index}
                                index={index}
                                removeIcon={closeimg}
                                item={{
                                  text: question?.answer || "",
                                }}
                                onEditorStateChangeHandler={(
                                  updatedIndex,
                                  value
                                ) => onEditorStateChange(updatedIndex, value)}
                                onRemoveGeetingHandler={(removedIndex) => {}}
                              />
                            </>
                          )}
                      </li>
                      {!props.isQuizView && !question.hasEditMode && (
                        <div className="edit-action-btns">
                          <button
                            className="btn py-0"
                            onClick={() => {
                              startEditQuestionHandler(index);
                            }}
                          >
                            <Image src={editImg} alt="" />
                          </button>
                          <button
                            className="btn py-0"
                            onClick={() => {
                              removeKeywordFromList(index);
                            }}
                          >
                            <Image src={closeImg} alt="" />
                          </button>
                        </div>
                      )}
                      {question.hasEditMode && (
                        <div className="text-right edit-done-btns pb-2">
                          <button
                            className="btn btn-primary font-sm font-weight-normal py-1 px-2"
                            onClick={() => {
                              saveQuestionHandler(question, index);
                            }}
                            disabled={question.newlyAdded && !question.answer}
                          >
                            {t("Save")}
                          </button>
                          <button
                            className="btn btn-outline-primary font-sm font-weight-normal py-1 px-2 ml-2"
                            onClick={() => {
                              startEditQuestionHandler(index);
                            }}
                          >
                            {t("Cancel")}
                          </button>
                        </div>
                      )}
                      <div ref={props?.lastQuesRef}/>
                    </ul>
                  </div>
                </Draggable>
              );
            })}
        </Container>

         {(generateQnaForm.error || hasGenerateTriggred) &&
          props.addMoreQuestionHandler && (
            <div className="text-center create-from-scratch">
              <div className="my-3 font-16">
                {/* {t("Can’t find your question and answer ?")} */}
                   <button
                  className="btn btn-outline-primary"
                  onClick={(e) => {
                    props.addMoreQuestionHandler(e, props._id);
                  }}
                >
                  <span className="mr-3">+</span>
                  {t("Create from Scratch")}
                </button>
              </div>
            </div>
          )}
       </div>
      {/* {Qnas &&
        Qnas.length === 0 &&
        (!addNewQuestionAnswer || !addNewQuestionAnswer.show) &&
        qnAFetchError &&
        !qnasLoading && (
          <div className="add-ques-error mb-3">
            {t(
              "We couldn’t generate questions for this document. Please click on the “Add Now” button to type your own question. Alternatively,"
            )}{" "}
            <button
              className="btn btn-link font-sm py-0"
              onClick={addMoreQuestionHandler}
            >
              {t("click here")}
            </button>{" "}
            {t("to start adding.")}
          </div>
        )} */}

      {/* {Qnas && Qnas.length === 0 && qnAFetchError && !qnasLoading && (
        <PermissionWrapper>
          <button
            className="btn btn-link p-0"
            onClick={tryLoadingMoreQnasHandler}
          >
            {t("Try Loading Questions Again")}
          </button>
        </PermissionWrapper>
      )} */}
    </React.Fragment>
  );
};

// QAnsAList.propTypes = {

// }

export default QAnsAListSummarised;
