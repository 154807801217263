import React, { useEffect, useState } from "react";
import { Col, Container, ProgressBar, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import chatImg1 from "../../../../assets/New-images/chatbotImg1.svg";
import chatImg2 from "../../../../assets/New-images/chatbotImg2.svg";
import createQuizImg from "../../../../assets/New-images/create-quiz.svg";
import takingNotesImg from "../../../../assets/New-images/taking-notes-img.svg";
import { CHAT_CUSTOMISATION, SUPPORT } from "../../../../config";
import {
  generateQuestionAnswerBasedonNumberOfInput,
  getDocumentProcess,
  getToolkitQuizQuestions,
} from "../../../../store/knowledge/actions";
import "../../index.scss";
import inviteLearnersImg from "../../../../assets/New-images/toolkit-invite-learners-img.svg";
import crownImg from "../../../../assets/New-images/tootlkit-crown-img.svg";
import axios from "axios";

export const ToolKitProgress = (props: any) => {
  const { t } = useTranslation("translation");
  const useLocatio = useLocation();
  const dispatch = useDispatch();
  const history: any = useHistory();
  const [groupIds, setGroupIds] = useState(null);
  const [historyData, setHistoryData] = useState(null);
  const [docId, setDocId] = useState(null);
  const [quiz_ID, setQuizID] = useState(null);
  const [summaryDocumentId, setSummaryDocumentId] = useState(null);
  const [progressData, setProgressData] = useState({
    now: 0,
    label: 0,
  });

  let source = axios.CancelToken.source();

  useEffect(() => {
    const query = new URLSearchParams(useLocatio.search);
    const documentId = query.get("docsum");
    const summaryDocId = query.get("summary_document_id");
    const groupId = query.get("group");
    const quizId = query.get("quizId");
    const queTypes = query.get("queTypes");
    const type = query.get("type");
    const firstDocument = query.get("first");
    const queCount = query.get("queCount");
    const through = query.get("through");
    const fileType = query.get("fileType");
    const summaryTypeDocuments = query.get("summaryTypeDocuments");

    setDocId(documentId);
    setSummaryDocumentId(summaryDocId);
    setGroupIds(groupId);
    setQuizID(quizId);
    processDocuments(
      documentId,
      summaryDocId,
      groupId,
      quizId,
      queTypes,
      type,
      queCount,
      firstDocument,
      through,
      fileType,
      summaryTypeDocuments
    );

    return () => {
      source.cancel("CANCEL FROM QnaListSummarised");
    };
  }, []);

  const processDocuments = async (
    documentId: any,
    summaryDocumentId: any,
    groupId: any,
    quizId: any,
    queTypes: any,
    type: string,
    queCount: any,
    first?: string,
    through?: string,
    fileType?: string,
    summaryTypeDocuments?: any
  ) => {
    try {
      const documents = documentId.split(",");
      const que = queTypes?.split(",");
      const tempSummaryDocumentId = summaryDocumentId ? summaryDocumentId.split(",") : [];
      const tempSummaryTypeDocuments = summaryTypeDocuments ? summaryTypeDocuments.split(",") : [];
      const allTempDocuments = tempSummaryDocumentId.concat(tempSummaryTypeDocuments);
      const totalPercentage = 100;
      const eachDocumentPercentage = +(100 / documents.length).toFixed(2);
      let documentIndex = 0;
      let now = 0;
      let label = 0;
      let progressData: any[] = [];
      const processDocument = async () => {
        if (documentIndex > documents.length - 1) {
          const throughPage = through === "questionBank" ? `&through=${through}` : "";
          const file = fileType ? `&fileType=${fileType}` : "";
          const ids = allTempDocuments && allTempDocuments.length > 0 ? `&document=${allTempDocuments.join(",")}` : ""

          await history.push(
            `${
              props.redirectUrl
            }?group=${groupId}${ids}&quizId=${
              props.quizId || quizId
            }&queTypes=${que}&type=${type}&queCount=${queCount}${throughPage}${file}`
          );
          return;
        }
        const response: any = await dispatch(
          getDocumentProcess(documents[documentIndex])
        );
        if (
          response.progress >= 1 &&
          !props.learningOutcomes &&
          !props.genQna &&
          !props.smartQuiz
        ) {
          //If not learning outcomes and generate knowledge flows
          now = eachDocumentPercentage * (documentIndex + 1);
          label = eachDocumentPercentage * (documentIndex + 1);
          setProgressData({
            ...progressData,
            now,
            label,
          });
          documentIndex++;
          processDocument();
        } else if (
          props.learningOutcomes &&
          response.progress >= 1 &&
          response.progress_flags &&
          response.progress_flags.is_learningoutcome_generation_done
        ) {
          //If learning outcomes flow
          now = eachDocumentPercentage * (documentIndex + 1);
          label = eachDocumentPercentage * (documentIndex + 1);
          setProgressData({
            ...progressData,
            now,
            label,
          });
          documentIndex++;
          processDocument();
        } else if (
          props.genQna &&
          response.progress >= 1 &&
          documentIndex < documents.length - 1
        ) {
          //If generate knowledge flow and document is not last document
          now = eachDocumentPercentage * (documentIndex + 1);
          label = eachDocumentPercentage * (documentIndex + 1);
          setProgressData({
            ...progressData,
            now,
            label,
          });
          documentIndex++;
          processDocument();
        } else if (
          props.smartQuiz &&
          response.progress >= 1 &&
          documentIndex < documents.length - 1
        ) {
          //If Smart Quiz flow and document is not last document
          now = eachDocumentPercentage * (documentIndex + 1);
          label = eachDocumentPercentage * (documentIndex + 1);
          setProgressData({
            ...progressData,
            now,
            label,
          });
          documentIndex++;
          processDocument();
        } else {
          const forceQnaCall =
            props.genQna &&
            response.progress >= 1 &&
            documentIndex >= documents.length - 1;

          const forceQnaCallSmartQuiz = props.smartQuiz &&
          response.progress >= 1 &&
          documentIndex >= documents.length - 1;

          if (forceQnaCall) {
            //If generate knowledge flow, if document is last document and progress is 100%
            generateQuestionAndAnswerHandler(
              first,
              summaryDocumentId,
              groupId,
              quizId,
              queTypes,
              type,
              allTempDocuments
            );
            return;
          } else if(forceQnaCallSmartQuiz) {
            generateQuestionAndAnswerForSmartQuizHandler(
              first,
              summaryDocumentId,
              groupId,
              quizId,
              queTypes,
              type,
              queCount,
              through,
              fileType,
              allTempDocuments
            );
            return;
          } else {
            if (
              (props.genQna || props.smartQuiz) &&
              response.progress >= 0.9 &&
              documentIndex >= documents.length - 1
            ) {
              //set progress to 90% if generate knowledge flow
              setProgressData({
                ...progressData,
                now: +(
                  (0.9 * 100 * eachDocumentPercentage) / totalPercentage +
                  now
                ).toFixed(2),
                label: +(
                  (0.9 * 100 * eachDocumentPercentage) / totalPercentage +
                  now
                ).toFixed(2),
              });
            } else {
              setProgressData({
                ...progressData,
                now: +(
                  (response.progress * 100 * eachDocumentPercentage) /
                    totalPercentage +
                  now
                ).toFixed(2),
                label: +(
                  (response.progress * 100 * eachDocumentPercentage) /
                    totalPercentage +
                  now
                ).toFixed(2),
              });
            }
            setTimeout(() => {
              processDocument();
            }, 2000); // 1 sec duration
          }
        }
      };
      processDocument();
    } catch (error) {
      console.log("error", error);
    }
  };

  const generateQuestionAndAnswerHandler = (
    id: string,
    summaryId: any,
    groupId: any,
    quizId: any,
    queTypes: any,
    type: any,
    allTempDocuments: any
  ) => {
    let apicall = 0;
    let now = 90;
    let label = 90;
    const func = async () => {
      const res: any = await dispatch(
        generateQuestionAnswerBasedonNumberOfInput(id, 10, source, "tutoring")
      );
      if (res && res.length <= 0 && apicall < 60) {
        if ((apicall + 1) % 6 === 0) {
          now += 1;
          label += 1;
        }
        setProgressData({
          ...progressData,
          now: now,
          label: label,
        });
        apicall++;
        setTimeout(() => {
          func();
        }, 5000); // 5 sec duration
      } else {
        setProgressData({
          ...progressData,
          now: 100,
          label: 100,
        });

        const question = props.genQna && allTempDocuments && allTempDocuments.length > 0 ? `&question=${allTempDocuments.join(",")}` : "";
        const ids = allTempDocuments && allTempDocuments.length > 0 ? `&document=${allTempDocuments.join(",")}` : ""
        await history.push(
          `${props.redirectUrl}?group=${groupId}${ids}&quizId=${
            props.quizId || quizId
          }&queTypes=${queTypes}&type=${type}${question}`
        );
      }
    };
    func();
  };

  const generateQuestionAndAnswerForSmartQuizHandler = (
    id: string,
    summaryId: any,
    groupId: any,
    quizId: any,
    queTypes: any,
    type: any,
    queCount: any,
    through: any,
    fileType: any,
    allTempDocuments: any
  ) => {
    let apicall = 0;
    let now = 90;
    let label = 90;
    let data: any = {
      question_count: 10,
    };
    const que = queTypes.split(",")
    if (que && que.length > 0) {
      data = {
        ...data,
        qna_types: que,
      };
    }
    const summaryDocumentId =  summaryId.split(",");
    const func = async () => {
      const res: any = await dispatch(
        getToolkitQuizQuestions(summaryDocumentId[0], data, source)
      );
      if (res && res.questions.length <= 0 && apicall < 60) {
        if ((apicall + 1) % 6 === 0) {
          now += 1;
          label += 1;
        }
        setProgressData({
          ...progressData,
          now: now,
          label: label,
        });
        apicall++;
        setTimeout(() => {
          func();
        }, 5000); // 5 sec duration
      } else {
        setProgressData({
          ...progressData,
          now: 100,
          label: 100,
        });

        const throughPage = through === "questionBank" ? `&through=${through}` : "";
        const file = fileType ? `&fileType=${fileType}` : "";
        const ids = allTempDocuments && allTempDocuments.length > 0 ? `&document=${allTempDocuments.join(",")}` : ""

        await history.push(
          `${
            props.redirectUrl
          }?group=${groupId}${ids}&quizId=${
            props.quizId || quizId
          }&queTypes=${queTypes}&type=${type}&queCount=${queCount}${throughPage}${file}`
        );
      }
    };
    func();
  };

  return (
    <React.Fragment>
      <section className="page-mid-wraper h-without-foter">
        <Container>
          <>
            <div className="d-flex flex-wrap justify-content-center clr-primary">
              <h1 className="mb-2 font-weight-bold">
                {props.progressHeading
                  ? props.progressHeading
                  : t("Processing Document(s)")}
              </h1>
            </div>
            <div className="mb-32 font-16 text-center font-600">
              {props.progressStatement}
            </div>
          </>
          <div className="pdf-section-wrapper mb-32">
            <div className="row process-documents align-items-center">
              <div className="col-md-4 col-lg-3">
                <img src={takingNotesImg} alt={t("")} />
              </div>
              <div className="col-md-8 col-lg-9">
                <div className="font-600 font-1 d-flex justify-content-center">
                  <span className="doc-loader spinner-width-20 mr-2"></span>
                  {props.waitText}
                </div>
                <ProgressBar
                  now={progressData.now}
                  // label={`${progressData.label}%`}
                  className="toolkit-progress-bar m-3"
                />
                <p className="font-600 font-16">
                  {props.notificationText}
                  <br />
                  {t(
                    "Please ensure that your notifications are on so that you don’t miss out on the update."
                  )}
                  <br />
                  <span className="clr-primary font-weight-bold cursor-pointer">
                    {t("Check notification settings")}
                  </span>{" "}
                </p>
              </div>
            </div>
          </div>
        </Container>

        <Container className="mt-4">
          <h2 className="font-600 mb-3 font-1">
            {t("While waiting, you can choose to do the following.")}
          </h2>
          <Row className={"mt-2 row-12"}>
            <Col md={4} className="mb-4">
              <div
                className="quick-start-box p-3 d-flex  align-items-center text-left"
                onClick={() => history.push(CHAT_CUSTOMISATION)}
              >
                <img src={chatImg1} alt="" />
                <div className="   pl-3">
                  <div className="inner-text">
                    <h2 className="mb-0">{t("Manage your chatbot")}</h2>
                    <span className="description">
                      {t("To match your brand")}
                    </span>
                  </div>
                </div>
              </div>
            </Col>
            {!props.buildKnowledge && (
              <Col md={4} className="mb-4">
                <div
                  className="quick-start-box p-3 d-flex align-items-center text-left"
                  onClick={() => {
                    history.push(
                      `/toolkit/home?group=${groupIds}&reference=create-quiz`
                    );
                  }}
                >
                  <img src={createQuizImg} alt="" />
                  <div className="inner-box pl-3">
                    <div className="inner-text">
                      <h2 className="mb-0">{t("Build a Quiz")}</h2>
                      <span className="description">
                        {t("View your other files")}
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
            )}
            <Col md={4} className="mb-4">
              <div
                className="quick-start-box p-3 d-flex align-items-center text-left"
                onClick={() => {
                  history.push(SUPPORT);
                }}
              >
                <img src={chatImg2} alt="" />
                <div className="inner-box pl-3">
                  <div className="inner-text">
                    <h2 className="mb-0">{t("Learn how to setup")}</h2>
                    <span className="description">
                      {t("View our video guides")}
                    </span>
                  </div>
                </div>
              </div>
            </Col>
            {props.buildKnowledge && (
              <Col md={4} className="mb-4">
                <div
                  className="quick-start-box p-3 d-flex align-items-center text-left"
                  onClick={() => {
                    history.push(SUPPORT);
                  }}
                >
                  <img src={inviteLearnersImg} alt="" />
                  <div className="inner-box pl-3">
                    <div className="inner-text">
                      <h2 className="mb-0">
                        {t("Invite learners ")}
                        <span>
                          <img src={crownImg} alt="invite-learners-crown" />
                        </span>
                      </h2>
                      <span className="description">
                        {t(
                          "Start adding learners to use your newly created chatbot"
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default ToolKitProgress;
