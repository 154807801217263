import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import downarrowblk from "../../assets/images/chevron-down-blk.svg";
import crossRed from "../../assets/New-images/close-icon-red.svg";

import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import QuizzesButtonsGroup from "../../components/UI/QuizzesButtonsGroup/QuizzesButtonsGroup";
import SunEditorUI from "../../components/UI/SunEditor/SubEditor";
import {
  AllUserGroups,
  getButtonOptions,
} from "../../store/questions/selector";
import classNames from "classnames";
import CustomOption from "./OldPortalComponents/Dropdown/customOption";
import { useTranslation } from "react-i18next";
import { getLinkedIntentsBasedOnQuizAndQuestions, getLinkedQuestionsBasedOnQuizAndQuestions } from "../../store/quizzes/actions";

function ScoreRange(props: any) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const buttonOptions = useSelector(getButtonOptions);
  const allGroups = useSelector(AllUserGroups);
  const [buttonsList, setButtonOptionsList] = useState([]);
  const [relevantIntents, setReleventIntents] = useState([]);
  const [hasIdPresent, setHasIdPresent] = useState( null );
  const [quizId, setQuizId] = useState( null );

  useEffect(() => {
    if (!props.quizId) {
      return
    }
    fetchAllLinkedQuizQuestions()
    setButtonOptionsList(transformData(props.buttonsData));
  }, []);

  const fetchAllLinkedQuizQuestions = async () => {
    if ( !props.hasIdPresent || !props.isFeedback || !props.conditionData || !props.conditionData.test || !props.conditionData.test.question_id) {
      return
    }

    try {
      const response: any = await dispatch(getLinkedIntentsBasedOnQuizAndQuestions(props.quizId, props.conditionData.test.question_id));
      setReleventIntents(response.intents ? response.intents.slice(0, 5) : [])
    } catch (error) {
      setReleventIntents([])
    }
  }

  useEffect(() => {
    if (
      !props.quizId 
      || !props.hasIdPresent
      || (
        hasIdPresent && quizId
      )
    ) {
      return
    }

    setHasIdPresent( props.hasIdPresent );
    setQuizId( props.quizId );
  }, [
    props.hasIdPresent,
    props.quizId,
    hasIdPresent,
    quizId
  ]);

  const transformQuestionOptions = (options: any) => {
    let temp: any[] = [];
    if (options) {
      options.forEach((item: any) => {
        temp.push({
          value: item.id,
          label: item.text,
        });
      });
    }
    return temp;
  };

  const transformData = (data: any) => {
    let temp: any = [];
    data.forEach((element: any) => {
      if (!element.buttonId) {
        temp.push({
          ...element,
          buttonId: uuidv4(),
        });
      } else {
        temp.push({
          ...element,
        });
      }
    });
    return temp;
  };

  const rangeHtml = () => {
    return (
      <>
        <Col md={6} className="d-flex align-items-center">
          <label className="font-16 font-600 clr-black mb-0 text-nowrap mr-2">
            {t("ranges from (inclusive)")}
          </label>
          <input
            type="number"
            className="form-control h-auto radius-md mr-2"
            min="0"
            value={
              props.conditionData.test.min_score
                ? props.conditionData.test.min_score
                : "0"
            }
            onChange={(event) =>
              props.onConditionFieldChange(
                event.target.value,
                "minScore",
                props.conditionIndex
              )
            }
          />
          <label className="font-16 font-600 clr-black mb-0 text-nowrap mr-2">
            {t("to (inclusive)")}
          </label>
          <input
            type="number"
            className="form-control h-auto radius-md"
            min="0"
            max={props.totalQuizScore.toString()}
            value={
              props.conditionData.test.max_score
                ? props.conditionData.test.max_score
                : "0"
            }
            onChange={(event) =>
              props.onConditionFieldChange(
                event.target.value,
                "maxScore",
                props.conditionIndex
              )
            }
          />
          <button
            className="btn btn-outline-danger ml-4 px-0 w-100"
            onClick={() => {
              props.removeScoreCondition(props.conditionIndex);
            }}
          >
            <img src={crossRed} alt="" />
          </button>
        </Col>
      </>
    );
  };

  const getGroupLabel = (optionId: any, options: any[]) => {
    let label = "";
    options.forEach((item) => {
      if (item.value === optionId) {
        label = item.label;
      }
    });

    return label;
  };

  const getOptionLabel = (optionId: any, options: any) => {
    let label = "";
    options &&
      options.forEach((item: any) => {
        if (item.id === optionId) {
          label = item.text;
        }
      });

    return label;
  };

  const selectKnowledgeHandler = (id: string) => {
    console.log('[ selectKnowledgeHandler ]', id)
    
  };

  return (
    <div
      className={classNames({
        "quiz-response-wrap p-lg-4 p-3 mb-3": true,
        "d-none": (!props.isFeedback && props.conditionData.test.question_id) || (props.isFeedback && !props.conditionData.test.question_id),
      })}
    >
      <Row className="no-gutters mb-4">
        <Col md={6} className="d-flex align-items-center pr-md-3 mb-2 mb-md-0">
          <label className="font-16 font-600 clr-black mb-0 mr-2">{t("If")}</label>
          <Select
            isSearchable
            isDisabled={!props.isFeedback}
            // isMulti
            className="select-target w-100"
            classNamePrefix="select-target-prefix"
            components={props.isNested ? {
              Option: CustomOption,
              DropdownIndicator: () => (
                <img src={downarrowblk} alt="dropdown arrow" />
              ),
              IndicatorSeparator: () => null,
              ClearIndicator: () => null,
            }: {
              DropdownIndicator: () => (
                <img src={downarrowblk} alt="dropdown arrow" />
              ),
              IndicatorSeparator: () => null,
              ClearIndicator: () => null,
            }}
            placeholder={t("Select user groups")}
            options={!props.isFeedback ? [
              {
                value: 0,
                label: t("All quiz questions"),
              },
            ] : [ ...props.quizQuestionDropdownData ]}
            value={
              props.conditionData.test.question_id
                ? {
                    value: props.conditionData.test.question_id,
                    label: props.questionIdLabelMap[
                      props.conditionData.test.question_id
                    ]
                      ? props.questionIdLabelMap[
                          props.conditionData.test.question_id
                        ].label
                      : "",
                  }
                : {
                    value: 0,
                    label: t("All quiz questions"),
                  }
            }
            onChange={(value: any) =>
              props.onConditionQuestionDropdownChange(
                value.value,
                "quizQuestion",
                props.conditionIndex
              )
            }
          />
        </Col>
        {(!props.conditionData.test.question_id ||
          props.conditionData.test.question_id === 0) &&
          rangeHtml()}
        {props.conditionData.test.question_id !== undefined &&
          props.conditionData.test.question_id !== 0 &&
          props.questionIdLabelMap[props.conditionData.test.question_id] &&
          props.questionIdLabelMap[props.conditionData.test.question_id]
            .type !== "checkbox" &&
          props.questionIdLabelMap[props.conditionData.test.question_id]
            .type !== "radio" &&
          rangeHtml()}
        {props.conditionData.test.question_id !== undefined &&
          props.conditionData.test.question_id !== 0 &&
          props.questionIdLabelMap[props.conditionData.test.question_id] &&
          (props.questionIdLabelMap[props.conditionData.test.question_id]
            .type === "checkbox" ||
            props.questionIdLabelMap[props.conditionData.test.question_id]
              .type === "radio") && (
            <Col md={6} className="d-flex align-items-center">
              <label className="font-16 font-600 clr-black mb-0 mr-2 text-nowrap">{t("answer is")}</label>
              <Select
                className="select-target w-100 mr-2"
                classNamePrefix="select-target-prefix"
                components={{
                  DropdownIndicator: () => (
                    <img src={downarrowblk} alt="dropdown arrow" />
                  ),
                  IndicatorSeparator: () => null,
                  ClearIndicator: () => null,
                }}
                placeholder={t("Select condition")}
                options={[
                  {
                    value: "correct",
                    label: t("correct"),
                  },
                  {
                    value: "incorrect",
                    label: t("incorrect"),
                  },
                ].concat(
                  transformQuestionOptions(
                    props.questionIdLabelMap[
                      props.conditionData.test.question_id
                    ]
                      ? props.questionIdLabelMap[
                          props.conditionData.test.question_id
                        ].options
                      : []
                  )
                )}
                value={{
                  value:
                    "requires_correct" in props.conditionData.test
                      ? props.conditionData.test.requires_correct === true
                        ? "correct"
                        : "incorrect"
                      : props.conditionData.test.option_id,
                  label:
                    "requires_correct" in props.conditionData.test
                      ? props.conditionData.test.requires_correct === true
                        ? "correct"
                        : "incorrect"
                      : props.questionIdLabelMap[
                          props.conditionData.test.question_id
                        ]
                      ? getOptionLabel(
                          props.conditionData.test.option_id,
                          props.questionIdLabelMap[
                            props.conditionData.test.question_id
                          ].options
                        )
                      : "",
                }}
                onChange={(value) =>
                  props.onConditionQuestionDropdownChange(
                    value.value,
                    "requiresCorrect",
                    props.conditionIndex
                  )
                }
              />
            <button
              className="btn btn-outline-danger ml-auto"
              onClick={() => {
                props.removeScoreCondition(props.conditionIndex);
              }}
            >
              <img src={crossRed} alt="" />
            </button>
            </Col>
          )}
      </Row>
      <h2 className="font-600 font-1 clr-grey-txt7">{t("Custom Response")}</h2>
      <div className="sugested-next-ques">
        <QuizzesButtonsGroup
          buttonOptions={[...buttonOptions]}
          buttons={buttonsList ? [...transformData(props.buttonsData)] : []}
          relevantIntents={relevantIntents}
          localUserQuestionUpdate={(name, data) => {
            props.onSort(data, props.conditionIndex);
          }}
          isActivityDetected={() => {}}
          hideGroup
          onSelectKnowledge={selectKnowledgeHandler}
        />
      </div>

      <div className="form-group">
        <label className="font-16 font-600 clr-black">{t("Custom Message")}</label>
        <SunEditorUI
          placeholder={t("Oops, the answer is wrong. Let's revise this section again!")}
          buttonList={[
            ["undo", "redo"],
            [
              "bold",
              "underline",
              "italic",
              "strike",
              "subscript",
              "superscript",
            ],
            ["fontColor", "hiliteColor"],
            ["link"],
          ]}
          autoFocus={false}
          name={`message-editor`}
          editorType="sun"
          value={
            props.conditionData.show.message
              ? props.conditionData.show.message
              : t("Oops, the answer is wrong. Let's revise this section again!")
          }
          onChange={(content: any) =>
            props.onConditionFieldChange(
              content,
              "message",
              props.conditionIndex
            )
          }
        />
      </div>
      <div className="form-group mb-0">
        <label className="font-16 font-600 clr-black d-flex align-items-center justify-content-between">
          {t("Move user to user group")}
          {/* <button className="btn py-0 clr-primary font-600">Add</button> */}
        </label>
        <Select
          isSearchable
          className="select-target w-100 clr-primary"
          classNamePrefix="select-target-prefix"
          components={{
            DropdownIndicator: () => (
              <img src={downarrowblk} alt="dropdown arrow" />
            ),
            IndicatorSeparator: () => null,
            ClearIndicator: () => null,
          }}
          placeholder={t("User group")}
          options={[...allGroups]}
          value={
            props.conditionData.trigger &&
            props.conditionData.trigger &&
            props.conditionData.trigger.data
              ? {
                  value: props.conditionData.trigger.data.group_id,
                  label: getGroupLabel(
                    props.conditionData.trigger.data.group_id,
                    [...allGroups]
                  ),
                }
              : []
          }
          onChange={(value: any) => {
            props.onConditionFieldChange(
              value.value,
              "userGroup",
              props.conditionIndex
            );
          }}
          //options={[...allGroups]}
        />
      </div>
    </div>
  );
}

export default ScoreRange;
