import React, { useEffect, useRef, useState } from "react";
import cx from "classnames";
import {
  Dropdown,
  Tab,
  Tabs,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import Select from "react-select";
import * as _ from "lodash";

import { useTranslation } from "react-i18next";

import { ChartTableSortIcon } from "../Knowledge/AllKnowledge";
import { useDispatch, useSelector } from "react-redux";
import editIcon from "../../assets/New-images/responses-edit-icon.svg";
import Pagination from "../../components/UI/Pagination/Pagination";
import { HelperService } from "../../services/helper.service";
import {
  openAiCustomStyles,
} from ".";
import AddAnswerFromKnowledgeBase from "./AddAnswerFromKnowledgeBase";
import { getBotResponses } from "../../store/knowledge/actions";
import ConfirmationModal from "../../components/UI/ConfirmationModal/ConfirmationModal";
import { deleteAnswersFromOutsideSource } from "../../store/questions/actions";
import ExportReportShared from "../../components/UI/ExportReportShared/ExportReportShared";
import DashboardNavigation from "../Dashboard/pages/DashboardNavigation";
import UsersAskedModal from "./popups/UsersAskedModal";
import { getAllOrganisationUsers } from "../../store/agents/actions";
import { getClasses } from "../../store/dashboard/actions";
import QuestionListSkeleton from "../../components/UI/Skeletons/QuestionListSkeleton/QuestionListSkeletons";
import plusIcon from "../../assets/New-images/plus-white.svg";
import { CHANNELS } from "../../app.constant";
import ManageLearnerQuestions from "../ManageLearnerQuestions";
import moment from "moment";
import DateRangePickerComponent from "../../components/UI/DateRangePicker/DateRangePicker";

const defaultClass = { label: "All Classes", value: "" };

const ManageAnswersByOpenAI = (props: any) => {
  const { t } = useTranslation("translation");
  const helperService = new HelperService();
  const unansweredDatatableRef: any = useRef();
  const [filters, setFilters] = useState({
    filter_connection_ids: [],
    page: null,
    status: null,
    page_size: null,
    sort_field: null,
    sort_order: null,
    from_date:
      props.location.state &&
        props.location.state.filtersData &&
        props.location.state.filtersData.hasOwnProperty("filter_from_date")
        ? props.location.state.filtersData.filter_from_date
        : moment(new Date()).subtract(30, "days").format("YYYY-MM-DD"),
    to_date:
      props.location.state &&
        props.location.state.filtersData &&
        props.location.state.filtersData.hasOwnProperty("filter_to_date")
        ? props.location.state.filtersData.filter_to_date
        : moment(new Date()).format("YYYY-MM-DD"),
    filter_question_text: null,
    filter_thread_type: null,
    filter_has_intent_id: { value: null, label: t("All Responses") },
  });
  const [activeKey, setActiveKey] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [perPage, setperPage] = useState(25);
  const [paginationState, setpaginationState] = useState(null);
  const [questionAnswerData, setQuestionAnswerData] = useState(null);
  const [showUpdateAnswerModal, setShowUpdateAnswerModal] = useState(false);
  const [isIntentId, setIsIntentId] = useState(null);
  const [sessionUsersShow, setSessionUsersShow] = useState(false);
  const [modalData, setModalData] = useState({
    users_asked: null,
    date: null,
  });
  const [userList, setUserList] = useState([]);
  const [classes, setClasses] = useState([[defaultClass]]);
  const [activeTab, setActiveTab] = useState(
    props.location.state && props.location.state.activeTab
      ? props.location.state.activeTab
      : "all"
  );
  const [filtersUnansweredThreadType, setFiltersUnansweredThreadType] =
    useState({
      class_id: null,
      selectedChannel: {
        value: null,
        label: t("All Channels"),
      },
      dateParameters:
        props.location.state &&
          props.location.state.filtersData &&
          props.location.state.filtersData.hasOwnProperty("dateParameters")
          ? props.location.state.filtersData.dateParameters
          : {
            value: "30",
            label: t("Last 30 days"),
          },
      from_date:
        props.location.state &&
          props.location.state.filtersData &&
          props.location.state.filtersData.hasOwnProperty("filter_from_date")
          ? props.location.state.filtersData.filter_from_date
          : moment(new Date()).subtract(30, "days").format("YYYY-MM-DD"),
      to_date:
        props.location.state &&
          props.location.state.filtersData &&
          props.location.state.filtersData.hasOwnProperty("filter_to_date")
          ? props.location.state.filtersData.filter_to_date
          : moment(new Date()).format("YYYY-MM-DD"),
    });
  const [dateParameters, setDateParameters] = useState(
    props.location.state &&
      props.location.state.filtersData &&
      props.location.state.filtersData.hasOwnProperty("dateParameters")
      ? props.location.state.filtersData.dateParameters
      : {
        value: "30",
        label: t("Last 30 days"),
      }
  );
  const [openDatePicker, setOpenDatePicker] = useState(false);

  const dispatch = useDispatch();

  const ActionColumn = ({ row }: any) => {
    const isEdit = row.hasOwnProperty("intent_id") && row.intent_id !== null;
    return (
      <div className="responses-action-btn">
        {!isEdit && (
          <button
            className={
              "btn btn-primary font-14 font-600 text-nowrap radius-8 py-1 px-2"
            }
            onClick={() => questionAnswerHandler(row)}
          >
            <img src={plusIcon} alt="edit" className="mr-2" />
            {"Add"}
          </button>
        )}
        {isEdit && (
          <button
            className={
              "btn btn-outline-primary font-14 font-600 text-nowrap radius-8 py-1 px-2"
            }
            onClick={() => questionAnswerHandler(row)}
          >
            <img src={editIcon} alt="edit" className="mr-2" />
            {"Edit"}
          </button>
        )}
      </div>
    );
  };

  useEffect(() => {
    getClassesFun();
    getAllUsers();
  }, []);

  useEffect(() => {
    if (filters.from_date !== null && filters.to_date !== null) {
      getQuestionAnswerList();
    }
  }, [filters, activeTab]);

  const getAllUsers = async () => {
    try {
      const response: any = await dispatch(getAllOrganisationUsers());
      const data: any = [];
      response.users.forEach((element: any) => {
        data.push({
          _id: element._id,
          email: element.email,
          name: ((element?.first_name || "") + " " + (element?.last_name || "")).trim(),
        });
      });
      setUserList(data);
    } catch (error) {
      setUserList([]);
    }
  };

  const getClassesFun = async () => {
    try {
      const { classes }: any = await dispatch(getClasses());
      setClasses(classes);
    } catch (error: any) {
      setClasses([]);
    }
  };

  const getQuestionAnswerList = async () => {
    if (activeTab === "unanswered") {
      return;
    }
    try {
      setLoading(true);
      const queAnsList: any = await dispatch(
        getBotResponses({
          ...filters,
          page_size: perPage,
          page: currentPage,
          filter_question_text: query,
          sort_field: filters.sort_field ? filters.sort_field : "updated_at",
          sort_order: filters.sort_order ? filters.sort_order : "dsc",
          filter_has_intent_id: filters.filter_has_intent_id.value,
          filter_connection_ids:
            (filters.filter_connection_ids &&
              filters.filter_connection_ids.length > 0 &&
              filters.filter_connection_ids.map(
                (id: { value: string | null; label: string }) => id.value
              )) ||
            null,
          filter_thread_type: activeTab === "all" ? null : activeTab,
        })
      );
      let allRows = queAnsList.rows.map((row: any) => {
        let askedByUsers = [];
        if (row.asked_by && row.asked_by.length > 0) {
          askedByUsers = row.asked_by.map((user: any) => {
            return {
              user_id: user,
              user_email: userList.find((u: any) => u._id === user)?.email,
              class_id: row.class_id
            };
          });
        }
        return {
          ...row,
          asked_by: askedByUsers,
        };
      });
      setData(allRows);
      setpaginationState(queAnsList.pagination);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };

  // const askedByHandler = (row: any) => {
  //   let user = null;
  //   let uniqueUsers: any;
  //   const containsDuplicate = (nums: any[]) => {
  //     uniqueUsers = new Set(nums);
  //     return uniqueUsers.size === 1;
  //   };
  //   let isAskedMulti =
  //     row &&
  //     row.asked_by &&
  //     row.asked_by.length >= 1 &&
  //     containsDuplicate(row.asked_by.map((e: { user_id: string, user_email: string, class_id: any }) => (e.user_id)));

  //   if (isAskedMulti) {
  //     user = getDetailByNameOrEmail(uniqueUsers.values().next().value);
  //   }

  //   console.log(typeof user.name, "user.name")

  //   return (
  //     <div className="font-weight-normal font-sm">
  //       {(row.asked_by &&
  //         row.asked_by.length >= 1 &&
  //         // (isAskedMulti && !user.name ? "-" : (
  //         <button
  //           onClick={(e) => {
  //             setSessionUsersShow(true);
  //             setModalData({
  //               users_asked: row.asked_by,
  //               date: row.updated_at,
  //             });
  //           }}
  //           className="btn btn-link btn-underline p-0 font-weight-normal text-left"
  //         >
  //           {isAskedMulti
  //             ? `${user.name} (${uniqueUsers.size})`
  //             :  !user.name && user.name !== "" ? `${user.email} (${uniqueUsers.size})`
  //               : `Multiple Users (${uniqueUsers.size})`}
  //         </button>
  //         // ))
  //       ) || "-"}
  //     </div>
  //   );
  // };

  const askedByHandler = (row: any) => {
    let user:any = null;
    let uniqueUsers: Set<string>;

    const containsDuplicate = (nums: any[]) => {
      uniqueUsers = new Set(nums);
      return uniqueUsers.size === 1;
    };

    let isAskedMulti =
      row &&
      row.asked_by &&
      row.asked_by.length >= 1 &&
      containsDuplicate(row.asked_by.map((e: { user_id: string }) => e.user_id));

    if (isAskedMulti) {
      user = getDetailByNameOrEmail(uniqueUsers.values().next().value);
    }

    const displayUserInfo = () => {
      if (!user) {
        return "-";
      }

      const hasName = user.name && user.name.trim() !== "";

      return hasName ? `${user.name} (${uniqueUsers.size})` : `${user.email} (${uniqueUsers.size})`;
    };

    return (
      <div className="font-weight-normal font-sm">
        {row.asked_by && row.asked_by.length >= 1 ? (
          <button
            onClick={(e) => {
              setSessionUsersShow(true);
              setModalData({
                users_asked: row.asked_by,
                date: row.updated_at,
              });
            }}
            className="btn btn-link btn-underline p-0 font-weight-normal text-left"
          >
            {isAskedMulti ? displayUserInfo() : `Multiple Users (${uniqueUsers.size})`}
          </button>
        ) : (
          "-"
        )}
      </div>
    );
  };

  const getDetailByNameOrEmail = (userId: string) => {
    const user = userList.find((u: any) => u._id === userId);
    return user || {};
  };

  const columns = [
    {
      name: t("Question"),
      selector: "text",
      sortable: true,
      cell: (row: any) => <div>{row.text}</div>,
    },
    {
      name: t("Answer"),
      selector: "description",
      sortable: true,
      cell: (row: any) => <div>{row.description}</div>,
    },
    {
      name: t("Asked by"),
      selector: "asked_by",
      sortable: false,
      cell: (row: any) => <div>{askedByHandler(row)}</div>,
    },
    {
      name: t("Action"),
      selector: "intent_id",
      sortable: false,
      cell: (row: any) => (
        <div>
          <ActionColumn row={row} />
        </div>
      ),
    },
  ];

  const onSortHandler = async ({ selector }: any, direction: string) => {
    await setFilters({
      ...filters,
      sort_field: selector,
      sort_order: direction === "desc" ? "dsc" : "asc",
    });
  };

  const customSort = (rows: any[], selector: string, direction: string) => {
    return rows;
  };

  /**
   * Used to paginate data based on per page and page number
   * @param newPerPage
   * @param page
   */
  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    await setperPage(newPerPage);
    await setcurrentPage(page);
    await setFilters({
      ...filters,
      page: page,
      page_size: newPerPage,
    });
  };

  /**
   *
   * @param page Pagination on change of page
   */
  const handlePageChange = async (page: number) => {
    await setcurrentPage(page);
    await setFilters({
      ...filters,
      page: page,
    });
  };

  const questionAnswerHandler = (row: any) => {
    if (row && row.text) {
      setQuestionAnswerData({
        question: row.text,
        answer: row.description,
        intent_id: row.intent_id || null,
        conversation_log_id: row._id,
      });
    }
    setShowUpdateAnswerModal(!showUpdateAnswerModal);
  };

  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteModalId, setDeleteModalId] = useState([]);

  const deleteModalhandler = (value: any) => {
    setDeleteModalShow(value);
  };

  const confirmDeleteKnowledgeHandler = async (e: any, data: string) => {
    setDeleteModalShow(false);
    try {
      await setLoading(true);
      await dispatch(deleteAnswersFromOutsideSource(data));
      getQuestionAnswerList();
      await setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };

  const [query, setQuery] = useState("");
  const [isTyping, setIsTyping] = useState(false);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (isTyping) {
        setcurrentPage(1);
        setFilters({
          ...filters,
          filter_question_text: query,
          page: currentPage,
        });
        setIsTyping(false);
      }
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [query]);

  const resetEditKnowledgehandler = () => {
    setShowUpdateAnswerModal(!showUpdateAnswerModal);
    getQuestionAnswerList();
  };

  const onExportHandler = (type: string) => {
    unansweredDatatableRef.current.exportQuestions(type);
  }

  return (
    <section
      className={cx({
        "page-mid-wraper dashborad-wraper h-without-foter position-relative":
          true,
      })}
    >
      <div className="page-mid-wraper all-knowledge-search responses-by-ai justify-content-between d-flex flex-wrap">
        <div className="tw-w-full filters-sticky">
          {/*--------- filters -------------*/}
          <div className="filters-dashboard discussion d-flex flex-wrap justify-content-between align-items-center mb-3 position-relative">
            {/* <h3 className="mb-0">{t("Filters")}</h3> */}
            <h2 className="mb-0 font-bold">{t("Dashboard")}</h2>
            <div className="d-flex flex-wrap sugested-next-ques">
              <>
                <Select
                  className="select-sort multi-select-target select-blue shadow-none radius-8"
                  classNamePrefix="select-target-prefix"
                  isSearchable={false}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  placeholder={t("All Classes")}
                  options={
                    activeTab === "unanswered"
                      ? [
                        defaultClass,
                        ...classes.map((c: any) => ({
                          value: c._id,
                          label: c.name,
                        })),
                      ]
                      : classes.map((c: any) => ({
                        value: c._id,
                        label: c.name,
                      }))
                  }
                  onChange={(e: any) => {
                    if (activeTab === "unanswered") {
                      setFiltersUnansweredThreadType({
                        ...filtersUnansweredThreadType,
                        class_id: e,
                      });
                      return;
                    }
                    setFilters({
                      ...filters,
                      filter_connection_ids: e,
                    });
                  }}
                  isMulti={activeTab !== "unanswered"}
                  value={
                    activeTab === "unanswered"
                      ? filtersUnansweredThreadType.class_id
                      : filters.filter_connection_ids
                  }
                />
                {activeTab === "unanswered" && (
                  <Select
                    className="select-sort select-target select-blue shadow-none radius-8"
                    classNamePrefix="select-target-prefix"
                    isSearchable={false}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    placeholder={t("All Channels")}
                    options={CHANNELS}
                    onChange={(e: any) => {
                      setFiltersUnansweredThreadType({
                        ...filtersUnansweredThreadType,
                        selectedChannel: e,
                      });
                    }}
                    value={
                      filtersUnansweredThreadType.selectedChannel
                        ? filtersUnansweredThreadType.selectedChannel
                        : null
                    }
                  />
                )}
              </>
            </div>
          </div>

          {/*--------- top-navigation -----------*/}
          <DashboardNavigation />
          {/*-------- welcome text ------------*/}
          <div className="pt-3">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <div>
                <h2>{t("Responses")}</h2>
                <div>
                  {t("View all questions and responses provided by the bot.")}
                </div>
              </div>
              <div className="">
                {activeTab === "unanswered" ? (
                  <Dropdown className="export-report p-0">
                    <Dropdown.Toggle id="dropdown-basic">
                      <div className="clr-primary font-600">{t("Export")}</div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => onExportHandler("csv")}>
                        {t("Export as csv")}
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => onExportHandler("xlsx")}>
                        {t("Export as xlsx")}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <ExportReportShared
                    name={"conversation-logs"}
                    exportName={
                      activeTab === "document_searching"
                        ? "answered_questions"
                        : activeTab === "improvising"
                          ? "other_questions"
                          : "all_questions"
                    }
                    filtersData={filters}
                    removedKeyFromObject="filter_connection_ids, page, page_size, filter_thread_type, filter_has_intent_id "
                    exportFont={true}
                  />
                )}
              </div>
            </div>

            <div className="chat-responses-tab mt-0">
              <Tabs
                defaultActiveKey="all"
                onSelect={(k) => {
                  setActiveTab(k);
                }}
                activeKey={activeTab}
              >
                <Tab eventKey="all" title={t("All Responses")}></Tab>
                <Tab
                  eventKey={"document_searching"}
                  title={t("Contextualised Chat Responses")}
                ></Tab>

                <Tab
                  eventKey="improvising"
                  title={t("Responses from External Sources")}
                ></Tab>

                <Tab eventKey="unanswered" title={t("Unanswered Questions")}>
                  <div className="discussion ">
                    <ManageLearnerQuestions
                      hideClasses
                      filtersUnansweredThreadType={filtersUnansweredThreadType}
                      ref={unansweredDatatableRef}
                      activeTab={activeTab}
                      dashboardResponses
                    />
                  </div>
                </Tab>
              </Tabs>
            </div>

            {activeTab !== "unanswered" && (
              <div className=" ml-auto d-flex flex-wrap doc-select-dropdown mb-3">
                {/* <div className="web-links mw-100 w-50">
                <input
                  type="text"
                  className="form-control h-auto"
                  placeholder="Search Questions or Users"
                  onChange={onInputChangeHandler}
                />
              </div> */}

                <Select
                  className="select-sort select-target select-blue shadow-none radius-8"
                  classNamePrefix="select-target-prefix"
                  isSearchable={false}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  placeholder={t("Status")}
                  options={[
                    {
                      value: null,
                      label: t("All Responses"),
                    },
                    {
                      value: "no",
                      label: t("Not Added to Knowledge"),
                    },
                    {
                      value: "yes",
                      label: t("Added to Knowledge Base"),
                    },
                  ]}
                  onChange={async (e: any) => {
                    await setFilters({
                      ...filters,
                      filter_has_intent_id: e,
                    });
                  }}
                  value={filters.filter_has_intent_id}
                />

                <Select
                  className="select-sort select-target select-blue shadow-none radius-8"
                  classNamePrefix="select-target-prefix"
                  isSearchable={false}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  value={dateParameters ? { ...dateParameters } : null}
                  onChange={(e: any) => {
                    setDateParameters(e);
                    if (e.value === "custom") {
                      setOpenDatePicker(true);
                    } else {
                      setFilters({
                        ...filters,
                        to_date: moment(new Date()).format("YYYY-MM-DD"),
                        from_date: moment(new Date())
                          .subtract(e.value, "days")
                          .format("YYYY-MM-DD"),
                      });
                    }
                  }}
                  options={[
                    { value: "60", label: t("Last 60 days") },
                    { value: "30", label: t("Last 30 days") },
                    { value: "7", label: t("Last 7 days") },
                    { value: "custom", label: t("Custom") },
                  ]}
                />
                {openDatePicker && (
                  <DateRangePickerComponent
                    dateValue={{
                      startDate:
                        filters && filters.from_date
                          ? new Date(filters.from_date)
                          : new Date(),
                      endDate:
                        filters && filters.to_date
                          ? new Date(filters.to_date)
                          : new Date(),
                    }}
                    onDateChange={(e) => {
                      setFilters({
                        ...filters,
                        from_date: e.filter_from_date,
                        to_date: e.filter_to_date,
                      });
                    }}
                    onCloseDateRangeHandler={() => setOpenDatePicker(false)}
                  />
                )}
              </div>
            )}

            {showUpdateAnswerModal && (
              <div className="edit-knowledge-sidewrap edit-knowledge-sidewrap-openAI">
                <AddAnswerFromKnowledgeBase
                  resetEditKnowledgehandler={resetEditKnowledgehandler}
                  questionAnswerData={questionAnswerData}
                  editMode={questionAnswerData.intent_id ? true : false}
                  viewall
                  openAi
                />
              </div>
            )}

            <UsersAskedModal
              modalData={modalData}
              show={sessionUsersShow}
              onHide={() => setSessionUsersShow(false)}
              users={userList}
              classes={classes}
              modalTitle={t("Asked By")}
              showCloseBtn={true}
            // attempted={modalData.filter_is_attempted}
            />

            <ConfirmationModal
              message={t("Are you sure you want to delete this question?")}
              size={"md"}
              show={deleteModalShow}
              selectedRow={deleteModalId}
              onHide={() => deleteModalhandler(false)}
              confirmHandler={confirmDeleteKnowledgeHandler}
            />

            {activeTab !== "unanswered" && (
              <>
                <DataTable
                  className="table-wraper-trending-questions"
                  noContextMenu
                  noHeader
                  // fixedHeader
                  // fixedHeaderScrollHeight="1500px"
                  columns={columns}
                  data={data}
                  customStyles={openAiCustomStyles}
                  sortIcon={<ChartTableSortIcon />}
                  // conditionalRowStyles={conditionalRowStylesOpenAi}
                  sortFunction={customSort}
                  onSort={onSortHandler}
                  progressPending={loading} //
                  progressComponent={
                    <div style={{ padding: 10, width: "100%" }}>
                      <QuestionListSkeleton count={12} />
                    </div>
                  }
                  defaultSortAsc={false}
                />

                {data && data.length > 0 && paginationState && (
                  <Pagination
                    hideSuggestedQuestion
                    pagination={paginationState}
                    currentPage={currentPage}
                    paginationPerPage={perPage}
                    pageChange={(newPerPage: number, page: number) => handlePerRowsChange(newPerPage, page)}
                    onChangePage={(page: number) => handlePageChange(page)}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ManageAnswersByOpenAI;
