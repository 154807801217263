import React from "react";

import "./style.scss";

const CheckRadio = ({
  name,
  checked,
  onRadioChange,
  label,
  value,
  isDisabled
}: any) => {
  return (
    <div className="checkradio-container">
      <input
        value={value}
        disabled={isDisabled}
        type="radio"
        className="custom-checkbox"
        onClick={onRadioChange}
        checked={checked}
        name={name}
      />
      <span className="checkbox-label">{label}</span>
    </div>
  );
};

export default CheckRadio;
