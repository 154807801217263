import { useEffect, useState } from "react";
import { Button, Image, Modal, ModalFooter } from "react-bootstrap";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import { useDispatch } from "react-redux";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import downarrowblk from "../../assets/images/chevron-down-blk.svg";
import closeimg from "../../assets/images/close-blk.svg";
import PermissionWrapper from "../../components/UI/PermissionWrapper/PermissionWrapper";
import { HelperService } from "../../services/helper.service";
import { getAllOrganisationUsers } from "../../store/agents/actions";
import {
  addAdminsToClass,
  addAdminUser,
  addRoleUserToAdmins,
  addUserAsAdmins,
  getAdminsBasedOnRoles,
  getLearningUserDetail,
  updateAdminUser,
} from "../../store/knowledge/actions";

import { useParams } from "react-router";
import { getAgentFromStorage } from "../../utils/appUtils";
import { useTranslation } from "react-i18next";

const AddAdminModal = (props: any) => {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const { classId }: any = useParams();
  const helper = new HelperService();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState([]);
  const [showRestValues, setShowRestValues] = useState(false);
  const [creatableValue, setCreatableValue] = useState(undefined);
  const [selectedButtonTarget, setSelectedButtonTarget] = useState({
    email: "",
    first_name: "",
    last_name: "",
    editMode: false,
    permission: "",
  });
  const [activationEmail, setActivationEmail] = useState(true);

  useEffect(() => {
    if (!props.show) {
      return;
    }
    setShowRestValues(false);
    setCreatableValue(null);
    setSelectedButtonTarget({
      email: "",
      first_name: "",
      last_name: "",
      editMode: false,
      permission: "",
    });
    getAllUsers();
  }, [props]);

  useEffect(() => {
    if (
      !props.editData ||
      !props.editData.first_name ||
      !props.editData.last_name
    ) {
      return;
    }
    setSelectedButtonTarget({
      ...selectedButtonTarget,
      first_name: props.editData.first_name,
      last_name: props.editData.last_name,
      editMode: true,
    });
    getUserClassAndGroupDetails();
  }, [props.editData]);

  const getAllUsers = async () => {
    try {
      const agentId: string = getAgentFromStorage();
      if (!agentId) {
        return;
      }
      const response: any = await dispatch(getAllOrganisationUsers());
      const data: any = [];
      response.users.forEach((element: any) => {
        data.push({
          _id: element._id,
          label: element.email,
          value: element.email,
        });
      });
      setUsers(data);
    } catch (error) {
      setUsers([]);
    }
  };

  const getUserClassAndGroupDetails = async () => {
    try {
      const response: any = await dispatch(
        getLearningUserDetail(props.editData._id)
      );
      setUser(response);
    } catch (error) {
      setUser(null);
    }
  };

  const validateEmail = (email: string) => {
    return email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleChangeHandler = async (newValue: any, actionMeta: any) => {
    if (!newValue) {
      setCreatableValue(undefined);
      return;
    }

    setCreatableValue(newValue);
  };

  const handleInputChange = (inputValue: any, actionMeta: any) => {
    // console.group("Input Changed");
    // console.log(inputValue);
    // console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();
  };

  const createOrUpdateAgent = async () => {
    if (props.editMode) {
      if (!selectedButtonTarget.first_name || !selectedButtonTarget.last_name) {
        return;
      }

      try {
        setLoading(true);
        await dispatch(
          updateAdminUser(
            {
              first_name: selectedButtonTarget.first_name,
              last_name: selectedButtonTarget.last_name,
            },
            props.editData._id
          )
        );

        if (selectedButtonTarget.permission) {
          await dispatch(
            addRoleUserToAdmins(
              props.agentId,
              props.editData._id,
              selectedButtonTarget.permission,
              props.editData ? props.editData.is_superadmin : false
            )
          );
        }
        props.confirmHandler();
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
      }
    } else {
      // console.log(" [ creatableValue ] ", creatableValue);
      // console.log(' [ selectedButtonTarget ] ', selectedButtonTarget)
      if (!creatableValue) {
        helper.showToaster(t("Please enter email."));
        return;
      }

      if (creatableValue && creatableValue._id) {
        if (!selectedButtonTarget.permission && !props.classMode) {
          helper.showToaster(t("Please Choose permission."));
          return;
        }
        try {
          setLoading(true);
          if (props.classMode) {
            const reponse: any = await dispatch(
              addAdminsToClass(classId, creatableValue._id)
            );
          } else {
            const reponse: any = await dispatch(
              addUserAsAdmins(props.agentId, creatableValue._id)
            );
            await dispatch(
              addRoleUserToAdmins(
                props.agentId,
                creatableValue._id,
                selectedButtonTarget.permission,
                props.editData ? props.editData.is_superadmin : false
              )
            );
          }
          props.confirmHandler();
          setLoading(false);
        } catch (error: any) {
          setLoading(false);
        }
      }

      //--------[ ]----------/
      if (creatableValue && !creatableValue._id && creatableValue.value) {
        if (
          !selectedButtonTarget.first_name ||
          !selectedButtonTarget.last_name
        ) {
          helper.showToaster(t("Please enter first name and last name."));
          return;
        }

        if (!selectedButtonTarget.permission && !props.classMode) {
          helper.showToaster(t("Please choose permission."));
          return;
        }

        try {
          setLoading(true);
          if (props.classMode) {
            const reponse: any = await dispatch(
              addAdminUser({
                email: creatableValue.value,
                first_name: selectedButtonTarget.first_name,
                last_name: selectedButtonTarget.last_name,
                registration_context_id: classId,
                silent: !activationEmail,
                role: "admin"
              })
            );
            await dispatch(addAdminsToClass(classId, reponse._id));
          } else {
            const reponse: any = await dispatch(
              addAdminUser({
                email: creatableValue.value,
                first_name: selectedButtonTarget.first_name,
                last_name: selectedButtonTarget.last_name,
                role:
                  selectedButtonTarget.permission === "admins"
                    ? "admin"
                    : "user",
                silent: !activationEmail
              })
            );
            await dispatch(
              addRoleUserToAdmins(
                props.agentId,
                reponse._id,
                selectedButtonTarget.permission,
                props.editData ? props.editData.is_superadmin : false
              )
            );
          }
          props.confirmHandler();
          setLoading(false);
        } catch (error: any) {
          setLoading(false);
        }
      }
    }
  };

  const handleCreate = (inputValue: string) => {
    if (validateEmail(inputValue)) {
      const obj: any = {
        _id: null,
        label: inputValue,
        value: inputValue,
      };
      setCreatableValue(obj);
      setUsers([...users, obj]);
      setShowRestValues(true);
    }
    return;
  };

  const inputChangeHandler = (e: any) => {
    setSelectedButtonTarget({
      ...selectedButtonTarget,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Modal
      show={props.show}
      size={props.size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="modal-400"
      onHide={props.onHide}
    >
      <ModalHeader className="justify-content-start border-bottom py-2 align-items-center">
        <ModalTitle
          className="h5 py-1 font-600"
          id="contained-modal-title-vcenter"
        >
          {selectedButtonTarget.editMode ? t("Update User") : t("Add User")}
        </ModalTitle>
        <Button onClick={props.onHide} className="ml-auto">
          <Image src={closeimg} alt="" />
        </Button>
      </ModalHeader>
      <ModalBody>
        {!props.editMode && (
          <div className="mb-12">
            <h3 className="mb-1">{t("User Email")}</h3>
            <CreatableSelect
              className="select-target radius-2"
              classNamePrefix="select-creatable-prefix"
              isClearable
              onChange={handleChangeHandler}
              placeholder={t("Type user Email ID")}
              onCreateOption={handleCreate}
              value={creatableValue}
              // getOptionLabel={(option: any) => option.email}
              // getOptionValue={(option: any) => option.email}
              onInputChange={handleInputChange}
              options={users}
            />
          </div>
        )}

        {(showRestValues || props.editMode) && (
          <>
            <div className="mb-12">
              <h3 className="mb-1">{t("First Name")}</h3>

              <input
                type="text"
                className="form-control he-35 radius-2"
                placeholder={t("Please enter first name")}
                name="first_name"
                value={selectedButtonTarget.first_name || ""}
                onChange={inputChangeHandler}
              />
            </div>

            <div className="mb-12">
              <h3 className="mb-1">{t("Last Name")}</h3>

              <input
                type="text"
                className="form-control he-35 radius-2"
                placeholder={t("Please enter last name")}
                name="last_name"
                value={selectedButtonTarget.last_name || ""}
                onChange={inputChangeHandler}
              />
            </div>
          </>
        )}
        {!props.classMode && (
          <div className="mb-12">
            <h3 className="mb-1">{t("Permissions")}</h3>
            <Select
              isSearchable={false}
              className="select-target radius-2"
              classNamePrefix="select-target-prefix"
              components={{
                //DropdownIndicator: () => null,
                DropdownIndicator: (props: any) => (
                  <img src={downarrowblk} alt={t("dropdown arrow")} />
                ),
                IndicatorSeparator: () => null,
              }}
              onChange={(e: any) => {
                setSelectedButtonTarget({
                  ...selectedButtonTarget,
                  permission: e.value,
                });
              }}
              placeholder={t("Permission")}
              getOptionLabel={(option: any) => option["label"]}
              getOptionValue={(option: any) => option["value"]}
              options={[
                { value: "admins", label: t("Can Edit") },
                { value: "readonlyadmins", label: t("View Only") },
              ]}
              defaultValue={
                props.editData
                  ? props.editData.role === "readonlyadmin"
                    ? { value: "readonlyadmins", label: t("View Only") }
                    : props.editData.role === "admin" ||
                      props.editData.is_superadmin
                    ? { value: "admins", label: t("Can Edit") }
                    : null
                  : null
              }
            />
          </div>
        )}
        {!props.editMode && (
          <div className="custom-checkbox d-flex align-items-center">
            <input
              type="checkbox"
              onChange={(e) => {
                setActivationEmail(!activationEmail)
              }}
              checked={activationEmail}
            />
            <label className="pl-2 m-0">{t('Send activation email')}</label>
        </div>
        )}
      </ModalBody>
      <ModalFooter>
        <PermissionWrapper>
          <button
            disabled={loading}
            className="btn-outline-primary btn font-600 py-1"
            onClick={props.onHide}
          >
            {t("Cancel")}
          </button>
          <button
            className="btn btn-primary ml-3 font-600 py-1"
            onClick={createOrUpdateAgent}
            disabled={loading}
          >
            {props.editMode ? t("Update") : t("Add")}
          </button>
        </PermissionWrapper>
      </ModalFooter>
    </Modal>
  );
};

export default AddAdminModal;
