import React, { useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import ProgressSteps from "../../../components/UI/ProgressSteps/ProgressSteps";
import KnowledgeService from "../../../services/KnowledgeService";
import { forEach } from "lodash";
import { getDocumentFromID } from "../../../store/knowledge/actions";
import { useDispatch } from "react-redux";
import "./styles.scss";
import ModalCreate from "./ModalCreate";
import { HelperService } from "../../../services/helper.service";
import { GENERATE_KNOWLEDGE, GENERATE_QNA } from "../../../config";
import Loading from "../../../components/UI/Loading/Loading";

const helperService = new HelperService();

const LearningOutcomeReview = () => {
  const { t } = useTranslation("translation");
  const history: any = useHistory();
  const [outcomes, setOutcomes] = useState([]);
  const [groupId, setGroupId] = useState("");
  const [documents, setDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const knowledgeService = new KnowledgeService();

  const getLearnings = async (groupId: string) => {
    setIsLoading(true);
    const res = await knowledgeService.getLearningOutcomesByGroup(groupId);
    setOutcomes(res?.data?.data?.learning_outcomes);
    setIsLoading(false);
  };

  useEffect(() => {
    const query = new URLSearchParams(history.location.search);
    const groupId = query.get("group");
    setGroupId(groupId);
    const documents = query.get("documents");
    if (documents && documents.split(",").length > 0) {
      setDocuments(documents.split(","));
    }
    getLearnings(groupId);
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  // if(outcomes.length === 0){
  //   return (
  //     <React.Fragment>
  //       <div className="container">
  //         <section className="page-mid-wraper page-mid-wraper-build-v2 h-without-foter px-3">
  //           <div className="d-flex flex-wrap justify-content-center clr-primary">
  //             <h1 className="mb-2">{t("Review Learning Outcomes")}</h1>
  //           </div>
  //           <div className="row col-md-12 outcomes-wrapper recommened group center outcome-list emty ">
  //           {t('You have yet to set learning outcomes in the current module')}
  //           </div>
  //         </section>
  //         <ProgressSteps currentStep={5} />
  //       </div>
  //     </React.Fragment>
  //   )
  // }

  const renderOutcomes = () => {
    if (outcomes.length === 0) {
      return (
        <section className="page-mid-wraper page-mid-wraper-build-v2 h-without-foter px-3">
          <div className="d-flex flex-wrap justify-content-center clr-primary">
            <h1 className="mb-2">{t("Review Learning Outcomes")}</h1>
          </div>
          <div className="row col-md-12 outcomes-wrapper recommened group center outcome-list emty ">
            {t("You have yet to set learning outcomes in the current module")}
          </div>
        </section>
      );
    }

    return (
      <section className="page-mid-wraper page-mid-wraper-build-v2 h-without-foter px-3">
        <div className="d-flex flex-wrap justify-content-center clr-primary">
          <h1 className="mb-2">{t("Review Learning Outcomes")}</h1>
        </div>
        <div className="mb-32 font-16 text-center">
          {t(
            "These learning outcomes would be used to curate the tutoring questions generated from the document"
          )}
        </div>
        <div className="row col-md-12 outcomes-wrapper recommened group">
          <div className="col-md-12 recommened">
            <h2>{t("Recommended Learning Outcomes")}</h2>
            <div className="outcome-list ">
              {outcomes.map((o: any, i: number) => (
                <div className="p-3 mb-1 d-flex justify-content-between align-items-center  outcome-item">
                  <div>{o.text}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    );
  };

  const proceed = async () => {
    if(documents.length > 0) {
      setIsLoading(true)
      await knowledgeService.triggerGenerateQna(documents)
      setIsLoading(false)
    }
    history.push(
      `${GENERATE_QNA.replace(
        ":step",
        "5"
      )}?question=${documents.join(",")}&group=${groupId}`,
      {
        group: groupId,
      }
    );
  }

  return (
    <React.Fragment>
      <div className="container">
        {renderOutcomes()}
        <div className="text-right pr-3">
          <button
            className="btn btn-primary mt-4"
            onClick={proceed
            //   () => {
            //   history.push(
            //     `${GENERATE_QNA.replace(
            //       ":step",
            //       "5"
            //     )}?question=${documents.join(",")}&group=${groupId}`,
            //     {
            //       group: groupId,
            //     }
            //   );
            // }
          }
          >
            {t("Proceed to Generate Q&A")}
          </button>
        </div>
        <ProgressSteps currentStep={5} />
      </div>
    </React.Fragment>
  );
};

export default LearningOutcomeReview;
