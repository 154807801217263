import * as type from "./actionType";

export interface CurrentOrgUserDetailsInteface {
  created_at?: string;
  designation?: any;
  email?: string;
  first_name?: string;
  last_name?: string;
  number?: any;
  organisation_id?: string;
  role?: string;
  status?: string;
  updated_at?: string;
  _id?: string;
  is_email_verified?: boolean;
}

export interface NotificationInterface {
  author_email: string;
  author_id: string;
  content: string;
  created_at: string;
  excerpt: string;
  icon: string;
  image: string;
  is_popup: boolean;
  is_read: boolean;
  link: string;
  mail: string;
  title: string;
  type: string;
  updated_at: string;
  user_id: string;
  _id: string;
  content_html: string;
}

interface CommonState {
  UILoading?: boolean;
  currentRoute: string;
  nextRoute: string;
  selectedAgent: string;
  selectedAgentName: string;
  setup_completed: string;
  stickersets: any[];
  stickers: any[];
  user: CurrentOrgUserDetailsInteface;
  notificationsSummary: {
    has_new_notification: boolean;
    notifications: NotificationInterface[];
    unread_count: number;
  };
  notifications: {
    notifications: NotificationInterface[];
    pagination: {
      page: number;
      page_size: number;
      total_items: number;
      total_pages: number;
    };
  };
  hasNewNotification: boolean;
  userHasSeenNewNotification: boolean;
  selectedGroupId?: string;
  selectedYear?: any;
}

const initialState: CommonState = {
  UILoading: false,
  currentRoute: null,
  nextRoute: null,
  selectedAgent: null,
  selectedAgentName: null,
  setup_completed: null,
  stickersets: [],
  stickers: [],
  user: {
    is_email_verified: true,
  },
  notifications: {
    notifications: [],
    pagination: null,
  },
  notificationsSummary: {
    has_new_notification: false,
    notifications: [],
    unread_count: 0,
  },
  hasNewNotification: false,
  userHasSeenNewNotification: false,
};

const CommonReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case type.SETCOMMONSTATE:
      return {
        ...state,
        ...action.payload,
      };
    case type.STICKER_SETS_FAIL:
    case type.STICKER_SETS_SUCCESS:
      return {
        ...state,
        stickersets: action.payload,
      };
    case type.STICKERS_SUCCESS:
    case type.STICKERS_FAIL:
      return {
        ...state,
        stickers: action.payload,
      };
    case type.SETUISTATE:
      return {
        ...state,
        UILoading: action.payload.UILoading,
      };
    case type.SELECT_GROUP_ID:
      return {
        ...state,
        selectedGroupId: action.payload,
      };
    case type.SELECT_YEAR:
      return {
        ...state,
        selectedYear: action.payload,
      };
    case type.ALLSIDEBARNOTIFICATIONS: {
      return {
        ...state,
        notificationsSummary: {
          has_new_notification: action.payload.hasOwnProperty(
            "has_new_notification"
          )
            ? action.payload.has_new_notification
            : state.notificationsSummary.has_new_notification,
          notifications: action.payload.notifications
            ? action.payload.notifications
            : state.notificationsSummary.notifications,
          unread_count: action.payload.unread_count
            ? action.payload.unread_count
            : state.notificationsSummary.unread_count,
        },
      };
    }
    case type.DELETENOTIFICATIONS: {
      const oldNotifications = { ...state.notificationsSummary };
      oldNotifications.notifications = oldNotifications.notifications.filter(
        (notification: NotificationInterface) =>
          notification._id !== action.payload
      );
      return {
        ...state,
        notificationsSummary: { ...oldNotifications },
      };
    }
    case type.UPDATENOTIFICATIONS: {
      const oldNotificationSummary = { ...state.notificationsSummary };
      const oldNotifications = [...oldNotificationSummary.notifications];
      const oldNotification = oldNotifications.find(
        (notification: NotificationInterface) =>
          notification._id === action.payload.id
      );
      if (oldNotification) {
        oldNotification.is_read = action.payload.isRead;
      }
      oldNotificationSummary.notifications = oldNotifications;
      return {
        ...state,
        notificationsSummary: { ...oldNotificationSummary },
      };
    }
    case type.HASNEWNOTIFICATIONS: {
      return {
        ...state,
        hasNewNotification: action.payload.hasNewNotification,
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default CommonReducer;
