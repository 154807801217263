import React, { useState, useEffect } from "react";
import { EditorState, Modifier } from "draft-js";

import periodic from "../../../../../assets/images/editor/periodic-table.png";
import PeriodicTablePicker from "../PeriodicTablePicker";

const PeriodicTableOption = (props: any) => {
  const [expanded, setExpanded] = useState(false);

  const stopPropagation = (event: any) => {
    event.stopPropagation();
  };

  const onElementSelect = (symbol: any) => {
    const { editorState, onChange } = props;
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      symbol,
      editorState.getCurrentInlineStyle()
    );
    onChange(EditorState.push(editorState, contentState, "insert-characters"));

    doCollapse();
  };

  const renderModal = () => {
    return (
      <div
        onClick={stopPropagation}
        className="rdw-emoji-modal rdw-periodic-modal"
      >
        <PeriodicTablePicker onElementSelect={onElementSelect} />
      </div>
    );
  };

  const expandCollapse = () => {
    if (!expanded) {
      props.modalHandler.closeAllModals();
    }
    setExpanded((expanded) => !expanded);
  };

  const doCollapse = () => {
    setExpanded(false);
  };

  return (
    <div
      aria-haspopup="true"
      aria-expanded={expanded}
      aria-label="rdw-link"
      className="rdw-link-wrapper"
    >
      <div
        onClick={expandCollapse}
        className="rdw-option-wrapper markdown-toolbar-option"
      >
        <img src={periodic} alt="S" />
      </div>
      {expanded ? renderModal() : undefined}
    </div>
  );
};

export default PeriodicTableOption;
