import React from "react";
import { useTranslation } from "react-i18next";
import { Image } from "react-bootstrap";
import lmsLogo from "../../../assets/images/canvas.svg";
import "../integration.scss";
import { useHistory } from "react-router";
import { INTEGRATION_TYPE } from "../../../config/index";

const CanvasIntegration = (props: any) => {
  const { t } = useTranslation("translation");
  const history = useHistory();

  const clickIntegration = () => {
    history.push(INTEGRATION_TYPE.replace(":type", "canvas"));
  };

  return (
    <>
      <div
        role="button"
        className="integration-item"
        onClick={(e: any) => {
          e.stopPropagation();
          clickIntegration();
        }}
      >
        <div className="container-fluid d-inline-flex justify-content-between align-items-center p-0">
          <Image src={lmsLogo} />
        </div>
        <div className="integrationBox-heading">
          <h4>Canvas</h4>
        </div>
        <div>
          {t(
            "Canvas LMS simplifies course management, communication, and student connection.",
          )}
        </div>
        <div className="configure-btn">
          <button
            className="btn btn-primary"
            onClick={() => clickIntegration()}
          >
            {t("Configure")}
          </button>
        </div>
      </div>
    </>
  );
};

export default CanvasIntegration;
