export const RESET_DOCUMENTS = 'RESET_DOCUMENTS';
export const UPDATE_ALL_DOCUMENTS = 'UPDATE_ALL_DOCUMENTS';
export const GENERATE_ANSWERS_RELOADED = 'GENERATE_ANSWERS_RELOADED';
export const INIT_GENERATE_ANSWERS_SCREEN = 'INIT_GENERATE_ANSWERS_SCREEN';
export const STORE_KEYWORDS_FOR_DOCUMENT_SUCCESS = 'STORE_KEYWORDS_FOR_DOCUMENT_SUCCESS';
export const INIT_STORE_QUESTIONS_FOR_KEYWORDS = 'INIT_STORE_QUESTIONS_FOR_KEYWORDS';
export const STORE_QUESTIONS_FOR_KEYWORDS_SUCCESS = 'STORE_QUESTIONS_FOR_KEYWORDS_SUCCESS';
export const INIT_STORE_QUESTIONS_FOR_KEYWORDS_SUCCESS = 'INIT_STORE_QUESTIONS_FOR_KEYWORDS_SUCCESS';
export const INIT_SAVE_RESPONSE_TO_KNOWLEDGE = 'INIT_SAVE_RESPONSE_TO_KNOWLEDGE';
export const SAVE_RESPONSE_TO_KNOWLEDGE_SUCCESS = 'SAVE_RESPONSE_TO_KNOWLEDGE_SUCCESS';
export const SAVE_RESPONSE_TO_KNOWLEDGE_FAIL = 'SAVE_RESPONSE_TO_KNOWLEDGE_FAIL';
export const LOCAL_UPDATE_URL_DATA = 'LOCAL_UPDATE_URL_DATA';
export const PAGINATE_WEB_LINKS = 'PAGINATE_WEB_LINKS';
export const INIT_GENERATE_WEBLINKS_FROM_URL = 'INIT_GENERATE_WEBLINKS_FROM_URL';
export const INIT_STORE_WEBSITE_TO_SERVER = 'INIT_STORE_WEBSITE_TO_SERVER';
export const FETCH_ALL_KNOWLEDGE_SUCCESS = 'FETCH_ALL_KNOWLEDGE_SUCCESS';
export const FETCH_ALL_KNOWLEDGE_ERROR = 'FETCH_ALL_KNOWLEDGE_ERROR';
export const FETCH_TOPICS_SUCCESS = 'FETCH_TOPICS_SUCCESS';
export const FETCH_TOPICS_ERROR = 'FETCH_TOPICS_ERROR';
export const UPDATE_KNOWLEDGE_BY_ID_ERROR = 'UPDATE_KNOWLEDGE_BY_ID_ERROR';
export const CHUNK_KNOWLEDGE_STARTED = 'CHUNK_KNOWLEDGE_STARTED';
export const DELETE_KNOWLEDGE_BY_ID_SUCCESS = 'DELETE_KNOWLEDGE_BY_ID_SUCCESS';
export const RESTORE_KNOWLEDGE_BY_ID_SUCCESS = 'RESTORE_KNOWLEDGE_BY_ID_SUCCESS';
export const CHUNK_KNOWLEDGE_FINISHED = 'CHUNK_KNOWLEDGE_FINISHED';
export const STORE_DOCUMENTS = 'STORE_DOCUMENTS';
export const STORE_FAQ_RESPONSES_FOR_DOCUMENT_SUCCESS = 'STORE_FAQ_RESPONSES_FOR_DOCUMENT_SUCCESS';
export const INIT_STORE_FAQ_RESPONSES_FOR_DOCUMENT = 'INIT_STORE_FAQ_RESPONSES_FOR_DOCUMENT';
export const INIT_ALL_KNOWLEDGE = 'INIT_ALL_KNOWLEDGE';
export const FETCH_ALL_SUGGESTIONS_SUCCESS = 'FETCH_ALL_SUGGESTIONS_SUCCESS';
export const FETCH_ALL_SUGGESTIONS_ERROR = 'FETCH_ALL_SUGGESTIONS_ERROR';
export const INIT_KNOWLEDGE_BY_ID = 'INIT_KNOWLEDGE_BY_ID';
export const UPDATE_SUGGESTION_BY_ID = 'UPDATE_SUGGESTION_BY_ID';
export const UPDATE_DOCUMENT_AFTER_INTERVAL = 'UPDATE_DOCUMENT_AFTER_INTERVAL';
export const STORE_WEBSITE_TO_SERVER_SUCCESS = 'STORE_WEBSITE_TO_SERVER_SUCCESS';
export const UPDATE_KNOWLEDGE_BY_ID_SUCCESS = 'UPDATE_KNOWLEDGE_BY_ID_SUCCESS';
export const UPDATE_BULK_KNOWLEDGE_BY_ID_SUCCESS = 'UPDATE_BULK_KNOWLEDGE_BY_ID_SUCCESS';
export const INIT_STORE_KEYWORDS_FOR_DOCUMENT_SUCCESS = 'INIT_STORE_KEYWORDS_FOR_DOCUMENT_SUCCESS';
export const DOCUMENT_FAQ_INIT = 'DOCUMENT_FAQ_INIT';
export const DOCUMENT_FAQ_SUCCESS = 'DOCUMENT_FAQ_SUCCESS';
export const DOCUMENT_FAQ_ERROR = 'DOCUMENT_FAQ_ERROR';
export const LOCAL_DOCUMENT_UPDATE = 'LOCAL_DOCUMENT_UPDATE';
export const STORE_QUESTIONS_FOR_KEYWORDS_UPDATE = 'STORE_QUESTIONS_FOR_KEYWORDS_UPDATE';
export const LOCAL_ANSWWER_UPDATE = 'LOCAL_ANSWWER_UPDATE';
export const STORE_QUESTIONS_FOR_KEYWORDS_FAIL = 'STORE_QUESTIONS_FOR_KEYWORDS_FAIL';
export const STORE_WEBSITE_TO_SERVER_FAIL = 'STORE_WEBSITE_TO_SERVER_FAIL';
export const FETCH_BOT_DATA_FAIL = 'FETCH_BOT_DATA_FAIL';
export const MANAGE_ACCESS_RIGHTS_DATA = "MANAGE_ACCESS_RIGHTS_DATA";
