import React from "react";

import "./style.css";

const Toggle = ({
  toggled,
  onToggle,
  label = "",
  defaultChecked = false,
  defaultValue = false,
  isDisabled = false
}) => {
  return (
    <div className="custom-toggle">
      <label className="toggle-label mr-2">{label}</label>
       <label class="switch">
        <input
          defaultChecked={defaultChecked}
          defaultValue={defaultValue}
          disabled={isDisabled}
          type="checkbox"
          onClick={onToggle}
          checked={toggled}
        />
        <span class="slider round"></span>
      </label>
    </div>
  );
};

export default Toggle;
