import * as type from "./actionType";

interface FallbackReducerState {
  fallback: any,
  error: any,
  botUpdating: boolean,
  botUpdated: boolean,
  greetUpdating: boolean,
  greetError: any,
  loading: boolean,
}

const initialState: FallbackReducerState = {
  fallback: null,
  error: null,
  loading: true,
  botUpdating: false,
  greetUpdating: false,
  greetError: null,
  botUpdated: false
};

const FallbackReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case type.LOCAL_FALLBACK_BUTTONS_UPDATE:
      const oldIntent = { ...state.fallback };
      oldIntent.intent[action.payload.key] = (typeof action.payload.value === 'boolean' || action.payload.value === null) ? action.payload.value : [ ...action.payload.value ];
      return {
        ...state,
        fallback: { ...oldIntent }
      };
    case type.FETCH_USER_FALLBACK_SUCCESS:
      return {
        ...state,
        loading: false,
        fallback: action.payload.data.data,
      };
    case type.FETCH_USER_FALLBACK_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        fallback: null
      };
    case type.INIT_UPDATE_USER_FALLBACK_DATA:
    case type.UPDATE_USER_FALLBACK_DATA_FAIL:
      return {
        ...state,
        ...action.payload
      };
    case type.UPDATE_USER_FALLBACK_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        greetUpdating: false,
        fallback: action.payload.data.data,
      };
    default:
      return {
        ...state
      };
  }
};

export default FallbackReducer;
