import React, { useState } from "react";

import { symbolSets, symbolsData } from "./data";
import "./style.scss";

const MathSymbolPicker = ({ onSelect }: any) => {
  const [symbols, setSymbols] = useState(symbolsData["General"]);
  const [selectedName, setSelectedName] = useState("General");

  const onSetChange = (name: any) => {
    setSelectedName(name);
    setSymbols(symbolsData[name]);
  };

  return (
    <div className="math-symbol-picker">
      <div className="math-symbol-set-tray">
        {symbolSets.map((set: any) => (
          <button
            className={
              set.name === selectedName
                ? "math-symbol-set-thumbnail math-symbol-set-thumbnail-active"
                : "math-symbol-set-thumbnail"
            }
            onClick={() => onSetChange(set.name)}
          >
            <img
              className="math-symbol-set-thumbnail-img"
              src={set.thumbnail}
              alt={set.name}
            />
          </button>
        ))}
      </div>
      <div className="math-symbol-picker-scroll">
        {symbols.map((symbol: any) => (
          <button className="math-symbol-item" onClick={() => onSelect(symbol)}>
            {symbol}
          </button>
        ))}
      </div>
    </div>
  );
};

export default MathSymbolPicker;
