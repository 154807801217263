import React from "react";
import {
  ModalTitle,
  Button,
  ModalBody,
  ModalFooter,
  Image,
  Modal,
} from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import closeImg from "../../../assets/New-images/build-knowledge-close-img.svg";
import { useTranslation } from "react-i18next";

const DeleteLearnerQuestionsPopup = (props: any) => {
  const { t } = useTranslation("translation");
  return (
    <React.Fragment>
      <Modal show={props.show} centered dialogClassName="modal-400">
        <ModalHeader className="clr-black p-3 justify-content-between border-bottom">
          <ModalTitle id="contained-modal-title-vcenter">
            <h2 className="mb-0 font-1 font-600">{t("Delete Question(s)")}</h2>
          </ModalTitle>
          <button className="btn" onClick={props.onHide}>
            <img src={closeImg} alt="" />
          </button>
        </ModalHeader>
        <ModalBody>
          <label>
            {t(
              "Deleting question would remove the question from the learner board, are you sure you would like to delete?"
            )}
          </label>
        </ModalBody>
        <ModalFooter className="border-top">
          {/* <button
            className="btn-outline-danger btn ml-3 mr-0"
            onClick={(e: any) => props.onArchive(e, props.selectedRows)}
          >
            {t("Archive Question(s)")}
          </button> */}
          <button
            className="btn-outline-danger btn ml-3 mr-0"
            onClick={(e: any) => props.onDelete(e, props.selectedRows)}
          >
            {t("Delete")}
          </button>
          <button
            className="btn-primary btn ml-3 mr-0"
            onClick={props.onHide}
          >
            {t("Cancel")}
          </button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default DeleteLearnerQuestionsPopup;
