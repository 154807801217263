import cx from "classnames";
import React, { ReactElement, useState } from "react";
import Moment from "react-moment";
import deleteImg from "../../../assets/images/delete-icon.svg";
import editImg from "../../../assets/images/notify-edit.svg";
import { NotificationInterface } from "../../../store/common/reducer";
import { useDispatch } from "react-redux";
import { readNotification } from "../../../store/common/actions";

import { useTranslation } from "react-i18next";

interface Props {
  notification: NotificationInterface;
  readNotificationHandler: (e: any) => void;
  deleteNotificationHandler: (e: any, id: string) => void;
  editNotificationHandler?: (
    e: any,
    notification: NotificationInterface
  ) => void;
  navigateToFaqHandlerAsProp?: (
    e: any,
    notification: NotificationInterface
  ) => void;
  isEdit: boolean;
  type?: string;
}

const windowObj: any = window;

export default function NotificationListItem({
  isEdit,
  notification,
  readNotificationHandler,
  deleteNotificationHandler,
  editNotificationHandler,
  navigateToFaqHandlerAsProp,
}: Props): ReactElement {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const [isExpanded, setIsExpanded] = useState(false);

  const onClickMore = (event: any) => {
    event.stopPropagation();
    setIsExpanded(!isExpanded);
  };
  const navigateToFaqHandler = async (
    e: any,
    notification: NotificationInterface
  ) => {
    e.preventDefault();
    if (!notification.is_read) {
      dispatch(
        readNotification(notification._id, notification.is_read.toString())
      );
    }

    if (notification.link.trim().length > 0) {
      if (notification.link.includes(windowObj.OLD_PORTAL_URI)) {
        window.open(
          windowObj.NEW_PORTAL_URI + notification.link.split(".ai/").pop(),
          "_blank"
        );
      } else if (
        notification.link.includes("portal.noodlefactory.ai/quiz/") ||
        notification.link.includes("newportal.staging.noodlefactory.ai/quiz/")
      ) {
        window.open(
          windowObj.NEW_PORTAL_URI + notification.link.split(".ai/").pop(),
          "_blank"
        );
      } else if (
        notification.link.includes(
          "portal.staging.noodlefactory.ai/review-submission/"
        )
      ) {
        window.open(
          windowObj.NEW_PORTAL_URI + notification.link.split(".ai/").pop(),
          "_blank"
        );
      } else {
        window.open(notification.link, "_blank");
      }
    }
  };

  return (
    <div className="notify-item">
      <div
        className={cx({
          "notify-indicate": true,
          active: !notification.is_read,
        })}
        data-id={notification._id}
        data-read={notification.is_read}
        onClick={readNotificationHandler}
      ></div>
      <div
        data-id={notification._id}
        data-read={notification.is_read}
        onClick={(e) =>
          navigateToFaqHandlerAsProp
            ? navigateToFaqHandlerAsProp(e, notification)
            : navigateToFaqHandler(e, notification)
        }
      >
        <h2>{notification.title}</h2>
        <div>
          {isExpanded === true && notification.content_html && (
            <div className="notification-content">
              <p
                key={notification._id}
                className="notify-content"
                dangerouslySetInnerHTML={{ __html: notification.content_html }}
              ></p>
              {notification.content !== notification.excerpt && (
                <>
                  <button
                    className="btn notification-view-more"
                    onClick={(event) => onClickMore(event)}
                  >
                    {isExpanded === true ? t("view less") : t("view more")}
                  </button>
                </>
              )}
            </div>
          )}
          {isExpanded === true && !notification.content_html && (
            <div className="notification-content">
              <p>{notification.content}</p>
              {notification.content !== notification.excerpt && (
                <>
                  <button
                    className="btn notification-view-more"
                    onClick={(event) => onClickMore(event)}
                  >
                    {isExpanded === true ? t("view less") : t("view more")}
                  </button>
                </>
              )}
            </div>
          )}

          {!isExpanded && (
            <div className="notification-content">
              <p dangerouslySetInnerHTML={{ __html: notification.excerpt }}></p>
              {notification.content !== notification.excerpt && (
                <>
                  <button
                    className="btn notification-view-more"
                    onClick={(event) => onClickMore(event)}
                  >
                    {isExpanded === true ? t("view less") : t("view more")}
                  </button>
                </>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="notify-time">
        <Moment
          utc
          local
          date={notification.updated_at}
          format=" DD-MM-YYYY hh:mm A"
        >
          {notification.updated_at}
        </Moment>
      </div>
      <button
        className="btn"
        onClick={(e) => deleteNotificationHandler(e, notification._id)}
      >
        <img src={deleteImg} alt={t("Delete Notification")} />
      </button>
      {isEdit && (
        <button
          className="btn noti-edit"
          onClick={(e) => editNotificationHandler(e, notification)}
        >
          <img src={editImg} alt={t("Edit Notification")} />
        </button>
      )}
    </div>
  );
}
