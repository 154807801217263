/* eslint-disable no-lone-blocks */
import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
const QuestionListSkeleton = (props: any) => {
  return (
    <React.Fragment>
      <SkeletonTheme
        color='rgba(255, 0, 158, 0.05)'
        highlightColor='rgba(255, 0, 158, 0.05)'
      >
        <Skeleton duration={2} count={props.count ? props.count: 4} width={`100%`} height={30} />
      </SkeletonTheme>
    </React.Fragment>
  )
}
export default QuestionListSkeleton

{
  /* <ul className='list-unstyled ques-list'>
        <li>
          <SkeletonTheme>
            <Skeleton width={`100%`} />
          </SkeletonTheme>
        </li>
      </ul>
      <ul className='list-unstyled ques-list'>
        <li>
          <SkeletonTheme>
            <Skeleton width={`100%`} />
          </SkeletonTheme>
        </li>
      </ul>
      <ul className='list-unstyled ques-list'>
        <li>
          <SkeletonTheme>
            <Skeleton width={`100%`} />
          </SkeletonTheme>
        </li>
      </ul> */
}

// <section>
//   <h2 className='section-title'>
//     <Skeleton height={30} width={300} />
//   </h2>

//   <ul className='list'>
//     {[1, 2, 3, 4, 5, 6].map((item, index) => (
//       <li className='card' key={index}>
//         <Skeleton height={180} />
//         <h4 className='card-title'>
//           <Skeleton circle={true} height={50} width={50} />
//           <Skeleton height={36} width={`80%`} />
//         </h4>
//         <p className='card-channel'>
//           <Skeleton width={`60%`} />
//         </p>
//         <div className='card-metrics'>
//           <Skeleton width={`90%`} />
//         </div>
//       </li>
//     ))}
//   </ul>
// </section>
