import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import BarCompact from '../../BarCompact'
import { getDashboardUniqueActiveUsersSummary, getDashboardUserSessionReports } from '../../../../store/dashboard/actions'
import { LineChartCompact } from '../../LineCompact/LineCompact'
import InfoIconDetails from '../InfoIconDetails/InfoIconDetails'

import { useTranslation } from "react-i18next";

interface Props {
  filtersData?: {
    filter_from_date?: string
    filter_to_date?: string
    channel?: string
  }
}

function ActiveUsers({ filtersData }: Props): ReactElement {
  const { t } = useTranslation('translation');
  const [loading, setLoading] = useState(false)
  const [sessionData, setSessionData] = useState(null)
  const dispatch = useDispatch()
  useEffect(() => {
    // console.log('[ Sessions filtersData ]', filtersData)
    if (
      loading ||
      !filtersData.filter_from_date ||
      !filtersData.filter_to_date
    ) {
      return
    }

    const fetchSessionData = async (filtersData: any) => {
      setLoading(true)
      try {
        const sessionsData: any = await dispatch(
          getDashboardUniqueActiveUsersSummary(filtersData)
        )
        // console.log('[ sessionsData ]', sessionsData)
        await setSessionData({
          ...sessionsData,
          activeUsersSessions: sessionsData.activeUsersSessions.map(
            (d: any) => {
              return {
                x: d.date,
                y: d.user_count,
              }
            }
          ),
        })
        setLoading(false)
        // console.log(
        //   'Active Users Data: src_components_UI_Dashboard_ActiveUsers_ActiveUsers.tsx',
        //   sessionsData
        // )
      } catch (e: any) {
        setLoading(false)
        console.log(
          '[[[ src_components_UI_Dashboard_ActiveUsers_ActiveUsers.tsx ]]]',
          e
        )
      }
    }
    fetchSessionData(filtersData)
  }, [filtersData])

  return (
    <div className='col-sm-6 col-md-4 col-lg-2'>
      <div className='graph-box'>
        <h3>
          {t("Unique active users")}
          <InfoIconDetails
            message={t('Total number of unique active users in the chatbot.')}
          />
        </h3>
        <div className='row'>
          <div className='col-4'>
            {/* <h2>{weeklyUsersCount?.summary?.current_week_count}</h2> */}
            {!loading && <h2>{sessionData?.usersCount || 0}</h2>}
            {loading && (
              <div className='spinner-border spinner-border-sm text-primary'></div>
            )}
            {/* {weeklyUsersCount && (
                <div
                  className={cx({
                    'growth-percent': true,
                    'growth-up':
                      Math.sign(weeklyUsersCount?.summary?.growth) > -1,
                    'growth-down':
                      Math.sign(weeklyUsersCount?.summary?.growth) < 0,
                  })}
                >
                  {weeklyUsersCount
                    ? weeklyUsersCount?.summary?.growth * 100
                    : 0}
                  %
                </div>
              )} */}
          </div>
          <div className='col-8'>
            <div style={{ width: '100%', height: '50px' }}>
              {sessionData &&
                sessionData.activeUsersSessions &&
                sessionData.activeUsersSessions.length > 0 && (
                  <LineChartCompact
                    data={
                      sessionData.activeUsersSessions
                        ? sessionData.activeUsersSessions
                        : []
                    }
                  />
                )}

              {/* {weeklyUsersCount &&
                  weeklyUsersCount.rows &&
                  weeklyUsersCount.rows.length > 0 && (
                    <LineChartCompact data={weeklyUsersCount.rows} />
                  )} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ActiveUsers
