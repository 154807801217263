import React, { useEffect, useState, useRef } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import ProgressSteps from "../../../components/UI/ProgressSteps/ProgressSteps";
import KnowledgeService from "../../../services/KnowledgeService";
import { getDocumentFromID } from "../../../store/knowledge/actions";
import { useDispatch } from "react-redux";
import "./styles.scss";
import ModalCreate from "./ModalCreate";
import { HelperService } from "../../../services/helper.service";
import { GENERATE_QNA } from "../../../config";
import Loading from "../../../components/UI/Loading/Loading";
import refreshIcon from "../../../assets/images/refresh.svg";
import { Image } from "react-bootstrap";
const helperService = new HelperService();
const MAX_GEN_LEARNING_OUTCOMES = 20;
const LearningOutcome = () => {
  const { t } = useTranslation("translation");
  const history: any = useHistory();
  const [documents, setDocuments] = useState([]);
  const [outcomes, setOutcomes] = useState([]);
  const [groupId, setGroupId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [generates, setGenerates] = useState([]);
  const [isShowAdd, setIsShowAdd] = useState(false);
  const knowledgeService = new KnowledgeService();
  const dispatch = useDispatch();
  const messagesEndRef = useRef(null);

  const [type, setType] = useState("");

  const generateLearnings = async (documents: string[]) => {
    const documentsResult = [];
    for await (const document of documents) {
      const fetchedDocument: any = await dispatch(getDocumentFromID(document));
      // const outcomeRes: any = await knowledgeService.getLearningOutcomes(
      const outcomeRes: any = await knowledgeService.generateLearningOutcomes(
        document,
        MAX_GEN_LEARNING_OUTCOMES
      );

      documentsResult.push({
        ...fetchedDocument,
        remaining: outcomeRes?.data?.data?.remaining || 0,
        // outcomes: outcomeRes?.data?.data?.learning_outcomes,
      });
    }
    setDocuments(documentsResult);
    await getLearnings(documentsResult);
  };

  const getRecommendLearningOutcomes = async (documentIds: any) => {
    const outcomeRes: any = await knowledgeService.getRecommendLearningOutcomes(
      documentIds
    );
    setOutcomes(outcomeRes?.data?.data?.learning_outcomes);
  };

  const saveOutcomes = async (proceedWithoutSaving: boolean = false) => {
    // if (outcomes.length > 0) {
    setIsLoading(true);
    const documenIds: any = documents.map((doc: any) => doc?._id);
    const learningOutcomes: any = outcomes.map((o) => ({
      knowledge_group_id: groupId,
      text: o?.text,
    }));
    if (!proceedWithoutSaving) {
      await knowledgeService.saveRecommendLearningOutcomes(
        documenIds,
        learningOutcomes
      );
    }

    await knowledgeService.triggerGenerateQna(documenIds);
    setIsLoading(false);
    helperService.showToaster("Success", "success");
    history.push(
      `${GENERATE_QNA.replace(":step", "5")}?question=${documenIds.join(
        ","
      )}&group=${groupId}&type=${type}`,
      {
        group: groupId,
      }
    );
    // }
  };

  const getLearnings = async (docs: any) => {
    const documentsResult = [...docs];
    for await (const doc of documentsResult) {
      const outcomeRes: any = await knowledgeService.getLearningOutcomes(
        doc?._id
      );
      doc.outcomes = outcomeRes?.data?.data?.learning_outcomes;
    }
    setDocuments(documentsResult);
  };

  const generateMore = async (doc: any) => {
    const copyGenerates = [...generates];
    setGenerates([...copyGenerates, doc._id]);
    const outcomeRes: any = await knowledgeService.generateLearningOutcomes(
      doc._id,
      MAX_GEN_LEARNING_OUTCOMES
    );
    const outcomes = outcomeRes?.data?.data?.learning_outcomes;
    if (outcomes) {
      const copyDocs = [...documents];
      copyDocs.forEach((document: any) => {
        if (document._id === doc._id) {
          document.outcomes = [...document.outcomes, ...outcomes];
          document.remaining = outcomeRes?.data?.data?.remaining || 0;
        }
      });
      setDocuments(copyDocs);
    }
    setGenerates((prev) => prev.filter((id) => id !== doc._id));
  };

  const getData = async (documents: any) => {
    setIsLoading(true);
    await generateLearnings(documents.split(","));
    await getRecommendLearningOutcomes(documents.split(","));
    setIsLoading(false);
  };

  useEffect(() => {
    const query = new URLSearchParams(history.location.search);
    const documents = query.get("document");
    const groupId = query.get("group");
    const type = query.get("type");
    setGroupId(groupId);
    setType(type);

    if (documents.split(",").length > 0) {
      getData(documents);
    }
  }, []);

  const addOutcomes = async (o: any, event: any) => {
    await setOutcomes((prev: any) => [...prev, { text: o.text }]);
    if (event) {
      scrollToBottom();
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      <div className="">
        <section className="page-mid-wraper page-mid-wraper-build-v2 h-without-foter ">
          <div className="d-flex flex-wrap justify-content-center clr-primary">
            <h1 className="mb-2">{t("Update Existing Learning Outcomes")}</h1>
          </div>
          <div className="mb-32 font-16 text-center">
            {t("Review new recommended learning outcomes in knowledge group")}
          </div>
          <div className=" row col-md-12">
            <div className="col-md-5">
              <div className="outcomes-wrapper justify-content-center">
                <h2>{t("Document(s) Learning outcomes")}</h2>
                <div className="outcome-list">
                  {documents.map((out: any, index: any) => (
                    <div key={index}>
                      <div className="p-3 d-flex justify-content-between align-items-center doc-name">
                        <div>{out.name}</div>
                        <div
                          // className="px-3"
                          className={cx({
                            "more-btn px-3": true,
                            disabled: out.remaining === 0,
                            loading: generates.includes(out._id),
                          })}
                          role="button"
                          onClick={() => {
                            out.remaining !== 0 &&
                              !generates.includes(out._id) &&
                              generateMore(out);
                          }}
                        >
                          <Image src={refreshIcon} />
                        </div>
                      </div>
                      {out.outcomes.map((o: any, i: string) => (
                        <div
                          key={i}
                          className="p-3 mb-1 d-flex justify-content-between align-items-center  outcome-item"
                        >
                          <div>{o.text}</div>
                          <div
                            className="px-3 clr-blue"
                            role="button"
                            onClick={(e) => {
                              addOutcomes(o, e);
                              // setOutcomes((prev: any) => [
                              //   ...prev,
                              //   { text: o.text },
                              // ]);
                              // if(e) {
                              //   scrollToBottom();
                              // }
                            }}
                          >
                            +
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-md-7 outcomes-wrapper recommened">
              <div className="recommened">
                <h2 className="pb-1 m-0">
                  {t("Recommended Overall Learning Outcomes")}
                </h2>
                <div>
                  {t(
                    "This is suggested based on the existing learning outcomes and the newly uploaded document(s)."
                  )}
                </div>
                <div className="outcome-list mt-2">
                  {outcomes.map((o: any, i: number) => (
                    <div
                      key={i}
                      className="p-3 mb-1 d-flex justify-content-between align-items-center  outcome-item"
                    >
                      <div>{o.text}</div>
                      <div
                        className="px-3  clr-grey-text"
                        role="button"
                        onClick={() => {
                          const copy = [...outcomes];
                          copy.splice(i, 1);
                          setOutcomes((prev: any) => [...copy]);
                        }}
                      >
                        x
                      </div>
                    </div>
                  ))}
                  <div ref={messagesEndRef} />
                </div>
              </div>
              <div className="text-center p-1">
                <button
                  className="btn btn-outline-primary"
                  onClick={() => setIsShowAdd(true)}
                >
                  {t("+ Add")}
                </button>
              </div>
            </div>
          </div>
        </section>
        <div className="text-right">
          <button
            className="btn btn-outline-primary mt-4 mr-3"
            onClick={() => {
              saveOutcomes(true);
            }}
          >
            {t("Proceed without Saving")}
          </button>
          <button
            className="btn btn-primary mt-4"
            onClick={() => {
              saveOutcomes();
            }}
          >
            {t("Save and Proceed")}
          </button>
        </div>
        <ProgressSteps currentStep={3} steps={4} />
        <ModalCreate
          show={isShowAdd}
          onHide={() => setIsShowAdd(false)}
          addOutcome={(name: any) => {
            setIsShowAdd(false);
            setOutcomes((prev: any) => [...prev, { text: name }]);
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default LearningOutcome;
