import { Container, Image } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import backblue from "../../../assets/images/arrow-left.svg";
import { useTranslation } from "react-i18next";
import KnowledgeGroupSelector from "../../../components/UI/KnowledgeDatatableComponent/KnowledgeGroupSelector";

export const SetUpChat = (props: any) => {
  const { t } = useTranslation("translation");
  const history = useHistory();
  const { id } = useParams<{ id: string|undefined }>();
  const title = id? id:"chat"

  const generateTitle = () => {
   switch(title){
    case "chat":
       return t("Set up contextualised chat")
     case "learning-outcomes":
       return t("Set Learning Outcomes")
     case "generate-qa":
       return t("Generate Q&A")
     case "summarise-doc":
       return t("Summarise documents")
     case "quiz":
       return t("Build a Quiz")
   }
  };

  return (
    <section className="page-mid-wraper  h-without-foter">
      <div className="row header-link">
        <div className="col-md-12">
          <div className="row">
            <div className="col-sm-12">
              <button
                className="btn p-0 btn-back font-16 mb-0 font-400"
                onClick={() => {
                  history.goBack();
                }}
              >
                <Image src={backblue} /> {t("Return to previous page")}
              </button>
              <div className="text-center">
                <div className="d-flex flex-wrap justify-content-center">
                  <h1 className="clr-primary first-step mb-2 font-weight-bold font-24">
                    {generateTitle()}
                  </h1>
                </div>
                <h2 className="mb-0 font-16 text-center font-600">
                  {t(
                    "Select an existing knowledge group or create a new one to upload your content."
                  )}
                </h2>
              </div>
            </div>
          </div>
          <KnowledgeGroupSelector />
        </div>
      </div>
    </section>
  );
};

export default SetUpChat;
