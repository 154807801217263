import React from 'react'
import loadingimg from '../../../assets/images/portal-loading-anim.svg'

import { useTranslation } from "react-i18next";

interface LoadingProps {}

const Loading: React.FunctionComponent<LoadingProps> = (props) => {
  const { t } = useTranslation('translation');
  return (
    <div className="loading-container">
      <div>
        <object
          style={{ maxWidth: '460px', margin:'0 auto' }}
          type='image/svg+xml'
          data={loadingimg}
        >
          svg-animation
        </object>
        <h1 className="mb-24">{t("Loading…")}</h1>
        <div className="font-1">{t("Keep calm! This will take a short while.")}</div>
      </div>
    </div>
  )
}

export default Loading
