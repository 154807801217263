import React from "react";
import { components } from "react-select";

const renderNestedOption = (props: any, label: any, nestedOptions: any) => {
  const { cx, getStyles, innerProps, selectOption } = props;

  // Will be applied to nested optgroup headers
  const nestedLabelClassName = cx(
    {
      option: true,
    },
    "nested-optgroup-label"
  );

  return (
    <div className="nested-optgroup">
      <div className={nestedLabelClassName}>{label}</div>
      {nestedOptions.map((nestedOption: any) => {
        if (nestedOption.options) {
          // Read below
          // Read above
          return renderNestedOption(
            props,
            nestedOption.label,
            nestedOption.options
          );
        }

        const nestedInnerProps = innerProps;
        nestedInnerProps.onClick = () => selectOption(nestedOption);
        return (
          <div className="nested-optgroup-option" key={nestedOption.value}>
            <components.Option {...props} innerProps={nestedInnerProps}>
              {nestedOption.label}
            </components.Option>
          </div>
        );
      })}
    </div>
  );
};

const CustomOption = (props: any) => {
  const { children, data } = props;

  const nestedOptions = data.options;

  if (nestedOptions) {
    const label = data.label;
    return renderNestedOption(props, label, nestedOptions);
  }

  return <components.Option {...props}>{children}</components.Option>;
};

export default CustomOption;
