import i18n from "../../../components/UI/Internationalisation/i18n";

const { t } = i18n;
export const dummyData: any = {
  type: "checkbox",
  text: t("Question 1?"),
  score: 1,
  options: [
    {
      text: t("Option 1"),
      is_correct: true,
    },
    {
      text: t("Option 2"),
      is_correct: false,
    },
  ],
};

export const emptyData: any = {
  type: "radio",
  text: "",
  score: 1,
  options: [
    {
      text: "",
      is_correct: true,
    },
    {
      text: "",
      is_correct: false,
    },
  ],
};

export const fieldOptions = [
  {
    value: "radio",
    label: t("Multiple Choice (select one)"),
  },
  {
    value: "checkbox",
    label: t("Checkboxes (select many)"),
  },
  {
    value: "text",
    label: t("Text (paragraph)"),
  },
  {
    value: "bullet",
    label: t("Text (points)"),
  },
  {
    value: "newbullet",
    label: t("Bullet"),
  },
  {
    value: "table",
    label: t("Table"),
  },
  {
    value: "description",
    label: t("Header/Description"),
  },
  {
    value: "group",
    label: t("Group Question"),
  },
  {
    value: "essay",
    label: t("Essay Question"),
  },
];

export const subFieldOptions = [
  {
    value: "radio",
    label: t("Multiple Choice (select one)"),
  },
  {
    value: "checkbox",
    label: t("Checkboxes (select many)"),
  },
  {
    value: "text",
    label: t("Text (paragraph)"),
  },
  {
    value: "bullet",
    label: t("Text (points)"),
  },
  {
    value: "table",
    label: t("Table"),
  },
  {
    value: "description",
    label: t("Header/Description"),
  },
  {
    value: "newbullet",
    label: t("Bullet"),
  },
  {
    value: "essay",
    label: t("Essay Question"),
  },
];

export const fieldOptionsMarkBy = [
  {
    value: "similar_match",
    label: t("Matching similar sentences"),
  },
  {
    value: "exact_match",
    label: t("Matching exactly with ideal answer"),
  },
  {
    value: "keywords",
    label: t("Only Keywords"),
  },
];

export const textOptionsMarkBy = [
  {
    value: "similar_match",
    label: t("Matching similar sentences"),
  },
  {
    value: "exact_match",
    label: t("Matching exactly with ideal answer"),
  },
  {
    value: "keywords",
    label: t("Only Keywords"),
  },
  {
    value: "essay_grader",
    label: t("Essay Grader (Beta)"),
  },
];

export const difficultyLevels = [
  {
    value: 1,
    label: t("1"),
  },
  {
    value: 2,
    label: t("2"),
  },
  {
    value: 3,
    label: t("3"),
  },
  {
    value: 4,
    label: t("4"),
  },
  {
    value: 5,
    label: t("5"),
  },
];

export const newFieldOptionsMarkBy = [
  {
    value: "similar_match",
    label: t("Matching similar sentences"),
  },
  {
    value: "exact_match",
    label: t("Matching exactly with ideal answer"),
  },
  {
    value: "keywords",
    label: t("Only Keywords"),
  },
  {
    value: "enhanced_keywords",
    label: t("Keywords per bullet/cell"),
  },
];

export const fieldOptionsMapMarkBy: any = {
  similar_match: t("Matching similar sentences"),
  exact_match: t("Matching exactly with ideal answer"),
  keywords: t("Only Keywords"),
  enhanced_keywords: t("Keywords per bullet/row"),
  // essay_grader: t("Essay Grader (Beta)"),
};

export const MarkingSettingsMap: any = {
  "": t("Order doesn't matter"),
  order: t("Answer should be in order"),
  placement: t("All items in the answer should be in exact position"),
};

export const fieldOptionsMap: any = {
  radio: t("Multiple Choice (select one)"),
  checkbox: t("Checkboxes Choice (select many)"),
  text: t("Text (paragraph)"),
  precision: t("Text (paragraph)"),
  bullet: t("Text (points)"),
  table: t("Table"),
  description: t("Header/Description"),
  matching: t("Matching"),
  group: t("Group Question"),
  newbullet: t("Bullet"),
  essay: t("Essay Question")
};

export const optionKey = [
  "noodle",
  "factory",
  "walter",
  "winnie",
  "chatbot",
  "chat",
  "webhook",
  "socket",
  "meepok",
  "admin",
  "analyse",
];

const divideArray = (data: any, controlColumnCount: any) => {
  let temp = [...data];
  const result = new Array(Math.ceil(temp.length / controlColumnCount))
    .fill(null)
    .map((_) => temp.splice(0, controlColumnCount));
  return result;
};

export const getNewTableDisplay = (data: any, answerKeySettings: any) => {
  return (
    <table className="table mb-0">
      <thead>
        <tr>
          {answerKeySettings &&
            answerKeySettings.headers &&
            answerKeySettings.headers.map((column: any) => (
              <th>{column}</th>
            ))}
        </tr>
      </thead>

      <tbody>
        {divideArray(data, answerKeySettings.column_count).map((row, index) => (
          <tr>
            <th>
              {"column_headers" in answerKeySettings
                ? answerKeySettings.column_headers[index]
                : ""}
            </th>
            {row.map((column) => (
              <td>{column.text}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
