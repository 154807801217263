import { ResponsiveLine } from '@nivo/line'
import React, { useState, useEffect} from 'react'

interface Props {
  data?: {
    label?: string
    value?: string
  }[]
}

const localData = [
  {
    x: 'plane',
    y: 126,
  },
  {
    x: 'helicopter',
    y: 104,
  },
  {
    x: 'boat',
    y: 80,
  },
  {
    x: 'train',
    y: 132,
  },
  {
    x: 'subway',
    y: 97,
  },
  {
    x: 'bus',
    y: 287,
  },
  {
    x: 'car',
    y: 76,
  },
  {
    x: 'moto',
    y: 47,
  },
  {
    x: 'bicycle',
    y: 294,
  },
  {
    x: 'horse',
    y: 177,
  },
  {
    x: 'skateboard',
    y: 293,
  },
  {
    x: 'others',
    y: 261,
  },
]

export const LineChartCompact = (props: Props) => {
  const [chartData, setChartData] = useState([
    {
      id: 'linechart',
      color: '#5D09E6',
      data: props.data ? [...props.data] : [...localData],
    },
  ])

  useEffect(() => {
    setChartData([
      {
        id: 'linechart',
        color: '#5D09E6',
        data: props.data ? [...props.data] : [...localData],
      },
    ])
  }, [ props.data ])

  return (
    <ResponsiveLine
      colors='#5D09E6'
      data={chartData}
      enableGridX={false}
      enableGridY={false}
      curve='basis'
      axisTop={null}
      axisRight={null}
      axisBottom={null}
      axisLeft={null}
      enablePoints={false}
    />
  )
}
