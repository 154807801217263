import React from 'react'
import { Image, OverlayTrigger, Popover } from 'react-bootstrap'
import infoimg from '../../../../assets/images/help-info.svg';
import tooltipImg from "../../../../assets/images/pink-tooltip.svg";

const InfoIconDetails = (props: any) => {
  const PopoverComponent = (data: any) => {
    return (
      <Popover className={props.class ? props.class : ""}  id='popover-basic-' {...data.popoverComponentProps}>
        {/* <Popover.Title as='h3'>Popover right</Popover.Title> */}
        <Popover.Content
          dangerouslySetInnerHTML={{
            __html: props.message ? props.message : '',
          }}
        ></Popover.Content>
      </Popover>
    )
  }

  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement={props.placement ? props.placement : 'auto'}
      overlay={(d) => <PopoverComponent popoverComponentProps={d} />}
    >
      <Image src={props.isPinkImage ? tooltipImg : infoimg} className={'ml-1'} />
    </OverlayTrigger>
  )
}

export default InfoIconDetails
