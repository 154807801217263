import { Button, ButtonGroup, OverlayTrigger, Popover } from "react-bootstrap";
import Moment from "react-moment";
import { useHistory } from "react-router";
import Select from "react-select";
import PermissionWrapper from "../../../../components/UI/PermissionWrapper/PermissionWrapper";
import i18n from "../../../../components/UI/Internationalisation/i18n";

import { useTranslation } from "react-i18next";
const { t } = i18n;

export const customStyles = {
  header: {
    style: {
      minHeight: "56px",
    },
  },
  headRow: {
    style: {
      borderRadius: "10px",
      backgroundColor: "#0923E6",
      borderBottomWidth: "0",
    },
  },
  expanderRow: {
    style: {
      // backgroundColor: '#0923E6',
    },
  },
  expanderCell: {
    style: {
      "&:hover": {
        backgroundColor: "transparent",
      },
      button: {
        backgroundColor: "transparent !important",
        img: {
          width: "10px",
        },
      },
    },
  },
  expanderButton: {
    style: {
      backgroundColor: "transparent",
      alignItems: "center",
    },
  },
  headCells: {
    style: {
      "&:first-child": {
        borderTopLeftRadius: "8px",
        borderBottomLeftRadius: "0px",
      },
      "&:last-child": {
        borderTopRightRadius: "8px",
        borderBottomRightRadius: "0px",
      },
      "&.chart--table-sort-icon": {
        marginLeft: "10px",
      },
      'input[type="checkbox"]': {
        width: "16px",
        height: "16px",
      },
      backgroundColor: "#0923E6",
      width: "250px",
      padding: "20px 20px",
      fontSize: "14px",
      color: "#fff",
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "#FFFFFF",
      },
      div: {
        color: "#fff",
      },
    },
  },
  cells: {
    style: {
      color: "#07032B",
      fontSize: "14px",
      paddingTop: "10px",
      paddingBottom: "10px",
      'input[type="checkbox"]': {
        width: "16px",
        height: "16px",
      },
      "&:nth-child(1)": {
        div: {
          textOverflow: "ellipsis",
          display: " -webkit-box",
          WebkitLineClamp: "2",
          WebkitBoxOrient: "vertical",
        },
      },
      "&:nth-child(2)": {
        div: {
          textOverflow: "ellipsis",
          display: " -webkit-box",
          WebkitLineClamp: "2",
          WebkitBoxOrient: "vertical",
        },
      },
    },
  },
};

export const PopoverComponent = (data: any) => {
  return (
    <Popover id="popover-basic-" {...data.popoverComponentProps}>
      {/* <Popover.Title as='h3'>Popover right</Popover.Title> */}
      <Popover.Content
        dangerouslySetInnerHTML={{
          __html: data.row.answer ? data.row.answer : "",
        }}
      ></Popover.Content>
    </Popover>
  );
};

export const Answer = ({ row }: any) => (
  <OverlayTrigger
    trigger={["hover", "focus"]}
    placement="auto"
    overlay={(d) => <PopoverComponent popoverComponentProps={d} row={row} />}
  >
    <div
      data-tag="allowRowEvents"
      style={{
        color: "#07032B",
        overflow: "hidden",
        textOverflow: "ellipses",
      }}
    >
      {row.answer ? row.answer : ""}
    </div>
  </OverlayTrigger>
);

export const Question = ({ row }: any) => (
  <OverlayTrigger
    trigger={["hover", "focus"]}
    placement="auto"
    overlay={(d) => <PopoverComponent popoverComponentProps={d} row={row} />}
  >
    <div
      data-tag="allowRowEvents"
      style={{
        color: "#07032B",
        overflow: "hidden",
        textOverflow: "ellipses",
      }}
    >
      {row.question ? row.question : ""}
    </div>
  </OverlayTrigger>
);

export const TotalAsked = ({ row }: any) => <div>{row.total_asked}</div>;

export const DateComponent = ({ row }: any) => (
  <div>
    <Moment utc local format="DD/MM/YYYY">
      {row.last_asked_at}
    </Moment>
  </div>
);

export const ActionColumn = ({
  row,
  editKnowledgehandler,
  addToExistingIntent,
  unmatched,
}: any) => {
  const { t } = useTranslation("translation");
  const history = useHistory();
  return (
    <div className="d-flex flex-wrap">
      <PermissionWrapper>
        <Select
          isSearchable={false}
          className="select-sort select-target"
          classNamePrefix="select-target-prefix"
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          onChange={(e: any) => {
            if (e.value === "edit" && editKnowledgehandler && !unmatched) {
              editKnowledgehandler({
                _id: row.intent_id,
              });
            }
            if (e.value === "new") {
              history.push(
                "/knowledge/knowledge-create-from-scratch?view-all=true",
                {
                  que: row.question,
                  ans: row.answer,
                }
              );
            }
            if (e.value === "existing") {
              addToExistingIntent(row);
            }
          }}
          placeholder={t("Add to Knowledge")}
          controlShouldRenderValue={false}
          options={
            !unmatched
              ? row.botmode === "guided"
                ? [
                    { value: "new", label: t("Add new") },
                    {
                      value: "existing",
                      label: t("Add to existing knowledge"),
                    },
                    { value: "edit", label: t("Edit this question") },
                  ]
                : [
                    { value: "new", label: t("Add new") },
                    {
                      value: "existing",
                      label: t("Add to existing knowledge"),
                    },
                  ]
              : [
                  { value: "new", label: t("Add new") },
                  { value: "existing", label: t("Add to existing knowledge") },
                ]
          }
        />
      </PermissionWrapper>
    </div>
  );
};

export const columns = ({
  editKnowledgehandler,
  addToExistingIntent,
  unmatched,
}: any) => {
  return [
    {
      name: t("Question"),
      selector: "_id",
      sortable: true,
      cell: (row: any) => <Question row={row} />,
    },
    {
      name: t("Answer"),
      selector: "answer",
      sortable: true,
      cell: (row: any) => <Answer row={row} />,
    },
    {
      name: t("Total Asked"),
      selector: "total_asked",
      sortable: true,
      cell: (row: any) => <TotalAsked row={row} />,
    },
    {
      name: t("Last Asked(DD/MM/YYYY)"),
      selector: "last_asked_at",
      sortable: true,
      cell: (row: any) => <DateComponent row={row} />,
    },
    {
      name: t("Action"),
      selector: "",
      sortable: false,
      cell: (row: any) => (
        <ActionColumn
          row={row}
          unmatched={unmatched}
          editKnowledgehandler={editKnowledgehandler}
          addToExistingIntent={addToExistingIntent}
        />
      ),
    },
  ];
};
