import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { UPDATE_CLASS } from "../../../config";
import { Row, Col } from "react-bootstrap";
import Moment from "react-moment";

import { useTranslation } from "react-i18next";
import _ from "lodash";
import PermissionWrapper from "../PermissionWrapper/PermissionWrapper";
import { lmsListData } from "../../../routes/Users/partials/Classes/LinkCourseModal";

function ClassesTile({
  searchedClasses,
  deleteClassHandler,
  removeSelectedClassHandler,
  openDuplicateModal
}: any) {
  // { item, deleteClassHandler }: any
  const { t } = useTranslation("translation");
  const history = useHistory();
  const [selectedRows, setSelectedRows] = useState([]);
  const [classes, setClasses] = useState<any>([]);

  useEffect(() => {
    if (searchedClasses && searchedClasses.length) {
      setClasses([...searchedClasses]);
    }
  }, [searchedClasses]);

  const onSelectAllRows = (event: any) => {
    if (event.target.checked) {
      if (classes && classes.length > 0) {
        let data: any[] = [...classes.map((intent: any) => intent._id)];
        setSelectedRows([..._.uniq(data)]);
      }
    } else {
      setSelectedRows([]);
    }
  };

  const addToSelectedRows = (e: any, id: any) => {
    let ids: any[] = [...selectedRows];
    if (e.target.checked) {
      const isPresent = ids.indexOf(id);
      if (isPresent < 0) {
        ids.push(id);
      }
    } else {
      const oldData = [...selectedRows];
      const index = oldData.indexOf(id);
      oldData.splice(index, 1);
      ids = [...oldData];
    }
    setSelectedRows([..._.uniq(ids)]);
  };

  return (
    <>
      <div className="d-flex flex-wrap justify-content-between align-items-center mt-2 pl-lg-3 mb-3">
        <div className="d-flex align-items-center mt-1">
          <div className="custom-checkbox mr-3">
            <input
              type="checkbox"
              className="mr-2"
              id="select-all"
              checked={
                classes &&
                classes.length > 0 &&
                selectedRows.length === classes.length
              }
              onChange={onSelectAllRows}
            />
            <label htmlFor="select-all" className="mb-0">
              {t("Select all")}
            </label>
          </div>
          {selectedRows && selectedRows.length > 0 && (
            <PermissionWrapper>
              <button
                className="btn btn-suggest py-0 font-sm"
                onClick={() =>
                  history.push(
                    "/usergroup/class-access/class/" +
                      selectedRows +
                      "?classes=" +
                      selectedRows.join(",")
                  )
                }
              >
                {t("Edit Access Rights")}
              </button>
              <button
                className="btn btn-outline-danger border-0 py-0 font-600 font-sm"
                onClick={() => {
                  removeSelectedClassHandler(selectedRows, true);
                  setSelectedRows([]);
                }}
              >
                {t("Remove Selected")}
              </button>
            </PermissionWrapper>
          )}
        </div>
      </div>
      <div className="manage-user-scroll scroll-wrap">
        {classes.map((item: any) => {
          return (
            <div className="containerClass" key={item._id}>
              <div className="header">
                <div className="d-flex">
                  <input
                    type="checkbox"
                    className="mr-3"
                    checked={selectedRows.indexOf(item._id) >= 0}
                    onChange={(e) => {
                      addToSelectedRows(e, item._id);
                    }}
                  />
                  <div>
                    <h3 className="font-600 mb-1">{t("Class Name")}</h3>
                    <h2 className="font-600">{item.name}</h2>
                  </div>
                </div>

                <div>
                  <h3 className="font-600 mb-1">{t("Number Of Users")}</h3>
                  <h4 className="font-weight-normal">
                    {item.student_count || 0} {t("user(s)")}
                  </h4>
                </div>
              </div>
              <div className="content">
                <Row>
                  <Col md={4} className="my-2 my-md-0">
                    <h3>{t("Created on")}</h3>
                    <div>
                      <Moment format="DD/MM/YYYY">{item?.created_at}</Moment>
                    </div>
                  </Col>
                  <Col md={4} className="my-2 my-md-0">
                    <h3>{t("Created By")}</h3>
                    <div>{item?.author_email}</div>
                  </Col>
                  <Col md={3} className="my-2 my-md-0">
                    <h3>{t("Integrations")}</h3>
                    <div className="font-weight-normal font-sm">
                      {item?.lms_courses.length === 1
                        ? `${lmsListData.find((e: any) => e.value === item.lms_courses[0]?.provider).label} - ${item.lms_courses[0].course_id}`
                        : "-"}
                    </div>
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col md={4} className="my-2 my-md-0">
                    <h3>{t("Number of Admins")}</h3>
                    <div>{item?.admin_count}</div>
                  </Col>
                  <Col md={4} className="my-2 my-md-0">
                    <h3>{t("Number of Learners")}</h3>
                    <div>{item?.student_count}</div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h3>{t("Knowledge Group Access")}</h3>
                    <div className="class-tag">
                      {item.knowledge_groups &&
                        item.knowledge_groups.map(
                          (knowledgeItem: any, index: number) => {
                            return (
                              <div className="moduleWrapper" key={index}>
                                {knowledgeItem.name}
                              </div>
                            );
                          }
                        )}
                      {(!item.knowledge_groups ||
                        item.knowledge_groups.length === 0) &&
                        item.access_all_knowledge && (
                          <div className="moduleWrapper">{t("All")}</div>
                        )}
                    </div>
                  </Col>
                </Row>
                <div className="editContainer justify-content-end">
                  <button
                    className="btn btn-outline-primary font-weight-normal"
                    onClick={() =>
                      openDuplicateModal && openDuplicateModal(item._id)
                    }
                  >
                    {t("Duplicate")}
                  </button>
                  <button
                    className="btn btn-outline-primary font-weight-normal"
                    onClick={() =>
                      history.push(UPDATE_CLASS.replace(":classId", item._id))
                    }
                  >
                    {t("Edit class")}
                  </button>
                  <button
                    className="btn btn-outline-primary font-weight-normal"
                    onClick={() => {
                      history.push("/usergroup/class-access/class/" + item._id);
                      setSelectedRows([]);
                    }}
                  >
                    {t("Edit Access Rights")}
                  </button>
                  {deleteClassHandler && (
                    <button
                      className="btn btn-outline-danger font-weight-normal mx-2 py-1 py-md-2"
                      onClick={() => {
                        deleteClassHandler(item._id);
                        setSelectedRows([]);
                      }}
                    >
                      {t("Remove")}
                    </button>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default ClassesTile;
