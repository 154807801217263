import React, { useEffect, useRef, useState, useCallback } from "react";
import { Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap";
import { Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import closeImg from "../../../assets/images/close-blk.svg";
import noodleBook from "../../../assets/images/noodle-book.svg";
import downChavBlue from "../../../assets/New-images/dropdown-arow-blue.svg";
import yellowBulb from "../../../assets/images/yellow-bulb.svg";
import "./style.scss";
import Select from "react-select";
import { getAllLevels } from "../../../store/knowledge/actions";
import Knowledge from "../../../routes/Knowledge";
import KnowledgeService from "../../../services/KnowledgeService";
import DocRow from "./DocRow";
import PDFViewer from "../PDFViewer/PDFViewer";
import { debounce } from "lodash";
import cx from "classnames";
import Pagination from "../Pagination/Pagination";
import {
  transformNestedLevels,
  transformNestedModules,
  transformNestedTopics,
} from "../../../utils/appUtils";
const reactPdf = require("react-pdf/dist/esm/entry.webpack");
const { Document, Page } = reactPdf;

const DocumentSearchPopup = (props: any) => {
  const handleClose = () => props?.close();
  const [docPreviewId, setDocPreviewId] = useState(null);
  const [docRefs, setDocRefs] = useState(props.docRefs);

  const [editingId, setEditingId] = useState("");
  const [docs, setDocs] = useState([]);
  const videoRef = useRef(null);
  const { t } = useTranslation("translation");
  const [activeTab, setActiveTab] = useState("docSearchTab");
  const [pagination, setPagination] = useState(null)
  const [years, setYears] = useState([]);
  const [modules, setModules] = useState([]);
  const [topics, setTopics] = useState([]);
  const [allGroups, setAllGroups] = useState([]);
  const [filter, setFilter] = useState({
    page: 1,
    page_size: 30,
    knowledge_group_ids: "",
    q: "",
  });
  const [selectedYear, setSelectedYear] = useState({
    value: "",
    label: t("All Years"),
  });
  const [selectedModule, setSelectedModule] = useState({
    value: "",
    label: t("All Modules"),
  });
  const [selectedTopic, setSelectedTopic] = useState({
    value: "",
    label: t("All Topics"),
  });
  const service = new KnowledgeService();

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setFilter({
      ...filter,
      page_size: newPerPage,
      page: page
    })
  }; 

  const handlePageChange = (page: number) => {
    setFilter({
      ...filter,
      page: page
    })
  };
  
  useEffect(() => {
    const group =
      selectedTopic?.value || selectedModule?.value || selectedYear?.value;
    setFilter({
      ...filter,
      page: 1,
      knowledge_group_ids: group,
    });
    setDocPreviewId("");
  }, [selectedYear?.value, selectedModule?.value, selectedTopic?.value]);

  const getDocs = async (queries: any) => {
    let filterQueries: any = {
      ...queries
    }
    if(!queries?.q){
      filterQueries.sort_field = "updated_at"
      filterQueries.sort_order = "dsc"
    }
    const response: any = await service.getAllDocumentsNewFlow(
      filterQueries,
      true,
      false
    );
    setDocs(response?.data?.data?.documents);
    setPagination(response?.data?.data?.pagination);

  };

  const debounceGetDocs = useCallback(
    debounce((filter) => {
      getDocs(filter)
    }, 400),
    []
  );

  const getAllGroups = async (year: string = null) => {
    try {
      const response: any = await service.getAllKnowledgeGroupsSubGroups();
      const years = response?.data?.data?.groups
        ?.filter((g: any) => g?.depth === 1)
        .map((t: any) => ({
          ...t,
          label: t?.name,
          value: t?._id,
        }));

      setYears(years);
      const allGroups = transformNestedModules(
        transformNestedLevels(response?.data?.data?.groups)
      );
      // console.log({ test });
      setAllGroups(allGroups);
    } catch (error: any) {
      setYears([]);
    }
  };

  useEffect(() => {
    // getAllYears();
    getAllGroups();
    getDocs(filter);
  }, []);

  const handleYearChange = (topic: any) => {
    // setModules([]);
    // setTopics([]);
    // setSelectedModule({
    //   value: "",
    //   label: "All Modules",
    // });
    // setSelectedTopic({
    //   value: "",
    //   label: t("All Topics"),
    // });
    // getAllModulesFromYearFun(topic);
    setSelectedYear({
      value: topic.value,
      label: topic.label,
    });
    // setFilters({
    //   ...filters,
    //   knowledge_group_ids: !topic._id ? null : topic._id,
    //   includes_all_subgroups: true,
    //   ids: null,
    // });
  };

  const getSubModule = async (parentId: string) => {
    const res = service.getAllModulesFromYear("");
  };

  useEffect(() => {
    if (selectedYear?.value) {
      const year: any = allGroups.find(
        (g: any) => g._id === selectedYear.value
      );
      setModules(
        year?.children?.map((g: any) => ({
          ...g,
          label: g?.name,
          value: g?._id,
        }))
      );
    }
  }, [selectedYear?.value]);

  useEffect(() => {
    if (selectedModule?.value) {
      const module: any = allGroups.find(
        (g: any) => g._id === selectedModule.value
      );
      setTopics(
        module?.children?.map((g: any) => ({
          ...g,
          label: g?.name,
          value: g?._id,
        }))
      );
    }
  }, [selectedModule?.value]);

  useEffect(() => {
    debounceGetDocs({
      ...filter,
      q: filter?.q?.trim()
    });
  }, [filter?.q]);

  useEffect(() => {
    getDocs(filter);
  }, [filter?.knowledge_group_ids, filter?.page, filter?.page_size]);

  const calculateTotalPage = (documentReferences: any) => {
    let totalPages = 0;
    documentReferences.forEach((docRef: any) => {
      docRef.sections.forEach((section: any) => {
        totalPages += section.to_page - section.from_page + 1;
      });
    });
    return totalPages;
  };

  const saveDocRefs = () => {
    props.setDocRefs(docRefs);
    handleClose();
  };

  return (
    <Modal
      show={props.show}
      size="xl"
      centered
      className="document-search-popup"
    >
      <Modal.Header className="p-4 border-bottom d-block">
        <ModalTitle className="px-2 w-100 d-flex justify-content-between font-16 font-600">
          {t("Manage Reference(s)")}
          <button className="btn" onClick={() => props.close()}>
            <img src={closeImg} alt="" />
          </button>
        </ModalTitle>
        <div className="px-2">
          {t(
            "Select the pages of the documents that you would like to use as reference(s)"
          )}
        </div>
      </Modal.Header>
      <ModalBody className="row mx-4 px-0">
        <section className="col-5 doc-previews pt-2">
          <div className="font-600">{t("Document Preview")}</div>
          <div className="select-doc-preview ">
            <div className="d-flex justify-content-center align-items-center flex-column">
              {!docPreviewId && (
                <>
                  <span className="clr-grey-txt7 pb-2">
                    {t("Select a document to preview")}
                  </span>
                  <img width={250} src={noodleBook} alt="noodle-book" />
                </>
              )}
              {docPreviewId && (
                <PDFViewer
                  documentId={docPreviewId}
                  pageWidth={window.innerWidth / 5}
                  // pageWidth={350 }
                />
              )}
            </div>
          </div>
        </section>

        <section className="col-7 pr-0">
          <Tabs
            defaultActiveKey={activeTab}
            onSelect={(e: any) => {
              setActiveTab(e);
            }}
          >
            <Tab eventKey="docSearchTab" title={t("Search Documents")}>
              <div className="doc-filters d-flex">
                <Select
                  className="select-sort select-target select-blue"
                  classNamePrefix="select-target-prefix"
                  isSearchable={false}
                  components={{
                    DropdownIndicator: () => (
                      <img
                        src={downChavBlue}
                        alt={t("dropdown arrow")}
                        className="mr-2"
                      />
                    ),
                    IndicatorSeparator: () => null,
                  }}
                  options={[{ value: "", label: t("All Years") }, ...years]}
                  onChange={handleYearChange}
                  value={selectedYear}
                />
                <Select
                  className="select-sort select-target select-blue"
                  classNamePrefix="select-target-prefix"
                  isSearchable={false}
                  components={{
                    DropdownIndicator: () => (
                      <img
                        src={downChavBlue}
                        alt={t("dropdown arrow")}
                        className="mr-2"
                      />
                    ),
                    IndicatorSeparator: () => null,
                  }}
                  options={[{ value: "", label: t("All Groups") }, ...modules]}
                  onChange={(e: any) =>
                    setSelectedModule({
                      value: e.value,
                      label: e.label,
                    })
                  }
                  value={selectedModule}
                />
                <Select
                  className="select-sort select-target select-blue"
                  classNamePrefix="select-target-prefix"
                  isSearchable={false}
                  components={{
                    DropdownIndicator: () => (
                      <img
                        src={downChavBlue}
                        alt={t("dropdown arrow")}
                        className="mr-2"
                      />
                    ),
                    IndicatorSeparator: () => null,
                  }}
                  options={[{ value: "", label: t("All Topics") }, ...topics]}
                  onChange={(e: any) =>
                    setSelectedTopic({
                      value: e.value,
                      label: e.label,
                    })
                  }
                  value={selectedTopic}
                />
              </div>
              <div className="doc-search pt-3 col-12 px-0">
                <input
                  type="text"
                  className="form-control h-auto"
                  placeholder={t("Search Documents")}
                  value={filter.q}
                  onChange={(e: any) => {
                    setFilter({
                      ...filter,
                      q: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="docs-container">
                {docs?.length > 0 &&
                  docs.map((doc: any) => (
                    <div
                      key={doc?._id}
                      onClick={() => {
                        setDocPreviewId(doc?._id);
                      }}
                    >
                      <DocRow
                        doc={doc}
                        selectedId={docPreviewId}
                        setDocRefs={setDocRefs}
                        docRefs={docRefs}
                      />
                    </div>
                  ))}
                {docs?.length === 0 && (
                  <div className="d-flex justify-content-center p-5">
                    {t("No document found")}
                  </div>
                )}
              </div>
              {pagination && docs.length > 0 && (
                <Pagination
                  hideSuggestedQuestion
                  showScrollButtons={false}
                  currentPage={filter.page}
                  pagination={pagination}
                  paginationPerPage={filter.page_size}
                  pageChange={handlePerRowsChange}
                  onChangePage={handlePageChange}
                />
              )}
            </Tab>
            <Tab eventKey="addedDocTab" title={t("Added References")}>
              <div>
                {t("Total page count: ")}
                <span
                  className={cx({
                    "clr-primary font-600": true,
                    "clr-error": calculateTotalPage(docRefs) > props?.maxPages,
                  })}
                >
                  {calculateTotalPage(docRefs)}/10
                </span>
              </div>
              <div className="d-flex page-warning">
                <img src={yellowBulb} alt="yellowBulb" className="pr-2" />
                <span>
                  {t("You may add up to a total of ") +
                    props?.maxPages +
                    t(" pages from multiple documents for reference.")}
                </span>
              </div>
              <div className="doc-container">
                {docRefs.map((doc: any, index: any) => (
                  <div key={index}>
                    <DocRow
                      index={index}
                      doc={{
                        ...doc,
                        ...doc?.document,
                      }}
                      selectedId={""}
                      isEditing
                      setDocRefs={setDocRefs}
                      docRefs={docRefs}
                    />
                  </div>
                ))}
              </div>
            </Tab>
          </Tabs>
        </section>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-outline-primary" onClick={handleClose}>
          {t("Cancel")}
        </button>
        <button
          className="btn btn-primary"
          disabled={calculateTotalPage(docRefs) > props?.maxPages}
          onClick={() => saveDocRefs()}
        >
          {t("Save changes")}
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default DocumentSearchPopup;
