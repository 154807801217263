import * as React from 'react'
import './Pagination.scss'
import cx from 'classnames'

import { useTranslation } from "react-i18next";

export interface IPaginationProps {
  pagination: {
    page: number
    page_size: number
    total_items: number
    total_pages: number
  }
  currentPage?: number
  paginationPerPage?: number
  hideSuggestedQuestion?: boolean
  suggestedQuestionText?: string
  pageChange: (page: number, currentPage: number) => void
  onChangePage: (page: number) => void
  scrollRightValue?: number
  scrollWrapper?: string
  showScrollButtons?: boolean
  showKnowledgeBase?: boolean
}

const Pagination: React.FC<IPaginationProps> = ({
  pagination,
  currentPage,
  paginationPerPage,
  pageChange,
  onChangePage,
  hideSuggestedQuestion,
  suggestedQuestionText,
  showScrollButtons,
  scrollRightValue,
  scrollWrapper,
  showKnowledgeBase
}: IPaginationProps) => {
  const { t } = useTranslation('translation');
  // Scroll related code starts from here
  const [scroll, setScroll] = React.useState(0)
  React.useEffect(() => {
    if (!showScrollButtons) {
      return
    }

    setScroll(scrollRightValue)
    return () => {
      setScroll(0)
    }
  }, [scrollRightValue])

  const btnLeftHandler = (e: any) => {
    e.preventDefault()
    const el = document.getElementsByClassName(scrollWrapper)
    let scrollLeft = el[0].scrollLeft
    el[0].scrollLeft -= 200
    setScroll((scrollLeft -= 200))
  }

  const btnRightHandler = (e: any) => {
    e.preventDefault()
    const el = document.getElementsByClassName(scrollWrapper)
    let scrollLeft = el[0].scrollLeft
    el[0].scrollLeft += 200
    setScroll((scrollLeft += 200))
  }
  // Scroll related code ends here

  /**
   * Get start number for calculation
   *
   * @param {number} pageSize
   * @param {number} pageNr
   * @return {*}
   */
  const getPageStart = (pageSize: number, pageNr: number) => {
    return pageSize * pageNr
  }

  /**
   * Label component for showing start and end number in pagination
   * @returns ()
   */
  const GetPageLabel = () => {
    const start = Math.max(
      getPageStart(pagination.page_size, currentPage - 1),
      0
    )
    const end = Math.min(
      getPageStart(pagination.page_size, currentPage),
      pagination.total_items
    )

    return <>{`${start + 1} - ${end}`}</>
  }

  /**
   * Page change handler for per page value
   * @param {React.ChangeEvent<HTMLSelectElement>} e
   */
  const pageChangeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault()
    pageChange(+e.target.value, currentPage)
  }
  

  return (
    <div className='pagination-wraper d-flex flex-wrap justify-content-between align-items-center py-2'>
      {showScrollButtons && (
        <button
          onClick={btnLeftHandler}
          data-pos='left'
          className={cx({ 'table-slide tbl-L': true, active: scroll > 0 })}
        >
          <svg width='15.657' height='11' viewBox='0 0 15.657 11'>
            <path
              id='arrow-left'
              d='M.623,9.2,5.7,4.124a.423.423,0,0,1,.6.6L1.944,9.077H15.733a.423.423,0,1,1,0,.846H1.944L6.3,14.278a.423.423,0,0,1-.6.6L.623,9.8a.423.423,0,0,1,0-.6Z'
              transform='translate(-0.499 -4)'
              fill='#cdd5ef'
            />
          </svg>
        </button>
      )}
      <div className='suggested-option'>
        {!hideSuggestedQuestion && (
          <React.Fragment>
            <svg width='8' height='8' viewBox='0 0 8 8' className='mr-2'>
              <circle
                id='Ellipse_146'
                data-name='Ellipse 146'
                cx='4'
                cy='4'
                r='4'
                fill='rgba(255, 0, 158, 0.7)'
              />
            </svg>
            {suggestedQuestionText ? suggestedQuestionText : t('Suggested question')}
          </React.Fragment>
        )}

        {showKnowledgeBase && (
          <React.Fragment>
            <svg width='8' height='8' viewBox='0 0 8 8' className='mr-2'>
              <circle
                id='Ellipse_146'
                data-name='Ellipse 146'
                cx='4'
                cy='4'
                r='4'
                fill='#304EF2'
              />
            </svg>
            {t('Added to Knowledge Base')}
          </React.Fragment>
        )}
      </div>
      
      {/* ------------------------ Rows per page ------------------------------ */}
      <nav className='d-flex align-items-center'>
        <span>{t("Rows per page")}</span>
        <select onChange={pageChangeHandler}>
          {/*--------- Can be configured later ----- */}
          {[10, 15, 20, 25, 30, 50, 100].map((n: number, index: number) => {
            return (
              <option
                key={'opt__' + index}
                selected={paginationPerPage === n}
                value={n}
              >
                {n}
              </option>
            )
          })}
        </select>
        {/* ------------------------ Rows per page ------------------------------ */}
        <div className='mx-4'>
          <GetPageLabel /> {t("of")} {pagination.total_items}
        </div>
        <div>
          {/* ------------------------ Previous page ------------------------------ */}
          <button
            className={cx('btn first-page', {
              active: pagination.page > 1 && currentPage >= pagination.page,
            })}
            disabled={pagination.page === 1}
            onClick={() => onChangePage(1)}
          >
            <svg width='7.503' height='14' viewBox='0 0 7.503 14'>
              <g
                id='Group_3649'
                data-name='Group 3649'
                transform='translate(-1156 -708)'
              >
                <path
                  data-name='Icon feather-chevron-down'
                  d='M0,0,5.5,5.5,11,0'
                  transform='translate(1162.796 709.25) rotate(90)'
                  fill='none'
                  stroke='rgba(9,35,230,0.5)'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='1'
                />
                <line
                  id='Line_118'
                  data-name='Line 118'
                  y2='13'
                  transform='translate(1156.5 708.5)'
                  fill='none'
                  stroke='rgba(9,35,230,0.5)'
                  stroke-linecap='round'
                  stroke-width='1'
                />
              </g>
            </svg>
          </button>
          <button
            className={cx('btn prev-page', {
              active: currentPage > 1 && currentPage >= pagination.page,
            })}
            onClick={() => onChangePage(currentPage - 1)}
            disabled={pagination.page === 1}
          >
            <svg width='6.707' height='12.414' viewBox='0 0 6.707 12.414'>
              <path
                data-name='Icon feather-chevron-down'
                d='M0,0,5.5,5.5,11,0'
                transform='translate(6 0.707) rotate(90)'
                fill='none'
                stroke='rgba(9,35,230,0.5)'
                stroke-linecap='round'
                stroke-linejoin='round'
                stroke-width='1'
              />
            </svg>
          </button>
          {/* ------------------------ Next page ------------------------------ */}
          <button
            className={cx('btn next-page', {
              active: currentPage < pagination.total_pages,
            })}
            disabled={pagination.total_pages === currentPage}
            onClick={() => onChangePage(currentPage + 1)}
          >
            <svg width='6.707' height='12.414' viewBox='0 0 6.707 12.414'>
              <path
                data-name='Icon feather-chevron-down'
                d='M9,19l5.5-5.5L20,19'
                transform='translate(19.707 -8.293) rotate(90)'
                fill='none'
                stroke='rgba(9,35,230,0.5)'
                stroke-linecap='round'
                stroke-linejoin='round'
                stroke-width='1'
              />
            </svg>
          </button>
          <button
            className={cx('btn last-page', {
              active: currentPage < pagination.total_pages,
            })}
            disabled={pagination.total_pages === currentPage}
            onClick={() => onChangePage(pagination.total_pages)}
          >
            <svg width='7.411' height='14' viewBox='0 0 7.411 14'>
              <g
                id='Group_3650'
                data-name='Group 3650'
                transform='translate(-1298.589 -708)'
              >
                <path
                  data-name='Icon feather-chevron-down'
                  d='M9,19l5.5-5.5L20,19'
                  transform='translate(1318.296 700.25) rotate(90)'
                  fill='none'
                  stroke='rgba(9,35,230,0.5)'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='1'
                />
                <line
                  id='Line_117'
                  data-name='Line 117'
                  y2='13'
                  transform='translate(1305.5 708.5)'
                  fill='none'
                  stroke='rgba(9,35,230,0.5)'
                  stroke-linecap='round'
                  stroke-width='1'
                />
              </g>
            </svg>
          </button>
        </div>
      </nav>
      {showScrollButtons && (
        <button
          onClick={btnRightHandler}
          data-pos='right'
          className='table-slide active tbl-R'
        >
          <svg width='15.657' height='11' viewBox='0 0 15.657 11'>
            <path
              id='arrow-right'
              d='M16.532,9.2,11.455,4.124a.423.423,0,0,0-.6.6l4.355,4.355H1.423a.423.423,0,1,0,0,.846H15.211l-4.355,4.355a.423.423,0,0,0,.6.6L16.533,9.8a.423.423,0,0,0,0-.6Z'
              transform='translate(-1 -4)'
              fill='#cdd5ef'
            />
          </svg>
        </button>
      )}
    </div>
  )
}

export default Pagination
