import { HelperService } from '../../services/helper.service';

const helperService = new HelperService();

interface QuizReducerState {
  loading: boolean,
  quizzes: any[],
}

const initialState: QuizReducerState = {
  loading: true,
  quizzes: [],
};

const QuizReducer = (state = initialState, action: any) => {
  switch (action.type) {
    default:
      return {
        ...state,
        loading: false
      };
  }
};

export default QuizReducer;
