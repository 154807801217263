import React, { useEffect, useState } from "react";
import Loading from "../../components/UI/Loading/Loading";
import { useDispatch } from "react-redux";
import {
  addRoleUserToAdmins,
  deleteAdminsFromRoles,
  getAdminsBasedOnRoles,
  resendInvite,
} from "../../store/knowledge/actions";
import cx from "classnames";
import Pagination from "../../components/UI/Pagination/Pagination";
import DataTable from "react-data-table-component";
import { ChartTableSortIcon } from "../Knowledge/AllKnowledge";
import QuestionListSkeleton from "../../components/UI/Skeletons/QuestionListSkeleton/QuestionListSkeletons";
import { customStyles, columns } from "./index";
import AddAdminModal from "./AddAdminModal";
import ConfirmationModal from "../../components/UI/ConfirmationModal/ConfirmationModal";
import PermissionWrapper from "../../components/UI/PermissionWrapper/PermissionWrapper";

import { useTranslation } from "react-i18next";

interface Props {}

export const OrganizationUsersAdmin = (props: any) => {
  const { t } = useTranslation('translation');
  const windoeObj: any = window;
  const [iframeLoading, setiframeLoading] = useState(false);
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [paginationData, setPaginationData] = useState(null);
  const [perPage, setPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editData, setEditData] = useState(null);
  const [showAdminModal, setShowAdminModal] = useState(false);
  const [filters, setFilters] = useState({
    page: 1,
    page_size: 20,
  });
  useEffect(() => {
    setiframeLoading(true);
  }, []);

  useEffect(() => {
    if (props.match.params.agentId) {
      func();
    }
  }, [props.match.params.agentId, filters]);

  const func = async () => {
    try {
      const response: any = await dispatch(
        getAdminsBasedOnRoles({
          ...filters,
          agentId: props.match.params.agentId,
        })
      );
      setUsers(response.users);
      setPaginationData(response.pagination);
    } catch (error: any) {}
  };

  /**
   *
   * @param page Pagination on change of page
   */
  const handlePageChange = async (page: number) => {
    await setCurrentPage(page);
    await setFilters({
      ...filters,
      page: page,
    });
  };

  /**
   * Used to paginate data based on per page and page number
   * @param newPerPage
   * @param page
   */
  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    await setPerPage(newPerPage);
    await setCurrentPage(page);
    await setFilters({
      ...filters,
      page: page,
      page_size: newPerPage,
    });
  };

  const iframeLoadedHandler = () => {
    setiframeLoading(false);
  };

  const addRoleUserToAdminsHandler = async (id: string, role: string, isSuperadmin: boolean) => {
    try {
      setiframeLoading(true);
      const response: any = await dispatch(
        addRoleUserToAdmins(props.match.params.agentId, id, role, isSuperadmin)
      );
      func();
      setiframeLoading(false);
    } catch (error: any) {
      setiframeLoading(false);
    }
  };

  const deleteAdminsFromRolesHandler = async (eve: any, e: {
    id: string, role: string
  }) => {
    setAdminDeleteModalShow( false)
    try {
      setiframeLoading(true);
      const response: any = await dispatch(
        deleteAdminsFromRoles(props.match.params.agentId, e.id, e.role)
      );
      func();
      setiframeLoading(false);
    } catch (error: any) {
      setiframeLoading(false);
    }
  };

  const addAdminToggleHandler = () => {
    setEditMode(false);
    setEditData(null);
    setShowAdminModal(!showAdminModal);
  };

  const confirmHandler = () => {
    func();
    setShowAdminModal(false);
  };

  const editAdminToggleHandler = (data: any, isEdit: boolean) => {
    setEditMode(true);
    setEditData(data);
    setShowAdminModal(!showAdminModal);
  };

  const [deletedRowId, setDeletedRowId] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const resendInviteHandler = async (e: any) => {
    try {
      setDeletedRowId(e);
      setModalShow(true);
    } catch (error: any) {}
  };

  const [deletedAdminId, setDeletedAdminId] = useState(null);
  const [adminDeleteModalShow, setAdminDeleteModalShow] = useState(false);
  const deleteAdminHandler = async (id: string, role: string) => {
    try {
      setDeletedAdminId({
        id,
        role
      });
      setAdminDeleteModalShow(true);
    } catch (error: any) {}
  };

  const deleteConfirmed = async (
    e: React.ChangeEvent<HTMLInputElement>,
    selectedRow: any
  ) => {
    e.preventDefault();
    setModalShow(false);
    await dispatch(resendInvite(selectedRow._id));
    func();
  };

  return (
    <>
      {/* {iframeLoading && <Loading />} */}
      <AddAdminModal
        show={showAdminModal}
        onHide={addAdminToggleHandler}
        agentId={props.match.params.agentId}
        confirmHandler={confirmHandler}
        editMode={editMode}
        editData={editData}
      />

      <ConfirmationModal
        message={t("Are you sure you want to remove this admin from this agent?")}
        size={"md"}
        show={adminDeleteModalShow}
        selectedRow={deletedAdminId}
        onHide={() => setAdminDeleteModalShow(false)}
        confirmHandler={deleteAdminsFromRolesHandler}
      />

      <ConfirmationModal
        message={t("Are you sure want to resend the invite?")}
        size={"md"}
        show={modalShow}
        selectedRow={deletedRowId}
        onHide={() => setModalShow(false)}
        confirmHandler={deleteConfirmed}
        buttonText={t("Resend Invite")}
      />

      <section
        className={cx({
          "dashborad-wraper page-mid-wraper h-without-foter": true,
        })}
      >
        <div className="container">
          <div className="welcome-subtext d-flex flex-md-nowrap flex-wrap mb-4 justify-content-between">
            <h2>{t("Admins")}</h2>
            <div className="font-sm mb-2">
              <PermissionWrapper>
                <button
                  className="btn btn-primary"
                  onClick={addAdminToggleHandler}
                >
                  {t("Add Admin")}
                </button>
              </PermissionWrapper>
            </div>
          </div>

          <div className="d-flex flex-wrap "></div>
          <React.Fragment>
            <div className="">
              <DataTable
                className="table-wraper"
                noContextMenu
                noHeader
                fixedHeader
                fixedHeaderScrollHeight="700px"
                columns={columns({
                  deleteAdminsFromRolesHandler: deleteAdminHandler,
                  addAdminToggleHandler,
                  editAdminToggleHandler,
                  addRoleUserToAdmins: addRoleUserToAdminsHandler,
                  resendInvite: resendInviteHandler,
                })}
                data={users ? users : []}
                customStyles={customStyles}
                sortIcon={<ChartTableSortIcon />}
                paginationPerPage={perPage}
                paginationDefaultPage={1}
                paginationResetDefaultPage={false}
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                progressPending={loading} //
                progressComponent={
                  <div style={{ padding: 10, width: "100%" }}>
                    <QuestionListSkeleton count={12} />
                  </div>
                }
                defaultSortAsc={false}
                // sortFunction={customSort}
                // onSort={onSortHandler}
              />

              {/* -----------------  Pagination   --------------------------- */}
              {users && users.length > 0 && paginationData && (
                <Pagination
                  hideSuggestedQuestion={true}
                  suggestedQuestionText={"Reached Fallback"}
                  currentPage={currentPage}
                  pagination={paginationData}
                  paginationPerPage={perPage}
                  pageChange={handlePerRowsChange}
                  onChangePage={handlePageChange}
                />
              )}
            </div>
          </React.Fragment>
          {/* )} */}
        </div>
      </section>

      {/* <section className='page-mid-wraper h-without-foter pb-0 p-0'>
        <div className='container h-100 p-0'>
          <div className='row h-100'>
            <div className='col-md-12 h-100'>
              <iframe
                id='quiz'
                src={
                  windoeObj.OLD_PORTAL_URI +
                  `agent/${props.match.params.agentId}/admins?viewMode=true`
                }
                title='Forms'
                className='full-iframe h-100'
                onLoad={iframeLoadedHandler}
              ></iframe>
            </div>
          </div>
        </div>
      </section> */}
      {/* <footer></footer> */}
    </>
  );
};

export default OrganizationUsersAdmin;
