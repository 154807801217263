import React from 'react'
import Auth0Wrapper from './Auth0Wrapper'
import OneAuthWrapper from './OneAuthWrapper'
import Header from '../components/Header/Header'
import { useGlobalContext } from '../context/GlobalContext'
import { useHistory } from 'react-router';

const ProtectedRoute: React.FunctionComponent<any> = ({
  component,
  ...args
}) => {
  const windowObj: any = window

  const history = useHistory();
  const { userFlowExecution, loadingFromApp, authoLoading } = useGlobalContext()
  React.useEffect(() => {
    // if ( !userFlowExecution && !loadingFromApp && !authoLoading ) {
    //   history.push("/")
    // }
  }, [ userFlowExecution ])

  const redirectHandler = () => {
    const windowObj: any = window
    window.location.href = windowObj.OLD_PORTAL_URI
  }

  const betaWrapper = (
    <div className='beta-content'>
      <span>Beta</span>
      <label onClick={redirectHandler}>Leave beta</label>
    </div>
  )
  // One AUTH wrapper
  if (windowObj.REACT_APP_SSO_PROVIDER === 'oneauth') {
    return <OneAuthWrapper Component={component} {...args} />
  } else {
    // AUTH0 wrapper
    return <Auth0Wrapper Component={component} {...args} />
  }
}

export default ProtectedRoute
