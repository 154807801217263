import { getAgentFromStorage } from './../utils/appUtils';
import AppInstance from '../axios-global';
import ApiUtil from '../utils/apiUtil';
type Type = any;
export default class UploadFilesService {
    async upload(file: any, onUploadProgress: any): Promise<Type> {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("filepath", file.name);
        return AppInstance.post("/storage/files", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress,
        });
    }

    async updateDocumentIdToDB(
        data: {
            file_name: string,
            file_id: string,
            type: string
        }
    ): Promise<Type> {
        const res = await ApiUtil.SendAsync<any>({
            url: `convos/documents?agent_id=${getAgentFromStorage()}`,
            method: "POST",
            data: { ...data }
        });
        return res;
    }

    async removeDocumentByDocumentId(documentId: string): Promise<Type> {
        const res = await ApiUtil.SendAsync<any>({
            url: `knowledge/documents/${documentId}?agent_id=${getAgentFromStorage()}`,
            method: "DELETE"
        });
        return res;
    }

    async createFaqDocument({
        name,
        type,
        knowledge_group_id,
        flow
    }: {
        name: string,
        type: string,
        knowledge_group_id: string
        flow: string
    }): Promise<Type> {
        const res = await ApiUtil.SendAsync<any>({
            url: `knowledge/documents?agent_id=${getAgentFromStorage()}`,
            method: "POST",
            data: {
                name,
                type,
                knowledge_group_id,
                flow
            }
        });
        return res;
    }

    async uploadFaqDocumentUsingDocumentId(
        { file_name, file}: {
            file_name: string,
            file: File
        },
        documentId: string,
        onUploadProgress: any
    ): Promise<Type> {
        const formData = new FormData();
        if (file) {
            formData.append("file", file);
        }
        formData.append("file_name", file_name);
        return AppInstance.post(`knowledge/documents/${documentId}/files?agent_id=${getAgentFromStorage()}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress,
        });
    }

    
    /** 
     * QNAS FLOW V2
    */
     async createDocumentFlow2(fileName: string, fileType: string, knowledge_group_id: string | null, documentType:string = 'v1'): Promise<Type> {
        const res = await ApiUtil.SendAsync<any>({
            url: `knowledge/documents?agent_id=${getAgentFromStorage()}`,
            method: "POST",
            data: {
                "name": fileName,
                "type": fileType,
                "knowledge_group_id": knowledge_group_id,
                flow: documentType
            }
        });
        return res;
    }

    async uploadV2(file: any, document_id: string, onUploadProgress: any): Promise<Type> {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("filepath", file.name);
        return AppInstance.post(`knowledge/documents/${document_id}/files?agent_id=${getAgentFromStorage()}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress,
        });
    }

    async uploadV3(file: any, fileName: string, document_id: string, onUploadProgress: any): Promise<Type> {
        const formData = new FormData();
        formData.append("file", file);
        if ( fileName ) {
            formData.append("filepath", fileName);
            formData.append("file_name", fileName);
        }
        return AppInstance.post(`knowledge/documents/${document_id}/markdown-files?agent_id=${getAgentFromStorage()}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress,
        });
    }

    async bulkUploadLearners(file: File, onUploadProgress: any
    ): Promise<Type> {
        const formData = new FormData();
        if (file) {
            formData.append("file", file);
        }
        return AppInstance.post(`/learning/users/extract-users?agent_id=${getAgentFromStorage()}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress,
        });
    }

    async validateDocuments(file: any, file_name: string, onUploadProgress: any): Promise<Type> {
        const formData = new FormData();
        formData.append("file", file);
        if ( file_name ) {
            // formData.append("filepath", file_name);
            formData.append("file_name", file_name);
        }
        return AppInstance.post(`knowledge/documents/validate-file?agent_id=${getAgentFromStorage()}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress,
        });
    }

}   