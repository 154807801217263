import React, { useEffect } from 'react'
import { Image } from 'react-bootstrap'
import close from '../../../../assets/images/close-blk.svg'
import arrowUp from '../../../../assets/images/preview_up.svg'
import arrowDown from '../../../../assets/images/preview_down.svg'
import { useDispatch } from 'react-redux'
import { getGeneratedContent } from '../../../../store/knowledge/actions'
import { getTextBlock } from '../../../../store/knowledge/actions'

import { useTranslation } from "react-i18next";

export const DocumentPreview = (props: any) => {
  const { t } = useTranslation('translation');
  const dispatch = useDispatch()
  const [content, setcontent] = React.useState(
    t('Sorry, no content found! Try the search feature above!')
  )
  const [keyword, setkeyword] = React.useState('')
  const [preceed, setpreceed] = React.useState(15)
  const [succeed, setsucceed] = React.useState(15)
  const [textBlockIds, setTextBlockIds] = React.useState({
    previous: "",
    current: "",
    next: ""
  })
  const [enableSearch, setEnableSearch] = React.useState(false)
  useEffect(() => {
    if (
      !props.preview ||
      !props.answerData ||
      props.answerData.keyword.trim().length === 0
    ) {
      return
    }
    getTextBlockDetails(props.answerData._id, props.answerData.text_block_id)
    setkeyword(props.answerData.keyword)
    setpreceed(15)
    setsucceed(15)
  }, [props.answerData, props.preview])

  useEffect(() => {
    if (!props.preview) {
      return
    }
    func(keyword, preceed, succeed)
  }, [preceed, succeed])

  const getTextBlockDetails = async (document_id: string, text_block_id: string) => {
    try {
      const response: any = await dispatch(
        getTextBlock(document_id, text_block_id)
      )
      if (response.text_block.text) {
        setcontent(response.text_block.text)
        setTextBlockIds({
          previous: response.previous_text_block_id,
          current: response.text_block._id,
          next: response.next_text_block_id
        })
      }
      else {
        setcontent(
          t('Sorry, no content found! Try the search feature above!')
        )
      }
      setEnableSearch(false)
    } catch (error: any) {}
  }

  const func = async (keyword: string, preceed?: number, succeed?: number) => {
    try {
      const response: any = await dispatch(
        getGeneratedContent({
          ...props.answerData,
          keyword,
          preceed,
          succeed,
        })
      )
      if (response.content) {
        setcontent(response.content)
      }
      else {
        setcontent(
          t('No content in the document matched the search. Check if you are searching for correct keyword.')
        )
      }
      setEnableSearch(true)
    } catch (error: any) {}
  }

  const searchHandler = (e: any) => {
    setkeyword(e.target.value)
  }

  const clickSearchHandler = (e: any) => {
    func(keyword, 15, 15)
    setpreceed(15)
    setsucceed(15)
    setEnableSearch(true)
  }

  const setpreceedHandler = (e: any) => {
    e.preventDefault()
    if (!enableSearch) {
      getTextBlockDetails(props.answerData._id, textBlockIds.previous)
    }
    else {
      setpreceed(preceed + 50)
    }
  }
  const setsucceedHandler = (e: any) => {
    e.preventDefault()
    if (!enableSearch) {
      getTextBlockDetails(props.answerData._id, textBlockIds.next)
    }
    else {
      setsucceed(succeed + 50)
    }
  }

  return (
    <React.Fragment>
      <div className='notify-header notify-header-document'>
        <h2>
          {t("Search keyword in")} {props?.answerData?.question}
        </h2>
          <button className='btn ml-auto'
            onClick={props.previewToggleHandler}>
            <Image src={close} />
          </button>
      </div>
      <div className='px-4'>
        <div className='d-flex document-search-wrapper'>
          <input
            type='text'
            className='form-control radius-md'
            placeholder={t('Type keywords here and press search')}
            value={keyword || ''}
            onChange={searchHandler}
          />
          <button
            className='btn btn-primary ml-2 radius-md'
            onClick={clickSearchHandler}
          >
            {t("Search")}
          </button>
        </div>
        <div className='doc-search text-center'>
          <button className='btn my-3' onClick={setpreceedHandler}>
            <Image src={arrowUp} alt='button up' />
          </button>
          <div
            className='doc-srch-content border radius-md p-3 text-left'
            dangerouslySetInnerHTML={{ __html: content.toString() }}
          ></div>
          <button className='btn my-3' onClick={setsucceedHandler}>
            <Image src={arrowDown} alt={t('button updown')} />
          </button>
        </div>
      </div>
    </React.Fragment>
  )
}
