import * as type from "./actionType";
export interface IntentInterface {
  _id?: string;
  agent_id: string;
  author_email: string;
  author_id: string;
  buttons: {
    label: string;
    question?: any;
    reference_id: string;
    type: string;

  }[]
  created_at: string;
  document_id: string;
  is_input_disabled?: any;
  is_suggested: false
  name: string;
  organisation_id: string;
  parent_id?: any;
  qa_id?: any;
  questions: {
    text: string;
  }[]
  responses: {
    group_ids?: any;
    text: string;
  }[]
  tags?: any;
  topic_id?: any;
  topic_name?: any;
  type: number;
  updated_at: string;
}

interface ConversationFlowState {
  UILoading?: boolean;
  elements?: any[];
  intent?: IntentInterface;
  loading: boolean;
  error: boolean;
  id: string | null,
  nodeId: string | null,
}

const initialState: ConversationFlowState = {
  UILoading: false,
  elements: [],
  intent: null,
  loading: false,
  error: false,
  id: null,
  nodeId: null,
};

const ConversationFlowReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case type.SETELEMENT:
      return {
        ...state,
        ...action.payload
      }
    case type.UPDATEELEMENT:
      return {
        ...state,
        elements: state.elements.concat(action.payload)
      }
    case type.UPDATE_DRAGGED_ELEMENT:
      return {
        ...state,
        ...action.payload
      }
    default:
      return {
        ...state
      };
  }
};

export default ConversationFlowReducer;
