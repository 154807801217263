import i18n from "../../../components/UI/Internationalisation/i18n";
const { t } = i18n;

export const steps = [
  {
    selector: '.first-step',
    content: () => (
      <div>
        {t("If you wanna go anywhere, skipping places, it is absolutely possible.")}
        <br /> {t("`Oh, I forgot something inside the bus…`")}{' '}
        {/* <button
            style={{
              border: "1px solid #f7f7f7",
              background: "none",
              padding: ".3em .7em",
              fontSize: "inherit",
              display: "block",
              cursor: "pointer",
              margin: "1em auto",
            }}
          >
          </button> */}
      </div>
    ),
  },
  {
    selector: '.second-step',
    content: () => (
      <div>
        {t("If you wanna go anywhere, skipping places, it is absolutely possible.")}
        <br /> {t("`Oh, I forgot something inside the bus…`")}{' '}
        {/* <button
            style={{
              border: "1px solid #f7f7f7",
              background: "none",
              padding: ".3em .7em",
              fontSize: "inherit",
              display: "block",
              cursor: "pointer",
              margin: "1em auto",
            }}
          >
            Please go back to{" "}
            <span aria-label="bus" role="img">
              🚌
            </span>
          </button> */}
      </div>
    ),
  },
  {
    selector: '.third-step',
    content: () => (
      <div>
        {t("If you wanna go anywhere, skipping places, it is absolutely possible.")}
        <br /> {t("`Oh, I forgot something inside the bus…`")}{' '}
        {/* <button
            style={{
              border: "1px solid #f7f7f7",
              background: "none",
              padding: ".3em .7em",
              fontSize: "inherit",
              display: "block",
              cursor: "pointer",
              margin: "1em auto",
            }}
          >
          </button> */}
      </div>
    ),
  },
  // ...
]
