import ApiUtil from "../utils/apiUtil";
import { base64MimeType, convertToBlob, getAgentFromStorage } from "../utils/appUtils";
import DataApiUtil from '../utils/dataApiUtils';

type Type = any;

export default class NotificationService {

    async getNotificationsBasedOnType(type = 'inbox'): Promise<Type> {
        const res = await ApiUtil.SendAsync<any>({
            url: `notification/notifications?mail=${type}&page_size=1000&agent_id=${getAgentFromStorage()}`,
            method: "GET"
        });
        return res;
    }

    async getNotificationsSettings(): Promise<Type> {
        const res = await ApiUtil.SendAsync<any>({
            url: `notification/settings?agent_id=${getAgentFromStorage()}`,
            method: "GET"
        });
        return res;
    }

    async updateNotificationsSettings(data: any): Promise<Type> {
        const res = await ApiUtil.SendAsync<any>({
            url: `notification/settings?agent_id=${getAgentFromStorage()}`,
            method: "PUT",
            data: {
                ...data
            }
        });
        return res;
    }

    async getAllUsers(): Promise<Type> {
        const res = await ApiUtil.SendAsync<any>({
            url: `organisation/users?page_size=5000`,
            method: "GET",
        });
        return res;
    }

    async sendNotifications(data: any): Promise<Type> {
        const res = await ApiUtil.SendAsync<any>({
            url: `notification/notifications?agent_id=${getAgentFromStorage()}`,
            method: "POST",
            data: {
                ...data
            }
        });
        return res;
    }

    async uploadNotificationHeaderImage({ image, name }: any): Promise<Type> {
        const blob = await convertToBlob(image.split("base64,")[1], base64MimeType(image));
        const formData = await new FormData();
        await formData.append(
            "media",
            blob,
            name
        );
        const res = await ApiUtil.SendAsync<any>({
            url: `media/images/upload`,
            method: "POST",
            data: formData
        });
        return res;
    }

}
