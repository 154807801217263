import React, { Children } from "react";
import { Button, Form, Image, Modal, ModalFooter } from "react-bootstrap";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import closeimg from "../../../assets/images/close-blk.svg";

import "./style.scss";

const QuizModal = ({
  children,
  title,
  subTitle,
  buttonLabel,
  buttonAction,
  openModal,
  onRequestClose,
  noModal1100
}: any) => {
  if (!openModal) {
    return null;
  }

  return (
    <>
      <Modal
        show={openModal}
        centered
        scrollable
        dialogClassName={noModal1100 ? "" : "modal-1100"}
        onHide={onRequestClose}
      >
        <ModalHeader onHide={onRequestClose}>
          <Button onClick={onRequestClose} className="mt-2">
            <Image src={closeimg} />
          </Button>
        </ModalHeader>
        <ModalTitle id="contained-modal-title-vcenter">
          {title && title}
          {subTitle && <h2 className="mb-0">{subTitle}</h2>}
        </ModalTitle>
        <ModalBody className="py-0 px-4 sub-question-modal quiz-question-editor-card">
        {Children.toArray(children)}
        </ModalBody>
        <ModalFooter className="justify-content-center border-0 pb-4">
          {buttonLabel && buttonAction && (
            <button
              className="btn-primary btn my-0 font-600"
              onClick={buttonAction}
            >
              {buttonLabel}
            </button>
          )}
        </ModalFooter>
      </Modal>
      {/* <div className="modal">
      <div className="modal-content">
        <div className="modal-header">
          <div className="modal-title">
            {title && title}
            {subTitle && <div className="modal-subtitle">{subTitle}</div>}
          </div>
          <div className="modal-close" onClick={onRequestClose}>
            <i className="icon-close" />
          </div>
        </div>
        <div className="modal-content-body">{Children.toArray(children)}</div>
        {buttonLabel && buttonAction && (
          <div className="modal-button-container">
            <Button
              className="custom-button primary-button"
              onClick={buttonAction}
            >
              {buttonLabel}
            </Button>
          </div>
        )}
      </div>
    </div> */}
    </>
  );
};

export default QuizModal;
