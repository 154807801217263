import React, { memo } from "react";
import { ResponsiveBar } from "@nivo/bar";
import {
  AxisBottomTick,
  AxisBottomWithNoDateTick,
  AxisLeftTick,
  CustomBarComponent,
} from "../StackedBarChart/chart-functions";

export default memo(function BarChart(props: any) {
  return (
    <ResponsiveBar
      {...props}
      barComponent={CustomBarComponent}
      data={props.data}
      keys={[props.keys]}
      padding={0.7}
      // theme={{
      //   axis: {
      //     domain: {
      //       line: {
      //         stroke: "#EAF0F4",
      //         strokeWidth: 1,
      //       },
      //     },
      //   },
      //   grid: {
      //     line: {
      //       stroke: "#EAF0F4",
      //       strokeWidth: 1,
      //     },
      //   },
      // }}
      axisLeft={{
        legend: props.axisLeftLegend ? props.axisLeftLegend : "",
        legendPosition: "middle",
        legendOffset: -38,
        tickSize: 0,
        tickPadding: 5,
        tickss: 'tick',
        renderTick: (e) => {
          return <AxisLeftTick {...e} isQuestion={props.isQuestion} />
        }
      }}
      axisBottom={{
        tickValues: 5,
        legend: props.axisBottomLegend ? props.axisBottomLegend : "Date",
        legendPosition: "middle",
        legendOffset: 55,
        tickPadding: 0,
        tickRotation: 45,
        renderTick: (e) => {
          if ( props.noDateLegend ) {
            return <AxisBottomWithNoDateTick {...e} data={props.data.length} />
          } else {
            return <AxisBottomTick {...e} data={props.data.length} />
          }
        },
      }}
      margin={{
        top: 5,
        right: 5,
        bottom: 45,
        left: 42,
      }}
      indexBy={props.indexBy}
      enableLabel={true}
      borderRadius={4}
      defs={[
        {
          id: "gradientC",
          type: "linearGradient",
          colors: [
            { offset: 0, color: "#00FFEE" },
            { offset: 63, color: "#00ACFF" },
            { offset: 100, color: "#3C09E6" },
          ],
        },
      ]}
      fill={[{ match: "*", id: "gradientC" }]}
      layers={["grid", "axes", "bars", "markers", "legends"]}
    />
  );
});
