import cx from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { v4 as uuidv4 } from "uuid";
import saveQuesicon from "../../../assets/New-images/icon-ques-saved.svg";
import tagInfoicon from "../../../assets/New-images/icon-tag-info.svg";
import linkkeywords from "../../../assets/images/link-keywords.svg";
import ButtonToggle from "../OldPortalComponents/ButtonToggle";
import Card from "../OldPortalComponents/Card";
import CheckRadio from "../OldPortalComponents/CheckRadio";
import Checkbox from "../OldPortalComponents/Checkbox";
import Dropdown from "../OldPortalComponents/Dropdown";
import HeaderDescriptionEditor from "../OldPortalComponents/HeaderDescriptionEditor";
import IconLabelButton from "../OldPortalComponents/IconLabelButton";
import InfoBlock from "../OldPortalComponents/InfoBlock";
import MatchEditor from "../OldPortalComponents/MatchEditor/MatchEditor";
import TableEditor from "../OldPortalComponents/TableEditor";
import TextInput from "../OldPortalComponents/TextInput";
import Toggle from "../../../components/UI/Toggle";
import yellowBulb from "../../../assets/images/yellow-bulb.svg";
import draftjsToMd from "../../../components/UI/RichTextArea/converters/draftJsToMd";
import mdToDraftjs from "../../../components/UI/RichTextArea/converters/mdToDraftJs";

// import draftjsToMd from "../../utils/CustomDraftjsConverters/draftjsToMd";
// import mdToDraftjs from "../../utils/CustomDraftjsConverters/mdToDraftjs";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import Question from "./Question";

import classNames from "classnames";
import * as _ from "lodash";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import AddTags from "../../../components/UI/AddTags/AddTags";
import QuizzesDocumentGroup from "../../../components/UI/QuizzesButtonsGroup/QuizzesDocumentGroup";
import RichTextAreaQuiz from "../../../components/UI/RichTextArea/RichTextAreaQuiz";
import SunEditorUI from "../../../components/UI/SunEditor/SubEditor";
import { HelperService } from "../../../services/helper.service";
import {
  getMcqDistractors,
  updateKeywordsOfQuestion,
} from "../../../store/quizzes/actions";
import { TagsComponent } from "../../Knowledge/AllKnowledge";
import QuizBulletEditor from "../OldPortalComponents/QuizBulletEditor";
import QuizTableEditor from "../OldPortalComponents/QuizTableEditor";
import RubricDescription from "../OldPortalComponents/RubricDescription";
import RubricQuizTableEditor from "../OldPortalComponents/RubricQuizTableEditor";
import {
  difficultyLevels,
  fieldOptions,
  fieldOptionsMap,
  fieldOptionsMapMarkBy,
  fieldOptionsMarkBy,
  newFieldOptionsMarkBy
} from "./data";
import "./style.scss";
import DocumentSearchPopup from "../../../components/UI/DocumentSearchPopup/DocumentSearchPopup";

const QuizQuestionEditor = (props: any) => {
  let history = useHistory();
  const helper = new HelperService();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showTagsModal, setShowTagsModal] = React.useState(false);
  const [idealToggle, setIdealToggle] = React.useState(!!props.data?.ideal_answer);
  const [idealText, setIdealText] = React.useState(props.data?.ideal_answer || "");
  const [showDocumentSearchPopup, setShowDocumentSearchPopup] = React.useState(false);
  const [docRefs, setDocRefs] = React.useState(props.data?.document_references || []);
  const [docRefsCb, setDocRefsCb] = React.useState(props.data?.document_references?.length >= 0);
  const [allTags, setAllTags] = React.useState(
    props.questionBank && props.questionBank.tags ? props.questionBank.tags : []
  );
  const hideOldBullet = props.data.type !== "bullet";
  const [makeEditable, setMakeEditable] = useState(
    props.defaultEditable
      ? props.defaultEditable
      : props.data.text === ""
      ? true
      : false
  );
  const [questionText, setQuestionText] = useState(
    props.data.text ? props.data.text : ""
  );
  const [positionCheck, setPositionCheck] = useState(
    "position_check" in props.data ? props.data.position_check : null
  );

  const [showConfirmGenerateKeys, setShowConfirmGenerateKeys] = useState(false);
  const [showConfirmSaveGenerateKeys, setShowConfirmSaveGenerateKeys] =
    useState(false);

  const [rubricPositionCheck, setRubricPositionCheck] = useState(
    "position_check" in props.data &&
      props.data.type === "essay" &&
      props.data.position_check
      ? props.data.position_check
      : "rubric_description"
  );

  const hideEssayOption = props.data.type !== "essay" && !props.isEssayGrader;

  const convertToEditorFormat = (data: any) => {
    const rawData = mdToDraftjs(data, {});
    const contentState = convertFromRaw(rawData);

    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  };

  const convertObjectToString = (obj: any) => {
    let newString = "";
    Object.keys(obj).forEach((keyword) => {
      newString = newString + `${keyword} = ${obj[keyword]}, `;
    });
    return newString;
  };

  const convertToBulletFormat = (data: any) => {
    const convertedData: any = [];
    data.forEach((item: any) => {
      convertedData.push({
        ...item,
        keyword_scores: convertObjectToString(
          item.keyword_scores ? item.keyword_scores : {}
        ),
      });
    });
    return convertedData;
  };

  const [idealAnswer, setIdealAnswer] = useState(
    props.data.ideal_answer &&
      (props.data.type !== "table" || props.data.type !== "description")
      ? convertToEditorFormat(props.data.ideal_answer)
      : EditorState.createEmpty()
  );
  const [idealAnswerItems, setIdealAnswerItems] = useState(
    props.data.ideal_answer_items &&
      props.data.position_check !== "rubric_description"
      ? convertToBulletFormat(props.data.ideal_answer_items)
      : [
          { text: "", keyword_scores: "", score: 1 },
          { text: "", keyword_scores: "", score: 1 },
        ]
  );
  const [bpQuestionText, setBpQuestionText] = useState("");
  const [questionOptions, setQuestionOptions] = useState(
    props.data.options
      ? [...props.data.options]
      : [
          {
            text: "",
            is_correct: false,
          },
          {
            text: "",
            is_correct: false,
          },
        ]
  );

  const [bpQuestionOptions, setBpQuestionOptions] = useState([
    {
      text: "",
      id: null,
      is_correct: false,
    },
    {
      text: "",
      id: null,
      is_correct: false,
    },
  ]);
  const [questionScore, setQuestionScore] = useState(
    "score" in props.data && props.data.position_check !== "rubric_description"
      ? props.data.score
      : props.data.type === "description"
      ? 2
      : 1
  );

  const [bpQuestionScore, setBpQuestionScore] = useState(
    props.data.type === "description" ? 2 : 1
  );
  const [questionType, setQuestionType] = useState(
    props.data.type ? props.data.type : "radio"
  );
  const [questionTopic, setQuestionTopic] = useState(
    props.data.topic ? props.data.topic : ""
  );
  const [questionTopicId, setQuestionTopicId] = useState(
    props.data.topic_id ? props.data.topic_id : ""
  );
  const [bpQuestionType, setBpQuestionType] = useState("radio");
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showLogicOptions, setShowLogicOptions] = useState(false);
  const [showKeywordsOptions, setShowKeywordsOptions] = useState(false);
  const [logicSettings, setLogicSettings] = useState<any>({});
  const [bpLogicSettings, setBpLogicSettings] = useState<any>({});
  const [questionIdLabelMap, setQuestionIdLabelMap] = useState<any>({});
  const [answerKeySettings, setAnswerKeySettings] = useState(
    props.data.answer_key_settings &&
      props.data.position_check !== "rubric_description"
      ? props.data.answer_key_settings
      : {}
  );
  const [tableAnswer, setTableAnswer] = useState(
    props.data.ideal_answer &&
      (props.data.type === "table" || props.data.type === "description")
      ? JSON.parse(props.data.ideal_answer)
      : null
  );
  let bpTableAnswer = useRef(
    props.data.ideal_answer &&
      (props.data.type === "table" || props.data.type === "description")
      ? JSON.parse(props.data.ideal_answer)
      : null
  );

  const [matchAnswer, setMatchAnswer] = useState(
    props.data.matches && props.data.type === "matching"
      ? props.data.matches
      : null
  );
  const [keywordsData, setKeywordsData] = useState(null);
  const [scoredBy, setScoredBy] = useState(
    props.data.scored_by
      ? {
          value: props.data.scored_by,
          label: fieldOptionsMapMarkBy[props.data.scored_by],
        }
      : fieldOptionsMarkBy[0]
  );

  const [difficultyLevel, setDifficultyLevel] = useState(
    props.data.hasOwnProperty("difficulty_level")
      ? {
          value: props.data.difficulty_level,
          label: props.data.difficulty_level.toString(),
        }
      : props.questionBank &&
        props.questionBank.hasOwnProperty("difficulty_level")
      ? {
          value: props.questionBank.difficulty_level,
          label: props.questionBank.difficulty_level.toString(),
        }
      : difficultyLevels[0]
  );
  const [baseScore, setBaseScore] = useState(
    props.data.base_score ? props.data.base_score : 1
  );
  const [headerScore, setHeaderScore] = useState(
    "base_score_1" in props.data ? props.data.base_score_1 : 1
  );
  const [addHeaderScore, setAddHeaderScore] = useState(
    "base_score_1" in props.data && props.data.base_score_1 !== null
      ? true
      : false
  );
  const [subQuestionsForSave, setSubQuestionsForSave] = useState<any>({});
  const [subQuestionErrors, setSubQuestionErrors] = useState<any>({});
  const [enterComplete, setEnterComplete] = useState({
    status: false,
    identifier: "",
    index: "",
  });
  const [turnOnEnhancedKeywords, setTurnOnEnhancedKeywords] = useState(
    props.data.scored_by === "enhanced_keywords" ? true : false
  );
  const [newTableRowCount, setNewTableRowCount] = useState(
    "answer_key_settings" in props.data &&
      props.data.answer_key_settings.row_count &&
      props.data.type === "essay"
      ? props.data.answer_key_settings.row_count
      : 2
  );

  const [newTableColumnCount, setNewTableColumnCount] = useState(
    "answer_key_settings" in props.data &&
      props.data.answer_key_settings.column_count
      ? props.data.answer_key_settings.column_count
      : 2
  );

  const [rubricDescIdealAnswerItems, setRubricDescIdealAnswerItems] = useState(
    props.data.ideal_answer_items &&
      props.data.position_check === "rubric_description"
      ? convertToBulletFormat(props.data.ideal_answer_items)
      : [
          { text: "", keyword_scores: "", score: 1 },
          { text: "", keyword_scores: "", score: 1 },
        ]
  );

  const [rubricDescAnswerKeySettings, setRubricDescAnswerKeySettings] =
    useState(
      props.data.answer_key_settings &&
        props.data.position_check === "rubric_description"
        ? {
            ...props.data.answer_key_settings,
            headers: [
              "Score Criteria",
              "Score for Criteria",
              "Description of Scoring Criteria",
            ],
          }
        : {
            headers: [
              "Score Criteria",
              "Score for Criteria",
              "Description of Scoring Criteria",
            ],
          }
    );

  const [rubricDescQuestionScore, setRubricDescQuestionScore] = useState(
    "score" in props.data &&
      props.data.type === "essay" &&
      props.data.position_check === "rubric_description"
      ? props.data.score
      : 1
  );

  const [rubricDescRowCount, setRubricDescRowCount] = useState(1);

  useEffect(() => {
    if ("keyword_scores" in props.data && props.data.keyword_scores !== null) {
      let temp = convertKeywordsToTable(props.data.keyword_scores);
      setKeywordsData(temp);
    }
    if (
      "answer_key_settings" in props.data &&
      props.data.answer_key_settings.row_count
    ) {
      setNewTableRowCount(props.data.answer_key_settings.row_count);
      setNewTableColumnCount(props.data.answer_key_settings.column_count);
      setRubricDescRowCount(props.data.answer_key_settings.row_count);
    }

    generateMap();
  }, []);

  useEffect(() => {
    if (!props.data.subquestions) {
      return;
    }
    // console.log('props.data.subquestions', props.data.subquestions)
    let questionObj: any = Object.keys(subQuestionsForSave).length
      ? { ...subQuestionsForSave }
      : {};
    if (
      (props.data.subquestions &&
        Object.keys(subQuestionsForSave).length === 0) ||
      props.data.subquestions.length !== Object.keys(subQuestionsForSave).length
    ) {
      props.data?.subquestions?.forEach((question: any) => {
        if (!subQuestionsForSave[question.id]) {
          Object.assign(questionObj, {
            [question.id]: question,
          });
        }
      });
      // console.log('questionObj', questionObj)
      setSubQuestionsForSave(questionObj);
      setSubQuestionErrors({});
    }
  }, [props.data.subquestions]);

  useEffect(() => {
    if (
      answerKeySettings &&
      answerKeySettings.headers &&
      answerKeySettings.headers.length !== 0
    ) {
      if (questionType === "essay") {
        let matches: any = [
          ...answerKeySettings.headers.map((score: any) => {
            let numbers = score.match(/\d+/g);
            if (numbers && numbers.length > 0) {
              numbers = numbers.map((i: any) => Number(i));
              return Math.max(...numbers);
            }
            return 0;
          }),
        ];
        if (matches && matches.length > 0) {
          // const score = matches.reduce((a: any, b: any) => a + b, 0);
          // setQuestionScore(score <= 0 ? 1 : score);
          let maximum = Math.max(...matches);
          if (maximum) {
            setQuestionScore(newTableRowCount * maximum);
          }
        }
      }
    }
  }, [answerKeySettings, newTableRowCount]);

  useEffect(() => {
    if (props.closeEditable) {
      setShowError(false);
      setMakeEditable(false);
      props.setCloseEditable(false);
    }
  }, [props.closeEditable]);

  // Run effect to set default first options when answer keys are not empty.
  // useEffect(() => {
  //   console.log(' PROPS CHANGES ', props, props.questionNumber)

  //   let temp: any = {};
  //   temp.id = props.questionId;

  //   temp.text = questionText;
  //   temp.topic_id = questionTopicId;
  //   temp.topic = questionTopic;
  //   temp.type = questionType;
  //   temp.difficulty_level = difficultyLevel.value || 1;
  //   temp.score = parseInt(questionScore);
  //   if (questionType === "radio" || questionType === "checkbox") {
  //     temp.options = questionOptions;
  //   }
  //   if (questionType === "text" || questionType === "bullet") {
  //     temp.ideal_answer = convertToMarkdown(idealAnswer);
  //   }
  //   if (questionType === "newbullet") {
  //     temp.ideal_answer = convertToMarkdown(idealAnswer);
  //     let transformedIdealAnswerItems: any = [];
  //     idealAnswerItems.forEach((item: any) => {
  //       transformedIdealAnswerItems.push({
  //         ...item,
  //         keyword_scores: convertStringToObject(item.keyword_scores),
  //         score: "score" in item ? Number(item.score) : 1,
  //         scored_by: scoredBy.value,
  //       });
  //     });
  //     temp.ideal_answer_items = transformedIdealAnswerItems;
  //   }
  //   if (
  //     ["text", "table", "bullet", "description", "newbullet"].indexOf(
  //       questionType
  //     ) > -1
  //   ) {
  //     temp.scored_by = scoredBy.value;
  //   }
  //   if (questionType === "table") {
  //     let settings = { ...answerKeySettings };
  //     settings.row_count = newTableRowCount;
  //     settings.column_count = newTableColumnCount;
  //     temp.answer_key_settings = settings;
  //     setAnswerKeySettings(temp.answer_key_settings);
  //     let transformedIdealAnswerItems: any = [];
  //     idealAnswerItems.forEach((item: any) => {
  //       transformedIdealAnswerItems.push({
  //         ...item,
  //         keyword_scores: convertStringToObject(item.keyword_scores),
  //         score: "score" in item ? Number(item.score) : 1,
  //         scored_by: scoredBy.value,
  //       });
  //     });
  //     temp.ideal_answer_items = transformedIdealAnswerItems;
  //   }
  //   if (questionType === "description") {
  //     let settings = { ...answerKeySettings };
  //     settings.column_headers = [];
  //     settings.headers = ["", ""];
  //     settings.enable_column_header = false;
  //     temp.answer_key_settings = settings;
  //     temp.ideal_answer = JSON.stringify(tableAnswer);
  //   }
  //   if (questionType === "matching") {
  //     let settings = { ...answerKeySettings };
  //     settings.column_headers = [];
  //     settings.enable_column_header = false;
  //     temp.answer_key_settings = settings;
  //     temp.matches = matchAnswer;
  //   }
  //   if (
  //     scoredBy.value === "keywords" &&
  //     questionType !== "group" &&
  //     isTableValid()
  //   ) {
  //     temp.keyword_scores = convertTableToKeywords();
  //   } else {
  //     temp.keywords = null;
  //   }
  //   if (questionType === "bullet" || questionType === "description") {
  //     temp.base_score = parseInt(baseScore);
  //   }
  //   if (questionType === "description") {
  //     temp.base_score_1 =
  //       headerScore && addHeaderScore ? parseInt(headerScore) : null;
  //   }
  //   if (
  //     questionType === "bullet" ||
  //     questionType === "table" ||
  //     questionType === "description"
  //   ) {
  //     temp.position_check = positionCheck;
  //   }
  //   // temp.valid = checkisQuestionValid();
  //   if (questionType === "group") {
  //     let data = [],
  //       queIds = [];
  //     data.push(temp);
  //     queIds.push(props.questionId);
  //     Object.keys(subQuestionsForSave).forEach((subquestionId: any) => {
  //       data.push(subQuestionsForSave[subquestionId]);
  //       queIds.push(subquestionId);
  //     });
  //   } else {
  //     if (bpQuestionType === "group") {
  //       temp.id = uuidv4();
  //     }
  //   }
  //   checkisQuestionValid(temp)
  // }, [
  //   props,
  //   questionText,
  //   questionTopicId,
  //   questionTopic,
  //   questionType,
  //   answerKeySettings,
  //   subQuestionsForSave,
  //   positionCheck,
  //   idealAnswer,
  //   idealAnswerItems,
  //   bpQuestionText,
  //   questionOptions,
  //   bpQuestionOptions,
  //   questionScore,
  //   bpQuestionScore,
  //   questionType,
  //   questionTopic,
  //   questionTopicId,
  //   bpQuestionType,
  //   showError,
  //   errorMessage,
  //   showLogicOptions,
  //   showKeywordsOptions,
  //   logicSettings,
  //   bpLogicSettings,
  //   questionIdLabelMap,
  //   answerKeySettings,
  //   tableAnswer,
  //   matchAnswer,
  //   keywordsData,
  //   scoredBy,
  //   difficultyLevel,
  //   baseScore,
  //   headerScore,
  //   addHeaderScore,
  //   subQuestionsForSave,
  //   subQuestionErrors,
  //   enterComplete,
  //   turnOnEnhancedKeywords,
  //   newTableRowCount,
  //   newTableColumnCount,
  // ]);

  // Run effect to set default first options when answer keys are not empty.
  useEffect(() => {
    if (questionOptions) {
      // const itemObject = questionOptions[0];
      // setIdealAnswer(convertToEditorFormat(itemObject.text));
      // setIdealAnswerItems(convertToBulletFormat([itemObject]));
      // setTableAnswer([[null, questionOptions[0].text]]);
      // setMatchAnswer([{
      //   term: itemObject.text,
      //   definition: ''
      // }]);
    }
  }, [questionType, questionOptions]);

  const generateMap = () => {
    let tempMap: any = {};
    props.allQuestions.forEach((question: any, index: any) => {
      tempMap[question.id] = {
        label: <div dangerouslySetInnerHTML={{ __html: question.text }}></div>,
        options: question.options,
      };
    });

    setQuestionIdLabelMap(tempMap);
  };

  useEffect(() => {
    if (Object.keys(questionIdLabelMap).length) {
      transformToLogicSettings(questionOptions);
    }
  }, [questionIdLabelMap]);

  useEffect(() => {
    if (props.questionBank && props.questionBank.tags) {
      setAllTags(_.uniq([...allTags, ...props.questionBank.tags]));
    }
  }, [props.questionBank]);

  const transformToLogicSettings = (options: any) => {
    let temp: any = {};
    options.forEach((option: any) => {
      if (option.branch && option.branch.action) {
        if (option.branch.action === "end-quiz") {
          temp[option.id] = {
            value: "end-quiz",
            label: t("End quiz"),
          };
        } else if (option.branch.action === "jump-to-question") {
          temp[option.id] = {
            value: option.branch.reference_id,
            label: questionIdLabelMap[option.branch.reference_id]
              ? questionIdLabelMap[option.branch.reference_id].label
              : "",
          };
        }
      }
    });

    setLogicSettings(temp);
    setBpLogicSettings(temp);
  };

  const onQuestionChange = (value: any) => {
    setQuestionText(value);
  };

  const onAnswerChange = (value: any) => {
    setIdealAnswer(value);
  };

  const onTableChange = (value: any) => {
    setTableAnswer(value);
  };

  const onAnswerKeyTableChange = (
    value: any,
    rowCount: any,
    columnCount: any
  ) => {
    setIdealAnswerItems(value);
    setNewTableRowCount(rowCount);
    setNewTableColumnCount(columnCount);
  };

  const onRubricDescAnswerKeyTableChange = (
    value: any,
    rowCount: any,
    columnCount: any
  ) => {
    let matches: any = [
      ...value
        .filter((e: any, i: any) => i % 2 === 0)
        .map((val: any) => {
          let numbers = val.text.match(/\d+/g);
          if (numbers && numbers.length > 0) {
            numbers = numbers.map((i: any) => Number(i));
            return Math.max(...numbers);
          }
          return 0;
        }),
    ];

    if (matches && matches.length > 0) {
      // let maximum = Math.max(...matches);
      //     if (maximum) {
      //       setRubricDescQuestionScore(rowCount * maximum);
      //     }
      setRubricDescQuestionScore(matches.reduce((a: any, b: any) => a + b, 0));
    }
    setRubricDescIdealAnswerItems(value);
    setRubricDescRowCount(rowCount);
    setNewTableColumnCount(columnCount);
  };

  const handleQuestionCardDelete = (id: any) => {
    let temp = [...idealAnswerItems];
    temp.splice(id, 1);
    setIdealAnswerItems(temp);
  };

  const onQuestionEdit = (event: any, id: any) => {
    let sentences = event.target.value.split("\n");
    let temp = [...idealAnswerItems];

    if (sentences.length <= 1) {
      temp[id].text = event.target.value;
      setIdealAnswerItems(temp);
    }
  };

  const keywordsInputChange = (event: any, id: any) => {
    let temp = [...idealAnswerItems];
    temp[id].keyword_scores = event.target.value;
    setIdealAnswerItems(temp);
  };

  const pointScoreChange = (event: any, id: any) => {
    let temp = [...idealAnswerItems];
    temp[id].score = event.target.value;
    setIdealAnswerItems(temp);
    let newMaxScore = 0;
    temp.forEach((item: any) => {
      newMaxScore += Number(item.score);
    });
    setQuestionScore(newMaxScore.toString());
  };

  const handlePaste = (event: any, id: any) => {
    let temp = event.clipboardData
      ? event.clipboardData.getData("text")
      : event.target.value;
    let sentences = temp.split("\n");

    let tempAnswer = [...idealAnswerItems];

    if (sentences.length > 1) {
      let count = 0;
      sentences.forEach((sentence: any) => {
        if (sentence.trim()) {
          tempAnswer.splice(id + count, 0, {
            text: sentence.trim(),
            keyword_scores: "",
            score: 1,
          });
          count += 1;
        }
      });
    }

    setIdealAnswerItems(tempAnswer);
  };

  useEffect(() => {
    if (enterComplete.status) {
      let element = document.getElementById(
        `input${enterComplete.identifier.toString()}${enterComplete.index.toString()}`
      );
      if (element) {
        element.focus();
      }
    }
  }, [enterComplete]);

  const handleBulletInputEnter = (event: any, id: any) => {
    if (event.key === "Enter" || event.keyCode === 13 || event.which === 13) {
      let tempAnswer = [...idealAnswerItems];

      tempAnswer.splice(id + 1, 0, { text: "", keyword_scores: "", score: 1 });
      setIdealAnswerItems(tempAnswer);
      setEnterComplete({
        status: true,
        identifier: props.questionId,
        index: id + 1,
      });
      setQuestionScore((data: any) => (Number(data) + 1).toString());
    }
  };

  const onMatchEditorChange = (value: any) => {
    setMatchAnswer(value);
  };

  const onKeywordsTableChange = (value: any) => {
    setKeywordsData(value);
  };

  const handleTableHeaderChange = (value: any) => {
    let temp = { ...answerKeySettings };
    temp.headers = value;
    setAnswerKeySettings(temp);
  };

  const handleRubricTableHeaderChange = (value: any) => {
    let temp = { ...rubricDescAnswerKeySettings };
    temp.headers = value;
    setRubricDescAnswerKeySettings(temp);
  };

  const handleVerticalTableHeaderChange = (value: any) => {
    let temp = { ...answerKeySettings };
    temp.column_headers = value;
    setAnswerKeySettings(temp);
  };

  const handleRubricDescVerticalTableHeaderChange = (value: any) => {
    let temp = { ...rubricDescAnswerKeySettings };
    temp.column_headers = value;
    setRubricDescAnswerKeySettings(temp);
  };

  const updateEnableColumnHeader = (value: any) => {
    let temp = { ...answerKeySettings };
    temp.enable_column_header = value;
    setAnswerKeySettings(temp);
  };

  const onOptionChange = (value: any, optionIndex: any) => {
    setShowError(false);
    let temp = [...questionOptions];
    temp[optionIndex] = {
      ...temp[optionIndex],
      text: value,
      id: temp[optionIndex].id,
      is_correct: temp[optionIndex].is_correct,
    };
    setQuestionOptions(temp);
  };

  const onTypeChange = (value: any) => {
    setQuestionType(value);
    if (value !== "radio") {
      let options = [...questionOptions];
      options.forEach((option: any) => {
        delete option.branch;
      });

      setQuestionOptions(options);
      setLogicSettings({});
    }

    // if (
    //   (value === "checkbox" || value === "radio") &&
    //   !idealAnswer.getCurrentContent().hasText() &&
    //   !idealAnswerItems
    // ) {
    //   console.log("here");

    //   let options = [...questionOptions];
    //   let temp = props.data?.options?.filter(
    //     (e: any) => e.text.trim().length !== 0
    //   );
    //   if (options.length === 0 && idealAnswer.getCurrentContent().hasText()) {
    //     console.log("in1");

    //     options.push({
    //       id: uuidv4(),
    //       text: convertToMarkdown(idealAnswer),
    //       is_correct: false,
    //     });
    //     setQuestionOptions(options);
    //   } else if (temp && temp.length > 0) {
    //     console.log("in2");

    //     //   let data: any = [];
    //     // options.map((e: any, i: any) => {
    //     //   data.push({
    //     //     id: uuidv4(),
    //     //     text: e.text,
    //     //     is_correct: i === 0 ? true : false,
    //     //   });
    //     // });
    //     // setQuestionOptions([...data]);
    //     setExecuteGenerateDistractors(true);
    //   } else {
    //     return;
    //   }
    // }

    // if (questionOptions.length > 0 && (value === "checkbox" || value === "radio")) {
    //   let options = [...questionOptions];
    //   let temp: any = [];
    //   options.map((e: any, i: any) => {
    //     temp.push({
    //       id: uuidv4(),
    //       text: e.text,
    //       is_correct: i === 0 ? true : false,
    //     });
    //   });
    //   setQuestionOptions([...temp])
    //   setExecuteGenerateDistractors(true);
    // }

    if (value === "description" && idealAnswer) {
      setTableAnswer([[convertToMarkdown(idealAnswer), null]]);
    }

    if (!props.data.matches && value === "matching" && idealAnswer) {
      setMatchAnswer([
        {
          term: convertToMarkdown(idealAnswer),
          definition: "",
        },
      ]);
    }

    // if (!props.data.matches && value === "essay" && idealAnswer) {
    //   setRubricMatchAnswer([
    //     {
    //       term: convertToMarkdown(idealAnswer),
    //       definition: "",
    //       score: ""
    //     },
    //   ]);
    // }

    if (value === "essay") {
      setIdealAnswerItems([
        { text: "", keyword_scores: "", score: 1 },
        { text: "", keyword_scores: "", score: 1 },
      ]);
      setAnswerKeySettings({
        ...answerKeySettings,
        headers: ["", "", ""],
        column_headers: [""],
        enable_column_header: false,
        column_count: 2,
        row_count: 1,
      });
    }

    if (
      !props.data.answer_key_settings &&
      value === "matching" &&
      idealAnswer
    ) {
      setAnswerKeySettings({
        ...answerKeySettings,
        headers: [convertToMarkdown(idealAnswer), ""],
        column_headers: [],
        enable_column_header: false,
      });
    }

    if (value === "radio" || value === "checkbox") {
      let temp: any = [...idealAnswerItems];
      temp = temp.filter((e: any) => e.text.trim().length !== 0);
      let options = props.data?.options?.filter(
        (e: any) => e.text.trim().length !== 0
      );
      if (
        temp.length >= 1 &&
        props.data.type !== "table" &&
        props.data.type !== "description" &&
        props.data.type !== "matching" &&
        (!options || options.length === 0) &&
        !idealAnswer.getCurrentContent().hasText()
      ) {
        const data: any = [];
        temp.map((e: any) => {
          data.push({
            id: uuidv4(),
            text: e.text.trim(),
            is_correct: false,
          });
        });

        if (data && data.length !== 0) {
          setQuestionOptions([...data]);
        }
        // setQuestionOptions([
        //   {
        //     id: uuidv4(),
        //     text: idealAnswerItems[0].text,
        //     is_correct: false,
        //   },
        //   {
        //     id: uuidv4(),
        //     text: "",
        //     is_correct: false,
        //   },
        // ]);
      } else if (
        idealAnswer.getCurrentContent().hasText() &&
        temp.length === 0 &&
        (!options || options.length === 0)
      ) {
        setQuestionOptions([
          {
            id: uuidv4(),
            text: convertToMarkdown(idealAnswer),
            is_correct: false,
          },
          {
            id: uuidv4(),
            text: "",
            is_correct: false,
          },
        ]);
      } else if (
        options &&
        options.length > 0 &&
        !idealAnswer.getCurrentContent().hasText() &&
        (!temp || temp.length === 0)
      ) {
        setExecuteGenerateDistractors(true);
      } else {
        return;
      }
      setExecuteGenerateDistractors(true);
    }

    if (
      !props.data.ideal_answer_items &&
      value === "newbullet" &&
      idealAnswer
    ) {
      setIdealAnswerItems([
        { text: convertToMarkdown(idealAnswer), keyword_scores: "", score: 1 },
        { text: "", keyword_scores: "", score: 1 },
      ]);
    }
  };

  const onScoredByChange = (value: any) => {
    setScoredBy(value);
    if (value.value === "enhanced_keywords") {
      setTurnOnEnhancedKeywords(true);
    } else {
      setTurnOnEnhancedKeywords(false);
    }
  };

  const onDifficultyLevelChange = (value: any) => {
    setDifficultyLevel(value);
  };

  const onScoreChange = (event: any) => {
    setShowError(false);
    // let score = parseFloat(event.target.value);
    setQuestionScore(
      Number.isNaN(event.target.value) ? "0" : event.target.value
    );
  };

  const onBaseScoreChange = (event: any) => {
    setShowError(false);
    setBaseScore(Number.isNaN(+event.target.value) ? "0" : +event.target.value);
  };

  const onHeaderScoreChange = (event: any) => {
    setShowError(false);
    setHeaderScore(
      Number.isNaN(+event.target.value) ? "0" : +event.target.value
    );
  };

  const onOptionDelete = (optionId: any) => {
    let temp = [...questionOptions];
    questionOptions.forEach((option, index) => {
      if (option.id === optionId) {
        temp[index] = "remove";
      } else {
        temp[index] = {
          ...option,
          id: option.id,
          text: option.text,
          is_correct: option.is_correct,
        };
      }
    });
    let opt = [];
    opt = temp.filter(function (el) {
      return el !== "remove";
    });
    setQuestionOptions(opt);
  };

  const handleCheckboxCorrect = (isChecked: any, optionIndex: any) => {
    setShowError(false);
    let temp = [...questionOptions];
    temp[optionIndex].is_correct = isChecked;
    setQuestionOptions(temp);
  };

  const handleRadioCorrect = (value: any) => {
    let temp = [...questionOptions];
    temp.forEach((option: any) => {
      option.is_correct = false;
    });
    temp[parseInt(value)].is_correct = true;
    setQuestionOptions(temp);
  };

  const addOption = () => {
    let temp = [...questionOptions];
    temp.push({
      id: uuidv4(),
      text: "",
      is_correct: false,
    });
    setQuestionOptions(temp);
  };

  const isOptionCheckValid = (options: any = null) => {
    let tempOptions = options ? options : questionOptions;
    let isValid = false;
    tempOptions.forEach((option: any) => {
      if (option.is_correct) {
        isValid = true;
      }
    });
    return isValid;
  };

  const isOptionTextValid = (options: any = null) => {
    let tempOptions = options ? options : questionOptions;
    let isValid = true;
    tempOptions.forEach((option: any) => {
      if (!option.text && !option.text.trim()) {
        isValid = false;
      }
    });
    return isValid;
  };

  const sendSubquestionsData = (data: any, questionId: any) => {
    setShowError(false);
    let tempErrors = { ...subQuestionErrors };
    tempErrors[questionId] = "";
    setSubQuestionErrors(tempErrors);
    let temp = { ...subQuestionsForSave };
    temp[questionId] = data;
    setSubQuestionsForSave(temp);
  };

  const deleteQuestionData = (event: any) => {
    const toDeleteQuestionFromObject = { ...subQuestionsForSave };
    delete toDeleteQuestionFromObject[event];
    setSubQuestionsForSave(toDeleteQuestionFromObject);
    setSubQuestionErrors({});
    props.onQuestionDelete(event);
  };

  const areSubQuestionsValid = () => {
    let temp: any = {};
    let isValid = true;
    Object.keys(subQuestionsForSave).forEach((id: any) => {
      if (
        subQuestionsForSave[id].text === "" ||
        subQuestionsForSave[id].text.trim() === ""
      ) {
        isValid = false;
        temp[id] = t("Error! Question cannot be empty");
      } else if (subQuestionsForSave[id].score < 0) {
        isValid = false;
        temp[id] = t(
          "Error! Please enter a valid score (any number more than or equal to 0)"
        );
      } else if (
        (subQuestionsForSave[id].type === "checkbox" ||
          subQuestionsForSave[id].type === "radio") &&
        !isOptionTextValid(subQuestionsForSave[id].options)
      ) {
        isValid = false;
        temp[id] = t("Error! Option cannot be empty");
      } else if (
        (subQuestionsForSave[id].type === "checkbox" ||
          subQuestionsForSave[id].type === "radio") &&
        subQuestionsForSave[id].score > 0 &&
        !isOptionCheckValid(subQuestionsForSave[id].options)
      ) {
        isValid = false;
        temp[id] = t("Error! Please mark at least one option as correct");
      } else if (
        subQuestionsForSave[id].type === "essay" &&
        subQuestionsForSave[id].position_check === "rubric_breakdown" &&
        !isEssayTableValid(
          subQuestionsForSave[id].answer_key_settings,
          subQuestionsForSave[id].ideal_answer_items
        )
      ) {
        isValid = false;
        temp[id] = t("Error! Inputs cannot be empty");
      } else if (
        subQuestionsForSave[id].type === "essay" &&
        subQuestionsForSave[id].position_check === "rubric_description" &&
        !isEssayTableValid(
          subQuestionsForSave[id].answer_key_settings,
          subQuestionsForSave[id].ideal_answer_items
        )
      ) {
        isValid = false;
        temp[id] = t("Error! Inputs cannot be empty");
      }
    });
    setSubQuestionErrors(temp);
    return isValid;
  };

  useEffect(() => {
    if (props.updatingQuizQuestionsFromParent) {
      onSave();
      if (props.hasLastIndex) {
        setTimeout(() => {
          props.SetUpdatingQuizQuestionsFromParent(false);
        }, 500);
      }
    }
  }, [props.updatingQuizQuestionsFromParent]);

  // useEffect(() => {
  //   onSave()
  // }, [])

  const convertStringToObject = (str: any) => {
    let allKeywords = str.split(",");
    let temp: any = {};
    allKeywords.forEach((item: any) => {
      if (!item.trim().length) {
        return;
      }
      let keywordWithScore = item.split("=");
      if (keywordWithScore[0]) {
        temp[keywordWithScore[0].trim()] = keywordWithScore[1]
          ? Number(keywordWithScore[1].trim())
          : 1;
      }
    });
    return temp;
  };

  const isEssayTableValid = (answerKeySettings: any, idealAnswerItems: any) => {
    let headers: any = [];
    let columnHeaders: any = [];
    let isValid = true;
    let headersValidity = false;

    if (
      answerKeySettings &&
      answerKeySettings.headers &&
      answerKeySettings.headers.length !== 0
    ) {
      headers = [...answerKeySettings["headers"]];
    }
    if (
      answerKeySettings &&
      answerKeySettings.column_headers &&
      answerKeySettings.column_headers.length !== 0
    ) {
      columnHeaders = [...answerKeySettings["column_headers"]];
    }

    headers = headers && headers.length > 0 ? headers.slice(1) : headers;

    headersValidity =
      (headers.length !== 0
        ? !Object.keys(headers).some((e: any) => headers[e].trim().length === 0)
        : false) &&
      (columnHeaders.length !== 0
        ? !Object.keys(columnHeaders).some(
            (e: any) => columnHeaders[e].trim().length === 0
          )
        : false);
    isValid =
      headersValidity &&
      !Object.keys(idealAnswerItems).some(
        (e) => idealAnswerItems[e].text.trim().length === 0
      );

    return isValid;
  };

  const checkisQuestionValid = (temp: any) => {
    const updatedQuestion: any = temp;
    let invalid: boolean = false;
    const rawText = questionText.replace(/(<([^>]+)>)/gi, "");
    const rawTextIdeal = idealText?.replace(/(<([^>]+)>)/gi, "");


    if (rawText === "" || rawText.trim() === "") {
      setShowError(true);
      setErrorMessage(t("Error! Question cannot be empty"));
      updatedQuestion.invalid = true;
      invalid = true;
    } else if (questionScore < 0) {
      setShowError(true);
      setErrorMessage(
        t(
          "Error! Please enter a valid score (any number more than or equal to 0)"
        )
      );
      updatedQuestion.invalid = true;
      invalid = true;
    } else if (
      (questionType === "checkbox" || questionType === "radio") &&
      !isOptionTextValid()
    ) {
      setShowError(true);
      setErrorMessage(t("Error! Option cannot be empty"));
      updatedQuestion.invalid = true;
      invalid = true;
    } else if (
      (questionType === "checkbox" || questionType === "radio") &&
      questionScore > 0 &&
      !isOptionCheckValid()
    ) {
      setShowError(true);
      setErrorMessage(t("Error! Please mark at least one option as correct"));
      updatedQuestion.invalid = true;
      invalid = true;
    } else if (questionType === "matching") {
      const hasInvalidKey = temp?.matches.some((match: any) => {
        if (match?.term.length === 0 || match?.definition.length === 0) {
          return true;
        }
        return false;
      });
      updatedQuestion.invalid = hasInvalidKey;
      invalid = hasInvalidKey;
      setShowError(hasInvalidKey);
      if (hasInvalidKey) {
        setErrorMessage(t("Error! Answer should not be empty."));
      }
    } else if (
      questionType === "group" &&
      Object.keys(subQuestionsForSave).length === 0
    ) {
      setShowError(true);
      setErrorMessage(t("Error! Please add at least one sub-question"));
      updatedQuestion.invalid = true;
      invalid = true;
    } else if (
      questionType === "group" &&
      Object.keys(subQuestionsForSave).length > 0 &&
      !areSubQuestionsValid()
    ) {
      // console.log('[ subQuestionsForSave ]', subQuestionsForSave)
      setShowError(true);
      setErrorMessage(t("Error! Please check the sub-questions"));
      updatedQuestion.invalid = true;
      invalid = true;
    } else if (
      questionType === "essay" &&
      rubricPositionCheck === "rubric_breakdown" &&
      !isEssayTableValid(answerKeySettings, idealAnswerItems)
    ) {
      setShowError(true);
      setErrorMessage(t("Error! Inputs cannot be empty"));
      updatedQuestion.invalid = true;
      invalid = true;
    } else if (
      questionType === "essay" &&
      rubricPositionCheck === "rubric_description" &&
      !isEssayTableValid(
        rubricDescAnswerKeySettings,
        rubricDescIdealAnswerItems
      )
    ) {
      setShowError(true);
      setErrorMessage(t("Error! Inputs cannot be empty"));
      updatedQuestion.invalid = true;
      invalid = true;
    }
    // else if (
    //   questionType === "table" &&
    //   (!("headers" in temp.answer_key_settings) ||
    //     ("headers" in temp.answer_key_settings &&
    //       (temp.answer_key_settings.headers.includes("") ||
    //         temp.answer_key_settings.headers.includes(undefined))))
    // ) {
    //   setShowError(true);
    //   setErrorMessage(t("Error! Please check table headers."));
    //   updatedQuestion.invalid = true;
    //   invalid = true;
    // }
    else {
      updatedQuestion.invalid = false;
      invalid = false;
    }

    if(idealToggle && rawTextIdeal.length === 0){
      setShowError(true);
      setErrorMessage(t("Error! Ideal awnser cannot be empty"));
      updatedQuestion.invalid = true;
      invalid = true;
    }

    if (invalid && props.showEditableIfError) {
      setMakeEditable(true);
    }
    
    return invalid;
  };

  const onSave = async (
    e: any = null,
    isGenerateKeywords: boolean = false,
    isLinkSections: boolean = false
  ) => {
    setShowError(false);
    e && e.stopPropagation();

    
    let temp: any = {};
    temp.id = props.questionId;

    temp.text = questionText;
    temp.topic_id = questionTopicId;
    temp.topic = questionTopic;
    temp.type = questionType;
    temp.difficulty_level = difficultyLevel.value || 1;
    if ("quiz_question_id" in props) {
      temp.quiz_question_id = props.quiz_question_id;
    }
    temp.score =
      questionType === "essay" && rubricPositionCheck === "rubric_description"
        ? +rubricDescQuestionScore
        : +questionScore;
    if (questionType === "radio" || questionType === "checkbox") {
      temp.options = questionOptions;
    }
    if (questionType === "text" || questionType === "bullet") {
      temp.ideal_answer = convertToMarkdown(idealAnswer);
    }
    if (questionType === "newbullet") {
      temp.ideal_answer = convertToMarkdown(idealAnswer);
      let transformedIdealAnswerItems: any = [];
      idealAnswerItems.forEach((item: any) => {
        transformedIdealAnswerItems.push({
          ...item,
          keyword_scores: convertStringToObject(item.keyword_scores),
          score: "score" in item ? Number(item.score) : 1,
          scored_by: scoredBy.value,
        });
      });
      temp.ideal_answer_items = transformedIdealAnswerItems;
    }
    if (
      ["text", "table", "bullet", "description", "newbullet"].indexOf(
        questionType
      ) > -1
    ) {
      temp.scored_by = scoredBy.value;
    }
    if (questionType === "table") {
      let settings = { ...answerKeySettings };
      settings.row_count = newTableRowCount;
      settings.column_count = newTableColumnCount;

      temp.answer_key_settings = settings;
      setAnswerKeySettings(temp.answer_key_settings);
      let transformedIdealAnswerItems: any = [];
      idealAnswerItems.forEach((item: any) => {
        transformedIdealAnswerItems.push({
          ...item,
          keyword_scores: convertStringToObject(item.keyword_scores),
          score: "score" in item ? Number(item.score) : 1,
          scored_by: scoredBy.value,
        });
      });

      // Work around for column headers
      if ("column_headers" in settings) {
        // settings.row_count = transformedIdealAnswerItems.length;
        settings.column_headers = settings.column_headers.slice(
          0,
          transformedIdealAnswerItems.length
        );
      }
      // Work around for column headers
      temp.ideal_answer_items = transformedIdealAnswerItems;
    }

    if (
      questionType === "essay" &&
      rubricPositionCheck === "rubric_breakdown"
    ) {
      let settings = {
        ...answerKeySettings,
        row_count: newTableRowCount,
        column_count: newTableColumnCount,
      };
      // settings.row_count = newTableRowCount;
      // settings.column_count = newTableColumnCount

      temp.answer_key_settings = { ...settings };
      setAnswerKeySettings(temp.answer_key_settings);
      let transformedIdealAnswerItems: any = [];
      idealAnswerItems.forEach((item: any) => {
        transformedIdealAnswerItems.push({
          ...item,
          keyword_scores: convertStringToObject(item.keyword_scores),
          score: "score" in item ? Number(item.score) : 1,
          scored_by: scoredBy.value,
        });
      });

      // Work around for column headers
      if ("column_headers" in settings) {
        // settings.row_count = transformedIdealAnswerItems.length;
        settings.column_headers = settings.column_headers.slice(
          0,
          transformedIdealAnswerItems.length
        );
      }
      // Work around for column headers
      temp.ideal_answer_items = transformedIdealAnswerItems;
    }

    if (
      questionType === "essay" &&
      rubricPositionCheck === "rubric_description"
    ) {
      let settings = { ...rubricDescAnswerKeySettings };
      settings.row_count = rubricDescRowCount;
      settings.column_count = newTableColumnCount;

      temp.answer_key_settings = settings;
      setRubricDescAnswerKeySettings(temp.answer_key_settings);
      let transformedIdealAnswerItems: any = [];
      rubricDescIdealAnswerItems.forEach((item: any) => {
        transformedIdealAnswerItems.push({
          ...item,
          keyword_scores: convertStringToObject(item.keyword_scores),
          score: "score" in item ? Number(item.score) : 1,
          scored_by: scoredBy.value,
        });
      });

      // Work around for column headers
      if ("column_headers" in settings) {
        // settings.row_count = transformedIdealAnswerItems.length;
        settings.column_headers = settings.column_headers.slice(
          0,
          transformedIdealAnswerItems.length
        );
      }
      // Work around for column headers
      temp.ideal_answer_items = transformedIdealAnswerItems;
    }

    if (questionType === "description") {
      let settings = { ...answerKeySettings };
      settings.column_headers = [];
      settings.headers = ["", ""];
      settings.enable_column_header = false;
      temp.answer_key_settings = settings;
      temp.ideal_answer = JSON.stringify(tableAnswer);
    }
    if (questionType === "matching") {
      let settings = { ...answerKeySettings };
      settings.column_headers = [];
      settings.enable_column_header = false;
      temp.answer_key_settings = settings;
      temp.matches = matchAnswer;
    }
    if (
      scoredBy.value === "keywords" &&
      questionType !== "group" &&
      isTableValid()
    ) {
      temp.keyword_scores = convertTableToKeywords();
    } else {
      temp.keywords = null;
    }

    if (
      questionType === "bullet" ||
      questionType === "newbullet" ||
      questionType === "description" ||
      questionType === "newbullet"
    ) {
      temp.base_score = parseInt(baseScore);
    }
    if (questionType === "description") {
      temp.base_score_1 =
        headerScore && addHeaderScore ? parseInt(headerScore) : null;
    }
    if (
      questionType === "bullet" ||
      questionType === "newbullet" ||
      questionType === "table" ||
      questionType === "description" ||
      questionType === "essay"
    ) {
      temp.position_check =
        questionType === "essay" ? rubricPositionCheck : positionCheck;
    }
    // temp.valid = checkisQuestionValid();
    if (questionType === "group") {
      let data = [],
        queIds = [];
      data.push(temp);
      queIds.push(props.questionId);

      // console.log('---------------- GROUP');
      // console.log('subQuestionsForSave', subQuestionsForSave);
      // console.log('props.data.subquestion', props.data.subquestions);
      // console.log('---------------- GROUP');

      props.data?.subquestions?.forEach((question: any) => {
        data.push({
          ...subQuestionsForSave[question.id],
          is_saved: question.is_saved,
        });
        queIds.push(question.id);
      });
      if (!checkisQuestionValid(temp)) {
        props.onSetErrorIfAny(
          {
            ...{
              ...temp,
              data: data,
            },
            invalid: checkisQuestionValid(temp),
          },
          props.questionId,
          false,
          false,
          props.questionNumber
        );

        console.log("props.questionNumber", props.questionNumber);

        // props.onGroupQuestionSave(
        //   data,
        //   queIds,
        //   isGenerateKeywords,
        //   isLinkSections
        // );
      }
    } else {
      if (bpQuestionType === "group") {
        temp.id = uuidv4();
      }

      if(docRefsCb){
        temp.document_references = docRefs?.map((docRef: any) => ({
          document_id: docRef?.document_id,
          sections: docRef.sections
        }))
      }

      if (props.isQuestionBank && !checkisQuestionValid(temp)) {
        if (isGenerateKeywords || isLinkSections) {
          props.onQuestionSave(
            temp,
            props.questionId,
            isGenerateKeywords,
            isLinkSections,
            props.questionNumber
          );
          return;
        }
      }

      if(questionType === "essay" && idealText?.length > 0) {
        temp.ideal_answer = idealToggle? idealText: ""
      }

      props.onSetErrorIfAny(
        {
          ...temp,
          invalid: checkisQuestionValid(temp),
        },
        props.questionId,
        false,
        false,
        props.questionNumber
      );
      console.log("props.questionNumber", props.questionNumber);
      return;
      // if (!checkisQuestionValid(temp)) {
      //   // onQuestionSave
      //   props.onQuestionSave(temp, props.questionId, false, false, props.questionNumber);
      // }
    }

    if (
      (props.isQuestionBank || props.isQuestionBankMultiEdit) &&
      !checkisQuestionValid(temp)
    ) {
      // console.log("CALLED HERE 4");
      // onQuestionSave
      if (isGenerateKeywords || isLinkSections) {
        props.onQuestionSave(
          temp,
          props.questionId,
          isGenerateKeywords,
          isLinkSections,
          props.questionNumber
        );
      } else {
        props.onSetErrorIfAny(
          {
            ...temp,
            invalid: checkisQuestionValid(temp),
          },
          props.questionId,
          false,
          false,
          props.questionNumber
        );
      }
    }

    // console.log("CALLED HERE 5");
    // !props.activateEditAsProps && setMakeEditable(false);
  };

  const convertTableToKeywords = () => {
    let data: any = {};
    keywordsData.forEach((row: any) => {
      data[row[0]] = parseFloat(row[1]);
    });
    return data;
  };

  const convertKeywordsToTable = (data: any) => {
    let temp: any = [];
    if (data) {
      Object.keys(data).forEach((keyword) => {
        temp.push([keyword, data[keyword]]);
      });
      return temp;
    }
  };

  const isTableValid = () => {
    let isValid = false;
    keywordsData.forEach((row: any) => {
      if (row.filter((cell: any) => cell !== "").length > 0) {
        isValid = true;
      }
    });
    return isValid;
  };

  const convertToMarkdown = (data: any) => {
    const content = data.getCurrentContent();
    let markup = draftjsToMd(convertToRaw(content), null);
    let splitMarkup = markup.replace("&nbsp;", " ");

    let transformedMarkup = splitMarkup.trim();
    return transformedMarkup;
  };

  const onCancel = (e: any) => {
    setShowError(false);
    setMakeEditable(false);
    props.onCancelEdit && props.onCancelEdit();
    return;
    e.stopPropagation();
    if (props.addNew) {
      props.directQuestionDelete(props.questionId);
    } else {
      let temp: any = [...bpQuestionOptions];
      bpQuestionOptions.forEach((option, index) => {
        temp[index] = {
          ...option,
          id: option.id,
          text: option.text,
          is_correct: option.is_correct,
        };
      });
      setQuestionText(bpQuestionText);
      setQuestionOptions(temp);
      setQuestionScore(bpQuestionScore);
      setQuestionType(bpQuestionType);
      setLogicSettings(bpLogicSettings);
      if (bpQuestionType === "table") {
        let columnHeaders: any = [];
        let settings = { ...answerKeySettings };
        if (answerKeySettings.enable_column_header) {
          tableAnswer.forEach((row: any) => {
            columnHeaders.push(row[0] !== null ? row[0] : "");
            row.shift();
          });
        }
        settings.column_headers = columnHeaders;
        setAnswerKeySettings(settings);
      }
      setTableAnswer(bpTableAnswer.current);
    }
    setMakeEditable(false);
  };

  useEffect(() => {
    if (props.activateEditAsProps) {
      subActiveEdit();
      if (props.showKeyword) {
        setMakeEditable(false);
        setShowKeywordsOptions(true);
      }
    }
  }, []);

  const activateEdit = (event: any) => {
    event.stopPropagation();
    subActiveEdit();
  };

  const subActiveEdit = () => {
    let temp: any = [];
    questionOptions.forEach((option, index) => {
      temp[index] = {
        ...option,
        id: option.id,
        text: option.text,
        is_correct: option.is_correct,
      };
    });
    setBpQuestionText(questionText);
    setBpQuestionOptions(temp);
    setBpQuestionScore(questionScore);
    setBpQuestionType(questionType);
    setMakeEditable(true);
    if (props.activateEditAsProps) {
      // onSave();
    }
  };

  const onLogicQuestionChange = (optionId: any, value: any) => {
    let temp = { ...logicSettings };
    temp[optionId] = value;
    let action = value.value === "end-quiz" ? value.value : "jump-to-question";
    let options = [...questionOptions];
    options.forEach((option, index) => {
      if (option.id === optionId) {
        if (action === "end-quiz") {
          options[index] = {
            ...option,
            branch: {
              action: action,
            },
          };
        } else {
          options[index] = {
            ...option,
            branch: {
              action: action,
              reference_id: value.value,
            },
          };
        }
      }
    });

    setQuestionOptions(options);
    setLogicSettings(temp);
  };

  const onLogicQuestionClear = (optionId: any) => {
    let temp = { ...logicSettings };
    delete temp[optionId];

    let options = [...questionOptions];
    options.forEach((option, index) => {
      if (option.id === optionId) {
        delete options[index].branch;
      }
    });

    setQuestionOptions(options);
    setLogicSettings(temp);
  };

  const getToolbarActions = (hideKey: any = null) => {
    // if (showKeywordsOptions) {
    //   return <></>;
    // } else {
    return (
      <>
        {!props.questionBankId && props.enableDrag && (
          <span className="drag-handle">::</span>
        )}
        <div className="toolbar-actions tolbar-left-vertical">
          {(!props.isQuestionBank || props?.isQuestionBankMultiEdit) &&
            props.showCheckbox && (
              <div className="py-1">
                <input
                  type="checkbox"
                  className="m-0"
                  onChange={(e) => {
                    props.addToSelectedRows(e, props.data.id);
                  }}
                  checked={props.selectedNodes.indexOf(props.data.id) >= 0}
                />
              </div>
            )}
          {!makeEditable && (
            <button
              className="toolbar-btn"
              title="Edit"
              onClick={(e) => {
                setShowLogicOptions(false);
                setShowKeywordsOptions(false);
                activateEdit(e);
              }}
            >
              <i className="icon-edit" />
            </button>
          )}

          {props.isQuestionBank &&
            props.isQuestionBankEditable &&
            !showKeywordsOptions && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  if (makeEditable) {
                    onCancel(e);
                    setShowKeywordsOptions(!showKeywordsOptions);
                  } else {
                    setShowKeywordsOptions(!showKeywordsOptions);
                  }
                }}
                className="toolbar-btn"
                title="Link Keywords"
              >
                <img src={linkkeywords} width="17" alt="link keywords" />
              </button>
            )}

          {questionType === "radio" &&
            !props.isQuestionBank &&
            !props.isQuestionBankMultiEdit &&
            !props.hideAddLogic && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  activateEdit(e);
                  setShowLogicOptions(true);
                }}
                className="toolbar-btn"
                title="Add logic/branch"
              >
                <i className="icon-branch" />
              </button>
            )}

          <button
            onClick={(e) => {
              e.stopPropagation();
              props.onQuestionDelete(props.questionId);
            }}
            className="toolbar-btn delete-toolbar-btn"
            title="Delete"
          >
            <i className="icon-delete" />
          </button>
        </div>
        {/* <div className="toolbar-actions"> */}

        {/* <button
          onClick={(e) => {
            setShowLogicOptions(false);
            setShowKeywordsOptions(false);
            activateEdit(e);
          }}
          className="toolbar-btn"
          title="Edit"
        >
          <i className="icon-edit" />
        </button> */}

        {/* {questionType === "radio" && !props.isQuestionBank && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              activateEdit(e);
              setShowLogicOptions(true);
            }}
            className="toolbar-btn"
            title="Add logic/branch"
          >
            <i className="icon-branch" />
          </button>
        )} */}
        {/* <button className="toolbar-btn" title="Copy">
          <i className="icon-copy" />
        </button>
        <button className="toolbar-btn" title="Move up">
          <i className="icon-move-up" />
        </button>
        <button className="toolbar-btn" title="Move down">
          <i className="icon-move-down" />
        </button> */}
        {/* <button
          onClick={(e) => {
            e.stopPropagation();
            props.onQuestionDelete(props.questionId);
          }}
          className="toolbar-btn delete-toolbar-btn"
          title="Delete"
        >
          <i className="icon-delete" />
        </button> */}
        {/* </div> */}
      </>
    );
    // }
  };

  const getScoreByOptions = (type: string) => {
    // if (type === "text") {
    //   if (props.isEssayGrader || props.data.scored_by === "essay_grader") {
    //     return textOptionsMarkBy;
    //   } else {
    //     return textOptionsMarkBy.filter((e) => e.value !== "essay_grader");
    //   }
    // }

    if (type === "newbullet" || type === "table") {
      return newFieldOptionsMarkBy;
    }

    return fieldOptionsMarkBy;
  };

  const showInline = (toolbar: any, context: any) => {
    console.log(toolbar, context);
  };

  const getQuestionHTML = () => {
    const oldOptions = [...fieldOptions];
    if (props.isNew) {
      oldOptions.splice(3, 1);
    }

    const options = hideOldBullet
      ? oldOptions.filter((o: any) => o.value !== "bullet")
      : oldOptions;

    const opt = hideEssayOption
      ? options.filter((o: any) => o.value !== "essay")
      : options;

    return (
      <div className="editable-question">
        <div className="flex-container">
          <div className="flex-one">
            <div className="flex-container">
              <div className="flex-one-no-padding">
                <Dropdown
                  menuPosition="absolute"
                  label={`Question ${props.questionNumber + 1}`}
                  data={
                    props.enableLearning
                      ? opt.concat([
                          {
                            value: "matching",
                            label: t("Matching"),
                          },
                        ])
                      : options
                  }
                  value={{
                    value: questionType,
                    label: fieldOptionsMap[questionType],
                  }}
                  defaultValue={{
                    value: "radio",
                    label: t("Multiple Choice (select one)"),
                  }}
                  onChange={(value: any) => onTypeChange(value.value)}
                />
                <div className="half-clearfix" />
              </div>
              <div className="quiz-guide-btn-container">
                <HashLink
                  smooth
                  to={`/quiz-guide#${questionType}`}
                  className="quiz-guide-btn"
                >
                  <i className="icon-help-circle" />
                </HashLink>
              </div>
            </div>
            {questionType === "essay" && 
              <div className="d-flex page-warning">
                <img src={yellowBulb} alt="yellowBulb" className="pr-3" />
                <span>
                  {t(
                    "Please keep in mind that learners' responses to essay questions are restricted to a maximum of 8000 characters which is approximately 2000 words."
                  )}
                </span>
              </div>
            }
            <SunEditorUI
              showInline={showInline}
              id={props.questionNumber}
              name={`question-editor`}
              editorType="sun"
              value={questionText}
              onChange={onQuestionChange}
              maxCharCount={props.essayConfig?.max_prompt_characters || 4000}
              placeholder={t("Type question here")}
            />
          </div>
          <div className="max-width-250">
            {(questionType === "radio" || questionType === "checkbox") && (
              <>
                <TextInput
                  value={questionScore.toString()}
                  inputType="text"
                  // min={0}
                  label={t("Max Question Score")}
                  // inputChange={onScoreChange}
                  inputChange={(event: any) => {
                    if (event.target.value.match(/^[0-9]*(\.[0-9]{0,2})?$/)) {
                      onScoreChange(event);
                    }
                  }}
                />
                <div className="clearfix" />
              </>
            )}

            {props.enableLearning === true &&
              ["text", "bullet", "table", "description", "newbullet"].indexOf(
                questionType
              ) > -1 && (
                <div className="flex-container">
                  <div className="flex-one-no-padding">
                    <Dropdown
                      label={t("Score by")}
                      data={getScoreByOptions(questionType)}
                      //   questionType === "newbullet" || questionType === "table"
                      //     ? newFieldOptionsMarkBy
                      //     : fieldOptionsMarkBy
                      // }
                      value={scoredBy}
                      onChange={(value: any) => onScoredByChange(value)}
                    />
                    <div className="clearfix" />
                  </div>
                  <div className="quiz-guide-btn-container">
                    <HashLink
                      smooth
                      to={`/quiz-guide#${scoredBy.value}`}
                      className="quiz-guide-btn"
                    >
                      <i className="icon-help-circle" />
                    </HashLink>
                  </div>
                </div>
              )}

            {props.enableLearning === false &&
              (questionType === "text" ||
                questionType === "bullet" ||
                questionType === "newbullet" ||
                questionType === "table" ||
                questionType === "description") && (
                <>
                  <div className="half-clearfix" />

                  <InfoBlock>
                    {t("This question will not be scored.")}
                  </InfoBlock>
                </>
              )}

            {props.enableLearning === true &&
              (questionType === "text" ||
                questionType === "bullet" ||
                questionType === "newbullet" ||
                questionType === "table" ||
                questionType === "description" ||
                questionType === "matching" ||
                questionType === "essay") && (
                <>
                  <TextInput
                    value={
                      questionType === "essay" &&
                      rubricPositionCheck === "rubric_description"
                        ? rubricDescQuestionScore.toString()
                        : questionScore.toString()
                    }
                    inputType="text"
                    // min={0}
                    label={t("Max Question Score")}
                    inputChange={(event: any) => {
                      if (event.target.value.match(/^[0-9]*(\.[0-9]{0,2})?$/)) {
                        onScoreChange(event);
                      }
                    }}
                    readOnly={questionType === "essay"}
                    disabled={questionType === "essay"}
                  />
                  <div className="clearfix" />
                </>
              )}

            {props.enableLearning === true &&
              (questionType === "bullet" || questionType === "newbullet") &&
              scoredBy.value !== "keywords" &&
              scoredBy.value !== "enhanced_keywords" && (
                <>
                  <TextInput
                    value={baseScore.toString()}
                    inputType="text"
                    // min={0}
                    label={t("Score for each point")}
                    // inputChange={onScoreChange}
                    inputChange={(event: any) => {
                      if (event.target.value.match(/^[0-9]*(\.[0-9]{0,2})?$/)) {
                        onBaseScoreChange(event);
                      }
                    }}
                  />

                  {/* <TextInput
                    value={baseScore.toString()}
                    inputType="number"
                    min={0}
                    label={t("Score for each point")}
                    inputChange={onBaseScoreChange}
                  /> */}
                  <div className="clearfix" />
                </>
              )}

            {/* {props.isQuestionBank && ( */}
            <div className="flex-container">
              <div className="flex-one-no-padding">
                <Dropdown
                  dropdownContainerClassName="difficulty"
                  label={t("Difficulty Level")}
                  data={difficultyLevels}
                  value={difficultyLevel}
                  onChange={(value: any) => onDifficultyLevelChange(value)}
                />
                <div className="clearfix" />
              </div>
            </div>
            <div className="flex-container">
              {questionType === "essay" && 
              <div className="flex-one-no-padding custom-checkbox">
                <form>
                  <input
                    id={"cb-ref-" + props?.questionId }
                    type="checkbox"
                    onChange={(e) => {
                      setDocRefsCb(!docRefsCb)
                    }}
                    checked={docRefsCb}
                  />
                  <label htmlFor={"cb-ref-" + props?.questionId} className="pl-1">{t('Add References')}</label>
                </form>  
              </div>}
              
            </div>

            {props.enableLearning === true &&
              questionType === "description" &&
              scoredBy.value !== "keywords" && (
                <>
                  <TextInput
                    value={baseScore.toString()}
                    inputType="number"
                    min={0}
                    label={t("Score for each PAIR (header + description)")}
                    inputChange={onBaseScoreChange}
                  />
                  <div className="clearfix" />
                </>
              )}
            {props.enableLearning === true &&
              questionType === "description" &&
              scoredBy.value !== "keywords" && (
                <React.Fragment>
                  <Checkbox
                    checked={addHeaderScore}
                    label={t("Check to allot score for each headers")}
                    onCheckboxChange={(event: any) => {
                      setAddHeaderScore(event.target.checked);
                      setHeaderScore("");
                    }}
                  />
                  <div className="half-clearfix" />
                </React.Fragment>
              )}
            {props.enableLearning === true &&
              addHeaderScore &&
              questionType === "description" &&
              scoredBy.value !== "keywords" && (
                <>
                  <TextInput
                    value={headerScore.toString()}
                    inputType="number"
                    min={0}
                    label={t("Score per header")}
                    inputChange={onHeaderScoreChange}
                  />
                  <div className="clearfix" />
                </>
              )}
          </div>
        </div>
        {(questionType === "bullet" ||
          questionType === "table" ||
          questionType === "description" ||
          questionType === "newbullet") &&
          scoredBy.value !== "enhanced_keywords" && (
            <div className="flex-one px-0">
              <div className="clearfix" />
              <h3 className="page-subheading">{t("Marking setting")}</h3>
              <ButtonToggle
                value={positionCheck}
                buttons={[
                  {
                    value: null,
                    label: t("Order doesn't matter"),
                  },
                  {
                    value: "order",
                    label: t("Answer should be in order"),
                  },
                  {
                    value: "placement",
                    label: t(
                      "All items in the answer should be in exact position"
                    ),
                  },
                ]}
                onChange={(value: any) => setPositionCheck(value)}
              />
              <div className="clearfix" />
            </div>
          )}
        {questionType === "essay" && (
          // scoredBy.value !== "enhanced_keywords" &&
          <div className="d-flex align-items-center justify-content-between px-0 mt-3">
            <h3 className="page-subheading mb-0 font-14">{t("Scoring Rubric ")}</h3>
            <button
              className="btn btn-link font-weight-bold font-14"
              onClick={() => setRubricPositionCheck((prev: any) => prev === "rubric_breakdown" ? "rubric_description" : "rubric_breakdown")}
            >
              {rubricPositionCheck === "rubric_breakdown"
                ? t("Switch to Scoring Rubric with Description")
                : t("Switch to Scoring Rubric with Breakdown")}
            </button>
          </div>
        )}
        <div
          className={`"flex-one px-0 mt-3 " ${
            questionType === "essay" ? "rubric-scoring-table" : ""
          } `}
        >
          {(questionType === "text" || questionType === "bullet") && (
            <>
              <h4 className="page-subheading">{t("Answer key")}</h4>

              <InfoBlock>
                {t(
                  "Paragraph and non-paragraph based answer have a different scoring mechanism. If the ideal answer has multiple parts please use bulleted or numbered list."
                )}
              </InfoBlock>

              <RichTextAreaQuiz
                key={props.questionNumber}
                value={idealAnswer}
                id={props.questionNumber}
                onChange={onAnswerChange}
                isQuizAnswer
              />
            </>
          )}
          {questionType === "table" && (
            <>
              <h4 className="page-subheading">{t("Answer key")}</h4>
              <QuizTableEditor
                onHeaderChange={handleTableHeaderChange}
                onColumnHeaderChange={handleVerticalTableHeaderChange}
                onAnswerKeyTableChange={onAnswerKeyTableChange}
                tableValues={idealAnswerItems}
                horizontalHeaderValues={
                  "headers" in answerKeySettings
                    ? answerKeySettings.headers
                    : ["", "", ""]
                }
                verticalHeaderValues={
                  "column_headers" in answerKeySettings
                    ? answerKeySettings.column_headers
                    : [""]
                }
                enableVerticalHeader={
                  "enable_column_header" in answerKeySettings
                    ? answerKeySettings.enable_column_header
                    : false
                }
                rowCount={
                  "row_count" in answerKeySettings
                    ? answerKeySettings.row_count
                    : null
                }
                columnCount={
                  "column_count" in answerKeySettings
                    ? answerKeySettings.column_count
                    : null
                }
                scoredBy={scoredBy}
              />
            </>
          )}

          {questionType === "essay" &&
            rubricPositionCheck === "rubric_breakdown" && (
              <>
                <RubricQuizTableEditor
                  maxCharCount={props.essayConfig?.max_criteria_characters || 4000}
                  onHeaderChange={handleTableHeaderChange}
                  onColumnHeaderChange={handleVerticalTableHeaderChange}
                  onAnswerKeyTableChange={onAnswerKeyTableChange}
                  tableValues={idealAnswerItems}
                  horizontalHeaderValues={
                    "headers" in answerKeySettings
                      ? answerKeySettings.headers
                      : ["", "", ""]
                  }
                  verticalHeaderValues={
                    "column_headers" in answerKeySettings
                      ? answerKeySettings.column_headers
                      : [""]
                  }
                  enableVerticalHeader={
                    "enable_column_header" in answerKeySettings
                      ? answerKeySettings.enable_column_header
                      : false
                  }
                  rowCount={
                    "row_count" in answerKeySettings
                      ? answerKeySettings.row_count
                      : null
                  }
                  columnCount={
                    "column_count" in answerKeySettings
                      ? answerKeySettings.column_count
                      : null
                  }
                  scoredBy={scoredBy}
                  essayGrader
                />
              </>
            )}

          {questionType === "essay" &&
            rubricPositionCheck === "rubric_description" && (
              <RubricDescription
                maxCharCount={props?.essayConfig?.max_criteria_characters || 4000}
                onHeaderChange={handleRubricTableHeaderChange}
                onColumnHeaderChange={handleRubricDescVerticalTableHeaderChange}
                onAnswerKeyTableChange={onRubricDescAnswerKeyTableChange}
                tableValues={rubricDescIdealAnswerItems}
                horizontalHeaderValues={
                  "headers" in rubricDescAnswerKeySettings
                    ? rubricDescAnswerKeySettings.headers
                    : [
                        "Score Criteria",
                        "Score for Criteria",
                        "Description of Scoring Criteria",
                      ]
                }
                verticalHeaderValues={
                  "column_headers" in rubricDescAnswerKeySettings
                    ? rubricDescAnswerKeySettings.column_headers
                    : [""]
                }
                enableVerticalHeader={
                  "enable_column_header" in rubricDescAnswerKeySettings
                    ? rubricDescAnswerKeySettings.enable_column_header
                    : false
                }
                rowCount={
                  "row_count" in rubricDescAnswerKeySettings
                    ? rubricDescAnswerKeySettings.row_count
                    : null
                }
                columnCount={
                  "column_count" in rubricDescAnswerKeySettings
                    ? rubricDescAnswerKeySettings.column_count
                    : null
                }
                scoredBy={scoredBy}
                essayGrader
              />
            )}

          {questionType === "description" && (
            <>
              <h4 className="page-subheading">{t("Answer key")}</h4>
              <HeaderDescriptionEditor
                onHeaderChange={handleTableHeaderChange}
                onTableChange={onTableChange}
                tableValues={tableAnswer}
                // headerValues={
                //   "headers" in answerKeySettings
                //     ? answerKeySettings.headers
                //     : [""]
                // }
              />
            </>
          )}
          {questionType === "matching" && props.enableLearning && (
            <>
              <h4 className="page-subheading">{t("Answer Key")}</h4>
              <MatchEditor
                data={matchAnswer}
                onChange={onMatchEditorChange}
                headerValues={
                  "headers" in answerKeySettings
                    ? answerKeySettings.headers
                    : ["", ""]
                }
                onHeaderChange={handleTableHeaderChange}
              />
            </>
          )}
          {questionType === "newbullet" && (
            <>
              <h4 className="page-subheading">{t("Answer Key")}</h4>
              <QuizBulletEditor
                spellCheck={false}
                identifier={props.questionId}
                data={idealAnswerItems}
                handleCardDelete={handleQuestionCardDelete}
                onInputEdit={onQuestionEdit}
                handlePaste={handlePaste}
                handleKeyPress={handleBulletInputEnter}
                shouldAutoFocus={false}
                handleKeyUp={handleBulletInputEnter}
                turnOnKeywords={turnOnEnhancedKeywords}
                keywordsInputChange={keywordsInputChange}
                paraData={idealAnswer}
                onParaChange={onAnswerChange}
                pointScoreChange={pointScoreChange}
              />
            </>
          )}
          {questionType === "essay" && (
            <div>
              <div className="mt-2 d-flex justify-content-between align-items-center">
                <div>
                  <b>{t("Ideal Answer")}</b>
                </div>
                <div>
                  <Toggle
                    toggled={idealToggle}
                    onToggle={async () => {
                      setIdealToggle(!idealToggle);
                    }}
                    label={t("Show Ideal Answer")}
                  />
                </div>
              </div>
              <div className="w-100">
                {idealToggle && 
                  <SunEditorUI
                    maxCharCount={props.essayConfig?.max_essay_characters || 8000}
                    showInline={true}
                    id={props.questionNumber + "-eassay"}
                    name={`question-editor-essay`}
                    editorType="sun"
                    value={idealText}
                    onChange={(value: any) => setIdealText(value)}
                  />
                }
              </div>
            </div>
          )}
          {
            docRefsCb && (
            <div className="ref-container py-3">
              <div className="page-subheading d-flex justify-content-between">
                <span className="font-14">{t("References")}</span>
                <span className="font-14 clr-primary cursor-pointer" onClick={() => setShowDocumentSearchPopup(true)}>{t("Manage Reference(s)")}</span>
              </div>
              {docRefs?.length === 0 && (
                <div className="emty-docs-ref">
                  <span className="font-14">{t("No references added yet")}</span>
                  <span 
                    className="font-14 font-600 clr-primary cursor-pointer" 
                    onClick={() =>  setShowDocumentSearchPopup(true)}
                  >
                    {t("Add Reference(s)")}
                  </span>
                </div>
              )}
              {docRefs?.length > 0 && docRefs.map((doc: any)=> 
                (
                  <div className="p-3 doc-refs-table">
                    <div className="d-flex pb-1">
                      <span className="title ref-table-col-1">
                        {doc?.document?.knowledge_groups?.[0]?.name}
                        {doc?.document?.knowledge_groups?.[1]?.name ? " / " + doc?.document?.knowledge_groups?.[1]?.name: ""}
                      </span>
                      <span className="title ref-table-col-2">{t("Pages")}</span>
                    </div>
                    <div className="d-flex">
                      <span className="ref-table-col-1">{doc?.document?.name}</span>
                      <span className="ref-table-col-2">{doc?.sections[0].from_page}-{doc?.sections[0].to_page}</span>
                    </div>
                  </div>
                )
              )}
              
            </div>
           
            )
          }
        </div>
      </div>
    );
  };

  const [isBtnDisable, setIsBtnDisable] = useState(false);
  const [generatingDistractors, setGeneratingDistractors] = useState(false);
  const [executeGenerateDistractors, setExecuteGenerateDistractors] =
    useState(false);

  useEffect(() => {
    setIsBtnDisable(
      !questionOptions.some((e, i) => {
        return e.is_correct && e.text.trim().length > 0;
      })
    );
  }, [questionOptions]);

  const getDistractors = async () => {
    try {
      let temp = [...questionOptions];
      // const emptyInputs = temp.filter((opt) => opt.text.trim().length <= 0);
      temp = temp.filter((e) => e.text.trim().length !== 0);
      const rawText = questionText.replace(/(<([^>]+)>)/gi, "");
      if (!rawText) {
        helper.showToaster(
          t("Please input question before generating distractors")
        );
        return;
      }

      setGeneratingDistractors(true);
      const data = {
        text: rawText.trim().length !== 0 && rawText,
        correct_options: temp
          .filter((opt) => opt.is_correct)
          .map((e) => e.text),
      };

      const response: any = await dispatch(getMcqDistractors(data));
      if (response && response.distractors.length > 0) {
        response.distractors.forEach((e: any) =>
          temp.push({
            id: uuidv4(),
            text: e,
            is_correct: false,
          })
        );
      }

      if (temp && temp.length !== 0) {
        temp = _.uniqBy(
          _.sortBy(temp, (e) => !e.is_correct),
          "text"
        );
        temp = _.shuffle(temp);
        // temp = [...temp, ...emptyInputs];
      }

      await setQuestionOptions([...temp]);
      setGeneratingDistractors(false);
    } catch (error) {
      setGeneratingDistractors(false);
    }
  };

  if (executeGenerateDistractors) {
    if (questionOptions && questionOptions.length >= 1) {
      handleRadioCorrect(0);
      if (questionType === "radio") {
        getDistractors();
      }
    }
    setExecuteGenerateDistractors(false);
  }

  const getOptionsHTML = () => {
    return (
      <>
        {(questionType === "radio" || questionType === "checkbox") && (
          <div className="options-editor">
            <h4 className="card-subheading">{t("Options")}</h4>
            <div className="flex-container">
              <div className="page-label">{t("Is correct")}</div>
              <div className="page-label flex-one">
                {t("Enter options below")}
              </div>
              {questionType === "radio" && (
                <button
                  className={classNames({
                    "btn page-label btn-link": true,
                  })}
                  disabled={isBtnDisable}
                  onClick={getDistractors}
                >
                  {t("Generate Distractors")}
                </button>
              )}
            </div>
            {(questionType === "radio" || questionType === "checkbox") &&
              questionOptions.map((option, index) => (
                <div key={option.id + "_OH_" + index}>
                  <div className="flex-container">
                    <div className="options-editor-checkbox">
                      {questionType === "checkbox" && (
                        <Checkbox
                          checked={option.is_correct}
                          onCheckboxChange={(event: any) =>
                            handleCheckboxCorrect(event.target.checked, index)
                          }
                        />
                      )}
                      {questionType === "radio" && (
                        <CheckRadio
                          name={questionText}
                          checked={option.is_correct}
                          onRadioChange={(event: any) =>
                            handleRadioCorrect(event.target.value)
                          }
                          value={index}
                        />
                      )}
                    </div>
                    <div className="flex-one no-padding">
                      {/* <SunEditorUI
                        mode="balloon-always"
                        name={"option-editor"}
                        editorType="sun"
                        config={{
                          toolbarInline: true,
                          charCounterCount: false,
                          toolbarVisibleWithoutSelection: true
                        }}
                        // value={initOp[index] ? initOp[index].text : ""}
                        value={option.text}
                        onChange={value => onOptionChange(value, index)}
                      /> */}
                      <TextInput
                        // autoFocus
                        inputChange={(event: any) =>
                          onOptionChange(event.target.value, index)
                        }
                        value={option.text}
                      />
                    </div>
                    <button
                      onClick={() => onOptionDelete(option.id)}
                      className="options-editor-delete"
                    >
                      <i className="icon-delete" />
                    </button>
                  </div>
                  <div className="clearfix" />
                </div>
              ))}
            {generatingDistractors && (
              <div className="doc-checking-msg d-flex align-items-center justify-content-center clr-black">
                <span className="doc-loader spinner-width-20 mr-2"></span>
                {t("Generating Distractors... Please wait")}
              </div>
            )}
            <IconLabelButton
              showIcon
              iconClass="icon-add"
              buttonLabel={t("ADD AN OPTION")}
              buttonClass="add-option-btn"
              onClick={addOption}
            />
          </div>
        )}
      </>
    );
  };

  const getLogicHTML = () => {
    return (
      <div className="logic-options-wrapper">
        <div
          className="display-question"
          dangerouslySetInnerHTML={{
            __html: questionText,
          }}
        ></div>
        <InfoBlock type="warning">
          {t(
            "The logic you've set on a choice will cause the page to redirect to another question. Make sure you do not create any cycle in the quiz as it will cause the respondant to go in an infinite loop."
          )}
        </InfoBlock>
        {questionOptions.length === 0 &&
          "There are no choices added in the question. Please add choices to add logic."}
        {questionOptions.length > 0 && (
          <>
            <div className="logic-options-container">
              <div className="logic-option-text">
                {t("If the answer is")}...
              </div>
              <div className="logic-option-text">{t("then skip to")}...</div>
            </div>
            <div className="clearfix" />
            {questionOptions.map((option: any, index: number) => {
              return (
                <>
                  <div
                    key={option.id + "_LH_" + index}
                    className="logic-options-container"
                  >
                    <div className="logic-option-text">{option.text}</div>
                    <div className="logic-question-select-wrapper">
                      <Dropdown
                        data={[
                          {
                            value: "end-quiz",
                            label: t("End quiz"),
                          },
                        ].concat(props.quizQuestionDropdownData)}
                        value={
                          logicSettings[option.id]
                            ? logicSettings[option.id]
                            : {}
                        }
                        onChange={(value: any) =>
                          onLogicQuestionChange(option.id, value)
                        }
                      />
                      <IconLabelButton
                        buttonLabel={t("Clear")}
                        onClick={() => onLogicQuestionClear(option.id)}
                      />
                    </div>
                  </div>
                  <div className="clearfix" />
                </>
              );
            })}
          </>
        )}
      </div>
    );
  };

  const getSubquestionsHTML = () => {
    return (
      <div>
        {props.data.subquestions &&
          props.data.subquestions.map((subquestion: any, index: any) => (
            <Question
              isNew={subquestion.isNew ? subquestion.isNew : false}
              key={subquestion.id}
              parentId={props.questionId}
              questionId={subquestion.id}
              questionData={subquestion}
              questionNumber={`${props.questionNumber + 1}.${index + 1}`}
              enableLearning={props.enableLearning}
              sendSubquestionsData={sendSubquestionsData}
              onDelete={deleteQuestionData}
              markRed={subQuestionErrors[subquestion.id] && true}
              errorMessage={
                subQuestionErrors[subquestion.id]
                  ? subQuestionErrors[subquestion.id]
                  : ""
              }
              onSave={onSave}
              isEssayGrader={props.isEssayGrader}
            />
          ))}
      </div>
    );
  };

  const addSubQuestion = () => {
    setShowError(false);
    props.addNewQuestion("sub", props.questionId);
  };

  const showEditableHTML = () => {
    return (
      <>
        {getToolbarActions("hideEdit")}
        <Card
          className={
            showError
              ? "quiz-question-editor-card quiz-question-editor-card-error quiz-question-live-card"
              : "quiz-question-editor-card quiz-question-live-card"
          }
        >
          {!showLogicOptions && (
            <div>
              {getQuestionHTML()}
              {<div className="clearfix" />}
              {getOptionsHTML()}
              {scoredBy.value === "keywords" && questionType !== "group" && (
                <div className="flex-one">
                  <h3 className="card-subheading">
                    {t(
                      "Keywords (user answers will be scored against these keywords only)"
                    )}
                  </h3>
                  <TableEditor
                    disableHeader
                    disableColumnAdd
                    onTableChange={onKeywordsTableChange}
                    tableValues={keywordsData}
                    headerValues={["Keyword", "Score"]}
                    enableColumnHeader={false}
                  />
                </div>
              )}
              {questionType !== "group" && <div className="clearfix" />}
            </div>
          )}
          {questionType === "group" && getSubquestionsHTML()}
          {questionType === "group" && (
            <div className="sub-question-add-btn">
              <IconLabelButton
                buttonLabel={t("Add sub question")}
                onClick={addSubQuestion}
              />
            </div>
          )}
          {showLogicOptions && getLogicHTML()}
          {showError && <div className="card-error">{errorMessage}</div>}

          {!props.activateEditAsProps &&
            !props.isQuestionBankEditable &&
            !props.hideActions && (
              <div className="editable-question-primary-actions">
                {!props.isQuestionBank && (
                  <button
                    className="btn btn-primary font-sm font-600 py-1 mx-2"
                    onClick={(e) => {
                      props.updateQuizQuestionsFromParent(e);
                      props.checkAllQuestionsValidity();
                    }}
                  >
                    {t("Save")}
                  </button>
                )}
                <button
                  className="btn btn-outline-primary font-sm font-600 py-1 mx-2"
                  onClick={(e) => onCancel(e)}
                >
                  {t("Cancel")}
                </button>
              </div>
            )}

          {props.isQuestionBankEditable && !props.hideActions && (
            <div className="editable-question-primary-actions">
              <button
                className="btn btn-primary font-sm font-600 py-1 mx-2"
                onClick={(e) => {
                  e.stopPropagation();
                  props.isQuestionBankMultiEdit
                    ? onSave()
                    : props.updateQuizQuestionsFromParent(e);
                }}
              >
                {t("Save changes only")}
              </button>
              <div className="d-inline-block position-relative">
                {showConfirmSaveGenerateKeys && (
                  <>
                    <div className="confirm-save-generate-keywords">
                      <div>
                        <b>{t("Save and Generate keywords")}</b>
                      </div>
                      <div className="py-2">
                        {t(
                          "Generating new keywords would replace existing keywords and remove existing feedback & tutoring mapping. Procceed?"
                        )}
                      </div>
                      <div className="d-flex my-2" style={{ float: "right" }}>
                        <button
                          className="btn btn-outline-primary font-sm font-600 py-1 mx-2"
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowConfirmSaveGenerateKeys(false);
                          }}
                        >
                          {t("Cancel")}
                        </button>
                        <button
                          className="btn btn-primary font-sm font-600 py-1 mx-2"
                          onClick={(e) => {
                            e.stopPropagation();
                            onSave(e, true);
                            setShowConfirmSaveGenerateKeys(false);
                          }}
                        >
                          {t("Proceed")}
                        </button>
                      </div>
                    </div>
                    <div className="bottom-triangle"></div>
                  </>
                )}
                <button
                  className="btn btn-outline-primary font-sm font-600 py-1 mx-2"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowConfirmSaveGenerateKeys(true);
                  }}
                >
                  {t("Save and generate keywords")}
                </button>
              </div>
              {/* <button
                  className="btn btn-outline-primary font-sm font-600 py-1 mx-2"
                  onClick={(e) => {
                    e.stopPropagation()
                    setShowConfirmSaveGenerateKeys(true)
                  }}
                >
                  {t("Save and generate keywords")}
                </button> */}
              <button
                className="btn btn-outline-primary font-sm font-600 py-1 mx-2"
                onClick={(e) => onCancel(e)}
              >
                {t("Cancel")}
              </button>
            </div>
          )}
        </Card>
      </>
    );
  };

  const getTableDisplay = (
    data: any,
    answerKeySettings: any,
    noHeader: any
  ) => {
    let temp = typeof data === "string" ? JSON.parse(data) : data;
    if (answerKeySettings && answerKeySettings.enable_column_header) {
      temp &&
        temp.map((row: any, index: any) =>
          row.unshift(answerKeySettings.column_headers[index])
        );
    }

    return (
      <table className="quiz-table-answer-table">
        {!noHeader && (
          <thead>
            <tr>
              {answerKeySettings &&
                answerKeySettings.headers &&
                answerKeySettings.headers.map((column: any) => (
                  <th className="quiz-table-answer-cell">{column}</th>
                ))}
            </tr>
          </thead>
        )}
        <tbody>
          {temp &&
            temp.map((row: any) => (
              <tr>
                {row.map((column: any) => (
                  <td className="quiz-table-answer-cell">{column}</td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    );
  };

  const getMatchDisplay = (data: any) => {
    return (
      <table className="quiz-table-answer-table">
        <thead>
          <tr>
            {answerKeySettings &&
              answerKeySettings.headers &&
              answerKeySettings.headers.map((column: any) => (
                <th className="quiz-table-answer-cell">{column}</th>
              ))}
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((row: any) => (
              <tr>
                <td className="quiz-table-answer-cell">{row.term}</td>
                <td className="quiz-table-answer-cell">{row.definition}</td>
              </tr>
            ))}
        </tbody>
      </table>
    );
  };

  const getIdealAnswerItemsDisplay = () => {
    return (
      <ul>
        {idealAnswerItems.map((item: any) => (
          <li>{item.text}</li>
        ))}
      </ul>
    );
  };

  const divideArray = (data: any, controlColumnCount: any) => {
    // console.log('---------------------------');
    // console.log('data', data);
    // console.log('controlColumnCount', controlColumnCount);

    let temp = [...data];
    // console.log('temp.length / controlColumnCount', temp.length / controlColumnCount);
    // console.log('new Array(Math.ceil(temp.length))', new Array(Math.ceil(temp.length)));
    if (temp.length > 0) {
      const result = new Array(Math.ceil(temp.length / controlColumnCount))
        .fill(null)
        .map((_) => temp.splice(0, controlColumnCount));
      return result;
    }

    return [];
  };

  const getNewTableDisplay = (data: any = [], answerKeySettings: any = {}) => {
    return (
      <table className="quiz-table-answer-table">
        <thead>
          <tr>
            {answerKeySettings &&
              answerKeySettings.headers &&
              answerKeySettings.headers.map((column: any) => (
                <th className="quiz-table-answer-cell">{column}</th>
              ))}
          </tr>
        </thead>

        <tbody>
          {divideArray(data, answerKeySettings?.column_count).map(
            (row, index) => (
              <tr>
                <th className="quiz-table-header-cell">
                  {"column_headers" in answerKeySettings
                    ? answerKeySettings?.column_headers?.[index]
                    : ""}
                </th>
                {row.map((column) => (
                  <td className="quiz-table-answer-cell">{column.text}</td>
                ))}
              </tr>
            )
          )}
        </tbody>
      </table>
    );
  };

  const getQuestionDisplay = () => {
    return (
      <React.Fragment>
        {!props.isQuestionBank && props.data.quiz_question_id && (
          <OverlayTrigger
            trigger={["hover", "focus"]}
            placement="auto"
            overlay={(d) => (
              <Popover id="popover-basic-" {...d}>
                <Popover.Content
                  dangerouslySetInnerHTML={{
                    __html: t("Question saved in knowledge base"),
                  }}
                ></Popover.Content>
              </Popover>
            )}
          >
            <div className="save-ques-toltip">
              <img src={saveQuesicon} alt="" />
            </div>
          </OverlayTrigger>
        )}
        {props?.data?.breadcrumbs && (
          <div
            className="group-tag"
            onClick={(e: any) => {
              props?.groupTagLabelClick(props?.data);
              e.stopPropagation();
            }}
          >
            {props?.data?.breadcrumbs.length > 1
              ? props?.data?.breadcrumbs?.map((b: any) => b.name).join("/")
              : props?.data?.breadcrumbs[0]?.name}
          </div>
        )}
        <div
          className="display-question"
          dangerouslySetInnerHTML={{
            __html: `Question ${props.questionNumber + 1}<br/> ${questionText}`,
          }}
        ></div>
        {(questionType === "text" || questionType === "bullet") && (
          <div className="display-textinput">
            <div className="text-answer-field">
              {props.data.ideal_answer === ""
                ? "Text answer field"
                : convertToMarkdown(idealAnswer)}
            </div>
          </div>
        )}

        {questionType === "newbullet" && (
          <div className="display-description">
            {props.data.ideal_answer_items.length === 0
              ? "Bullet answer field"
              : getIdealAnswerItemsDisplay()}
          </div>
        )}

        {(questionType === "radio" || questionType === "checkbox") && (
          <div className="display-options">
            {questionOptions.map((option, index) => (
              <div
                key={option.id + "_DO_" + index}
                className="option-display-field"
              >
                {questionType === "radio" && <span className="radio-circle" />}
                {questionType === "checkbox" && (
                  <span className="checkbox-square" />
                )}
                <span dangerouslySetInnerHTML={{ __html: option.text }}></span>
              </div>
            ))}
          </div>
        )}

        {questionType === "description" && (
          <div className="display-description">
            {getTableDisplay(
              props.data.ideal_answer,
              props.data.answer_key_settings,
              true
            )}
          </div>
        )}

        {questionType === "table" && (
          <div className="display-description">
            {getNewTableDisplay(
              props.data.ideal_answer_items,
              props.data.answer_key_settings
            )}
          </div>
        )}

        {questionType === "essay" && (
          <div className="display-description">
            {getNewTableDisplay(
              props.data.ideal_answer_items,
              props.data.answer_key_settings
            )}
          </div>
        )}

        {questionType === "matching" && (
          <div className="display-description">
            {getMatchDisplay(props.data.matches)}
          </div>
        )}
      </React.Fragment>
    );
  };

  const showAddTagHandler = (e: any, document?: any, sectionId?: number) => {
    // if (document) {
    //   setSelectedDocumentId(document);
    //   setSelectedDocumentSectionId(sectionId);
    //   setAllTags([...document.sections[sectionId].tags]);
    //   setShowTagsModal(!showTagsModal);
    // } else {
    // }
    setShowTagsModal(!showTagsModal);
  };

  const showDisplayHTML = () => {
    return (
      <>
        {getToolbarActions()}
        <Card
          className={cx({
            "quiz-question-editor-card clickable-card": true,
            keywords: showKeywordsOptions,
          })}
          onClick={(e: any) => !showKeywordsOptions && activateEdit(e)}
        >
          <div className="space-around">
            {getQuestionDisplay()}

            {(props.isQuestionBank || props.isMultiEdit) &&
              props.isQuestionBankEditable &&
              props.data.type !== "group" && (
                <>
                  <div className="mt-3">
                    <div className="d-flex flex-wrap justify-content-between align-items-start mb-2">
                      <div>
                        <h2 className="font-12 mb-1">
                          {showKeywordsOptions
                            ? t("Edit keywords")
                            : t("keywords")}
                        </h2>
                        {showKeywordsOptions && (
                          <p className="mb-0">
                            {t("Manage keywords for the knowledge.")}
                          </p>
                        )}
                      </div>
                      {showKeywordsOptions && props.isQuestionBankEditable && (
                        <div className="d-inline-block position-relative">
                          {showConfirmGenerateKeys && (
                            <>
                              <div className="confirm-generate-keywords">
                                <div>
                                  <b>{t("Generate keywords")}</b>
                                </div>
                                <div>
                                  {t(
                                    "Generating new keywords would replace existing keywords and remove existing feedback & tutoring mapping. Procceed?"
                                  )}
                                </div>
                                <div
                                  className="d-flex my-2"
                                  style={{ float: "right" }}
                                >
                                  <button
                                    className="btn btn-outline-primary font-sm font-600 py-1 mx-2"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setShowConfirmGenerateKeys(false);
                                    }}
                                  >
                                    {t("Cancel")}
                                  </button>
                                  <button
                                    className="btn btn-primary font-sm font-600 py-1 mx-2"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      onSave(e, true);
                                      setShowConfirmGenerateKeys(false);
                                    }}
                                  >
                                    {t("Proceed")}
                                  </button>
                                </div>
                              </div>
                              <div className="right-triangle"></div>
                            </>
                          )}
                          <button
                            className="btn font-12 py-0 clr-primary px-1 font-weight-bold"
                            onClick={(e: any) => {
                              e.stopPropagation();
                              setShowConfirmGenerateKeys(true);
                            }}
                          >
                            {t("Generate Keywords")}
                          </button>
                        </div>
                      )}
                    </div>

                    <div className="d-flex align-items-start">
                      <TagsComponent
                        row={{ tags: [...allTags] }}
                        showAllTagsAtOnce
                        hideDeleteOption={!showKeywordsOptions}
                        removeTagHandler={(e: any, value: any) => {
                          removeTag(e, value, allTags);
                        }}
                      />
                      {showKeywordsOptions && props.isQuestionBankEditable && (
                        <button
                          className="btn btn-add-tag font-12 my-1"
                          onClick={(e: any) => {
                            e.stopPropagation();
                            showAddTagHandler(e, [...allTags], 0);
                          }}
                        >
                          {t("+ Add Tag")}
                        </button>
                      )}
                    </div>
                  </div>

                  {showKeywordsOptions && props.isQuestionBankEditable && (
                    <div className="mt-1">
                      <div className="d-flex flex-wrap justify-content-between align-items-start mb-2">
                        <div>
                          <h2 className="font-12 mb-1">
                            {t("Curriculum Tagging")}
                          </h2>
                          <p className="mb-0">
                            {t("Tag Quiz Question to document sections")}
                          </p>
                        </div>

                        <button
                          className="btn font-12 py-0 clr-primary px-1 font-weight-bold"
                          onClick={(e: any) => {
                            e.stopPropagation();
                            onSave(e, false, true);
                          }}
                        >
                          {t("Generate matches")}
                        </button>
                      </div>
                      <div className="font-12 tagg-info-msg py-1 px-3 my-2 d-flex align-items-center">
                        <img src={tagInfoicon} alt="" className="mr-3" />
                        Please ensure to assign the correct keywords for better
                        suggestions
                      </div>
                      <div
                        onClick={(e: any) => {
                          e.stopPropagation();
                        }}
                      >
                        <QuizzesDocumentGroup
                          allDocuments={props.allDocuments}
                          documentId={props.questionBankId}
                          question={props.questionBank}
                        />

                        {/* <button className="btn font-12 py-0 clr-primary font-weight-bold">
                        {t("Add")}
                      </button> */}
                      </div>
                    </div>
                  )}
                </>
              )}

            {questionType === "group" &&
              props.data.subquestions &&
              props.data.subquestions.map((subquestion: any, index: any) => (
                <Question
                  forDisplay
                  questionId={subquestion.id}
                  questionData={subquestion}
                  questionNumber={`${props.questionNumber + 1}.${index + 1}`}
                  enableLearning={props.enableLearning}
                  showKeywordsOptions={showKeywordsOptions}
                  allDocuments={props.allDocuments}
                  documentId={props.questionBankId}
                  isEssayGrader={props.isEssayGrader}
                />
              ))}
          </div>
          {showKeywordsOptions &&
            props.isQuestionBankEditable &&
            !props.hideActions && (
              <div className="editable-question-primary-actions">
                <button
                  className="btn btn-primary font-sm font-600 py-1 mx-2"
                  // onClick={(e) => props.updateQuizQuestionsFromParent(e)}
                  onClick={(e) => {
                    e.stopPropagation();
                    onSave();
                    // activateEdit(e);
                    setShowKeywordsOptions(!showKeywordsOptions);
                  }}
                >
                  {t("Save")}
                </button>
                <button
                  className="btn btn-outline-primary font-sm font-600 py-1 mx-2"
                  onClick={(e) => {
                    e.stopPropagation();
                    // activateEdit(e);
                    setShowKeywordsOptions(!showKeywordsOptions);
                  }}
                >
                  {t("Cancel")}
                </button>
              </div>
            )}
        </Card>
      </>
    );
  };

  const confirmedTagsHandler = async (e: any, tags: any[]) => {
    e.preventDefault();
    setShowTagsModal(false);
    const tagsValues: string[] = tags.map((tag) => tag.value);
    setAllTags(tagsValues);
    try {
      await dispatch(updateKeywordsOfQuestion(props.questionId, tagsValues));
    } catch (e: any) {}
  };

  const removeTag = async (e: any, tag: string, tags: any[]) => {
    e.preventDefault();
    const filterTags = tags.filter((t) => t !== tag);
    setAllTags(filterTags);
    try {
      await dispatch(updateKeywordsOfQuestion(props.questionId, filterTags));
    } catch (e: any) {}
  };

  return (
    <div
      key={props.questionNumber}
      className={classNames({ "quiz-question-editor-card-wrapper": true })}
    >
      {props.isQuestionBank && (
        <AddTags
          onHide={showAddTagHandler}
          show={showTagsModal}
          confirmhandler={confirmedTagsHandler}
          selectedTags={
            allTags.map((t: any) => {
              return {
                value: t,
                label: t,
              };
            }) || []
          }
        />
      )}
      {makeEditable && showEditableHTML()}
      {!makeEditable && showDisplayHTML()}
      {showDocumentSearchPopup && 
        <DocumentSearchPopup 
          maxPages={props?.essayConfig?.max_document_reference_pages || 10}
          show={showDocumentSearchPopup}
          docRefs={docRefs}
          setDocRefs={setDocRefs}
          close={() => {
            setShowDocumentSearchPopup(false)
          }}
        />
      }
    </div>
  );
};

export default QuizQuestionEditor;
