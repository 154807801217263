import i18n from "../../../components/UI/Internationalisation/i18n";
const { t } = i18n;

export const steps = [
    {
      selector: '.first-step',
      content: () => (
        <div>
          {t("Here are the questions we generated from the document document_name.")}
          {t('Click on “Add” to add a question to your bot knowledge.')}{' '}
        </div>
      ),
    },
    {
      selector: '.second-step',
      content: () => (
        <div>
          {t("If you wanna go anywhere, skipping places, it is absolutely possible.")}
          <br /> {t("`Oh, I forgot something inside the bus…`")}{' '}
        </div>
      ),
    },
    {
      selector: '.third-step',
      content: () => (
        <div>
          {t("If you wanna go anywhere, skipping places, it is absolutely possible.")}
          <br /> {t("`Oh, I forgot something inside the bus…`")}{' '}
        </div>
      ),
    },
    // ...
  ]
  