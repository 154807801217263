/* eslint-disable jsx-a11y/anchor-is-valid */
import download from "downloadjs";
import * as htmlToImage from "html-to-image";
import { subDays } from "date-fns";
import * as _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import { DashboardFilters } from "../../../../components/UI/Dashboard";
import {
  exportReportUsingDateAndType,
  getQuizSubmissionsList,
} from "../../../../store/dashboard/actions";
import "../../index.scss";
import DashboardNavigation from "../DashboardNavigation";
import { columns, customStyles } from "./index";
import { HelperService } from "../../../../services/helper.service";
import chevLeft from "../../../../assets/New-images/chevron-left-blk.svg";

import Pagination from "../../../../components/UI/Pagination/Pagination";
import Select from "react-select";
import DateRangePickerComponent from "../../../../components/UI/DateRangePicker/DateRangePicker";
import downarrowimg from "../../../../assets/images/chevron-down.svg";
import { useHistory } from "react-router-dom";

const QuizAnalyticsExportSubmission = (props: any) => {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const [chartDownloading, setChartDownloading] = useState(false);
  const [filtersData, setFiltersData] = useState({
    // type: 'daily',
    filter_from_date: null,
    filter_to_date: null,
    filter_connection_ids: null,
    page: 1,
    page_size: 10,
  });
  const [loading, setLoading] = useState(false);
  const [topUsersData, setTopUsersData] = useState([]);

  const helperService = new HelperService();
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [paginationState, setPaginationState] = useState(null);
  const [paginatedTopUsers, setPaginatedTopUsers] = useState([]);
  const [data, setData] = useState([]);
  const [datePickerClicked, setDatePickerClicked] = useState(false);
  const [customDatePickerValue, setCustomDatePickerValue] = useState(false);
  const [dateParameters, setDateParameters] = useState({
    value: "30",
    label: t("Last 30 days"),
  });

  useEffect(() => {
    if (
      dateParameters &&
      dateParameters.value &&
      dateParameters.value === "custom"
    ) {
      return setCustomDatePickerValue(true);
    }

    setTimeout(async () => {
      const filter: any = {
        ...filtersData,
        filter_from_date: dateParameters.value
          ? moment(subDays(new Date(), +dateParameters.value)).format(
              "YYYY-MM-DD"
            )
          : null,
        filter_to_date: dateParameters.value
          ? moment(new Date()).format("YYYY-MM-DD")
          : null,
      };

      setCustomDatePickerValue(false);
      await setFiltersData(filter);
    }, 100);
  }, [dateParameters]);

  const dateChangehandler = async (e: any) => {
    const filter = {
      ...filtersData,
      ...e,
    };
    await setFiltersData(filter);
  };

  const closeDateRangeHandler = () => {
    setCustomDatePickerValue(false);
  };

  useEffect(() => {
    if (
      loading ||
      !(filtersData.filter_from_date || filtersData.filter_to_date)
    ) {
      return;
    }
    const fun = async () => {
      await setLoading(true);
      try {
        const data: any = await dispatch(getQuizSubmissionsList(filtersData));
        await setData(data.rows);
        await setPaginationState(data.pagination);
        await setLoading(false);
      } catch (e: any) {
        await setPaginationState([]);
        await setLoading(false);
      }
    };
    fun();
  }, [filtersData]);

  useEffect(() => {
    const filter = {
      ...filtersData,
      page: currentPage,
      page_size: perPage,
    };
    setFiltersData(filter);
  }, [perPage, currentPage]);

  const handlePageChange = async (page: number) => {
    await setCurrentPage(page);
  };

  const onExportTableClick = async () => {
    try {
      const cloned = {
        ...filtersData,
      };
      delete cloned.page;
      delete cloned.page_size;
      const blob: any = await dispatch(
        exportReportUsingDateAndType("quiz-submissions-list", "csv", cloned)
      );

      if (!blob) {
        return;
      }

      let extension =
        blob &&
        blob.type ==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ? "xlsx"
          : "csv";
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${"quiz-submissions-list"}.` + extension);
      // 3. Append to html page
      document.body.appendChild(link);
      // 4. Force download
      link.click();
      // 5. Clean up and remove the link
      link.parentNode.removeChild(link);
    } catch (error) {}
  };

  const ChartTableSortIcon = (data: any) => {
    return (
      <img
        src={downarrowimg}
        alt={t("collapsed")}
        className="chart-table-sort-icon"
      />
    );
  };

  return (
    <section className="dashborad-wraper quiz-analytics-submission">
      <div className="page-mid-wraper">
        {/*--------- top-navigation -----------*/}
        <DashboardNavigation />

        {/*-------- welcome text ------------*/}

        <div className="mt-3 mb-4">
          <div className="mb-24 h1 d-flex align-items-center">
            <button
              className="btn py-0 pl-0"
              onClick={() => {
                history.goBack();
              }}
            >
              <img src={chevLeft} alt="" />
            </button>

            <h2 className="w-100 mb-0">{t("View All Submission")}</h2>
          </div>

          <div className="d-flex justify-content-between mb-3 flex-wrap  position-relative">
            {/* search,filter,exportable */}
            <div className="search-filter d-flex align-items-center blue-filters">
              <Select
                className="select-sort select-target select-blue shadow-none"
                classNamePrefix="select-target-prefix"
                components={{
                  // DropdownIndicator: () => (
                  //         <img src={downarrowblue} alt="" />
                  //       ),
                  IndicatorSeparator: () => null,
                }}
                value={dateParameters ? { ...dateParameters } : null}
                onChange={(e: any) => {
                  setDatePickerClicked(e.value === "custom");
                  setDateParameters(e);
                }}
                options={[
                  { value: "60", label: t("Last 60 days") },
                  { value: "30", label: t("Last 30 days") },
                  { value: "7", label: t("Last 7 days") },
                  { value: "custom", label: t("Custom") },
                ]}
              />
              {/* {search && (
                    <input
                      type="text"
                      className="form-control search-text"
                      placeholder={search?.placeHolder}
                      onChange={search?.handleInput}
                      value={search?.value}
                    />
                  )} */}
            </div>
            <div>
              <button
                className="btn-outline-primary action btn btn-suggest w-auto h-100"
                onClick={onExportTableClick}
              >
                {t("Export Table (CSV)")}
              </button>
            </div>
            {!loading && (
              <React.Fragment>
                {customDatePickerValue && datePickerClicked && (
                  <DateRangePickerComponent
                    dateValue={{
                      startDate:
                        filtersData && filtersData.filter_from_date
                          ? new Date(filtersData.filter_from_date)
                          : new Date(),
                      endDate:
                        filtersData && filtersData.filter_to_date
                          ? new Date(filtersData.filter_to_date)
                          : new Date(),
                    }}
                    onDateChange={dateChangehandler}
                    onCloseDateRangeHandler={closeDateRangeHandler}
                  />
                )}
              </React.Fragment>
            )}
          </div>
        </div>
        <React.Fragment>
          <DataTable
            className="table-wraper-trending-questions"
            noContextMenu
            noHeader
            columns={columns()}
            data={data}
            customStyles={customStyles}
            sortIcon={<ChartTableSortIcon />}
          />

          {data && data.length > 0 && paginationState && (
            <Pagination
              currentPage={currentPage}
              pagination={paginationState}
              paginationPerPage={perPage}
              pageChange={(newPerPage: number, page: number) => {
                setPerPage(newPerPage);
                setCurrentPage(1);
              }}
              onChangePage={handlePageChange}
              hideSuggestedQuestion
            />
          )}
        </React.Fragment>
      </div>
    </section>
  );
};
export default QuizAnalyticsExportSubmission;
