import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Image } from "react-bootstrap";
import noodleLogo from "./../../assets/images/noodle-text.svg";
import sendEmailImg from "./../../assets/images/walter-head.svg";
import bigCheck from "./../../assets/New-images/check-success-green.svg";
import "./styles.scss";

const windowObj: any = window

export const Success = (props: any) => {
  const { t } = useTranslation("translation");

  useEffect(() => {
    windowObj.gtag(
      'event',
      'conversion',
      {
        'send_to': 'AW-16691232596/SsPgCJKE188ZENT-_5Y-',
        'value': 1.0,
        'currency': 'SGD',
        'transaction_id': ''
      });
  }, []);

  return (
    <div className="d-flex verify-email-container">
      <div
        className="green-bg d-flex flex-1 flex-column align-items-center justify-content-center max-col"
        style={{ paddingBottom: "50px" }}
      >
        <Image className="top-logo" src={noodleLogo} />

        <Image src={bigCheck} />
        <h1 className="text-center ai-head-1 font-weight-bold clr-pink mt-4">{t("Registration Complete")}</h1>
        <div className="font-16 font-600">
          {t("You're all set to log in and explore the portal")}
        </div>
        <div className="btn-wrapper d-flex justify-content-center">
          <button
            className="btn btn-primary font-600"
            onClick={() =>
              (window.location.href = windowObj.NEW_PORTAL_URI)
            }
          >
            {t("Login Now")}
          </button>
        </div>
      </div>
      <div className="d-none d-md-flex flex-column align-items-center justify-content-center max-col">
        <h1 className="text-center ai-head-1 font-800">
          {t("Congratulations! You're now ready to")} <br />
          <span className='clr-blue'> {t("embark on your journey")}</span>
          {t(" to shape the")}
          <span className='clr-pink d-block'> {t("Future of Education!")}</span>
        </h1>
        <figure className="text-center">
          <Image src={sendEmailImg} alt="" className="mw-70" />
        </figure>
      </div>
    </div>
  );
};

export default Success;
