import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { DashboardFilters } from "../../../components/UI/Dashboard";
import { QUIZZSUBMISSIONBYSUBMISSIONID } from "../../../config";
import { getClasses } from "../../../store/dashboard/actions";
import {
  getLearningSubmissionsReviews,
  updateReviews,
} from "../../../store/quizzes/actions";
import QuizSumbmissionDetails from "../QuizSubmissionList/QuizSubmissionDetails";

const ReviewSubmissionsList = (props: any) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [reviewList, setReviewList] = useState([]);
  const [resolvedReviewList, setResolvedReviewList] = useState([]);
  const [showSubmissionDetailScreen, setShowSubmissionDetailsScreen] =
    useState(false);
  const [submissionId, setSubmissionId] = useState("");
  const [selectedReview, setSelectedReview] = useState("");
  const [pending, setPending] = useState(false);
  const [resolvedPending, setResolvedPending] = useState(false);
  const [showReviewedSubmissions, setShowReviewedSubmissions] = useState(false);
  const [connectionIdClassMap, setConnectionIdClassMap] = useState<any>({});
  const dispatch = useDispatch();
  const dateSort = (rowA: any, rowB: any) => {
    let temp =
      rowA.submittedAt > rowB.submittedAt
        ? 1
        : rowB.submittedAt > rowA.submittedAt
        ? -1
        : 0;
    return temp;
  };
  const [filtersData, setFiltersData] = useState({
    connection_id: null,
    channel: null,
  });
  const reviewListColumns = [
    {
      name: t("Learner Email"),
      selector: "learnerEmail",
      sortable: true,
      wrap: true,
    },

    {
      name: t("Learner Name"),
      selector: "learnerName",
      wrap: true,
    },
    {
      name: t("Learner ID"),
      selector: "learnerId",
      sortable: true,
      minWidth: "120px",
    },
    {
      name: t("User Quiz Score"),
      selector: "userQuizScore",
      sortable: true,
      wrap: true,
    },
    {
      name: t("No. of Questions to be Reviewed"),
      selector: "questionCount",
      sortable: true,
      wrap: true,
    },
    {
      name: t("Submitted At"),
      selector: "submittedAt",
      sortable: true,
      wrap: true,
      sortFunction: dateSort,
      format: (row: any, index: any) => {
        return (
          <Moment format="DD/MM/YYYY HH:mm" utc local>
            {row.submittedAt}
          </Moment>
        );
      },
    },
    {
      name: t("Class"),
      selector: "submissionClass",
      sortable: true,
      wrap: true,
    },
    {
      name: t("Action"),
      selector: "action",
      sortable: true,
      wrap: true,
    },
  ];

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderRadius: "10px",
        backgroundColor: "#0923E6",
        borderBottomWidth: "0",
      },
    },
    expanderRow: {
      style: {
        // backgroundColor: '#0923E6',
      },
    },
    expanderCell: {
      style: {
        "&:hover": {
          backgroundColor: "transparent",
        },
        button: {
          backgroundColor: "transparent !important",
          img: {
            width: "10px",
          },
        },
      },
    },
    expanderButton: {
      style: {
        backgroundColor: "transparent",
        alignItems: "center",
      },
    },
    headCells: {
      style: {
        "&:first-child": {
          borderTopLeftRadius: "5px",
          borderBottomLeftRadius: "0px",
        },
        "&:last-child": {
          borderTopRightRadius: "5px",
        },
        "&.chart--table-sort-icon": {
          marginLeft: "10px",
        },
        'input[type="checkbox"]': {
          width: "16px",
          height: "16px",
        },
        backgroundColor: "#0923E6",
        //width: "250px",
        padding: "10px 10px",
        fontSize: "14px",
        color: "#fff",
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: "#FFFFFF",
        },
        div: {
          color: "#fff",
        },
      },
    },
    cells: {
      style: {
        color: "#07032B",
        fontSize: "14px",
        padding: "10px 10px",
        'input[type="checkbox"]': {
          width: "16px",
          height: "16px",
        },
      },
    },
  };

  useEffect(() => {
    const func = async () => {
      const { classes }: any = await dispatch(getClasses());
      getClassesForUser(classes);
    };
    func();
  }, []);

  useEffect(() => {
    if ( pending || showReviewedSubmissions ) { return }
    getReviewList();
  }, [ filtersData, showReviewedSubmissions ]);
  
  useEffect(() => {
    if ( resolvedPending || !showReviewedSubmissions || !showReviewedSubmissions) { return }
    getResolvedReviewList();
  }, [ filtersData, showReviewedSubmissions]);

  const getReviewList = async () => {
    let quizId = props.quizId ? props.quizId : "";
    if (quizId) {
      try {
        setPending(true);
        const response: any = await dispatch(
          getLearningSubmissionsReviews(quizId, null, filtersData)
        );
        setReviewList(response.submission_reviews);
        setPending(false);
      } catch (error) {
        setPending(false);
      }
    }
  };

  const getClassesForUser = (classes: any) => {
    // setClassDropdownData(transformClassDropdownData(classes));
    let temp: any = {};
    classes.forEach((record: any) => {
      record.connections.forEach((connection: any) => {
        temp[connection.connection_id] = record.name;
      });
    });
    setConnectionIdClassMap(temp);
  };

  const getResolvedReviewList = async () => {
    let quizId = props.quizId ? props.quizId : "";
    if (quizId) {
      try {
        setResolvedPending(true);
        const response: any = await dispatch(
          getLearningSubmissionsReviews(quizId, "resolved", filtersData)
        );
        setResolvedReviewList(response.submission_reviews);
        setResolvedPending(false);
      } catch (error) {
        setResolvedPending(false);
      }
    }
  };

  const transformTableData = (data: any, t: any) => {
    let details: any = [];
    data.forEach((prop: any, key: any) => {
      details.push({
        id: key,
        learnerName: prop.user
          ? `${prop.user.first_name} ${prop.user.last_name}`
          : prop.first_name
          ? `${prop.first_name} ${prop.last_name}`
          : "-",
        learnerId: props.user ? prop.user.metadata.student_id : "-",
        learnerEmail:
          prop.user && prop.user.email
            ? prop.user.email
            : prop.email
            ? prop.email
            : "User",
        userQuizScore: prop.quiz_submission.score
          ? `${
              props.decimalPlaces === "0"
                ? Math.floor(prop.quiz_submission.score)
                : parseFloat(prop.quiz_submission.score).toFixed(
                    props.decimalPlaces
                  )
            }/${
              props.decimalPlaces === "0"
                ? Math.floor(prop.quiz.score)
                : parseFloat(prop.quiz.score).toFixed(props.decimalPlaces)
            }`
          : 0.0,
        questionCount: prop.to_review_answers.length,
        submittedAt: prop.created_at ? prop.created_at : "-",
        submissionClass:
          prop.quiz_submission.connection_id in connectionIdClassMap
            ? connectionIdClassMap[prop.quiz_submission.connection_id]
            : "-",
        action: (
          <button
            onClick={() => {
              setSubmissionId(prop.quiz_submission_id);
              setSelectedReview(prop);
              // setShowSubmissionDetailsScreen(true);
              history.push(
                QUIZZSUBMISSIONBYSUBMISSIONID.replace(":quizId", props.quizId)
                  .replace(":submissionId", prop.quiz_submission_id)
                  .replace(
                    ":email",
                    prop.user && prop.user.email
                      ? prop.user.email
                      : prop.email
                      ? prop.email
                      : "User"
                  )
                  + '?reviewId=' + (prop._id || '') + (showReviewedSubmissions ? '&reviewed=reviewed' : "")
              );
            }}
            className="btn clr-primary font-sm font-600 p-0"
          >
            {t("Review Scores")}
          </button>
        ),
      });
    });
    return details;
  };

  const markReviewed = async (reviewId: any) => {
    try {
      const response: any = dispatch(updateReviews(reviewId));
      getReviewList();
      setShowSubmissionDetailsScreen(false);
    } catch (error) {}
  };

  const hasFilterUpdated = async (e: any) => {
    await setFiltersData({
      ...filtersData,
      connection_id: e.connection_ids,
      channel: e.channel,
    });
  };


  return (
    <div>
      <div className="d-flex justify-content-between align-items-start mb-4 ">
        {showReviewedSubmissions ? (
          <div>
            <h2 className="mb-1">{t("Resolved Reviews")}</h2>
            <div className="clr-grey font-600">
              {t("List of reviewed submissions")}
            </div>
          </div>
        ) : (
          <div>
            <h2 className="mb-1"> {t("Pending Reviews")}</h2>
            <div className="clr-grey font-600">
              {t("Review the scores requested by the learners")}
            </div>
          </div>
        )}
        {showReviewedSubmissions ? (
          <div>
            <button
              onClick={() => {
                setShowReviewedSubmissions(false);
              }}
              className="btn btn btn-outline-primary"
            >
              {t("View Pending Reviews")}
            </button>
          </div>
        ) : (
          <div>
            <button
              onClick={() => {
                getResolvedReviewList();
                setShowReviewedSubmissions(true);
              }}
              className="btn btn btn-outline-primary"
            >
              {t("View Resolved Reviews")}
            </button>
          </div>
        )}
      </div>

      <div className="blue-filters">
        <DashboardFilters
          hasFilterUpdated={hasFilterUpdated}
          hideDateOptions
        />
      </div>

      {!showReviewedSubmissions && (
        <div>
          {!showSubmissionDetailScreen && (
            <DataTable
              progressPending={pending}
              columns={reviewListColumns}
              data={transformTableData(reviewList, t)}
              //   hideFilter
              subHeader={false}
              noHeader
              customStyles={customStyles}
            />
          )}

          {showSubmissionDetailScreen && (
            <>
              <QuizSumbmissionDetails
                markReviewed={markReviewed}
                isForReview
                quizId={props.quizId}
                selectedRecord={selectedReview}
                submissionId={submissionId}
                onBack={() => setShowSubmissionDetailsScreen(false)}
                actionLabel={t("Resolve Review")}
                decimalPlaces={props.decimalPlaces}
                connectionIdClassMap={connectionIdClassMap}
                enableLearning={props.enableLearning}
              />
            </>
          )}
        </div>
      )}
      {showReviewedSubmissions && (
        <div>
          {!showSubmissionDetailScreen && (
            <DataTable
              columns={reviewListColumns}
              data={transformTableData(resolvedReviewList, t)}
              subHeader={false}
              noHeader
              customStyles={customStyles}
            />
          )}

          {showSubmissionDetailScreen && (
            <>
              <QuizSumbmissionDetails
                isForReview
                quizId={props.quizId}
                selectedRecord={selectedReview}
                submissionId={submissionId}
                onBack={() => setShowSubmissionDetailsScreen(false)}
                actionLabel={t("Update Review")}
                decimalPlaces={props.decimalPlaces}
                connectionIdClassMap={connectionIdClassMap}
                enableLearning={props.enableLearning}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ReviewSubmissionsList;
