import React, { useEffect, useRef } from "react";
import classNames from "classnames";
import {
  getAllLevels,
  getAllModulesFromYear,
  getAllModulesFromYearForSelction,
} from "../../../store/knowledge/actions";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { setupModuleFormat } from "../../../utils/appUtils";
import KnowledgeDynamicList from "./KnowledgeDynamicList";
import GroupUpdater from "./GroupUpdater";
import { useHistory, useParams } from "react-router";
import { TOOLKIT_GENERATE_LEARNING_OUTCOMES, TOOLKIT_GENERATE_QNA_UPLOAD_DOC, TOOLKIT_SETUP_CONTEXTUALISED_CHAT, TOOLKIT_SUMMARISE_DOCUMENTS } from "../../../config";
import CreateQuizModalTeachersToolkit from "../../../routes/TeachersToolkit/popups/createQuizModalTeachersToolkit";
import { getCommonState } from "../../../store/common/selector";

export const KnowledgeGroupSelector = (props: any) => {
  const { t } = useTranslation("translation");
  const commonState = useSelector(getCommonState);
  const history = useHistory();
  const [editId, setEditId] = useState("");
  const dispatch = useDispatch();
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [modules, setModules] = useState([]);
  const [selectedModule, setSelectedModule] = useState("");
  const [addSelectId, setAddSelectId] = useState("");
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [showCreateQuizModal, setShowCreateQuizModal] = useState(false);

  const horizontalScrollRef = useRef();
  const { id } = useParams<{ id: string | undefined }>();
  const title = id ? id : "chat"

  const proceedHandler = () => {
    switch (title) {
      case "chat":
        history.push(
          `${TOOLKIT_SETUP_CONTEXTUALISED_CHAT}?group=${selectedModule}`
        );
        break;
      case "learning-outcomes":
        history.push(
          `${TOOLKIT_GENERATE_LEARNING_OUTCOMES}?group=${selectedModule}`
        );
        break;
      case "generate-qa":
        history.push(
          `${TOOLKIT_GENERATE_QNA_UPLOAD_DOC}?group=${selectedModule}`
        );
        break;
      case "summarise-doc":
        history.push(
          `${TOOLKIT_SUMMARISE_DOCUMENTS}?group=${selectedModule}`
        );
        break;
      case "quiz":
        setShowCreateQuizModal(true);
        break;
      default:
        break;
    }
  }


  useEffect(() => {
    if (editId === "") {
      getAllYearsFun();
      setSelectedGroupId(props.groupId ? props.groupId : null);
    }
  }, [editId]);

  const getAllYearsFun = async (loadSubModules: boolean = true) => {
    try {
      const response: any = await dispatch(getAllLevels());
      setYears([...response.filter((res: any) => res.group_type !== "system")]);
      response &&
        response.length > 0 &&
        getAllModulesFromYearFun(selectedYear ? selectedYear :commonState.selectedYear ? commonState.selectedYear : response[0]);
    } catch (error: any) {
      setYears([]);
    }
  };

  const getAllModulesFromYearFun = async (year: any) => {
    setSelectedYear(year);
    try {
      const response: any = await getAllModulesFromYearForSelction(year._id);
      commonState?.selectedGroupId && setSelectedModule(commonState?.selectedGroupId)
      setModules(response);
    } catch (error: any) {
      setModules([]);
    }
  };



  return (
    <>
      <CreateQuizModalTeachersToolkit
        show={showCreateQuizModal}
        onHide={() => {
          setShowCreateQuizModal(false);
        }}
        selectedGroupId={selectedModule}
        createSmartQuiz={true}
      />
      <div className="single-modle-box-wrap mt-16">
        <div className="level-tab-wraper">
          <div className="levels-row mb-1 d-flex align-items-center ">
            <div
              className="horizontal-scroll-view"
              id="levels-wrap"
              ref={horizontalScrollRef}
            >
              <h2 className="mb-0 mr-3 font-18">{t("Level:")}</h2>
              {years.map((year: any) => {
                return (
                  <button
                    className={classNames({
                      "btn btn-tabs ": true,
                      active: selectedYear && year._id === selectedYear._id,
                      "font-18": true,
                    })}
                    onClick={(e: any) => {
                      e.preventDefault();
                      getAllModulesFromYearFun(year);
                      setSelectedModule("")
                    }}
                  >
                    {year?.name}
                  </button>
                );
              })}
            </div>
          </div>
          {years.length > 0 && (
            <div className="knowledge-box p-0 build-knowbox text-left d-flex flex-column bg-white mt-16">
              <div className="d-flex justify-content-between align-items-center he-52 px-3 2 bg-grey-head">
                <h2 className="mb-0 font-16">{t("Knowledge Group Name")}</h2>
                <button
                  className="btn p-0 clr-primary add-group"
                  onClick={(e: any) => {
                    setEditId(selectedYear?._id);
                  }}
                >
                  + New Group
                </button>
              </div>
              {(modules.length < 1 && selectedYear?._id !== editId) &&
                <div className="flex just justify-content-center align-items-end h-100">
                  <div className="flex justify-content-center align-items-center flex-column font-600 ">
                    <span> No Modules Found.</span>

                    <span>
                      Click<span className="clr-primary"> [+ New Group]</span> to
                      create a new knowledge group
                    </span>
                  </div>
                </div>}

              <div className="superbody">
                {selectedYear?._id === editId && (
                  <GroupUpdater
                    editId={editId}
                    setEditId={setEditId}
                    depth={0}
                    setSelectedModule={setSelectedModule}
                  />
                )}
                <KnowledgeDynamicList
                  data={modules}
                  editId={editId}
                  setEditId={setEditId}
                  selectedModule={selectedModule}
                  setSelectedModule={setSelectedModule}
                  setAddSelectId={setAddSelectId}
                  addSelectId={addSelectId}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="d-flex justify-content-end mt-16">
        <button
          className="btn-primary btn mr-0 font-14 font-600"
          disabled={selectedModule?.length < 1 ? true : false}
          onClick={() => proceedHandler()}
        >
          {title === "quiz" ? t("Proceed to Create Quiz") : t("Proceed to Upload Content")}
        </button>
      </div>
    </>
  );
};

export default KnowledgeGroupSelector;
