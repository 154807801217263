import classNames from "classnames";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import ModalBody from "react-bootstrap/ModalBody";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { TOOLKIT_SUMMARISE_DOCUMENTS_SELECT_PAGES } from "../../../../../config";

export const SelectSummaryType = (props: any) => {
  const { t } = useTranslation();
  const useLocatio = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [documents, setDocuments] = useState(null);

  useEffect(() => {
    if(props.documents && props.documents.length === 0) {
      return;
    }
    setDocuments(props.documents);
  }, [props.documents])

  const onSetSummaryType = (summaryType: string) => {
    props.onSetSummaryType(summaryType);
  };

  return (
    <Modal
      show={props.show}
      centered
      dialogClassName="modal-683"
      onHide={props.onHide}
    >
      <ModalBody className="py-0 px-4 pt-3">
        <>
          <div className="form-group">
            <label className="font-600">{t("Summarise Document(s)")} </label>
            <p className="clr-grey-txt7 font-600">
              {t("How should the documents be summarised?")}
            </p>
            <div className="row px-2 justify-content-center">
              <div
                className="col-6 px-2"
                onClick={() => {
                  if (props.uploadDoc) {
                    onSetSummaryType("single");
                  } else {
                    history.push(
                      `${TOOLKIT_SUMMARISE_DOCUMENTS_SELECT_PAGES}?documents=${documents.join(
                        ","
                      )}&group=${props.groups}&summary-type=single${
                        props.selectFromKnowledgeBase
                          ? `&through=documents`
                          : ""
                      }`
                    );
                  }
                }}
              >
                <div
                  className={classNames({
                    "py-4 px-3 border-grey d-flex align-items-center radius-8 h-100 text-center":
                      true,
                  })}
                >
                  <span>
                    <h3>{t("Merge Document(s)")}</h3>
                    <p className="mb-0">
                      {t(
                        "Consolidate and summarise uploaded documents into a single file."
                      )}
                    </p>
                  </span>
                </div>
              </div>
              <div
                className="col-6 px-2"
                onClick={() => {
                  if (props.uploadDoc) {
                    onSetSummaryType("individual");
                  } else {
                    history.push(
                      `${TOOLKIT_SUMMARISE_DOCUMENTS_SELECT_PAGES}?documents=${documents.join(
                        ","
                      )}&group=${props.groups}&summary-type=individual${
                        props.selectFromKnowledgeBase
                          ? `&through=documents`
                          : ""
                      }`
                    );
                  }
                }}
              >
                <div
                  className={classNames({
                    "py-4 px-3 border-grey radius-8 h-100 d-flex align-items-center text-center":
                      true,
                  })}
                >
                  <span>
                    <h3>{t("Summarise Document(s) individually")}</h3>
                    <p className="mb-0">
                      <span>
                        {t(
                          "Each uploaded file will be summarised individually"
                        )}
                      </span>
                      <span>
                        {t("Recommended for unstructured documents ")}
                      </span>
                      <span>
                        {t("(e.g., transcripts) and PowerPoint slides. ")}
                      </span>
                    </p>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </>
      </ModalBody>
    </Modal>
  );
};

export default SelectSummaryType;
