import React from "react";
import { useSelector } from "react-redux";
import { getCommonState } from "../../../store/common/selector";


const OnlySupperAdmin = ( props: any ) => {
  const commonState = useSelector(getCommonState);
  // console.log('commonState', commonState?.user?.role)
  if (commonState?.user?.role === "superadmin"
  ) {
    return props.children;
  }
  return <></>; 
} 

export default OnlySupperAdmin;
