import React, { useEffect, useState } from "react";
import { AddGreeting } from "../../../app.interface";
import RichTextArea from "../RichTextArea/RichTextArea";
import UserGroupModal from "../UserGroupModal/UserGroupModal";
import deleteImage from "../../../assets/images/delete-icon.svg";
import peoples from "../../../assets/images/peoples.svg";
import PermissionWrapper from "../PermissionWrapper/PermissionWrapper";

import { useTranslation } from "react-i18next";

interface Props {
  allUserGroups: any;
  greetings: AddGreeting[];
  onUpdateGreetings: (greetings: AddGreeting[]) => void;
  onCopyExistingGreeting?: (greeting: AddGreeting, index: number) => void;
  hideUserGroup?: boolean
}

const GreetingsWithGroups = ({
  allUserGroups,
  greetings,
  onUpdateGreetings,
  onCopyExistingGreeting,
  hideUserGroup = false
}: Props) => {
  const { t } = useTranslation('translation');
  const [addGreeting, setAddGreeting] = useState<AddGreeting[]>([]);
  useEffect(() => {
    if (greetings && greetings.length > 0) {
      setAddGreeting([...greetings]);
    } else {
      setAddGreeting([]);
    }
  }, [greetings]);

  const onRemoveGeetingHandler = (index: number) => {
    const prevResult = [...addGreeting];
    prevResult.splice(index, 1);
    setAddGreeting([...prevResult]);
    onUpdateGreetings([...prevResult]);
  };

  const onEditorStateChange = (index: number, name: any) => {
    const prevResult = [...addGreeting];
    const greeting = prevResult[index];
    greeting.text = name;
    setAddGreeting([...prevResult]);
    onUpdateGreetings([...prevResult]);
  };

  const chooseButtonGrouphandler = (e: any, index: number) => {
    const prevResult = [...addGreeting];
    const greeting = prevResult[index];
    greeting.group_ids = e.map((g: any) => g.value);
    setAddGreeting([...prevResult]);
    onUpdateGreetings([...prevResult]);
    hideModalHandler();
  };

  const [modalData, setModalData] = useState<any>({});
  const popupHandler = (data: any) => {
    setModalData({
      selectedGroups: data.selectedGroups,
      index: data.index,
      show: true,
    });
  };

  const hideModalHandler = () => {
    setModalData({
      selectedGroups: [],
      index: null,
      show: false,
    });
  };

  return (
    <React.Fragment>
      <UserGroupModal
        selectedGroups={
          modalData.selectedGroups ? [...modalData.selectedGroups] : []
        }
        index={modalData.index}
        allUserGroups={allUserGroups}
        show={modalData.show}
        size="md"
        onHide={hideModalHandler}
        onUpdate={chooseButtonGrouphandler}
      />
      {addGreeting &&
        addGreeting.map((item: any, index: number) => {
          const selectedGroups: any = [];
          item.group_ids &&
            item.group_ids.length > 0 &&
            item.group_ids.forEach((id: any) => {
              const group = allUserGroups.find((ug: any) => ug.value === id);
              if (group) {
                selectedGroups.push(group);
              }
            });
          return (
            <React.Fragment key={"greeting_grp_" + index}>
              <div
                className="row greeting-row mb-3"
                ref={(e: any) => {
                  if (e) {
                    e.style.setProperty("--animation-order", index + 1);
                  }
                }}
                id={"greeting_grp_" + index}
              >
                <div className="col-md-12 d-flex row-reverse align-items-start">
                  <div className="ans-settings">
                    <PermissionWrapper>
                      {addGreeting.length > 1 && (
                        <button
                          className="btn"
                          onClick={() => onRemoveGeetingHandler(index)}
                        >
                          <img
                            src={deleteImage}
                            alt={t("Delete Image")}
                            width="16"
                            height="16"
                          />
                        </button>
                      )}
                      </PermissionWrapper>
                      {!hideUserGroup && 
                          <PermissionWrapper>
                          <button
                            className="btn"
                            onClick={() =>
                              popupHandler({
                                selectedGroups,
                                index,
                              })
                            }
                          >
                             <img
                                src={peoples}
                                alt={t("Update user groups")}
                                width="16"
                                height="16"
                              />
                          </button>
                          </PermissionWrapper>
                      }
                    
                      <button
                        className="btn"
                        onClick={() => onCopyExistingGreeting(item, index)}
                      >
                        <svg width="17" height="17" viewBox="0 0 17 17">
                          <g
                            id="Icon_feather-copy"
                            data-name="Icon feather-copy"
                            transform="translate(-2.5 -2.5)"
                          >
                            <path
                              id="Path_2864"
                              data-name="Path 2864"
                              d="M15.1,13.5h7.2a1.6,1.6,0,0,1,1.6,1.6v7.2a1.6,1.6,0,0,1-1.6,1.6H15.1a1.6,1.6,0,0,1-1.6-1.6V15.1A1.6,1.6,0,0,1,15.1,13.5Z"
                              transform="translate(-4.9 -4.9)"
                              fill="none"
                              stroke="#0e0748"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1"
                            />
                            <path
                              id="Path_2865"
                              data-name="Path 2865"
                              d="M5.4,13.4H4.6A1.6,1.6,0,0,1,3,11.8V4.6A1.6,1.6,0,0,1,4.6,3h7.2a1.6,1.6,0,0,1,1.6,1.6v.8"
                              fill="none"
                              stroke="#0e0748"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1"
                            />
                          </g>
                        </svg>
                      </button>
                  </div>
                  <RichTextArea
                    showRemoveIcon={false}
                    key={index}
                    index={index}
                    item={item}
                    onEditorStateChangeHandler={onEditorStateChange}
                    onRemoveGeetingHandler={onRemoveGeetingHandler}
                    placeholder={t('Add New Variant')}
                  />
                </div>
                {/*<div className='col-md-1'>
                   <h3 className='mb-12 font-weight-bold'>
                    User Group
                    <OverlayTrigger
                      trigger={['hover', 'focus']}
                      placement={'auto'}
                      overlay={(d) => (
                        <PopoverComponent popoverComponentProps={d} />
                      )}
                    >
                      <Image src={infoimg} className='ml-2' alt='Info' />
                    </OverlayTrigger>
                  </h3>
                  <Select
                    key={'select_ref_' + index}
                    isSearchable
                    isMulti
                    className='select-usergroup'
                    classNamePrefix='select-greeting-user-group'
                    components={{
                      DropdownIndicator: () => (
                        <img src={downarrowblk} alt='dropdown arrow' />
                      ),
                      IndicatorSeparator: () => null,
                      ClearIndicator: () => null,
                    }}
                    placeholder={'Select user group'}
                    defaultValue={[...selectedGroups]}
                    value={[...selectedGroups]}
                    onChange={(e) => chooseButtonGrouphandler(e, index)}
                    options={allUserGroups}
                    getOptionLabel={(option: any) => option['name']}
                    getOptionValue={(option: any) => option['_id']}
                  />
               
                </div>  */}
              </div>
            </React.Fragment>
          );
        })}
    </React.Fragment>
  );
};

export default GreetingsWithGroups;
