import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Container,  } from "react-bootstrap";
import CheckPlan from "../../assets/images/check-plan.svg";
import PlanBG from "../../assets/images/plan-bg.png";
import "./styles.scss";
import PlanSection from "./PlanSection";
import QnA from "./QnA";
import CommonService from "../../services/CommonService";
import { Link, useHistory, useLocation } from "react-router-dom";
import chevLeft from "../../assets/New-images/chevron-left-blk.svg";
import PlanCheck from "../../assets/images/plan-check.svg";

const commonService = new CommonService();
const PLANS = {
  EDUCATOR_PLUS: {
    isRecommened: true,
    name: "Educator Plus",
    des: "Great for educators looking to start their AI journey",
    priceMonth: "$9.99/month",
    priceYear: "$109/year (10% savings)",
    btnText: "Try for 14 days free",
    isCancelAble: true,
    planIncludes: [
      "Up to 20 document uploads per month",
      "Teacher AI toolkit",
      "Up to 30 students",
      "Up to 1 teacher/admin",
      "Up to 10 quizzes",
      "AI grading",
      "Single agent",
    ],
  },
  // EDU_ES: {
  //   isRecommened: false,
  //   name: "Educator Essential",
  //   des: "Perfect for any educator",
  //   priceMonth: "Free",
  //   priceYear: "For a lifetime",
  //   planIncludes: [
  //     "Access to teacher AI toolkit",
  //     "Document summarisation",
  //     "Automated learning outcomes",
  //     "Automated tutoring pathways",
  //     "AI-generated assessments",
  //   ],
  // },
  CLASSROOM_PRO: {
    isRecommened: false,
    name: "Classroom Pro",
    isCancelAble: true,
    des: "Great for larger classes and multiple teachers",
    priceMonth: "$59.99/month",
    priceYear: "$580/year (20% savings)",
    btnText: "Select Plan",
    planIncludes: [
      "Access to teacher AI toolkit",
      "Document summarisation",
      "Automated learning outcomes",
      "Automated tutoring pathways",
      "AI-generated assessments",
      "AI-grading",
      "Increased document size limit",
      "Up to 50 students access",
      "Multiple teachers access",
    ],
  },
  INSTITUTION_ESSENTIALS: {
    isRecommened: false,
    name: "Institution Essentials",
    isCancelAble: true,
    des: "Ideal for schools and higher ed institutes looking to start integrating AI into their teaching and learning",
    priceMonth: "$99.99/month",
    priceYear: "",
    btnText: "Select Plan",
    planIncludes: [
      "Unlimited document uploads",
      "Teacher AI toolkit",
      "Up to 100 student access",
      "Unlimited teacher access",
      "Unlimited quizzes",
      "Rubric-based AI essay grading",
      "LTI integrations",
      "Multi-agent",
      "Dedicated learning success manager",
    ],
  },
  BUSINESS_ESSENTIALS: {
    isRecommened: false,
    name: "Business Essentials",
    isCancelAble: true,
    des: "Great for AI learning and support for your employees tailored to your company's messaging",
    priceMonth: "$99.99/month",
    priceYear: "",
    btnText: "Select Plan",
    planIncludes: [
      "Access to AI toolkit",
      "Unlimited document uploads",
      "Up to 100 learner access",
      "Unlimited admin/trainer access",
      "Automated learning pathways",
      "Azure SSO integration",
      "24/7 online support",
      "Dedicated learning success manager",
    ],
  },
  INSTITUTION: {
    isRecommened: false,
    name: "Institution",
    des: "Tailored for schools and higher ed institutes based on your needs",
    btnText: "Request for free trial",
    planIncludes: [
      "Unlimited document uploads",
      "Increased document size limits",
      "Teacher AI toolkit",
      "Student access based on requirement",
      "Unlimited teacher access",
      "Unlimited quizzes",
      "Rubric-based AI essay grading",
      "LTI and custom integrations",
      "Multi-agent",
      "Custom personas",
      "Dedicated learning success manager",
    ],
  },
  BUSSINESS: {
    isRecommened: false,
    name: "Enterprise",
    des: "Great for larger enterprises with custom needs",
    btnText: "Request for demo",
    planIncludes: [
      "Access to AI toolkit",
      "Unlimited document uploads",
      "Learner access depending on requirement",
      "Unlimited admin/trainer access",
      "Automated learning pathways",
      "No-touch setup services",
      "SSO/Workflow integrations",
      "Custom integrations",
      "24/7 online support",
      "Dedicated learning success manager",
    ],
  },
};

export const ManagePlan = () => {
  const { t } = useTranslation("translation");
  const useLocatio = useLocation();
  const history = useHistory();
  const [prods, setProds] = useState([]);
  const [allowsChangeSubscription, setAllowsChangeSubscription] = useState(false);
  const [licenses, setLicences] = useState([]);

  const getProducts = async () => {
    const res: any = await commonService.getProds();
    if (res?.data?.data?.product_definitions) {
      setProds(res?.data?.data?.product_definitions);
    }
  };
  
  const getPaymentInfo = async () => {
    try {
      const res: any = await commonService.getPaymentInfo();
      if(res && res.data && res.data.data) {
        if (res.data.data.licenses.length > 0) {
          setLicences([ res?.data?.data?.licenses[0] ]);
        }
        if(res.data.data.allows_change_subscription) {
          setAllowsChangeSubscription(true);
          getProducts();
        } else {
          setAllowsChangeSubscription(false);
        }
      }
    } catch (error) {
      setAllowsChangeSubscription(false);
      setLicences([])
    }
  };

  useEffect(() => {
    getPaymentInfo();
  }, []);

  const renderCompareTable = () => {
    return (
        <table className="compare-table">
          <thead>
          <tr>
            <th className=""></th>
            <th>
              <h5>{t("Educator Plus")}</h5>
            </th>
            <th>
              <div className="clr-primary">{t("(Most Popular)")}</div>
              <h5>{t("Classroom Pro")}</h5>
            </th>
            <th>
              <h5>{t("Institution Essentials")}</h5>
            </th>
            <th>
              <h5>{t("Business Essentials")}</h5>
            </th>
            <th>
              <h5>{t("Institution")}</h5>
            </th>
            <th>
              <h5>{t("Business")}</h5>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th>{t("Teacher AI toolkit")}</th>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
          </tr>
          <tr>
            <th>{t("Document summarisation")}</th>
            <td>{t("Up to 20")}</td>
            <td>{t("Up to 50")}</td>
            <td>{t("Unlimited")}</td>
            <td>{t("Unlimited")}</td>
            <td>{t("Unlimited")}</td>
            <td>{t("Unlimited")}</td>
          </tr>
          <tr>
            <th>{t("Automated learning outcomes")}</th>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
          </tr>
          <tr>
            <th>{t("Automated tutoring pathways")}</th>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
          </tr>
          <tr>
            <th>{t("AI-generated assessments")}</th>
            <td>{t("Up to 10")}</td>
            <td>{t("Up to 10")}</td>
            <td>{t("Unlimited")}</td>
            <td>{t("Unlimited")}</td>
            <td>{t("Unlimited")}</td>
            <td>{t("Unlimited")}</td>
          </tr>
          <tr>
            <th>{t("AI grading")}</th>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
          </tr>
          <tr>
            <th>{t("Increased document size limits")}</th>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
          </tr>
          <tr>
            <th>{t("Multiple teachers access")}</th>
            <td>-</td>
            <td>{t("Up to 5")}</td>
            <td>{t("Unlimited")}</td>
            <td>{t("Unlimited")}</td>
            <td>{t("Unlimited")}</td>
            <td>{t("Unlimited")}</td>
          </tr>
          <tr>
            <th>{t("Unlimited document uploads")}</th>
            <td>-</td>
            <td>-</td>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
          </tr>
          <tr>
            <th>{t("Unlimited user access")}</th>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
          </tr>
          <tr>
            <th>{t("LTI integrations")}</th>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
          </tr>
          <tr>
            <th>{t("Workflow integrations")}</th>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
          </tr>
          <tr>
            <th>{t("Rubric-based AI essay grading")}</th>
            <td>-</td>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
            <td>-</td>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
            <td>
              <img className="check-plan" src={CheckPlan} alt=""/>
            </td>
          </tr>
          </tbody>
        </table>
    );
  };

  const renderQuestions = () => {
    const ques = [
      {
        q: "Does Walter support multiple languages?",
        a: () => (
            <p className="font-16">
              {t("Absolutely! Walter supports over 80 languages today!")}
            </p>
        ),
      },
      {
        q: "What's the quickest way to learn how to use Walter?",
        a: () => (
            <p className="font-16">
              {t("Our")}{" "}
              <Link
                  className="reach-out"
                  to="/support"
              >
                {t("Support Page")}
              </Link>{" "}
              {t(
                  "offers video guides that will make learning Walter a breeze. Simply log in, access the guides, and become a Walter expert in record time! We also provide 24/7 live chat support."
              )}
            </p>
        ),
      },
      {
        q: "What if I reach my plan's limit?",
        a: () => (
            <p className="font-16">
              {t(
                  "When you exceed your plan's limit, you will need to upgrade to a higher plan to increase your capacity. For larger deployments with over 50 users, we recommend considering a customised plan tailored to your specific requirements. Get in touch with us at"
              )}{" "}
              <a
                  className="reach-out"
                  href="mailto:chat@noodlefactory.ai"
                  target="_new"
              >
                chat@noodlefactory.ai
              </a>{" "}
              {t("to explore your options and take the next steps!")}
            </p>
        ),
      },
      {
        q: "What is your refund policy?",
        a: () => (
            <p className="font-16">
              {t(
                  "Discover the power of Walter as part of your Educator Essential Plan, or test it during your 14-day free trial period with the Educator Plus Plan and decide if it's right for you. You can upgrade, downgrade, or cancel anytime as your needs evolve. For account assistance, reach out to"
              )}{" "}
              <a
                  className="reach-out"
                  href="mailto:chat@noodlefactory.ai"
                  target="_new"
              >
                chat@noodlefactory.ai
              </a>
              {" ."}
              {t("We're here to support you every step of the way!")}
            </p>
        ),
      },
      {
        q: "Am I eligible for a discounted plan?",
        a: () => (
            <p className="font-16">
              {t(
                  "If you've had a fantastic trial experience with Walter and want to bring him into your school or business, our tailored packages ensure you get the best value and features to meet your school's or business' needs. Ready to learn more? Email us at"
              )}{" "}
              <a
                  className="reach-out"
                  href="mailto:chat@noodlefactory.ai"
                  target="_new"
              >
                chat@noodlefactory.ai
              </a>
              {", "}
              {t("and we'll provide you with all the exciting details.")}
            </p>
        ),
      },
      {
        q: "Is there a specific type of school that is best suited for Walter?",
        a: () => (
            <p className="font-16">
              {t(
                  "Our AI platform thrives in various learning environments! We've witnessed great success in higher education, vocational training programs, adult learning centres, and K-12 schools, particularly with students from Grade 8 onwards. Regardless of the type of institution, our AI platform is designed to enhance teaching and learning experiences."
              )}
            </p>
        ),
      },
      {
        q: "Can I use Walter in a business setting?",
        a: () => (
            <p className="font-16">
              {t(
                  "Definitely! Our AI platform is versatile and well-suited for various learning environments, including business settings. It is great for a range of use cases, such as onboarding new employees, supporting employee training programs, compliance training, and information management. Whether you're a small business or a large corporation, Walter can be tailored to meet your specific needs in a business setting."
              )}
            </p>
        ),
      },
    ];

    return ques.map((q) => <QnA q={q} />);
  };

  const getProd = (name: any) => {
    const prod = prods.find((p) => p.name === name);
    if (prod) {
      return prod;
    }
    return null;
  };

  return (
    <div>
      {!allowsChangeSubscription && (
        <Container className="pb-5 current-subscription">
          <div className="mb-24 h1 d-flex align-items-center mt-3">
            <button
              className="btn py-0 pl-0 pr-3"
              onClick={() => {
                history.goBack();
              }}
            >
              <img src={chevLeft} alt="" />
            </button>

            <div>
              <h4 className="w-100 mb-0 font-weight-bold">
                {t("View Subscription")}
              </h4>
              <p className="font-16 mb-0">
                {t("Manage your subscription or upgrade plans")}
              </p>
            </div>
          </div>
          <div>
            <h3 className="mb-3 font-weight-bold">{t("Current Plan")}</h3>
            {
                (licenses.length > 0) && licenses.map(({ features, product_definition, status, tier,  }): any => {
                return (
                <div className="plan-section-wrapper w-100">
                  <div className="row">
                    <div className="col-4 border-right">
                      <div className="name">{product_definition?.name}</div>
                      <div className="sub mb-24">
                        {product_definition?.description}
                      </div>
                      <div>
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="feature-label pt-0 pb-3">{t("Features")}</div>
                      <div className="all-features">
                        {(features||[]).map((p: any) => (
                        <div className="feature-item d-flex align-items-center">
                          <img src={PlanCheck} alt="" />
                          <span className="feature"> {t(p)}</span>
                        </div>
                      ))}
                      </div>
                    </div>
                  </div>
                </div>
                )
              })
            }
          </div>
        </Container>
      )}
      {allowsChangeSubscription && (
        <Container className="pb-5">
          <div className="w-100 d-relative plan-bg">
            <img src={PlanBG} alt="" />
            <div className="center-banner-text">
              <div className="banner-text">
                <span className="clr-primary">{t("Unblock Your")}</span>{" "}
                <span className="clr-hight-light">{t("Superpowers")}</span>
              </div>
              <div>
                <h1>{t("with our AI teaching assistant")}</h1>
              </div>
            </div>
          </div>
          <div className="text-center heading-plan py-5">
            {t("We got a pricing plan that’s perfect for you")}
          </div>
          <section className="row my-3">
            {/*<div className="col-md-4">*/}
            {/*  <PlanSection*/}
            {/*    {...PLANS.EDU_ES}*/}
            {/*    url={getProd("Educator Essential")?.url}*/}
            {/*    isCurrent={getProd("Educator Essential")?.is_current}*/}
            {/*    isRecommened={getProd("Educator Essential")?.is_recommended}*/}
            {/*  />*/}
            {/*</div>*/}
            <div className="col-md-4">
              <PlanSection
                  {...PLANS.EDUCATOR_PLUS}
                  url={getProd("Educator Plus")?.url}
                  isCurrent={getProd("Educator Plus")?.is_current}
                  isRecommened={getProd("Educator Plus")?.is_recommended}
              />
            </div>
            <div className="col-md-4">
              <PlanSection
                  {...PLANS.CLASSROOM_PRO}
                  url={getProd("Classroom Pro")?.url}
                  isCurrent={getProd("Classroom Pro")?.is_current}
                  isRecommened={getProd("Classroom Pro")?.is_recommended}
              />
            </div>
            <div className="col-md-4">
              <PlanSection
                  {...PLANS.INSTITUTION_ESSENTIALS}
                  url={getProd("Institution Essentials")?.url}
                  isCurrent={getProd("Institution Essentials")?.is_current}
                  isRecommened={getProd("Institution Essentials")?.is_recommended}
              />
            </div>
          </section>
          <section className="row mb-3">
            <div className="col-md-4">
              <PlanSection
                  {...PLANS.BUSINESS_ESSENTIALS}
                  url={getProd("Business Essentials")?.url}
                  isCurrent={getProd("Business Essentials")?.is_current}
                  isRecommened={getProd("Business Essentials")?.is_recommended}
              />
            </div>
            <div className="col-md-4">
              <PlanSection
                  {...PLANS.INSTITUTION}
                  url={"https://info.noodlefactory.ai/request-institution-trial "}
                  isCurrent={getProd("Institution")?.is_current}
                  isRecommened={getProd("Institution")?.is_recommended}
              />
            </div>
            <div className="col-md-4">
              <PlanSection
                  {...PLANS.BUSSINESS}
                  url={" https://info.noodlefactory.ai/request-demo"}
                  isCurrent={getProd("Business")?.is_current}
                  isRecommened={getProd("Business")?.is_recommended}
              />
            </div>
          </section>
          <section className="my-3 pt-5">
            <div className="text-center">
              <b className="font-18">{t("Plan Features")}</b>
            </div>
            <div className="text-center pb-5">
              <h1 className="font-18">{t("What's The Best Fit")}</h1>
            </div>
            {renderCompareTable()}
          </section>
          <section className="font-1 text-center py-3">
            {t("Can't decide? The Noodlers are here to help!")}
            <a
              className="reach-out"
              href="https://info.noodlefactory.ai/contact-sales"
              rel="noopener noreferrer"
              target="_blank"
            >
              {" "}
              {t("Reach Out Now")}
            </a>
          </section>
          <section className="pt-5">
            <div className="text-center">
              <b className="font-18">{t("Answers to")}</b>
            </div>
            <div className="text-center">
              <h1>{t("Frequently Asked Questions")}</h1>
            </div>
            <div className="qnas mt-5">{renderQuestions()}</div>
          </section>
        </Container>
      )}
    </div>
  );
};

export default ManagePlan;
