import { AtomicBlockUtils } from 'draft-js'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import useFetch from "../../helpers/token-check";
// import StickerPicker from "../StickerPicker";
import sticker from '../../../../assets/editor/sticker.svg'
import {
  getStickersBasedOnNames
} from '../../../../store/common/actions'
import {
  getCommonStickersFromState, getCommonStickersSetsFromState
} from '../../../../store/common/selector'
import StickerPicker from './StickerPicker/StickerPicker'

interface Props {}

export default function StickerOption(props: any) {
  const [expanded, setExpanded] = useState(false)
  const [stickerSets, setStickerSets] = useState([])
  const [stickers, setStickers] = useState([])
  const dispatch = useDispatch()
  const commonStickersSets = useSelector(getCommonStickersSetsFromState)
  const allStickers = useSelector(getCommonStickersFromState)

  useEffect(() => {
    setStickerSets([...commonStickersSets])
    setStickers([...allStickers])
  }, [commonStickersSets, allStickers])
  
  useEffect(() => {
    if ( expanded && stickerSets.length > 0 ) {
      dispatch(getStickersBasedOnNames(stickerSets[0].name))
    }
  }, [ expanded ])

  const onStickerSetChange = (stickerName: string) => {
    setStickers([]);
    dispatch(getStickersBasedOnNames(stickerName))
  }

  const stopPropagation = (event: any) => {
    event.stopPropagation()
  }

  const onStickerSelect = (stickerUrl: string) => {
    const { editorState, onChange, config } = props

    const entityData = {
      src: stickerUrl,
      height: 'auto',
      width: '100px',
      imgType: 'sticker',
      fileName: 'sticker',
      alt: '',
      class: '',
    }

    entityData.alt = 'sticker'
    entityData.class = 'custom-sticker-img'

    const entityKey = editorState
      .getCurrentContent()
      .createEntity('IMAGE', 'MUTABLE', entityData)
      .getLastCreatedEntityKey()

    const newEditorState = AtomicBlockUtils.insertAtomicBlock(
      editorState,
      entityKey,
      ' '
    )

    onChange(newEditorState)
    doCollapse()
  }

  const renderModal = () => {
    return (
      <div onClick={stopPropagation} className='rdw-emoji-modal'>
        <StickerPicker
          onSelect={onStickerSelect}
          stickerSets={stickerSets}
          onSetChange={onStickerSetChange}
          stickers={stickers}
        />
      </div>
    )
  }

  const expandCollapse = () => {
    if (!expanded) {
      props.modalHandler.closeAllModals()
    }
    setExpanded((expanded) => !expanded)
  }

  const doCollapse = () => {
    setExpanded(false)
  }

  return (
    <div
      aria-haspopup='true'
      aria-expanded={expanded}
      aria-label='rdw-link'
      className='rdw-link-wrapper'
    >
      <div
        onClick={expandCollapse}
        className='rdw-option-wrapper markdown-toolbar-option'
      >
        <img src={sticker} alt='S' />
      </div>
      {expanded ? renderModal() : undefined}
    </div>
  )
}
