import React, { useEffect, useState } from "react";
import { Container, Image } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";
import { DocOptions } from "../../app.constant";
import backblue from "../../assets/images/arrow-left.svg";
import closeimg from "../../assets/New-images/close-icon-red.svg";
import downarrowblue from "../../assets/New-images/dropdown-arow-blue.svg";
import noSectionRequired from "../../assets/New-images/no_section_required.svg";
import infoImg from "../../assets/New-images/info-file-img.svg";
import infoCircleImg from "../../assets/New-images/info-pink-circle.svg";

import PDFViewer from "../../components/UI/PDFViewer/PDFViewer";
import PermissionWrapper from "../../components/UI/PermissionWrapper/PermissionWrapper";
import {
  addDocumentSectionsForDocuments,
  bulkAssignModules,
  docSearchAddToIndex,
  getDocumentFromID,
  getDocumentsValidations,
  getSectionsBasedOnDocumentId,
  sendDocToSumRequest,
  updateDocumentSections,
} from "../../store/knowledge/actions";

import { useTranslation } from "react-i18next";
import Loading from "../../components/UI/Loading/Loading";
import { SUMMARISE_DOCUMENT, SUMMARISE_KANDRA_DOCUMENT } from "../../config";
import classNames from "classnames";

import { HelperService } from "../../services/helper.service";
import MoveSelectedModal from "../../components/UI/KnowledgeDatatableComponent/MoveSelectedModal";
const helper = new HelperService();

export const MultiDocumentSectionsKandra = (props: any) => {
  const { t } = useTranslation("translation");
  //   const isMgs = process.env.REACT_APP_CISCO === "mgs";
  //   const Knowoptions: any[] = DocOptions;
  const history = useHistory();
  const historyParams: any = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [uploadedDocumentIds, setUploadedDocumentIds] = useState([]);
  const [documentSectionData, setDocumentSectionData] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [localSections, setLocalSections] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(0);
  const [selectedDocumentType, setSelectedDocumentType] = useState(null);
  const [notReadyToSummarised, setNotReadyToSummarised] = useState(false);
  const [activeTab, setActiveTab] = useState("0");
  const [modalShow, setModalShow] = useState(false);
  const [throughDocument, setThroughDocument] = useState(null);
  const [urlMap, setUrlMap] = useState(null);

  useEffect(() => {
    const query = new URLSearchParams(history.location.search);
    const question = query.get("documents") || query.get("question");
    const params = question.split(",");
    setUploadedDocumentIds(params);
    setThroughDocument(query.get("through"))
    if (!params.length) {
      history.goBack();
    }

    getDocumentFromIDHandler(params);
  }, []);

  const getDocumentFromIDHandler = async (documentIds: string[]) => {
    const documents = [];
    let index = 0;
    for await (const document of documentIds) {
      const fetchedDocument: any = await dispatch(getDocumentFromID(document));
      const option: any = DocOptions.find(
        (option: any) => option.value === fetchedDocument.type
      );
      setSelectedDocumentType(option);
      documents.push({
        ...fetchedDocument,
        documentIndex: index,
        label: fetchedDocument.name,
        value: fetchedDocument._id,
      });
      index++;
    }

    // let temp: { [id: string]: any } = {};
    // for (const question of documentIds) {
    //   let tempSectionsArray = new Array(4);
    //   for (let i = 0; i < 9; i++) {
    //     const id = uuidv4();
    //     tempSectionsArray[i] = {
    //       id: id,
    //       name: "",
    //       from_page: "",
    //       to_page: "",
    //     };
    //   }
    //   temp[question] = { ...temp[question], sections: [] };
    // }
    // await setDocumentSectionData(temp);
    await setDocuments([...documents]);
    await paginateDocuments(documents, 0);
  };

  const paginateDocuments = async (documents: any[] = [], index: number) => {
    setSelectedDocument(documents[index]);
    setSelectedDocumentIndex(index);
    getDocumentSections(documents[index]._id);
    // getDocumentValidationsHandler(documents.map((doc: any) => doc._id));
    // getDocumentValidationsHandler(documents[index]._id);
  };

  const setDocumentSection = async (
    fetchedSections: any[],
    questionId: string
  ) => {
    const temp: { [id: string]: any } = {};
    // let tempSectionsArray = new Array(4);
    // for (let i = 0; i < 4; i++) {
    //   const id = uuidv4();
    //   tempSectionsArray[i] = {
    //     id: id,
    //     name: "",
    //     from_page: "",
    //     to_page: "",
    //   };
    // }
    temp[questionId] = {
      ...temp[questionId],
      sections: [],
    };
    if (fetchedSections.length > 0) {
      let sections: any = [...temp[questionId].sections];
      fetchedSections.reverse().forEach((section: any) => {
        sections.unshift(section);
      });
      temp[questionId].sections = [...sections];
      await setDocumentSectionData({ ...documentSectionData, ...temp });
      return;
    } else {
      let sections: any = [...temp[questionId].sections];
      const newId = uuidv4();
      sections.push({
        id: newId,
        name: "",
        from_page: "",
        to_page: "",
      });
      temp[questionId].sections = [...sections];
      await setDocumentSectionData(temp);
      return;
    }
  };

  const getDocumentSections = async (questionId: any) => {
    try {
      const sectionResponse: any = await dispatch(
        getSectionsBasedOnDocumentId([questionId])
      );
      setLocalSections([...sectionResponse]);
      setDocumentSection(sectionResponse, questionId);
    } catch (error) {
      setLocalSections([]);
    }
  };

  const getDocumentValidationsHandler = async (questionIds: string[]) => {
    try {
      const validationResponse: any = await dispatch(
        getDocumentsValidations([questionIds])
      );
      let valid: boolean = true;
      // const valdationsErr: any[] = validationResponse.filter(
      //   (response: any) => !response[0]
      // ).length;
      if (validationResponse.length && !validationResponse[0][0]) {
        await helper.showToaster(
          t(
            "Current document does not meet requirement. Please ensure that total page count does not exceed 20 pages"
          )
        );
        valid = false;
        setNotReadyToSummarised(true);
      } else {
        valid = true;
        setNotReadyToSummarised(false);
      }
      return valid;
    } catch (error) {
      return false;
    }
  };

  const handleInputChange = (
    documentId: string,
    sectionId: any,
    value: string,
    type: string,
    index: number
  ) => {
    const temp = { ...documentSectionData };

    if (type === "section_name") {
      let sections: any = documentSectionData[documentId].sections;
      sections[index].name = value;
      temp[documentId].sections = [...sections];
      setDocumentSectionData(temp);
    } else if (type === "from_page") {
      let sections: any = documentSectionData[documentId].sections;
      sections[index].from_page = value;
      temp[documentId].sections = [...sections];
      setDocumentSectionData(temp);
    } else if (type === "to_page") {
      let sections: any = documentSectionData[documentId].sections;
      sections[index].to_page = value;
      temp[documentId].sections = [...sections];
      setDocumentSectionData(temp);
    }
  };

  const tabsChangeHandler = (e: string) => {
    setActiveTab(e);
  };

  const saveSectionDetails = async (
    generateTags = false,
    knowledgeMode = false,
    index: number,
    sendDocumentForSummarisation: boolean = false
  ) => {
    if (!selectedDocument || !selectedDocument.value) {
      return;
    }
    let data: any = {};
    data[selectedDocument.value] = [];
    let updateSectiondata: any = {};
    updateSectiondata[selectedDocument.value] = [];
    let newSections: any[] = [];
    let updateSections: any[] = [];
    documentSectionData[selectedDocument.value].sections.forEach(
      (section: any) => {
        if (section.name || (section.to_page && section.from_page)) {
          delete section.id;
          if (!section._id) {
            newSections.push({
              ...section,
              from_page: Number(section.from_page),
              to_page: Number(section.to_page),
            });
          }

          if (section._id) {
            updateSections.push({
              ...section,
              from_page: Number(section.from_page),
              to_page: Number(section.to_page),
            });
          }
        }

        updateSectiondata[selectedDocument.value] = updateSections;
        data[selectedDocument.value] = newSections;
      }
    );

    try {
      setLoading(true);

      await dispatch(
        updateDocumentSections(
          [selectedDocument.value],
          updateSectiondata,
          false
        )
      );
      if (!updateSections.length) {
        const sectionsResponse: any = await dispatch(
          addDocumentSectionsForDocuments([selectedDocument.value], data, false)
        );
      }
      await getDocumentSections(selectedDocument.value);
      if (sendDocumentForSummarisation) {
        summariseDocumentHandler();
      } else {
        paginateDocuments(documents, index);
        setLoading(false);
      }

      //   if (generateTags) {
      //     const selectedRows: { _id: string }[] = [];
      //     uploadedDocumentIds.forEach((document: string) => {
      //       selectedRows.push({
      //         _id: document,
      //       });
      //     });
      //     const ID = uploadedDocumentIds.join(",");
      //     const docUploadRes: any = await dispatch(getDocumentFromID(ID));
      //     if (docUploadRes?.is_document_processed === false) {
      //       history.push(DOCUMENT_GENERATETE_WAITING + `?documentId=${ID}`);
      //     } else {
      //       history.push(DOCUMENTGENERATETAGSBYID + `?question=${ID}`, {
      //         selectedDocuments: documents,
      //       });
      //     }
      //   } else {
      //     if (knowledgeMode) {
      //       // const ID = documents.map((d: any) => d._id).join(",");
      //       const ID = uploadedDocumentIds.join(",");
      //       history.push(
      //         `${KNOWLEDGE_GENERATE_QUESTIONS_ANSWERS}?question=${ID}`
      //       );
      //     } else {
      //       history.push("/uploaded-documents");
      //     }
      //   }
    } catch (error: any) {
      setLoading(false);
    }
  };

  const summariseDocumentHandler = async () => {
    try {
      setLoading(true);
      // const response: any = await dispatch(
      //   sendDocToSumRequest(documents.map((doc: any) => doc._id))
      // );
      await dispatch(
        docSearchAddToIndex(documents.map((doc: any) => doc._id))
      );
      history.push(SUMMARISE_KANDRA_DOCUMENT);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const addSectionInputRow = (documentId: string) => {
    const temp = { ...documentSectionData };
    let newSectionData = [...documentSectionData[documentId].sections];
    const newId = uuidv4();
    newSectionData.push({
      id: newId,
      name: "",
      from_page: "",
      to_page: "",
    });
    temp[documentId].sections = newSectionData;
    setDocumentSectionData(temp);
  };

  const removeSectionHandler = (documentId: string, index: number) => {
    const temp = { ...documentSectionData };
    let newSectionData: any[] = [...documentSectionData[documentId].sections];
    newSectionData.splice(index, 1);
    temp[documentId].sections = newSectionData;
    setDocumentSectionData(temp);
  };

  const renderInputFields = (
    index: any,
    sectionDetails: any,
    documentId: string,
    isDisabled: boolean = false
  ) => {
    return (
      <React.Fragment>
        <div>
          {index === 0 && <h6>Section Name</h6>}
          <input
            className="input-field form-control"
            value={sectionDetails.name}
            readOnly={props.hideHeaders || isDisabled}
            onChange={(e) =>
              handleInputChange(
                documentId,
                sectionDetails.id,
                e.target.value,
                "section_name",
                index
              )
            }
          />
        </div>
        <div>
          {index === 0 && <h6>From Page</h6>}
          <input
            className="input-field form-control page-number-input"
            type="number"
            value={sectionDetails.from_page}
            readOnly={props.hideHeaders || isDisabled}
            onChange={(e) =>
              handleInputChange(
                documentId,
                sectionDetails.id,
                e.target.value,
                "from_page",
                index
              )
            }
          ></input>
        </div>
        <span
          className={`page-number-separator page-top-margin ${
            index === 0 && "page-separator-extra-margin"
          }`}
        >
          -
        </span>
        <div>
          {index === 0 && <h6>To Page</h6>}
          <input
            className="input-field form-control page-number-input"
            type="number"
            value={sectionDetails.to_page}
            readOnly={props.hideHeaders || isDisabled}
            onChange={(e) =>
              handleInputChange(
                documentId,
                sectionDetails.id,
                e.target.value,
                "to_page",
                index
              )
            }
          ></input>
        </div>
        {/* {index > 0 && ( */}
        <button
          className={`btn-red-close border-0 ml-1 ${index === 0 && "mt-36"}`}
        >
          <Image
            src={closeimg}
            onClick={(e) => removeSectionHandler(documentId, index)}
          />
        </button>
        {/* )} */}
      </React.Fragment>
    );
  };

  const getNoOfPagesHandler = (e: any) => {
    let docuData: any = { ...documentSectionData };
    docuData[selectedDocument.value].count = e;
    if (
      "sections" in docuData[selectedDocument.value] &&
      docuData[selectedDocument.value].sections.filter(
        (sec: any) =>
          sec.name.trim().length === 0 &&
          sec.to_page.toString().trim().length === 0 &&
          sec.from_page.toString().trim().length === 0
      ).length === docuData[selectedDocument.value].sections.length
    ) {
      const temp = { ...documentSectionData };
      let newSectionData = [
        ...documentSectionData[selectedDocument.value].sections,
      ];
      const newId = uuidv4();
      newSectionData.unshift({
        id: newId,
        name: "Default",
        from_page: 1,
        to_page: e,
      });
      temp[selectedDocument.value].sections = newSectionData;
      setDocumentSectionData(temp);
    }
    setDocumentSectionData({ ...docuData });
  };

  const setUrlMapHandler = (e: any) => {
    setUrlMap({ ...e[selectedDocument.value] });
    // let docuData: any = { ...documentSectionData };
    // docuData[selectedDocument.value].urlMap = { ...e[selectedDocument.value] };
    // setDocumentSectionData({ ...docuData });
  };

  const getTotalPageCountBasedOnDocument = () => {
    let docuData: any = { ...documentSectionData };
    let countArr: any[] = [];
    if (
      docuData &&
      selectedDocument.value &&
      docuData[selectedDocument.value] &&
      docuData[selectedDocument.value].sections
    ) {
      docuData[selectedDocument.value].sections.forEach((section: any) => {
        if (section.to_page && section.from_page) {
          if (section.from_page > section.to_page) {
            for (
              let index = section.from_page;
              index <= section.to_page;
              index--
            ) {
              countArr.push(+index);
            }
          }

          if (section.to_page > section.from_page) {
            for (
              let index = section.from_page;
              index <= section.to_page;
              index++
            ) {
              countArr.push(+index);
            }
          }
        }
      });
    }
    countArr = new Set(countArr) as any;
    countArr = [...countArr];
    return countArr.length ? Math.max.apply(Math, [...countArr]) : 0;
  };

  const confirmCategoryUpdateHandler = async (selectedOption: any) => {
    try {
      saveKnowledge(selectedOption);
    } catch (error: any) {}
  };

  const saveKnowledge = async (selectedOption: string) => {
    if (!selectedOption) {
      helper.showToaster('Please choose a module before saving.')
      return
    }
    setModalShow(false);
    try {
      await setLoading(true);
      if (selectedOption) {
        await dispatch(bulkAssignModules(selectedOption, documents.map((document: any) => document._id), 'document', false));
      }
      saveSectionDetails(
        false,
        false,
        selectedDocumentIndex + 1,
        true
      );
    } catch (error: any) {
      setModalShow(false);
      await setLoading(false);
    }
    return;
  };

  const saveKnowledgeWithoutOption = async () => {
    try {
      await setLoading(true);
      // if (selectedOption) {
      //   await dispatch(bulkAssignModules(selectedOption, documents.map((document: any) => document._id), 'document', false));
      // }
      saveSectionDetails(
        false,
        false,
        selectedDocumentIndex + 1,
        true
      );
    } catch (error: any) {
      setModalShow(false);
      await setLoading(false);
    }
    return;
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <React.Fragment>

    <MoveSelectedModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        confirmCategoryUpdate={confirmCategoryUpdateHandler}
      />

      <section className="page-mid-wraper">
        <Container>
          {!props.hideHeaders && (
            <>
              <div className="row">
                <div className="col-md-12">
                  <button
                    className="btn p-0 btn-back"
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    <Image src={backblue} />
                    {t("Return to previous page")}
                  </button>
                </div>
              </div>
              <div className="d-flex flex-wrap justify-content-between">
                <h1 className="mb-2">{t("Section Your Document")}</h1>
              </div>
              <div className="mb-32 font-16">
                {t(
                  "Omit pages from the document that you do not want included in the question generation by excluding them from the sections listed below."
                )}
                <br />
                {t(
                  "(Eg: title pages, references, table of contents should all be excluded for better question generation)"
                )}
              </div>
            </>
          )}
          <div className="pdf-section-wrapper">
            {/* {uploadedDocumentIds.map((documentId, index) => ( */}
            <>
              <div className="row mb-3">
                <div className="col-md-5 font-16 font-weight-bold clr-black">
                  {/* {urlMap && <>{urlMap[documentId]?.name}</>} */}
                  <Select
                    className="select-target px-0 select-blue shadow-none"
                    classNamePrefix="select-target-prefix"
                    value={selectedDocument}
                    // isDisabled
                    components={{
                      DropdownIndicator: () => (
                        <img src={downarrowblue} alt="" />
                      ),
                      IndicatorSeparator: () => null,
                      ClearIndicator: () => null,
                    }}
                    onChange={(e) => {
                      console.log(e);
                      saveSectionDetails(false, false, e.documentIndex);
                    }}
                    placeholder={t("Document")}
                    options={documents}
                  />
                </div>
                {/* <div className="col-md-7 mt-3 mt-md-0">
                  <div className="d-flex align-items-center font-16 info-doc-msg radius-8 py-2 px-3">
                    <Image src={infoImg} alt="" className="mr-3" />
                    {t(
                      "Please ensure that you do not have more than 20 pages per document."
                    )}
                  </div>
                </div> */}
              </div>

              {selectedDocument && selectedDocument.value && (
                <div
                  key={selectedDocument.value}
                  //eventKey={index.toString()}
                  title={
                    urlMap && urlMap[selectedDocument.value]
                      ? urlMap[selectedDocument.value].name
                      : `Document ${selectedDocument.value}`
                  }
                >
                  <div className="document-section-wrapper row">
                    <div className="col-md-5">
                      {documentSectionData &&
                        documentSectionData[selectedDocument.value] && (
                          <PDFViewer
                            setUrlMapForParent={setUrlMapHandler}
                            getNoOfPages={getNoOfPagesHandler}
                            documentId={selectedDocument.value}
                            multiDoc
                          />
                        )}
                    </div>
                    <div className="col-md-7 mt-3 mt-md-0">
                      <div className="section-pages-data">
                        {/* urlMap[documentId].type &&
                            urlMap[documentId].type === "pdf" && */}
                        {selectedDocument &&
                          (selectedDocument.name.includes(".html") ||
                            selectedDocument.name.includes(".txt")) && (
                            <div className="empty-doc-msg d-flex align-items-center justify-content-center">
                              <div className="text-center">
                                <h3>
                                  {t(
                                    "You are not required to section this document"
                                  )}
                                </h3>
                                <figure className="mt-3 mb-0 empty-img-wrap">
                                  <img src={noSectionRequired} alt="" />
                                </figure>
                              </div>
                            </div>
                          )}

                        {documentSectionData &&
                          documentSectionData[selectedDocument.value] &&
                          !(
                            selectedDocument.name.includes(".html") ||
                            selectedDocument.name.includes(".txt")
                          ) &&
                          ("urlMap" in
                            documentSectionData[selectedDocument.value] ||
                            "sections" in
                              documentSectionData[selectedDocument.value]) && (
                            <React.Fragment>
                              <div className="section-pages-input-container">
                                {"urlMap" in
                                  documentSectionData[selectedDocument.value] &&
                                  documentSectionData[selectedDocument.value]
                                    .urlMap.type &&
                                  documentSectionData[selectedDocument.value]
                                    .urlMap.type !== "pdf" &&
                                  documentSectionData[selectedDocument.value]
                                    .urlMap.type !== "docx" && (
                                    <div className="section-pages-no-input-container mb-3">
                                      {t(
                                        "Dividing documents into sections is currently"
                                      )}{" "}
                                      <strong>
                                        {t(
                                          "only supported for PDF type documents"
                                        )}
                                      </strong>
                                      {/* . If you have uploaded multiple documents,
                                        you can add sections for PDF type documents
                                        or click on "Save Document(s)" to proceed to
                                        next step. */}
                                    </div>
                                  )}

                                {/* <h6>
                                  Total Page Count:{" "}
                                  <span className={notReadyToSummarised ? "clr-error": ""}>
                                  {getTotalPageCountBasedOnDocument()}/20
                                  </span>
                                </h6> */}

                                {"sections" in
                                  documentSectionData[selectedDocument.value] &&
                                  documentSectionData[
                                    selectedDocument.value
                                  ]?.sections.map(
                                    (sectionDetails: any, index: any) => (
                                      <div
                                        key={sectionDetails.id}
                                        className="section-pages-input-row"
                                      >
                                        <div>
                                          {index === 0 && <h6>#</h6>}
                                          <span
                                            className={`page-number page-top-margin`}
                                          >
                                            {index + 1}.
                                          </span>
                                        </div>
                                        {renderInputFields(
                                          index,
                                          sectionDetails,
                                          selectedDocument.value,
                                          documentSectionData &&
                                            documentSectionData[
                                              selectedDocument.value
                                            ] &&
                                            documentSectionData[
                                              selectedDocument.value
                                            ].type &&
                                            documentSectionData[
                                              selectedDocument.value
                                            ].type !== "pdf" &&
                                            documentSectionData[
                                              selectedDocument.value
                                            ].type !== "docx"
                                        )}
                                      </div>
                                    )
                                  )}

                                {selectedDocument &&
                                  (selectedDocument.label.includes(".pdf") ||
                                    selectedDocument.label.includes(
                                      "docx"
                                    )) && (
                                    <PermissionWrapper>
                                      <div className="text-center">
                                        <button
                                          onClick={() =>
                                            addSectionInputRow(
                                              selectedDocument.value
                                            )
                                          }
                                          className="btn btn-link genurl-btn"
                                        >
                                          {t("Add another section")}
                                        </button>
                                      </div>
                                    </PermissionWrapper>
                                  )}
                              </div>
                            </React.Fragment>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          </div>
        </Container>
      </section>

      <footer>
        <Container>
          <div className="d-flex align-items-center flex-wrap justify-content-between">
            <div
              className={classNames({
                "d-flex align-items-center font-16 font-600 radius-8 py-2 px-3":
                  true,
                "info-moredoc-msg": notReadyToSummarised,
              })}
            >
              {notReadyToSummarised && (
                <>
                  <Image src={infoCircleImg} alt="" className="mr-3" />
                  {t(
                    "Current document does not meet requirement."
                  )}
                  <br />
                  {t(
                    "Please ensure that total page count does not exceed 20 pages"
                  )}
                </>
              )}
            </div>
            <PermissionWrapper>
              <div>
                <button
                  className={classNames({
                    "btn mr-3 font-weight-bold": true,
                    "btn-light": !documents[selectedDocumentIndex - 1],
                    "btn-outline-primary": documents[selectedDocumentIndex - 1],
                  })}
                  onClick={() => {
                    if (documents[selectedDocumentIndex - 1]) {
                      saveSectionDetails(
                        false,
                        false,
                        selectedDocumentIndex - 1
                      );
                    }
                  }}
                >
                  {t("Previous Document")}
                </button>
                {documents[selectedDocumentIndex + 1] && (
                  <button
                    className="btn btn-primary mr-3"
                    disabled={!documents[selectedDocumentIndex + 1]}
                    onClick={() => {
                      saveSectionDetails(
                        false,
                        false,
                        selectedDocumentIndex + 1
                      );
                    }}
                  >
                    {t("Next Document")}
                  </button>
                )}
                {!documents[selectedDocumentIndex + 1] && (
                  <button
                    className="btn btn-primary mr-3"
                    onClick={() => throughDocument ? saveKnowledgeWithoutOption() : setModalShow(true)}
                    // onClick={() => {
                    //   saveSectionDetails(
                    //     false,
                    //     false,
                    //     selectedDocumentIndex + 1,
                    //     true
                    //   );
                    // }}
                  >
                    {t("Save to Knowledge Base")}
                  </button>
                )}
              </div>
            </PermissionWrapper>
          </div>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default MultiDocumentSectionsKandra;
