import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  getLinkedIntentsBasedOnQuizAndQuestions,
  getLinkedQuestionsBasedOnQuizAndQuestions,
} from "../../../store/quizzes/actions";
import { useTranslation } from "react-i18next";
import ScoreRange from "../ScoreRange";
import classNames from "classnames";

function FeedbackAndTutoring(props: any) {
  
  const [conditions, setConditions] = useState([]);
  const [quizQuestions, setQuizQuestions] = useState([]);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (props.conditions && props.conditions.length) {
      setConditions([...props.conditions]);
    } else {
      setConditions([]);
    }

    if (
      props.transformedQuestions &&
      Object.keys(props.transformedQuestions).length > 0
    ) {
      const quizzes: any[] = [];
      Object.keys(props.transformedQuestions).map((questionId: any) => {
        if (props.transformedQuestions[questionId].id) {
          quizzes.push(props.transformedQuestions[questionId].id);
        }
      });
      setQuizQuestions([...quizzes]);
    }
    return () => {};
  }, [props]);

  // const fetchAllLinkedQuizQuestions = async () => {
  //   try {
  //     await dispatch(getLinkedQuestionsBasedOnQuizAndQuestions(props.quizId));
  //     props.getQuiz(props.quizId);
  //     // for await (const question of quizQuestions) {
  //     //   const linkedKnowledge: any = await dispatch(getLinkedIntentsBasedOnQuizAndQuestions(props.quizId, question));
  //     //   console.log('[ linkedKnowledge ]', linkedKnowledge)
  //     // }
  //   } catch (error) {
  //     console.log('[ error ]', error)
  //   }
  // }

  return (
    <>
      <div className="d-flex flex-wrap justify-content-between mb-24 align-items-start">
        <div>
          <h2 className="mb-0">{t("Feedback & Tutoring")}</h2>
          <div className="font-16">
            {t(
              "Create tutoring link and custom feedback for learner to review mistakes"
            )}
          </div>
        </div>

        {conditions.length > 0 && (
          <button
            className="btn btn-outline-primary ml-auto clr-primary font-600"
            onClick={props.fetchAllLinkedQuizQuestions}
          >
            {t("Check for unlinked quiz questions")}
          </button>
        )}

        <button
          className={classNames({
            btn: true,
            "ml-2 btn-outline-primary": true, 
            // "btn-light": conditions.length === 0,
            // "btn-outline-primary": conditions.length > 0,
          })}
          // disabled={conditions.length === 0}
          onClick={props.updateCombinedQuizSettings}
        >
          {t("Save changes")}
        </button>
        {/* props.updateCombinedQuizSettings */}
      </div>
      <Row>
        <div className="mb-32 w-100">
          {conditions.length === 0 && (
            <div className="page-banner bg-page-wrapper text-center radius-8">
              <h2>
                {t(
                  "It looks like your quiz question & answers have not been linked. Click below to link them to your knowledge."
                )}
              </h2>
              <button
                onClick={props.fetchAllLinkedQuizQuestions}
                className="btn btn-primary font-600 mt-2"
              >
                {t("Link quiz questions to knowledge")}
              </button>
            </div>
          )}

          <div>
            {/* <div className="d-flex flex-wrap justify-content-between mb-3 align-items-center">
              <label className="font-16 font-600 mb-0">
                {t('Post Quiz Response')}
              </label>
              <button
                className="btn clr-primary font-600 py-0"
                onClick={() => {
                  props.addScoreRangeCondition(props.isFeedback);
                }}
              >
                {" "}
                + {t('Add Another Response')}
              </button>
            </div> */}
            {conditions.map((item, index) => {
              return (
                <ScoreRange
                  isFeedback={props.isFeedback}
                  key={item.id ? item.id : index.toString()}
                  buttonsData={props.transformData(
                    item.show.buttons ? item.show.buttons : []
                  )}
                  buttonOptions={props.buttonOptions}
                  quizQuestionDropdownData={props.quizQuestionDropdownData}
                  conditionData={item}
                  conditionIndex={index}
                  removeScoreCondition={props.removeScoreCondition}
                  onConditionFieldChange={props.onConditionFieldChange}
                  onConditionQuestionDropdownChange={
                    props.onConditionQuestionDropdownChange
                  }
                  onButtonAdd={props.onButtonAdd}
                  topicDropdownData={props.topicDropdownData}
                  userGroupData={props.userGroupData}
                  onButtonLabelEdit={props.onButtonLabelEdit}
                  onButtonQuestionSelect={props.onButtonQuestionSelect}
                  onSort={props.onSort}
                  deleteButtonRow={props.deleteButtonRow}
                  questionIdLabelMap={props.questionIdLabelMap}
                  totalQuizScore={props.totalQuizScore}
                  hasIdPresent={item.id}
                  quizId={props.quizId}
                />
              );
            })}
          </div>
        </div>
      </Row>
    </>
  );
}

export default FeedbackAndTutoring;
