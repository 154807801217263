import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import "./style.scss";
import delIcon from "../../../assets/New-images/close-icon-red.svg";
import editIcon from "../../../assets/New-images/editIcon.svg";
import copyIcon from "../../../assets/images/copy.svg";
import greenCheck from "../../../assets/images/single-green-check.svg";

const DocRow = (props: any) => {
  const { doc, docRefs, isEditing, index } = props;
  const [startPage, setStartPage] = useState(
    isEditing ? doc.sections?.[0]?.from_page : 1
  );
  const [endPage, setEndPage] = useState(
    isEditing ? doc.sections?.[0]?.to_page : 1
  );
  const [isEditPage, setIsEditPage] = useState(false);
  const { t } = useTranslation("translation");
  const selected = props?.selectedId === doc._id;

  const addRef = () => {
    props.setDocRefs([
      ...docRefs,
      {
        ...doc,
        document_id: doc._id,
        document: {
          knowledge_groups: { 
            ...doc?.knowledge_groups
          },
          name: doc.name
        },
        sections: [
          {
            from_page: parseInt(startPage),
            to_page: parseInt(endPage),
          },
        ],
      },
    ]);
  };

  const cloneRef = () => {
    props.setDocRefs([
      ...docRefs,
      {
        ...doc,
        document_id: doc._id,
        sections: [
          {
            from_page: parseInt(startPage),
            to_page: parseInt(endPage),
          },
        ],
      },
    ]);
  };

  const updateRef = () => {
    props.setDocRefs(
      docRefs.map((docRef: any, i: number) =>
        i === props?.index
          ? {
              ...docRef,
              sections: [
                {
                  from_page: parseInt(startPage),
                  to_page: parseInt(endPage),
                },
              ],
            }
          : docRef
      )
    );
  };

  const delRef = () => {
    props.setDocRefs(
      docRefs.filter((docRef: any, i: number) => i !== props?.index)
    );
  };

  return (
    <div
      className={cx({
        "py-3 row my-4 mx-0 doc-row": true,
        selected: selected || isEditing,
      })}
    >
      <div className="col-lg-6 col-xl-8 ">
        <div className="title ref-table-col-1 clr-grey-txt7">
          {doc?.knowledge_groups?.[0]?.name}
          {doc?.knowledge_groups?.[1]?.name
            ? " / " + doc?.knowledge_groups?.[1]?.name
            : ""}
        </div>
        <div className="ref-table-col-1">{doc?.name}</div>
      </div>
      {selected && (
        <div className="col-lg-6 col-xl-4 d-flex page-select">
          <input
            type="number"
            className="page-input"
            min={1}
            value={startPage}
            onChange={(e: any) => {
              setStartPage(parseInt(e.target.value))
            }}
          />
          <span className="px-1"> - </span>
          <input
            type="number"
            className="page-input"
            min={1}
            value={endPage}
            onChange={(e: any) => setEndPage(parseInt(e.target.value))}
          />
          <button
            disabled={endPage < startPage}
            className="ml-2 btn btn-primary add-page"
            onClick={() => addRef()}
          >
            {t("Add")}
          </button>
        </div>
      )}
      {isEditing && (
        <>
          {!isEditPage && (
            <div className="col-lg-3 col-xl-2 d-flex page-select flex-column">
              <div className="clr-grey-txt7">{t("Pages")}</div>
              <div>
                {startPage} - {endPage}
              </div>
            </div>
          )}
          {isEditPage && (
            <div className="col-lg-3 col-xl-2 d-flex page-select">
              <input
                type="number"
                className="page-input"
                min={1}
                value={startPage}
                onChange={(e: any) => setStartPage(parseInt(e.target.value))}
              />
              <span className="px-1"> - </span>
              <input
                type="number"
                className="page-input"
                min={1}
                value={endPage}
                onChange={(e: any) => setEndPage(parseInt(e.target.value))}
              />
            </div>
          )}
          <div className="col-lg-3 col-xl-2 d-flex page-select justify-content-around align-items-center">
            {!isEditPage && (
              <img
                className="cursor-pointer"
                alt="edit"
                src={editIcon}
                onClick={() => {
                  setIsEditPage(true);
                }}
              />
            )}
            {!isEditPage && (
              <img
                className="cursor-pointer"
                alt="copy"
                src={copyIcon}
                onClick={() => cloneRef()}
              />
            )}
            {isEditPage && (
              <img
                className="cursor-pointer"
                alt="confirm"
                src={greenCheck}
                onClick={() => {
                  setIsEditPage(false);
                  updateRef();
                }}
              />
            )}
            <img
              className="cursor-pointer"
              alt="del"
              src={delIcon}
              onClick={() => {
                if (isEditPage) {
                  setIsEditPage(false);
                  setStartPage(doc.sections?.[0]?.from_page);
                  setEndPage(doc.sections?.[0]?.to_page);
                } else {
                  delRef();
                }
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default DocRow;
