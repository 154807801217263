export const customStyles = {
  header: {
    style: {
      minHeight: "56px",
    },
  },
  headRow: {
    style: {
      borderRadius: "10px",
      backgroundColor: "#0923E6",
      borderBottomWidth: "0",
    },
  },
  expanderRow: {
    style: {
      // backgroundColor: '#0923E6',
    },
  },
  expanderCell: {
    style: {
      "&:hover": {
        backgroundColor: "transparent",
      },
      button: {
        backgroundColor: "transparent !important",
        img: {
          width: "10px",
        },
      },
    },
  },
  expanderButton: {
    style: {
      backgroundColor: "transparent",
      alignItems: "center",
    },
  },
  headCells: {
    style: {
      "&:first-child": {
        borderTopLeftRadius: "5px",
        borderBottomLeftRadius: "0px",
      },
      "&.chart--table-sort-icon": {
        marginLeft: "10px",
      },
      'input[type="checkbox"]': {
        width: "16px",
        height: "16px",
      },
      backgroundColor: "#0923E6",
      padding: "20px 20px",
      fontSize: "14px",
      color: "#fff",
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "#FFFFFF",
      },
      div: {
        color: "#fff",
      },
      "&:nth-child(1)": {
        minWidth: "245px",
      },
      "&:nth-child(2)": {
        minWidth: "500px",
      },
      "&:nth-child(3)": {
        minWidth: "120px",
      },
      "&:nth-child(4)": {
        minWidth: "140px",
        // div: {
        //   textOverflow: 'ellipsis',
        //   display: ' -webkit-box',
        //   WebkitLineClamp: '2',
        //   WebkitBoxOrient: 'vertical',
        // },
      },
    },
  },
  cells: {
    style: {
      color: "#07032B",
      fontSize: "14px",
      paddingTop: "10px",
      paddingBottom: "10px",
      'input[type="checkbox"]': {
        width: "16px",
        height: "16px",
      },
      "&:nth-child(1)": {
        minWidth: "245px",
      },
      "&:nth-child(2)": {
        minWidth: "500px",
      },
      "&:nth-child(3)": {
        minWidth: "120px",
        justifyContent: "center",
      },
      "&:nth-child(4)": {
        minWidth: "140px",
        div: {
          textOverflow: "ellipsis",
          display: " -webkit-box",
          WebkitLineClamp: "2",
          WebkitBoxOrient: "vertical",
        },
      },
    },
  },
};

export const openAiCustomStyles = {
  header: {
    style: {
      minHeight: "56px",
    },
  },
  headRow: {
    style: {
      borderRadius: "10px",
      backgroundColor: "#0923E6",
      borderBottomWidth: "0",
    },
  },
  expanderRow: {
    style: {
      // backgroundColor: '#0923E6',
    },
  },
  expanderCell: {
    style: {
      "&:hover": {
        backgroundColor: "transparent",
      },
      button: {
        backgroundColor: "transparent !important",
        img: {
          width: "10px",
        },
      },
    },
  },
  expanderButton: {
    style: {
      backgroundColor: "transparent",
      alignItems: "center",
    },
  },
  headCells: {
    style: {
      "&:first-child": {
        borderTopLeftRadius: "5px",
        borderBottomLeftRadius: "0px",
      },
      "&.chart--table-sort-icon": {
        marginLeft: "10px",
      },
      'input[type="checkbox"]': {
        width: "16px",
        height: "16px",
      },
      backgroundColor: "#0923E6",
      padding: "20px 20px",
      fontSize: "14px",
      color: "#fff",
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "#FFFFFF",
      },
      div: {
        color: "#fff",
      },
      "&:nth-child(1)": {
        minWidth: "400px",
      },
      "&:nth-child(2)": {
        minWidth: "400px",
      },
      "&:nth-child(3)": {
        minWidth: "160px",
      },
      "&:nth-child(4)": {
        minWidth: "100px",
        maxWidth: "100px",
        // div: {
        //   textOverflow: 'ellipsis',
        //   display: ' -webkit-box',
        //   WebkitLineClamp: '2',
        //   WebkitBoxOrient: 'vertical',
        // },
      },
    },
  },
  rows: {
    style: {
      minHeight: "auto",
    },
  },
  cells: {
    style: {
      color: "#07032B",
      fontSize: "14px",
      paddingTop: "10px",
      paddingBottom: "10px",
      'input[type="checkbox"]': {
        width: "16px",
        height: "16px",
      },
      "&:nth-child(1)": {
        minWidth: "400px",
      },
      "&:nth-child(2)": {
        minWidth: "400px",
      },
      "&:nth-child(3)": {
        minWidth: "160px",
      },
      "&:nth-child(4)": {
        minWidth: "100px",
        maxWidth: "100px",
        div: {
          textOverflow: "ellipsis",
          display: " -webkit-box",
          WebkitLineClamp: "2",
          WebkitBoxOrient: "vertical",
        },
      },
    },
  },
};

export const conditionalRowStyles = [
  {
    when: (row: any) => row.hasOwnProperty("intent_id") && row.intent_id,
    style: {
      borderLeft: "3px solid rgb(48, 78, 242)",
    },
  },
];

export const conditionalRowStylesOpenAi = [
  {
    when: (row: any) => row.hasOwnProperty("intent_id") && row.intent_id,
    style: {
      backgroundColor: "#E6EDFF",
    },
  },
];
