import React from "react";
import { Button, Image, Modal, ModalFooter } from "react-bootstrap";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import { useTranslation } from "react-i18next";
import closeimg from "../../../assets/images/close-blk.svg";
import popupimg from "../../../assets/images/pixeltrue-meditation.svg";
import KnowledgeDatatableComponentGridView from "../../../components/UI/KnowledgeDatatableComponent/KnowledgeDatatableComponentGridView";
import AllKnowledge from "../../Knowledge/AllKnowledge/AllKnowledge";

export default function CreateQuestionFromDocument(props: any) {
  const { t } = useTranslation("translation");
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="modal-960"
    >
      <ModalHeader
        className="pt-4 justify-content-between"
        onHide={props.onHide}
      >
        <ModalTitle id="contained-modal-title-vcenter">
          <h2 className="mb-0">{t("Generate from Uploaded Document")}</h2>
          {/* Sub title */}
          <p className="mb-0 font-sm">
            {t(
              "Select the knowledge or document you would like to create questions from"
            )}
          </p>
        </ModalTitle>
        <Button onClick={props.onHide}>
          <Image src={closeimg} />
        </Button>
      </ModalHeader>
      <ModalBody className="know-search-modalbody">
        <KnowledgeDatatableComponentGridView
          onTabChanges={() => {}}
          createFromUploadedDocument
          selectedGroupIdForDocument={props.selectedGroupIdForDocument}
          isEditQuiz={props.isEditQuiz}
          quizId={props.quizId}
          // toolkitSmartQuizAddMoreQue={props.toolkitSmartQuizAddMoreQue}
          queTypes={props.queTypes}
          questionBank={props.questionBank}
          document_Id={props.document_Id}
          addMoreQuestionFromDocument
        />
      </ModalBody>
    </Modal>
  );
}
