import {
  Button,
  Image,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import Select from "react-select";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getAllDocumentsNewFlow,
  getAllLevels,
  getAllModulesFromYear,
} from "../../store/knowledge/actions";
import closeimg from "../../assets/images/close-blk.svg";
import yellowBulb from "../../assets/images/yellow-bulb.svg";
import { LinkedDocument } from "../../types/conversation";

const AddDocumentModal = (props: any) => {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const [years, setYears] = useState([]);
  const [modules, setModules] = useState([]);
  const [topics, setTopics] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [documentFilter, setDocumentFilter] = useState("");

  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedModule, setSelectedModule] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState(null);

  const [isYearsLoading, setYearsLoading] = useState(true);
  const [isModuleLoading, setModuleLoading] = useState(true);

  useEffect(() => {
    if (props.show === false) {
      setSelectedYear(null);
      setSelectedModule(null);
      setSelectedTopic(null);
      setDocumentFilter("");
      setDocuments([]);
    }
  }, [props.show]);

  const getDocuments = async (id: string) => {
    const { documents: _documents }: any = await dispatch(
      getAllDocumentsNewFlow(
        {
          page: 1,
          page_size: 10000000,
          knowledge_group_ids: id,
          includes_all_subgroups: true,
        },
        props.hasTopLevelApplied,
        props.isArchived
      )
    );

    setDocuments([..._documents]);
  };

  useEffect(() => {
    if (!props.selectedDocuments) return;
    setSelectedDocuments(props.selectedDocuments);
  }, [props.selectedDocuments]);

  const getAllModulesFromYearFun = async (year: any) => {
    setModuleLoading(true);
    try {
      const response: any = await dispatch(getAllModulesFromYear(year._id));
      setModules([...response]);
      setModuleLoading(false);
    } catch (error: any) {
      setModules([]);
    }
  };

  const getAllYearsFun = async (loadSubModules: boolean = true) => {
    setYearsLoading(true);
    try {
      const response: any = await dispatch(getAllLevels());
      setYears([...response.filter((res: any) => res.group_type !== "system")]);
      response &&
        response.length > 0 &&
        getAllModulesFromYearFun(selectedYear ? selectedYear : response[0]);
      setYearsLoading(false);
    } catch (error: any) {
      setYears([]);
    }
  };

  useEffect(() => {
    setSelectedModule(null);
    setSelectedTopic(null);

    if (selectedYear) getDocuments(selectedYear.value);
  }, [selectedYear]);

  useEffect(() => {
    if (!selectedModule) return;

    const mod = modules.find((module) => module._id === selectedModule?.value);
    getDocuments(selectedModule.value);
    if (mod.children.length === 0) {
      setTopics([]);
      return;
    }

    setTopics(
      modules.filter((module) => module.parent_id === selectedModule?.value)
    );
  }, [selectedModule]);

  useEffect(() => {
    if (!selectedTopic) return;
    getDocuments(selectedTopic.value);
  }, [selectedTopic]);

  useEffect(() => {
    getAllYearsFun();
  }, []);

  return (
    <Modal
      className="add-document-modal"
      show={props.show}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <ModalHeader
        className={cx({
          "pt-3": true,
          "justify-content-between": true,
          "px-4": true,
        })}
        onHide={props.onHide}
      >
        <div>
          <div style={{ fontSize: 18 }}>{t("Add Document(s)")}</div>
          <div className="mt-1">
            {t("Choose documents to link with your key phrases")}
          </div>
        </div>
        <Button onClick={props.onHide}>
          <Image src={closeimg} />
        </Button>
      </ModalHeader>
      <ModalBody className="document-modal-body">
        <Row>
          <div className="col-md-4">
            <Select
              className="select-sort select-target select-blue shadow-none radius-8"
              classNamePrefix="select-target-prefix"
              isSearchable={false}
              components={{
                IndicatorSeparator: () => null,
              }}
              placeholder={t("Year")}
              options={years.map((year) => ({
                value: year._id,
                label: year.name,
              }))}
              onChange={(e: any) => {
                setSelectedYear(e);
                getAllModulesFromYearFun(
                  years.find((year) => year._id === e.value)
                );
              }}
              value={selectedYear ? selectedYear : null}
              isDisabled={isYearsLoading}
            />
          </div>
          <div className="col-md-4">
            <Select
              className="select-sort select-target select-blue shadow-none radius-8"
              classNamePrefix="select-target-prefix"
              isSearchable={false}
              components={{
                IndicatorSeparator: () => null,
              }}
              placeholder={t("Module")}
              options={modules
                .filter((module) => module.depth === 0)
                .map((module) => ({
                  value: module._id,
                  label: module.name,
                }))}
              onChange={(e: any) => {
                setSelectedTopic(null);
                setSelectedModule(e);
              }}
              value={selectedModule ? selectedModule : null}
              isDisabled={isModuleLoading}
            />
          </div>
          <div className="col-md-4">
            <Select
              className="select-sort select-target select-blue shadow-none radius-8"
              classNamePrefix="select-target-prefix"
              isSearchable={false}
              components={{
                IndicatorSeparator: () => null,
              }}
              placeholder={t("Topics")}
              options={topics}
              onChange={(e: any) => {
                setSelectedTopic(e);
              }}
              value={selectedTopic ? selectedTopic : null}
            />
          </div>
        </Row>
        <Row>
          <div className="col-md-12">
            <div className="page-warning">
              <img src={yellowBulb} alt="yellowBulb" className="pr-3" />
              <span>
                {t(
                  "The maximum number of documents you may link to a key phrase is 5"
                )}
              </span>
            </div>
          </div>
        </Row>
        <Row className="mt-2">
          <div className="col-md-12 web-links">
            <input
              type="text"
              className="form-control h-auto search-keyphrase-input"
              placeholder={t("Search Documents")}
              value={documentFilter}
              onChange={(e) => setDocumentFilter(e.currentTarget.value)}
            />
          </div>
        </Row>
        <Row className="document-items-container">
          <div className="col-md-12">
            {documents
              .filter(
                (document) =>
                  document.name
                    .toLowerCase()
                    .indexOf(documentFilter.toLowerCase()) !== -1
              )
              .map((document) => {
                const isActive = selectedDocuments.find(
                  (sel: LinkedDocument) => sel._id === document._id
                );
                return (
                  <div
                    className={cx(
                      "document-item px-4 py-2",
                      isActive ? "active" : ""
                    )}
                    onClick={() => {
                      if (isActive) return;
                      if (selectedDocuments.length === 5) return;
                      setSelectedDocuments([
                        ...selectedDocuments,
                        {
                          _id: document._id,
                          name: document.name,
                        },
                      ]);
                    }}
                  >
                    <div className="d-flex flex-column">
                      <div className="document-item-breadcrumb">{document.breadcrumbs.map((breadcrumb: any) => breadcrumb.name).join("/")}</div>
                      <div>{document.name}</div>
                    </div>
                    {isActive && (
                      <button
                        className="btn ml-auto p-0"
                        onClick={(e) => {
                          setSelectedDocuments(
                            selectedDocuments.filter(
                              (sel) => sel._id !== document._id
                            )
                          );
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      >
                        <Image src={closeimg} alt="" />
                      </button>
                    )}
                  </div>
                );
              })}
          </div>
        </Row>
      </ModalBody>
      <ModalFooter className="border-0 justify-content-end px-3 flex-nowrap">
        <button
          className="btn-outline-primary btn mx-2"
          onClick={() => props.onHide()}
        >
          {t("Cancel")}
        </button>
        <button
          className="btn-primary btn mx-2"
          onClick={() => {
            props.onAdd(selectedDocuments);
          }}
        >
          {t("Add Document")}
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default AddDocumentModal;
