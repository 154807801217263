import React, { useEffect, useState } from 'react'
import { Image } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import closeimg from '../../../../assets/images/close-red.svg'
import QuestionListSkeleton from '../../../../components/UI/Skeletons/QuestionListSkeleton/QuestionListSkeletons'
import * as type from '../../../../store/actionType'
import {
  loadMoreKeywordForDocument,
  updateDocumentAfterTimeout,
} from '../../../../store/knowledge/actions'
import AutoTextArea from '../../../../components/UI/AutoResizeTextArea/AutoResizeTextArea'
import cx from 'classnames';

import { useTranslation } from "react-i18next";

interface Props {
  agent_id?: string
  author_id?: string
  created_at?: string
  file_id?: string
  file_name?: string
  keywords?: string[]
  keywordsQnas?: any[]
  name?: string
  organisation_id?: string
  qas?: string
  updated_at?: string
  _id?: string
  index?: number
  last_offset?: number
  documentLength?: number
  keywordsLoading?: boolean
  keywordFetchError?: boolean
  retryExecution?: boolean
  updatedQuestions: (data: any) => void
}

const QuestionsList = (props: Props) => {
  const { t } = useTranslation('translation');
  const dispatch = useDispatch()
  const [allKeywords, setKeywords] = useState(props.keywords)
  const [allKeywordsQnas, setKeywordsQnas] = useState(props.keywordsQnas)
  const [lastOffset, setLastOffset] = useState(0)
  const [executionStarted, setExecutionStarted] = useState(false)
  const [InternalRef, setInternalRef] = useState(null)
  const [keywordsLoading, setKeywordsLoading] = useState(true)
  const [keywordFetchError, setKeywordFetchError] = useState(false)
  const [addNewKeyword, setAddNewKeyword] = useState({
    value: '',
    show: false,
  })

  useEffect(() => {
    return () => {
      if (InternalRef) {
        clearInterval(InternalRef)
      }
    }
  }, [])

  useEffect(() => {
    if ( props.keywords && props.keywords.length > 0 && !props.retryExecution && !executionStarted ) {
      setKeywordsLoading( false )
    }
    setKeywords(props.keywords)
    setKeywordsQnas(props.keywordsQnas)
  }, [props.keywords])

  useEffect(() => {
    setKeywordFetchError(props.keywordFetchError)
  }, [props.keywordFetchError])

  // useEffect(() => {
  //   setKeywordsLoading(props.keywordsLoading)
  // }, [props.keywordsLoading])

  useEffect(() => {
    setLastOffset(props.last_offset ? props.last_offset : lastOffset)
  }, [props.last_offset])

  useEffect(() => {
    if (props.retryExecution && !executionStarted) {
      const inititateInterval = async (props: any) => {
        let intervalCount = 0
        let lastOffset = props.last_offset
        let fetchedKeywords = [...props.keywords]
        let fetchedKeywordsQnas = [...props.keywordsQnas]
        let intervalRef = setInterval(async () => {
          intervalCount = intervalCount + 1
          await setExecutionStarted(true)
          if (intervalCount === 5) {
            clearInterval(intervalRef)
            setKeywordsLoading(false)
            // dispatch(updateDocumentAfterTimeout(props))
            props.updatedQuestions({
              keywordFetchError: fetchedKeywords.length === 0,
              documentId: props._id,
              lastOffset: lastOffset
            })
            return;
          }
          try {
            setKeywordsLoading(true)
            // console.log('INTERVAL startded', fetchedKeywords, 'intervalCount', intervalCount)
            const lastOffsetFetched: any = await dispatch(
              loadMoreKeywordForDocument(props, lastOffset, 10)
            )
            fetchedKeywords = [
              ...fetchedKeywords.concat(lastOffsetFetched.keywords),
            ]
            fetchedKeywordsQnas = [
              ...fetchedKeywordsQnas.concat(lastOffsetFetched.keywordsQnas),
            ]
            setKeywords(fetchedKeywords)
            setKeywordsQnas(fetchedKeywordsQnas)
            props.updatedQuestions({
              ...lastOffsetFetched,
              keywords: fetchedKeywords,
              keywordsQnas: fetchedKeywordsQnas,
            })
            setKeywordFetchError(lastOffsetFetched.keywordFetchError)
            lastOffset = lastOffsetFetched.last_offset
            setLastOffset(lastOffset)
            if (lastOffsetFetched.questionsLength > 0) {
              setKeywordsLoading(false)
              clearInterval(intervalRef)
            }
          } catch (error: any) {
            // console.log('INTERVAL error', error)
            props.updatedQuestions(error)
            setKeywordsLoading(false)
            setKeywordFetchError(true) // error.keywordFetchError
            clearInterval(intervalRef)
          }
          // console.log(`[ CALLED IN every 5 seconds ] :::>>> ${props._id}`, oldCount)
        }, 10000)
        setInternalRef(intervalRef)
      }
      inititateInterval(props)
    }
  }, [props.retryExecution])

  const removeKeywordFromList = (index: number) => {
    const oldKeywords = [...allKeywords]
    oldKeywords.splice(index, 1)

    const oldkeywordsQnas = [...allKeywordsQnas]
    oldkeywordsQnas.splice(index, 1)
    // dispatch({
    //   type: type.LOCAL_DOCUMENT_UPDATE,
    //   payload: {
    //     documentId: props._id,
    //     keywords: oldKeywords,
    //     keywordFetchError: props.keywordFetchError,
    //   },
    // })
    setKeywords(oldKeywords)
    setKeywordsQnas(oldkeywordsQnas)
    props.updatedQuestions({
      documentId: props._id,
      keywords: oldKeywords,
      keywordsQnas: oldkeywordsQnas,
      keywordFetchError: props.keywordFetchError,
      lastOffset: lastOffset
    })
  }

  const dataChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    const index = +e.target.dataset.index

    const oldKeywords = [...allKeywords]
    oldKeywords[index] = value
    setKeywords(oldKeywords)
    
    const oldkeywordsQnas = [...allKeywordsQnas]
    oldkeywordsQnas[index].question = value
    oldkeywordsQnas[index].isNew = false
    setKeywordsQnas(oldkeywordsQnas)

    props.updatedQuestions({
      documentId: props._id,
      keywords: oldKeywords,
      keywordsQnas: oldkeywordsQnas,
      keywordFetchError: props.keywordFetchError,
      lastOffset: lastOffset
    })
    // dispatch({
    //   type: type.LOCAL_DOCUMENT_UPDATE,
    //   payload: {
    //     documentId: props._id,
    //     keywords: oldKeywords,
    //     keywordFetchError: props.keywordFetchError,
    //   },
    // })
  }

  const addMoreQuestionHandler = (e: any) => {
    e.preventDefault()
    setAddNewKeyword({
      ...addNewKeyword,
      show: true,
    })
  }

  const addMoreQuestionChangeHandler = (e: any) => {
    e.preventDefault()
    setAddNewKeyword({
      ...addNewKeyword,
      value: e.target.value,
    })
  }

  const handleKeyDown = async (e: any) => {
    if (e.key === 'Enter' && addNewKeyword && addNewKeyword.value) {
      const oldKeywords = [...allKeywords]
      oldKeywords.unshift(addNewKeyword.value)
      await setKeywords(oldKeywords)
      setAddNewKeyword({
        ...addNewKeyword,
        value: '',
      })

      const oldkeywordsQnas = [...allKeywordsQnas]
      oldkeywordsQnas.unshift({
        answer: "",
        question: addNewKeyword.value,
        sort_order: 0,
        isNew: true,
      })
      // await setAddNewKeyword({
      //   value: '',
      //   show: false,
      // })
      await setKeywordsQnas(oldkeywordsQnas)

      props.updatedQuestions({
        documentId: props._id,
        keywordFetchError: props.keywordFetchError,
        keywords: oldKeywords,
        keywordsQnas: oldkeywordsQnas,
        lastOffset: lastOffset
      })
    }
  }

  const tryLoadingMoreQuestionHandler = async (e: any) => {
    e.preventDefault()
    // offset is 0 and limit is 10 because we want to fetch data from starting point
    try {
      setKeywordsLoading(true)
      const moreKeywordsFromDocument: any = await dispatch(
        loadMoreKeywordForDocument(props, 0, 10)
      )
      setKeywordsLoading(false)
      setKeywords([...allKeywords.concat(moreKeywordsFromDocument.keywords)])
      setKeywordsQnas([...allKeywordsQnas.concat(moreKeywordsFromDocument.keywordsQnas)])
      setKeywordFetchError(moreKeywordsFromDocument.keywordFetchError)
      setLastOffset(moreKeywordsFromDocument.last_offset)
      props.updatedQuestions({
        ...moreKeywordsFromDocument,
        keywords: allKeywords.concat(moreKeywordsFromDocument.keywords),
        keywordsQnas: allKeywordsQnas.concat(moreKeywordsFromDocument.keywordsQnas),
      })
    } catch (e) {
      setKeywordsLoading(false)
      setKeywords([])
      setKeywordsQnas([])
      setKeywordFetchError(true) // e.keywordFetchError
      props.updatedQuestions(e)
    }
  }

  const loadMoreQuestionsHandler = async (e: any) => {
    e.preventDefault()
    try {
      setKeywordsLoading(true)
      const moreKeywordsFromDocument: any = await dispatch(
        loadMoreKeywordForDocument(props, lastOffset, 10)
      )
      setKeywords([...allKeywords.concat(moreKeywordsFromDocument.keywords)])
      setKeywordsQnas([...allKeywordsQnas.concat(moreKeywordsFromDocument.keywordsQnas)])
      setKeywordsLoading(false)
      setLastOffset(moreKeywordsFromDocument.last_offset)
      setKeywordFetchError(moreKeywordsFromDocument.keywordFetchError)
      props.updatedQuestions({
        ...moreKeywordsFromDocument,
        keywords: allKeywords.concat(moreKeywordsFromDocument.keywords),
        keywordsQnas: allKeywordsQnas.concat(moreKeywordsFromDocument.keywordsQnas),
      })

    } catch (e) {
      setKeywords([])
      setKeywordsLoading(false)
      setKeywordFetchError(true) // e.keywordFetchError
      props.updatedQuestions(e)
    }
  }

  const onFocusOutInputHandler = (e: React.FocusEvent<HTMLInputElement>) => {
    e.preventDefault()
    const element = document.getElementById(e.target.dataset.focusid)
    if (element) {
      element.classList.remove('ques-error')
    }
  }

  const onFocusInputHandler = (e: React.FocusEvent<HTMLInputElement>) => {
    e.preventDefault()
    const element = document.getElementById(e.target.dataset.focusid)
    console.log('e.target.dataset.focusid', e.target.dataset.focusid)
    if (element) {
      element.classList.add('ques-error')
    }
  }

  return (
    <React.Fragment>
      <div className='row'>
        <div className='col-md-12'>
          <div className='d-flex flex-wrap justify-content-between'>
            <h1 className='mb-12'>
              {t("Knowledge from")} {props.file_name}
              <strong className='font-sm ml-3'>
                ({t("Document")} {props.index + 1} {t("of")} {props.documentLength})
              </strong>
            </h1>
            {!keywordsLoading && (
              <div className='font-sm mb-2'>
                {t("Can’t find your question?")}
                <button
                  className='btn btn-link font-sm py-0'
                  onClick={addMoreQuestionHandler}
                >
                  {t("Add now")}
                </button>
              </div>
            )}
          </div>
          <h3 className='font-sm mb-32'>
            {t("The following questions will be added to the virtual assistant knowledge for this document. The answers to the questions will be generated in the next screen.")}
          </h3>
          <div className='d-flex align-items-center doc-count'> </div>
        </div>
      </div>

      <div className='generated-questions'>
        {addNewKeyword.show && (
          <ul
            className='list-unstyled ques-list greeting-row'
            id={props._id + '_KW__add_now'}
            key={props._id + '_KW__add_now'}
          >
            <li>
              {/* <AutoTextArea
              placeholder='Type the question here…'
              name={props._id + '_IP_' + index}
              id={props._id + '_IP__add_now'}
              key={props._id + '_IP__add_now'}
              type='text'
              className='form-control text-area'
              data-index={index}
              data-focusid={props._id + '_KW__add_now'}
              value={keyword || ''}
              onChangeHandler={dataChangeHandler}
              onBlur={onFocusOutInputHandler}
              onFocus={onFocusInputHandler}
              onKeyDown={handleKeyDown}
            /> */}
              <input
                placeholder='Type the question here and press enter…'
                name={props._id + '_IP__add_now'}
                id={props._id + '_IP__add_now'}
                type='text'
                className='form-control'
                data-focusid={props._id + '_KW__add_now'}
                value={addNewKeyword.value || ''}
                onChange={addMoreQuestionChangeHandler}
                onBlur={onFocusOutInputHandler}
                onFocus={onFocusInputHandler}
                onKeyDown={handleKeyDown}
              />
            </li>
          </ul>
        )}

        {keywordsLoading && (
          <QuestionListSkeleton
            count={
              allKeywords && allKeywords.length > 4 ? allKeywords.length + 2 : 4
            }
          />
        )}
        {allKeywords &&
          !keywordsLoading &&
          allKeywordsQnas.map((keyword: {
            answer: string;
            question: string;
            isNew: boolean;
            sort_order: number;
          }, index: number) => {
            return (
              <ul
                className={cx({
                  'list-unstyled ques-list greeting-row': true,
                  'is-new': keyword.isNew
                })}
                id={props._id + '_KW_' + (allKeywords.length - index)}
                key={props._id + '_KW_' + (allKeywords.length - index)}
              >
                <button className='btn py-0'>
                  <Image
                    src={closeimg}
                    onClick={() => removeKeywordFromList(index)}
                  />
                </button>
                <li>
                  {/* <AutoTextArea
                    placeholder='Type the question here…'
                    name={props._id + '_IP_' + index}
                    id={props._id + '_IP_' + (allKeywords.length - index)}
                    key={props._id + '_IP_' + (allKeywords.length - index)}
                    type='text'
                    className='form-control text-area'
                    data-index={index}
                    data-focusid={props._id + '_KW_' + (allKeywords.length - index)}
                    value={keyword || ''}
                    onChangeHandler={dataChangeHandler}
                    onBlur={onFocusOutInputHandler}
                    onFocus={onFocusInputHandler}
                    onKeyDown={handleKeyDown}
                  /> */}
                  <input
                    placeholder={t('Type the question here…')}
                    name={props._id + '_IP_' + (allKeywords.length - index)}
                    id={props._id + '_IP_' + (allKeywords.length - index)}
                    type='text'
                    className='form-control'
                    data-index={index}
                    data-focusid={
                      props._id + '_KW_' + (allKeywords.length - index)
                    }
                    value={keyword.question || ''}
                    onChange={dataChangeHandler}
                    onBlur={onFocusOutInputHandler}
                    onFocus={onFocusInputHandler}
                  />
                </li>
              </ul>
            )
          })}
        {allKeywords && allKeywords.length === 0 && addNewKeyword && addNewKeyword.show && (
          <p>{t("Press Enter to add")}</p>
        )}
        {allKeywords && allKeywords.length > 0 && !keywordFetchError && (
          <button
            className='btn btn-link mb-32 p-0'
            onClick={loadMoreQuestionsHandler}
          >
            {t("Load More Questions")}
          </button>
        )}
      </div>
      {allKeywords && allKeywords.length === 0 && (!addNewKeyword || !addNewKeyword.show) && keywordFetchError && !keywordsLoading && (
        <div className='add-ques-error mb-3'>
          {t('We couldn’t generate questions for this document. Please click on the “Add Now” button to type your own question. Alternatively,')}{' '}
          <button
            className='btn btn-link font-sm py-0'
            onClick={addMoreQuestionHandler}
          >
            {t("click here")}
          </button>{' '}
          {t("to start adding.")}
        </div>
      )}
      {allKeywords && allKeywords.length === 0 && keywordFetchError && !keywordsLoading && (
        <button
          className='btn btn-link p-0'
          onClick={tryLoadingMoreQuestionHandler}
        >
          {t("Try Loading Questions Again")}
        </button>
      )}
    </React.Fragment>
  )
}

// QuestionsList.propTypes = {

// }

export default QuestionsList
