import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import chatImg1 from "../../assets/New-images/chatbotImg1.svg";
import chatImg2 from "../../assets/New-images/chatbotImg2.svg";
import docsummaryImg from "../../assets/New-images/doc-summary-img-new.svg";
import greenCheck from "../../assets/New-images/green-check.svg";

import axios from "axios";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import Loading from "../../components/UI/Loading/Loading";
import {
  CHAT_CUSTOMISATION,
  GENERATESUMMARISEDKEYWORDS,
  SUPPORT,
} from "../../config";
import {
  addTagsToDocument,
  generateDocumentsTags,
  getDocumentsTextBlocks,
} from "../../store/knowledge/actions";
// import { cancelTokenSource } from "../../axios-global";

export const SummariseDocumentSections = (props: any) => {
  const { t } = useTranslation("translation");
  const history = useHistory();
  const params: any = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [InternalRef, setInternalRef] = useState(null);
  const [text, setText] = useState("");
  const [throughDocument, setThroughDocument] = useState(null);
  const editableContentRef = useRef(null);
  let source = axios.CancelToken.source();

  useEffect(() => {
    const query = new URLSearchParams(history.location.search);
    setThroughDocument(query.get("through"));
    setLoading(true);
    getDocumentsTextBlocksHandler();
    return () => {
      source.cancel("Cancelled from source.");
    };
  }, []);

  const getDocumentsTextBlocksHandler = async () => {
    // InternalRef && clearTimeout(InternalRef)
    try {
      const response: any = await dispatch(
        getDocumentsTextBlocks(params.questionId, source)
      );
      let lastIndex: any = response[response.length - 1];
      patchHtml(response);
      if (lastIndex && lastIndex.is_last_block) {
        setLoading(false);
      }
      if (!response.length) {
        setTimeout(() => {
          getDocumentsTextBlocksHandler();
        }, 10 * 1000);
      }
      if (lastIndex && !lastIndex.is_last_block) {
        setTimeout(() => {
          getDocumentsTextBlocksHandler();
        }, 10 * 1000);
      }
    } catch (error) {
      console.log("[ error ]", error);
    }
  };

  const patchHtml = (response: any[]) => {
    let summarisedContent = "";
    response.forEach((res: any) => {
      summarisedContent += `<p>${res.text}</p>`;
    });
    setText(summarisedContent);
  };

  const proceedToKnowledgeHandler = async () => {
    const query = new URLSearchParams(history.location.search);
    const references = query.get("references");
    try {
      setLoading(true);
      const generatedTags: any = await dispatch(
        generateDocumentsTags([params.questionId])
      );
      generatedTags.forEach(async (generateTag: any) => {
        const sections: any[] = [];
        generateTag.sections.forEach(async (section: any) => {
          const updatedSection = {
            ...section,
            tags: section.tags,
          };
          sections.push(updatedSection);
        });
        await dispatch(addTagsToDocument(params.questionId, sections));
      });
      setLoading(false);

      history.push(
        GENERATESUMMARISEDKEYWORDS.replace(":questionId", params.questionId) +
          "?question=" +
          params.questionId +
          (throughDocument
            ? `&through=document&references=${references}`
            : `&references=${references}`)
      );
    } catch (e) {
      history.push(
        GENERATESUMMARISEDKEYWORDS.replace(":questionId", params.questionId) +
          "?question=" +
          params.questionId +
          (throughDocument
            ? `&through=document&references=${references}`
            : `&references=${references}`)
      );
    }
  };

  if (loading) {
    <Loading />;
  }

  return (
    <React.Fragment>
      <section className="page-mid-wraper h-without-foter">
        <div>
          <div className="d-flex flex-wrap justify-content-between">
            <h1 className="mb-2">{t("Summarising documents")}</h1>
          </div>
          <div className="mb-32 font-16">
            {t("Preview of document(s) summary")}
          </div>

          <div className="pdf-section-wrapper">
            <>
              <div className="document-section-wrapper row">
                <div className="col-md-5 d-flex flex-column text-center">
                  <div className="doc-processing d-flex flex-wrap align-items-center p-0">
                    <div className="col-status">
                      {loading && <span className="doc-loader"></span>}
                    </div>
                    <div className="col-msg pl-3 font-600">
                      {!loading && (
                        <span className="doc-success mr-2">
                          <img src={greenCheck} alt="" />
                        </span>
                      )}
                      {loading
                        ? t("Walter is still reading through your document...")
                        : t("Your document has been summarised!")}
                    </div>
                  </div>
                  <div className="my-3">
                    <img src={docsummaryImg} alt="" />
                    {loading && (
                      <div className="font-600 font-16 mt-3">
                        {t(
                          "The process would take longer if there are several documents. Please be patient."
                        )}
                      </div>
                    )}
                  </div>
                  {loading && (
                    <div className="summarise-notify-settings border-grey p-3 radius-8 mt-auto text-left">
                      <h2 className="font-600 mb-3">
                        {t("You could wait or do the following")}
                      </h2>
                      <Row>
                        <Col>
                          <div
                            className="knowledge-box know-box-new radius-8 p-3 not-hover d-flex align-items-center"
                            onClick={() => history.push(CHAT_CUSTOMISATION)}
                          >
                            <figure className="h-auto mb-0">
                              <img src={chatImg1} alt="" />
                            </figure>
                            <div className="text-left pl-2">
                              <h3 className="font-weight-bold font-sm clr-primary mb-1">
                                {t("Manage your chatbot")}
                              </h3>
                              <p className="clr-grey-txt7">
                                {t("To match your your brand")}
                              </p>
                            </div>
                          </div>
                        </Col>
                        <Col>
                          <div
                            className="knowledge-box know-box-new radius-8 p-3 not-hover d-flex align-items-center"
                            onClick={() => history.push(SUPPORT)}
                          >
                            <figure className="h-auto mb-0">
                              <img src={chatImg2} alt="" />
                            </figure>
                            <div className="text-left pl-2">
                              <h3 className="font-weight-bold font-sm clr-primary mb-1">
                                {t("Learn how to setup")}
                              </h3>
                              <p className="clr-grey-txt7">
                                {t("View our video guides")}
                              </p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <div className="font-600 font-sm mt-3">
                        {t(
                          "You will receive a notification when the context setup has been completed."
                        )}
                        <br />
                        {t(
                          "Please ensure that your notifications are on so you don’t miss out on the update."
                        )}{" "}
                        <span
                          className="clr-primary font-weight-bold cursor-pointer"
                          onClick={() =>
                            history.push("/notification", {
                              activetab: "preference",
                            })
                          }
                        >
                          {t("Check notification settings")}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-md-7 mt-3 mt-md-0">
                  <div className="doc-summary-preview border-grey radius-8 p-3 ">
                    <h2 className="font-600 mb-3">{t("Summary Preview")}</h2>
                    <div
                      ref={editableContentRef}
                      className="quiz-info-extract border-grey radius-8 p-3 scroll-wrap"
                      dangerouslySetInnerHTML={{
                        __html: `<div class='pre-quiz-info-extract'>${text}</div>`,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
              <div className="text-right mt-3">
                <button
                  className={classNames({
                    "btn ": true,
                    "btn-light": loading,
                    "btn-primary": !loading,
                  })}
                  onClick={() => {
                    if (!loading) {
                      proceedToKnowledgeHandler();
                    }
                  }}
                >
                  {t("Proceed to generate keyword")}
                </button>
              </div>
            </>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default SummariseDocumentSections;
