import React, { useState, useEffect } from 'react'
import { Modal, ModalFooter } from 'react-bootstrap'
import ModalBody from 'react-bootstrap/ModalBody'
import ModalHeader from 'react-bootstrap/ModalHeader'
import ModalTitle from 'react-bootstrap/ModalTitle'
import { useSelector } from 'react-redux'
import downarrowblk from '../../../../../assets/images/chevron-down-blk.svg'
import Select from 'react-select';
import { getAllIntents } from '../../../../../store/questions/selector'

import { useTranslation } from "react-i18next";

export default function AddToExistingIntent(props: any) {
  const { t } = useTranslation('translation');
  const allIntents: any = useSelector(getAllIntents)
  const [AllIntents, setAllIntents] = useState([])

  useEffect(() => {
    setAllIntents([...allIntents])
}, [allIntents])


  return (
    <Modal
      className='add-tag-modal'
      show={props.show}
      onHide={props.onHide}
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <ModalHeader>
        <ModalTitle id='contained-modal-title-vcenter'></ModalTitle>
      </ModalHeader>
      <h2 className='mb-3 pb-4 text-center border-bottom'>{t("Where do you want to add the question?")}</h2>
      <ModalBody>
        <div className='changepurpose-select-tag'>
          <Select
            isSearchable
            className='select-target'
            classNamePrefix='select-target-prefix'
            components={{
              DropdownIndicator: () => (
                <img src={downarrowblk} alt='dropdown arrow' />
              ),
              IndicatorSeparator: () => null,
            }}
            placeholder={t('Select a question')}
            onChange={props.confirmhandler}
            options={AllIntents}
            getOptionLabel={(option: any) => option['name']}
            getOptionValue={(option: any) => option['_id']}
          />
        </div>
      </ModalBody>
      {/* <ModalFooter className='border-0 justify-content-center px-3 mb-3 mx-4 flex-nowrap'>
        <button
          className='btn-primary btn mx-2'
          onClick={(e) => props.confirmhandler(e)}
        >
          Done
        </button>
        <button className='btn-outline-primary btn mx-2' onClick={props.onHide}>
          {' '}
          Cancel
        </button>
      </ModalFooter> */}
    </Modal>
  )
}
