import React, { useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { useTranslation } from "react-i18next";
import closeImg from "../../../assets/New-images/build-knowledge-close-img.svg";

const DocumentRename = (props: any) => {
  const { t } = useTranslation("translation");
  const [name, setName] = useState("");

  return (
    <React.Fragment>
      <Modal show={props.show} centered>
        <ModalHeader className="clr-black pt-4 px-4 justify-content-between">
          <ModalTitle id="contained-modal-title-vcenter">
            <h2 className="mb-0 font-1">{t("Rename Document")}</h2>
          </ModalTitle>
          <button className="btn" onClick={props.onHide}>
            <img src={closeImg} alt="" />
          </button>
        </ModalHeader>
        <ModalBody className="px-4">
          <label htmlFor="" className="font-600">
            {t("Document Name")}
          </label>
          <input
            type="text"
            className="form-control h-auto radius-4"
            onChange={(e: any) => {
              setName(e.target.value);
            }}
            placeholder={props.documentName}
          />
        </ModalBody>
        <ModalFooter className="justify-content-center border-top-0">
          <button
            className="btn-primary btn py-1 font-600"
            onClick={() => {
              props.footerButtonAction(name);
            }}
            disabled={!name || name.trim().length === 0}
          >
            {t("Save Changes")}
          </button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default DocumentRename;
