import { AxiosResponse } from "axios";
import ApiUtil from "../utils/apiUtil";
import { getAgentFromStorage } from "../utils/appUtils";
import {
  AddDocumentKeyPhrase,
  AddKeyPhrase,
  KeyPhraseResponse,
  KeyPhraseUpdateResponse,
  UpdateKeyPhrase,
} from "../types/conversation";

type Type = any;

export default class ConversationFlowService {
  async getConversationFlowGraph({
    nodeId,
    topic_id,
  }: {
    nodeId: string;
    topic_id: string;
  }): Promise<Type> {
    let q = `package=reactflow&agent_id=${getAgentFromStorage()}`;
    if (nodeId) {
      q += `&node=${nodeId}`;
    }

    if (typeof topic_id !== "undefined" && topic_id) {
      q += `&knowledge_group_ids=${topic_id}`;
    }

    const res = await ApiUtil.SendAsync<any>({
      url: `convos/graphs/current?${q}`,
      method: "GET",
    });
    return res;
  }

  async deleteElementByEdgeId({
    selectedRow,
    relink_following_nodes,
  }: {
    selectedRow: string;
    relink_following_nodes: number;
  }): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `convos/edges/${selectedRow}?relink_following_nodes=${relink_following_nodes}&agent_id=${getAgentFromStorage()}`,
      method: "DELETE",
    });
    return res;
  }

  async getDocumentRelevanceTune(): Promise<AxiosResponse<KeyPhraseResponse>> {
    const res = await ApiUtil.SendAsync<any>({
      url: `convos/document-relevance-tune?agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }
 
  async getDocumentKeyPhraseList(docId:string,currentPage:number, perPage:number): Promise<AxiosResponse<KeyPhraseResponse>> {
    const res = await ApiUtil.SendAsync<any>({
      url: `convos/document-relevance-tune/paginated?agent_id=${getAgentFromStorage()}&document_id=${docId}&page=${currentPage}&page_size=${perPage}`,
      method: "GET",
    });
    return res;
  }

  async getDocumentYearList(relevanceId:string): Promise<AxiosResponse<KeyPhraseResponse>> {
    const res = await ApiUtil.SendAsync<any>({
      url: `convos/document-relevance-tune/${relevanceId}?agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async createDocumentRelevanceTune(
    data: AddKeyPhrase[]
  ): Promise<AxiosResponse<KeyPhraseResponse>> {
    const res = await ApiUtil.SendAsync<any>({
      url: `convos/document-relevance-tune?agent_id=${getAgentFromStorage()}`,
      method: "POST",
      data,
    });
    return res;
  }

  async createSpecificDocumentRelevanceTune(
    data: AddDocumentKeyPhrase[]
  ): Promise<AxiosResponse<KeyPhraseResponse>> {
    const res = await ApiUtil.SendAsync<any>({
      url: `convos/document-relevance-tune?agent_id=${getAgentFromStorage()}`,
      method: "POST",
      data,
    });
    return res;
  }

  async deleteDocumentRelevanceTune(
    relevanceId: string
  ): Promise<AxiosResponse<KeyPhraseResponse>> {
    const res = await ApiUtil.SendAsync<any>({
      url: `convos/document-relevance-tune/${relevanceId}?agent_id=${getAgentFromStorage()}`,
      method: "DELETE",
    });
    return res;
  }

  async editDocumentKeyPhrasePriority(
    relevanceId: string,
    data: UpdateKeyPhrase
  ): Promise<AxiosResponse<KeyPhraseResponse>> {
    const res = await ApiUtil.SendAsync<any>({
      url: `convos/document-relevance-tune/${relevanceId}?agent_id=${getAgentFromStorage()}`,
      method: "PUT",
      data 
    });
    return res;
  }

  async updateDocumentRelevanceTune(
    relevanceId: string,
    data: UpdateKeyPhrase
  ): Promise<AxiosResponse<KeyPhraseUpdateResponse>> {
    const res = await ApiUtil.SendAsync<any>({
      url: `convos/document-relevance-tune/${relevanceId}?agent_id=${getAgentFromStorage()}`,
      method: "PUT",
      data,
    });
    return res;
  }
}
