import React from "react";
import { Button, Image, Modal, ModalFooter } from "react-bootstrap";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import closeimg from "../../../assets/images/close-blk.svg";
import cx from "classnames";

import { useTranslation } from "react-i18next";

export default function ConfirmationModal(props: any) {
  const { t } = useTranslation("translation");
  return (
    <Modal
      show={props.show}
      size={props.size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName={
        props.dialogClassName
          ? props.dialogClassName
          : "delete-knowledge-confirmation"
      }
    >
      <ModalHeader
        className={cx({
          "pt-3": true,
          "justify-content-between": props.headerMessage ? true : false,
        })}
        onHide={props.onHide}
      >
        {props.headerMessage && (
          <ModalTitle id="contained-modal-title-vcenter">
            {props.headerMessage}
          </ModalTitle>
        )}
        <Button onClick={props.onHide}>
          <Image src={closeimg} />
        </Button>
      </ModalHeader>
      <ModalBody>
        <div className="px-4">{props.message}</div>
      </ModalBody>
      <ModalFooter className="border-0 justify-content-center px-3 mb-3 mx-4 flex-nowrap">
        <button
          className="btn-outline-primary btn w-50 ml-0 mr-3"
          onClick={
            props.confirmOnHideHandler
              ? props.confirmOnHideHandler
              : props.onHide
          }
        >
          {props.secondaryButtonText
            ? props.secondaryButtonText
            : t("No, Cancel")}
        </button>
        <button
          className={
            props.positiveAction
              ? "btn-primary btn w-50 ml-3 mr-0"
              : "btn-danger btn w-50 ml-3 mr-0"
          }
          onClick={(e) => props.confirmHandler(e, props.selectedRow)}
        >
          {props.buttonText ? props.buttonText : t("Yes, Delete")}
        </button>
      </ModalFooter>

      {/* delete new modal */}
      {/* <ModalHeader className="py-3 justify-content-center border-bottom">
        <ModalTitle id="contained-modal-title-vcenter" className="d-flex align-items-center justify-content-between w-100">
          <h2 className="mb-0 font-1 font-600">Are you sure you want to delete</h2>
          <Button onClick={props.onHide}>
            <Image src={closeimg} />
          </Button>
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <div className='font-600 font-16 px-1'>
          Knowledge Group would be <span className='clr-red'>permanently deleted</span>. All knowledge items/Quizzes in knowledge group would be shifted to uncategorised 
        </div>
      </ModalBody>
      <ModalFooter>
        <button className="btn-outline-danger btn mx-2 font-600">
          Delete
        </button>
        <button
          className="btn-primary btn mx-2 font-600"
        >
          Cancel
        </button>
      </ModalFooter> */}
    </Modal>
  );
}
