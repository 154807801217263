import {
  Button,
  Modal,
  ModalFooter,
  OverlayTrigger,
  Tooltip,
  Image,
} from "react-bootstrap";
import copyIcon from '../../../assets/images/copy-icon.svg';
import canvasEnableNavigation from "../../../assets/images/integration/canvas-enable-navigation.png";
import ModalBody from "react-bootstrap/ModalBody";
import {useTranslation} from "react-i18next";
import {CopyContant} from "../../../utils/copyContant";
import CanvasConfigureForm from './CanvasConfigureForm';
import {FormData} from '../IntegrationType'
import IntegrationModalHeader from '../Modal/IntegrationModalHeader'
import {useState} from "react";

type LtiSetup = {
  integrationType: string;
  closeModal: () => void;
  integrateSpecificAgent: boolean;
  setTntegrateSpecificAgent: (value: boolean) => void;
  renderCopy: (text: string) => JSX.Element;
  data: LtiSetupData;
  handleIntegration: () => Promise<void>;
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
  allAgents: any[];
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>, name: string) => void;
}

type LtiSetupData = {
  redirect_uri: string,
  target_link_uri: string,
  openid_connect_initiation_url: string,
}

const CanvasLtiModal = (props: LtiSetup) => {
  const {t} = useTranslation("translation");
  const [showsInstructionsModal, setShowsInstructionsModal] = useState(true);
  const [showsTestConnectModal, setShowsTestConnectModal] = useState(false);
  const closeModal = () => {
    setShowsInstructionsModal(false);
    setShowsTestConnectModal(false);
    props.closeModal();
  }
  const renderTooltip = (props: any) => (
      <Tooltip id="button-tooltip" {...props}>
        Copy
      </Tooltip>
  );

  const json_config = `{
    "title": "Noodle Factory - Walter AI",
    "scopes": [],
    "extensions": [
      {
        "platform": "canvas.instructure.com",
        "settings": {
          "platform": "canvas.instructure.com",
          "placements": [
            {
              "placement": "course_navigation",
              "message_type": "LtiResourceLinkRequest",
              "text": "Walter AI Tutor",
              "visibility": "members",
              "display_type": "full_width_in_context",
              "default":"disabled"
            },
            {
              "placement": "course_home_sub_navigation",
              "message_type": "LtiResourceLinkRequest",
              "text": "Walter AI Admin",
              "icon_url": "https://media.noodlefactory.ai/static/img/walter-head-white.png",
              "visibility": "admins",
              "display_type": "full_width_in_context"
            },
            {
              "placement": "link_selection",
              "message_type": "LtiResourceLinkRequest"
            }
          ]
        },
        "privacy_level": "public"
      }
    ],
    "public_jwk": {},
    "description": "Noodle Factory - Walter AI",
    "custom_fields": {},
    "public_jwk_url": "https://oneauth.noodlefactory.ai/auth/.well-known/jwks.json",
    "target_link_uri": "${props?.data?.target_link_uri}",
    "oidc_initiation_url": "${props?.data?.openid_connect_initiation_url}"
  }`;

  return (
      <>
        <Modal
            className="modal-width"
            aria-labelledby="contained-modal-title-vcenter"
            show={showsInstructionsModal}
            onHide={() => {
            }}
            centered
        >
          <IntegrationModalHeader
              modalTitle="Enable Walter AI Tutor in Canvas"
              closeModal={ closeModal }
          />

          <ModalBody className="integration-modal-body">
            <div className="tw-flex  tw-gap-4 canvas-manual-integration">
              <div className="tw-px-4 tw-py-4 w-90 left-content-wrap">
                <h2 className="mb-2">{t("Step 1: Configure Developer Key in Canvas LMS")}</h2>
                <p className="mb-0 subhEad">{t("To configure a developer key, please do the following")}:</p>
                <ol>
                  <li>{t("Log in to Canvas as an admin")}.</li>
                  <li>{t("In Global Navigation, click on")}&nbsp;
                    <strong>Admin</strong> {t("link, then click the name of the account")}.
                  </li>
                  <li>{t("In Account Navigation, click on")} <strong>Developer Keys</strong> {t("link")}.</li>
                  <li>{t("Click on")} <strong>+ Developer Key</strong> {t("button and select")} <strong>+ LTI
                    Key</strong> {t("from the dropdown")}.
                    <p className="mb-0 subhEad">{t("You'll need to complete the form with the following values")}:</p>
                    <ul>
                      <li>
                        <span className="label">Key Name: </span>
                        {props.renderCopy("Noodle Factory - Walter AI")}
                      </li>
                      <li>
                        <span className="label">Owner Email: </span>
                        &lt; {t("Key Owner's Email")} &gt;
                      </li>
                      <li>
                        <span className="label">Method: </span>
                        <em>Paste JSON</em>
                      </li>
                      <li className="code-snippet-for-widget">
                        <span
                            className="label">LTI 1.3 Configuration: </span>{t("Copy the following JSON and paste into the form input")}

                        <pre className="publish-pre-tag-background">
                          <OverlayTrigger
                              placement="right"
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltip}
                          >
                            <Image className="cursor-pointer absolute right-0"
                                 onClick={() => {CopyContant(json_config)}}
                                 src={copyIcon} />
                          </OverlayTrigger>
                          <code>
                            {json_config}
                          </code>
                        </pre>

                      </li>
                    </ul>
                  </li>
                  <li>{t("Click on button")} <strong>Save</strong> {t("to create the LTI key.")}</li>
                  <li>{t("Once the LTI Key is created, go back to the list of Developer Keys and enable the key by clicking on the toggle under State column. Next, copy and paste client ID to the form on the right")}</li>
                </ol>

                <h2 className="mb-2">{t("Step 2: Deploy the app in Canvas")}</h2>
                <p
                    className="mb-0 subhEad">{t("To install the app in your Canvas LMS, kindly follow these instructions")}:</p>
                <ol>
                  <li>{t("In Global Navigation, click the")} <strong>Admin</strong> {t("link, then click the name of the account")}.
                  </li>
                  <li>{t("Click on")} <strong>Settings</strong> {t("link, then choose")} <strong>{t("Apps")}</strong> {t("tab")}</li>
                  <li>{t("In the Apps page, click on")} <strong>+ App</strong> {t("to deploy new LTI Advantage app")}
                  </li>
                  <li>{t("Configure the App")}:
                    <ul>
                      <li>{t("In the")} Configuration Type {t("dropdown, choose")} <em>By Client ID</em>.</li>
                      <li>{t("Enter the Client ID obtained from Step 1")}.</li>
                      <li>{t("Click on")} <strong>Submit</strong>.</li>
                    </ul>
                  </li>
                  <li>{t("Copy and paste deployment ID to the form on the right and click on")} <strong>Next Step</strong> {t("button")}.</li>
                  <li>{t("After this step in Canvas, the app will appear to teachers in the Course Home Sub Navigation on the right side of all courses")}</li>
                </ol>
              </div>

              <CanvasConfigureForm
                  integrateSpecificAgent={props.integrateSpecificAgent}
                  setTntegrateSpecificAgent={props.setTntegrateSpecificAgent}
                  integrationType={props.integrationType}
                  formData={props.formData}
                  setFormData={props.setFormData}
                  handleInputChange={props.handleInputChange}
                  allAgents={props.allAgents}
              />
            </div>
          </ModalBody>
          < ModalFooter>
            <Button
                variant="outline-primary"
                onClick={closeModal}
            >
              {t("Cancel")}
            </Button>
            <Button
                variant="primary"
                disabled={!props.formData.LMS_URL || !props.formData.Client_ID || !props.formData.Deployment_ID}
                onClick={() => {
                  props.handleIntegration();
                  setShowsInstructionsModal(false);
                  setShowsTestConnectModal(true);
                }}
            >
              {t("Next Step")}
            </Button>
          </ModalFooter>
        </Modal>


        <Modal
            className="modal-width max-content"
            aria-labelledby="contained-modal-title-vcenter"
            show={showsTestConnectModal}
            onHide={() => {
            }}
            centered
        >
          <IntegrationModalHeader
              modalTitle="Enable Walter AI Tutor for students in a Canvas course"
              closeModal={ props?.closeModal }
          />

          <ModalBody className="integration-modal-body">
            <div className="tw-px-4 tw-py-4 w-100">
              <div className="content-wrap-linkadmin">
                <p className="mb-0 subhEad">{t("After previous steps, Walter AI Admin is enabled for all teachers under course home sub navigation. Teachers can enable the tool for students with the following steps")}:</p>
                <ol>
                  <li>{t("Log in Canvas as a teacher")}</li>
                  <li>{t("Go to a course to enable the tool")}</li>
                  <li>{t("Click on")} <strong>Settings</strong> {t("link")}, {t("then choose")} <strong>Navigation</strong> {t("tab")}.</li>
                  <li>
                    {("Locate")} <em>Walter AI Tutor</em> {t("under the list of navigation items, and lick on three dots icon. Then choose Enable from the dropdown")}.
                    <Image className="tip-img" src={canvasEnableNavigation} />
                  </li>
                  <li>{t("Click on")} <strong>Save</strong> {("button. The tool will appear in the course navigation and be visible to students")}.</li>
                </ol>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
                variant="primary"
                onClick={closeModal}
            >
              {t("Complete")}
            </Button>
          </ModalFooter>
          {/* )} */}
        </Modal>
      </>
  );
};
export default CanvasLtiModal;
