// "use strict";

const defaultMarkdownDict: any = {
  BOLD: "__",
  ITALIC: "*",
};

const blockStyleDict: any = {
  "unordered-list-item": "- ",
  "header-one": "# ",
  "header-two": "## ",
  "header-three": "### ",
  "header-four": "#### ",
  "header-five": "##### ",
  "header-six": "###### ",
  blockquote: "> ",
};

const wrappingBlockStyleDict: any = {
  "code-block": "```",
};

const getBlockStyle = (
  currentStyle: any,
  depth: any,
  appliedBlockStyles: any
) => {
  if (currentStyle === "ordered-list-item") {
    const counter = appliedBlockStyles.reduce((prev: any, style: any) => {
      if (style === "ordered-list-item") {
        return prev + 1;
      }
      return prev;
    }, 1);
    return `${counter}. `;
    // Start custom code
  } else if (currentStyle === "unordered-list-item") {
    return `${Array(2 * depth).fill(" ").join("")}${blockStyleDict[currentStyle]}`;
  } // End custom code
  return blockStyleDict[currentStyle] || "";
};

const applyWrappingBlockStyle = (currentStyle: string, content: string) => {
  if (currentStyle in wrappingBlockStyleDict) {
    const wrappingSymbol = wrappingBlockStyleDict[currentStyle];
    return `${wrappingSymbol}\n${content}\n${wrappingSymbol}`;
  }

  return content;
};

const applyAtomicStyle = (
  block: { type: string; text: string | any[]; entityRanges: { key: any }[] },
  entityMap: any,
  content: string
) => {
  if (block.type !== "atomic") return content;
  // strip the test that was added in the media block
  const strippedContent = content.substring(
    0,
    content.length - block.text.length
  );
  const key = block.entityRanges[0].key;
  const type = entityMap[key].type;
  const data = entityMap[key].data;
  if (type === "draft-js-video-plugin-video") {
    return `${strippedContent}[[ embed url=${data.url || data.src} ]]`;
  }
  return `${strippedContent}![${data.fileName || ""}](${data.url || data.src})`;
};

const getEntityStart = (entity: { type: any }) => {
  switch (entity.type) {
    case "LINK":
      return "[";
    default:
      return "";
  }
};

const getEntityEnd = (entity: { type: any; data: { url: any } }) => {
  switch (entity.type) {
    case "LINK":
      return `](${entity.data.url.replace("[", "")})`;
    default:
      return "";
  }
};

function fixWhitespacesInsideStyle(
  text: any,
  style: { range?: any; symbol?: any }
) {
  const { symbol } = style;

  // Text before style-opening marker (including the marker)
  const pre = text.slice(0, style.range.start);
  // Text between opening and closing markers
  const body = text.slice(style.range.start, style.range.end);
  // Trimmed text between markers
  const bodyTrimmed = body.trim();
  // Text after closing marker
  const post = text.slice(style.range.end);

  const bodyTrimmedStart = style.range.start + body.indexOf(bodyTrimmed);

  // Text between opening marker and trimmed content (leading spaces)
  const prefix = text.slice(style.range.start, bodyTrimmedStart);
  // Text between the end of trimmed content and closing marker (trailing spaces)
  const postfix = text.slice(
    bodyTrimmedStart + bodyTrimmed.length,
    style.range.end
  );

  // Temporary text that contains trimmed content wrapped into original pre- and post-texts
  const newText = `${pre}${bodyTrimmed}${post}`;
  // Insert leading and trailing spaces between pre-/post- contents and their respective markers
  return newText.replace(
    `${symbol}${bodyTrimmed}${symbol}`,
    `${prefix}${symbol}${bodyTrimmed}${symbol}${postfix}`
  );
}

function getInlineStyleRangesByLength(inlineStyleRanges: any) {
  return [...inlineStyleRanges].sort((a, b) => b.length - a.length);
}

function draftjsToMd(
  raw: { blocks: any[]; entityMap: { [x: string]: any } },
  extraMarkdownDict: any
) {
  const markdownDict = { ...defaultMarkdownDict, ...extraMarkdownDict };
  const appliedBlockStyles: any[] = [];

  return raw.blocks
    .map((block: any) => {
      // totalOffset is a difference of index position between raw string and enhanced ones
      let totalOffset = 0;
      let returnString = "";

      // add block style
      returnString += getBlockStyle(
        block.type,
        block.depth,
        appliedBlockStyles
      );
      appliedBlockStyles.push(block.type);
      const appliedStyles:
        | { end: number }[]
        | { symbol: any; range: { start: any; end: any }; end: any }[] = [];
      returnString += Array.from(block.text).reduce(
        (text, currentChar, index) => {
          let newText: any = text;

          const sortedInlineStyleRanges = getInlineStyleRangesByLength(
            block.inlineStyleRanges
          );

          // find all styled at this character
          const stylesStartAtChar = sortedInlineStyleRanges
            .filter((range) => range.offset === index)
            .filter((range) => markdownDict[range.style]); // disregard styles not defined in the md dict

          // add the symbol to the md string and push the style in the applied styles stack
          stylesStartAtChar.forEach((currentStyle) => {
            const symbolLength = markdownDict[currentStyle.style].length;
            newText += markdownDict[currentStyle.style];
            totalOffset += symbolLength;
            appliedStyles.push({
              symbol: markdownDict[currentStyle.style],
              range: {
                start: currentStyle.offset + totalOffset,
                end: currentStyle.offset + currentStyle.length + totalOffset,
              },
              end: currentStyle.offset + (currentStyle.length - 1),
            });
          });

          // check for entityRanges starting and add if existing
          const entitiesStartAtChar = block.entityRanges.filter(
            (range: { offset: number }) => range.offset === index
          );
          entitiesStartAtChar.forEach((entity: { key: string | number }) => {
            newText += getEntityStart(raw.entityMap[entity.key]);
          });

          // add the current character to the md string
          newText += currentChar;

          // check for entityRanges ending and add if existing
          const entitiesEndAtChar = block.entityRanges.filter(
            (range: { offset: any; length: any }) =>
              range.offset + range.length - 1 === index
          );
          entitiesEndAtChar.forEach((entity: { key: string | number }) => {
            newText += getEntityEnd(raw.entityMap[entity.key]);
          });

          // apply the 'ending' tags for any styles that end in the current position in order (stack)
          while (
            appliedStyles.length !== 0 &&
            appliedStyles[appliedStyles.length - 1].end === index
          ) {
            const endingStyle: any = appliedStyles.pop();
            newText += endingStyle.symbol;

            newText = fixWhitespacesInsideStyle(newText, endingStyle);
            totalOffset += endingStyle.symbol.length;
          }

          return newText;
        },
        ""
      );

      returnString = applyWrappingBlockStyle(block.type, returnString);
      returnString = applyAtomicStyle(block, raw.entityMap, returnString);

      return returnString;
    })
    .join("\n");
}

// module.exports = draftjsToMd;
export default draftjsToMd;
