import { FC, ReactNode, useEffect, useState } from "react";
import {
  Dashboard,
  Form,
  Knowledge,
  Quiz,
  Channels,
  Users,
  Maximize,
  Question,
  Integration
} from "../UI/Icons";
import { useTranslation } from "react-i18next";
import { CustomLink } from "./CustomLink";
import * as CONFIG from "../../config";
import classNames from "classnames";
import { NewLookModal } from "./NewLook";
import Logo from "../../assets/New-images/logo.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getCommonState,
  getCommonUserDetails,
} from "../../store/common/selector";
import { getOrganizationStates } from "../../store/common/actions";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getSelectedAgent,updateSelectedAgent } from "../../store/agents/actions";
import { useGlobalContext } from "../../context/GlobalContext";

interface SidebarItem {
  icon: ReactNode;
  label: string;
  link: string;
}

const windowObj: any = window;

export const Sidebar: FC = () => {
  const { t } = useTranslation("translation");
  const history = useHistory();
  const location = useLocation()
  const dispatch = useDispatch();
  const { setSelectedAgentName,setSelectedAgentEmail } = useGlobalContext();
  const [selectedAgent, setSelectedAgent] = useState(
    localStorage.getItem("selectedAgent")
  );
  // const location = useLocation();
  // const history = useHistory();
  // const dispatch = useDispatch();
  
  // useEffect(() => {
  //   const currentAgent = localStorage.getItem("selectedAgent");
  //   const searchParams = new URLSearchParams(location.search);
  //   const urlAgentId = searchParams.get("agent");

  //   // Check if the URL agent ID is different from the current agent in localStorage
  //   if (urlAgentId && urlAgentId !== currentAgent) {
  //     agentUpdater(urlAgentId);
  //   } else if (currentAgent && !searchParams.has('agent')) {
  //     // If there's no agent in the URL, append the current agent to the URL
  //     searchParams.append('agent', currentAgent);
  //     history.replace({ search: searchParams.toString() });
  //   }
  // }, [location, history]);

  // const agentUpdater = async (agentId:string) => {
  //   try {
  //     const selectAgentResponse = await dispatch(getSelectedAgent(agentId));
  //     const updateAgentResponse = await dispatch(
  //       updateSelectedAgent({
  //         states: {
  //           selectedAgent: selectAgentResponse._id,
  //           selectedAgentName: selectAgentResponse.name,
  //           showToster: false,
  //         }
  //       })
  //     );

  //     // Update local storage with the new agent information
  //     localStorage.setItem("selectedAgent", updateAgentResponse.selectedAgent);
  //     localStorage.setItem("selectedAgentName", updateAgentResponse.selectedAgentName);
  //   } catch (error) {
  //     console.error("Error updating agent:", error);
  //   }
  // };


  
  useEffect(() => {
    const currentAgent = localStorage.getItem("selectedAgent");
    const searchParams = new URLSearchParams(location.search);
    const urlAgentId = searchParams.get("agent");
  
    if (urlAgentId && urlAgentId !== currentAgent) {
      agentUpdater(urlAgentId);
    } else if (currentAgent && !searchParams.has('agent')) {
      searchParams.append('agent', currentAgent);
      history.replace({ search: searchParams.toString() });
    }
  }, [location, history]);

  const agentUpdater = async (agentId: string) => {
    try {
      const selectAgentReponse: any = await dispatch(getSelectedAgent(agentId));
      const updateAgentResponse: any = await dispatch(
        updateSelectedAgent({
          states: {
            selectedAgent: selectAgentReponse._id,
            selectedAgentName:selectAgentReponse.name,
            showToster: false,
          }
        })
      );
      setSelectedAgentName(updateAgentResponse.selectedAgentName);
      setSelectedAgent(updateAgentResponse.selectedAgent);
      setSelectedAgentEmail(updateAgentResponse.selectedAgentName);

      localStorage.setItem("selectedAgent", updateAgentResponse.selectedAgent);
      localStorage.setItem("selectedAgentName", updateAgentResponse.selectedAgentName);
      localStorage.setItem("selectedAgentEmail", updateAgentResponse.selectedAgentName);
      localStorage.setItem("selectedLanguageCode", updateAgentResponse.selectedLanguageCode);
      
    } catch (error) {
      console.error("Error updating agent:", error);
    }
  };

  // useEffect(() => {
  //   const currentAgent = localStorage.getItem("selectedAgent");
  //   const searchParams = new URLSearchParams(location.search);
  //   const urlAgentId = searchParams.get("agent");

  //   // Check if the URL agent ID is different from the current agent in localStorage
  //   if (urlAgentId && urlAgentId !== currentAgent) {
  //     agentUpdater(urlAgentId);
  //   } else if (currentAgent && !searchParams.has('agent')) {
  //     // If there's no agent in the URL, append the current agent to the URL
  //     updateUrlWithCurrentAgent(currentAgent);
  //   }
  // }, [location, history]);

  // const updateUrlWithCurrentAgent = (currentAgent: string) => {
  //   const searchParams = new URLSearchParams(location.search);
  //   searchParams.set('agent', currentAgent); // Use set to replace the existing value
  //   history.replace({ search: searchParams.toString() });
  // };

  // const agentUpdater = async (agentId: string) => {
  //   try {
  //     const selectAgentResponse: any = await dispatch(getSelectedAgent(agentId));
  //     const updateAgentResponse: any = await dispatch(
  //       updateSelectedAgent({
  //         states: {
  //           selectedAgent: selectAgentResponse._id,
  //           selectedAgentName: selectAgentResponse.name,
  //           showToster: false,
  //         }
  //       })
  //     );

  //     // Update local storage with the new agent information
  //     localStorage.setItem("selectedAgent", updateAgentResponse.selectedAgent);
  //     localStorage.setItem("selectedAgentName", updateAgentResponse.selectedAgentName);
  //   } catch (error) {
  //     console.error("Error updating agent:", error);
  //   }
  // };
  
  const categories: {
    category: string;
    items: SidebarItem[];
  }[] = [
    {
      category: "#",
      items: [
        {
          icon: <Dashboard />,
          label: t("Dashboard"),
          link: "/dashboard",
        },
      ],
    },
    {
      category: t("Set Up"),
      items: [
        {
          icon: <Knowledge />,
          label: t("Knowledge"),
          link: "/knowledge/all",
        },
        {
          icon: <Quiz />,
          label: t("Exercises"),
          link: CONFIG.QUIZZES_BETA,
        },
        {
          icon: <Form />,
          label: t("Forms"),
          link: "/forms?viewMode=true",
        },
      ],
    },
    {
      category: t("Manage"),
      items: [
        {
          icon: <Channels />,
          label: t("Channels"),
          link: "/chat-customisation?viewMode=true",
        },
        {
          icon: <Users />,
          label: t("Users"),
          link: "/user-management?viewMode=true",
        }
      ],
    },
  ];

  return (
    <div className="tw-p-4 !tw-w-[200px] tw-bg-white tw-h-full flex">
      <div className="tw-flex tw-flex-col tw-gap-4 tw-h-full">
        <img src={Logo} alt="Logo" />
        <div className="tw-flex-1 tw-flex tw-flex-col tw-gap-4 tw-overflow-auto">
          {categories.map(({ category, items }) =>
            category === "#" ? (
              items.map((item, index) => <CustomLink key={index} {...item} />)
            ) : (
              <div className="tw-flex tw-flex-col tw-gap-1">
                <div className="tw-text-[#79767A] tw-font-semibold tw-text-sm">
                  {category}
                </div>
                <div className="tw-flex tw-flex-col tw-gap-1">
                  {items.map((item, index) => (
                    <CustomLink key={index} {...item} />
                  ))}
                </div>
              </div>
            )
          )}
        </div>
        <div>
          <a
            className="tw-px-4 tw-py-2.5 tw-text-white tw-bg-primary tw-rounded-[4px] tw-flex tw-items-center tw-gap-4"
            href={`${windowObj.CHATBOT_URL}?agent=${localStorage.getItem(
              "selectedAgent"
            )}&through=admin-portal`}
            target="_blank"
            rel="noreferrer"
          >
            <Maximize />
            {t("Learner View")}
          </a>
        </div>
        <div className="tw-bg-black-20 tw-w-full tw-h-[1px]" />
        <CustomLink
          icon={<Question />}
          label={t("Support")}
          link={CONFIG.SUPPORT}
        />
      </div>
    </div>
  );
};

interface CustomSidebarProps {
  isShown: boolean;
  onToggle: () => void;
}

const CustomSidebar: FC<CustomSidebarProps> = ({ isShown, onToggle }) => {
  const user = useSelector(getCommonUserDetails);
  // const [isNewLookModalShown, setNewLookModalShown] = useState(false);
  const dispatch = useDispatch();
  const commonState = useSelector(getCommonState);

  useEffect(() => {
    if (commonState.UILoading) return;
    // console.log("COMMON", commonState);

    // if (!commonState.hideLayoutChangeModal) {
    //   setNewLookModalShown(true);
    // }
  }, [commonState]);

  return (
    <>
      <div className="max-lg:tw-hidden tw-fixed tw-top-0 tw-left-0 tw-bottom-0 tw-z-[1000] tw-shadow-sidebar">
        <Sidebar />
      </div>
      <div
        className={classNames(
          "tw-fixed tw-top-0 tw-left-0 tw-bottom-0 tw-overflow-hidden tw-z-[1000] tw-shadow-sidebar tw-transition-all tw-duration-300",
          isShown ? "tw-translate-x-0" : "-tw-translate-x-[200%]"
        )}
      >
        <Sidebar />
      </div>
      {isShown && (
        <div
          className="tw-fixed tw-bg-black tw-bg-opacity-30 tw-top-0 tw-bottom-0 tw-left-0 tw-right-0 tw-z-[999]"
          onClick={() => onToggle()}
        ></div>
      )}

      {/*<NewLookModal*/}
      {/*  show={isNewLookModalShown}*/}
      {/*  onClose={() => {*/}
      {/*    localStorage.setItem(`hide-layout-change-inform${user._id}`, "true");*/}
      {/*    setNewLookModalShown(false);*/}

      {/*    dispatch(*/}
      {/*      updateSelectedAgent({*/}
      {/*        states: {*/}
      {/*          hideLayoutChangeModal: true,*/}
      {/*        },*/}
      {/*      })*/}
      {/*    );*/}
      {/*  }}*/}
      {/*/>*/}
    </>
  );
};

export default CustomSidebar;
