import cx from "classnames";
import * as _ from "lodash";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Loading from "../../../components/UI/Loading/Loading";
import PDFViewer from "../../../components/UI/PDFViewer/PDFViewer";
import PermissionWrapper from "../../../components/UI/PermissionWrapper/PermissionWrapper";
import { getStickerSets } from "../../../store/common/actions";
import {
  generateQuestionsAndAnswersBasedOnDocument,
  getSectionsBasedOnDocumentId,
  resetAnswersScreen
} from "../../../store/knowledge/actions";
import {
  getUserDocuments,
  iskeyWordsLoading,
  isKnowledgeCreated,
  isKnowledgeCreating
} from "../../../store/knowledge/selector";
import GenerateTags from "../../UploadedDocuments/GenerateTags/GenerateTags";

import { useTranslation } from "react-i18next";

export const GenerateSummarisedKeywords = (props: any) => {
  const { t } = useTranslation("translation");
  const isMgs = process.env.REACT_APP_CISCO === "mgs";
  const history = useHistory();
  const params: any = useParams();
  let defaultActiveKey = "qnas";
  if (isMgs) {
    defaultActiveKey = "sections";
  } else {
    defaultActiveKey = props.manageDocument ? "qnas" : "questions";
  }
  const dispatch = useDispatch();
  const useLocatio = useLocation();
  const [generatedTags, setGeneratedTags] = useState([]);
  const [generatedQuestions, setGeneratedQuestions] = useState([]);
  const [updatedQuestions, setUpdatedQuestions] = useState([]);
  const userDocuments = useSelector(getUserDocuments);
  const [sections, setSections] = useState([]);
  const [sectionsFetched, setSectionsFetched] = useState(false);
  const [selectedSection, setSelectedSection] = useState(null);
  const [urlMap, setUrlMap] = useState(null);

  useEffect(() => {
    if (!useLocatio.search) {
      history.goBack();
    }
    const query = new URLSearchParams(useLocatio.search);
    const paramsUrl = query.get("question").split(",");
    if (paramsUrl.length === 0) {
      history.goBack();
      return;
    }

    generateQuestionsAndAnswersBasedOnDocumentFn();
  }, []);

  const generateQuestionsAndAnswersBasedOnDocumentFn = async () => {
    const query = new URLSearchParams(useLocatio.search);
    const params = query.get("question").split(",");
    setSectionsFetched(false);
    try {
      const sectionResponse: any = await dispatch(
        getSectionsBasedOnDocumentId(params)
      );
      setSections([...sectionResponse]);
      setSelectedSection(
        sectionResponse.length > 0 ? sectionResponse[0] : null
      );
      dispatch(
        generateQuestionsAndAnswersBasedOnDocument(
          userDocuments,
          params,
          sectionResponse.length > 0 ? sectionResponse[0] : null
        )
      );
      setSectionsFetched(true);
    } catch (error) {
      setSectionsFetched(true);
      dispatch(
        generateQuestionsAndAnswersBasedOnDocument(userDocuments, params, null)
      );
    }
  };


  useEffect(() => {
    const query = new URLSearchParams(useLocatio.search);
    const params = query.get("question").split(",");
    const questionsFetched = userDocuments.filter(
      (document: any) => document._id === params[0]
    );
    setGeneratedQuestions([..._.uniqBy(questionsFetched, "_id")]);
    setUpdatedQuestions([..._.uniqBy(questionsFetched, "_id")]);
  }, [userDocuments]);

  return (
    <>
      {/* {loading && <Loading />} */}

      <section
        className={cx({
          "page-mid-wraper pb-0": true,
          "h-without-foter": true,
          "knowledge-detailby-id": props && props.hideHeaders,
        })}
      >
        <Container>
          {/* {!props.hideHeaders && (
            <>
              <div className="row">
                <div className="col-md-12">
                  <button
                    className="btn p-0 btn-back"
                    onClick={() => history.goBack()}
                  >
                    <Image src={backimg} /> {t("Return to previous page")}
                  </button>
                </div>
              </div>
            </>
          )} */}
          <div className="d-flex flex-wrap justify-content-between">
            <h1 className="mb-2">{t("Generate Keywords")}</h1>
          </div>
          <div className="mb-32 font-16">
            {t(
              "Review and manage the generated question and answers from the document."
            )}
          </div>

          <div className="d-flex">
            <div className="col-md-5">
              <PDFViewer
                setUrlMapForParent={setUrlMap}
                documentId={params.questionId}
              />
            </div>
            <div className="col-md-7">
              {generatedQuestions &&
                generatedQuestions.length > 0 &&
                sectionsFetched && (
                  <GenerateTags
                    hideHeaders
                    document={
                      generatedQuestions && generatedQuestions.length > 0
                        ? generatedQuestions[0]
                        : null
                    }
                    documents={generatedQuestions}
                    sections={[...sections]}
                    manageDocument={true}
                    summarisedView={true}
                  />
                )}
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default GenerateSummarisedKeywords;
