import React, { Children } from "react";

import "./style.scss";

const InfoBlock = ({ type, children }: any) => {
  return (
    <div
      className={
        type === "warning" ? "info-block info-block-warning" : "info-block"
      }
    >
      {type === "warning" ? (
        <i className="icon-alert-circle" />
      ) : (
        <i className="icon-bulb" />
      )}
      {Children.toArray(children)}
    </div>
  );
};

export default InfoBlock;
