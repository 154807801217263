import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import TextInput from "../TextInput";
// import MarkdownEditor from "../MarkdownEditor";
// import Button from "../Button";
import "./style.scss";

const QuizInputCard = ({
  id,
  type,
  value,
  hideClose,
  onClose,
  inputChange,
  placeholder,
  handleKeyPress,
  useTextArea,
  required,
  usePrimaryMarkdown,
  numberLabel,
  onPrimaryPaste,
  tabIndex,
  autoFocus,
  spellCheck,
  handleKeyUp,
  turnOnKeywords,
  keywordsValue,
  keywordsInputChange,
  pointScoreValue,
  pointScoreChange,
}: any) => {
  const { t } = useTranslation();
  return (
    <a className="quiz-input-card-wrapper-anchor">
      <div className={"quiz-input-card"}>
        {type === "edit" && (
          <div className="quiz-input-card-edit-wrapper">
            <div className="flex-container primary-section">
              {numberLabel && (
                <div className="number-section-label">
                  <span>{numberLabel}</span>
                </div>
              )}
              {!usePrimaryMarkdown && (
                <TextInput
                  id={id}
                  autoFocus={autoFocus}
                  required={required}
                  inputType="text"
                  inputClass="input-nf"
                  placeholder={placeholder}
                  value={value}
                  inputChange={inputChange}
                  name={id}
                  useTextArea={useTextArea}
                  onPaste={onPrimaryPaste}
                  tabIndex={tabIndex}
                  onKeyPress={handleKeyPress}
                  spellCheck={spellCheck}
                  onKeyUp={handleKeyUp}
                />
              )}
              {turnOnKeywords && (
                <React.Fragment>
                  <div className="keyword-input">
                    <TextInput
                      useTextArea
                      value={keywordsValue}
                      inputType="text"
                      inputClass="input-nf"
                      placeholder={t("keyword1 = 1, keyword2 = 0.5")}
                      inputChange={keywordsInputChange}
                    />
                  </div>
                  <div className="score-input">
                    <TextInput
                      value={pointScoreValue}
                      inputType="number"
                      inputClass="input-nf"
                      placeholder={t("Score for this point")}
                      inputChange={pointScoreChange}
                    />
                  </div>
                </React.Fragment>
              )}
            </div>
            {!hideClose && (
              <button
                className="quiz-input-card-close-button"
                onClick={() => onClose(id)}
              >
                <i className="icon-delete quiz-input-card-close-icon" />
              </button>
            )}
          </div>
        )}
      </div>
    </a>
  );
};

export default QuizInputCard;
