import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import chatImg1 from "../../../assets/New-images/chatbotImg1.svg";
import chatImg2 from "../../../assets/New-images/chatbotImg2.svg";
import docsummaryImg from "../../../assets/New-images/doc-success-img.svg";
import docSummaryLoadImg from "../../../assets/New-images/doc-summary-load-img.svg";
import greenCheck from "../../../assets/New-images/green-check.svg";

import axios from "axios";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { sumarieseToolbar } from "../../../app.constant";
import MoveSelectedModal from "../../../components/UI/KnowledgeDatatableComponent/MoveSelectedModal";
import Loading from "../../../components/UI/Loading/Loading";
import ProgressSteps from "../../../components/UI/ProgressSteps/ProgressSteps";
import RichTextArea from "../../../components/UI/RichTextArea/RichTextArea";
import {
  CHAT_CUSTOMISATION,
  GENERATE_KNOWLEDGE,
  LEARNING_OUTCOMES,
  LEARNING_OUTCOMES_REVIEW,
  SUPPORT,
} from "../../../config";
import UploadFilesService from "../../../services/UploadService";
import {
  bulkAssignModules,
  getDocumentsTextBlocks,
} from "../../../store/knowledge/actions";
import { convertDataToMarkdownFormat } from "../../../utils/appUtils";
import SaveDocumentPopup from "./Popups/SaveDocumentPopup";
// import { cancelTokenSource } from "../../axios-global";

const uploadService = new UploadFilesService();

export const SummariseMultipleOrSingleFile = (props: any) => {
  const { t } = useTranslation("translation");
  const history: any = useHistory();
  const query = new URLSearchParams(history.location.search);
  const params: any = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [internalDocuments, setInternalDocuments] = useState<any>({});
  const [documents, setDocuments] = useState<any>([]);
  const [text, setText] = useState(null);
  const [documentLoaded, setDocumentLoaded] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const editableContentRef = useRef(null);
  const [loadingState, setLoadingState] = useState(false);
  const [documentName, setDocumentName] = useState("");

  let source = axios.CancelToken.source();

  const [showModal, setShowModal] = useState(null);
  const [newDocAdded, setNewDocAdded] = useState(false);
  const [paramsDocIds, setParamsDocIds] = useState([]);
  const [groupId, setGroupId] = useState(query.get("group") || "");

  useEffect(() => {
    setGroupId(
      history &&
        history.location &&
        history.location.state &&
        history.location.state.group
        ? history.location.state.group
        : query.get("group")
    );
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(history.location.search);
    let documents = query.get("documents").split(",");
    setParamsDocIds(documents);

    if (!documents.length) {
      history.goBack();
    }
    setSelectedDocument({
      value: documents[0],
      html: "",
    });
    setDocuments(documents);
    checkInternalDocuments(documents);

    return () => {
      source.cancel("Cancelled from source.");
    };
  }, []);

  const checkInternalDocuments = async (documents: string[]) => {
    let intDocs: any = {};
    await documents.forEach((element: any) => {
      intDocs[element] = {};
      intDocs[element].loading = true;
    });
    await setInternalDocuments(intDocs);
    setDocumentLoaded(true);
  };

  useEffect(() => {
    const query = new URLSearchParams(history.location.search);
    let documents = query.get("documents").split(",");
    if (
      Object.keys(internalDocuments).length === documents.length &&
      !documentLoaded
    ) {
      getDocumentsTextBlocksHandler();
    }

    setLoading(
      Object.values(internalDocuments).some(function (elem: any) {
        return elem.loading;
      })
    );
  }, [internalDocuments]);

  // console.log("internalDocuments", internalDocuments);

  const getDocumentsTextBlocksHandler = async () => {
    // InternalRef && clearTimeout(InternalRef)
    try {
      for await (const document of Object.keys(internalDocuments)) {
        if (internalDocuments[document].loading) {
          const response: any = await dispatch(
            getDocumentsTextBlocks(document, source)
          );
          let intDocs: any = { ...internalDocuments };
          intDocs[document].loading = true;
          intDocs[document].html = patchHtml(response);
          setInternalDocuments({ ...intDocs });
          let lastIndex: any = response[response.length - 1];
          patchHtml(response);

          //
          if (selectedDocument.value === document) {
            setText(internalDocuments[selectedDocument.value].html);
            setSelectedDocument({
              value: documents[0],
              html: internalDocuments[selectedDocument.value].html,
            });
          }

          if (lastIndex && lastIndex.is_last_block) {
            intDocs[document].loading = false;
            intDocs[document].success = true;
            setInternalDocuments({ ...intDocs });
          }
          if (!response.length || (lastIndex && !lastIndex.is_last_block)) {
            setTimeout(() => {
              getDocumentsTextBlocksHandler();
            }, 20 * 1000);
          }
        }
      }
    } catch (error) {
      console.log("[ error ]", error);
    }
  };

  const patchHtml = (response: any[]) => {
    let summarisedContent = "";
    let summarisedContentWithoutTag = "";
    response.forEach((res: any) => {
      summarisedContent += `<p>${res.text}</p>`;
      summarisedContentWithoutTag += `${res.text}\n\n`;
    });
    // setText(summarisedContent);
    return summarisedContentWithoutTag;
  };

  const updateSelectedDocument = (key: string) => {
    setSelectedDocument({
      value: key,
      html: internalDocuments[key].html,
    });
    setText(internalDocuments[key].html);
  };

  const onEditorStateChange = (key: string, value: any) => {
    setSelectedDocument({
      value: key,
      html: internalDocuments[key].html,
    });

    let intDocs: any = { ...internalDocuments };
    intDocs[key].html = value;
    setInternalDocuments({ ...intDocs });
    setText(value);
  };

  const saveThisDocumentHandler = async () => {
    const query = new URLSearchParams(history.location.search);
    const documents = query.get("documents");
    try {
      setLoading(true);
      if (selectedDocument && selectedDocument.html) {
        let content = convertDataToMarkdownFormat(selectedDocument.html);
        const file = new Blob([content], {
          type: "text/markdown; text/markdown",
        });

        await uploadService.uploadV3(
          file,
          selectedDocument.value + ".md",
          null,
          (event: any) => {}
        );
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const saveAllDocumentHandler = async (fileName: string = "") => {
    try {
      setLoading(true);
      let documents: string[] = [];
      for await (const document of Object.keys(internalDocuments)) {
        if (internalDocuments && internalDocuments[document].html) {
          let content = convertDataToMarkdownFormat(
            internalDocuments[document].html
          );
          const file = new Blob([content], {
            type: "text/markdown; text/markdown",
          });

          await uploadService.uploadV3(
            file,
            fileName ? fileName + ".md" : null, // document + ".md"
            document,
            (event: any) => {}
          );
          documents.push(document);
        }
      }

      await dispatch(
        bulkAssignModules(groupId, documents, "document", false)
      );
      let docIds = query.get("documents").split(",");
      if("learning"){

      }
      const learning = history.location.state?.learning ;
      const path = learning === 'learning'? LEARNING_OUTCOMES: LEARNING_OUTCOMES_REVIEW

      history.push(
        path +
          "?documents=" +
          docIds +
          `&group=${groupId}`,
        {
          group: groupId,
          learning: history.location.state?.learning,
          docIds: history.location.state?.docIds
        }
      );

      // history.push(
      //   GENERATE_KNOWLEDGE.replace(":step", "5").replace(
      //     ":questionId",
      //     documents.join(",")
      //   ) + `?group=${groupId}`,
      //   {
      //     group: groupId,
      //   }
      // );
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const confirmCategoryUpdate = async (
    selectedOption: any,
    createButtons: boolean = false
  ) => {
    setNewDocAdded(false);
    try {
      const query = new URLSearchParams(history.location.search);
      let documents = query.get("documents").split(",");
      await setLoading(true);
      if (selectedOption) {
        await dispatch(
          bulkAssignModules(
            selectedOption,
            [...paramsDocIds],
            "document",
            false
          )
        );
      }
      await setLoading(false);
      history.push(
        GENERATE_KNOWLEDGE.replace(":step", "5").replace(
          ":questionId",
          documents.join(",")
        )
      );
    } catch (error) {
      setNewDocAdded(false);
      await setLoading(false);
    }
  };

  if (loading) {
    <Loading />;
  }

  return (
    <React.Fragment>
      {showModal && (
        <SaveDocumentPopup
          show={showModal}
          onHide={() => setShowModal(false)}
          footerButtonAction={async (fileName: string) => {
            setShowModal(false);
            await saveAllDocumentHandler(fileName);
            await setNewDocAdded(true);
          }}
        />
      )}

      <MoveSelectedModal
        show={newDocAdded}
        onHide={() => setNewDocAdded(false)}
        confirmCategoryUpdate={confirmCategoryUpdate}
        isBuildKnowledge
      />

      <section className="page-mid-wraper page-mid-wraper-build-v2 h-without-foter">
        <div>
          <div className="d-flex flex-wrap justify-content-center clr-primary">
            {paramsDocIds.length <= 1 ? (
              <h1 className="mb-2">{t("Summarise Uploaded Document(s)")}</h1>
            ) : (
              <h1 className="mb-2">
                {t("Individual Summaries for Uploaded Document(s)")}
              </h1>
            )}
          </div>
          <div className="mb-32 font-16 text-center">
            {loading
              ? t("Preview of document(s) summary.")
              : t(
                  "Review and edit summarised document(s) before saving them into your knowledge base."
                )}
          </div>

          <div className="pdf-section-wrapper">
            <>
              <div className="document-section-wrapper row">
                <div className="col-md-5 d-flex flex-column text-center">
                  <div className="doc-processing d-flex flex-wrap align-items-center p-0 mb-3">
                    {/* <div className="col-status">
                      {loading && <span className="doc-loader"></span>}
                    </div> */}
                    {!loading && (
                      <div className="w-100 text-center font-600">
                        <span className="doc-success mr-2">
                          <img src={greenCheck} alt="" />
                        </span>
                        <span>{t("All document(s) has been summarised!")}</span>
                      </div>
                    )}
                    {loading && (
                      <div className="w-100 font-600 d-flex align-items-center">
                        <span>
                          <img src={docSummaryLoadImg} alt="" width={150} />
                        </span>
                        <span className="text-left pl-3">
                          {t(
                            "Summarising your document(s)."
                          )}
                          <br />
                          {t(
                            "This may take a few moments, depending on the length of your document."
                          )}
                        </span>
                      </div>
                    )}
                  </div>

                  {!loading && (
                    <div className="doc-sucess-img">
                      <img src={docsummaryImg} alt="" />
                    </div>
                  )}
                  <div className="doc-process-status-wrap">
                    {Object.keys(internalDocuments).map(
                      (key: string, index: number) => {
                        return (
                          <>
                            <div
                              className={classNames({
                                "mt-3": index > 0,
                                "border-grey p-3 radius-8 text-left d-flex align-items-center":
                                  true,
                                "summarise-notify-settings":
                                  selectedDocument?.value === key,
                              })}
                              onClick={(e: any) => {
                                e.preventDefault();
                                updateSelectedDocument(key);
                              }}
                            >
                              {internalDocuments[key].loading && (
                                <figure className="h-auto mb-0">
                                  <span className="doc-loader spinner-width-20 mr-2"></span>
                                </figure>
                              )}
                              {internalDocuments[key].success && (
                                <span className="doc-success mr-2">
                                  <img src={greenCheck} alt="" />
                                </span>
                              )}
                              <div className="text-left pl-2">
                                <h3 className="font-weight-bold font-16 clr-primary mb-1">
                                  {t("Document ")} {index + 1}
                                </h3>
                                {internalDocuments[key].loading && (
                                  <p className="clr-grey-txt7 mb-0 font-sm font-600">
                                    {t("Processing Document...")}
                                  </p>
                                )}
                                {!internalDocuments[key].loading && (
                                  <p className="clr-grey-txt7 mb-0 font-sm font-600">
                                    {t("Processing Completed")}
                                  </p>
                                )}
                              </div>
                            </div>
                            {/* {!loading && (
                              <div className="summarise-notify-settings border-grey p-3 radius-8 mt-auto d-flex align-items-center">
                                <span className="doc-success mr-2">
                                  <img src={greenCheck} alt="" />
                                </span>
                                <div className="text-left pl-2">
                                  <h3 className="font-weight-bold font-16 mb-1 clr-green">
                                    {t("Document 1")}
                                  </h3>
                                  <p className="clr-grey-txt7 mb-0 font-sm font-600">
                                    {t("Processing Complete")}
                                  </p>
                                </div>
                              </div>
                            )} */}
                          </>
                        );
                      }
                    )}
                  </div>
                  {loading && (
                    <div className="summarise-notify-settings border-grey p-3 radius-8 mt-auto text-left">
                      <h2 className="font-600 mb-3">
                        {t(
                          "While waiting, you can choose to do the following."
                        )}
                      </h2>
                      <Row>
                        <Col className="mb-3">
                          <div
                            className="knowledge-box know-box-new radius-8 p-3 not-hover d-flex align-items-center "
                            onClick={() => history.push(CHAT_CUSTOMISATION)}
                          >
                            <figure className="h-auto mb-0">
                              <img src={chatImg1} alt="" />
                            </figure>
                            <div className="text-left pl-2">
                              <h3 className="font-weight-bold font-sm clr-primary mb-1">
                                {t("Manage your chatbot")}
                              </h3>
                              <p className="clr-grey-txt7">
                                {t("To match your your brand")}
                              </p>
                            </div>
                          </div>
                        </Col>
                        <Col className="mb-3">
                          <div
                            className="knowledge-box know-box-new radius-8 p-3 not-hover d-flex align-items-center"
                            onClick={() => history.push(SUPPORT)}
                          >
                            <figure className="h-auto mb-0">
                              <img src={chatImg2} alt="" />
                            </figure>
                            <div className="text-left pl-2">
                              <h3 className="font-weight-bold font-sm clr-primary mb-1">
                                {t("Learn how to setup")}
                              </h3>
                              <p className="clr-grey-txt7">
                                {t("View our video guides")}
                              </p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <div className="font-600 font-sm mt-3">
                        {t(
                          "Please ensure that your notifications are on so that you don’t miss out on the update."
                        )}{" "}
                        <span
                          className="clr-primary font-weight-bold cursor-pointer"
                          onClick={() =>
                            history.push("/notification", {
                              activetab: "preference",
                            })
                          }
                        >
                          {t("Check notification settings")}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-md-7 mt-3 mt-md-0">
                  <div className="doc-summary-preview border-grey radius-8 p-3 ">
                    <h2 className="font-600 mb-3">
                      {t(`${!loading ? t("Edit document") : t("Document Preview")}`)}
                    </h2>
                    {selectedDocument && selectedDocument.value && (
                      <>
                        <RichTextArea
                          showRemoveIcon={false}
                          key={selectedDocument.value + "_TA_IP"}
                          index={0}
                          removeIcon={null}
                          item={{
                            text: text || "",
                          }}
                          onEditorStateChangeHandler={(updatedIndex, value) => {
                            onEditorStateChange(selectedDocument.value, value);
                          }}
                          onRemoveGeetingHandler={(removedIndex) => {}}
                          customToolbar={sumarieseToolbar}
                          // readOnly={loading ? true : false}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="text-right mt-3">
                {documents.length > 1 && (
                  <>
                    {/* {" "}
                    <button
                      className={classNames({
                        "btn mr-2": true,
                        "btn-light": loading,
                        "btn-outline-primary": !loading,
                      })}
                      disabled={loading}
                      // disabled={
                      //   internalDocuments &&
                      //   selectedDocument &&
                      //   internalDocuments[selectedDocument.value] &&
                      //   !internalDocuments[selectedDocument.value]?.success
                      // }
                      onClick={() => {
                        saveThisDocumentHandler();
                        // if (!loading) {
                        // }
                      }}
                    >
                      {t("Save This Document")}
                    </button> */}
                    {/* {!loading && ( */}
                    <button
                      className={classNames({
                        "btn ": true,
                        "btn-light": loading,
                        "btn-primary": !loading,
                      })}
                      disabled={loading}
                      onClick={async () => {
                        await saveAllDocumentHandler();
                        // await setNewDocAdded(true);
                      }}
                    >
                      {t("Save all Document")}
                    </button>
                    {/* )} */}
                  </>
                )}

                {/* <button
                  className={classNames({
                    "btn mr-2": true,
                    "btn-light": loading,
                    "btn-primary": !loading,
                  })}
                  onClick={() => {
                    if (!loading) {
                      proceedToKnowledgeHandler();
                    }
                  }}
                >
                  {t("Save This Document")}
                </button> */}

                {!loading && documents.length === 1 && (
                  <button
                    className={classNames({
                      "btn ": true,
                      "btn-light": loading,
                      "btn-primary": !loading,
                    })}
                    onClick={async () => {
                      // setShowModal( true );
                      await saveAllDocumentHandler();
                      // await setNewDocAdded(true);
                    }}
                  >
                    {t("Save Document")}
                  </button>
                )}
              </div>
            </>
          </div>
        </div>
        <ProgressSteps currentStep={4} />
      </section>
    </React.Fragment>
  );
};

export default SummariseMultipleOrSingleFile;
