import cx from "classnames";
import { useEffect, useState } from "react";
import { Container, Image, Tab, Tabs } from "react-bootstrap";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import backblue from "../../../assets/images/arrow-left.svg";
import ClassesTile from "../../../components/UI/ClassesTile/ClassesTile";
import LearnerGroupTile from "../../../components/UI/LearnerGroupTile/LearnerGroupTile";
import {
  getCourseClasses,
  getUserGroups
} from "../../../store/knowledge/actions";
import "./ManageAccess.scss";

import { useTranslation } from "react-i18next";

export const ManageAccess = (props: any) => {
  const { t } = useTranslation('translation');
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const [classes, setClasses] = useState<any>([]);
  const [userGroups, setUserGroups] = useState<any>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const handle = async () => {
      try {
        const courseClasses: any = await dispatch(getCourseClasses());
        setClasses(courseClasses);
      } catch (error) {
        setClasses([]);
      }

      try {
        const userGroupsRes: any = await dispatch(getUserGroups());
        setUserGroups(userGroupsRes);
      } catch (error) {
        setUserGroups([]);
      }
    };
    handle();
  }, []);

  return (
    <section
      className={cx({
        "page-mid-wraper pb-0 h-without-foter": true,
        hidden: loading,
      })}
    >
      <Container>
        <div className="row">
          <div className="col-md-12">
            <button
              className="btn p-0 btn-back"
              onClick={() => {
                history.goBack();
              }}
            >
              <Image src={backblue} /> {t("Return to previous page")}
            </button>
            <h1 className="first-step mb-12 d-flex flex-wrap">{t("Manage Access")}</h1>
            <h2 className="mb-32 font-weight-normal">
              {t("Manage users and grant access and decide which of the content they can access")}
            </h2>
          </div>
        </div>

        <div className="knowledge-viewby col-lg-12 col-md-12 p-0">
          <h2 className="mb-0 mr-3 font-1 d-md-inline-block">{t("View for:")}</h2>
          <Tabs
            defaultActiveKey="class"
            // onSelect={(e: any) => {
            //   setActiveTab(e);
            // }}
          >
            <Tab eventKey="class" title={t("Classes")}>
              {" "}
              {classes.length > 0 &&
                classes.map((item: any, i: number) => {
                  return <ClassesTile item={item} key={i} />
                })}
            </Tab>
            <Tab eventKey="learner" title={t("Learner Groups")}>
              {" "}
              {userGroups.length > 0 &&
                userGroups.map((item: any, i: number) => {
                  return <LearnerGroupTile item={item} key={i} />;
                })}
            </Tab>
          </Tabs>
        </div>

        {/* <div className="row mb-2">
          <div className="col-md-12">
            <div className="all-knowledge-search d-flex flex-wrap align-items-center">
              <Dropdown className="">
                <Dropdown.Toggle
                  className="btn btn-outline-primary font-600 py-1 my-1 mr-3"
                  variant="success"
                  id="dropdown-basic"
                >
                  <img src={sortIcon} alt="" className="mr-2" />
                  Sort
                </Dropdown.Toggle>
                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => {
                                    onSortHandler('updated_at', 'desc')
                                    }}>Most Recent</Dropdown.Item>
                                    <Dropdown.Item onClick={() => {
                                    onSortHandler('name', 'asc')
                                    }}>Ascending</Dropdown.Item>
                                    <Dropdown.Item onClick={() => {
                                    onSortHandler('name', 'desc')
                                    }}>Descending</Dropdown.Item>
                                </Dropdown.Menu>
              </Dropdown>
              <div className="web-links my-1">
                <input
                  type="text"
                  className="form-control h-auto"
                  placeholder="Search for Classes"
                  // onChange={onInputChangeHandler}
                />
              </div>
            </div>
          </div>
        </div> */}
      </Container>
    </section>
  );
};
export default ManageAccess;
